/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const palette = {
  primary: {
    light: "#FD8331",
    main: "#F96302",
    dark: "#C14C00",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: "#5796BA",
    main: "#3E7697",
    dark: "#325F7A",
    contrastText: "#FFFFFF",
  },
  gray: {
    "50": "#EEEEEE",  //Light Gray
    "70": "#DDDDDD",  //Light Gray
    "100": "#CCCCCC", // Gray
    "200": "#999999", //Medium Gray
    "300": "#777777", //Dark Gray
    "400": "#55565A", //PRO Shade
    "500": "#333333", //Black
  },
  success: {
    light: "#BAF0D4",
    main: "#00A950",
    dark: "#088944",
  },
  warning: {
    light: "#FFF2C9",
    main: "#A1803C",
    dark: "#80652F",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#333333",
    secondary: "#777777",
    disabled: "#999999",
    highlight: "#00A950",
    alert: "#A1803C",
    expandedMenu: "#FFFFFF",
  },
  border: {
    main: "#cccccc",
    hover: "#F0F4F7",
    alert: "#C10C0D",
  },
  divider: "#eeeeee",
  background: {
    default: "#EEEEEE",
    paper: "#FFFFFF",
    transparency: "CC",
  },
  disabled: {
    background: "#EEEEEE",
  },
  //Palette Basic & Color
  foreground: "#FFFFFF",
  backgroundGray: "#EEEEEE",
  // secondary: {
  //   main: "#3E7697",
  //   light: "#81B0CC",
  //   dark: "#325F7A"
  // },
  highlight: {
    main: "#00A950",
    light: "#BAF0D4",
    dark: "#088944",
  },
  error: {
    main: "#DB1825",
    light: "#FFDAD4",
    dark: "#AF0D18",
    contrastText: "#FFFFFF",
  },
  alert: {
    main: "#A1803C",
    light: "#FFF2C9",
    dark: "#80652F",
    contrastText: "#FFFFFF",
  },
  overlay: "rgba(153, 153, 153, 0.6)",
  stickyShadows: "#eeeeee 0 3px 6px",
  cardPopUpsShadows: "#cccccc 0 3px 6px",
};
