import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledTypography } from "../../elements";

import { useWishList } from "../../../_foundation/hooks/use-wishlist";

export const WishListAddToCart = (props) => {
  const { products } = props;
  const { t } = useTranslation();

  const { addAllToCart } = useWishList();

  const handleAddToCart = () => {
    addAllToCart(products);
  };

  let disabled = false;
  if (!products || products?.length === 0) {
    disabled = true;
  }

  return (
    <>
      {/* <StyledTypography variant="textSegment" fontWeight={800}>
        {t("WishList.labels.total")}
        </StyledTypography>
        <StyledTypography variant="bodySubtext" className="color-gray300">
        {t("WishList.labels.taxes")}
        </StyledTypography>
        <StyledTypography variant="textPrice" fontWeight={700}>
        $00000.00
        </StyledTypography> */}

      <StyledButton
        id={"wishlist--sticky-addCartBtn"}
        variant="outlined"
        onClick={disabled ? null : handleAddToCart}
        disabled={disabled}>
        {t("WishList.labels.addListToCart")}
      </StyledButton>
    </>
  );
};
