/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Stepper from "@material-ui/core/Stepper";
import React from "react";

const StyledStepper = styled(({ ...props }) => <Stepper {...props} />)`
  ${({ theme, disableBackground }) => `
    background: ${disableBackground ? "" : theme.palette.disabled.background} !important;
    padding: 0px 0px;
    align-items: flex-start;

    &.step-number-grid-container{
      background:  ${theme.palette.primary.contrastText} !important;
    }
    
    .MuiStepConnector-alternativeLabel {
      left: calc(-50% + 13px);
      right: calc(50% + 13px);

    }

    .MuiStep-horizontal{
      padding-left: 0px;
      padding-right: 0px;
    }

    .MuiStepLabel-iconContainer{
      padding: 0;
    }

    .MuiStepLabel-labelContainer{
      position: absolute;
      width: auto;
      transform: translateY(120%);
    }
    // .MuiStepConnector-root{
    //   position: relative;
    //   top: 10px;
    // }

    .MuiStepLabel-root{
      justify-content: center;
    }



  `}
`;

export { StyledStepper };
