import { useState } from 'react';

import {StyledStepper } from '../../../../hdm/elements/stepper/styled-stepper'
import { Divider, makeStyles, StepConnector, withStyles } from "@material-ui/core"
import Check from "@material-ui/icons/Check";
import clsx from 'clsx'

import Collapse from "@material-ui/core/Collapse";

import KeyboardArrowUpSharpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { StyledBox, StyledLinkTextSecondary, StyledStep, StyledStepLabel, StyledTypography } from '../../../../hdm/elements';
import { palette } from '../../../../hdm/themes';
import styled from 'styled-components';

const HistoryDataContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
  .color-gray300{
    color: ${theme.palette.gray["300"]}
  }
`}`;


const QontoConnector = withStyles({
    alternativeLabel: {
    //   marginTop: 50,
    //   left: 'calc(-100%) !important',
    //   right: 'calc(100%) !important',
    //   alignItems: 'left !important',
      backgroundColor: `${palette.background.paper} !important`,
    },
    active: {
      '& $line': {
        borderColor: palette.primary.main,
        width: '100%'
      },
    },
    completed: {
      '& $line': {
        borderColor: palette.primary.main,
        width: '100%'
      },
    },
    line: {
      borderColor: palette.gray["100"],
      borderTopWidth: 2,
      borderRadius: 1,
      width: '100%'

    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
    //   color: palette.background.paper,
    //   display: 'flex',
    //   height: 22,
    //   alignItems: 'left',
        // marginTop: 50,
        backgroundColor: "",
        zIndex: 1,
        color: '#fff',
        width: 10,
        height: 10,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        marginTop: -9,
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: palette.primary.main,
        color: palette.background.paper,
    },
    circle: {
        marginTop: -10,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: palette.gray["100"]
    },
    completed: {
        marginTop: -10,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: palette.primary.main,
    },
});

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    console.log('props', props)

    const activo = {
        // marginTop: -10,
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: palette.primary.main,
        color: palette.background.paper,
    }
    return (
      <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
      >

        {completed ? <div className={classes.completed} /> : active ? <Check style={activo} /> : <div className={classes.circle} />}
      </div>
    );
  }

export const OrderHistorySteper = (props) => {
    const { orderDetails } = props

    const [expanded, setExpanded] = useState(true);
    
    if(!orderDetails) return null
    let stpes = [0,1,2,3,4]
    let stepsStatus = {};
    
    if((orderDetails?.shipModeCode && orderDetails?.shipModeCode !== 'Mail') ||
        (orderDetails?.x_shipType && orderDetails?.x_shipType !== '2')){
        stpes = [0,1,2,3]
        stepsStatus = {
            "M": 0,
            "A": 0,
            "R": 1,
            "S": 2,
            "SE": 2,
            "ER" : 2,
            "D": 3
        }
    }
    else {
        stepsStatus = {
            "M": 0,
            "A": 0,
            "R": 1,
            "S": 2,
            "SE": 2,
            "ER" : 2,
            "T": 3,
            "D": 4
        }
    }

    return (
        <StyledBox display="flex" flexDirection={orderDetails?.verHistorial ? "column" : "row"}>
                <HistoryDataContainer width={orderDetails.isMobile ? "100%" : "50%"}>
                    <StyledTypography variant="bodyBaseline" weight="semibold" style={{paddingBottom: '10px'}}>
                        {orderDetails?.x_statusFinal?.estatus}
                    </StyledTypography>
                    <StyledTypography className="color-gray300" variant="bodySubText">
                    {/* Actualización: {dd mm aaaa} {hh: mm} */}
                    Actualización: {orderDetails?.x_statusFinal?.fecha}
                    </StyledTypography>

                    <StyledTypography variant="bodyBaseline">
                        {orderDetails?.x_statusFinal?.descripcion}
                    </StyledTypography>

                    <StyledBox mt={4} mb={2} > 

                    <StyledStepper connector={<QontoConnector />}
                        activeStep={stepsStatus[orderDetails?.x_statusFinal?.varStatus] || stepsStatus[orderDetails?.xitem_itemHistStatus]}>

                            {stpes.map(step => {

                                return (
                                    <StyledStep key={step} >
                                        <StyledStepLabel StepIconComponent={QontoStepIcon}>
                                            
                                        </StyledStepLabel>
                                    </StyledStep>

                                )
                            })}
                        </StyledStepper>

                    </StyledBox>
                </HistoryDataContainer>
                <StyledBox width={orderDetails?.verHistorial ? "100%": "50%"} pl={orderDetails?.verHistorial ? 0 : 6} mt={orderDetails?.verHistorial ? 2 : 0} >
                    <StyledBox display="flex">
                        <StyledBox>
                            <StyledLinkTextSecondary
                                style={{color: palette.secondary.main}} 
                                className="color-secondary color-two" 
                                onClick={() => setExpanded(!expanded)}
                            >
                                Historial de estatus
                            </StyledLinkTextSecondary>
                        </StyledBox>
                        <StyledBox>
                            {expanded ? (
                                <KeyboardArrowUpSharpIcon color="secondary" style={{ marginLeft: '5px', fontSize: 12, cursor: 'pointer' }} onClick={() => setExpanded(!expanded)} />
                            ) : (
                                <KeyboardArrowDownSharpIcon color="secondary" style={{ marginLeft: '5px', fontSize: 12, cursor: 'pointer' }} onClick={() => setExpanded(!expanded)} />
                            )}
                        </StyledBox>                        
                    </StyledBox>


                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <StyledBox>
                        {orderDetails.x_HistorialStatus.map((historial, index) => {

                            return (
                                <HistoryDataContainer mb={2} key={index}>
                                    <StyledTypography className="color-gray300" variant="bodySubText">
                                    Actualización: {historial.fecha} 
                                    </StyledTypography>

                                    <StyledTypography variant="bodyBaseline">
                                    {historial.descripcion}
                                    </StyledTypography>
                                </HistoryDataContainer>

                            )
                        })}
                        {/* <StyledBox mb={2}>
                            <StyledTypography className="gray300" variant="bodySubText">
                            Actualización: 24 Jun 2022 12:30 PM
                            </StyledTypography>

                            <StyledTypography variant="bodyBaseline">
                            Tu orden está en camino
                            </StyledTypography>
                        </StyledBox>
                        <StyledBox mb={2}>
                            <StyledTypography className="gray300" variant="bodySubText">
                            Actualización: 24 Jun 2022 12:30 PM
                            </StyledTypography>

                            <StyledTypography variant="bodyBaseline">
                            Estamos programando el envío de tu orden
                            </StyledTypography>
                        </StyledBox>
                        <StyledBox mb={2}>
                            <StyledTypography className="gray300" variant="bodySubText">
                            Actualización: 24 Jun 2022 12:30 PM
                            </StyledTypography>

                            <StyledTypography variant="bodyBaseline">
                            Estamos validando tu orden
                            </StyledTypography>
                        </StyledBox>
                        <StyledBox mb={2}>
                            <StyledTypography className="gray300" variant="bodySubText">
                            Actualización: 24 Jun 2022 12:30 PM
                            </StyledTypography>

                            <StyledTypography variant="bodyBaseline">
                            Hemos recibido tu orden
                            </StyledTypography>
                        </StyledBox> */}
                    </StyledBox>
                    </Collapse>
                </StyledBox>
            </StyledBox>
    )
}