import React from "react";
import styled from "styled-components";
import { StyledGrid, StyledPaper } from "../../../hdm/elements";

const StyledPaperContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
    ${({ theme, ..._props }) => `
    .styledStickyPaper--stickyPaper-container{
        padding-left: ${theme.spacing(2)}px;
        padding-right: ${theme.spacing(2)}px;
        padding-top: ${theme.spacing(2)}px;
    }
     
    &.styledStickyPaper--stickyContainer-mobile{
        ${theme.breakpoints.up("md")} {
          display: none;
        }
        margin-top: 0px;
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        position: fixed;
        ${_props?.bottom?"bottom: "+_props.bottom+"px;":"bottom: 0px;"}
        ${_props?.zindex?"z-index: "+_props.zindex+";":"z-index: 100;"}
        left: 0px;
      }
    `}
`;



const StyledStickyPaper = (props) => {
    const bottom = props.bottom ? props.bottom : "0";
    const zindex = props.zindex ? props.zindex : "100";
    const className = props.className ? props.className : "";
    const id = props?.id ? props.id : null;
    return (
    <>
        <StyledPaperContainer id={id} className="styledStickyPaper--stickyContainer-mobile" bottom={bottom} zindex={zindex}>
            <StyledPaper className={`styledStickyPaper--stickyPaper-container ${className}`}>
                {props.content}
            </StyledPaper>
        </StyledPaperContainer>
    </>
    );
};

export { StyledStickyPaper };
