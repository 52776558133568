/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";

const StyledInputLabel = styled(({ ...props }) => <InputLabel {...props} />)`
  ${({ theme }) => `
  background-color: #ffffff;
  padding-right: ${theme.spacing(1)}px;
  pointer-events: auto;
`}
`;

export { StyledInputLabel };
