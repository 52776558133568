import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { Divider } from "@material-ui/core";
import { ContainerStyledBox, ContainerStyledTypography } from "./containers-acerca-de";
import { palette } from "../../themes/color-palette";
import { useSite } from "../../../_foundation/hooks/useSite";

interface Props {
  allEspecificacionesTec: any;
  isTablet: boolean;
  isKit?: boolean;
}
const EspecificacionesTecnicas = (props: Props) => {
  const { allEspecificacionesTec, isTablet, isKit = false } = props;
  const { mySite } = useSite();
  const isB2B:boolean = mySite?.isB2B;
  const SpecsEspotName:string = isB2B ? "PRO_PDP-AdditionalInfo_MiddleSection" : "PDP-AdditionalInfo_MiddleSection"; 

  const capitalFisrtletter = (data) => {
    if (data.trim() === "") return;
    data = data.trim().toLowerCase();
    return data.charAt(0).toUpperCase() + data.slice(1);
  };

  const formatName = (text:string):string => {
    const accentRegex = /[\u0300-\u036f]/g;
    const specialCharsRegex = /[&/\\#,+()$~%.'":*?<>{}]/g;
    const spaceRegex = /\s+/g;
  
    // Replace accents with non-accented letters
    text = text.normalize("NFD").replace(accentRegex, "");
    // Remove special characters
    text = text.replace(specialCharsRegex, "");
    // Replace spaces with hyphens
    text = text.replace(spaceRegex, "-");
    return text;
  }

  if (isTablet) {
    return (
      <ContainerStyledBox className="product-specs-card" mx={!isKit ? 2 : 0}>
        {allEspecificacionesTec &&
          allEspecificacionesTec.map((especTec, index) => {
            const residuo: number = (index + 1) % 2;
            const formattedName = formatName(especTec.atributo).toLowerCase();
            return (
              <ContainerStyledBox
                key={especTec.key}
                display="flex"
                className={`container-atributos ${
                  residuo === 1 ? "background-especificacion-g" : "background-especificacion-w"
                }`}>
                  
                <ContainerStyledBox width={1 / 2}>
                  <ContainerStyledTypography variant="bodyBaseline" weight="semibold"
                    id={`attName-${formattedName}`}
                    className={`attName-${formattedName}`}>
                    {capitalFisrtletter(especTec.atributo)}
                  </ContainerStyledTypography>
                </ContainerStyledBox>
                <ContainerStyledBox width={1 / 2}>
                  <ContainerStyledTypography variant="bodyBaseline"
                    id={`attValue-${formattedName}`}
                    className={`attValue-${formattedName}`}>
                    {capitalFisrtletter(especTec.valor)}
                  </ContainerStyledTypography>
                </ContainerStyledBox>
              </ContainerStyledBox>
            );
          })}
        {!isKit && (
          <>
            <ContainerStyledBox>
              <Divider style={{ marginTop: 10, background: palette.gray["50"] }} />
            </ContainerStyledBox>

            <ContainerStyledBox mt={2}>
              <EspotHdm espotName={SpecsEspotName} />
            </ContainerStyledBox>
          </>
        )}
      </ContainerStyledBox>
    );
  }

  const resultado = allEspecificacionesTec.length % 6;

  const allEspecificacionesTecClone: any = JSON.parse(JSON.stringify(allEspecificacionesTec));
  if (resultado === 1) {
    allEspecificacionesTecClone.push(
      {
        atributo: "",
        key: "li_attrValue1",
        valor: "",
      },
      {
        atributo: "",
        key: "li_attrValue2",
        valor: "",
      }
    );
  }
  if (resultado === 2) {
    allEspecificacionesTecClone.push({
      atributo: "",
      key: "li_attrValue1",
      valor: "",
    });
  }
  let attrValues: any = [];
  let background = true;
  return (
    <ContainerStyledBox>
      {allEspecificacionesTecClone &&
        allEspecificacionesTecClone.map((especTec, index) => {
          if(!especTec?.atributo || !especTec?.valor) return;
          const residuo: number = (index + 1) % 3;
          if (residuo === 1) {
            attrValues = [];
          }
          const formattedName = formatName(especTec.atributo).toLowerCase();
          attrValues.push(
            <ContainerStyledBox width={1 / 6} style={{ borderLeft: "10px solid white", padding: "10px" }}>
              <ContainerStyledTypography
                id={`attName-${formattedName}`}
                className={`attName-${formattedName}`}
                variant="bodyBaseline"
                style={{ fontWeight: "600" }}>
                {capitalFisrtletter(especTec.atributo)}
              </ContainerStyledTypography>
            </ContainerStyledBox>
          );
          attrValues.push(
            <ContainerStyledBox width={1 / 6} style={{ padding: "10px" }}>
              <ContainerStyledTypography
                id={`attValue-${formattedName}`}
                className={`attValue-${formattedName}`}
                variant="bodyBaseline">
                {capitalFisrtletter(especTec.valor)}
              </ContainerStyledTypography>
            </ContainerStyledBox>
          );



          if (residuo === 1 ) {
            background = !background;
            return (
              <ContainerStyledBox
                key={especTec.key}
                display="flex"
                className={`${background ? "background-especificacion-w" : "background-especificacion-g"}`}>
                {attrValues}
              </ContainerStyledBox>
            );
          }
        })}
      <ContainerStyledBox>
        <Divider style={{ borderLeft: "10px solid white", marginTop: 10, background: palette.gray["50"] }} />
      </ContainerStyledBox>
      <ContainerStyledBox style={{ paddingLeft: "10px" }} mt={2}>
        <EspotHdm espotName={SpecsEspotName} />
      </ContainerStyledBox>
    </ContainerStyledBox>
  );
};

export default EspecificacionesTecnicas;
