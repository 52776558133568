import styled from "styled-components";
import { StyledGrid } from "../../../../hdm/elements";
const StyledStoreSelectorContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .tooltip-container{
    align-items: center;
    display: flex;
  }

  .storeSelector--styledGrid-container{
    margin-top: ${theme.spacing(1)}px;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
  }

  .storeSelector--styledBox{
    display: flex;
  }

  .storeSelector--styledGrid-FormContainer{
    margin-top: ${theme.spacing(2)}px;
  }

  .storeSelector--styledGrid-locatorContainer{
    text-align: center;
    vertical-align: middle;
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .storeSelector--styledGrid-locatorContainerBottom{
    vertical-align: middle;
  }
  
  .storeSelector--styledGrid-moreInfoContainer{
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(4)}px;
    margin-bottom: ${theme.spacing(1)}px;
  }

  .storeLocator-styledGrid-addressInfo{
    margin-top: ${theme.spacing(1)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .storeSelector--styledGrid-callLinkMoreInfo{
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .storeSelector--styledGrid-showMap{
    display: flex;
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(1)}px;
  }

  .storeSelector--styledGrid-goBackLink{
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .storeLocator--styledGrid-arrowSelect{
    display: flex;
    width: 340px;
    justify-content: flex-end;
    vertical-align: middle;
  }

  .storeLocator--helperText-error{
    color: ${theme.palette.error.main};
    font-size: 10px; 
    margin-top: 3px;
  }

  .general--styledSelect-label-disabled {
      background-color: ${theme.palette.gray["50"]};
  }

  .storeSelector{
    &--styledTypography{
      &-highlight{
        color: ${theme.palette.text.highlight};
      }
      &-secondary{
        color: ${theme.palette.text.secondary};
      }
      &-errorMain{
        color: ${theme.palette.error.main};
      }
      &-primary{
        color: ${theme.palette.text.primary};
      }
      &-capital{
        text-transform: uppercase;
      }
      &-secondaryPalette{
        color: ${theme.palette.secondary.main};
      }
    &--listDecoration{list-style: none;}

  }


`}
`;
export { StyledStoreSelectorContainer };
