/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";

const StyledIconButton = styled(({ ...props }) => <IconButton {...props} />)`
  ${({ theme }) => `

  &.product-card-whishicon {
    box-shadow: 0px 3px 6px #99999999;
    opacity: 1;
    padding: 7px 7px 7px 7px;

  }
  &.addressIcon {
    padding: 0px;
    margin-left: 5px;
  }
  `}
`;

export { StyledIconButton };
