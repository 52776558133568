import { AnyARecord } from "dns";
import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledAvatar,
} from "../../containers-precio-promocion";

interface Props {
  ahorramasValues: any;
  ahorraMas: any;
}
const AhorraMasSeccionDos = (props: Props) => {
  const { ahorramasValues, ahorraMas } = props;

  let tipoAhorro = "";
  if (ahorraMas.tipoAhorro === "porcentaje") {
    tipoAhorro = "%";
  }
  if (ahorraMas.tipoAhorro === "monto") {
    tipoAhorro = "$";
  }

  return (
    <>
      <ContainerStyledBox className="dialog-secciontwo">
        <ContainerStyledBox display="flex" className="dialog-seccionobten">
          <ContainerStyledAvatar className="dialog-numberone">
            <ContainerStyledTypography varinat="bodySubText" weight="semibold">
              2
            </ContainerStyledTypography>
          </ContainerStyledAvatar>
          <ContainerStyledBox>
          <ContainerStyledTypography variant="bodyBaseline" component="span">
              &nbsp;Obtén&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold" component="span">
              {tipoAhorro}{ahorramasValues.ahorra}&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodyBaseline" component="span">
              de ahorro al agregar cualquiera de estos productos
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerStyledBox>
    </>
  );
};

export default AhorraMasSeccionDos;
