import styled from "styled-components";
import { StyledGrid } from "../../../hdm";
import { tabletBreakpoint } from "../../../hdm/functions/utils";

export const StyledCartContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  ${theme.breakpoints.down(tabletBreakpoint())} {
    .styledCardSummaryContent--dstp {
      display:none;
    }
  }
  
  .styledCart--stickyPaper-container{
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    padding-top: ${theme.spacing(2)}px;
    position: fixed;
    bottom: 0px;
  }

  &.styledCart--styledGrid-stickyContainer{
    margin-top: 0px;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 100;
    left: 0px;
  }

  .styledCart--stickyPaper-margin{
    margin-bottom: ${theme.spacing(2)}px;
  }

  .styledCart--styledGrid-title{
    background-color: white;
    margin-left: -${theme.spacing(1)}px;
    margin-right: -${theme.spacing(1)}px;
  }

  .summary--grid-marginDiscounts{
    margin-top: ${theme.spacing(1)}px;
  }

  .summary--typography-totalMargin{
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .summary--typography-taxIncludedColor{
    color: ${theme.palette.gray["200"]};
    display: inline-flex;
  }

  .deliveryMethod--typography-darkGray{
    color: ${theme.palette.gray["300"]};
  }

  .noVerticalMargin{
    margin-top: 0px;
    margin-bottom: 0px;

    @media (min-width: 600px) {
      margin-top: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
    }

  }

  .summary--grid-stickySummary{
    display: flex;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .deliveryZone--icon-store{
    &.MuiSvgIcon-fontSizeSmall {
      font-size: 18px;
    }
  }
  .deliveryZone--styledGrid-changeLink{
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .deliveryZone--styledGrid-gpsLabel{
    margin-top: ${theme.spacing(1)}px;
    &-pickUp{
      height: 16px;
      margin-right: ${theme.spacing(1)}px;
    }
  }

  .deliveryZone--icon-bopisIcon{
    margin-top: ${theme.spacing(1)}px;
    height: 16px;
  }

  .deliveryZone--typography-darkGray{
    color: ${theme.palette.gray["300"]};
  }

  &.deliveryZone--styledGrid-drawerContainer{
    padding: ${theme.spacing(2)}px;
    max-height: calc(100% - 140px);
  }

  .deliveryZone--styledGrid-arrowSelect{
    display: flex;
    width: 340px;
    justify-content: flex-end;
    vertical-align: middle;
  } 

  .deliveryZone--styledGrid-storesContainer{
    margin-bottom: 140px;
  }
  
  .deliveryZone--styledGrid-filteredStoresContainer{
    margin-bottom: 170px;
  }

  .bopisDrawer--styledGrid-FormContainer{
    margin-top: ${theme.spacing(2)}px;
  }

  .bopisDrawer--styledGrid-moreInfoContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(1)}px;
  }

  .bopisDrawer--styledGrid-addressInfo{
    margin-top: ${theme.spacing(1)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }
  .productDetails--styledGrid-productOptionsDividerContainer{
    text-align: center;
    padding-bottom: ${theme.spacing(2)}px;
    border-bottom: 1px solid ${theme.palette.gray["50"]};
  }
  .productDetails--styledGrid-productOptionsDivider{
    border-right: 1px solid ${theme.palette.gray["50"]};
    height: 20px;
  }
  .productDetails--styledPaper-productContainer{
    margin-bottom: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
  }
  .productDetails--styledPaper-noBoxShadow{
    box-shadow: none;
  }
  .productDetails--styledPaper-productContainerFirst{
    padding-top: ${theme.spacing(1)}px;
  }
  .productDetails--styledGrid-productInformationContainer{
    overflow: hidden;
    max-height: 56px;
    margin-bottom: ${theme.spacing(1)}px;
  }
  .productDetails--icon-checkIconInstallations{
    color: ${theme.palette.gray["200"]}; 
    font-size: 25px;
  }
  .productDetails--styledGrid-productLeftContainer{
    display: flex;
    flex:1;
    border-right: 1px solid ${theme.palette.gray["50"]};
    padding-top: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(1)}px;

    @media (min-width:1280px) {
      border-right: unset;
    }
  }
  .productDetails--styledGrid-productLeftContainerTYP{
    @media (min-width:1280px) {
      border-right: 1px solid ${theme.palette.gray["50"]};
  }
  }
  .productDetails--styledGrid-productPriceContainer{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .productDetails--styledGrid-productContainer{
    border-bottom: 1px solid ${theme.palette.gray["50"]};
  }
  .productDetails--styledGrid-productContainerHeight{
    min-height: 56px;
  }
  .productDetails--styledGrid-productInventoryLabel{
    padding-left: 10px;
    display: flex;
    align-items: center;
    
    @media (max-width: 600px){
            justify-content: flex-end;
    }
  }
  .productDetails--customTable-tdProductDetail{
    padding: 0px;
  }
  .productDetails--styledPaper-noPadding{
    padding: 0px;
    margin-bottom: 0px;
  }
  .orderSummary--styledTypography-highlight{
    color: ${theme.palette.text.highlight};
  }

  .productDetails--styledGrid-cartExceptionsContainer{
    margin-top: ${theme.spacing(2)}px;
    ${theme.breakpoints.up("sm")} {
      margin-top: 0px;
    }
  }
  & .drawer-link{
    color: ${theme.palette.secondary.main};
    cursor: pointer;
    margin-left: ${theme.spacing(1)}px;
}
&.tv_price{
  padding-right: 15px
}
  .bopisDrawer{
    &--styledTypography{
      &-highlight{
        color: ${theme.palette.text.highlight};
      }
      &-secondary{
        color: ${theme.palette.text.secondary};
      }
      &-errorMain{
        color: ${theme.palette.error.main};
      }
      &-primary{
        color: ${theme.palette.text.primary};
      }
      &-capital{
        text-transform: uppercase;
      }
      &-secondaryPalette{
        color: ${theme.palette.secondary.main};
      }
    }
  }
  
`}
`;
