import styled from "styled-components";
import { Swiper } from "swiper/react";
import { Box, CardMedia } from "@material-ui/core";
import { StyledGrid } from "../../elements";
import { palette } from "../../themes";
import { StyledRating } from "../../elements/rating";

export const ContainerStyledEmarsysCard = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme, color }) => `

  .styled--emarsysproductcard-container {
    width: 338px;
    min-height: 303px;
  }

  .product-card-img-info {
    display: flex;
    padding: 10px;
  }

  .product-card-image {
      position: relative;
      height: 150px;
      width: 150px;
  }

  .product-card-img {
    height: 150px;
    width: 150px;
    
  }

  .product-card-variante-mobile {
    display: flex;
    height: 25px;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(4)}px;
  }

  .product-card-basicinfo {
    padding-left: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;
    height: 136px;
  }

  .product-card-title {    
    height: 56px;
    overflow: hidden;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .card-titulo-producto {
    /*margin-left: ${theme.spacing(1)}px;*/
  }

  .produt-card-rewiev {
    margin-bottom: ${theme.spacing(2)}px;
    display: flex;
    height: 20px;
  }

  .colorGray300 {
    color: ${palette.gray["300"]};
  }

  .product-card-precio {
    display: flex;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .product-card-price-promocion {
    display: flex;
    align-items: center;
    /*height: 71px;*/
  }  

  .produt-card-regularpromo {
    height: 25px;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
    ${theme.breakpoints.down("sm")} {
      padding-left: 0 px;
    }
  }

  .verDetalle {
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
    width: calc(100% - 20px);
  }

  .emarsysproduct-card-image {
    position: relative;
    height: 150px;
    width: 150px;
}

.product-image {
  height: 150px;
  width: 150px;
  background-size: contain;
}

.promo_plp-ribbon-hv {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.styled--productcard-title {
  display: flex;
  max-height: 92px;
  overflow: hidden;
  margin-bottom: ${theme.spacing(2)}px;
}

.styled--quantityproduct-container {
  display: flex;
  align-items: end;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.produt-card-caption {
  height: 20px;
  display: flex;
  align-items: center;
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(1)}px;
  margin-top: ${theme.spacing(1)}px;
}

.styled--showdetails-container {
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
}

.produt-card-notCaption {
  display:none;
}

.link-preferencial-price {
  display: flex;
  width: 100%;
  justify-content: center;
}

  

`}
`;

export const StyledCardMediaProduct = styled(({ ...props }) => <CardMedia {...props} />)`
  &.product-image {
    height: 150px;
    width: 150px;
    background-size: contain;
  }

  &.promo_plp-ribbon-hv {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const ContainerStyledRating = styled(({ ...props }) => <StyledRating {...props} />)`
  ${({ theme }) => `
  
  color: #F96302;
  .MuiRating-iconEmpty {
      color: #EEEEEE
  }

  &.emarsys-rating-product {
    font-size: 15px;
  }

`}
`;

export const StyledSwiper = styled(({ ...props }) => <Swiper {...props} />)`
  ${({ theme }) => `

    &.products-list-swiper {
        .swiper-wrapper{
            margin-bottom: 5px;
        }
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
        padding-top: ${theme.spacing(4)}px;
        background-color: #e4e4e4;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .swiper-wrapper{       

        & .swiper-slide {
            flex-shrink: 1;
            width: 338px !important;
        }
        
    }

`}
`;

export const StyledBulletPagination = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

    &.styled--Bulletpagination-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    &.styled--Bulletpagination-none {
      display: none;
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .buttton-color {
        &.MuiButton-root {
            color: ${theme.palette.secondary.main} !important;

        }
    }

    

`}
`;

export const StyledEmarsysTitle = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(0)}px 0px 0px 0;

    .title-border {
      border-bottom: 5px solid ${theme.palette.primary.main};
    }

    .title-border-checkout {
      border-bottom: 2px solid ${theme.palette.primary.main};
      padding-bottom: 5px
    }

    .vertical-padding-2 {
      padding-bottom: 0px !important;
    }
  `}
`;
