import { useEffect } from "react";
//Standard libraries
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../../../constants/order-item-table";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";

const OxxoPay = ({ ...props }) => {
  const { t } = useTranslation();
  const orderDataTYP = props.orderDataTYP;
  const orderData = props.orderData;
  const storeConfData = useSelector(onlineStoreRdc);

  let numberReferences = 0;
  if (orderData.paymentInstruction) {
    numberReferences = orderData.paymentInstruction.length;
  }

  const OxxoReference = (props) => {
    let imgOxxo = "";
    let referenceOxxo = "";
    let referenceTotal = "";
    if (orderData?.paymentInstruction) {
      referenceTotal = orderData.paymentInstruction[props.referenceNumber].piAmount;
      const pi: any = JSON.parse(JSON.stringify(orderData.paymentInstruction[props.referenceNumber].protocolData));
      const referenceOxxoArr = pi.filter((pi) => pi.name === "paymentReference")[0];
      referenceOxxo = referenceOxxoArr?.value;
      const imgOxxoArr = pi.filter((pi) => pi.name === "barcodeUrl")[0];
      imgOxxo = imgOxxoArr?.value;
    }
    return (
      <>
        <StyledGrid container>
          <StyledGrid xs={5} className="top-margin-2">
            {props.referenceNumber === 0 && (
              <>
                <img
                  src={storeConfData?.IMAGE_SERVER_HOSTNAME + CONSTANTS.icons.oxxoPay}
                  alt="logo home depot"
                  height={"59px"}
                  className="lazyload"
                />
              </>
            )}
          </StyledGrid>
          {orderData?.paymentInstruction && (
            <StyledGrid xs={7} className="top-margin-2" align="right">
              <img src={imgOxxo} alt="logo home depot" height={"59px"} className="lazyload" />
            </StyledGrid>
          )}
          {numberReferences === 1 && <StyledGrid xs={6}></StyledGrid>}
          <StyledGrid xs={12} md={numberReferences === 1 ? 6 : 12}>
            <StyledGrid container className="top-margin-2">
              <StyledGrid xs={6}>
                {props.numberReferences === 2 ? (
                  <StyledTypography variant="bodyBaseline">
                    {props.referenceNumber === 0 && <>{t("OrderConfirmation.PaymentMethod.Oxxo.Reference1")}:</>}
                    {props.referenceNumber === 1 && <>{t("OrderConfirmation.PaymentMethod.Oxxo.Reference2")}:</>}
                  </StyledTypography>
                ) : (
                  <StyledTypography variant="bodyBaseline">
                    {props.referenceNumber === 0 && <>{t("OrderConfirmation.PaymentMethod.Oxxo.Reference")}:</>}
                  </StyledTypography>
                )}
              </StyledGrid>
              <StyledGrid xs={6} className={"orderConfirmation--styledGrid-amount"}>
                <StyledTypography variant="bodyBaseline" component={"span"}>
                  {t("OrderConfirmation.PaymentMethod.Oxxo.Amount") + " "}
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight={"semibold"} component={"span"}>
                  <FormattedPriceDisplay min={referenceTotal} currency={orderData?.grandTotalCurrency} />
                </StyledTypography>
              </StyledGrid>
              <StyledGrid xs={12} className={"orderConfirmation--styledGrid-referenceContainer"}>
                {orderData?.paymentInstruction && (
                  <StyledTypography
                    variant="bodyBaseline"
                    className={"orderConfirmation--styledGrid-referenceFont"}
                    component={"span"}>
                    {referenceOxxo}
                  </StyledTypography>
                )}
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };

  return (
    <>
      <>
        <StyledGrid xs={12}>
          <StyledTypography
            variant="headingFeatured"
            // weight="semibold"
            className={"orderConfirmation--styledTypography-upperCase"}>
            {t("OrderConfirmation.PaymentMethod.Oxxo.Name")}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid xs={12}>
          <StyledTypography variant="bodyBaseline" className={"top-margin-1"}>
            {t("OrderConfirmation.PaymentMethod.Oxxo.Description")}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid container spacing={2} >
          <StyledGrid item xs={12} md={numberReferences === 1 ? 12 : 6} className="top-margin-2">
            <OxxoReference referenceNumber={0} numberReferences={numberReferences} />
          </StyledGrid>
          {numberReferences === 2 && (
            <StyledGrid item xs={12} md={6} className="top-margin-2">
              <OxxoReference referenceNumber={1} numberReferences={numberReferences} />
            </StyledGrid>
          )}
        </StyledGrid>
      </>
    </>
  );
};

export { OxxoPay };
