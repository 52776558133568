/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import styled from "styled-components";
//Custom libraries
import { OrderItemTable } from "../../widgets/order-item-table";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
//UI
// import { Divider } from "@material-ui/core";
import ButtonHDM from "../../../../src/components/custom-components/styled-cart-drawer/cart-item/ButtonHDM";
import { StyledBox, StyledTypography } from "../../../hdm/elements";
import NoItemsCart from "../../custom-components/styled-cart-drawer/no-Items-cart/NoItemsCart";
import { formatProductPrice } from "../../../hdm/functions/utils";
import StyledWrapper from "../StyledWrapper";
import withSkeletonLoader from "../../custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../custom-components/skeleton-loader/components";
import { useSelector } from "react-redux";
import { backdropLoaderSelector, backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../custom-components/skeleton-loader/actions/sl-actions";

interface MiniCartContentProps {
  title: string;
  orderType: string;
  orderItems: any[];
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  emptyCartMsg: string;
  cartLinkLabel: string;
  checkoutLinkLabel: string;
  handleCartOnClick: (e?: any) => void;
  handleCheckoutOnClick: (e?: any) => void;
  handleClose: (e?: any) => void;
  isOrderOwner: boolean;
  isB2B: boolean;
  setShowCart: (e?: any) => void;
  showCart: any;
}

const StyledProductListWrapper = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .miniCartPopper--icon-trashcan{
          padding-top: 0;
          padding-right: 0px;
          width: 30px;
        }
        .styledCartRowPaint--styledGrid-QuantityDisplayPaintsMiniCart{
          display: flex;
          justify-content: space-between;
        }
        .mini-cart-table{
          tr{
            border-bottom: 1px solid ${theme.palette.gray[50]};
            padding: 10px;
          }
        }

        .styled-delete-trash-icon {
          width: 15px;
          height: 20px;
          margin-left: 10px;
          cursor: pointer;
        }

        .styled-delete-trash-button {
          padding: 0px;
        }

        & .product-list-container{
            height: calc(96vh - 154px);
            overflow: auto;
            padding-right: 10px;
        }

        & .subtotal-box{
            height: auto;
            width: 100%;
            bottom: 0;
            padding: 0 10px;
            border-top: 1px solid #EEEEEE;
            color: red;

                .__subtotal{
                    display:flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px 0 10px 0;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #EEEEEE;
                    color: ${theme.palette.gray[300]};
                    & span:nth-child(1){
                        //TODO: use theme
                        color: #777777;
                    }

                }
                }

        }`}
`;

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>((props: any, ref: any) => {
  const {
    // title,
    // orderType,
    orderItems,
    subtotalLabel,
    subtotal,
    subtotalCurrency,
    // emptyCartMsg,
    cartLinkLabel,
    checkoutLinkLabel,
    handleCartOnClick,
    handleCheckoutOnClick,
    handleClose,
    setShowCart,
    showCart,
    // isOrderOwner,
    // isB2B,
  } = props;

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);

  const isValidLoader = ()=>{
    return loaderState.src === LoaderActions.PRODUCT_QUANTITY_UPDATE ||
            loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART
  }

  return (
    <>
      {orderItems.length > 0 ? (
        // <StyledWrapper>
        <StyledProductListWrapper ref={ref} data-testid="mini-cart-popper">
          {/* Pro shop configs?? */}
          {/* {!isB2B ? (
        <StyledTypography variant="h6" className="horizontal-padding-2 top-padding-2">
          {title}
        </StyledTypography>
      ) : ( */}
          {/* <> */}
          {/* <StyledTypography
            variant="h6"
            style={{ overflowWrap: "break-word" }}
            className="horizontal-padding-2 top-padding-2">
            {title}
          </StyledTypography> */}

          {/* <StyledTypography variant="body1" className="horizontal-padding-2 bottom-padding-2">
            {orderType}
          </StyledTypography>
        </> */}

          {/* )} */}

          {/* <Divider className="heading" /> */}
          <>
            <StyledBox className="product-list-container">
              <OrderItemTable
                data={orderItems}
                miniCartView={true}
                handleMiniCartClose={handleClose}
                showCart={showCart}
                setShowCart={setShowCart}
              />
            </StyledBox>

            <StyledBox className="subtotal-box">
              {/* PRO orders ?? */}
              {/* {isB2B && !isOrderOwner ? (
          <StyledButton testId="handle-cart-on-click" color="secondary" onClick={handleCartOnClick}>
            {cartLinkLabel}
          </StyledButton>
        ) : ( */}

              <StyledBox className="__subtotal">
                {withSkeletonLoader(
                  loaders.secondary.miniCart.quantity,
                  loaderState.open && isValidLoader(),
                  <StyledTypography variant="bodyBaseline">{subtotalLabel}</StyledTypography>,
                  {omitTimeout:true}
                )}

                {withSkeletonLoader(
                  loaders.secondary.miniCart.price,
                  loaderState.open && isValidLoader(),
                  <StyledTypography id={"quick-cart__subtotal"} variant="bodyBaseline" fontWeight={600}>
                    {formatProductPrice(subtotal)}
                  </StyledTypography>,
                  {omitTimeout:true}
                )}
              </StyledBox>

              <StyledBox className="buttons">
                {/*<ButtonHDM text={checkoutLinkLabel} variant="contained" onClick={handleCheckoutOnClick} />*/}
                <ButtonHDM
                  id={"quick-cart__cart-button"}
                  disabled={loaderState.open}
                  text={cartLinkLabel}
                  variant="outlined"
                  onClick={handleCartOnClick}
                />
              </StyledBox>
            </StyledBox>
          </>
        </StyledProductListWrapper>
      ) : (
        // </StyledWrapper>
        // Empty cart
        //TODO: Make this an Espot
        withSkeletonLoader(
          loaders.secondary.miniCart.card,
          loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART,
          <NoItemsCart showCart={showCart} setShowCart={setShowCart} />,
          {omitTimeout:true}
        )
        
      )}
    </>
  );
});

export default StyledMiniCartContent;
