import { SvgIcon } from "@material-ui/core";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledBox, StyledCardEmpty, StyledTypography } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes";

const ForgotPasswordConfirmationContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

    .styled--confirmation-container {
        display: flex;
        justify-content: center;
    }

    .material-symbols-sharp {
        font-size: 60px;
        color: ${theme.palette.highlight.main}
    }

    .styled--taskalt-container {
        width: 60px;
        height: 60px;
        margin: 20px;
    }
    
`}
`;

function TaskAltIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
    </SvgIcon>
  );
}

export const ForgotPasswordConfirmation = (props) => {
  const { t } = useTranslation();
  const passwordUpdate = t("ResetPassword.PasswordUpdate");
  const passwordUpdateTitle = t("ResetPassword.PasswordUpdateTitle");

  useEffect(() => {
    if (props.setTitle) {
      props.setTitle(passwordUpdateTitle);
    }
  }, [props]);

  return (
    <Suspense fallback={<></>}>
      <ForgotPasswordConfirmationContainer>
        <StyledBox className="styled--confirmation-container">
            <TaskAltIcon className="styled--taskalt-container color-highlight"/>
        </StyledBox>
        <StyledTypography variant="bodyBaseline" align="center" className="bottom-margin">
          {passwordUpdate}
        </StyledTypography>
      </ForgotPasswordConfirmationContainer>
    </Suspense>
  );
};
