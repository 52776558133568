/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { Fragment } from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Hidden } from "@material-ui/core";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import "pure-react-carousel/dist/react-carousel.es.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { SwiperSlide, useSwiper } from "swiper/react";
import { StyledSwiper, StyledBulletPagination } from "./Container-product-recommendation";
import { StyledBox, StyledTypography, StyledButton } from "../../elements";
import { mobileBreakpoint } from "../../functions/utils";
// import "swiper/swiper.min.css";
// import 'swiper/swiper.scss';
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
//  import "./style.css";

import { Pagination, FreeMode, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import EmarsysProduct from "../../commerce-widgets/hdm-emarsys-product-recommendation/EmarsysProduct";

const StyledCarouselProvider = styled(({ ...props }) => <CarouselProvider {...props} />)`
  ${({ theme }) => `
   position: relative;
   padding: 0 ${theme.spacing(0)}px;
   margin-top: ${theme.spacing(0)}px;
   height: 320px;
 
   .carousel__back-button,
   .carousel__next-button {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     height: ${theme.spacing(5)}px;
     width: ${theme.spacing(5)}px;
     border: none;
     border-radius: 50%;
     background: none;
     border: 1px solid transparent;
 
     &:hover {
       cursor: pointer;
       color: ${theme.palette.primary.main};
       border-color: ${theme.palette.primary.light};
     }
   }
 
   .carousel__back-button {
     left: 0;
   }
 
   .carousel__next-button {
     right: 0;
   }
 
   div {
     outline: none;
   }
 
   .carousel__inner-slide {
     width: 0px;
   }
 
 
 
   `}
`;

const StyledSlide = styled(({ ...props }) => <Slide {...props} />)`
  text-align: center;

  .product-card {
    width: 100px;
    display: inline-block;
    height: 396px;
  }
`;

const StyledStaticSlideList = styled.div`
  ${({ theme }) => `
     margin-top: ${theme.spacing(0)}px;
 
     display: flex;
     justify-content: center;
     /*align-items: stretch;*/
     /*Estilos*/
     .product-card {
       max-width: 100px;
       min-width: 100px;
       display: inline-block;
       height: 396px;
       margin-right: ${theme.spacing(1)}px;
     }
   `}
`;

const StyledProductRecommendationSlider = ({ slidesList, espotId }: any) => {
  const swiper = useSwiper();

  const theme = useTheme();
  let slidesShown = 4;
  let disabledSliding = false;
  const { t } = useTranslation();
  
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint()));

  if (useMediaQuery(theme.breakpoints.down(1370))) {
    slidesShown = 3;
  } 
  if (useMediaQuery(theme.breakpoints.down(1030))) {
    slidesShown = 2;
  }
  // Check if there are enough slides to cycle through
  if (slidesShown >= slidesList.length) {
    disabledSliding = true;
  }

  return (
    <>
      <StyledBox ml={2}>
        <StyledSwiper
          slidesPerView={"auto"}
          spaceBetween={5}
          freeMode={true}
          slidesPerGroup={slidesShown}
          pagination={{
            el: `.swiper-pagination${espotId}`,
            type: "bullets",
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>';
            },
          }}
          navigation={{
            nextEl: `.swiper-button-nextx${espotId}`,
            prevEl: `.swiper-button-prevx${espotId}`,
          }}
          modules={[FreeMode, Pagination, Navigation]}
          className="products-list-swiper"
          onSlideChange={(data) => {
            //  console.log('entro', data)
          }}>
          {slidesList.map((slide: any, i: number) => (
            <SwiperSlide key={i + i} className="miClasex">
              {slide}
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </StyledBox>

      <StyledBulletPagination
        display={isMobile ? "none" : "flex"}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={4}
        espotId={espotId}>
        <StyledBox>
          <StyledButton
            size="small"
            variant="default"
            color="secondary"
            className={`swiper-button-prevx${espotId} buttton-color`}>
            <KeyboardArrowLeft color="primary" />
            {t("RecommendProducts.Previous")}
          </StyledButton>
        </StyledBox>
        <StyledBox mx={10}>
          <div className={`swiper-pagination${espotId}`} />
        </StyledBox>
        <StyledBox>
          <StyledButton
            size="small"
            variant="inherit"
            color="secondary"
            className={`swiper-button-nextx${espotId} buttton-color`}>
            {t("RecommendProducts.Next")}
            <KeyboardArrowRight color="primary" />
          </StyledButton>
        </StyledBox>
      </StyledBulletPagination>
    </>
  );

  return disabledSliding ? (
    <StyledStaticSlideList>
      {slidesList.map((slide: any, i: number) => (
        <Fragment key={i}>{slide}</Fragment>
      ))}
    </StyledStaticSlideList>
  ) : (
    <StyledCarouselProvider
      naturalSlideWidth={230}
      naturalSlideHeight={396}
      visibleSlides={4}
      step={4}
      infinite={false}
      dragEnabled={false}
      totalSlides={slidesList.length}>
      <Slider>
        {slidesList.map((slide: any, i: number) => (
          <StyledSlide index={i} key={i + i}>
            {slide}
          </StyledSlide>
        ))}
      </Slider>
      <ButtonBack>
        <ChevronLeftIcon />
      </ButtonBack>
      <ButtonNext>
        <ChevronRightIcon />
      </ButtonNext>
    </StyledCarouselProvider>
  );
};

export { StyledProductRecommendationSlider };
