/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import { StyledTypography } from "../typography";
import { StyledBox } from "../box/styled-box";
import { body } from "msw/lib/types/context";

const StyledIconWithBackground = styled.div`
  ${({ theme }) => `
    position: relative;
    flex: none;
    background-color: ${theme.palette.gray["50"]};
    width: ${theme.spacing(12)}px;
    height: ${theme.spacing(12)}px;
    border-radius: 50%;
    text-align: center;
    color: ${theme.palette.secondary.main};

    .MuiSvgIcon-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: ${theme.palette.primary.main};
      width: 26px;
      height: 26px;

      ${theme.breakpoints.down("sm")} {
        width: 20px;
        height: 20px;
      }

    }

    ${theme.breakpoints.down("sm")} {
      width: ${theme.spacing(8)}px;
      height: ${theme.spacing(8)}px;
    }
  `}
`;

const StyledBoxContainerAddCard = styled(StyledBox)`
  ${({ theme }) => `
    display:flex;
    flex-flow: row wrap;
    align-items: center;

    ${theme.breakpoints.up(961)} {
      display: unset;

      .container-icon-card {
        text-align: -webkit-center;
      }
    }
  `}
`;

interface StyledIconLabelProps {
  icon: any;
  label: any;
  variant?: string;
  paddingTopText?: string;
  paddingLeftText?: string;
}

const StyledIconLabel = styled(
  React.forwardRef<any, StyledIconLabelProps>((props: any, ref: any) => {
    const { icon, label } = props;
    const variant = props.variant ? props.variant : "h5";
    const paddingTopText = props.paddingTopText ? props.paddingTopText : "0px";
    const paddingLeftText = props.paddingLeftText ? props.paddingLeftText : "0px";
    const isLabelString = typeof label === "string" || label instanceof String;

    const TextElements = isLabelString ? (
      <StyledTypography
        variant={variant}
        style={
          variant == "bodyBaseline"
            ? { color: "#3E7697", paddingTop: paddingTopText, paddingLeft: paddingLeftText }
            : { paddingTop: paddingTopText, paddingLeft: paddingLeftText }
        }>
        {label}
      </StyledTypography>
    ) : (
      label
    );

    return (
      <StyledBoxContainerAddCard ref={ref}>
        <StyledBox className="container-icon-card" pr={2} style={{ paddingRight: "0px"}}>
          <StyledIconWithBackground className="icon-add-card">{icon}</StyledIconWithBackground>
        </StyledBox>
        <StyledBox pr={2} py={1} >
          {TextElements}
        </StyledBox>
      </StyledBoxContainerAddCard>
    );
  })
)`
  ${({ theme }) => `

    `}
`;

export { StyledIconLabel };
