import styled from "styled-components";
import { StyledBox } from "../box/styled-box";


const ContainerCircleBackground = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme, width, height, backgroundColor }) => `

    display: flex;
    width: ${width};
    height: ${height};
    border-radius: 50%;
    background-color: ${backgroundColor};
    align-items: center;
    justify-content: center;


`}
`;

export const CircleBackground = (props) => {

    const {width , height, backgroundColor, icon } = props

    return (
         <ContainerCircleBackground width={width} height={height} backgroundColor={backgroundColor} >

            {icon ?
            icon
            :
            null}

        </ContainerCircleBackground>
    )
}