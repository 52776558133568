import styled from "styled-components";
import {
  StyledGrid,
  StyledSwipeableDrawer,
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledTextField,
} from "../../elements";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RoomIcon from "@material-ui/icons/Room";

import { palette } from "../../themes/color-palette";
export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.disponibilidad-container {    
    margin-bottom: 10px;
  }

  .title-disponibilidad {
    margin-bottom: 10px;
  }

  &.margin-y10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.buscar-tienda-container {
    position: absolute;
    bottom: 0px; 
    minHeight: 50px;
    width: 100%;
    background: white;
  }

  &.title-buscar-tienda {
    width: calc(100% - 20px);
  }

  &.collapse-container {
    width: calc(100% - 20px);    
  }

  .wrapper-buscar {    
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-tiendas {
    flex: 1;
    overflow: auto;
    background: pink;
  }

  .styled--eventsharp-icon {
    width: 16px;
    height: 16px;
    color: ${theme.palette.gray["200"]};
  }

  .styled--errorsharp-icon {
    width: 16px;
    height: 16px;
    color: ${theme.palette.gray["200"]};
  }

  .styled--timer-icon {
    width: 20px;
    height: 20px;
  }

 `}
`;

export const StyledSwipeableDrawersDisponibilidad = styled((props: any) => <StyledSwipeableDrawer {...props} />)`
  ${({ theme }) => `

  .MuiDrawer-paper{
    //position: relative;
    width: 90% !important;
    padding: 0!important;
    overflow-x: hidden;
    max-width: 360px!important;
    min-width: 288px!important;
  }
  .MuiDrawer-root{
    width: 90%;
  }

`}
`;

export const ContainerStylediv = styled.div`
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1;
    overflow: auto;
  }
`;

export const ContainerStyledTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `

  &.text-buscar-disponibilidad {
    color: ${palette.secondary.main};
    cursor: pointer;
  }

`}
`;

export const ContainerStyledButton = styled(({ ...props }) => <StyledButton {...props} />)`
  ${({ theme }) => `
    &.availability-PDP{
      margin-left: 5px;
      & span {
        justify-content: flex-start;
      }
    }

  
`}
`;

export const ContainerStyledCheckCircleIcon = styled(({ ...props }) => <CheckCircleIcon {...props} />)`
  ${({ theme }) => `

  height: 24px;
  margin-bottom: -1px;

  &.check-enabled {
    color:${palette.highlight.main};
  }

  &.check-disabled {
    color: ${palette.gray["200"]};
  }
  
`}
`;

export const ContainerStyledRadioCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 40px;
    align-items: center;

    &.container{
      outline: 1px solid ${theme.palette.secondary.light};
      border-radius: 3px;

      &:hover {
        outline: 1px solid ${theme.palette.primary.main};
        cursor: pointer;
      }
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
      &:hover {
        outline: 1px solid ${theme.palette.success.main};
      }
    }

    &.successIconRadio{
      justify-content: flex-end;
    }

    & span{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const ContainerStyledUncheckedRadioIcon = styled(({ ...props }) => <RadioButtonUncheckedIcon {...props} />)`
  ${({ theme }) => `
    color: ${palette.gray["100"]} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
`}
`;

export const ContainerStyledSuccessRoomIcon = styled(({ ...props }) => <RoomIcon {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.success.main} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
    width: 15px;
    height: 15px;
  `}
`;


export const ContainerStyledSuccessIconRadio = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  background-color: ${theme.palette.success.main};
  width: 26px;
  padding-top: ${theme.spacing(1)}px;
  height: 40px;
  display: inline-block;
  color: ${theme.palette.text.expandedMenu};
  text-align: center;
  border-radius: 0px 3px 3px 0px;
`}
`;
