import React, { FC, lazy, Suspense, useEffect, useState } from "react";
import { StyledBox, StyledTypography } from "../../../hdm/elements";
import styled from "styled-components";
import {useNavigate} from "react-router";
import {ErrorBoundary} from 'react-error-boundary'

interface CardIconProps {
  text: string;
  icon?: JSX.Element;
  link: string;
  closeMenu: () => void;
  svg: string;
}

const StyledCardIcon = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme, text, children }) => `
        &.cardIcon-container{
            height: 115px;
            width: 46%;
            display: flex;
            flex-direction: column;
            margin: 2%;
            border-radius: 3px;
            box-shadow: #cccccc 0 3px 6px;
            cursor: pointer;

            & .cardIcon-styledBox-container{
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 11px;
                // margin-bottom: 15px;

            }

            & .cardIcon-styledBox-container{


                display: flex;
                justify-content: center;

                & .cardIcon-styledBox-typography{
                    inline-size: ${
                      children[1].props.children.props.children.length === 23
                        ? "104"
                        : children[1].props.children.props.children.length === 25
                        ? "99"
                        : "100"
                    }px;
                    overflow-wrap: break-word;
                    word-break: break-all;
                    text-align: center;
                    margin-top: ${children[1].props.children.props.children.length <= 13 ? 11 : 5}px;
                }
            }
        }
    `}
`;

const CardIcon = ({ data, closeMenu, index, ...props}) => {

    const navigate = useNavigate();

    const navigateTo = () => {
        navigate(`${data.url}`, {replace: false});
        closeMenu();
    }

    const Wrapper = (props)=>{

      return (
        <StyledBox className="cardIcon-styledBox-container">
          {props.children}
        </StyledBox>
      );
    }

    const AssetIcon = (props)=>{  
      return <img className="__svg-background" src={props.icon} alt={props.icon} width={props.iconSize} />
    }

    const CardIcon = ({icon, url, ...props})=>{
      let PropIcon:any = <></>
        PropIcon = lazy(() => import("@material-ui/icons/"+icon))

        return(
          <ErrorBoundary fallback={
            <Wrapper>
                <AssetIcon icon={icon} iconSize={50} color={"primary"}/>
            </Wrapper>
          }>
            <Wrapper>
              <a rel="noreferrer" target={"_blank"} href={url}>
                  <Suspense fallback={<div>Loading..</div>}>
                    <PropIcon/>
                  </Suspense>
                </a>
            </Wrapper>
          </ErrorBoundary>
        );

    }

    

  return (
    <StyledCardIcon
        id={`menu_dept_destacados_${index}`}
        className="cardIcon-container"
        onClick={navigateTo}
    >
      <CardIcon icon={data.icon} url={data.url}/>

      <StyledBox className="cardIcon-styledBox-container">
        <StyledTypography variant="bodyBaseline" className="cardIcon-styledBox-typography">
          {data.title}
        </StyledTypography>
      </StyledBox>
    </StyledCardIcon>
  );
};

export default CardIcon;
