import { StyledBox } from "@hcl-commerce-store-sdk/react-component";
import React, { FC } from "react";
import styled from "styled-components";
import { StyledTypography } from "../../../hdm";

interface ICustomStepperLaberlProps {
  stepNumber: number;
  sideText?: string;
//   variant: Tvariant;
//   disabled?: boolean;
//   onClick?: () => void;
//   type?: string;
}


const StepperLabelContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
        display: flex;
        flex-direction: row;
        align-items: center;

        .step-number-border{
            border: 1px solid ${theme.palette.primary.main};
            border-radius: 50%;
            color: ${theme.palette.primary.main};
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            margin-right: ${theme.spacing(1)}px;;
        }
    `}
`;

const CustomStepperLabel: FC<ICustomStepperLaberlProps> = ({ stepNumber, sideText= "" }) => {
  return (
    <>
     <StepperLabelContainer>
        <StyledBox className="step-number-border">
            <span>
                {stepNumber}
            </span>
        </StyledBox>
        <StyledTypography variant="bodySubText" weight="semibold">
            {sideText}
        </StyledTypography>
     </StepperLabelContainer>
    </>
  );
};

export default CustomStepperLabel;
