import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { StyledBox, StyledGrid, StyledTypography } from "../../elements";
import { DownloableTYPContainer } from "./OrderConfirmationContainer";
import { Trans, useTranslation } from "react-i18next";
import Barcode from "react-barcode";

import LogoHDM from "../../../assets/homedepot/images/TheHomeDepot.png";
import HomeDepotLogoProBlack from "../../../assets/homedepot/images/TheHomeDepotProBlack.svg";
import OxxoPayIcon from "../../../assets/homedepot/images/oxxo-pay.png";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { formatProductPrice } from "../../functions/utils";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSite } from "../../../_foundation/hooks/useSite";
import { BadgeIcon } from "../../components/svg-icons";
import { palette } from "../../themes";
import { EMPTY_STRING } from "../../../constants/common";
import { StoreMallDirectory } from "@material-ui/icons";

interface IDownloadTypProps {
  addressDetails: any[] | any;
  orderData: any[] | any;
}

const DownloadTYP: FC<IDownloadTypProps> = ({ orderData, addressDetails }) => {
  const { t } = useTranslation();
  const storeConfData = useSelector(onlineStoreRdc);
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const isCreditAllowed = isB2B && !!orderData?.paymentInstruction?.[0]?.protocolData?.find(
    (data) => data?.name == "isCreditAllowed" && data?.value == "true"
  );

  const xSplitShippingMethod = orderData?.x_split_shippingMethod;
  const orderInProcessEspot = `OrderInProcess-mail-${
    xSplitShippingMethod == "2" ? "HomeDelivery" : xSplitShippingMethod == "1" ? "Bopis" : "Split"
  }-content${isCreditAllowed ? "-AR" : EMPTY_STRING}`;

  /*== Download TYP data ==*/
  const orderDate = orderData["x_lastUpdate"];
  const formattedOrderDate = new Date(orderData?.lastUpdateDate).toLocaleDateString("en-GB");
  // const orderHour = orderDate ? orderDate.split(" ").slice(3,5).join(" ") : null;

  const currentTime = new Date();
  let hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  let meridiem = "AM";

  if (hours >= 12) {
    meridiem = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  const orderHour =
    minutes < 10
      ? hours < 10
        ? `0${hours}:0${minutes} ${meridiem}`
        : `${hours}:0${minutes} ${meridiem}`
      : hours < 10
      ? `0${hours}:${minutes} ${meridiem}`
      : `${hours}:${minutes} ${meridiem}`;

  let totalDiscounts= 0;
  const taxDivFactor = orderData?.orderItem[0]?.xitem_taxRate
    ? orderData?.orderItem[0]?.xitem_taxRate / 100 + 1
    : storeConfData.TAX_DIVISION_FACTOR;
  // The promotion taxes value is not registered in the order or orderittems rest
  totalDiscounts = 0;
  orderData?.orderItem?.forEach((orderItem) => {
    if (parseFloat(orderItem.salesTax) === 0) {
      // Add tax to discount quantity if the product has "Artículo de Regalo" as promotion
      if(orderItem?.adjustment?.length > 0) {
        let hasGift = false;
        orderItem?.adjustment?.forEach(adjustment => {
          if(adjustment?.description === "Artículo de Regalo") {
            totalDiscounts = totalDiscounts + adjustment?.amount * -1;
            hasGift = true;
          }
        });
        if(hasGift) return;
      }

      totalDiscounts = totalDiscounts + orderItem.totalAdjustment.value * -1;
          
    } else {
      totalDiscounts = totalDiscounts + orderItem.totalAdjustment.value * taxDivFactor * -1;
    }
  });

  const subtotal =
    parseFloat(orderData.totalProductPrice) +
    parseFloat(orderData.totalSalesTax) +
    parseFloat(orderData.totalAdjustment) +
    totalDiscounts;

  const grandTotal = formatProductPrice(orderData?.grandTotal);
  const shippingPrice = formatProductPrice(parseFloat(orderData?.totalShippingCharge) + parseFloat(orderData?.totalShippingTax));
  const saleTaxes = orderData?.totalSalesTax;
  const shippingTaxes = orderData?.totalShippingTax;
  const totalTaxes = formatProductPrice(parseFloat(saleTaxes) + parseFloat(shippingTaxes));
  const [load, setLoad] = useState(false);

  let paymentMethod = orderData?.paymentInstruction?.[0]?.payMethodId;

  useEffect(() => {
    setInterval(() => setLoad(true), 100);
  }, []);

  //is paymethod is VISA, MasterCArd, amex or carnet
  if (["VISA", "Master Card", "AMEX", "CARNET"].includes(paymentMethod)) {
    paymentMethod = "CyberSource";
  }

  /*=== Oxxo data ====*/
  const oxxoPayHasTwoReferences =
    orderData?.paymentInstruction?.[0]?.protocolData &&
    paymentMethod === "Oxxo" &&
    orderData?.paymentInstruction.length === 2
      ? true
      : false;

  // const oxxoPayBarcode = orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo"
  //                         ? orderData?.paymentInstruction?.[0]?.protocolData.filter(elem => elem.name === "barcodeUrl")[0].value
  //                         : "";

  const oxxoPayReference =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "paymentReference")?.[0]?.value
      : "";

  const oxxoPayReferenceOneAmount =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo"
      ? formatProductPrice(
          orderData?.paymentInstruction?.[0]?.protocolData?.filter((elem) => elem.name === "transactionAmount")?.[0]
            ?.value
        )
      : "";

  const oxxoPaySecondReference =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo" && oxxoPayHasTwoReferences
      ? orderData?.paymentInstruction?.[1]?.protocolData.filter((elem) => elem.name === "paymentReference")[0].value
      : "";

  const oxxoPayReferenceTwoAmount =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo" && oxxoPayHasTwoReferences
      ? formatProductPrice(
          orderData?.paymentInstruction?.[1]?.protocolData.filter((elem) => elem.name === "transactionAmount")[0].value
        )
      : "";

  const oxxoPayExpireDate =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "Oxxo"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "responseExpiryDate")?.[0]
          ?.value
      : "";

  const formattedOxxoPayExpireDate = oxxoPayExpireDate
    ? new Date(oxxoPayExpireDate).toLocaleDateString("es-GB", { day: "numeric", month: "short", year: "numeric" })
    : "";

  /*=== OpenPay data ====*/
  const agreementNumber =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "OpenPay"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "agreement")[0].value
      : "";

  const referenceNumber =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "OpenPay"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "name")[0].value
      : "";

  const openPayTargetBank =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "OpenPay"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "bank")[0].value
      : "";

  const openPayBankClabe =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "OpenPay"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "clabe")[0].value
      : "";

  /*=== PayPal data ====*/
  const payPalAuthorizationCode =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "PayPal"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "authorizationCode")[0]?.value
      : "";

  const payPalTransactionDate =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "PayPal"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "transactionDate")[0]?.value
      : "";

  const formattedPaypalTrnsDate = payPalTransactionDate
    ? new Date(payPalTransactionDate).toLocaleString("es-GB", {
        timeZone: 'UTC',
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        hourCycle: "h24",
      })
    : "";
  /*=== Credit / Debit card data ====*/
  const cardNumber =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "CyberSource"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "cardNo")[0].value
      : "";

  const cardLastFourDigits = cardNumber.toString().slice(cardNumber.length - 4, cardNumber.length);

  const cardNameHolder =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "CyberSource"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "cardHolderName")[0].value
      : "";

  const cardAffiliationID =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "CyberSource"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "AffiliationID")[0].value
      : "";

  const cardAuthorizationCode =
    orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "CyberSource"
      ? orderData?.paymentInstruction?.[0]?.protocolData.filter((elem) => elem.name === "authorizationCode")[0].value
      : "";

  /*=== Shipping data ====*/
  const userName =
    (orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "PayPal") || paymentMethod === "CyberSource"
      ? orderData?.["x_pickUpPerson"]
      : "";
  const userTelephone =
    (orderData?.paymentInstruction?.[0]?.protocolData && paymentMethod === "PayPal") || paymentMethod === "CyberSource"
      ? orderData?.["x_pickUpContact"]
      : "";

  const userEMail =
    (orderData?.paymentInstruction?.[0]?.protocolData &&
      orderData?.paymentInstruction?.[0]?.email1 &&
      paymentMethod === "PayPal") ||
    paymentMethod === "CyberSource"
      ? orderData?.paymentInstruction?.[0]?.email1
      : addressDetails?.contactList?.[addressDetails?.contactList?.length - 1]?.email1
      ? addressDetails?.contactList?.[addressDetails?.contactList?.length - 1]?.email1
      : "";

  const userNameEspot = addressDetails?.firstName
  ? addressDetails.firstName + " " + addressDetails?.lastName
  : orderData?.paymentInstruction[0]?.firstName
  ? orderData?.paymentInstruction[0]?.firstName + " " + orderData?.paymentInstruction[0]?.lastName
  : orderData?.x_pickUpPerson;
  const userNameEspotEnconde = encodeURI(userNameEspot); 
  // const barCode =   document.getElementById('oxxobarcode');

  // console.log("OrdData", orderData, oxxoPayReferenceOneAmount, oxxoPayReferenceTwoAmount);
  console.log("OrdData", orderData);

  return (
    <>
      {paymentMethod === "Oxxo" ||
      paymentMethod === "PayInStore" ||
      paymentMethod === "PayPal" ||
      paymentMethod === "CyberSource" ||
      paymentMethod === "OpenPay" ? (
        <DownloableTYPContainer id="typ-download-pdf" className={load ? "hidePdf" : null} container>
          <StyledGrid container xs={12}>
            {/* LOGO HDM */}
            <StyledGrid item xs={2}>
              <img src={isB2B ? HomeDepotLogoProBlack : LogoHDM} alt="logo home depot" className="downloadble-typ-logo lazyload" />
            </StyledGrid>

            <StyledGrid item xs={10}>
              {/* Title */}
              <StyledGrid item xs={12}>
                {paymentMethod === "CyberSource" || paymentMethod === "PayPal" ? (
                  <EspotHdm espotName={"OrderInProcess-mail-title"} />
                ) : paymentMethod === "OpenPay" ? (
                  <EspotHdm espotName={"OrderPending-mail-PBT-title"} />
                ) : paymentMethod === "PayInStore" && !isCreditAllowed ? (
                  <EspotHdm espotName={`OrderPending-mail-PAS-title`} />
                ) : paymentMethod === "PayInStore" && isCreditAllowed ? (
                  <EspotHdm espotName={`PRO-OrderPendingApproval-mail-ARBuyer-title`} />
                ) : (
                  <EspotHdm espotName={"OrderPending-mail-Oxxo-title"} />
                )}
              </StyledGrid>
              {/* Order number and date + hour */}
              <StyledGrid item xs={12}>
                <StyledTypography variant="bodyBaseline" className="downloadble-typ-order-number">
                  {t("OrderConfirmation.OrderComplete.TYPDownload.OrderNumber")}
                  <StyledTypography component="span" variant="bodyBaseline" weight="semibold">
                    {orderData?.orderId}
                  </StyledTypography>
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" className="download-typ-order-date">
                  {t("OrderConfirmation.OrderComplete.TYPDownload.OrderRecieved")}
                  {` ${formattedOrderDate} | ${orderHour}`}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          
          {
            isB2B && orderData?.orgDistinguishedName && (
              <StyledGrid container xs={12} className="downloadble-typ-org-info vertical-margin-2">
                {/* Title */}
                <StyledGrid item xs={12}>
                  <StyledTypography variant="textButtons">
                    {t("OrderConfirmation.OrderComplete.TYPDownload.OrgInfo.Title")}
                    <StyledBox className="divider" />
                  </StyledTypography>
                </StyledGrid>

                <StyledBox display="flex" className="top-margin-2">
                  <StyledGrid className="right-margin-2">
                    <BadgeIcon fill={palette.gray["400"]}/>
                  </StyledGrid>
                  <StyledGrid>
                    <StyledTypography variant="bodyBaseline" className="bottom-margin-2">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.OrgInfo.Account")}
                      <StyledTypography component="span" variant="bodyBaseline">
                      {" #"}{orderData?.orgDistinguishedName?.split(",")[0]?.replace("o=", "")}
                      </StyledTypography>
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.OrgInfo.Org")}
                      {" "}
                      <StyledTypography component="span" variant="bodyBaseline" >
                        {orderData?.orgDistinguishedName?.split(",")[1]?.replace("o=", "")}
                      </StyledTypography>
                    </StyledTypography>
                  </StyledGrid>
                </StyledBox>

              </StyledGrid>
            )
          }

          <StyledGrid item xs={12}>
            {!isB2B && paymentMethod !== "Oxxo" && paymentMethod !== "OpenPay" && paymentMethod !== "PayInStore" ? (
              <>
                <StyledTypography variant="bodyBaseline" className="downloadble-typ-greetings">
                  <Trans
                    t={t}
                    i18nKey="OrderConfirmation.OrderComplete.TYPDownload.Greetings"
                    values={{
                      firstName: addressDetails?.firstName
                        ? addressDetails.firstName
                        : userName,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </StyledTypography>

                <StyledTypography variant="bodyBaseline" className="greetings-body">
                  <Trans
                    t={t}
                    i18nKey={
                      paymentMethod === "PayPal"
                        ? "OrderConfirmation.OrderComplete.TYPDownload.GreetingsBodyPaypal"
                        : paymentMethod === "CyberSource"
                        ? "OrderConfirmation.OrderComplete.TYPDownload.GreetingsBodyPaypal"
                        : "OrderConfirmation.OrderComplete.TYPDownload.GreetingsBody"
                    }
                    components={{ bold: <span /> }}
                  />
                </StyledTypography>
              </>
            ) : null}

            {!isB2B && (paymentMethod === "PayPal" || paymentMethod === "CyberSource") ? (
              <StyledTypography variant="bodyBaseline" className="paypal-greetings-subbody">
                <Trans
                  t={t}
                  i18nKey={
                    paymentMethod === "PayPal" || paymentMethod === "CyberSource"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.GreetingsBodySubPaypal"
                      : ""
                  }
                />
              </StyledTypography>
            ) : null}

            {(isB2B && !isCreditAllowed && 
              paymentMethod !== "Oxxo" &&
              paymentMethod !== "OpenPay" &&
              paymentMethod !== "PayInStore") && (
              <EspotHdm espotName={orderInProcessEspot + `?DM_SubstitutionName1=[Name]&DM_SubstitutionValue1=${
                userNameEspotEnconde
              }`} />
            )}

            {(isB2B && isCreditAllowed) && (
              <EspotHdm espotName={"PRO-OrderPendingApproval-mail-ARBuyer-content" + `?DM_SubstitutionName1=[Name]&DM_SubstitutionValue1=${
                userNameEspotEnconde
              }`} />
            )}

            {paymentMethod !== "PayPal" &&
            paymentMethod !== "CyberSource" &&
            paymentMethod !== "Oxxo" &&
            paymentMethod !== "OpenPay" &&
            paymentMethod !== "PayInStore" ? (
              <>
                {/* FIRST INSTRUCTION */}
                <StyledGrid item xs={12} className="downloadble-typ-instructions">
                  <StyledTypography variant="bodyBaseline" className="downloadble-type-instructions">
                    <StyledBox className="downloadble-typ-instructions-numbers" component="span">
                      1
                    </StyledBox>
                    <Trans
                      t={t}
                      i18nKey={
                        paymentMethod === "Oxxo"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.First"
                          : paymentMethod === "OpenPay"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.Instructions.First"
                          : paymentMethod === "PayInStore"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.PayInStore.Instructions.First"
                          : ""
                      }
                    />
                  </StyledTypography>
                </StyledGrid>

                {/* SECOND INSTRUCTION */}
                <StyledGrid item xs={12} className="downloadble-typ-instructions">
                  <StyledTypography variant="bodyBaseline" className="downloadble-type-instructions">
                    <StyledBox className="downloadble-typ-instructions-numbers" component="span">
                      2
                    </StyledBox>
                    <Trans
                      t={t}
                      i18nKey={
                        paymentMethod === "Oxxo"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.Second"
                          : paymentMethod === "OpenPay"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.Instructions.Second"
                          : paymentMethod === "PayInStore"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.PayInStore.Instructions.Second"
                          : ""
                      }
                    />
                  </StyledTypography>
                </StyledGrid>

                {/* THIRD INSTRUCTION */}
                {paymentMethod !== "OpenPay" ? (
                  <StyledGrid item xs={12} className="downloadble-typ-instructions">
                    <StyledTypography variant="bodyBaseline" className="downloadble-type-instructions">
                      <StyledBox className="downloadble-typ-instructions-numbers" component="span">
                        3
                      </StyledBox>
                      <Trans
                        t={t}
                        i18nKey={
                          paymentMethod === "Oxxo"
                            ? "OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.Third"
                            : paymentMethod === "PayInStore"
                            ? "OrderConfirmation.OrderComplete.TYPDownload.PayInStore.Instructions.Third"
                            : ""
                        }
                        components={{ orange: <span /> }}
                      />
                    </StyledTypography>
                  </StyledGrid>
                ) : null}
              </>
            ) : null}

            {/* PayInStore INSTRUCTIONS */}
            {paymentMethod === "PayInStore" ? (
              <EspotHdm
                espotName={`OrderPending-mail-PAS-content?DM_SubstitutionName1=[Name]&DM_SubstitutionValue1=${
                  userNameEspotEnconde
                }`}
              />
            ) : null}

            {/* OpenPay INSTRUCTIONS */}
            {paymentMethod === "OpenPay" ? (
              <EspotHdm
                espotName={`OrderPending-mail-PBT-content?DM_SubstitutionName1=[Name]&DM_SubstitutionValue1=${
                  userNameEspotEnconde
                }`}
              />
            ) : null}

            {/* OXXO INSTRUCTIONS */}
            {paymentMethod === "Oxxo" ? (
              <EspotHdm
                espotName={`OrderPending-mail-Oxxo-content?DM_SubstitutionName1=[Name]&DM_SubstitutionValue1=${
                  userNameEspotEnconde
                }`}
              />
            ) : null}

            {/* REFERENCES | PAYMENT METHOD */}
            <StyledGrid container xs={12} className={`download-typ-references`}>
              {/* Title */}
              <StyledGrid item xs={12}>
                <StyledTypography variant="textButtons">
                  {t(
                    paymentMethod === "PayPal" || paymentMethod === "CyberSource"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.PayPal.ReferencesForPaying.Title"
                      : "OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Title"
                  )}
                  {paymentMethod === "Oxxo" && oxxoPayHasTwoReferences ? (
                    <img src={OxxoPayIcon} alt="oxxopay logo" className="oxxopay-doublereferences-icon lazyload" />
                  ) : null}
                  <StyledBox className="divider" />
                </StyledTypography>
              </StyledGrid>
              {/* Order data */}
              <StyledGrid
                container
                xs={
                  paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences
                    ? 3
                    : paymentMethod === "Oxxo" && oxxoPayHasTwoReferences
                    ? 6
                    : paymentMethod === "OpenPay"
                    ? 6
                    : paymentMethod === "PayInStore" && !isCreditAllowed
                    ? 12
                    : paymentMethod === "PayInStore" && isCreditAllowed
                    ? 6
                    : paymentMethod === "PayPal"
                    ? 12
                    : paymentMethod === "CyberSource"
                    ? 6
                    : 3
                }
                className={`order-data ${paymentMethod === "CyberSource" ? "visa-references" : ""}`}>
                <StyledTypography
                  variant="bodySubText"
                  weight={paymentMethod === "OpenPay" || paymentMethod === "PayInStore" ? "bold" : ""}>
                  {t(
                    paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences
                      ? "OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.PaymentMethod"
                      : paymentMethod === "OpenPay"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPaying.PaymentMethod"
                      : paymentMethod === "PayInStore" && !isCreditAllowed
                      ? "OrderConfirmation.OrderComplete.TYPDownload.PayInStore.ReferencesForPaying.PaymentMethod"
                      : paymentMethod === "PayInStore" && isCreditAllowed
                      ? "OrderConfirmation.OrderComplete.TYPDownload.CreditAr.ReferencesForPaying.PaymentMethod"
                      : paymentMethod === "PayPal"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.PayPal.ReferencesForPaying.PaymentMethod"
                      : paymentMethod === "CyberSource"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.ReferencesForPaying.PaymentMethod"
                      : ""
                  )}
                </StyledTypography>
                <StyledTypography
                  variant="bodySubText"
                  className={
                    paymentMethod === "CyberSource"
                      ? "debitcreditcard-subtitle"
                      : paymentMethod === "PayPal"
                      ? "paypal-authnumber"
                      : paymentMethod === "PayInStore"
                      ? "payinstore-references"
                      : ""
                  }>
                  {t(
                    paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences
                      ? "OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Expires"
                      : paymentMethod === "OpenPay"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPaying.AgreementNumber"
                      : paymentMethod === "PayInStore" && !isCreditAllowed
                      ? "OrderConfirmation.OrderComplete.TYPDownload.PayInStore.ReferencesForPaying.PayInAnyStore"
                      : paymentMethod === "PayInStore" && isCreditAllowed
                      ? "OrderConfirmation.OrderComplete.TYPDownload.CreditAr.ReferencesForPaying.Total"
                      : paymentMethod === "PayPal"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.PayPal.ReferencesForPaying.AutorizationNumber"
                      : paymentMethod === "CyberSource"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.ReferencesForPaying.Subtitle"
                      : ""
                  )}
                  {paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences ? (
                    <span>[{formattedOxxoPayExpireDate}]</span>
                  ) : paymentMethod === "OpenPay" ? (
                    <span>{agreementNumber}</span>
                  ) : paymentMethod === "PayPal" ? (
                    <span>{payPalAuthorizationCode}</span>
                  ) : paymentMethod === "PayInStore" && isCreditAllowed ? (
                    <span>{grandTotal}</span>
                  ) : (
                    ""
                  )}
                </StyledTypography>

                {/* OXXO PAY TWO REFERENCES CONTAINER 1 */}
                {paymentMethod === "Oxxo" && oxxoPayHasTwoReferences ? (
                  <>
                    <StyledGrid container xs={12} className="oxxopay-two-references-container">
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.PayMethod")}
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.ReferenceOne")}
                        <span>{oxxoPayReference}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.amount")}
                        <span>{oxxoPayReferenceOneAmount}</span>
                      </StyledTypography>

                      <StyledBox item xs={12} className="oxxopay-barcode-container">
                        <Barcode value={oxxoPayReference} displayValue={false} marginBottom={-10} height={100} />
                      </StyledBox>
                    </StyledGrid>
                  </>
                ) : null}

                {paymentMethod === "OpenPay" || paymentMethod === "PayPal" ? (
                  <StyledTypography
                    variant="bodySubText"
                    className={paymentMethod === "PayPal" ? "paypal-transaction-date" : ""}>
                    {t(
                      paymentMethod === "OpenPay"
                        ? "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPaying.Reference"
                        : paymentMethod === "PayPal"
                        ? "OrderConfirmation.OrderComplete.TYPDownload.PayPal.ReferencesForPaying.TransactionDate"
                        : ""
                    )}
                    <span>{paymentMethod === "PayPal" ? formattedPaypalTrnsDate : referenceNumber}</span>
                  </StyledTypography>
                ) : null}

                <StyledTypography
                  variant="bodySubText"
                  className={paymentMethod === "CyberSource" ? "debitcreditcard-data-lastfourdigits" : ""}>
                  {t(
                    paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences
                      ? "OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Total"
                      : paymentMethod === "OpenPay"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Total"
                      : paymentMethod === "CyberSource"
                      ? "OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.ReferencesForPaying.CardInfo"
                      : ""
                  )}
                  {paymentMethod !== "PayInStore" && paymentMethod !== "PayPal" && !oxxoPayHasTwoReferences ? (
                    <span>{paymentMethod === "CyberSource" ? cardLastFourDigits : grandTotal}</span>
                  ) : null}
                </StyledTypography>

                {paymentMethod === "CyberSource" ? (
                  <>
                    <StyledTypography variant="bodySubText">
                      {t(
                        paymentMethod === "CyberSource"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.ReferencesForPaying.CardName"
                          : ""
                      )}
                      {paymentMethod === "CyberSource" ? <span>{cardNameHolder}</span> : ""}
                    </StyledTypography>

                    <StyledTypography variant="bodySubText">
                      {t(
                        paymentMethod === "CyberSource"
                          ? "OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.ReferencesForPaying.PaymentDeadline"
                          : ""
                      )}
                      {paymentMethod === "CyberSource" ? <span>{"Una sola exhibición"}</span> : ""}
                    </StyledTypography>
                  </>
                ) : paymentMethod === "OpenPay" ? (
                  <>
                    <StyledTypography variant="bodySubText" className="openpay-helpsection-disclaimer">
                      {t(
                        "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPaying.HelpSectionDisclaimer"
                      )}
                    </StyledTypography>
                  </>
                ) : null}
              </StyledGrid>

              {/* OXXO BARCODE */}
              {paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences ? (
                <StyledGrid container xs={4} className="oxxo-barcode">
                  <Barcode value={oxxoPayReference} displayValue={false} marginBottom={-10} height={100} />
                </StyledGrid>
              ) : null}

              {/* Reference */}
              <StyledGrid
                container
                xs={
                  paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences
                    ? 5
                    : paymentMethod === "Oxxo" && oxxoPayHasTwoReferences
                    ? 6
                    : paymentMethod === "OpenPay"
                    ? 6
                    : paymentMethod === "CyberSource"
                    ? 6
                    : paymentMethod === "PayInStore" && isCreditAllowed
                    ? 6
                    : 3
                }
                className={`oxxo-reference openpay-reference debitcreditcard-reference 
                                    ${
                                      paymentMethod === "OpenPay"
                                        ? "left-divider"
                                        : paymentMethod === "Oxxo" && oxxoPayHasTwoReferences
                                        ? "left-divider"
                                        : ""
                                    }`}>
                {paymentMethod === "Oxxo" && !oxxoPayHasTwoReferences ? (
                  <>
                    <StyledGrid item xs={12}>
                      <img src={OxxoPayIcon} alt="oxxopay logo" className="lazyload" />
                    </StyledGrid>
                    <StyledGrid item xs={12} className="reference-header">
                      <StyledTypography variant="bodyBaseline">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Reference")}
                      </StyledTypography>
                      <StyledTypography variant="bodyBaseline">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.ReferencesForPaying.Import")}
                        <span>{grandTotal}</span>
                      </StyledTypography>
                    </StyledGrid>
                    {/* Reference box */}
                    <StyledGrid item xs="12" className="reference-box">
                      {oxxoPayReference}
                    </StyledGrid>
                  </>
                ) : paymentMethod === "CyberSource" ? (
                  <>
                    <StyledGrid item xs={12} className="debitcreditcard-storeinfo-container">
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.StoreInfo.Title")}
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.StoreInfo.CommerceName")}
                        <span>{"The Home Depot México"}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.StoreInfo.MembershipNumber")}
                        <span>{cardAffiliationID}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.DebitCreditCard.StoreInfo.AuthorizationNumber")}
                        <span>{cardAuthorizationCode}</span>
                      </StyledTypography>
                    </StyledGrid>
                  </>
                ) : paymentMethod === "Oxxo" && oxxoPayHasTwoReferences ? (
                  <>
                    {/* OXXO PAY TWO REFERENCES CONTAINER 1 */}
                    <StyledGrid container xs={12} className="oxxopay-two-references-container2">
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.PayMethod")}
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.ReferenceTwo")}
                        <span>{oxxoPaySecondReference}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OxxoInstructions.amount")}
                        <span>{oxxoPayReferenceTwoAmount}</span>
                      </StyledTypography>

                      <StyledBox item xs={12} className="oxxopay-barcode-container">
                        <Barcode value={oxxoPaySecondReference} displayValue={false} marginBottom={-10} height={100} />
                      </StyledBox>
                    </StyledGrid>
                  </>
                ) : null}

                {paymentMethod === "PayInStore" && isCreditAllowed && (
                  <>
                    <StyledGrid item xs={12} className="debitcreditcard-storeinfo-container">
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.CreditAr.StoreInfo.Title")}
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.CreditAr.StoreInfo.CommerceName")}
                        <span>{"The Home Depot México"}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.CreditAr.StoreInfo.Date")}
                        <span>{`${formattedOrderDate} ${orderHour}`}</span>
                      </StyledTypography>
                    </StyledGrid>
                  </>
                )}

                {paymentMethod === "OpenPay" ? (
                  <>
                    <StyledGrid item xs={12} className="openpay-right-section-references">
                      <StyledTypography variant="bodySubText" weight="bold">
                        {t(
                          "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.PaymentMethod"
                        )}
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t(
                          "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.beneficiary"
                        )}
                        <span>{"The Home Depot México"}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t(
                          "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.TargetBank"
                        )}
                        <span>{openPayTargetBank}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.Clabe")}
                        <span>{openPayBankClabe}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t(
                          "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.PaymentConcept"
                        )}
                        <span>{referenceNumber}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t(
                          "OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.Reference"
                        )}
                        <span>{referenceNumber}</span>
                      </StyledTypography>
                      <StyledTypography variant="bodySubText">
                        {t("OrderConfirmation.OrderComplete.TYPDownload.OpenPay.ReferencesForPayingOtherBanks.Total")}
                        <span>{grandTotal}</span>
                      </StyledTypography>
                    </StyledGrid>
                  </>
                ) : null}
              </StyledGrid>
            </StyledGrid>

            {/* SHIPPING DATA */}
            {paymentMethod === "PayPal" || paymentMethod === "CyberSource" ? (
              <>
                <StyledGrid container xs={12} className="shipping-data-container">
                  {/* Title */}
                  <StyledGrid item xs={12} className="shippment-data-title">
                    <StyledTypography variant="textButtons">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.Shipping.Title")}
                      <StyledBox className="divider" />
                    </StyledTypography>
                  </StyledGrid>

                  {
                    (xSplitShippingMethod == "2" || xSplitShippingMethod == "3") && (
                      <>
                        <StyledGrid item xs={1}>
                          <LocalShippingIcon color="primary" className="shipping-icon" />
                        </StyledGrid>
                        <StyledGrid item xs={11} className="shipping-data-section section-one">
                          <StyledTypography variant="bodyBaseline" weight="semibold">
                            {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.Shipping.ShippingMethod")}
                          </StyledTypography>
                          <StyledTypography variant="bodyBaseline">
                            {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.Shipping.ShippingInstructions")}
                          </StyledTypography>
                          <StyledTypography variant="bodyBaseline">
                            <Trans t={t} i18nKey="OrderConfirmation.OrderComplete.TYPDownload.PayPal.Shipping.ScheduleTime" />
                          </StyledTypography>
                        </StyledGrid>
                      </>
                    )
                  }

                  {
                    (xSplitShippingMethod == "1" || xSplitShippingMethod == "3") && (
                      <>
                        <StyledGrid item xs={1}>
                          <StoreMallDirectory color="primary" className="shipping-icon" />
                        </StyledGrid>
                        <StyledGrid item xs={11} className="shipping-data-section section-one">
                          <StyledTypography variant="bodyBaseline" weight="semibold">
                            {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.Bopis.Title")}
                          </StyledTypography>
                          <StyledTypography variant="bodyBaseline">
                            {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.Bopis.ShippingInstructions")}
                          </StyledTypography>
                        </StyledGrid>
                      </>
                    )
                  }

                  <StyledGrid item xs={1}>
                    <AccountCircleIcon color="primary" className="shipping-icon" />
                  </StyledGrid>
                  <StyledGrid item xs={11} className="shipping-data-section section-two">
                    <StyledTypography variant="bodyBaseline" weight="semibold">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.PersonWhoReceives.Title")}
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.PersonWhoReceives.Name")}
                      <span>{userName}</span>
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.PersonWhoReceives.Telephone")}
                      <span>{userTelephone}</span>
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderConfirmation.OrderComplete.TYPDownload.PayPal.PersonWhoReceives.Email")}
                      <span>{userEMail}</span>
                    </StyledTypography>
                  </StyledGrid>
                </StyledGrid>
              </>
            ) : null}

            {/* ORDER RESUME */}
            <StyledGrid container xs={12} className="download-typ-order-resume">
              {/* Title */}
              <StyledGrid item xs={12}>
                <StyledTypography variant="textButtons">
                  {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.Title")}
                  <StyledBox className="divider" />
                </StyledTypography>
              </StyledGrid>
              {/* Order data */}
              <StyledGrid container xs={6} className="download-typ-order-data">
                {/* Left section */}
                <StyledGrid item xs={6} className="order-resume-left-section">
                  <StyledTypography variant="bodySubText">
                    {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.TotalProducts")}
                  </StyledTypography>
                  <StyledTypography variant="bodySubText">
                    {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.Saving")}
                  </StyledTypography>
                  <StyledTypography variant="bodySubText">
                    {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.Shipping")}
                  </StyledTypography>
                  <StyledTypography variant="bodySubText" weight="semibold">
                    {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.TotalOrder")}
                  </StyledTypography>
                  <StyledTypography variant="bodyCaption">
                    {`${t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.Taxes")} ${totalTaxes}`}
                  </StyledTypography>
                </StyledGrid>

                {/* Right section */}
                <StyledGrid item xs={6} className="order-resume-right-section">
                  <StyledTypography variant="bodySubText">{formatProductPrice(subtotal)}</StyledTypography>
                  <StyledTypography variant="bodySubText" className="order-resume-right-section-savings">
                    {formatProductPrice(totalDiscounts)}
                  </StyledTypography>
                  <StyledTypography variant="bodySubText">{shippingPrice}</StyledTypography>
                  <StyledTypography
                    variant="bodySubText"
                    weight="semibold"
                    className="order-resume-right-section-grandtotal">
                    {grandTotal}
                  </StyledTypography>
                </StyledGrid>
              </StyledGrid>
              {/* CheckIn legend */}
              <StyledGrid container xs={6} className="download-typ-order-data-checkin">
                <StyledTypography variant="bodySubText">
                  {t("OrderConfirmation.OrderComplete.TYPDownload.OrderResume.CheckIn")}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </DownloableTYPContainer>
      ) : null}
    </>
  );
};

export default DownloadTYP;
