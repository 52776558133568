import { Divider } from "@material-ui/core";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { StyledContainerHDM, StyledHeaderHDM } from "../../../../header/Header";
import { SLFooterElements } from "../dimensions/Footer";
import { StyledSkeleton } from "../StyledSkeleton";

const SLHeadetTop = () => {
    return (
        <>
            <StyledGrid container>
                <StyledGrid item xs={1}>
                    <StyledSkeleton variant="rect" width={30} height={30} />
                </StyledGrid>
                <StyledGrid container item xs={1} direction="column">
                    <StyledSkeleton variant="rect" width={30} height={10} />
                    <StyledSkeleton variant="rect" width={30} height={10} />
                </StyledGrid>
                <StyledGrid item xs={8}>
                    <StyledSkeleton variant="rect" width={200} height={30} />
                </StyledGrid>
                <StyledGrid container item xs={1} direction="column">
                    <StyledSkeleton variant="rect" width={20} height={20} />
                    <StyledSkeleton variant="rect" width={30} height={10} />
                </StyledGrid>
                <StyledGrid container item xs={1} direction="column">
                    <StyledSkeleton variant="rect" width={20} height={20} />
                    <StyledSkeleton variant="rect" width={30} height={10} />
                </StyledGrid>
            </StyledGrid>
        </>

    );
}
const SLHeadetBottom = () => {
    return (
        <StyledGrid container spacing={2} direction="row">
            <StyledGrid container item xs={12}>
                <StyledSkeleton variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
            </StyledGrid>

        </StyledGrid>
    );
}
//footer cards
export const SLHeader = () => {
    return (
        <StyledHeaderHDM>
            <StyledContainerHDM id="Main_Header" className="bottom-padding-2">
                <StyledContainer id={"Header"}>
                        <StyledGrid container spacing={2}>
                            <StyledGrid item xs={12}>
                                <SLHeadetTop />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <Divider className="vertical-margin-2" />
                            </StyledGrid>
                            <StyledGrid item xs={12}>
                                <SLHeadetBottom />
                            </StyledGrid>
                        </StyledGrid>
                </StyledContainer>
            </StyledContainerHDM>
        </StyledHeaderHDM>


    );
}

export const SLHeaderEspotMenu = () => {
    return (
        <StyledGrid container spacing={2} direction="row">
            <StyledGrid container item xs={12}>
                <StyledSkeleton variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
                <StyledSkeleton className={"horizontal-margin-2"} variant="rect" width={100} height={15} />
            </StyledGrid>

        </StyledGrid>
    );
}