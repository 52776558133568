import { Divider, Hidden, useMediaQuery } from "@material-ui/core";
import { StyledBox, StyledGrid } from "../../../../../hdm"
import theme from "../../../../../hdm/themes/thd";
import { StyledSkeleton } from "../StyledSkeleton"


export const SLProductCart = () => {
    const isMobile = !useMediaQuery(theme.breakpoints.up(960));
    console.log("SLProductCart is mobile", isMobile)
    return (
        <>
            {/* Datos de como sera la entrega */}
            <StyledGrid container item style={{ flexWrap: "nowrap", justifyContent: "start", height: "18px" }}>
                <StyledSkeleton variant="rect" width={152.61} height={18} />
                <Divider orientation="vertical" className="horizontal-margin-2" />
                <StyledGrid item xs={6} md={4} lg={5} style={{ justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={92.58} height={18} />
                </StyledGrid>
            </StyledGrid>

            <Divider className="top-margin-2" />

            {/* Datos del producto */}
            <StyledGrid container style={{ width: "inherit", borderBottom: "1px solid #EEEEEE" }}>
                {/* Datos principales */}
                <StyledGrid container item xs={8} style={{ padding: "10px", borderRight: "1px solid #EEEEEE", paddingBottom: "0px" }}>
                    {/* Imagen */}
                    <StyledGrid item style={{ marginBottom: "10px", paddingRight: "10px" }}>
                        <StyledSkeleton
                            variant="rect"
                            width={60}
                            height={60}
                        />
                    </StyledGrid>
                    {/* Nombre y SKU */}
                    <StyledGrid item style={{ width: "calc(100% - 70px)" }}>
                        <StyledGrid item style={{ marginBottom: "10px" }}>
                            <StyledSkeleton variant="rect" width={"inherit"} height={34} />
                        </StyledGrid>
                        <StyledGrid item>
                            <StyledSkeleton variant="rect" width={71.4} height={13.6} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
                {/* Precio */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>

            {/* Cantidad y subtotal */}
            <StyledGrid container style={{ width: "inherit", height: "56px", borderBottom: "1px solid #EEEEEE", marginBottom: "10px" }}>
                {/* Cantidad */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #EEEEEE" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
                {/* Espacio vacio */}
                <StyledGrid item xs={4} style={{ borderRight: "1px solid #EEEEEE" }}>
                </StyledGrid>
                {/* Subtotal */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>
        </>
    );
};

export const TotalSummary = () => {

    return (
        <>
            {/* Subtotal */}
            <StyledGrid container style={{ flexWrap: "nowrap" }} direction="row" justifyContent="space-between">
                <StyledGrid container direction="column" xs={6}>
                    <StyledSkeleton variant="rect" width={128.45} height={18} />
                </StyledGrid>
                <StyledGrid container item style={{ alignItems: "center", justifyContent: "right" }} xs={6}>
                    <StyledSkeleton variant="rect" width={66.74} height={18} />
                </StyledGrid>
            </StyledGrid>

            <StyledGrid xs={12}>
                <Divider className="vertical-margin-2" />
            </StyledGrid>

            {/* Total */}
            <StyledGrid container style={{ flexWrap: "nowrap" }} direction="row" justifyContent="space-between">
                <StyledGrid container direction="column" xs={6}>
                    <StyledGrid item className="bottom-margin-1">
                        <StyledSkeleton variant="rect" width={113.89} height={18} />
                    </StyledGrid>
                    <StyledGrid item>
                        <StyledSkeleton variant="rect" width={118.98} height={12} />
                    </StyledGrid>
                </StyledGrid>
                <StyledGrid container item style={{ alignItems: "center", justifyContent: "right" }} xs={6}>
                    <StyledSkeleton variant="rect" width={94.28} height={32.4} />
                </StyledGrid>
            </StyledGrid>
        </>
    );
};

export const SLFinalizePurchase = () => {
    return (
        <StyledGrid className="top-margin-4" style={{ background: "white", padding: "10px" }}>
            {/* Titulo */}
            <StyledGrid container item justifyContent="center" style={{ marginBottom: "15px" }}>
                <StyledSkeleton variant="rect" width={371.4} height={21.6} />
            </StyledGrid>

            {/* Informacion del metodo usado */}
            <StyledGrid item >
                <StyledSkeleton variant="rect" width={"100%"} height={80} />
            </StyledGrid>
        </StyledGrid>
    );
};

export const SLDeliveryMethod = () => {
    return (
        <StyledGrid className="top-margin-4" style={{ background: "white", padding: "10px" }}>
            {/* Titulo */}
            <StyledGrid container item justifyContent="center" style={{ marginBottom: "5px" }}>
                <StyledSkeleton variant="rect" width={151.11} height={21.6} />
            </StyledGrid>

            <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />

            {/* Contacto de entrega */}
            <StyledGrid item style={{ width: "100%" }}>
                {/* Titulo  */}
                <StyledGrid item style={{ heihgt: "18px" }}>
                    <StyledSkeleton variant="rect" width={145.03} height={18} />
                </StyledGrid>

                {/* correo */}
                <SLIconWithText textWidth={150} />

                {/* Telefono */}
                <SLIconWithText textWidth={83.43} />
            </StyledGrid>

            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

            {/* Contacto de entrega */}
            <StyledGrid item style={{ width: "100%", marginBottom:"10px" }}>
                {/* Titulo  */}
                <StyledGrid item style={{ heihgt: "18px" }}>
                    <StyledSkeleton variant="rect" width={130.04} height={18} />
                </StyledGrid>

                {/* Fecha */}
                <SLIconWithText textWidth={160.11} />

                {/* Direccion */}
                <StyledGrid item style={{ width: "100%", marginTop:"18px"}}>
                    <SLIconWithText textWidth={200} />
                </StyledGrid>
                <StyledGrid container item direction="row" alignItems="center" style={{ width:  "100%", height:"18px", paddingLeft: "20px" }}>
                    <StyledSkeleton variant="rect" width={"90%"} height={15} />
                </StyledGrid>
            </StyledGrid>

            {/* Informacion del metodo usado */}
            <StyledGrid item >
                <StyledSkeleton variant="rect" width={160.09} height={18} />
            </StyledGrid>
        </StyledGrid>
    );
};

export const SLIconWithText = ({textWidth}) => {
    return (
        <StyledGrid container item direction="row" alignItems="center" style={{ flexWrap: "nowrap", marginTop:"5px" }}>
            <StyledGrid container item direction="row" alignItems="center" style={{ width: "min-content" }}>
                <StyledSkeleton className={"right-margin-1"} variant="rect" width={15} height={15} />
            </StyledGrid>
            <StyledGrid container item direction="row" alignItems="center" style={{ paddingRight: "10px", height:"18px" }}>
                <StyledSkeleton variant="rect" width={textWidth} height={15} />
            </StyledGrid>
        </StyledGrid>
    );
};

export const SLPaymentMethod = () => {
    return (
        <StyledGrid className="top-margin-4" style={{ background: "white", padding: "10px" }}>
            {/* Titulo */}
            <StyledGrid container item justifyContent="center">
                <StyledSkeleton variant="rect" width={130.11} height={21.6} />
            </StyledGrid>

            <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />

            {/* Metodo usado */}
            <StyledGrid item style={{ width: "100%" }}>
                <StyledGrid item style={{ marginBottom: "6px" }}>
                    <StyledSkeleton variant="rect" width={115.59} height={16} />
                </StyledGrid>
                <StyledGrid item>
                    <StyledSkeleton variant="rect" width={"80%"} height={13} />
                </StyledGrid>
            </StyledGrid>

            {/* Informacion del metodo usado */}
            <StyledGrid item style={{ marginTop: "15px" }}>
                <StyledSkeleton variant="rect" width={"100%"} height={144} />
            </StyledGrid>
        </StyledGrid>
    );
};

export const SLThankYouLayout = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    console.log("isMobile typ",isMobile)
    return (
        <StyledGrid container spacing={2} className={"orderConfirmation-styledGrid-container MuiContainer-maxWidthLg"} style={{ margin: "-5px auto" }}>
            <StyledGrid id={"typ--title-container"} item xs={12}>
                {/* Back button */}
                <StyledBox display={"flex"} alignItems={"start"} height={"25.71px"} >
                    <StyledSkeleton variant="rect" noCard width={140} height={18} />
                </StyledBox>
                <StyledBox className={"top-margin-4"} display={"flex"} alignItems={"center"} justifyContent="center" width="100%" maxWidth="650">
                    <StyledSkeleton variant="rect" noCard width={"80%"} height={36} />
                </StyledBox>
            </StyledGrid>
            <StyledGrid item xs={12}>
                {/*Header Desktop*/}
                <StyledBox>
                    <StyledBox
                        display="flex" alignItems="center" justifyContent="center" height="auto" marginTop="20px"
                        flexWrap="wrap"
                        className="top-margin-4"
                    >
                        <StyledBox display="flex" alignItems="center" width={isMobile ? "100%" : "auto"}>
                            <StyledBox minWidth="250px" maxWidth="300px" width={isMobile ? "60%":"auto"}>
                                <StyledSkeleton noCard variant="rect" width={isMobile ? "100%" : 300} height={18} />
                            </StyledBox>
                        </StyledBox>

                        {isMobile
                            ?
                            <StyledBox className={"vertical-padding-2"} width="100%">
                                <Divider style={{ backgroundColor: "#CCCCCC"}} />
                            </StyledBox>
                            :
                            <StyledBox className={"horizontal-padding-2"}>
                                <Divider orientation="vertical" style={{ backgroundColor: "#CCCCCC", height: "15px" }} />
                            </StyledBox>
                        }

                        {/* correo */}
                        <StyledGrid container item direction="row" alignItems="center" style={{ flexWrap: "nowrap", marginTop:`${isMobile ? "5px" : ""}`,width: `${isMobile ? "100%" : "auto"}`}}>
                            <StyledGrid container item direction="row" alignItems="center" style={{ width: "min-content" }}>
                                <StyledSkeleton noCard className={"right-margin-1"} variant="rect" width={15} height={15} />
                            </StyledGrid>
                            <StyledGrid container item direction="row" alignItems="center" style={{height:"18px" }}>
                                <StyledSkeleton noCard variant="rect" width={150} height={15} />
                            </StyledGrid>
                        </StyledGrid>

                        {isMobile
                        ?
                            <></>
                        :
                            <StyledBox className={"horizontal-padding-2"}>
                                <Divider orientation="vertical" style={{ backgroundColor: "#CCCCCC", height: "15px" }} />
                            </StyledBox>
                        }

                        <StyledGrid container item direction="row" alignItems="center" style={{ flexWrap: "nowrap", marginTop:`${isMobile ? "5px" : ""}`, width: `${isMobile ? "100%" : "auto"}` }}>
                            <StyledGrid container item direction="row" alignItems="center" style={{ width: "min-content" }}>
                                <StyledSkeleton noCard className={"right-margin-1"} variant="rect" width={15} height={15} />
                            </StyledGrid>
                            <StyledGrid container item direction="row" alignItems="center" style={{height:"18px" }}>
                                <StyledSkeleton noCard variant="rect" width={83.43} height={15} />
                            </StyledGrid>
                        </StyledGrid>
                    </StyledBox>
                </StyledBox>
            </StyledGrid>


            <Hidden smDown>
                <StyledGrid xs={12} className={"vertical-padding-2"}>
                    <Divider style={{ backgroundColor: "#CCCCCC" }} />
                </StyledGrid>
            </Hidden>

            {/* Contenido inferior */}
            <StyledGrid item md={6} xs={12}>
                {/* Finaliza tu compra */}
                <SLFinalizePurchase/>

                {/* Productos */}
                <StyledGrid className="top-margin-4" style={{ background: "white", padding: "10px" }}>
                    {/* Titulo */}
                    <StyledGrid container item justifyContent="center">
                        <StyledSkeleton variant="rect" width={177.13} height={21.6} />
                    </StyledGrid>
                    <Divider className="vertical-margin-2" />
                    {/* Productos */}
                    <SLProductCart />
                </StyledGrid>

                {/* Summary */}
                <StyledGrid className="top-margin-1" style={{ background: "white", padding: "10px" }}>
                    <TotalSummary />
                </StyledGrid>
            </StyledGrid>

            {/* Seccion derecha */}
            <StyledGrid item md={6} xs={12}>
                {/* Metodo de entrega */}
                <SLDeliveryMethod />
                {/* Metodo de pago */}
                <SLPaymentMethod />
            </StyledGrid>

        </StyledGrid>
    )
}