import axios from "axios";
import inventoryavailabilityService from "../../_foundation/apis/transaction/inventoryavailability.service";
import storeLocatorService from "../../_foundation/apis/transaction/storeLocator.service";
import { InventoryStoreParams } from "./inventoryByStore";

const limitInventoryShow = 199;
const inventoryDetails: any = [];

const validateParameters = (parameters: InventoryStoreParams): boolean => {
  const isOnlineStoreId = parameters.onlineStoreId !== undefined && parameters.onlineStoreId !== "";
  const isPhysicalStoreId = parameters.physicalStoreId !== undefined && parameters.physicalStoreId.length > 0;

  if (!isOnlineStoreId || !isPhysicalStoreId) {
    throw new Error("onlineStoreId and physicalStoreId params are required.");
  }

  return true;
};

const getAvailableQty = (inventoryItem): string => {
  const invQty: string = inventoryItem.split(".")[0];
  // const availableQuantity: string = invQty;

  // if (parseInt(invQty) > limitInventoryShow) {
  //     availableQuantity = `${limitInventoryShow}`;
  // }

  return `${invQty}`;
};

const getAvailabilityByStores = async (parameters: InventoryStoreParams) => {
  parameters.siteLevelSearch = parameters.siteLevelSearch || "false";

  console.log("parametersQQQ", parameters)
  try {
    validateParameters(parameters);
    
    if (parameters.physicalStoreId.length > 0 && parameters.fullfillment_type !== "Special Order") {
      console.log("parametersQQQ", parameters)

      const {
        onlineStoreId,
        physicalStoreId,
        search,
        productIds,
        ...options
      } = parameters;

      let params 

      if(search){
        params = {
          onlineStoreId,
          search,
          physicalStoreId: parameters.physicalStoreId.join(","),
        }
      }else {
        params = {
          onlineStoreId,
          physicalStoreId: parameters.physicalStoreId.join(","),
        }

      }
      console.log("parameters", params)
      
      const urlInv = `/wcs/resources/store/10351/inventoryavailability/${productIds}?&langId=-5`;
      const InventoryStore: any = await axios.get(urlInv, {
      headers: {
          "Content-Type": "application/json",
      },
      params
      })
      .then((response) => {
     
        console.log("responseRRR", response)
        return response
     
      })
      .catch((error) => {
          console.log("errorLeadtime", "--", error);
      });


      // const InventoryStore: any = await inventoryavailabilityService.getInventoryAvailabilityByProductId({
      //   ...parameters,
      //   physicalStoreId: parameters.physicalStoreId.join(","),
      // });

      if (InventoryStore.status === 200 && InventoryStore?.data) {
        return InventoryStore.data?.InventoryAvailability;
      } else {
        return null;
      }
    }

    // const inventoryAvailabilityData: any = []
    // const response = {
    //     InventoryAvailabilityData: []
    // }
    // inventoryDetails.map((value, key) => {
    //     const data = { physicalStoreName: key, availableQuantity: value }
    //     inventoryAvailabilityData.push(data)
    //     return true
    // })

    // console.log("response:", inventoryAvailabilityData)

    // return inventoryAvailabilityData;
    return null;
  } catch (err) {
    console.log(" Something went wrong", err);
  }
};

const BundleBeanCase = (InventoryStore, physicalStoreName) => {
  let itemStoreInventory = "";

  InventoryStore.InventoryAvailability.forEach((itemInventory1) => {
    if (itemInventory1.physicalStoreName === physicalStoreName) {
      const qty = itemInventory1.availableQuantity;

      if (itemStoreInventory !== "") {
        if (parseInt(qty) < parseInt(itemStoreInventory)) {
          itemStoreInventory = qty;
        }
      } else {
        itemStoreInventory = qty;
      }
    }
  });

  //findme: HERE2
  return itemStoreInventory !== "" ? getAvailableQty(itemStoreInventory) : "0";
};

export { getAvailabilityByStores };
