import { StyledBox, StyledCardMedia, StyledGrid, StyledTypography } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

interface Props {
  productBoxData: any;
}

const ProductDataContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
  padding: 10px;
  margin-left: 10px;
  overflow: hidden;
  word-break: break-word;
  .gray300{
    color: ${theme.palette.gray["300"]}
  }
`}
`;

const ProductBoxInfo = (props: Props) => {
  const { thumbnail, manufacturer, name, partNumber, isInstalacion, skuInstallation, shortDescription, isTYP } =
    props.productBoxData;
  const storeConfData = useSelector(onlineStoreRdc);
  const imgUrl = storeConfData?.IMAGE_SERVER_HOSTNAME + "productos/" + partNumber + "/" + partNumber + "-m.jpg";
  if (isInstalacion) {
    if (isTYP) {
      return (
        <>
          <StyledBox display="flex" pr={1}>
            <StyledBox>
              <span className="material-symbols-sharp" style={{ color: palette.gray["200"], fontSize: 25 }}>
                check
              </span>
            </StyledBox>
            <StyledBox ml={1}>
              <StyledTypography variant={"bodyBaseline"} weight="semibold" component="span">
                Incluye Instalación de {name ? name.trim() : ""}
              </StyledTypography>
            </StyledBox>
          </StyledBox>
          <StyledTypography variant={"bodyBaseline"}>
            {shortDescription ? shortDescription.trim() : ""}
          </StyledTypography>
        </>
      );
    }

    return (
      <StyledBox>
        <StyledTypography variant={"bodySubText"} style={{ color: palette.gray["300"] }} component="span">
          ID: {partNumber ? partNumber.trim() : ""}
        </StyledTypography>
        <StyledTypography
          variant={"bodySubText"}
          component="span"
          style={{ marginLeft: "20px", color: palette.gray["300"] }}>
          SKU a instalar: #{skuInstallation ? skuInstallation.trim() : ""}
        </StyledTypography>
        <StyledBox className="product-title">
          <StyledTypography variant={"bodyBaseline"} weight="semibold">
            Instalación de {name ? name.trim() : ""}
          </StyledTypography>
          <StyledTypography variant={"bodyBaseline"}>
            {shortDescription ? shortDescription.trim() : ""}
          </StyledTypography>
        </StyledBox>
      </StyledBox>
    );
  }

  return (
    <StyledGrid item xs style={{ display: "flex" }}>
      <StyledGrid>
        <StyledCardMedia
          image={imgUrl}
          title="nombre"
          component="img"
          className="product-image lazyload"
          style={{ margin: 0, padding: 0, width: "60px", height: "60px" }}
        />
      </StyledGrid>
      <ProductDataContainer px={1}>
        <StyledBox maxHeight={"56px"} overflow={"hidden"}>
          <StyledTypography className="product-brand" variant="bodyBaseline" weight="semibold" component="span">
            {manufacturer ? manufacturer.trim() : ""}
          </StyledTypography>
          <StyledTypography className="product-name" variant={"bodyBaseline"} component="span">
            &nbsp;{name ? name.trim() : ""}
          </StyledTypography>
        </StyledBox>
        <StyledTypography variant={"bodySubText"} className="gray300 product-sku" component="span">
          SKU {partNumber ? partNumber.trim() : ""}
        </StyledTypography>
      </ProductDataContainer>
    </StyledGrid>
  );
};

export default ProductBoxInfo;
