/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
import React, { useMemo } from "react";
import { TableRow as SRTableRow } from "./table-row";
import { TableHeader as SRTableHeader } from "./table-header";
import { SRTable, SRThead, SRTbody, SRTr } from "../../../hdm/elements";
import { TableAction, TableConstants } from "../../types/super-responsive-table-types";
import { useCustomTable } from "./custom-table-context";
import { useEffect } from "react";
import { StyledPaper } from "../../elements";
import { useTableUtils } from "../../hooks";
import { CheckboxHeader } from "./checkbox-header-def";
import { CheckboxCell } from "./checkbox-cell-def";
import { PanelExpanderCell } from "./panel-expander-cell-def";
import TablePagination from "./table-pagination";
import { TableActions } from "./table-actions";
import { TableSelection } from "./table-selection";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { LoaderActions } from "../../../components/custom-components/skeleton-loader/actions/sl-actions";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { useSelector } from "react-redux";

interface CustomTableProps {
  data: any[];
  columns: any[];
  sortAction?: (props: any) => any;
  sortOrder?: "asc" | "desc";
  getActions?: (rData: any) => TableAction[];
  detailPanel?: any;
  [extraProps: string]: any;
}

const checkBoxHeader = () => {
  return {
    header: CheckboxHeader,
    headerClass: "hideOnMobile",
    title: "",
    keyLookup: {
      key: TableConstants.CHECKBOX,
    },
    display: {
      cellStyle: { verticalAlign: "middle" },
      cellClass: "hideOnMobile",
      template: CheckboxCell,
    },
  };
};

const panelExpanderHeader = ({ showPanelOnMobile }) => {
  return {
    headerClass: showPanelOnMobile ? "" : "hideOnMobile",
    title: "",
    keyLookup: {
      key: TableConstants.PANEL,
    },
    display: {
      cellStyle: { verticalAlign: "middle" },
      cellClass: showPanelOnMobile ? "" : "hideOnMobile",
      template: PanelExpanderCell,
    },
  };
};

/**
 * react-super-responsive-table implementation of a custom-table that can be used for tabular display
 *   this is a "new" implementation of a custom-table that is responsive as opposed to "sometimes" responsive
 * @param param0 properties used by table
 * @returns react-super-responsive-table implementation of a custom-table to be used in HCL react-store
 */
export const CustomTable: React.FC<CustomTableProps> = (props) => {
  const {
    checkBox,
    columns,
    data,
    sortOrder,
    sortAction,
    orderBy,
    detailPanel,
    showPanelOnMobile,
    disabled,
    t,
    labels,
    selectionActions,
    paginationData,
    actionData,
    outerClassName,
    ...others
  } = props;

  const { tableState, setTableState, currentData, setCurrentData } = useCustomTable();
  const { getRowKey } = useTableUtils();

  // do some initialization -- useEffect with empty deps will do this once
  useEffect(() => {
    data.forEach((row) => {
      const k = getRowKey(row, columns);
      if (!tableState[k]) {
        tableState[k] = {};
      }
      if (disabled && disabled[k]) {
        tableState[k][TableConstants.DISABLED] = true;
        tableState[TableConstants.HEADERS][TableConstants.DISABLED] = true;
      }
    });
    setTableState({ ...tableState });

    if (!paginationData || !paginationData.clientSide) {
      setCurrentData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, disabled]);

  const cbHeader = useMemo(() => checkBoxHeader(), []);
  const peHeader = useMemo(() => panelExpanderHeader({ showPanelOnMobile }), [showPanelOnMobile]);
  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);

  const loadSkeletonLoaderView = (miniCartView) => {
    // in case there are more scenarios add the corresponding view
    let Component = <></>
    if (miniCartView) {
      Component = loaders.secondary.miniCart.card
    } else {
      Component = loaders.secondary.cart.itemCard
    }

    return Component;
  };

  const shouldShowLoaderCard = (data)=>{
    const isItemInCart = data.filter(d=>{
      return d.partNumber === loaderState.idx || loaderState.idx === -1
    })

    return loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART && isItemInCart.length === 0
  }

  const validIndex = (idx) => {
    // if same index or general DELIVERY_TYPE_CHANGE
    return loaderState.idx === data[idx].orderItemId ||
           loaderState.idx === data[idx].partNumber ||
           loaderState.idx === -1
    
  }
  const validLoaders = () => {
    return loaderState.src === LoaderActions.PRODUCT_QUANTITY_UPDATE
      || loaderState.src === LoaderActions.DELIVERY_TYPE_CHANGE
      || loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART
  }
  const shouldShowLoader = (idx) => {
    return loaderState.open && validIndex(idx) && validLoaders()

  }

  return (
    <>
      {actionData ? (
        <>
          <StyledPaper
            className={"top-padding-2 horizontal-padding-2 productDetails--styledPaper-noBoxShadow"}
            elevation={0}>
            <TableActions {...{ t, actionData }} />
          </StyledPaper>
        </>
      ) : null}
      {tableState[TableConstants.SELECTION].count > 0 ? (
        <TableSelection {...{ t, labels, selectionActions, data, columns }} />
      ) : null}
      <SRTable {...others}>
        {Object.keys(tableState[TableConstants.HEADERS]).length > 0 ? (
          <SRThead>
            <SRTr key={`${tableState[TableConstants.HEADERS]}`}>
              {checkBox ? (
                <SRTableHeader
                  key={TableConstants.CHECKBOX}
                  tableColumn={cbHeader}
                  headers={columns}
                  fullTable={data}
                />
              ) : null}
              {detailPanel ? (
                <SRTableHeader key={TableConstants.PANEL} tableColumn={peHeader} headers={columns} fullTable={data} />
              ) : null}
              {/*columns.map((c, i) => {
                  const C = c.header;
                  return (<>
                    <SRTableHeader
                      key={c.keyLookup.key}
                      tableColumn={c}
                      fullTable={data}
                      headers={columns}
                      sortAction={sortAction}>
                      {c.header ? <C fullTable={data} headers={columns} /> : <>{c.title}</>}
                    </SRTableHeader>hola</>
                  );
                })*/}
            </SRTr>
          </SRThead>
        ) : null}
        <SRTbody>
          {currentData?.length === 0 ? (
            <SRTableRow
              key="message-displayer"
              rData={undefined}
              fullTable={data}
              tableExtras={{ checkBox, cbHeader, peHeader }}
              {...{ checkBox, detailPanel, columns, labels, t }}
            />
          ) : (
            <>
              {
                //new item is beign added to cart
                shouldShowLoaderCard(currentData) &&
                loaders.secondary.miniCart.card
              }
              {currentData?.map((row, index) => {
                const k = getRowKey(row, columns);
                return (
                  <>
                    {
                      shouldShowLoader(index)
                        ?
                        loadSkeletonLoaderView(others.c)
                        :
                        <>
                          <SRTableRow
                            key={`${k}_${tableState[k]}`}
                            rData={row}
                            fullTable={data}
                            tableExtras={{ checkBox, cbHeader, peHeader }}
                            {...{
                              showPanelOnMobile,
                              checkBox,
                              detailPanel,
                              columns,
                              labels,
                              t,
                            }}
                          />

                        </>

                    }

                  </>
                );
              })}
            </>
          )}
        </SRTbody>
      </SRTable>
      {paginationData ? <TablePagination {...{ ...paginationData, data }} /> : null}
    </>
  );
};
