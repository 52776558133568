import React from "react";
import { StyledButton } from "../../../../hdm/elements/button/styled-button";
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../../../hdm/elements/dialog";
import { StyledLinkButton } from "../styled-link-button/StyledLinkButton";
import { Hidden } from "@material-ui/core";

import { StyledTypography, StyledCircularProgress, StyledGrid } from "../../../../hdm/elements";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StyledDialogStore = (props) => {
  const {
    title,
    children,
    open,
    onConfirm,
    cancel,
    accept,
    onClose,
    isLoading,
    content1,
    content2,
    content3,
    onStoreSelect,
    terms,
    variantConfirmButton,
    alignConfirmButtons,
    isHomologatedPopUp
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1023));

  const validURLCsr = () => {
    const url = window.location.href;
    const keyWordCsr = "/csr/";
    
    if (isMobile && url.includes(keyWordCsr)) {
      return { bottom: "calc(100vh - 621px)" };
    } else {
      return null;
    }
  }

  const divStyle = validURLCsr();

  return (
    <StyledDialog style={divStyle} open={open} onClose={() => onClose()} aria-labelledby="confirm-dialog" 
    disableEnforceFocus isHomologatedPopUp={isHomologatedPopUp}
    className='dialog-choose-store-container'
    >
      <StyledDialogTitle title={title} onClickHandler={onClose}></StyledDialogTitle>
      <StyledDialogContent>
        <StyledTypography variant={"bodyBaseline"}>{children}</StyledTypography>
        <StyledGrid className={"styledDialog--content-subContent"}>
          {content1 && (
            <StyledTypography variant={"bodySubText"} className={"styledDialog--typography-secondary"}>
              {content1}
            </StyledTypography>
          )}
          {content2 && (
            <StyledTypography variant={"bodySubText"} className={"styledDialog--typography-secondary"}>
              {content2}
            </StyledTypography>
          )}
          {content3 && (
            <StyledTypography variant={"bodySubText"} className={"styledDialog--typography-secondary"}>
              {content3}
            </StyledTypography>
          )}
        </StyledGrid>
        {terms && (
          <StyledTypography variant="bodySubText" color="secondary" className={"styledDialog--link-terms"}>
            <Link to={terms.url} target="_blank" rel="noreferrer">
              {terms.text}
            </Link>
          </StyledTypography>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledGrid container>
          {alignConfirmButtons && alignConfirmButtons === "vertical" ? (
            <>
              <StyledGrid item xs={12} sm={12}>
                <StyledButton
                  variant={variantConfirmButton ? variantConfirmButton : "contained"}
                  onClick={() => {
                    onConfirm();
                  }}
                  style={isLoading ? { display: "none" } : { display: "" }}
                  color="primary"
                  className={"styledButton--style-buttonAccept"}>
                  {accept}
                </StyledButton>
                <StyledButton
                  disabled
                  variant={variantConfirmButton ? variantConfirmButton : "contained"}
                  style={!isLoading ? { display: "none" } : { display: "" }}
                  color="primary">
                  <StyledCircularProgress size={30} />
                </StyledButton>
                {cancel && (
                <Hidden xsDown>
                  <StyledGrid item xs={12} sm={12}>
                    <StyledLinkButton
                      variant="bodyBaseline"
                      color="secondary"
                      onClick={() => onStoreSelect()}
                      className={"styledLink--style-buttonLike"}>
                      {cancel}
                    </StyledLinkButton>
                  </StyledGrid>
                </Hidden>
                )}
              </StyledGrid>
              {cancel && (
              <Hidden smUp>
                <StyledGrid item xs={12} sm={12}>
                  <StyledLinkButton
                    variant="bodyBaseline"
                    color="secondary"
                    onClick={() => onStoreSelect()}
                    className={"styledLink--style-buttonLike"}>
                    {cancel}
                  </StyledLinkButton>
                </StyledGrid>
              </Hidden>
              )}
            </>
          ) : (
            <>
            {cancel && (
              <Hidden xsDown>
                <StyledGrid item xs={12} sm={6}>
                  <StyledLinkButton
                    variant="bodyBaseline"
                    color="secondary"
                    onClick={() => onStoreSelect()}
                    className={"styledLink--style-buttonLike"}>
                    {cancel}
                  </StyledLinkButton>
                </StyledGrid>
              </Hidden>
            )}
              <StyledGrid item xs={12} sm={6}>
                <StyledButton
                  variant={variantConfirmButton ? variantConfirmButton : "contained"}
                  onClick={() => {
                    onConfirm();
                  }}
                  style={isLoading ? { display: "none" } : { display: "" }}
                  color="primary">
                  {accept}
                </StyledButton>
                <StyledButton
                  disabled
                  variant={variantConfirmButton ? variantConfirmButton : "contained"}
                  style={!isLoading ? { display: "none" } : { display: "" }}
                  color="primary">
                  <StyledCircularProgress size={30} />
                </StyledButton>
              </StyledGrid>
              {cancel && (
              <Hidden smUp>
                <StyledGrid item xs={12} sm={6}>
                  <StyledLinkButton
                    variant="bodyBaseline"
                    color="secondary"
                    onClick={() => onStoreSelect()}
                    className={"styledLink--style-buttonLike"}>
                    {cancel}
                  </StyledLinkButton>
                </StyledGrid>
              </Hidden>
              )}
            </>
          )}
        </StyledGrid>
      </StyledDialogActions>
    </StyledDialog>
  );
};
export default StyledDialogStore;
