/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//FyF Login
export const FYF_LOGIN_REQUESTED = "FYF_LOGIN_REQUESTED";
export const FYF_LOGIN_SUCCESS = "FYF_LOGIN_SUCCESS";
export const FYF_LOGIN_ERROR = "FYF_LOGIN_ERROR";
