import styled from "styled-components";
import { CircleBackground, StyledBox, StyledCardEmpty, StyledTypography } from "../../elements";
import { palette } from "../../themes";
import { StyledAddIcon } from "../svg-icons";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const ContainerCardIconText = styled(({ ...props }) => <StyledCardEmpty {...props} />)`
  ${({ theme, padding, height, cursor, marginBottom }) => `

    
    cursor: ${cursor};
    margin-bottom: ${marginBottom};

    .styled--cardicontext-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: ${padding};
        height: ${height};
    }

    .styled--cardicontext-emptyWishList {
        max-width: 700px;
        margin: auto;
    }

    .styled--cardicontext-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: ${padding};
        height: 80px;
    }

`}
`;

export const CardIconText = (props) => {
  const {
    id,
    padding,
    height,
    cursor,
    handleShowCrearLista,
    icon,
    linkText,
    textOne,
    textOneDesc,
    textTwoDesc,
    cardIconTextClass,
    linktextClass,
    marginBottom,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContainerCardIconText
      // className={cardIconTextClass}
      id={id}
      padding={padding}
      height={height}
      cursor={cursor}
      marginBottom={marginBottom}
      onClick={handleShowCrearLista}>
      <StyledBox className={cardIconTextClass}>
        {textOne ? (
          <StyledTypography variant="bodyBaseline" fontWeight={600}>
            {textOne}
          </StyledTypography>
        ) : null}
        {textOneDesc && !isMobile && !textTwoDesc ? (
          <StyledTypography variant="bodyBaseline" className="color-gray300 bottom-padding-2 text-align-center">
            {textOneDesc}
          </StyledTypography>
        ) : null}
        {textTwoDesc ? (
          <StyledTypography variant="bodyBaseline" className="color-gray300 bottom-padding-2 text-align-center">
            {isMobile ? textOneDesc + textTwoDesc : textTwoDesc}
          </StyledTypography>
        ) : null}
        <CircleBackground
          width={icon.width}
          height={icon.height}
          backgroundColor={icon.backgroundColor}
          icon={icon.icon}
        />
        {linkText ? (
          <StyledTypography variant="bodyBaseline" color="secondary" className={linktextClass}>
            {linkText}
          </StyledTypography>
        ) : null}
      </StyledBox>
    </ContainerCardIconText>
  );
};
