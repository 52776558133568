import { useState } from "react";

import { ContainerStyledBox, ContainerStyledTypography } from "./containers-disponibilidad";
import StoreIcon from "@material-ui/icons/Store";
import HelpIcon from "@material-ui/icons/Help";

//Custom libraries
import { CONSTANTS } from "../../../constants/order-item-table";

import { withStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { palette } from "../../themes/color-palette";
import { StyledTypography } from "../../elements";
import { Divider } from "@material-ui/core";
import { HelpCenterIcon } from "../svg-icons";
import { StyledBox, StyledButton } from "@hcl-commerce-store-sdk/react-component";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { useTranslation } from "react-i18next";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { useSelector } from "react-redux";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../../components/custom-components/skeleton-loader/actions/sl-actions";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    marginTop: "-8px",
    marginLeft: "2px",
    padding: "0px",
  },
}))(Tooltip);

interface Props {
  isB2B: boolean;
  productData?: any;
  bahiaData: any;
  disponibilidadEnTienda: number;
  tiendaNombre: any;
  locationName: any;
  isKit?: boolean;
  kitMarketAvailability?: number;
  fullFillmentType?: string;
  nextAvailableDate?: string;
  orderRibbons?: any[];
}

const InventarioTiendaFisisca = (props: Props) => {
  const {
    isB2B,
    productData,
    bahiaData,
    disponibilidadEnTienda,
    tiendaNombre,
    locationName,
    isKit,
    kitMarketAvailability = 0,
    fullFillmentType,
    nextAvailableDate,
    orderRibbons,
  } = props;

  const { t } = useTranslation();

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);
  const cantidad: number = Math.trunc(isKit ? kitMarketAvailability : disponibilidadEnTienda);
  const showIcon = productData?.showIcon;
  const showStore = productData?.showStore;
  const viewCart = productData?.viewCart;
  const shipModeCode = productData?.shipModeCode;

  const shouldShowAvailableQuantity = () => {
    return loaderState.src === LoaderActions.DELIVERY_TYPE_CHANGE && loaderState.idx === undefined;
  };

  let wording = t("productDetail.Availability.labels.physicallyIn");
  let cantidadWording = t("productDetail.Availability.labels.availableQuantity", { n: cantidad });

  if (viewCart && shipModeCode === CONSTANTS.bopis) {
    cantidadWording = t("productDetail.Availability.labels.physicallyInStore", { n: cantidad });
  }

  if (cantidad && cantidad > 199 && viewCart && shipModeCode === CONSTANTS.bopis) {
    cantidadWording = t("productDetail.Availability.labels.physicallyInStore199");
  } else if (cantidad && cantidad > 199) {
    cantidadWording = t("productDetail.Availability.labels.available199");
  }

  if (cantidad === 0 && viewCart && shipModeCode === CONSTANTS.bopis) {
    cantidadWording = t("productDetail.Availability.labels.notAvailablePhysicallyInStore");
    wording = t("productDetail.Availability.labels.in");
  } else if (cantidad === 0) {
    // isProductOnlineOnly = STORE_ONLY_AVAILABLE
    if (orderRibbons?.includes("STORE_ONLY_AVAILABLE")) {
      //V8 Producto de venta exclusiva en tienda fisica
      cantidadWording = t("productDetail.Availability.labels.empty");
      wording = t("productDetail.Availability.labels.in");
    } else if (fullFillmentType === "Online Only") {
      cantidadWording = t("productDetail.Availability.labels.availableQuantity", { n: 0 });
      // cantidadWording = t("productDetail.Availability.labels.notAvailablePhysicallyInStore")
      // wording = t("productDetail.Availability.labels.in")
    } else {
      // Este producto no está disponible en la tienda seleccionada
      cantidadWording = t("productDetail.Availability.labels.empty");
      wording = t("productDetail.Availability.labels.in");
    }
  }

  return (
    <>
      <ContainerStyledBox display="flex" alignItems="flex-end" flexWrap="wrap">
        {showIcon !== false ? (
          <StoreIcon
            style={{
              fontSize: "16px",
              color: cantidad === 0 ? palette.gray["200"] : palette.primary.light,
              marginBottom: "-1px",
            }}
          />
        ) : null}
        {withSkeletonLoader(
          loaders.secondary.cart.availability,
          shouldShowAvailableQuantity(),
          <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
            &nbsp;{cantidadWording}&nbsp;
          </ContainerStyledTypography>,
          {
            omitTimeout: true,
          }
        )}

        {showStore !== false ? (
          <>
            <ContainerStyledTypography variant="bodyBaseline" component="span">
              {wording}&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
              {tiendaNombre}, {locationName}
            </ContainerStyledTypography>
          </>
        ) : null}
      </ContainerStyledBox>

      {bahiaData ? (
        <ContainerStyledBox mt={1} display="flex" flexWrap="wrap" className="buscar-tienda">
          <ContainerStyledTypography variant="bodySubText">Pasillo&nbsp;</ContainerStyledTypography>
          <ContainerStyledTypography variant="bodySubText" fontWeight={600} component="span">
            {bahiaData.pasillo} ･&nbsp;
          </ContainerStyledTypography>
          <ContainerStyledTypography variant="bodySubText" component="span">
            Área&nbsp;
          </ContainerStyledTypography>
          <ContainerStyledTypography variant="bodySubText" fontWeight={600} component="span">
            {bahiaData?.area} ･&nbsp;
          </ContainerStyledTypography>
          <ContainerStyledTypography variant="bodySubText" component="span">
            Bahía&nbsp;
          </ContainerStyledTypography>
          <ContainerStyledTypography variant="bodySubText" fontWeight={600} component="span">
            {bahiaData?.bahia}
          </ContainerStyledTypography>
          <HtmlTooltip
            title={
              <>
                <Divider style={{ backgroundColor: palette.primary.main, height: "2px" }} />
                {isB2B ? <EspotHdm espotName="PRO_PDP-PasilloBahia" /> : <EspotHdm espotName="PDP-PasilloBahia" />}
              </>
            }
            placement="bottom-start">
            <StyledBox style={{ marginTop: "-5px" }}>
              <HelpCenterIcon />
            </StyledBox>
          </HtmlTooltip>
        </ContainerStyledBox>
      ) : null}
    </>
  );
};

export default InventarioTiendaFisisca;
