/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/fyflogin";

const FYF_LOGIN_REQUESTED_ACTION = createAction<any, string>(ACTIONTYPES.FYF_LOGIN_REQUESTED);
const FYF_LOGIN_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.FYF_LOGIN_SUCCESS);
const fyfloginErrorAction = createAction<any, string>(ACTIONTYPES.FYF_LOGIN_ERROR);

export { FYF_LOGIN_REQUESTED_ACTION, FYF_LOGIN_SUCCESS_ACTION, fyfloginErrorAction };
