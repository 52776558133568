import React, { FC, Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Styles
import "../../../assets/homedepot/styles/sass/app.scss";
import { CLOSE_CART_DRAWER_ACTION } from "../../../redux/actions/drawer";
import { cartSourceSelector, drawerSelector } from "../../../redux/selectors/drawer";

import StyledSwipeableDrawerHDM from "../styled-drawer-hdm/StyledDrawerHDM";
import { useTranslation } from "react-i18next";

//Interfaces
interface CDrawerProps {
  children?: JSX.Element[] | JSX.Element;
  // showCart: boolean;
  // setShowCart?: (boolean) => void;
}

let idCartDrwaer
const StyledCartDrawer: FC<CDrawerProps> = ({ children }) => {
  const { t } = useTranslation();
  const productInCartTitle = t("Cart.productsInMyCart");
  const dispatch = useDispatch<Dispatch<any>>();
  const openState = useSelector(drawerSelector);
  const cartSource = useSelector(cartSourceSelector);

  const handleClose = () => {
    dispatch(
      CLOSE_CART_DRAWER_ACTION(() => {
        /* */
      })
    );
  };

  useEffect(() => {

    if(openState){
      if(cartSource !== "miniCart"){
        closeCartDrawer()
      }
    } else {
      cancelCartDrawer()
    }
    
  }, [openState])


  const closeCartDrawer = () => {
    idCartDrwaer = setTimeout(function() {
      dispatch(
        CLOSE_CART_DRAWER_ACTION(() => {
          /* */
        })
      );
    }, 10000);
  }

  const cancelCartDrawer = () => {
    clearTimeout(idCartDrwaer);
  }
  


  return (
    <StyledSwipeableDrawerHDM title={productInCartTitle} variant="cart" isOpen={openState} setOpenDrawer={handleClose}>
      {/* <CartItem /> */}
      {children}
    </StyledSwipeableDrawerHDM>
  );
};

export default StyledCartDrawer;
