/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
//Custom libraries
import addressUtil from "../../../utils/addressUtil";
import { HOME, SIGNIN } from "../../../constants/routes";
//Redux
import { registrationStatusSelector } from "../../../redux/selectors/user";
import * as userAction from "../../../redux/actions/user";
import { RESET_ERROR_ACTION } from "../../../redux/actions/error";
//UI
import Divider from "@material-ui/core/Divider";
import {
  StyledTextField,
  StyledButton,
  StyledTypography,
  StyledFormControlLabel,
  StyledCheckbox,
  StyledGrid,
  StyledPaper,
  StyledBox,
} from "../../../hdm";
import ButtonHDM from "../../custom-components/styled-cart-drawer/cart-item/ButtonHDM";
import { StyledRegistrationContainer, StyledRegistrationEspotContainer } from "./RegistrationStyles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
//GA360
import AsyncCall from "../../../_foundation/gtm/async.service";
import { InputAdornment, useMediaQuery, useTheme } from "@material-ui/core";
import { CLOSE_REGISTER_POPUP_ACTION, OPEN_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { is } from "immer/dist/internal";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

interface RegistrationContext {
  cid?: string;
  showSignInPage?: any;
  isPopUp?: boolean;
}

function RegistrationLayout({ cid, showSignInPage, isPopUp = false, ...props }: RegistrationContext) {
  const queryParameteres = new URLSearchParams(window.location.search);
  const widgetName = getDisplayName(RegistrationLayout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registrationStatus = useSelector(registrationStatusSelector);
  const isTokenRequiredVal: any = useSelector<any>((state) => state.user.isTokenRequired); // is a state variable fetched from user.ts after submit
  const isTokenInValid: any = useSelector<any>((state) => state.user.isTokenInvalid);
  const isTokenExpired: any = useSelector<any>((state) => state.user.isTokenExpired);
  const { t } = useTranslation();
  const { mySite } = useSite();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(960));
  const storeId: string = mySite ? mySite.storeID : "";
  const catalogId: string = mySite ? mySite.catalogID : "";
  const preferredLanguage: string = mySite ? mySite.defaultLanguageID : "";
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [verificationToken, setVerificationToken] = useState<string>("");
  const [phone] = useState<string>("");
  const reminder = t("ResetPassword.Reminder");
  const [receiveEmail, setReceiveEmail] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const tokenLabel = t("RegistrationLayout.VerificationToken");
  const [isTokenRequired, setIsTokenRequired] = useState<boolean>(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState({
    showFirstInputPassword: false,
    showVerifyInputPassword: false,
  });
  const storeConfData = useSelector(onlineStoreRdc);
  const enableRegisterVerificationToken = storeConfData.EnableRegisterVerificationToken;
  const [uuid, setUUID] = useState<string>("");
  const [showInputError, setShowInputError] = useState({
    emailError: false,
    emailVerifyError: false,
    isBothEmailsEqual: false,
    emailMsgError: "",
    lastNameError: false,
    passwordError: false,
    passwordVerifyError: false,
    tokenError: false,
    firstNameError: false,
    lastNamelError: false,
  });
  const [passwordValidations, setPasswordValidations] = useState({
    firstRequirement: 0,
    secondRequirement: 0,
    thirdRequirement: 0,
    fourthRequirement: 0,
    fifthRequirement: 0,
  });
  // 0 = neutral, 1 = error, 2 = success
  /* show or hide input password */
  const { showFirstInputPassword, showVerifyInputPassword } = showPassword;
  const handleShowPassword = (inputType: string) => {
    if (inputType === "first-input") {
      setShowPassword((prevState): any => ({
        ...prevState,
        showFirstInputPassword: prevState.showFirstInputPassword ? false : true,
      }));
    }
    if (inputType === "second-input") {
      setShowPassword((prevState): any => ({
        ...prevState,
        showVerifyInputPassword: prevState.showVerifyInputPassword ? false : true,
      }));
    }
  };

  /* show or hide text helper errors */
  const {
    emailError,
    emailVerifyError,
    isBothEmailsEqual,
    emailMsgError,
    passwordError,
    passwordVerifyError,
    firstNameError,
    lastNameError    //tokenError
  } = showInputError;

  /* Visual handler for password requirements */
  const { firstRequirement, secondRequirement, thirdRequirement, fourthRequirement, fifthRequirement } =
    passwordValidations;
  const specialCharacters = /[-=_*?!@#$/(){}.,;:]/;
  const upperCaseAlphabetLetters = /[A-ZÑ]/;
  const lowerCaseAlphabetLetters = /[a-zñ]/;
  const anyNumber = /\d/;
  if (
    queryParameteres.get("email") !== null &&
    queryParameteres.get("email") !== undefined &&
    queryParameteres.get("uuid") !== null &&
    queryParameteres.get("uuid") !== undefined &&
    email === "" &&
    uuid === "" &&
    enableRegisterVerificationToken === "true"
  ) {
    setEmail(String(queryParameteres.get("email")));
    setUUID(String(queryParameteres.get("uuid")));
  }
  useEffect(() => {
    setPasswordValidations((prevState): any => ({
      ...prevState,
      firstRequirement: password1.length > 7 ? 2 : 0,
      secondRequirement: lowerCaseAlphabetLetters.test(password1) ? 2 : 0,
      thirdRequirement: upperCaseAlphabetLetters.test(password1) ? 2 : 0,
      fourthRequirement: anyNumber.test(password1) ? 2 : 0,
      fifthRequirement: specialCharacters.test(password1) ? 2 : 0,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password1]);

  useEffect(() => {
    return () => {
      dispatch(userAction.RESET_LOGIN_TOKEN_ERROR());
    };
  }, []);

  useEffect(() => {
    if (!isTokenRequiredVal) {
      setShowRegistrationForm(true);
    }
  }, [isTokenRequiredVal]);

  const handleOnBlurPassword = () => {
    setPasswordValidations((prevState): any => ({
      ...prevState,
      firstRequirement: prevState.firstRequirement === 0 ? 1 : prevState.firstRequirement,
      secondRequirement: prevState.secondRequirement === 0 ? 1 : prevState.secondRequirement,
      thirdRequirement: prevState.thirdRequirement === 0 ? 1 : prevState.thirdRequirement,
      fourthRequirement: prevState.fourthRequirement === 0 ? 1 : prevState.fourthRequirement,
      fifthRequirement: prevState.fifthRequirement === 0 ? 1 : prevState.fifthRequirement,
    }));
    setShowInputError((prevState): any => ({
      ...prevState,
      passwordError:
        firstRequirement !== 2 ||
        secondRequirement !== 2 ||
        thirdRequirement !== 2 ||
        fourthRequirement !== 2 ||
        fifthRequirement !== 2
          ? true
          : false,
    }));
  };

  /* Verify password errors  */

  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */
  const validatedPassword = () => {
    if (
      firstRequirement === 2 &&
      secondRequirement === 2 &&
      thirdRequirement === 2 &&
      fourthRequirement === 2 &&
      fifthRequirement === 2
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canCreate = (): boolean => {
    if (
      email.trim() !== "" &&
      verifyEmail.trim() !== "" &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      password1.trim() !== "" &&
      password2.trim() !== "" &&
      password1.trim() === password2.trim()
    ) {
      return addressUtil.validateEmail(email) && addressUtil.validateEmail(verifyEmail);
      // && addressUtil.validatePhoneNumber(phone);
    }
    return false;
  };

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.name === "firstName") {
      const patt = new RegExp(/^[A-Za-z-ñÑáéíóúÁÉÍÓÚ´\s]+$/g);
      const isValid = patt.test(event.target.value);

      if (isValid || event.target.value.trim() === "") {
        setFirstName(event.target.value);
      }

      if (firstName.length < 1) {
        setShowInputError((prevState): any => ({ ...prevState, firstNameError: false }));
      }
    }

    if (event.target.name === "lastName") {
      const patt = new RegExp(/^[A-Za-z-ñÑáéíóúÁÉÍÓÚ´\s]+$/g);
      const isValid = patt.test(event.target.value);

      if (isValid || event.target.value.trim() === "") {
        setLastName(event.target.value);
      }

      if (lastName.length < 1) {
        setShowInputError((prevState): any => ({ ...prevState, lastNameError: false }));
      }
    }
  };

  // const onRememberMeChecked = useCallback((_event: any, value: boolean) => {
  //   setRememberMe(value);
  // }, []);

  const handleSubmitResend = (props: any) => {
    props.preventDefault();
    const isPasswordValidated = validatedPassword();
    const validatedInputs = canCreate();
    const payload: any = {
      body: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        logonId: email,
        bestCallingTime: "true",
        mobilePhone1Country: uuid,
        mobilePhone1: null,
        logonPassword: password1,
        logonPasswordVerify: password2,
        registerType: "G",
        profileType: "C",
        email1: email,
        phone1: phone.trim(),
        storeId,
        catalogId,
        preferredLanguage,
        receiveEmail: receiveEmail,
        receiveEmailPreference: [
          {
            value: receiveEmail,
            storeID: storeId,
          },
        ],
        challengeQuestion: "-",
        challengeAnswer: "-",
      },
      query: {},
      ...payloadBase,
    };
    
    if (rememberMe) payload.query.rememberMe = rememberMe;
    if (
      payload.body.mobilePhone1Country !== "" &&
      payload.body.mobilePhone1Country !== undefined &&
      payload.body.mobilePhone1 !== "" &&
      payload.body.mobilePhone1 !== undefined
    ) {
      dispatch(userAction.registrationAction(payload));
    } else {
      if (!isPasswordValidated && !validatedInputs && verifyEmail.length < 1 && email.length < 1) {
        setShowInputError((prevState): any => ({
          ...prevState,
          emailError: true,
          emailVerifyError: true,
          emailMsgError: t("RegistrationLayout.Msgs.InvalidFormat"),
          passwordError: true,
          passwordVerifyError: true,
          firstNameError: true,
          lastNameError: true,
        }));
      } else {
        if( email.trim() === "" || !addressUtil.validateEmail(email)) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            emailError: true
          }));
        }
        if( verifyEmail.trim() === "" || !addressUtil.validateEmail(verifyEmail)) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            emailVerifyError: true
          }));
        }
        if( firstName.trim() === "") { 
          setShowInputError((prevState): any => ({
            ...prevState,
            firstNameError: true
          }));
        }
        if( lastName.trim() === "") { 
          setShowInputError((prevState): any => ({
            ...prevState,
            lastNameError: true
          }));
        }
        if( password1.trim() === "" || !isPasswordValidated) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            passwordError: true
          }));
        }
        if( password2.trim() === "" || !isPasswordValidated) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            passwordVerifyError: true
          }));
        }
      }
      if (isPasswordValidated && validatedInputs) {
        dispatch(userAction.registrationAction(payload));
      }
    }
  };
  const handleVerificationToken = (props: any) => {
    props.preventDefault();
    const isPasswordValidated = validatedPassword();
    const validatedInputs = canCreate();
    const payload: any = {
      body: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        logonId: email,
        // bestCallingTime: "false", // this var is used to validate if we are using the resend button
        mobilePhone1Country: uuid,
        mobilePhone1: verificationToken,
        logonPassword: password1,
        logonPasswordVerify: password2,
        registerType: "G",
        profileType: "C",
        email1: email,
        phone1: phone.trim(),
        storeId,
        catalogId,
        preferredLanguage,
        receiveEmail: receiveEmail ? "true" : "false",
        receiveEmailPreference: [
          {
            value: receiveEmail ? "true" : "false",
            storeID: storeId,
          },
        ],
        challengeQuestion: "-",
        challengeAnswer: "-",
      },
      query: {},
      ...payloadBase,
    };
   
    if (rememberMe) payload.query.rememberMe = rememberMe;
    if (
      payload.body.mobilePhone1Country !== "" &&
      payload.body.mobilePhone1Country !== undefined &&
      payload.body.mobilePhone1 !== "" &&
      payload.body.mobilePhone1 !== undefined
    ) {
      dispatch(userAction.registrationAction(payload));
    } else {
      if (!isPasswordValidated && !validatedInputs && verifyEmail.length < 1 && email.length < 1) {
        setShowInputError((prevState): any => ({
          ...prevState,
          emailError: true,
          emailVerifyError: true,
          emailMsgError: t("RegistrationLayout.Msgs.InvalidFormat"),
          passwordError: true,
          passwordVerifyError: true,
          firstNameError: true,
          lastNameError: true,
        }));
      } else {
        if( email.trim() === "" || !addressUtil.validateEmail(email)) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            emailError: true
          }));
        }
        if( verifyEmail.trim() === "" || !addressUtil.validateEmail(verifyEmail)) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            emailVerifyError: true
          }));
        }
        if( firstName.trim() === "") { 
          setShowInputError((prevState): any => ({
            ...prevState,
            firstNameError: true
          }));
        }
        if( lastName.trim() === "") { 
          setShowInputError((prevState): any => ({
            ...prevState,
            lastNameError: true
          }));
        }
        if( password1.trim() === "" || !isPasswordValidated) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            passwordError: true
          }));
        }
        if( password2.trim() === "" || !isPasswordValidated) { 
          setShowInputError((prevState): any => ({
            ...prevState,
            passwordVerifyError: true
          }));
        }
      }
      if (isPasswordValidated && validatedInputs) {
        dispatch(userAction.registrationAction(payload));
      }
    }
  };
  const handleSubmit = (props: any) => {
    props.preventDefault();
    const isPasswordValidated = validatedPassword();
    const validatedInputs = canCreate();

    const payload: any = {
      body: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        logonId: email,
        // mobilePhone1Country:uuid,
        mobilePhone1: enableRegisterVerificationToken === "true" ? verificationToken : "false",
        logonPassword: password1,
        logonPasswordVerify: password2,
        registerType: "G",
        profileType: "C",
        email1: email,
        phone1: phone.trim(),
        storeId,
        catalogId,
        preferredLanguage,
        receiveEmail: receiveEmail ? "true" : "false",
        receiveEmailPreference: [
          {
            value: receiveEmail ? "true" : "false",
            storeID: storeId,
          },
        ],
        challengeQuestion: "-",
        challengeAnswer: "-",
      },
      query: {},
      ...payloadBase,
    };
    if (rememberMe) payload.query.rememberMe = rememberMe;
    if (!isPasswordValidated && !validatedInputs && verifyEmail.length < 1 && email.length < 1) {
      setShowInputError((prevState): any => ({
        ...prevState,
        emailError: true,
        emailVerifyError: true,
        emailMsgError: t("RegistrationLayout.Msgs.InvalidFormat"),
        passwordError: true,
        passwordVerifyError: true,
        firstNameError: true,
        lastNameError: true,
      }));
    } else {
      if( email.trim() === "" || !addressUtil.validateEmail(email)) { 
        setShowInputError((prevState): any => ({
          ...prevState,
          emailError: true
        }));
      }
      if( verifyEmail.trim() === "" || !addressUtil.validateEmail(verifyEmail)) { 
        setShowInputError((prevState): any => ({
          ...prevState,
          emailVerifyError: true
        }));
      }
      if( firstName.trim() === "") { 
        setShowInputError((prevState): any => ({
          ...prevState,
          firstNameError: true
        }));
      }
      if( lastName.trim() === "") { 
        setShowInputError((prevState): any => ({
          ...prevState,
          lastNameError: true
        }));
      }
      if( password1.trim() === "" || !isPasswordValidated) { 
        setShowInputError((prevState): any => ({
          ...prevState,
          passwordError: true
        }));
      }
      if( password2.trim() === "" || !isPasswordValidated) { 
        setShowInputError((prevState): any => ({
          ...prevState,
          passwordVerifyError: true
        }));
      }
    }
    if (isPasswordValidated && validatedInputs) {
      dispatch(userAction.registrationAction(payload));
      if (!isTokenRequiredVal) {
        setShowRegistrationForm(false);
      }
    }
  };

  const showSignIn = () => {
    window.setOpenMiniSingIn = false;
    showSignInPage(true);
    navigate(SIGNIN);
  };

  const showSignInPopUp = () => {
    dispatch(CLOSE_REGISTER_POPUP_ACTION({}));
    dispatch(OPEN_SIGN_IN_POPUP_ACTION({}));
  };

  const handleValidToken = (evt) => {
    evt.preventDefault();
    dispatch(userAction.RESET_REGISTRATION_TOKEN_ERROR());
    const regex = /^[0-9\b]+$/;
    if (evt.target.value === "" || regex.test(evt.target.value)) {
      setVerificationToken(evt.target.value);
    }
  };
  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const homeMainContainer = document.getElementById("home--footer-container");
    if(homeMainContainer){
      homeMainContainer.classList.remove('footer-main-container');
    }
  });

  if (registrationStatus) {
    //GA360
    if (mySite.enableGA) {
      AsyncCall.sendFormCompletionEvent("Register", {
        enableUA: mySite.enableUA,
        enableGA4: mySite.enableGA4,
      });
    }
    return <Navigate replace to={HOME} />;
  } else {
    //setVerificationTokenRequired(true);
    //const tokenRequired: boolean = true;
    return (
      <>
        <StyledRegistrationContainer
          className="horizontal-padding-2 vertical-padding-3"
          isMobile={isMobile}
          passwordValidations={passwordValidations}
          isPopUp={isPopUp}>
          <StyledBox id="form-wrapper" sx={{ maxWidth: "610px", margin: "20px 0" }}>
            {!isPopUp && (
              <StyledBox sx={{ display: "flex" }}>
                <StyledTypography
                  component="h1"
                  variant="headingSubhead"
                  className="bottom-margin-1 primary-color-divider">
                  {t("RegistrationLayout.Register")}
                </StyledTypography>
                <StyledBox className="gray-color-divider" />
              </StyledBox>
            )}
            {(showRegistrationForm || !isTokenRequiredVal) && uuid === "" ? (
              <form noValidate name="registrationForm" id={`registration_form_5_${cid}`} onSubmit={handleSubmit}>
                {/* Email inputs  */}
                <StyledGrid container item spacing={1}>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.Email")}
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (email.length < 1) {
                          setShowInputError((prevState): any => ({ ...prevState, emailError: false }));
                        }
                      }}
                      value={email}
                      inputProps={{
                        maxLength: 100,
                        type: "email",
                        placeholder: "correo@dominio.com",
                      }}
                      error={!addressUtil.validateEmail(email) || emailError}
                      onBlur={() =>
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          emailError: !addressUtil.validateEmail(email) ? true : false,
                        }))
                      }
                      helperText={
                        emailError && !addressUtil.validateEmail(email)
                          ? t("RegistrationLayout.Msgs.InvalidFormat")
                          : emailError
                          ? t("RegistrationLayout.Msgs.InvalidFormat")
                          : ""
                      }
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.VerifyEmail")}
                      name="verifyEmail"
                      autoComplete="email"
                      // autoFocus
                      onChange={(e) => {
                        setVerifyEmail(e.target.value);
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          isBothEmailsEqual: email !== verifyEmail ? true : false,
                        }));
                        if (verifyEmail.length < 1) {
                          setShowInputError((prevState): any => ({ ...prevState, emailVerifyError: false }));
                        }
                      }}
                      value={verifyEmail}
                      inputProps={{
                        maxLength: 100,
                        type: "email",
                        placeholder: "correo@dominio.com",
                      }}
                      error={!addressUtil.validateEmail(verifyEmail) || emailVerifyError}
                      onBlur={() => {
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          emailVerifyError: email !== verifyEmail ? true : false,
                          emailMsgError: !addressUtil.validateEmail(verifyEmail)
                            ? t("RegistrationLayout.Msgs.InvalidFormat")
                            : addressUtil.validateEmail(verifyEmail) && email !== verifyEmail
                            ? t("RegistrationLayout.Msgs.NoMatchEmails")
                            : addressUtil.validateEmail(verifyEmail) && isBothEmailsEqual
                            ? ""
                            : "",
                        }));
                      }}
                      helperText={emailVerifyError && emailMsgError}
                    />
                  </StyledGrid>
                </StyledGrid>

                {/* Name and lastname inputs */}
                <StyledGrid container item spacing={1}>
                  <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.FirstNames")}
                      name="firstName"
                      onChange={handleChange}
                      value={firstName}
                      inputProps={{
                        maxLength: 40,
                      }}
                      error={firstNameError}
                      onBlur={() =>
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          firstNameError: firstName.trim().length < 1 ? true : false,
                        }))
                      }
                      helperText={
                        firstNameError
                          ? t("RegistrationLayout.Msgs.NoFullRequirementsFirstName")
                          : ""
                      }
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.LastName")}
                      name="lastName"
                      onChange={handleChange}
                      value={lastName}
                      inputProps={{
                        maxLength: 40,
                      }}
                      error={lastNameError}
                      onBlur={() =>
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          lastNameError: lastName.trim().length < 1 ? true : false,
                        }))
                      }
                      helperText={
                        lastNameError
                          ? t("RegistrationLayout.Msgs.NoFullRequirementsLastName")
                          : ""
                      }
                    />
                  </StyledGrid>
                </StyledGrid>

                {/* Password validations */}
                <StyledGrid container item spacing={1}>
                  <StyledGrid item xs={12} sm={12} md={12} lg={12}>
                    <StyledTypography component="h1" variant="bodyBaseline" className="top-margin-2 validations-title">
                      {t("RegistrationLayout.RegistrationPasswordValidation")}
                    </StyledTypography>
                  </StyledGrid>

                  <StyledGrid item lg={4} className="password-criterias-list">
                    <StyledTypography component="ul" variant="bodyBaseline">
                      <StyledTypography component="li" variant="bodySubText" id="first-requirement">
                        {t("RegistrationLayout.PasswordCriteria.Criteria1")}
                      </StyledTypography>
                      <StyledTypography component="li" variant="bodySubText" id="second-requirement">
                        {t("RegistrationLayout.PasswordCriteria.Criteria2")}
                      </StyledTypography>
                      <StyledTypography component="li" variant="bodySubText" id="third-requirement">
                        {t("RegistrationLayout.PasswordCriteria.Criteria3")}
                      </StyledTypography>
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item lg={8} className="password-criterias-list">
                    <StyledTypography component="ul">
                      <StyledTypography component="li" variant="bodySubText" id="fourth-requirement">
                        {t("RegistrationLayout.PasswordCriteria.Criteria4")}
                      </StyledTypography>
                      <StyledTypography component="li" variant="bodySubText" id="fifth-requirement">
                        {t("RegistrationLayout.PasswordCriteria.Criteria5")}
                      </StyledTypography>
                    </StyledTypography>
                  </StyledGrid>
                </StyledGrid>

                {/* <StyledTextField
                    margin="normal"
                    fullWidth
                    label={t("RegistrationLayout.Phone")}
                    name="phone"
                    autoComplete="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    inputProps={{
                      maxLength: 32,
                      type: "tel",
                    }}
                    error={!addressUtil.validatePhoneNumber(phone)}
                    helperText={
                      !addressUtil.validatePhoneNumber(phone)
                        ? t("RegistrationLayout.Msgs.InvalidFormat")
                        : ""
                    }
                  /> */}

                {/* Password inputs */}
                <StyledGrid container item spacing={1}>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.Password")}
                      name="password1"
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPassword1(e.target.value);
                      }}
                      value={password1}
                      inputProps={{
                        maxLength: 50,
                        type: showFirstInputPassword ? "" : "password",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showFirstInputPassword ? (
                              <VisibilityOffOutlinedIcon
                                onClick={() => handleShowPassword("first-input")}
                                className="show-password-icon"
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                onClick={() => handleShowPassword("first-input")}
                                className="show-password-icon"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleOnBlurPassword}
                      error={passwordError}
                      helperText={passwordError && t("RegistrationLayout.Msgs.NoFullRequirementsPassword")}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      margin="normal"
                      fullWidth
                      label={t("RegistrationLayout.VerifyPassword")}
                      name="password2"
                      autoComplete="new-password"
                      onChange={(e) => setPassword2(e.target.value)}
                      value={password2}
                      inputProps={{
                        maxLength: 50,
                        type: showVerifyInputPassword ? "" : "password",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showVerifyInputPassword ? (
                              <VisibilityOffOutlinedIcon
                                onClick={() => handleShowPassword("second-input")}
                                className="show-password-icon"
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                onClick={() => handleShowPassword("second-input")}
                                className="show-password-icon"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onBlur={() =>
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          passwordVerifyError: password1 !== password2 ? true : false,
                        }))
                      }
                      error={passwordVerifyError}
                      helperText={passwordVerifyError && t("RegistrationLayout.Msgs.NoMatchPasswords")}
                    />
                  </StyledGrid>
                </StyledGrid>

                {/* Subscription to news and offers */}
                <StyledGrid item xs className="top-margin-2 subscribe-checkbox">
                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        value={receiveEmail}
                        color="primary"
                        onChange={(e) => setReceiveEmail(e.target.checked)}
                      />
                    }
                    label={t("RegistrationLayout.TextContent")}
                  />
                </StyledGrid>

                {/* Terms, conditions and privacy */}
                <StyledGrid item lg={12} className="top-margin-2 terms-and-conditions">
                  <StyledTypography component="h1" variant="bodyBaseline" className="bottom-margin-1">
                    {t("RegistrationLayout.AcceptTermsAndConditions")}
                    <StyledTypography
                      variant="bodyBaseline"
                      component="span"
                      className="terms-conditions-and-privacy-link">
                      <Link to="/terminos-condiciones" target="_blank" rel="noopener noreferrer">
                        {` Términos y Condiciones `}
                      </Link>
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline" component="span">
                      {`y`}
                    </StyledTypography>
                    <StyledTypography
                      variant="bodyBaseline"
                      component="span"
                      className="terms-conditions-and-privacy-link">
                      <Link to="/aviso-privacidad" target="_blank" rel="noopener noreferrer">
                        {` Aviso de privacidad`}
                      </Link>
                    </StyledTypography>
                    <StyledTypography variant="bodyCaption" component="span">
                      {`.`}
                    </StyledTypography>
                  </StyledTypography>
                </StyledGrid>

                {/* Create account button */}
                <StyledButton
                  testId="register-submit"
                  type="submit"
                  color="primary"
                  // disabled={!canCreate()}
                  className="login-process-button top-margin-1">
                  {t("RegistrationLayout.RegisterComplete")}
                </StyledButton>

                <Divider className="top-margin-3" />

                {/* SignIn link  */}
                <StyledGrid item lg={12} className="top-margin-2 sign-in-link-container">
                  <StyledTypography variant="bodyBaseline" component="span">
                    {t("RegistrationLayout.Account")}
                  </StyledTypography>
                  <StyledTypography
                    onClick={isPopUp ? showSignInPopUp : showSignIn}
                    variant="bodyBaseline"
                    component="span"
                    className="left-margin-1 terms-conditions-and-privacy-link">
                    {t("RegistrationLayout.SignIn")}
                  </StyledTypography>
                </StyledGrid>
              </form>
            ) : (
              <form onSubmit={handleSubmit} noValidate>
                <StyledGrid item xs={12} sm={12} md={12} lg={12}>
                  <StyledTypography component="h1" variant="bodyBaseline" className="top-margin-2 validations-title">
                    {t("RegistrationLayout.RegistrationSecondSection")}
                  </StyledTypography>
                </StyledGrid>
                <StyledGrid container justifyContent="center" alignItems="center" spacing={1}>
                  <StyledGrid item className={"vertical-margin-1"}>
                    <img
                      src="https://cdn.homedepot.com.mx/assets/img/reset-password/send-mail.svg"
                      alt="https://cdn.homedepot.com.mx/assets/img/reset-password/send-mail.svg"
                      width={"150"}
                      className="lazyload"></img>
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <StyledTypography
                      variant="bodyBaseline"
                      weight="regular"
                      className="color-gray200 bottom-margin-1 ">
                      {t("RegistrationLayout.RegistrationSecondReminderSection")}
                    </StyledTypography>
                  </StyledGrid>
                </StyledGrid>

                <StyledGrid container item spacing={1}>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="email"
                      autoComplete="email"
                      value={email}
                      label={t("RegistrationLayout.Email")}
                      disabled
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={6} lg={6}>
                    <StyledTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="verificationToken"
                      autoComplete="verificationToken"
                      label={tokenLabel}
                      autoFocus
                      onChange={handleValidToken}
                      value={verificationToken}
                      inputProps={{
                        maxLength: 6,
                      }}
                      isToken
                      error={isTokenInValid || isTokenExpired}
                      helperText={
                        isTokenInValid
                          ? t("RegistrationLayout.VerificationErrorToken")
                          : isTokenExpired && t("RegistrationLayout.ExpiredErrorToken")
                      }
                    />
                  </StyledGrid>
                </StyledGrid>

                <StyledBox mt={2}>
                  <StyledButton
                    testId="register-submit"
                    type="submit"
                    color="primary"
                    //variant="outlined"
                    className="login-process-button bottom-margin-2"
                    disabled={addressUtil.validateVerificationToken(verificationToken) ? true : false}
                    onClick={handleVerificationToken}>
                    {t("RegistrationLayout.RegistrationVerify")}
                  </StyledButton>
                </StyledBox>
                <StyledGrid item>
                  <StyledGrid container spacing={1} justifyContent="center" alignItems="center" direction="row">
                    <StyledGrid item xs={12}>
                      <StyledTypography variant="body1" align="center" className="bottom-margin-2">
                        {t("RegistrationLayout.RegistrationNoCode")}
                      </StyledTypography>
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <StyledBox align="center">
                        <StyledButton
                          testId="reset-password-resend-verification-code"
                          variant="outlined"
                          type="button"
                          className="login-process-button"
                          onClick={handleSubmitResend}>
                          {t("RegistrationLayout.RegistrationResendCode")}
                        </StyledButton>
                      </StyledBox>
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <Divider className="top-margin-2" />
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </form>
            )}
          </StyledBox>
        </StyledRegistrationContainer>

        {!isPopUp && (
          <StyledRegistrationEspotContainer isMobile={isMobile}>
            <EspotHdm espotName="Register_AdditionalInfo" />
          </StyledRegistrationEspotContainer>
        )}
      </>
    );
  }
}

export { RegistrationLayout };
