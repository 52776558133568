import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { hdmColorsRdc, onlineStoreRdc } from "../../../redux/selectors/hdmData";
import * as orderActions from "../../../redux/actions/order";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";
import { Divider, Hidden } from "@material-ui/core";
import { StyledPromp } from "../styled-promp";
import { StyledLinkButton } from "../styled-store-selector/styled-link-button";
import AddIcon from "@material-ui/icons/Add";
import { EMPTY_STRING, PREMIUM_BASE_PAINTS } from "../../../constants/common";
import { StyledCartRowPaintContainer } from "./StyledCartRowPaintContainer";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import React from "react";

const RowColorComponent = (props) => {
  const { rowPaint, variant, isMiniCart } = props;
  const { t } = useTranslation();
  const baseColors = PREMIUM_BASE_PAINTS;
  const isBase = rowPaint?.paintCode && baseColors.includes(rowPaint.paintCode);
  return (
    <>
      <StyledBox
        className="styledCartRowPaint--styledGrid-colorDisplay styled--chipbackground-color"
        r={rowPaint?.paintColor.R}
        g={rowPaint?.paintColor.G}
        b={rowPaint?.paintColor.B}
      />
      <StyledGrid className={"styledCartRowPaint--styledGrid-paintCodeNameContainer top-margin-1"}>
        {variant !== "cart" && (
          <>
            <StyledTypography
              variant="bodyCaption"
              component={"span"}
              className={"color-gray300 styledCartRowPaint--styledGrid-paintBaseNoWarp"}>
              {t("DeliveryInfoCard.Quantity") + ": "}
              {parseInt(rowPaint?.paintQuantity)}
            </StyledTypography>
            <StyledBox class="styledCartRowPaint--styledGrid-paintContainer horizontal-padding-1">
              <Divider orientation="vertical" className="styledCartRowPaint--styledGrid-backgroundColor100" flexItem />
            </StyledBox>
          </>
        )}
        {isMiniCart && (
          <>
            {!isBase && (
              <>
                <StyledTypography
                  variant={"bodyCaption"}
                  className={"color-gray300 styledCartRowPaint--styledGrid-paintBaseNoWarp"}
                  component="span">
                  {t("productDetail.BuyByColor.labels.code")}&nbsp;
                </StyledTypography>
              </>
            )}
            <StyledTypography
              variant={"bodyCaption"}
              className={"color-gray300 styledCartRowPaint--styledGrid-paintBaseNoWarp left-margin-1"}
              weight={"semibold"}
              component="span">
              {isBase ? rowPaint?.paintColorName.toUpperCase() : rowPaint?.paintCode.toUpperCase()}
            </StyledTypography>
          </>
        )}
        {!isMiniCart && (
          <>
            {!isBase && (
              <>
                <StyledTypography
                  variant={"bodyCaption"}
                  className={"color-gray300 styledCartRowPaint--styledGrid-paintBaseNoWarp"}
                  weight={"semibold"}
                  component="span">
                  {rowPaint?.paintCode.toUpperCase()}
                </StyledTypography>
                <StyledBox class="styledCartRowPaint--styledGrid-paintContainer horizontal-padding-1">
                  <Divider orientation="vertical" className="styledCartRowPaint--styledGrid-backgroundColor100" flexItem />
                </StyledBox>
              </>
            )}
            <StyledTypography
              variant={"bodyCaption"}
              className={"color-gray300 styledCartRowPaint--styledGrid-paintCodeName"}
              component="span">
              {rowPaint?.paintColorName.toUpperCase()}
            </StyledTypography>
          </>
        )}
      </StyledGrid>
    </>
  );
};
const StyledCartRowPaint = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onCloseDrawer } = props;
  const rowData = props.rowData;
  const colorDataSelector = useSelector(hdmColorsRdc);
  const storeConfData = useSelector(onlineStoreRdc);
  const rowPaintString = rowData?.xitem_colorCode ? rowData?.xitem_colorCode : "";
  const rowPaintArray = useMemo(() => {
    return rowPaintString.split(",");
  }, [rowPaintString]);
  const CancelToken = Axios.CancelToken;
  const rowPaintsArray: any[] = [];
  const [rowPaints, setRowPaints] = useState<any>([]);
  const manufacturerArray: any[] = [];
  const colorData = colorDataSelector?.allLineColors;
  const cancels: Canceler[] = [];
  const QuantityCell = props?.QuantityCell ? props?.QuantityCell : () => void 0;
  const variant = props?.variant ? props.variant : "cart";
  const isMiniCart = props?.isMiniCart === true ? true : false;
  const payloadBase: any = {
    widget: "StyledCartRowPaint",
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  colorData.map((color) => {
    manufacturerArray.push(color.linea);
  });

  const deletePaintItem = (item) => {
    let x_colorDetails: any = null;
    const quantityString = "0";
    let quantityInit = quantityString;
    if (item?.paintQuantity) {
      quantityInit = (parseInt(item.quantity) - parseInt(item.paintQuantity) + parseInt(quantityString)).toString();
      x_colorDetails = "0" + "|" + item.paintCode;
      const payload = {
        ...payloadBase,
        quantity: quantityInit,
        orderItemId: item.orderItemId,
        fetchCatentries: true,
        x_colorDetails: x_colorDetails,
        x_deleteColor: "true",
      };
      dispatch(orderActions.UPDATE_ITEM_ACTION(payload));
    }
  };

  useEffect(() => {
    if (manufacturerArray?.length === 0) return;
    rowPaintArray.map((paint) => {
      if (paint !== EMPTY_STRING) {
        const paints = paint.split("|");
        let manufacturer = "";
        let r = 0;
        let g = 0;
        let b = 0;
        let colorName = "";
        let paintValue = "";
        for (let i = 0; i < manufacturerArray.length; i++) {
          if (
            rowData?.manufacturer &&
            rowData.manufacturer.toUpperCase().includes(manufacturerArray[i].toUpperCase())
          ) {
            const brandColor = colorData[i].colores.filter(
              (color) =>
                color.codigo === paints[1] ||
                (color?.valor && color.valor === paints[1] && PREMIUM_BASE_PAINTS.includes(paints[1]))
            );
            if (brandColor.length > 0) {
              manufacturer = manufacturerArray[i];
              r = brandColor[0].R;
              g = brandColor[0].G;
              b = brandColor[0].B;
              colorName = brandColor[0].nombre;
              paintValue = colorData[i].valor;
              break;
            }
          }
        }
        rowPaintsArray.push({
          paintColorName: colorName,
          paintColor: {
            R: r,
            G: g,
            B: b,
          },
          paintValue: paintValue,
          paintCode: paints[1],
          paintQuantity: paints[0],
          paintManufacturer: manufacturer,
          paintItemManufacturer: rowData?.manufacturer,
        });
      }
    });
    setRowPaints(rowPaintsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPaintArray]);
  if (rowPaints.length === 0) {
    return <></>;
  }

  return (
    <StyledCartRowPaintContainer>
      <StyledGrid
        container
        direction="row"
        className={
          variant === "TYP" ? "horizontal-padding-2 styledCartRowPaint-styledGrid-container" : "top-padding-2"
        }>
        {variant === "cart" && (
          <Hidden mdUp>
            <StyledGrid item xs={12}>
              <Divider className={"bottom-margin-2"} />
            </StyledGrid>
          </Hidden>
        )}
        {React.Children.toArray(
          rowPaints.map((rowPaint, index) => (
            <StyledGrid item xs={12} className={"styledCartRowPaint--styledGrid-paintContainer"}>
              {variant === "cart" && <QuantityCell {...{ rowData }} rowPaint={rowPaint} />}
              <StyledGrid
                item
                className={
                  variant === "cart"
                    ? "styledCartRowPaint--styledGrid-colorDisplayContainer"
                    : "styledCartRowPaint--styledGrid-colorDisplayContainerSimple"
                }>
                {rowPaint?.paintColorName ? (
                  <RowColorComponent rowPaint={rowPaint} variant={variant} isMiniCart={isMiniCart} />
                ) : (
                  <>
                    {variant === "cart" && (
                      <>
                        {(rowPaint?.paintCode.toUpperCase() === "D" || rowPaint?.paintCode.toUpperCase() === "M") && (
                          <>
                            <StyledPromp variant="alert" message={t("productDetail.CartOrderItemPaintBase")} />
                            <StyledGrid item xs={12} md={isMiniCart ? 6 : 3}>
                              <Link
                                to={rowData?.seo?.href ? rowData?.seo?.href+"#buybycolor" : ""}
                                className={"styledCartRowPaint--styledGrid-paintBaseAddColor"}
                                reloadDocument
                                onClick={onCloseDrawer}
                                >
                                <AddIcon
                                  color={"primary"}
                                  className={
                                    isMiniCart
                                      ? "styledCartRowPaint--styledGrid-addDirectionIconMiniCart"
                                      : "styledCartRowPaint--styledGrid-addDirectionIcon"
                                  }
                                />
                                <StyledLinkButton variant={isMiniCart ? "bodySubtext" : "bodyBaseline"}>
                                  {t("productDetail.CartOrderItemPaintBaseAddColor")}
                                </StyledLinkButton>
                              </Link>
                            </StyledGrid>
                          </>
                        )}
                      </>
                    )}
                    {variant !== "cart" && (
                      <>
                        <StyledGrid xs={12} className={"styledCartRowPaint--styledGrid-paintBaseContainer"}>
                          <StyledTypography variant="bodyCaption" component={"span"} className={"color-gray300"}>
                            {t("DeliveryInfoCard.Quantity") + ": "}
                            {parseInt(rowPaint?.paintQuantity)}
                          </StyledTypography>
                          <Divider orientation="vertical" className="horizontal-margin-1" flexItem />
                          <StyledTypography variant="bodyCaption" component={"span"} className={"color-gray300"}>
                            {rowPaint?.paintCode.toUpperCase() === "D" &&
                              t("productDetail.CartOrderItemPaintBaseTransparent")}
                            {rowPaint?.paintCode.toUpperCase() === "B" &&
                              t("productDetail.CartOrderItemPaintBaseBlancoPuro")}
                            {rowPaint?.paintCode.toUpperCase() === "U" &&
                              t("productDetail.CartOrderItemPaintBaseUtraPureWhite")}
                            {rowPaint?.paintCode.toUpperCase() === "M" &&
                              t("productDetail.CartOrderItemPaintBaseTransparent")}
                          </StyledTypography>
                        </StyledGrid>
                      </>
                    )}
                  </>
                )}
              </StyledGrid>
              {variant === "cart" && !isMiniCart && (
                <StyledGrid item className="styledCartRowPaint--img-deletePaintTrashContainer">
                  <StyledBox
                    component="img"
                    className={"styledCartRowPaint--img-deletePaintTrashSvg"}
                    alt="Trashcan icon"
                    onClick={() => deletePaintItem({ ...rowData, ...rowPaint })}
                    src={storeConfData.IMAGE_SERVER_HOSTNAME + "assets/img/delete-trashcan.svg"}
                  />
                </StyledGrid>
              )}
            </StyledGrid>
          ))
        )}
        {variant === "cart" && !isMiniCart && (
          <Hidden smDown>
            <StyledGrid item xs={12}>
              <Divider />
            </StyledGrid>
          </Hidden>
        )}
      </StyledGrid>
    </StyledCartRowPaintContainer>
  );
};

export { StyledCartRowPaint };
