import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";

import { StyledLink, StyledBox } from "../../../../elements";
import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledCardMedia,
  ContainerRadioCardGrid,
  ContainerUncheckedRadioIcon,
} from "../../containers-precio-promocion";

interface Props {
  articulo: any;
  handleSelectedArticulo: (value: string) => void;
  selected: boolean;
}
const ProductoGratisRadio = (props: Props) => {
  const { articulo, handleSelectedArticulo, selected } = props;

  const storeConfData = useSelector(onlineStoreRdc);
  const { t } = useTranslation();

  useEffect(() => {
    if (selected) {
      handleSelectedArticulo(articulo);
    }
  }, []);

  const imageURL = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${articulo?.partNumber}/${articulo?.partNumber}-m.jpg`;

  return (
    <>
      <ContainerRadioCardGrid className="container" onClick={() => handleSelectedArticulo(articulo)}>
        <ContainerStyledBox className={selected ? "radio-container-selected" : "radio-container"}>
          <ContainerUncheckedRadioIcon className={selected ? "radioicon-style-selected" : "radioicon-style"} />
        </ContainerStyledBox>
        <ContainerStyledBox display="flex" p={2} alignItems="flex-start" justifyContent="flex-start">
          <ContainerStyledBox>
            <ContainerStyledCardMedia image={imageURL} className="list-productogratis-image" title="" component="img" />
          </ContainerStyledBox>

          <ContainerStyledBox ml={1}>
            <ContainerStyledBox className="product-title-text">
              <ContainerStyledTypography variant="bodyBaseline">{articulo.name}</ContainerStyledTypography>
            </ContainerStyledBox>
            <ContainerStyledTypography variant="bodySubText" className="producto-gratis-sku">
              {t("productDetail.SKU")} {articulo.partNumber}
            </ContainerStyledTypography>
            <StyledLink target="_blank" to={articulo.seo.href}>
              <ContainerStyledTypography variant="bodyBaseline" className="producto-verdetalle" component="span">
                {t("productDetail.SeeDetails")}
              </ContainerStyledTypography>
            </StyledLink>
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerRadioCardGrid>
    </>
  );
};

export default ProductoGratisRadio;
