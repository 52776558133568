const getStoreOnlyAvailable = (rowData, currentMarketId) => {
    let attributes = rowData?.attributes ? rowData?.attributes : [];
    const storeOnlyAvailable = attributes && attributes.length > 0 ? attributes?.find((attr) => attr?.identifier === "STORE_ONLY_AVAILABLE") : false;
    if (storeOnlyAvailable && storeOnlyAvailable?.values && storeOnlyAvailable?.values?.length !== 0) {
        const isStoreOnlyAvailable = storeOnlyAvailable?.values[0].value.includes(currentMarketId);
        if (!isStoreOnlyAvailable) {
            attributes = attributes.filter((attr) => attr?.identifier !== "STORE_ONLY_AVAILABLE");
        }
    }
    return attributes;
}

export { getStoreOnlyAvailable };