import { CardMedia } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledBox, StyledTypography } from "../../elements";
import { Style } from "@material-ui/icons";
import { formatProductPrice } from "../../functions/utils";
import { WEIGHT_BOX, WEIGHT_LM, WEIGHT_MTK, WEIGHT_MTR } from "../../functions/constants";

export const ContainerStyledPriceBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

  &.product-card-precio {
    display: flex;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: 0px;
  }

  .price-card-img-height {
    height: 71px;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    // align-items: center !important;
  }

  .price-card-height {
    height: 71px;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    // align-items: center !important;
  }

  .colorHighlight {
    color: ${theme.palette.text.highlight};
  }

  .colorGray300Line {
    color: ${theme.palette.gray["300"]};
    text-decoration: line-through;
  }

  .promo_plp-ribbon-nlp {
    width: 40px;
    height: 40px;
    background-color: #FFF;
  }

  .superscript-text-price {
    font-size: 16px;
  }

  .superscript-mearure-type {
    font-size: 8px;
  }

  .superscript-small-price {
    font-size: 10px;
  }

  .styled--priceby-box {
    color: ${theme.palette.gray["200"]}
  }

`}
`;

export const HdmPriceBox = (props) => {
  const { price, isMeassure, quantityMeasure, weightMeasure, ahorro, ribbonImg, isSmallSize } = props;

  const { t } = useTranslation();

  let variantFont = "textPrice";
  let superscript = "superscript-text-price";

  if (isSmallSize) {
    variantFont = "textSecondaryPrice";
    superscript = "superscript-small-price";
  }

  let isValidweightMeasure = false
  if(weightMeasure){
    if(quantityMeasure === WEIGHT_MTR && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
    if(quantityMeasure === WEIGHT_MTK && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
  }
  
  if (isSmallSize)
    return (
      <ContainerStyledPriceBox className="">
        <StyledBox className="">
          {isMeassure ? (
            <StyledTypography variant={"bodySubText"} className="styled--priceby-box">
              {t("productBox.byMeter2")}
              {quantityMeasure === "m2" ? (
                <sup className="superscript-mearure-type">{quantityMeasure.substring(1)}</sup>
              ) : (
                ""
              )}
            </StyledTypography>
          ) : null}
          <StyledTypography variant={variantFont}>
            <sup className={superscript}>$</sup>
            {isMeassure && isSmallSize === "unitPrice" ? price.offerMedidaUnidad : price.offerUnidad}
            <sup className={superscript}>{isMeassure ? price.offerMedidaDecimal : price.offerDecimal}</sup>
          </StyledTypography>
        </StyledBox>
      </ContainerStyledPriceBox>
    );

  return (
    <ContainerStyledPriceBox className="product-card-precio">
      <StyledBox className="price-card-img-height right-margin-1">
        {ribbonImg ? (
          <>
            <CardMedia
              image={ribbonImg}
              className={"promo_plp-ribbon-nlp"}
              title={"PROMOICON_CE"}
              component="img"
            />
          </>
        ) : null}
      </StyledBox>
      <StyledBox className="price-card-height">
        {ahorro ? (
          <StyledTypography variant={"bodySubText"} className="colorHighlight">
            {t("productBox.Ahorras")} {`$${ahorro}`}
          </StyledTypography>
        ) : null}
        <StyledTypography variant={variantFont} className="product-price">
          <sup className={superscript}>$</sup>
          {isMeassure && isValidweightMeasure ? price.offerMedidaUnidad : price.offerUnidad !== "0" ? price.offerUnidad : price.displayPrice}
          <sup className={superscript}>{isMeassure ? price.offerMedidaDecimal : price.offerDecimal}</sup>
          &nbsp;
          { 
            quantityMeasure === "m2" ?
            <>
              m<sup className={superscript}>{quantityMeasure.substring(1)}</sup>
            </>
            : quantityMeasure?.length > 0 && quantityMeasure !== "c62" ?
            <>
              {quantityMeasure}
            </>
            :null
          }

        </StyledTypography>
        {price?.proRegularPrice ?
        <StyledBox display="flex">
        <StyledTypography variant={"bodySubText"} component="span" className="color-gray300">
          Precio regular{" "}
        </StyledTypography>
        <StyledTypography variant={"bodySubText"} className="colorGray300Line" component="span">
        {formatProductPrice(price.proRegularPrice)}
        </StyledTypography>
        </StyledBox>
        :
        <StyledTypography variant={"bodySubText"} className="colorGray300Line">
          {ahorro ? t("productBox.Antes") : ""}
          {ahorro && (quantityMeasure === "m2" || quantityMeasure === "m")  ? ` $${price.listMedidaPrice}` : ahorro ? ` $${price.listPrice}` : ""}
        </StyledTypography>
        }
      </StyledBox>
    </ContainerStyledPriceBox>
  );
};
