/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import styled from "styled-components";
import { StyledContentCarouselSlider, StyledProgressPlaceholder } from "../../elements";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Skeleton } from "@material-ui/lab";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { SLContentCarouselWidget } from "../../../components/custom-components/skeleton-loader/components/widgets/sl-content-carousel-widget";

const StyledContentCarouselLayout = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(4)}px 0;
  `}
`;

interface CarouselWidgetProps {
  slideList: any[];
  controls: any;
}

/**
 * Content Carousel Widget
 * For props definition, @see {@link CarouselWidgetProps}.
 * @param controls The controls prop which contains the carousel controls.
 * @param slideList The slideList prop which contains an Carousel Slides.
 */

const ContentCarouselWidget: React.FC<CarouselWidgetProps> = ({ slideList, controls }) => {

  const ContentCarouselComponent = ()=>{
    return (
      <>
        <StyledContentCarouselLayout>
            <LazyLoadComponent placeholder={<StyledProgressPlaceholder className="vertical-padding-20" />}>
              <StyledContentCarouselSlider slidesList={slideList} controls={controls} />
            </LazyLoadComponent> 
        </StyledContentCarouselLayout>
      </>
    );
  }

  return withSkeletonLoader(<ContentCarouselComponent/>, slideList && slideList.length > 0 , <SLContentCarouselWidget/>)
  
};

export { ContentCarouselWidget };
