import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { StyledBox, StyledDialog, StyledDialogContent, StyledDialogTitle } from "../../../hdm";
import { CLOSE_REGISTER_POPUP_ACTION } from "../../../redux/actions/drawer";
import { isOpenRegisterPopUp, isOpenSignInPopUp } from "../../../redux/selectors/drawer";
import { RegistrationLayout } from "./RegistrationLayout";


// interface ISignInPopUp {
    // isOpen: boolean;
    // onClose: (boolean) => void;
    // }

const SignInPopUpContainer = styled((props: any) => <StyledDialog {...props} />)`
    ${({ theme }) => `
        .MuiPaper-root.MuiDialog-paper {
            max-width: fit-content;
        }
        & .MuiDialogContent-root {
            padding: 0;
        }

        & .divider{
            margin: ${theme.spacing(2)}px 0;
        }

        & .forgot-password-link{
            margin: ${theme.spacing(4)}px 0;
        }

        & .no-account-title{
            padding: ${theme.spacing(2)}px 0;
        }
        
        & .validations-title{
            margin-top: ${theme.spacing(2)}px;
        }

        & .subscribe-checkbox{
            margin: ${theme.spacing(2)}px 0px;
        }

        & .terms-and-conditions{
            margin-bottom: ${theme.spacing(2)}px;
        }

        & .sign-in-link-container{
            margin-top: ${theme.spacing(2)}px;
        }
    `}
`;

const RegistrationPopUp = () => {

    const { t } = useTranslation();
    const isOpen = useSelector(isOpenRegisterPopUp)
    const dispatch = useDispatch<Dispatch<any>>();

    return(
        <SignInPopUpContainer 
            open={isOpen} 
            onClose={() => dispatch(CLOSE_REGISTER_POPUP_ACTION({}))} 
            aria-labelledby="confirm-dialog" 
            disableEnforceFocus
        >
            <StyledDialogTitle title={t("RegistrationLayout.Register")} onClickHandler={() => dispatch(CLOSE_REGISTER_POPUP_ACTION({}))}>

            </StyledDialogTitle>
            <StyledDialogContent>
                <RegistrationLayout isPopUp/>
            </StyledDialogContent>
        </SignInPopUpContainer>
    )
}

export default RegistrationPopUp;