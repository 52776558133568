import styled from "styled-components";
import { StyledBox } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes/color-palette";

export const ContainerSpecialTitle = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    .divider-title {
        width: calc((100% - 250px) / 2);
    }

    .specialTitle {
        color: ${palette.primary.main};
    }


`}
`;