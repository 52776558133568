// import { takeLatest, call } from "redux-saga/effects";
import { response } from "msw/lib/types";
import * as Effects from "redux-saga/effects";

//Redux
import * as ACTIONS from "../../action-types/hdmData";
import * as WORKERS from "../workers/hdmData";

const takeLatest: any = Effects.takeLatest;
const call: any = Effects.call;
const takeEvery: any = Effects.takeEvery;

export function* onLoadGetInventory({ query }) {
  try {
    yield call(WORKERS.onLoadGetInventory, query);
  } catch (error) {
    console.log("error", error);
  }
}

export function* watchSaga() {
  // yield takeLatest(ACTIONS.FETCH_HDM_DATA_REQUESTED, onLoadGetInventory);
  yield takeEvery(ACTIONS.FETCH_HDM_DATA_REQUESTED, onLoadGetInventory);
}
