import { setCookie } from "../styled-store-selector/cookieHandler";

export const getCookieValue = (cookieName) => {
  const cookieValue = document.cookie.match("(^|;)\\s" + cookieName + "\\s*=\\s*([^;]+)");
  return cookieValue ? cookieValue.pop() : null;
};

export const setCookieCAPI = (cookieName) => {
  const randNum = Math.floor(Math.random() * 50000) + 1;
  const unixTime = Math.floor(Date.now() / 1000);
  const eventId = randNum.toString() + unixTime.toString();

  setCookie(cookieName, eventId, 1);
  console.log("Cookie: " + cookieName + " was created successful");
  return eventId;
};
