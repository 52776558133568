import { palette } from "./color-palette";

const openSansLight = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 300,
  src: `
  local("Open Sans Light"),
  local("OpenSans-Light"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Light.woff2") 
  format("woff2")
  `,
};

const openSansRegular = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
  local("Open Sans Regular"),
  local("OpenSans-Regular"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Regular.woff2") 
  format("woff2")
  `,
};

const openSansSemiBold = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 600,
  src: `
  local("Open Sans SemiBold"),
  local("OpenSans-SemiBold"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-SemiBold.woff2") 
  format("woff2")
  `,
};

const openSansBold = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
  local("Open Sans Bold"),
  local("OpenSans-Bold"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Bold.woff2") 
  format("woff2")
  `,
};

const helveticaNeueTHDNormal = {
  fontFamily: "HelveticaNeue_THD",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
  local("Helvetica Neue LT Std"),
  url("https://cdn.homedepot.com.mx/assets/fonts/HelveticaNeueLTStd-HvCn.woff2") 
  format("woff2")
  `,
};

const helveticaNeueTHDBold = {
  fontFamily: "HelveticaNeue_THD",
  fontStyle: "normal",
  fontWeight: 600,
  src: `
  local("Helvetica Neue LT Std"),
  url("https://cdn.homedepot.com.mx/assets/fonts/HelveticaNeueLTStd-BlkCn.woff2") 
  format("woff2")
  `,
};

export const fontFaceHDM = [
  openSansLight,
  openSansRegular,
  openSansSemiBold,
  openSansBold,
  helveticaNeueTHDNormal,
  helveticaNeueTHDBold,
];

export const typography = {
  fontFamily: ["Open Sans", "Helvetica Neue", "Arial", "sans-serif"].join(","),
  fontSize: 15,
  fontWeight: 400,
  lineHeight: 1.2,
  color: palette.gray["500"],
  searchResults: {
    color: palette.text.primary,
    fontSize: "15px",
  },
  searchBarTitles: {
    fontWeight: 600,
    color: palette.text.disabled,
    fontSize: "15px",
  },
  searchResultsCategoriesAndBrands: {
    color: palette.secondary.main,
    fontSize: "15px",
  },

  headingDisplay: {
    fontSize: "44px",
    fontWeight: 400,
    fontFamily: "HelveticaNeue_THD",
  },
  headingMainTitle: {
    fontSize: "36px",
    fontWeight: 300,
  },
  headingHeadline: {
    fontSize: "30px",
    fontWeight: 300,
  },
  headingSubhead: {
    fontSize: "27px",
    fontWeight: 300,
  },
  headingTitle: {
    fontSize: "25px",
    fontWeight: 400,
  },
  headingSubtitle: {
    fontSize: "20px",
    fontWeight: 400,
  },
  headingFeatured: {
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: "HelveticaNeue_THD",
    lineHeight: "1.2",
    textTransform: "uppercase",
  },
  textPrice: {
    fontSize: "27px",
    fontWeight: 700,
  },
  textSecondaryPrice: {
    fontSize: "15px",
    fontWeight: 700,
  },
  textSegment: {
    fontSize: "18px",
    fontWeight: 300,
  },
  textButtons: {
    fontSize: "16px",
    fontWeight: 600,
  },
  bodyBaseline: {
    fontSize: "15px",
    fontWeight: 400,
  },
  bodySubText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  bodyCaption: {
    fontSize: "10px",
    fontWeight: 400,
  },
};
