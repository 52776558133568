import React, { FC, useEffect, useState } from "react";
import {
  StyledBox,
  StyledFormControlLabel,
  StyledGrid,
  StyledTypography,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledListItemText,
  StyledCheckbox,
} from "../../../hdm";

//UI
import { ReactComponent as HandyMan } from "../../../assets/homedepot/images/handyman.svg";
import { Checkbox, Hidden, SvgIcon } from "@material-ui/core";
import { ProductInstalationContainer } from "./ProductInstalationHDMStyles";
import StyledSwipeableDrawerHDM from "../styled-drawer-hdm/StyledDrawerHDM";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Helpers
import { formatProductPrice } from "../../../hdm/functions/utils";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SET_HDM_INSTALLATION_DATA } from "../../../redux/actions/hdmData";
import { useTranslation } from "react-i18next";
import EspotHdm from "../shared/EspotHdm";
import { useNavigate } from "react-router";
import { TERMS_CONDITIONS } from "../../../constants/routes";
import ButtonHDM from "../styled-cart-drawer/cart-item/ButtonHDM";
import { ContainerStyledTypography } from "../../../hdm/components/pdp-disponibilidad/containers-disponibilidad";

import getDisplayName from "react-display-name";
import Axios, { Canceler } from "axios";
import { useMemo } from "react";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { useSite } from "../../../_foundation/hooks/useSite";
import * as orderActions from "../../../redux/actions/order";
import InputQuantityHDM from "../shared/input-quantity-hdm/InputQuantityHDM";
import { Box, Grid } from "@material-ui/core";
import { tabletBreakpoint } from "../../../hdm/functions/utils";
import ProductQuantity from "../styled-cantidad-producto/ProductQuantity";
import { numberWithCommas } from "../../../hdm/functions";
import { getCookieValue, setCookieCAPI } from "../conversion-api/cookieHandler";
import { AddSharp } from "@material-ui/icons";
import { StyledPromp } from "../styled-promp/StyledPromp";
import styled from "styled-components";
import { StyledLinkButton } from "../styled-store-selector/styled-link-button";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { LoaderActions } from "../skeleton-loader/actions/sl-actions";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";

interface IProductInstalationHDMProps {
  installationIsChecked: boolean;
  servicesInCart: any;
  cartQty: any | number;
  isCart: boolean;
  merchandisingAssociations: any;
}
const StyledAccordionDetailsHDM = styled((props: any) => <StyledAccordionDetails {...props} />)`
  ${({ theme, accordionMaxHeight }) => `
      display: block;
      width: 100%;
      min-height: ${accordionMaxHeight ? accordionMaxHeight : ""}px;
  `}
`;

const StyledAccordionSummaryHDM = styled((props: any) => <StyledAccordionSummary {...props} />)`
  ${({ theme }) => `
      font-weight: 600;
      font-size: 1.15em;
      background-color: #eee;
  `}
`;
const ProductInstalationHDM: FC<IProductInstalationHDMProps> = ({
  installationIsChecked,
  isCart,
  servicesInCart,
  cartQty,
  merchandisingAssociations,
}) => {
  const { mySite } = useSite();
  const { partNumber } = merchandisingAssociations;
  const currentStore = useSelector(currentStoreSelector);
  const currentStoreId = currentStore.currentStore.stLocId;
  const storeId: string = mySite ? mySite.storeID : "";
  const widgetName = getDisplayName("ProductDetailsWidget");
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const contract = useSelector(currentContractIdSelector);
  const [colorCode, setColorCode] = useState<any>(null);
  const installationName = merchandisingAssociations?.name;
  const [partNumberID, setPartNumberID] = useState<string>("");
  const [partNumberType, setPartNumberType] = useState<string>("");
  const [installationPartNumber, setInstallationPartNumber] = useState<string>("");
  const [shortDescription, setShortDescription] = useState<string>("");
  const [longDescription, setLongDescription] = useState<Array<any>>([]);
  const [instalationPrice, setInstalationPrice] = useState<number>(-1);
  const [showItem, setShowItem] = React.useState<Array<boolean>>([false, false, false, false]);

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector)

  const navigate = useNavigate();
  const { t } = useTranslation();
  const installationSpecificationsDrawerTitle = t("ProductInstalation.installationSpecifications");
  const howWorksList = t("ProductInstalation.howWorksList");
  const partNumberList: string[] = [];

  const styles: object = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(230,230,230,0.3)",
    zIndex: "999"
  }

  try {
    Axios.get<any>(`/wcs/resources/store/${mySite.storeID}/cart/@self?sortOrderItemBy=orderItemID`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        for (const orderItem of response.data.orderItem) {
          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
          }
        }
      }
    });
  } catch (error) {
    console.log("error", error);
  }
  // merchandisingAssociations should contain only the TV or SI to show, 1 TV/SI per widget
  useEffect(() => {
    const getLongDesc = async (partNumber: string) => {
      await Axios.get<any>(
        `/search/resources/api/v2/products?storeId=${mySite.storeID}&contractId=${contract}&langId=-5&partNumber=${partNumber}&physicalStoreId=${currentStoreId}`
      )
        .then((response) => response.data)
        .then((response) => {
          const { contents } = response;
          if (contents && contents?.length > 0) {
            setLongDescription(contents[0]?.longDescription?.split("|"));
          }
        });
    };
    if (merchandisingAssociations && longDescription.length === 0 && isCart) {
      getLongDesc(merchandisingAssociations?.partNumber);
    }
  }, [merchandisingAssociations, longDescription, isCart]);
  if (
    merchandisingAssociations !== undefined &&
    merchandisingAssociations &&
    longDescription.length === 0 &&
    shortDescription === "" &&
    partNumberID === "" &&
    partNumberType === ""
  ) {
    getServiceData(merchandisingAssociations);
  }
  const numberOfInstallations: any | number = partNumberType != "TV" ? cartQty : 1;
  const dispatch = useDispatch<Dispatch<any>>();

  /* States */
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(instalationPrice === 1); // installation is free when installation price is 1
  // const [isSIChecked, setIsSIChecked] = useState<boolean>(installationIsChecked);
  /* Open instalation specs drawer */
  const handleClick = () => {
    setOpenDrawer(true);
  };
  const handleRemove2Cart = () => {
    const addedServiceList: Array<any> = [];
    if (partNumberList != null) {
      partNumberList.forEach((item) => {
        if (item.includes("TV")) {
          const serviceId = item.split("_")[0];
          addedServiceList.push(serviceId);
        }
      });
    }
    if (addedServiceList.includes(installationPartNumber.split("_")[0].trim())) {
      //<StyledPromp variant="alert" message={"Selected technical visit service already added"} />
      alert("Selected technical visit service already added");
    } else {
      addToCart(installationPartNumber, numberOfInstallations);
    }
  };
  const handleAdd2Cart = () => {
    dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_TV_TO_CART, idx: partNumber }))
    const addedServiceList: Array<any> = [];
    if (partNumberList != null) {
      partNumberList.forEach((item) => {
        if (item.includes("TV")) {
          const serviceId = item.split("_")[0];
          addedServiceList.push(serviceId);
        }
      });
    }
    if (addedServiceList.includes(installationPartNumber.split("_")[0].trim())) {
      //<StyledPromp variant="alert" message={"Selected technical visit service already added"} />
      alert("Selected technical visit service already added");
    } else {
      addToCart(installationPartNumber, numberOfInstallations);
    }
  };
  const onQuantityUpdate = (quantityString: string, item: any) => {
    const payloadBase: any = {
      storeId: storeId,
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    const payload = {
      ...payloadBase,
      orderItemId: item,
      fetchCatentries: true,
    };
    dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
  };

  const addToCart = (productPartNumber: string, productQuantity: string) => {
    // const installationPartNumberData = "746_SI_906207"

    const hasInstallation = true;
    const payloadBase: any = {
      storeId: storeId,
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };

    const eventId = setCookieCAPI("mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);
    const pstore = getCookieValue(`WC_DefaultStore_${storeId}`);
    const param = {
      partnumber: productPartNumber,
      quantity: productQuantity ? productQuantity : 1,
      contractId: contract,
      colorCode: colorCode,
      mfbt: eventId,
      fbc: fbc,
      fbp: fbp,
      pstore: pstore,
      userAgent: userAgent,
      marketId: marketId,
      ...payloadBase,
    };

    dispatch(orderActions.ADD_ITEM_ACTION(param));
  };

  const handleTerms = () => {
    navigate(TERMS_CONDITIONS);
  };

  const handleChecked = (checkedValue: boolean) => {
    setIsChecked(checkedValue);
  };
  const handleCheckedInst = () => {
    dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_TV_TO_CART, idx: partNumber }))
    if (installationIsChecked) {
      // servicesInCart contains partnumber and orderItemId of the service
      servicesInCart.forEach((sku) => {
        const partNumber = sku.split("/")[0];
        const orderItemId = sku.split("/")[1];
        if (partNumber.includes(merchandisingAssociations.partNumber)) {
          onQuantityUpdate("0", orderItemId);
        }
      });
    } else {
      handleAdd2Cart();
    }

    // setIsSIChecked((...prevState) => !prevState);
  };

  // const State = useSelector<any>((state) => state.hdmDataReducer);

  function getServiceData(data: any) {
    let displayOfferPrice = Number.POSITIVE_INFINITY;
    setInstallationPartNumber(data.partNumber);
    setPartNumberType(data.partNumber.split("_")[1]);
    setPartNumberID(data.partNumber.split("_")[0]);
    setShortDescription(data.shortDescription);
    setLongDescription(data.longDescription?.split("|") ?? []);
    if (data.price) {
      for (const offer of data.price) {
        if (offer.value !== "" && parseFloat(offer.value) < displayOfferPrice) {
          displayOfferPrice = parseFloat(offer.value);
          setInstalationPrice(!isNaN(displayOfferPrice) ? displayOfferPrice : -1);
        }
      }
    } else {
      displayOfferPrice = parseFloat(data.unitPrice);
      setInstalationPrice(!isNaN(displayOfferPrice) ? displayOfferPrice : -1);
    }

    return displayOfferPrice;
  }

  useEffect(() => {
    /* Put data on redux state */
    if (isChecked) {
      dispatch(
        SET_HDM_INSTALLATION_DATA({
          installationPartNumber: installationPartNumber,
          installationPrice: instalationPrice,
        })
      );
    }
    /* Remove data from redux state */
    if (!isChecked) {
      dispatch(
        SET_HDM_INSTALLATION_DATA({
          installationPartNumber: "",
          installationPrice: 0,
        })
      );
    }
    /* Cleanup */
    return () => {
      dispatch(
        SET_HDM_INSTALLATION_DATA({
          installationPartNumber: "",
          installationPrice: 0,
        })
      );
    };
  }, [isChecked, dispatch, installationPartNumber, instalationPrice]);

  const toggleExpand = (item) => {
    const arr = [false, false, false, false];
    arr[item - 1] = !showItem[item - 1];
    setShowItem(arr);
  };

  const shouldShowOverlay = (partNumber) => {
    return (loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART ||
      loaderState.src === LoaderActions.DELIVERY_TYPE_CHANGE ||
      loaderState.src === LoaderActions.ADD_TV_TO_CART) &&
      loaderState.idx !== partNumber
  }

  const ItemCardEspot = (props) => {
    const item = props.item;
    const listItems: Array<any> = props.items ? props.items.split("-") : [];
    const list =
      listItems &&
      listItems.map(
        (item) =>
          item !== "" && (
            <div className={props.icon === "check" ? "check" : ""}>
              {props.icon === "check" && <CheckIcon />}
              <li>{item.trim()}</li>
            </div>
          )
      );
    const classes =
      props.icon === "bullet"
        ? "popup__body-list popup__body-list--bullet"
        : "popup__body-list popup__body-list--checked";
    return (
      <StyledGrid item xs={12} className={"card-drawer-details-container"}>
        <StyledAccordion
          expanded={props.open}
          onClick={() => toggleExpand(item)}
          className={"card-drawer-details-container"}
          square={false}>
          <StyledAccordionSummaryHDM disableRipple={true} expandIcon={<ExpandMoreIcon />}>
            <StyledTypography className={"title"}>{props.title}</StyledTypography>
          </StyledAccordionSummaryHDM>
          <StyledBox>
            <StyledAccordionDetailsHDM onClick={() => toggleExpand(item)}>
              {/* <StyledListItem > */}
              {!props.howWorks ? (
                <ul className={classes}>
                  <StyledListItemText primary={<StyledTypography variant={"bodyBaseline"}>{list}</StyledTypography>} />
                </ul>
              ) : (
                <ol className="popup__body-list">
                  <StyledListItemText primary={<StyledTypography variant={"bodyBaseline"}>{list}</StyledTypography>} />
                </ol>
              )}
              {/* </StyledListItem> */}
            </StyledAccordionDetailsHDM>
          </StyledBox>
        </StyledAccordion>
      </StyledGrid>
    );
  };



  return (
    <>
      {/* !isCart = is PDP  */}
      {merchandisingAssociations && instalationPrice >= 1 ? (
        <>
          <ProductInstalationContainer
            service={partNumberType}
            installationIsChecked={installationIsChecked}
            isCart={isCart}
            style={{ position: "relative" }}
          >
            {shouldShowOverlay(partNumber) && <div style={styles}></div>}
            <StyledGrid container className="technical-visits-container">
              <StyledGrid container className="sub-container">
                {isCart && partNumberType == "TV" && installationIsChecked && (
                  <StyledGrid item md={12} className="remove-item">
                    <StyledLinkButton className="delete-from-cart" onClick={handleCheckedInst} variant="bodyBaseline">
                      {t("productDetail.CartOrderDeleteItem")}
                    </StyledLinkButton>
                  </StyledGrid>
                )}
                {!isCart && (
                  <StyledGrid item md={12} className="title-container">
                    <SvgIcon className="handyman-icon">
                      <HandyMan />
                    </SvgIcon>
                    <StyledTypography variant="textSegment" className="instalation-card-title">
                      {"Nosotros lo instalamos por ti"}
                    </StyledTypography>
                  </StyledGrid>
                )}

                {isCart && partNumberType == "TV" && installationIsChecked && (
                  <StyledGrid item md={12}>
                    <StyledTypography className="product-brand" variant="bodyBaseline">
                      {`ID: ${partNumberID}`}
                    </StyledTypography>
                  </StyledGrid>
                )}
                {/* Header Services and Installations */}
                <StyledGrid item md={12} className="services-header margin-bottom">
                  {partNumberType == "TV" ? (
                    <StyledTypography variant="bodyBaseline" weight="semibold">
                      {!isCart ? `Solicita una ${installationName}` : installationName}
                    </StyledTypography>
                  ) : isCart ? (
                    <StyledFormControlLabel
                      control={
                        <StyledCheckbox
                          size="small"
                          color="primary"
                          checked={installationIsChecked}
                          onChange={handleCheckedInst}
                        />
                      }
                      label={
                        <StyledBox className="first-product-label-container">
                          <StyledTypography variant="bodyBaseline" weight="semibold">
                            {`Incluir instalación de ${installationName}`}
                          </StyledTypography>
                        </StyledBox>
                      }
                    />
                  ) : (
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          defaultChecked={isChecked}
                          onChange={(event) => handleChecked(event.target.checked)}
                        />
                      }
                      label={
                        <StyledBox className="first-product-label-container">
                          <StyledTypography variant="bodyBaseline" weight="semibold">
                            {`Incluir instalación de ${installationName}`}
                          </StyledTypography>
                        </StyledBox>
                      }
                    />
                  )}
                  {isCart && partNumberType == "TV" && !installationIsChecked && (
                    <StyledTypography
                      variant="bodyBaseline"
                      className="drawer-link"
                      component="span"
                      onClick={handleClick}>
                      &nbsp;{t("ProductInstalation.included")}
                    </StyledTypography>
                  )}
                </StyledGrid>
                {partNumberType === "TV" && isCart && !installationIsChecked ? (
                  <></>
                ) : (
                  <StyledGrid item md={12} className="service-description margin-bottom">
                    <StyledTypography variant="bodyBaseline">
                      {installationIsChecked && partNumberType === "TV"
                        ? "Un profesional acudira a tu domicillio para evaluar tus necesidades de instalacion."
                        : shortDescription}
                      <StyledTypography
                        variant="bodyBaseline"
                        className="drawer-link"
                        component="span"
                        onClick={handleClick}>
                        &nbsp;{t("ProductInstalation.included")}
                      </StyledTypography>
                    </StyledTypography>
                  </StyledGrid>
                )}

                <StyledGrid container md={12} className="qty-and-subtotal margin-bottom">
                  {(partNumberType != "TV" || (partNumberType == "TV" && installationIsChecked && isCart)) && (
                    <StyledGrid item md={6} className="">
                      <StyledTypography variant="bodySubText">
                        {`Cantidad: `}
                        <StyledTypography variant="bodyCaption" component="span" weight="semibold">
                          {parseInt(numberOfInstallations)}
                        </StyledTypography>
                      </StyledTypography>
                    </StyledGrid>
                  )}

                  {/* Priced installation */}
                  {instalationPrice > 1 && (
                    <StyledGrid item md={6} className="service-subtotal-a2c">
                      {partNumberType != "TV" || (partNumberType == "TV" && installationIsChecked && isCart) ? (
                        <StyledTypography variant="bodySubText">
                          {`Subtotal: `}
                          <StyledTypography variant="bodyCaption" component="span" weight="semibold">
                            {formatProductPrice(instalationPrice * numberOfInstallations)}
                          </StyledTypography>
                        </StyledTypography>
                      ) : (
                        <StyledTypography variant="bodySubText" component="span">
                          {`Precio: `}
                          <StyledTypography
                            variant="bodyCaption"
                            className="price-numeric"
                            component="span"
                            weight="semibold">
                            {/* {parseFloat(formatProductPrice(instalationPrice * numberOfInstallations)).toFixed(2)} */}
                            {formatProductPrice(instalationPrice * numberOfInstallations)}
                          </StyledTypography>
                        </StyledTypography>
                      )}
                    </StyledGrid>
                  )}

                  {instalationPrice <= 1 && (
                    <StyledGrid item md={6} className="subtotal-container_inst_cart">
                      <StyledTypography variant="bodySubText">
                        {`Subtotal: `}
                        <StyledTypography
                          className="free-installation-text"
                          variant="bodySubText"
                          component="span"
                          weight="semibold">{`Gratis`}</StyledTypography>
                      </StyledTypography>
                    </StyledGrid>
                  )}
                  {partNumberType == "TV" && !installationIsChecked && (
                    <StyledTypography
                      variant="bodyCaption"
                      className="aggregar-link"
                      component="span"
                      onClick={handleAdd2Cart}>
                      <SvgIcon className="Addsharp">
                        <AddSharp />
                      </SvgIcon>

                      {`Agregar visita técnica`}
                    </StyledTypography>
                  )}
                  {partNumberType == "TV" && installationIsChecked && !isCart && (
                    <StyledTypography variant="bodyCaption" className="already-added-link" component="span">
                      {`Visita técnica agregada`}
                    </StyledTypography>
                  )}
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            {!isCart && (
              <StyledTypography variant="bodyCaption" className="terms-and-conditions-container">
                {`Consulta los `}
                <StyledTypography
                  variant="bodyCaption"
                  component="span"
                  className="terms-and-conditions-link"
                  onClick={handleTerms}>
                  {`Términos y Condiciones `}
                </StyledTypography>
                {`de servicios de instalación`}
              </StyledTypography>
            )}

            <StyledSwipeableDrawerHDM
              setOpenDrawer={setOpenDrawer}
              isOpen={openDrawer}
              variant="instalation"
              title={installationName}>
              <StyledGrid container className="installation-specs-drawer-container" style={{ padding: "10px" }}>
                <StyledGrid item xs={12}>
                  <StyledTypography style={{ fontSize: "14px", marginBottom: "15px" }}>
                    {shortDescription}
                  </StyledTypography>
                </StyledGrid>
                <StyledGrid item xs={12}>
                  {longDescription &&
                    longDescription.length > 0 &&
                    longDescription.map((items, key) => {
                      if (key == 1 && items !== "") {
                        return (
                          <ItemCardEspot
                            open={showItem[key - 1]}
                            item={key}
                            icon="check"
                            title={t("ProductInstalation.included")}
                            items={items}
                          />
                        );
                      }
                      if (key == 2 && items !== "") {
                        return (
                          <ItemCardEspot
                            open={showItem[key - 1]}
                            item={key}
                            icon="bullet"
                            title={t("ProductInstalation.notIncluded")}
                            items={items}
                          />
                        );
                      }
                      if (key == 3 && items !== "") {
                        return (
                          <ItemCardEspot
                            open={showItem[key - 1]}
                            item={key}
                            icon="bullet"
                            title={t("ProductInstalation.materials")}
                            items={items}
                          />
                        );
                      }
                    })}
                  <ItemCardEspot
                    open={showItem[3]}
                    item={4}
                    howWorks={true}
                    icon=""
                    title={t("ProductInstalation.howWork")}
                    items={howWorksList}
                  />
                </StyledGrid>
                <StyledTypography variant="bodyCaption" style={{ size: "14px" }}>
                  {`Consulta los `}
                  <StyledTypography
                    variant="bodyCaption"
                    component="span"
                    style={{ color: "#0288d1", cursor: "pointer" }}
                    onClick={handleTerms}>
                    {`Términos y Condiciones `}
                  </StyledTypography>
                </StyledTypography>
                <StyledGrid item style={{ visibility: "hidden" }}>
                  <EspotHdm espotName="PDP-Drawer_Installation" />
                </StyledGrid>
              </StyledGrid>
            </StyledSwipeableDrawerHDM>
          </ProductInstalationContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductInstalationHDM;
