/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//standard libraries
import HTMLReactParser from "html-react-parser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
//hcl libraries
import { marketingConstants } from "@hcl-commerce-store-sdk/utils";
//local
import { DXContent, DXVideoContent } from "../../components";
import { StyledProgressPlaceholder } from "../../elements";
import { EMarketingSpotWidgetProps } from "../../types";
import DangerouslySetHtmlContent from "../../../components/custom-components/DangerouslySetHtmlContent/DangerouslySetHtmlContent";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { SLContentCarouselWidget } from "../../../components/custom-components/skeleton-loader/components/widgets/sl-content-carousel-widget";
import { Skeleton } from "@material-ui/lab";
import withEspotSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withEspotSkeletonLoader";

/**
 * Content recommendation widget.
 * For props definition, @see {@link EMarketingSpotWidgetProps}.
 * @param props The props for `ContentRecommendationWidget`, which contains an eSpot object.
 */
export const ContentRecommendationWidget: React.FC<EMarketingSpotWidgetProps> = ({ eSpot, emsName, ...props }) => {
  const { content } = eSpot;
  const { behavior } = eSpot;


  const [espotName, setEspotName] = useState(emsName);

  if (emsName === "ModulosUI_Mejores_Promos") { 
    //
  }


  const Component = ()=> useMemo( () => {

    return (
      <>
        <LazyLoadComponent
          visibleByDefault={(window as any).__isPrerender__ || false}
          placeholder={<StyledProgressPlaceholder className="vertical-padding-20" />}
          style={{display:"hidden"}}
          >
          {content.title && <h2>{HTMLReactParser(content.title)}</h2>}
          {content.templates.map((t: any) => {
            return t.isDxContent ? (
              t.contentType === marketingConstants.CONTENT_TYPE_VIDEO ? (
                <DXVideoContent key={t.id} {...t} />
              ) : (
                <DXContent key={t.id} {...t} />
              )
            ) : (
              <>
                {t?.marketingText ? (
                  <>
                    <DangerouslySetHtmlContent html={t.marketingText} />
                  </>
                ) : (
                  <React.Fragment key={t.id}>{t.template}</React.Fragment>
                )}
              </>
            );
          })}
        </LazyLoadComponent>
      </>
    )
  },[espotName]);


  /*
    Logo espot *_Logo
  */
  const ComponentWithLoader = useMemo(()=>{
    return withEspotSkeletonLoader(<Component/>,content.templates.length>0 || behavior !== undefined, espotName)
  },[content])

  return (
    <>
      {/*
        Html information is inside content.template
      * */}
      {content && (
        ComponentWithLoader
      )}
    </>
  );
};
