import React from "react";
import visa from "../../../assets/homedepot/images/visa-color.png";
import mc from "../../../assets/homedepot/images/mastercard-color.png";
import amex from "../../../assets/homedepot/images/amex-color.png";


const SavedCardImage = (props) => {



    const srcImage = {
      "visa": visa,
      "master card": mc,
      "amex": amex
    }

    const image = srcImage[props.cardType.toLowerCase()];

    return <>
      <img
        src={image}
        alt="logo home depot"
        height={16}
        style={{
          marginRight: 5,
          display: "block",
        }}
        className="lazyload"
      /></>
  }
export default SavedCardImage;