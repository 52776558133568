/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";


/**
 * see `CircularProgress` @material-ui/core/CircularProgress
 */
const StyledCircularProgress = styled(({ ...props }) => <CircularProgress {...props} />)`
  ${({ theme }) => `
    color: white;
  `}
`;
export { StyledCircularProgress };
