/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
import styled from "styled-components";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import { palette } from "../../../../themes/themes";

export const StyledTable = styled(Table)`
  ${({ theme }) => `
      text-align: left;
      font-size: 14x;
      border-radius: ${theme.shape.borderRadius}px;
      border-color: ${theme.palette.text.disabled};
      border-collapse: collapse;

      .MuiAvatar-root {
        margin: 0 auto;
        width: 60px;
        height: 60px;
        border-radius: 0;
 
        img {
          max-height: 100%;
          object-fit: contain;
        }
      }

      & .mini-imagex{
        padding: 0px 10px;
  
        & a{
          display: flex;
          justify-content: center;
          align-items: center
        }
        & img{
          height: 60px;
          width: 60px;
          min-width: 60px;
          min-height: 60px;
        } 
      }

    & .productDetails--styledGrid-productInformationContainerx{
      text-align: start;
      max-width: 320px;
      width: 100%;
    }
    
    & .product-name{
      padding-right: 5px;
      padding-left: 5px;
      word-break: break-word;
    }

    & .product-NoBrand{
      padding-right: 5px;
      padding-left: 0px;
      word-break: break-word;
    }

    .product-sku {
      color: ${theme.palette.gray[300]};
    }

    & .product-name-noManufacturer{
      padding-right: 5px;
      word-break: break-word;
    }

      &.mini-cart-table, &.order-item-table {
        td {
          vertical-align: middle;
        }
      }
 
      &.mini-cart-table {
        border: 0;
 
        td {
          padding: 0 !important;
          display: flex;
          flex-direction: row;
          margin: 10px 0px 10px 10px;
        }
 
        thead {
          display: none;
        }
 
        max-width: 350px;
 
        .MuiPaper-root {
          box-shadow: none;
          padding-left: 6px;
        }
 
        .MuiTableRow-root {
          td:first-child{
            padding-left: 0;
            padding-right: 0;
          }
          td:last-child{
            padding-right: 0;
          }
        }
 
        .MuiAvatar-root {
          margin-left: 0;
          width: ${theme.spacing(13)}px;
          height: ${theme.spacing(11)}px;
          border: 1px solid ${theme.palette.grey[200]};
        }
 
        .MuiGrid-justify-content-xs-space-between{
 
        }
 
        .qty-price-section {
          padding: 0;
          max-width: 131px;
           & b:nth-child(n){
             width: 38px;
             &:hover{
                 background: none;
                 & i {
                   // background:${theme.palette.main}
                   background:red;
                 }
                 }
                 &:active{
                   background-color:${theme.palette.primary.main};
                 }
           }
           & b:last-child{
             color: purple;
             border-right: 1px solid #CCCCCC;
           }
           & b:nth-child(2){
             border-left: 1px solid #CCCCCC;
           }
         }
 
         .qty-price{
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            margin-bottom: ${theme.spacing(2)}px;
         }
       }
 
      &.detailPanel {
        thead {
          > tr {
            border: 0;
            text-transform: uppercase;
          }
        }
      }
 
      @media screen and (max-width: 40em) {
        &.responsiveTable {
          background: none;
          border: 0;
          
          tbody {
            tr {
               border-bottom: 1px solid #eee !important;
               border: 0;
               padding: 0;
               margin-bottom: 0;
               background: none;
            }
 
            td {
              &:nth-child(odd) {
               //  background: #fafafa;
              }
            }
          }
        }
      }
      &.order-item-table td {
        vertical-align: middle;
        padding: 0px !important;
      }

      &.order-item-table tr {
        margin-bottom: 0px;
      }
      
    `}
`;

export const StyledThead = styled(Thead)`
  ${({ theme }) => `
      tr {

      }
    `}
`;

export const StyledTbody = styled(Tbody)`
  ${({ theme }) => `
      tr:nth-child(odd) {
        background: #fffff;
      }
    `}
`;

export const StyledTr = styled(Tr)`
  ${({ theme }) => `
      line-height: 1.25rem;
      vertical-align: baseline;
      @media screen and (max-width: 40em) {
        &.detailPanel {
          display: none;
        }
      }
    `}
`;

export const StyledTh = styled(Th)`
  ${({ theme }) => `
      font-weight: 700;
      padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px;
 
      @media screen and (max-width: 40em) {
        &.hideOnMobile {
          display: none;
        }
      }
    `}
`;

export const StyledTd = styled(Td)`
  ${({ theme }) => `
      font-weight: 400;
      padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px;
      > div.tdBefore {
        line-height: normal;
      }
      @media screen and (min-width: 40em) {
        > div.tdBefore {
          display: none;
        }
      }
      @media screen and (max-width: 40em) {
        &.hideOnMobile {
          display: none;
        }
      }
    `}
`;
