import { Dimension } from "../dimensions";


export const SLFooterElements = {
    tyc: new Dimension(442,18),
    newsletter: {
        title: new Dimension(640,26),
        subtitle: new Dimension(640,36)
    },
    slogan : new Dimension(282,30),
    contacts : [ new Dimension(100,100) ],
    navigation: {
        title: new Dimension(230,24),
        chevron: new Dimension(26,26),
        elem1: new Dimension(220,20),
        elem2: new Dimension(160,20)
    }
    
}