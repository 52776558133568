import { Skeleton } from "@material-ui/lab";
import { StyledTypography } from "../../../../hdm/elements";
import { typography } from "../../../../hdm/themes/typography";

interface skeletonDimensions  {
    width:number,
    height:number
}
interface SLStyledTypographyProps {
    options?: skeletonDimensions;
    [key: string]: any; // Allow any other prop
}

export const SLStyledTypography = ({options, ...props}:SLStyledTypographyProps)=>{
    let variantHeight;
    if(props.variant){
        const pxSize= typography[props.variant].fontSize;
        variantHeight = Number(pxSize.split("px")[0]);
    }
    //default values
    let width = 150;
    let height = variantHeight??20;
    if(options){
        width= options.width;
        height= options.height;
    }
    return (
        props.children 
        ?
            <>
                <StyledTypography {...props}>
                        {props.children}
                </StyledTypography>
            </>
        :
            <>
                <Skeleton variant="rect" width={width} height={height} />
            </>
        
    );
}