import { StyledDialog } from "../../../../elements/dialog";
import { StyledIconButton } from "../../../../elements/icon-button";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import { Divider } from "@material-ui/core";
import { palette } from "../../../../themes/color-palette";
import DialogContent from "@material-ui/core/DialogContent";
import AhorraMasProducto from "./AhorraMasProducto";

import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledButton,
} from "../../containers-precio-promocion";
import AhorraMasSeccionUno from "./AhorraMasSeccionUno";
import AhorraMasSeccionDos from "./AhorraMasSeccionDos";
import { StyledLink } from "../../../../elements";
import { Link } from "react-router-dom";

interface Props {
  brand: any;
  attributes: any;
  partNumber: any;
  shortDescription: any;
  nominalQuantity: any;
  openProductos: boolean;
  ahorramasValues: any;
  ahorraMas: any;
  handleCloseProductos: () => void;
  articulos: any;
}

const AhorraMasDialog = (props: Props) => {
  const { openProductos, handleCloseProductos, articulos, ahorramasValues, ahorraMas, brand, attributes, partNumber, shortDescription, nominalQuantity } = props;

  let allProducts = '/search?searchTerm='
  articulos.map((articulo, index) => {
    if(index === 0){
      allProducts = `${allProducts}${articulo?.partNumber}`

    }else{
      allProducts = `${allProducts}, ${articulo?.partNumber}`

    }
  })

  return (
    <>
      <StyledDialog open={openProductos} onClose={handleCloseProductos} fullScreen scroll={"paper"}>
        <ContainerStyledBox className="dialog-ahorramas-container">
          <ContainerStyledBox width={1 / 6}></ContainerStyledBox>
          <ContainerStyledBox width={4 / 6} className="dialog-titulo">
            <ContainerStyledTypography align="center" variant="textSegment">
              Ahorra más
            </ContainerStyledTypography>
          </ContainerStyledBox>
          <ContainerStyledBox width={1 / 6} className="dialog-close">
            <StyledIconButton aria-label="cerrar" className="dialog-close" size="small" onClick={handleCloseProductos}>
              <CancelOutlinedIcon fontSize="small" style={{ color: palette.gray["100"] }} />
            </StyledIconButton>
          </ContainerStyledBox>
        </ContainerStyledBox>

        <Divider style={{ backgroundColor: palette.gray["50"] }} />

        <DialogContent dividers={true} style={{ padding: 0 }}>
          <AhorraMasSeccionUno
          brand={brand}
          attributes={attributes}
          partNumber={partNumber}
          shortDescription={shortDescription}
          nominalQuantity={nominalQuantity}
          handleClose={handleCloseProductos}
          ahorramasValues={ahorramasValues} />
          <AhorraMasSeccionDos ahorramasValues={ahorramasValues} ahorraMas={ahorraMas} />

          {articulos &&
            articulos.map((articulo, index) => {
              return <AhorraMasProducto key={index} articulo={articulo} />;
            })}

          <ContainerStyledBox className="dialog-seccionthree">
            <Link to={allProducts} target="_blank">
            <ContainerStyledButton variant="contained">
              Ver todas las opciones
            </ContainerStyledButton>
            </Link>
            <ContainerStyledTypography varinat="bodySubText" weight="semibold" component="span" >
              La promoción se vería reflejada en tu carrito de compras. Consulta&nbsp;
            </ContainerStyledTypography>
              <StyledLink to="/terminos-condiciones" target="_blank" >
                Términos y condiciones
              </StyledLink>
          </ContainerStyledBox>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default AhorraMasDialog;
