import styled from "styled-components";
import { StyledBox, StyledGrid } from "../../../hdm";

export const StyledAddressBookContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
        & .contact-us-main-title{
            text-align: center;
            color: ${theme.palette.primary.main};
            font-weight: 300;
            font-size: 36px;
            width: fit-content;
            margin: auto;
            background-color: ${theme.palette.gray["50"]};
            padding: 0 ${theme.spacing(1)}px;
            margin-bottom: ${theme.spacing(2)}px;
        }

        & .middle-line{
            border: solid 1px ${theme.palette.gray["100"]};
            transform: translateY(23px);
            z-index: -1;
            position: relative;
        }

        .styledgrid--misdirecciones-container {
            padding: ${theme.spacing(4)}px;
            ${theme.breakpoints.down("sm")} {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        
    `}
`;

export const StyledAddAddressCard = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
    @media (max-width: 1024px){
        margin-left: ${theme.spacing(1)}px;
    }
    @media (min-width: 960px){
        height: 238px;
    }
    ${theme.breakpoints.down("sm")} {
        flex-direction: row;
        max-height: 60px;
        justify-content: flex-start;
        padding: 10px;

        .icon-background {
            margin-bottom: 0 !important;
        }

        .address--chevronRightIcon {
            margin-left: auto;
        }
    }
    
    max-width: 100%;
    width: 100%;
    cursor: pointer;
    
    background-color: #ffffff;
    box-shadow: #cccccc 0 3px 6px;
    // // padding: ${theme.spacing(2)}px;
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & svg{
        color: ${theme.palette.primary.main};
        font-size: 20px;
    }

    & .icon-background{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.gray["50"]};

        @media (min-width: 960px){
            width: 60px;
            height: 60px;
        }
    }

    & span{
        color: ${theme.palette.secondary.main};
        font-size: 15px;
        font-weight: 400;
    }
`}
`;
export const StyledAddAddressCardContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    @media (max-width: 1024px){
        padding-right: 0 !important;
    }
`}
`;

export const StyledNoAddressCardContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    @media (max-width: 1024px){
        margin: ${theme.spacing(1)}px 0px 0 ${theme.spacing(2)}px;
    }
    @media (min-width: 960px){
        height: 238px;
    }
    max-width: 100%;
    width: 100%;
    height: 202px;
    
    background-color: #ffffff;
    box-shadow: #cccccc 0 3px 6px;
    // // padding: ${theme.spacing(2)}px;
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .no-addres-title{
        color: ${theme.palette.gray["300"]};
    }

    & .icon-background{
        height: 130px;
        width: 130px;
        max-height: 130px;
        max-width: 130px;
        background-color: ${theme.palette.gray["50"]};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: ${theme.spacing(2)}px;

        & svg{
            font-size: 76px;
            color: white;
        }
    }
`}
`;
