import { Divider, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { HDmHeadingTitle } from "../../../../../hdm/components/hdm-heading-main-title/HdmHeadingMainTitle";
import { StyledCartContainer } from "../../../../../hdm/widgets/cart/StyledCartContainer";
import { SLFooterElements } from "../dimensions/Footer";
import { SLShoppingCart } from "../dimensions/ShoppingCart";
import { StyledSkeleton } from "../StyledSkeleton";
import { ReusableElement } from "./Checkout";

import { SRTr, SRTd } from "../../../../../hdm/elements";

const SLCartHeader = () => {
  const { t } = useTranslation();
  return (
    <StyledCartContainer className="page">
      <StyledBox className="vertical-padding-3 horizontal-margin-1">
        <HDmHeadingTitle title={t("Cart.Title")} />
      </StyledBox>
    </StyledCartContainer>
  );
};

{
  /* Detalle de pedido */
}
export const SLCartDetails = () => {
  const { t } = useTranslation();
  return (
    <StyledAccordion style={{ padding: "10px" }}>
      <StyledCartContainer className="page">
        {/* Titulo */}
        <StyledGrid container item justifyContent="center">
          <StyledSkeleton
            variant="rect"
            width={SLShoppingCart.details.title.getWidth()}
            height={SLShoppingCart.details.title.getHeight()}
          />
        </StyledGrid>

        <Divider className="vertical-margin-2" />

        {/* Mensaje precios y disponibilidad */}
        <StyledGrid container item justifyContent="center">
          <StyledSkeleton
            variant="rect"
            width={SLShoppingCart.details.subtitle.getWidth()}
            height={SLShoppingCart.details.subtitle.getHeight()}
          />
        </StyledGrid>

        <Divider className="vertical-margin-2" />

        {/* Producto */}
        <SLProductCart />
      </StyledCartContainer>
    </StyledAccordion>
  );
};

export const SLMiniCartItemCard = () => {
  const { t } = useTranslation();
  return (
    <SRTr className="quick-cart__product-container">
      <SRTd>
        <StyledCartContainer className="productDetails--styledPaper-productContainer" style={{ background: "white" }}>
          {/* Datos principales */}
          <StyledGrid container item style={{ flexWrap: "nowrap" }}>
            {/* Imagen */}
            <StyledGrid item style={{ padding: "0 10px" }}>
              <StyledSkeleton
                variant="rect"
                width={SLShoppingCart.details.product.info.image.getWidth()}
                height={SLShoppingCart.details.product.info.image.getHeight()}
              />
            </StyledGrid>
            {/* Nombre */}
            <StyledGrid container item style={{ height: "min-content", rowGap: "6px" }}>
              <StyledSkeleton variant="rect" width={220} height={15} />
              <StyledSkeleton variant="rect" width={165} height={15} /> {/* 184 */}
            </StyledGrid>
            {/* Icono eliminar */}
            <StyledGrid item style={{ padding: "0 5px 0 10px" }}>
              <StyledSkeleton variant="rect" width={15} height={20} />
            </StyledGrid>
          </StyledGrid>
        </StyledCartContainer>
      </SRTd>
      {/* Seccion inferior */}
      <SRTd>
        <StyledGrid container item style={{ flexWrap: "nowrap", marginBottom: "10px" }}>
          {/* Cantidad */}
          <StyledGrid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <StyledSkeleton variant="rect" width={125} height={42} />
          </StyledGrid>
          {/* Precio */}
          <StyledGrid item style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "right" }}>
            <StyledSkeleton variant="rect" width={60} height={19} />
          </StyledGrid>
        </StyledGrid>
      </SRTd>
    </SRTr>
  );
};

{
  /* Metodo de entrega */
}

export const SLCartShippingMethodContainer = () => {
  const { t } = useTranslation();
  return (
    <StyledAccordion>
      <StyledCartContainer className="page">
        {/* Titulo */}
        <StyledGrid container item justifyContent="center">
          <StyledSkeleton variant="rect" width={151.11} height={SLShoppingCart.details.title.getHeight()} />
        </StyledGrid>
        <Divider className="vertical-margin-2" />
        {/* Description */}
        <StyledGrid container item justifyContent="center" style={{ width: "100%" }}>
          <StyledSkeleton variant="rect" width={"inherit"} height={28.8} />
        </StyledGrid>
        {/* Opciones inferiores */}
        <StyledGrid container style={{ marginTop: "21px", marginBottom: "19px" }}>
          <SLCartShippingMethod />
        </StyledGrid>
      </StyledCartContainer>
    </StyledAccordion>
  );
};

export const SLCartShippingMethod = () => {
  return (
    <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "50px", columnGap: "10px" }}>
      {/* Envio a domicilio */}
      <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center", marginTop: "10px" }}>
        <StyledSkeleton variant="rect" width={"inherit"} height={40} />
      </StyledGrid>
      {/* Recoger en tienda */}
      <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center", marginTop: "10px" }}>
        <StyledSkeleton variant="rect" width={"inherit"} height={40} />
      </StyledGrid>
    </StyledGrid>
  );
};

{
  /* Zona de entrega */
}
const SLCartDeliveryContainer = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up(960));
  return (
    <StyledAccordion style={{ padding: "10px", margin: `${isMobile ? "0" : "0 0 10px 0"}` }}>
      <StyledCartContainer className="page">
        {/* Titulo */}
        <StyledGrid container item justifyContent="center">
          <StyledSkeleton variant="rect" width={132.1} height={SLShoppingCart.details.title.getHeight()} />
        </StyledGrid>
        <Divider className="vertical-margin-2" />
        {/* Description */}
        <SLCartDeliveryZone />
      </StyledCartContainer>
    </StyledAccordion>
  );
};

export const SLTVComponent = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up(960));
  return (
    <StyledAccordion style={{ padding: "10px", margin: `${isMobile ? "0" : "0 0 10px 0"}` }}>
      <StyledGrid container>
        <StyledGrid className={"bottom-margin-1"}  item xs={12}>
          <StyledSkeleton className="wide" variant="rect" height={20} />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton className="wide" variant="rect" height={30} />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

export const SLCartDeliveryZone = () => {
  return (
    <StyledGrid container style={{ flexWrap: "nowrap", height: "21px" }}>
      <StyledGrid container item direction="row" alignItems="center" style={{ flexWrap: "nowrap" }}>
        <StyledGrid container item direction="row" alignItems="center" style={{ width: "min-content" }}>
          <StyledSkeleton className={"right-margin-2"} variant="rect" width={15} height={15} />
        </StyledGrid>
        <StyledGrid container item direction="row" alignItems="center" style={{ paddingRight: "10px" }}>
          <StyledSkeleton variant="rect" width={"inherit"} height={18} />
        </StyledGrid>
      </StyledGrid>
      <StyledGrid container item direction="row" style={{ justifyContent: "end", width: "min-content" }} alignItems="center">
        <StyledSkeleton variant="rect" width={56.69} height={18} />
      </StyledGrid>
    </StyledGrid>
  );
};

{
  /* Resumen del pedido */
}
const SLCartSummary = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up(960));
  return (
    <StyledAccordion style={{ padding: "10px", margin: `${isMobile ? "0 0 10px 0" : "0"}` }}>
      <StyledContainer className="page">
        <StyledGrid container>
          {/* Titulo */}
          <StyledGrid container item xs={12} direction="row" justifyContent="center">
            <StyledSkeleton variant="rect" width={163} height={21.6} />
          </StyledGrid>

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          {/* Subtotal */}
          <StyledGrid container style={{ flexWrap: "nowrap" }} direction="row" justifyContent="space-between">
            <StyledGrid container direction="column" xs={6}>
              <StyledSkeleton variant="rect" width={59.21} height={18} />
            </StyledGrid>
            <StyledGrid container item style={{ alignItems: "center", justifyContent: "right" }} xs={6}>
              <StyledSkeleton variant="rect" width={66.74} height={18} />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          {/* Total */}
          <ReusableElement />

          {/* Boton pagar */}
          <Hidden smDown>
            <StyledGrid xs={12} className="bottom-margin-2" style={{ width: "100%" }}>
              <StyledSkeleton variant="rect" width={"inherit"} height={42} />
            </StyledGrid>
          </Hidden>
        </StyledGrid>
      </StyledContainer>
    </StyledAccordion>
  );
};

export const SLProductCartAsRow = () => {
  return (
    <SRTr className="quick-cart__product-container">
      <SRTd>
        <StyledCartContainer className="productDetails--styledPaper-productContainer" style={{ background: "white" }}>
          <SLProductCart />
        </StyledCartContainer>
      </SRTd>
    </SRTr>
  );
};

{
  /* Contenedor de producto */
}
export const SLProductCart = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up(960));
  console.log("SLProductCart is mobile", isMobile)
  return (
    <>
      {isMobile ?
        <>
          <StyledGrid container item style={{ justifyContent: "end" }}>
            <StyledGrid item md={8}></StyledGrid>
            <StyledGrid container item xs={12} md={4} style={{ flexWrap: "nowrap" }}>
              <StyledGrid item xs={6} md={8} lg={7}
                style={{ display: "inline-flex", justifyContent: "center", borderRight: "1px solid #EEEEEE" }}>
                <StyledSkeleton variant="rect" width={116.78} height={18} />
              </StyledGrid>
              <StyledGrid item xs={6} md={4} lg={5} style={{ display: "inline-flex", justifyContent: "center" }}>
                <StyledSkeleton variant="rect" width={54.19} height={18} />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          <Divider className="top-margin-2" />

          {/* Datos del producto */}
          <StyledGrid container style={{ width: "inherit", borderBottom: "1px solid #EEEEEE" }}>
            {/* Datos principales */}
            <StyledGrid container item xs={8} style={{ padding: "10px", borderRight: "1px solid #EEEEEE", paddingRight: "10px" }}>
              {/* Imagen */}
              <StyledGrid item style={{ marginBottom: "10px", paddingRight: "10px" }}>
                <StyledSkeleton
                  variant="rect"
                  width={SLShoppingCart.details.product.info.image.getWidth()}
                  height={SLShoppingCart.details.product.info.image.getHeight()}
                />
              </StyledGrid>
              {/* Nombre y SKU */}
              <StyledGrid item style={{ width: "calc(100% - 70px)" }}>
                <StyledGrid item style={{ marginBottom: "10px" }}>
                  <StyledSkeleton variant="rect" width={"inherit"} height={34} />
                </StyledGrid>
                <StyledGrid item>
                  <StyledSkeleton variant="rect" width={71.4} height={13.6} />
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            {/* Precio */}
            <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <StyledSkeleton variant="rect" width={60} height={31} />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid container style={{ width: "inherit", height: "56px" }}>
            {/* Espacio vacio */}
            <StyledGrid item xs={8} style={{ borderRight: "1px solid #EEEEEE" }}></StyledGrid>
            {/* Subtotal */}
            <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <StyledSkeleton variant="rect" width={60} height={31} />
            </StyledGrid>
          </StyledGrid>
          <Divider />

          {/* Opciones inferiores */}
          <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "62px", columnGap: "10px" }}>
            <StyledGrid container item xs={12} md={6} lg={5} style={{ flexWrap: "nowrap" }}>
              {/* Cantidad */}
              <StyledGrid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={125} height={42} />
              </StyledGrid>
              {/* Forma de surtir seleccionada */}
              <StyledGrid item xs={6} style={{ width: "100%", display: "flex", alignItems: "center", paddingLeft: "18px" }}>
                <StyledSkeleton variant="rect" width={112.55} height={18} />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "62px", columnGap: "10px" }}>
            <StyledGrid container item xs={12} md={5} lg={5} style={{ flexWrap: "nowrap", columnGap: "10px" }}>
              {/* Envio a domicilio */}
              <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={"inherit"} height={40} />
              </StyledGrid>
              {/* Recoger en tienda */}
              <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={"inherit"} height={40} />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </>
        :
        (<>
          {/* Opciones whishlist */}
          <StyledGrid container item style={{ justifyContent: "end" }}>
            <StyledGrid item xs={8}></StyledGrid>
            <StyledGrid container item xs={4} style={{ flexWrap: "nowrap" }}>
              <StyledGrid
                item
                xs={6}
                md={8}
                lg={7}
                style={{ display: "inline-flex", justifyContent: "center", borderRight: "1px solid #EEEEEE" }}>
                <StyledSkeleton variant="rect" width={116.78} height={18} />
              </StyledGrid>
              <StyledGrid item xs={6} md={4} lg={5} style={{ display: "inline-flex", justifyContent: "center" }}>
                <StyledSkeleton variant="rect" width={54.19} height={18} />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          <Divider className="top-margin-2" />

          {/* Datos del producto */}
          <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit" }}>
            {/* Datos principales */}
            <StyledGrid container item xs={6} style={{ padding: "10px" }}>
              {/* Imagen */}
              <StyledGrid item style={{ width: "70px", marginBottom: "10px" }}>
                <StyledSkeleton
                  variant="rect"
                  width={SLShoppingCart.details.product.info.image.getWidth()}
                  height={SLShoppingCart.details.product.info.image.getHeight()}
                />
              </StyledGrid>
              {/* Nombre y SKU */}
              <StyledGrid item style={{ width: "calc(100% - 70px)" }}>
                <StyledGrid item style={{ marginBottom: "10px" }}>
                  <StyledSkeleton variant="rect" width={"inherit"} height={34} />
                </StyledGrid>
                <StyledGrid item>
                  <StyledSkeleton variant="rect" width={71.4} height={13.6} />
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            {/* Espacio vacio */}
            <StyledGrid item xs={3}></StyledGrid>
            {/* Precio */}
            <StyledGrid item style={{ width: "12.5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <StyledSkeleton variant="rect" width={60} height={31} />
            </StyledGrid>
            {/* Subtotal */}
            <StyledGrid item style={{ width: "12.5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <StyledSkeleton variant="rect" width={60} height={31} />
            </StyledGrid>
          </StyledGrid>

          <Divider />

          {/* Opciones inferiores */}
          <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "62px", columnGap: "10px" }}>
            <StyledGrid container item xs={12} md={6} lg={5} style={{ flexWrap: "nowrap" }}>
              {/* Cantidad */}
              <StyledGrid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={125} height={42} />
              </StyledGrid>
              {/* Forma de surtir seleccionada */}
              <StyledGrid item style={{ width: "100%", display: "flex", alignItems: "center", paddingLeft: "18px" }}>
                <StyledSkeleton variant="rect" width={112.55} height={18} />
              </StyledGrid>
            </StyledGrid>

            {/* Espacio vacio */}
            <StyledGrid container item md={1} lg={2}></StyledGrid>

            <StyledGrid container item xs={12} md={5} lg={5} style={{ flexWrap: "nowrap", columnGap: "10px" }}>
              {/* Envio a domicilio */}
              <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={"inherit"} height={40} />
              </StyledGrid>
              {/* Recoger en tienda */}
              <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <StyledSkeleton variant="rect" width={"inherit"} height={40} />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </>)
      }
    </>
  );
};

export const SLCartPage = (props) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up(960));
  console.log("SLCartPage is mobile", isMobile)
  return (
    <>
      {isMobile ? (
        <>
          <StyledContainer className="page" id="SLCartPageMOBILE">
            <StyledCartContainer>
              <StyledGrid container spacing={2} style={{ margin: "0px", width: "100%" }}>
                {/* Titulo */}
                <StyledGrid item xs={12}>
                  <SLCartHeader />
                </StyledGrid>

                {/* Contenedor de productos y detalle de orden */}
                <StyledGrid
                  container
                  spacing={2}
                  xs={12}
                  style={{ margin: "0px", width: "100%" }}
                  className="horizontal-padding-1">
                  {/* Summary details */}
                  <StyledGrid container item sm={12} style={{ margin: "0px", height: "fit-content" }}>
                    <StyledGrid item xs={12}>
                      <SLCartSummary />
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <SLCartShippingMethodContainer />
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <SLCartDeliveryContainer />
                    </StyledGrid>
                  </StyledGrid>
                  {/* Tabla de productos */}
                  <StyledGrid item container sm={12}>
                    <StyledGrid xs={12}>
                      {/* Primer producto */}
                      <SLCartDetails />
                      <StyledAccordion style={{ padding: "10px" }}>
                        <StyledCartContainer className="page">
                          {/* Segundo producto */}
                          <SLProductCart />
                        </StyledCartContainer>
                      </StyledAccordion>
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledCartContainer>
          </StyledContainer>
        </>
      )
        :
        (<StyledContainer className="page" id="SLCartPage">
          <StyledCartContainer>
            <StyledGrid container spacing={2} style={{ margin: "0px", width: "100%" }}>
              {/* Titulo */}
              <StyledGrid item xs={12}>
                <SLCartHeader />
              </StyledGrid>

              {/* Contenedor de productos y detalle de orden */}
              <StyledGrid
                container
                spacing={2}
                xs={12}
                style={{ margin: "0px", width: "100%" }}
                className="horizontal-padding-1">
                {/* Tabla de productos */}
                <StyledGrid container item xs={8}>
                  <StyledGrid xs={12}>
                    {/* Primer producto */}
                    <SLCartDetails />
                    <StyledAccordion style={{ padding: "10px" }}>
                      <StyledCartContainer className="page">
                        {/* Segundo producto */}
                        <SLProductCart />
                      </StyledCartContainer>
                    </StyledAccordion>
                  </StyledGrid>
                </StyledGrid>
                {/* Summary details */}
                <StyledGrid container item xs={4} style={{ margin: "0px", height: "fit-content" }}>
                  <StyledGrid item xs={12}>
                    <SLCartShippingMethodContainer />
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <SLCartDeliveryContainer />
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <SLCartSummary />
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledCartContainer>
        </StyledContainer>)
      }
    </>
  );
};

export const SLCartPageHeadless = (props) => {
  return (
    <StyledGrid container spacing={2} id="SLCartPageHeadless">
      <StyledGrid item xs={8}>
        <SLCartDetails />
      </StyledGrid>
      <StyledGrid container spacing={1} item xs={4}>
        <StyledGrid item xs={12}>
          <SLCartShippingMethodContainer />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <SLCartDeliveryContainer />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <SLCartSummary />
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};
