/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import React from "react";

const StyledFormControl = styled(({ ...props }) => <FormControl {...props} />)`
  ${({ theme }) => `
    & .margins-top-bot{
      margin: 10px 0;
    }
    &.noMarginTop {
      margin-top: 0px !important;
    }
    [class ^= PrivateNotchedOutline] {
      font-size: 11px;
    }

    &.styledFormControl--checkoutProfile-content {
      margin-bottom: 100px;
    }

    .storeSelector--WalletIcon{
      width: 14.68px;
      height: 10.40px;
    }
  `}
`;

export { StyledFormControl };
