export enum LoaderActions {
  PRODUCT_QUANTITY_UPDATE,
  DELIVERY_TYPE_CHANGE,
  PLP_GET_PRODUCTS_SORT,
  PLP_GET_PRODUCTS_PAGE_CHANGE,
  PLP_GET_PRODUCTS_FACET_SELECTION,
  ADD_PRODUCT_TO_CART,
  ADD_TV_TO_CART,
  COPY_ORDER_TO_CART,
  FILTER_ORDERS,
  CHECKOUT_SAVE_AND_CONFIRM_STEP_2,
  CHECKOUT_CONTINUE_PAYMENT_STEP_3,
  FETCH_WISHLIST_PRODUCTS,
  FETCH_WISHLIST_LISTS
}
