/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const CustomTextField = (props: any) => {
  return (
    <TextField {...props} variant={props.variant ? props.variant : "outlined"} InputLabelProps={{ required: false }} />
  );
};

const StyledTextField = styled(CustomTextField)`
  ${({ theme, isB2B, ..._props }) => `
  
  margin-top:0px;

  // & .MuiInputLabel-outlined {
  // width: calc(100% - 46px);
  // overflow:hidden; 
  // white-space:nowrap; 
  // text-overflow: ellipsis;
  // }
  /*&.MuiTextField-root .MuiInputBase-root {
    margin-top: 0px;
    border-radius: 0px;
  }*/

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: darkgray !important;
  }
  .MuiInputBase-input {
    letter-spacing: ${_props.isToken === true ? "5px" : "inherit"};
  }
  .MuiFormHelperText-root {
    line-height: unset;
  }

  .MuiFormHelperText-contained  {
    margin-left:0px;
    margin-right: 0px;
  }

  
  &.MuiOutlinedInput-notchedOutline legend{
    font-size:11px !important;
  }

  .MuiOutlinedInput-notchedOutline legend{
    font-size:11px !important;
  }

  .Mui-disabled{

    & fieldset {
      background-color: rgba(200, 199, 200, 0.30);
    }

    .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel){
      margin-top:0px;
    }
    .MuiFilledInput-input{
      padding:inherit;
    }
  }

  &.order-search-component{
    &.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    & .searching-active{
      cursor: pointer;
      background-color: ${theme.palette.primary.main};
      width: 35px;
      height: 40px;
      max-height: none;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg{
        color: #ffffff !important;
      }
    }
  }

  


  }

  &.MuiTextField-root .MuiInputBase-input.MuiOutlinedInput-input{
     ${isB2B ? "height: 42px" : ""};
  }

  .MuiOutlinedInput-root.Mui-disabled {
    color: ${theme.palette.text.disabled};

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.text.disabled};
      }
    }

    .MuiOutlinedInput-input {
      color: ${theme.palette.text.disabled};
      background: ${theme.palette.action.disabledBackground};
      border-radius: ${theme.shape.borderRadius}px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.text.disabled};
    }
  }

  
  `}
`;

export { StyledTextField };
