//Standard libraries
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";
import { useSite } from "../../../../../_foundation/hooks/useSite";

const GiftCard = ({ ...props }) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const orderDataTYP = props.orderDataTYP;
  const orderData = props.orderData;

  const currentPI = orderData?.paymentInstruction.filter((pi) => pi.payMethodId == "GiftCard");

  let giftCardNumber = currentPI.length > 0 && currentPI[0].protocolData?.find((p) => p.name === "account").value;
  if (isB2B) {
    giftCardNumber = "**** " + giftCardNumber.substring(giftCardNumber.length - 4);
  }
  const authorizationCode =
    currentPI.length > 0 && currentPI[0].protocolData?.find((p) => p.name === "authorizationCode").value;
  const giftCardUsedBalance =
    currentPI.length > 0 && currentPI[0].protocolData?.find((p) => p.name === "giftCardUsedBalance").value;
  const availableBalance =
    currentPI.length > 0 && currentPI[0].protocolData?.find((p) => p.name === "giftCardAvailableBalance").value;

  return (
    <>
      <StyledGrid xs={12}>
        <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
          {t("OrderConfirmation.PaymentMethod.GiftCard.Name")}
        </StyledTypography>
      </StyledGrid>
      <StyledGrid item xs={12} className={"top-margin-1"}>
        <StyledTypography variant="bodyBaseline" className={"top-margin-1"} component={"span"}>
          {t("OrderConfirmation.PaymentMethod.GiftCard.CardNum") + ": "}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
          {giftCardNumber}
        </StyledTypography>
      </StyledGrid>
      <StyledGrid item xs={12} className={"top-margin-1"}>
        <StyledTypography variant="bodyBaseline" className={"top-margin-1"} component={"span"}>
          {t("OrderConfirmation.PaymentMethod.GiftCard.AuthNum") + ": "}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
          {authorizationCode}
        </StyledTypography>
      </StyledGrid>
      <StyledGrid item xs={12} className={"top-margin-1"}>
        <StyledTypography variant="bodyBaseline" className={"top-margin-1"} component={"span"}>
          {t("OrderConfirmation.PaymentMethod.GiftCard.UsedBalance") + ": "}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
          <FormattedPriceDisplay min={isB2B ? giftCardUsedBalance : orderData?.grandTotal} currency={orderData?.grandTotalCurrency} />
        </StyledTypography>
      </StyledGrid>
      <StyledGrid item xs={12} className={"top-margin-1"}>
        <StyledTypography variant="bodyBaseline" component={"span"}>
          {t("OrderConfirmation.PaymentMethod.GiftCard.RemainingBalance") + ": "}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
          <FormattedPriceDisplay min={availableBalance} currency={orderData?.grandTotalCurrency} />
        </StyledTypography>
      </StyledGrid>
    </>
  );
};

export { GiftCard };
