import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { StyledTextField } from "../text-field";
import { StyledButton } from "../button";
import { StyledBox } from "../box";
import { StyledRating } from "../rating";
import { StyledCardMedia } from "../card";
import { StyledTypography } from "../typography";
import { StyledGrid } from "../grid";
import CardMedia from "@material-ui/core/CardMedia";

import { palette } from "../../themes/color-palette";

export const ContainerStyledProductCard = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme, color }) => `

  .product-card-img-info {
    display: flex;
    padding: 10px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .product-card-img {
    height: auto;
    width: 100%;
    
  }

  .product-card-variante-mobile {
    display: flex;
    height: 25px;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(4)}px;
  }

  .product-card-basicinfo {
    padding-left: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;
    height: 136px;
  }

  .product-card-title {    
    height: 56px;
    overflow: hidden;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .card-titulo-producto {
    /*margin-left: ${theme.spacing(1)}px;*/
  }

  .produt-card-rewiev {
    margin-bottom: ${theme.spacing(2)}px;
    display: flex;
    height: 20px;
  }

  .colorGray300 {
    color: ${palette.gray["300"]};
  }

`}
`;

export const StyledCardMediaProductx = styled(({ ...props }) => <CardMedia {...props} />)`

  &.product-image {
    height: 150px;
    width: 150px;
    background-size: contain;
  }

  &.promo_plp-ribbon-hv {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `
  
 
`}
`;

export const ContainerStyledTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `

  &.colorHighlight {
    color: ${palette.text.highlight};
  }

  &.colorGray300Line {
    color: ${palette.gray["300"]};
    text-decoration: line-through;
  }

  &.colorGray300 {
    color: ${palette.gray["300"]};
  }

  &.card-titulo-producto {
    margin-left: ${theme.spacing(1)}px;
  }

`}
`;

export const ContainerStyledTextField = styled(({ ...props }) => <StyledTextField {...props} />)`
  ${({ theme }) => `
  
  &.MuiFormControl-root.MuiTextField-root {
    margin-bottom: 10px;
  }

  

  
`}
`;

export const ContainerStyledButton = styled(({ ...props }) => <StyledButton {...props} />)`
  ${({ theme }) => `
  
  &.product-card-lessmore-button {
    background-color: #ffffff;
    color: #F96302;
    border: 1px solid #CCCCCC;
    max-width: 34px;
    min-width: 34px;
    max-height: 42px;
    min-height: 42px;

  }
`}
`;

export const ContainerStyledCardMedia = styled(({ ...props }) => <CardMedia {...props} />)`
  ${({ theme }) => `  
 
  &.promo-remate {
    width: 49px;
    height: 22px;
  }

  &.promo_plp-ribbon-gr {
    width: 49px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-sb {
    width: 30px;
    height: 24px;
  }
  
  &.promo_plp-ribbon-nlp {
    width: 25px;
    height: 25px;
  }
  
  &.promo_plp-ribbon-combo {
    width: 58px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-fp {
    width: 82px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-nxm {
    width: 40px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-nxm2 {
    width: 60px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-ahp {
    width: 38px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-cp {
    width: 28px;
    height: 22px;
  }

`}
`;

export const ContainerStyledRating = styled(({ ...props }) => <StyledRating {...props} />)`
  ${({ theme }) => `
  
  color: #F96302;
  .MuiRating-iconEmpty {
      color: #EEEEEE
  }

  &.recomended-rating-product {
    font-size: 15px;
  }

`}
`;


