/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */

 import {
  StyledGrid,
  StyledPDPContainer,
  StyledTypography,
  StyledTabs,
  ITabs,
  StyledNumberInput,
  StyledProductImage,
  StyledPaper,
  StyledBox,
  StyledCardEmpty,
} from "../../elements";
import Axios, { Canceler } from "axios";
import { ProductImage, ChipAd, CustomTable } from "../../components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import styled from "styled-components";
import ProductSummaryCardHDM from "../../../components/custom-components/styled-product-summary-card/ProductSummaryCardHDM";
import { ContainerPdP } from "../product-details/container-pdp";
import InformacionAdicional from "../../components/pdp-informacion-adicional/InformacionAdicional";
import AcercaDeEsteProducto from "../../components/pdp-acerca-de-este-producto/AcercaDeEsteProducto";
import PdpDisponibilidad from "../../components/pdp-disponibilidad/Disponibilidad";
import PdpCardPrecio from "../../components/pdp-card-precio/CardPrecio";
import CarouselHDMDesktop from "../../../components/custom-components/styled-carousel-hdm/CarouselHDMDesktop";
import CarouselHDM from "../../../components/custom-components/styled-carousel-hdm/CarouselHDM";
import Sticky from "react-sticky-el";
import QuantityHeader from "../../components/pdp-quantity-header/QuantityHeader";
import { InView } from "react-intersection-observer";
import ShopperActionsPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsPDPHDM";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { checkIfHasColorBase, checkVideos, getAllEspecificacionesTec, getBrowser, tabletBreakpoint } from "../../functions/utils";
import theme from "../../themes/thd";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hdmColorsRdc, hdmStoresDataRdc, installationPartNumber, lineaGamaColorsRdc, onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { data, mockProduct } from "./mockData";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import {
  getPesoMedida,
  getPromoicons,
  getStoreOnlyAvailable,
  getUnidadMedida,
  ordenarJerarquia,
  ribbonsPromoIconsImg,
} from "../../functions";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";
import getDisplayName from "react-display-name";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import jsonStateCityList from "../../../components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";
import { SET_CURRENT_STORE_ACTION } from "../../../redux/actions/current-store";
import { CustomTableKits } from "./kit-custom-table";
import ProductInstalationHDM from "../../../components/custom-components/styled-product-instalation-card/ProductInstalationHDM";
import ShopperActionsKitPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsKitPDPHDM";
import { orderItemsSelector } from "../../../redux/selectors/order";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import RoomvoVisualSupport from "../../../components/custom-components/styled-visual-support/RoomvoVisualSupport";
import ViewContentEvent from "../../../components/custom-components/conversion-api/ViewContentEvent";
import SuperSkuContainer from "../../components/pdp-super-sku/SuperSkuContainer";
import ComprarColor from "../../components/pdp-comparar-color/BuyByColor";
import ProductNotAvailable from "../../pages/not-available/ProductNoAvailable";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { productDataInterface } from "../../../interfaces/menu/productDataInterface";
import KitAvailability, { KitAvailabilityDataInterface } from "../../components/pdp-disponibilidad/KitAvailability";
import { ProductDetailsContainer } from "../product-details/product-detail-container";
import { CLOSE_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { hasStoreBuyableExclude } from "../../functions/getPromoicons";
import { triggerSelectItemDL, triggerViewItemDL } from "../../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
import { currentContractIdSelector } from "../../../redux/selectors/contract";

interface BundleWidgetProps {
  productPartNumber: string;
  displayName: string;
  translation: any;
  displayShortDesc: string;
  displayOfferPrice: number;
  displayListPrice: number;
  productDetailTabsChildren: ITabs[];
  definingAttrs: any;
  displayFullImage: string;
  FormattedPriceDisplay: any;
  tableData: any;
  addKitButton: any;
  productAvailability: any;
  marketProductAvailability: any;
  marketInventory: any;
  productQuantity: number;
  updateProductQuantity: any;
  availabilityImageText: any;
  addRequisitionListButton: any;
  kit?: any;
  addComboToCart?: any;
  ribbonFinder?: any;
  isB2B: boolean;
  shopperActionsData:any;
  productData: productDataInterface;
  getCompKitInventoryForAnotherStore:any;
  kitAvailabilityData:KitAvailabilityDataInterface;
}

export const KitWidget: React.FC<BundleWidgetProps> = (props: any) => {
  const {
    productPartNumber,
    displayName,
    catentryData,
    translation,
    displayShortDesc,
    displayOfferPrice,
    displayListPrice,
    productDetailTabsChildren,
    displayFullImage: fullImage,
    FormattedPriceDisplay,
    tableData,
    addKitButton,
    productAvailability,
    marketProductAvailability,
    marketInventory,
    productQuantity,
    updateProductQuantity,
    availabilityImageText,
    kit,
    addComboToCart,
    ribbonFinder,
    isB2B,
    shopperActionsData,
    productData,
    kitAvailabilityData,
    loginStatus,
    name,
    shortDescription,
    displayFullImage,
    longDescription,
    legalDescription,
    brand,
    partNumber,
    productId,
    attributes,
    promociones,
    orderRibbons,
    urlRibbons,
    allEspecificacionesTec,
    model,
    customBrandExclude,
    attachedImages,
    videos,
    quantityMeasure,
    weightMeasure,
    bahiaData,
    aRIdentifier,
    roomvoIdentifier,
    leadTimeProduct
  } = props;

  const [nominalQuantity, setNominalQuantity] = useState<string>("1");
  const [isParentSSKU, setIsParentSSKU] = useState<boolean>(false);
  const [productNotBuyable, setProductNotBuyable] = useState<boolean>(false);
  const breadcrumbsValue = useSelector(breadcrumbsSelector);
  const [hasProductBaseColor, setHasProductBaseColor] = useState<any>(null);

  const pdpQty: any | number = useSelector<any>((state) =>
    state.hdmDataReducer.pdpQuantity ? state.hdmDataReducer.pdpQuantity : 1
  );
  const storeConfData = useSelector(onlineStoreRdc);
  const hdmStoresData = useSelector(hdmStoresDataRdc);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const hdmOrderItemsSelector = useSelector(orderItemsSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const contract = useSelector(currentContractIdSelector);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const [alltags, setAlltags] = useState<any>(null);
  const [showCarritoHeader, setShowCarritoHeader] = useState<boolean>(false);
  const [stickyDisabled, setStickyDisabled] = useState<boolean>(false);
  const [bottomOffset, setBottomOffset] = useState<boolean>(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const is1440 = useMediaQuery(theme.breakpoints.down(1440));

  const [isGTMLoaded, setIsGTMLoaded] = useState<boolean>(false);
  const [partNumberBT, setPartNumberBT] = useState<any>(null);
  const [categoryBT, setCategoryBT] = useState<any>(null);
  const [categoryBT2, setCategoryBT2] = useState<any>(null);
  const [categoryBT3, setCategoryBT3] = useState<any>(null);

  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const product = kit;

  const widgetName = getDisplayName("KitsWidget");
  const ribbonAds = ribbonFinder(kit);
  const { mySite } = useSite();
  const storeSelector = useSelector(currentStoreSelector);

  const payloadBase: any = {
    storeId: mySite.storeID,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const cityList = jsonStateCityList.formScheduleObject;
  const dispatch = useDispatch<Dispatch<any>>();

  const queryParams = {};
  /**LIA feature */
  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(queryParams ?? "");
      /**Check for store_code for LIA feature*/
      const store_code = params.get("store_code");
      if (store_code) {
        //change current store to given store_code
        const findStores = cityList.filter((states: any) =>
          states.stores.some((cities) => cities.stLocId === store_code)
        )[0];
        const findStore = findStores?.stores.filter((stores: any) => stores.stLocId === store_code)[0];
        let stLocId = "";

        await Axios.get(
          `/wcs/resources/store/${mySite.storeID}/storelocator/latitude/${findStore.latitude}/longitude/${findStore.longitude}?maxItems=1&radius=10&langId=-5`
        )
          .then((response) => response.data)
          .then((res) => {
            stLocId = res.PhysicalStore[0].uniqueID;
          });

        const defaultPayload = {
          findStore: {
            ...findStore,
            uniqueID: stLocId,
            stateId: findStores.stateId,
            isExternal: true,
          },
        };
        dispatch(SET_CURRENT_STORE_ACTION(defaultPayload));
      }
    };
    fetchData();
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [queryParams]);

  useEffect(() => {
    let newNominalQuantity: any = "1";
    if (hdmOrderItemsSelector && hdmOrderItemsSelector?.length !== 0 && productData) {
      const productInCart = hdmOrderItemsSelector.find(
        (orderItem) => orderItem?.partNumber === productData?.partNumber
      );

      if (productInCart && productData?.nominalQuantity) {
        newNominalQuantity = parseInt(productData?.nominalQuantity) - parseInt(productInCart?.quantity);
        if (newNominalQuantity <= 0) {
          setNominalQuantity("1");
          newNominalQuantity = "1";
        } else {
          newNominalQuantity = productData?.nominalQuantity;
          setNominalQuantity(newNominalQuantity);
        }
      } else if (productData?.nominalQuantity) {
        newNominalQuantity = productData?.nominalQuantity;
        setNominalQuantity(newNominalQuantity);
      }
    } else {
      newNominalQuantity = productData?.nominalQuantity;
      setNominalQuantity(newNominalQuantity);
    }

    const query = {
      url: "",
      data: newNominalQuantity,
      option: "PDP_QUANTITY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  }, [hdmOrderItemsSelector, productData]);

 
  useEffect(() => {
    
    if(partNumber && partNumber!=null){
      try {
        const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
        triggerViewItemDL(
          mySite.storeID,
          isB2B && loginStatus && proCustomerLevel ? storeSelector?.currentStore?.stLocId + "_" + proCustomerLevel : storeSelector?.currentStore?.stLocId,
          contract,
          partNumber,
          1,
          itemAffiliation,
          currentMarketId,
          isB2B
        );
        
      } catch (e) {
        console.log("Error view item", e);
      }
    }
  },[partNumber]);
  /*==== Ar api key =====*/
  // const productSku: string = product?.items[0]?.partNumber;

  const showCarousel = false;

  const arApiKey = storeConfData?.AR_APITOKENKEY;
  const isArEnabled = storeConfData?.ENABLE_AR;
  const isVisualSupportEnabled = product ? product["x_visualsupport.field2"] : "";

  /*==== ROOMVO changes =====*/
  const isRoomvoEnabled = storeConfData?.ENABLE_ROOMVO;
  const isRoomvoVisualSupportEnabled = product ? product["x_visualsupport.field2"] : "";

  const currentStoreId = storeSelector.currentStore.stLocId;
  const currentMarketId = storeSelector.currentStore.marketId;
  const tiendaUniqueID = storeSelector.currentStore.uniqueID;
  const tiendaNombre = storeSelector.currentStore.storeName;
  const locationName = storeSelector.currentStore.stLocName;


  const jsonLPPayload = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: displayName,
    "@id": "product_id",
    image: [kit && kit.fullImageRaw],
    description: displayShortDesc,
    sku: kit?.partNumber.replace("P", ""),
    mpn: kit?.partNumber.replace("P", ""),
    brand: {
      "@type": "Thing",
      name: kit?.manufacturer ?? "",
    },
    offers: {
      "@type": "Offer",
      url: "offer_url",
      priceCurrency: "MXN",
      price: kit?.price.find((p) => p.usage === "Offer").value,
      price_sqm: productData?.quantityMeasure === "m2" ? kit?.price[1].field1?.OMP : "",
      priceValidUntil: "",
      availability: "https://schema.org/InStock",
    },
  };

  useEffect(() => {

    if (
      product &&
      breadcrumbsValue &&
      breadcrumbsValue?.length !== 0 &&
      currentStoreId &&
      catentryData &&
      tiendaUniqueID &&
      hdmLineaGamaColor &&
      hdmLineaGamaColor?.lenght !== 0 &&
      hdmColors &&
      hdmColors?.lenght !== 0
    ) {
      inicializarProductDetail();
    } 
    // else if (
    //   products === undefined &&
    //   !product &&
    //   !catentryData
    // ){
    //   productNotAvailable();
    // }

    //check if parent partNumber is sSKU
    product && setIsParentSSKU(product.partNumber.split("_SS").length == 2);

    return () => {
      // setProductData(null);
      let query: any = null;
      query = {
        url: "",
        data: "",
        option: "PDP_SEO",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: null,
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: null,
        option: "PDP_PRODUCT_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: null,
        option: "PDPSTORE_BUYABLE_EXCLUDE",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
      setProductNotBuyable(false);
    };
  }, [product, tiendaUniqueID, breadcrumbsValue, marketInventory, catentryData]);

  const inicializarProductDetail = async () => {
    // navigate(ERROR_PAGE, {state:{errorCode:'500'}})
    window.scrollTo({ top: 0, behavior: "instant" });
    console.log('catentryDataKit', catentryData)
    if (catentryData?.type && catentryData?.type === "package") {

      const storeBuyableExclude = hasStoreBuyableExclude(attributes, isB2B);

      if (storeBuyableExclude) {
        setProductNotBuyable(true);
        let query;

        query = {
          url: "",
          data: catentryData?.seo?.href,
          option: "PDP_SEO",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

        query = {
          url: "",
          data: true,
          option: "PDPSTORE_BUYABLE_EXCLUDE",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
        setTimeout(() => {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
        }, 1000);
        return;
      }

      const isPaint = breadcrumbsValue.some((category) => category.label === "Pinturas");
      const productBaseColor: any = checkIfHasColorBase(hdmColors, {
        manufacturer: brand,
        attributes: attributes,
      });

      let baseGamaColorDefault: any = null;
      if (isPaint && productBaseColor) {
        const { branchColor, lineColor, baseColor } = productBaseColor;
        const pinturasHDM = hdmLineaGamaColor;
        const colorBase = pinturasHDM.filter(
          (database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === "#N/D"
        )[0];
        baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0];

        const colorCodeHash = window.location.hash;
        let colorCode;
        if (colorCodeHash) {
          colorCode = colorCodeHash.split(":")[1];
        }

        productBaseColor.colorCode = colorCode;
        setHasProductBaseColor(productBaseColor)
      }

      const nomQuantity = catentryData?.["x_measurements.nominalQuantity"] ? parseInt(catentryData["x_measurements.nominalQuantity"]).toString() : "1";

      setNominalQuantity(nomQuantity);
      //datalayer
      const arrayCat = breadcrumbsValue.filter((e) => e.type_ === "FACET_ENTRY_CATEGORY");
      if (arrayCat.length > 0) {
        setPartNumberBT(partNumber);
        if (arrayCat[0]) {
          setCategoryBT(arrayCat[0].label ? arrayCat[0].label : "NA");
        }
        if (arrayCat[1]) {
          setCategoryBT2(arrayCat[1].label ? arrayCat[1].label : "NA");
        }
        if (arrayCat[2]) {
          setCategoryBT3(arrayCat[2].label ? arrayCat[2].label : "NA");
        }
      }

      let query;

      query = {
        url: "",
        data: catentryData?.seo?.href,
        option: "PDP_SEO",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: {
          partNumber: partNumber,
          longDescription: longDescription,
          attachments: catentryData?.attachments,
          productDetailTabsChildren: allEspecificacionesTec,
          legalDescription: legalDescription
        },
        option: "PDP_PRODUCT_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      if (!product.partNumber.includes("_SS")) {
        setTimeout(() => {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
        }, 2000);
      }
    }
  };


  const detectedBrowser = getBrowser();

  const thisProduct = JSON.parse(JSON.stringify(kit));
  const currentSelection = ""

  const storeBuyableExclude = hasStoreBuyableExclude(attributes, isB2B);


  if (storeBuyableExclude || productNotBuyable) return <ProductNotAvailable breadcrumbsValue={breadcrumbsValue} />;

  console.log('marketInventoryKITTT', marketInventory)
  return (
    <ProductDetailsContainer 
      displayName={displayName}
      catentryData={catentryData}
      kitAvailabilityData={kitAvailabilityData}
      partNumber={partNumber}
      displayOfferPrice={displayOfferPrice}
      currentSelection={currentSelection}
      nominalQuantity={nominalQuantity}
      marketInventory={marketInventory}
      currentStoreId={currentStoreId}
      promociones={promociones}
      quantityMeasure={quantityMeasure}
      loginStatus={loginStatus}
      brand={brand}
      name={name}
      model={model}
      detectedBrowser={detectedBrowser}
      customBrandExclude={customBrandExclude}
      urlRibbons={urlRibbons}
      aRIdentifier={aRIdentifier}
      fullImage={fullImage}
      attachedImages={attachedImages}
      product={product}
      attributes={attributes}
      shortDescription={shortDescription}
      productId={productId}
      weightMeasure={weightMeasure}
      orderRibbons={orderRibbons}
      roomvoIdentifier={roomvoIdentifier}
      bahiaData={bahiaData}
      leadTimeProduct={leadTimeProduct}
      tiendaNombre={tiendaNombre}
      locationName={locationName}
      allEspecificacionesTec={allEspecificacionesTec}
      displayPartNumber={partNumber}
      longDescription={longDescription}
      isParentSSKU={isParentSSKU}
      hasProductBaseColor={hasProductBaseColor}
      legalDescription={legalDescription}
      videos={videos}
      tableData={tableData}
      isGTMLoaded={isGTMLoaded}
      setIsGTMLoaded={setIsGTMLoaded}
      partNumberBT={partNumberBT}
      categoryBT={categoryBT}
      categoryBT2={categoryBT2}
      categoryBT3={categoryBT3}
      addComboToCart={addComboToCart}
      isB2B={isB2B}
      isPdp={false}
      isKit={true}
    />
  )
};
