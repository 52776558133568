/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import { StyledPaper } from "../paper";

const StyledPDPContainer = styled(({ ...props }) => <StyledPaper {...props} />)`
  ${({ theme, start, end, to }) => `


    &.pdp-container-desktop {

      overflow: visible;
      margin-bottom: 10px;
      position: relative;
      min-height: 510px;

    }

    &.pdp-container-desktop-chrome {

      overflow: visible;
      margin-bottom: 10px;
      position: relative;
      min-height: 510px;

      animation: gallery-image linear both;
      animation-timeline: scroll(root block);
      animation-range: ${start}px ${end}px;
    }

    @keyframes gallery-image {
      from {
        transform: translatey(0px);
        
      }
      to {
        transform: translatey(${to}px);
      }
    }

    &.pdp-container-mobile {

      overflow: visible;
      margin-bottom: 10px;
      position: relative;
      min-height: 510px;
    }

    ${theme.breakpoints.down(1024)} {
      min-height: 401px;
    }
  

    @media (max-width: 1024px){
      margin: 0 ${theme.spacing(2)}px;
    }

    @media (max-width: 1024px){
      padding: ${theme.spacing(2)}px ${theme.spacing(2)}px;
    }

    .MuiGrid-spacing-xs-2 {
      margin: 0px;
    }

    .product-name {
      margin-top: ${theme.spacing(2)}px;
      word-break: break-word;
    }

    .visual-support{
      display: flex;
      flex-direction: row;
      padding: 0 ${theme.spacing(1)}px;

      .align-right-desktop{
        @media (min-width: 1024px){
          display: flex;
          justify-content: center;
        }
      }

    .product-seller,
    .product-sku,
    .product-color,
    .product-quantity,
    .product-availability {
      margin: ${theme.spacing(2)}px 0 ${theme.spacing(1)}px;
      font-weight: bold;
    }

    .product-shortDescription {
      margin: ${theme.spacing(1)}px 0 ${theme.spacing(2)}px;
    }

    .product-price-container {
      margin-bottom: ${theme.spacing(2)}px;
    }

    .textalign {
      display:block;
      width:7em;
      text-align:right;
    }

    .MuiCardHeader-subheader{
      height: 3em;
    }

    .attachment{
      display: inline;
    }

    .product-attachment {
      vertical-align: middle;
      background-color: #F2F2F2;
    }

    .selectType{
      -webkit-appearance: none;
      -moz-appearance: none;
      border:0px;
    }

    input:focus, select:focus, textarea:focus, form:focus, button:focus {
      outline:0;
    }

    .product-price {
      margin-right: ${theme.spacing(2)}px;
    }

    .strikethrough {
      color: ${theme.palette.text.secondary};
      text-decoration: line-through;
      font-size: 0.9em;
    }

    .product-add-to-cart {
      margin-top: ${theme.spacing(1)}px;
      margin-bottom: ${theme.spacing(1)}px;
    }

    .product-imageB2B,
    .product-image {
      text-align: center;
      position: relative;
      width: calc(100% - 20px);

      @media (min-width: 1366px) {
        padding: 10px ${theme.spacing(10)}px 10px ${theme.spacing(10)}px;
      }

      @media (min-width: 1024px) and (max-width: 1365px) {
        padding: 0;
      }
    }
  `}
`;

export { StyledPDPContainer };
