import { Divider } from "@material-ui/core";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { StyledCartContainer } from "../../../../../hdm/widgets/cart/StyledCartContainer";
import { StyledContainerHDM, StyledHeaderHDM } from "../../../../header/Header";
import { SLFooterElements } from "../dimensions/Footer";
import { StyledSkeleton } from "../StyledSkeleton";

export const SLContentCarouselWidget = () => {
    return (
        <>
            <StyledGrid container>
                <StyledGrid item xs={12}>
                    <StyledSkeleton className="top-margin-2" variant="rect" height={350} noCard />
                </StyledGrid>
            </StyledGrid>
        </>

    );
}
