import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
} from "../../containers-precio-promocion";

export const Remate = () => {
  const storeConfData = useSelector(onlineStoreRdc);
  return (
    <>
      <ContainerStyledBox className="ahorra-mas-subcontainer">
        <ContainerStyledBox className="promoicon-container">
          <ContainerStyledCardMedia
            image={storeConfData?.IMAGE_SERVER_HOSTNAME + "iconosPromos/remate.svg"}
            className="promo_plp-ribbon-gr"
            title="Remate"
            component="img"
          />
          <ContainerStyledBox ml={1}>
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold">
              Oportunidad final
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerStyledBox>
      <ContainerStyledBox>
        <ContainerStyledTypography variant="bodySubText" component="span">
          Grandes ahorros en últimas piezas
        </ContainerStyledTypography>
      </ContainerStyledBox>
    </>
  );
};
