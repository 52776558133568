/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { style, typography } from "@material-ui/system";

const variant2 = style({
  prop: "variant",
  cssProperty: false,
  themeKey: "typography",
});

const StyledTypography = styled(({ variant, ...props }) => <Typography {...props} />)`
  ${variant2}
  ${typography}

  ${({ theme, ..._props }) => `
  white-space: break-spaces;
  align-items: baseline;
  ${_props?.weight ? `font-weight:${theme.fontWeights[_props.weight]}` : ""};
  ${_props?.color ? `color:${_props.color}` : ""};

  li, a {
      text-decoration: none;
    }
    a {
      color: ${theme.palette.secondary.main};

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.accounts-links-title {
      margin: ${theme.spacing(3)}px 0 ${theme.spacing(2)}px;
    }
    &.MuiTypography-gutterBottom {
      margin-bottom: ${theme.spacing(1)}px;
    }
    &.total-discount {
      color: ${theme.palette.text.highlight};
    }
    &.product-shordesc {
      font-size: 12px;
      color: ${theme.palette.text.secondary};
    }
    &.product-quantity {
      font-size: 17px;
      color: ${theme.palette.text.primary};
    }
    .strikethrough {
      text-decoration: line-through;
      font-size: 0.9em;
      opacity: 0.8;
      margin-right: ${theme.spacing(1)}px;
    }

    &.normal-white-space {
      white-space: normal;
    }

    .line-through > * {
      text-decoration: line-through;
    }

    &.expanded-menu-bold {
      font-size: 1.15em;
      font-weight: bold;
      margin-bottom: ${theme.spacing(1)}px;
    }

    &.expanded-menu-sub-links {
      margin-bottom: ${theme.spacing(0.75)}px;
      margin-top: ${theme.spacing(0.75)}px;
    }

    &.expanded-menu-text {
      color: ${theme.palette.text.expandedMenu};
      font-size: 1.15em;
      &.MuiTypography-body2 {
        font-size: 12px;
      }
    }
    &.wrapText {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    &.error {
      color: ${theme.palette.text.alert};
    }

    // checkout review shipment group summary heading
    &.shipment-group-heading{
      font-weight: bold
    }

    
    &.masColor {
      font-size: 2em;
      color: ${theme.palette.text.secondary};
    }
    &.link {
      color: ${theme.palette.secondary.main};
      cursor:pointer;
    }
    
    &.labelDesign { 
      display: flex;
      align-items: center;
      
      &.MuiInputAdornment-positionEnd {
        margin-left: 5px;
        height: auto;
      }
    }
    &.text-ellipsis {
      text-overflow: ellipsis;
    }

    &.gray300{
      color: ${theme.palette.gray["300"]};
    }
  `}
`;

export { StyledTypography };

export const StyledMenuTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `

    &.searchBar-resultsCategory{
      color:${theme.palette.text.primary};
      padding-left:10px;
    }

  `}
`;

export const StyledAccountTitleTypography = styled.h1`
  color: #f96302;
  font-weight: 100 !important;
  text-align: center;
  overflow: hidden;
  text-transform: uppercase;

  &:before,
  &:after {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 12%;
  }
  &:before {
    right: 10px;
    margin-left: -50%;
  }

  &:after {
    left: 10px;
    margin-right: -50%;
  }
`;
