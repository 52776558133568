/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Axios, { Canceler } from "axios";

//Custom libraries

import styled from "styled-components";
import { StyledProgressPlaceholder } from "../circular-progress";
import { StyledCardProduct, StyledCardMediaProduct, StyledCardMediaHipoteca } from "../card";
import { StyledCheckboxCompare } from "../check-box";
import { StyledFormControlLabel } from "../form";
import { RibbonAd } from "../../components/ribbon-ad";
import ProductPrice from "./styled-product-price";
import PromoIcons from "./Styled-promoicons";

import {
  StyledBox,
  StyledCardEmpty,
  StyledButton,
  StyledTypography,
  StyledCardMedia,
  StyledGrid,
  StyledLinkTextSecondary,
} from "../index";
import {
  ribbonsPromoIconsImg,
  getPromoicons,
  ordenarJerarquia,
  getUnidadMedida,
  getPesoMedida,
  getStoreOnlyAvailable,
} from "../../functions";
import ProductCardBasicInfo from "./Styled-product-card-basic-info";
import ProductBadgedPromotion from "./Styled-badged-promotion";
import SuperSKU from "./Styled-product-supersku";
import ProductQuantity from "../../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import InputCantidad from "./Styled-input-cantidad";
import AddProductCart from "../../functions/AddProductCart";

import {
  ContainerStyledTypography,
  ContainerStyledProductCard,
  StyledCardMediaProductx,
  ContainerStyledRating,
} from "./containers-product-card";
import { StyledWishListButton } from "../../../components/custom-components/styled-wishList-button";
import { ComboLinks } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { hdmWishListProductsQuantityRdc, onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { orderItemsSelector } from "../../../redux/selectors/order";

import { checkIfHasColorBase } from "../../functions/utils";
import { CardMedia } from "@material-ui/core";
import { StyledProductCardImage } from "./Styled-product-card-image";
import EmarsysProduct from "../../commerce-widgets/hdm-emarsys-product-recommendation/EmarsysProduct";
import { useSite } from "../../../_foundation/hooks/useSite";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { OPEN_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import AddKitToKart from "../../functions/AddKitToCart";

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `
  
 
`}
`;

const StyledProductCard = styled(({ className, smWidth, ...props }) =>
  props.to ? (
    <Link className={className} {...props} />
  ) : (
    <ContainerStyledBox className={className} style={{ width: smWidth }} {...props} />
  )
)`
  ${({ theme, smWidth, color }) => `

    .styled--productcard-container {
      position: relative;
      z-index: 0;

      &-wishList {
        .styled--productcardvariante-mobile-notSSKU,
        .produt-card-notCaption {
          display:none;
        }
        .styled--productcardimg-info,
        .product-card-precio {
          padding-bottom:0;
          margin-bottom:0;
        }
        .price-card-height, 
        .product-card-image{height:auto;}
      }
    }

    &.product-card {
      ${theme.breakpoints.down("sm")} {
        position: relative;
        width: ${smWidth};
        min-height: 269px;
      }
      ${theme.breakpoints.up("sm")} {
        position: relative;
        flex: 1;
        width: 285px;
        height: 679px;
      }
    }

    .produt-card-agregar {
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      display: flex;
    }

    .product-card-basicinfo {
      ${theme.breakpoints.up("sm")} {
        padding-left: ${theme.spacing(2)}px;
        padding-right: ${theme.spacing(2)}px;
        padding-top: ${theme.spacing(2)}px;
        padding-bottom: ${theme.spacing(2)}px;
        height: 136px;
  
      }
      ${theme.breakpoints.down("sm")} {
        padding-left: ${theme.spacing(2)}px;
        padding-bottom: ${theme.spacing(2)}px;
        height: 136px;
  
      }
    }

    .product-card-title {

      ${theme.breakpoints.down("sm")} {
        display: flex;
        max-height: 85px;
        overflow: hidden;
        margin-bottom: ${theme.spacing(2)}px;
      }
  
      ${theme.breakpoints.up("sm")} {
        display: flex;
        max-height: 56px;
        overflow: hidden;
        margin-bottom: ${theme.spacing(2)}px;
      }
  
    }

    .produt-card-rewiev {
      margin-bottom: ${theme.spacing(2)}px;
      display: flex;
      height: 20px;
    }

    .product-card-precio {
      display: flex;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .product-card-precio-simple {
      display: flex;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .superSKU-color {
      width: 25px;
      height: 25px;
      margin-right: 5px;
     /* border: 1px solid ${color};*/
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 2px solid #FFFFFF;
      background-color: ${color};
      border-radius: 12px;
      box-shadow: 0px 3px 6px #00000029;
    }

    .masOpcionesDisponibles {
      margin-bottom: ${theme.spacing(1)}px;
      margin-left: ${theme.spacing(1)}px;
    }
  
    .styled--productcardimg-info {
      display: flex;
      padding: 10px;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .product-card-image {
      ${theme.breakpoints.down("sm")} {
        position: relative;
        height: 150px;
        width: 150px;
        align-self: center;
        /*border: 1px solid blue;*/
        div img {
          height: 150px;
        }
      }
  
      ${theme.breakpoints.down("md")} {
        position: relative;
        height: 185px;
        /*border: 1px solid blue;*/
        div img {
          height: 185px;
        }
      }

      ${theme.breakpoints.up("md")} {
        position: relative;
        height: 285px;
        /*border: 1px solid blue;*/
        div img {
          height: 285px;
        }
      }
  
    } 

    .styled--productcard-variante {
      display: flex;
      height: 25px;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .styled--productcardvariante-mobile {
      display: flex;
      height: 25px;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(4)}px;
    }

    .product-card-price-promocion {
      display: flex;
      align-items: center;
      /*height: 71px;*/
    }  
  
    .produt-card-regularpromo {
      height: 25px;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
      ${theme.breakpoints.down("sm")} {
        height: 38px;
        padding-left: 0px;
      }
    }

    .product-card-compare {
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
    }
  
    .produt-card-caption {
      height: 20px;
      display: flex;
      align-items: center;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(1)}px;
      margin-top: ${theme.spacing(1)}px;
    }

    .styled--showdetails-container {
      width: 100%;
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .verDetalleRecomendado {
      width: calc(100% - 20px);
      margin-left: ${theme.spacing(2)}px;
      margin-right: ${theme.spacing(2)}px;
      margin-top: 8px;
    }
  

    &.product-card-shadow {
      border: none !important;
    }

    .styledProductCard--button-wishList{
      width: 100%;
      position: absolute;
      text-align: -webkit-right;
      ${theme.breakpoints.up("md")} {
        margin-top: ${theme.spacing(2)}px;
      }
    }

    .product-image {
      height: 150px;
      width: 150px;
      background-size: contain;
    }
  
    .promo_plp-ribbon-hv {
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .styled--product-img {

      ${theme.breakpoints.down("sm")} {
        height: 150px;
        width: 150px;
        background-size: contain;
        /*border: 1px solid red;*/
      }
    
      ${theme.breakpoints.up("sm")} {
        height: 285px;
        background-size: contain;
        /*border: 2px solid red;*/
      }
    }

    .styled--product-quantity {
      display: flex;
      height: 50px;
      align-items: end;
    }

    .styled--addtocart-container {
      margin-left: 5px;
      width: 100%;
    }

    .product-card-overflow {overflow:visible;}

    .link-preferencial-price {
      display: flex;
      width: 100%;
      ${theme.breakpoints.down("sm")} {
        justify-content: center;
      }
    }

  `}
`;

const CompareBox = ({ compare, product, catentryId }) => {
  const { type: t } = product;
  const visibility = t === "product" || t === "item" || t === "variant" ? "visible" : "hidden";
  return (
    <div style={{ marginLeft: "0px", visibility }}>
      <StyledFormControlLabel
        control={
          <StyledCheckboxCompare
            color="primary"
            className="compare-checkbox"
            disabled={!!(!compare.data.checked[catentryId] && compare.data.disabled)}
            checked={!!compare.data.checked[catentryId]}
            onChange={(e) => compare.onChange(e, catentryId, product)}
          />
        }
        label={<StyledTypography variant="bodyBaseline">{compare.t("productBox.comparar")}</StyledTypography>}
      />
    </div>
  );
};

interface ProductCardProps {
  seoUrl: any;
  name: any;
  ribbonads: any[];
  thumbnail: any;
  thumbnailLoading?: boolean;
  price?: number | null;
  swatches: any[];
  catentryId?: string;
  onClick?: any;
  actions?: any;
  className?: any;
  categoryId?: string;
  formattedPriceDisplay: any;
  link?: any;
  compare?: any;
  product?: any;
  isB2B: boolean;
  productRecomendation?: boolean;
  hasSSKU?: boolean;
  handleDeleteWishProduct?: (data) => void;
  wishListItems: any;
  wishListId: string;
}

/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */
function ProductCard(props: ProductCardProps) {
  const {
    seoUrl,
    name,
    ribbonads,
    thumbnail,
    categoryId = "",
    thumbnailLoading = false,
    price = null,
    swatches,
    onClick,
    catentryId = "",
    formattedPriceDisplay,
    actions: cardActions = null,
    link = null,
    compare,
    product,
    productRecomendation,
    hasSSKU,
    handleDeleteWishProduct,
    wishListItems,
    wishListId,
  } = props;

  const [productData, setProductData] = useState<any>(product?.product);
  const [finalProductData, setFinalProductData] = useState(product);
  const [windowSize, setWindowSize] = useState({ width: 320, height: 0 });
  const [quantity, setQuantity] = useState<string>("1");
  const to = useMemo(() => (link?.pathname ? link.pathname : seoUrl), [link, seoUrl]);
  const linkState = useMemo(() => link?.state ?? { categoryId }, [link, categoryId]);
  const onClickAction = useMemo(() => (onClick ? { onClick } : {}), [onClick]);
  const { t } = useTranslation();
  const [nominalQuantity, setNominalQuantity] = useState<string>("1");

  const storeConfData = useSelector(onlineStoreRdc);
  const hdmOrderItemsSelector = useSelector(orderItemsSelector);
  const hdmWishListProductsQuantity = useSelector(hdmWishListProductsQuantityRdc);
  const dispatch = useDispatch();
  const { mySite } = useSite();
  const loginStatus = useSelector(loginStatusSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMobile = true;
  const isB2B = Boolean(mySite?.isB2B);
  const storeSelector = useSelector(currentStoreSelector);
  const currentMarketId = storeSelector?.currentStore?.marketId;
  const currentStoreId = storeSelector?.currentStore?.stLocId;
  const contract = useSelector(currentContractIdSelector);
  const partNumber = finalProductData.partNumber;
  const compareBox = useMemo(
    () => (compare ? <CompareBox {...{ compare, product, catentryId }} /> : <></>),
    [compare, product, catentryId]
  );

  const getMainProductData = async (partNumber: string) => {
    const mainProduct: Array<object> = [];
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${mySite.storeID}&partNumber=${partNumber}&catalogId=10101&contractId=${contract}&langId=-5&physicalStoreId=${currentStoreId}`
    )
      .then((response) => {
        if (response && response.status === 200 && response?.data?.contents.length > 0) {
          const products = response.data.contents;
          products.forEach((p) => {
            mainProduct.push(p);
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    if (mainProduct && mainProduct.length > 0) {
      setProductData(mainProduct[0]);
    }
  };
  useEffect(() => {
    if (product?.type === "package" && productData === undefined) {
      // We need to fetch product information for this SKU
      if (partNumber) {
        getMainProductData(partNumber);
      }
    }
  }, [productData]);

  useEffect(() => {
    if (productData?.type === "package" && finalProductData?.product === undefined && productData !== undefined) {
      setFinalProductData((value) => ({ ...value, ["product"]: productData }));
    }
  }, [finalProductData, productData]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth - 20,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let newNominalQuantity: any = product?.["x_measurements.nominalQuantity"]
      ? parseInt(product["x_measurements.nominalQuantity"]).toString()
      : "1";
    if (hdmOrderItemsSelector && hdmOrderItemsSelector?.length !== 0 && product) {
      const productInCart = hdmOrderItemsSelector.find((orderItem) => orderItem?.partNumber === product?.partNumber);
      if (productInCart && newNominalQuantity) {
        newNominalQuantity = parseInt(newNominalQuantity) - parseInt(productInCart?.quantity);
        if (newNominalQuantity <= 0) {
          setNominalQuantity("1");
          setQuantity("1");
        } else {
          setNominalQuantity(newNominalQuantity);
          setQuantity(newNominalQuantity);
        }
      } else {
        setNominalQuantity(newNominalQuantity);
        setQuantity(newNominalQuantity);
      }
    } else {
      setNominalQuantity(newNominalQuantity);
      setQuantity(newNominalQuantity);
    }
  }, [hdmOrderItemsSelector, product]);

  useEffect(() => {
    if (hdmWishListProductsQuantity.length && product?.isWishProduct) {
      const data = hdmWishListProductsQuantity.map((item) => {
        if (item.id === product.id) {
          return {
            id: product.id,
            quantity: quantity ? quantity : "1",
          };
        }
        return item;
      });

      const query = {
        url: "",
        data,
        option: "WISH_LIST_PRODUCTS_QUANTITY",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  }, [quantity]);

  const handleSetQuantity = (value: string) => {
    setQuantity(value);
  };

  if (!finalProductData) return null;
  let productPartNumber = product?.partNumber !== "" && product?.partNumber !== undefined ? product?.partNumber : "";
  productPartNumber =
    productPartNumber.substr(productPartNumber.length - 1) === "P"
      ? productPartNumber.substr(0, productPartNumber.length - 1)
      : productPartNumber;
  const productManufacturer = product?.manufacturer !== "" ? product.manufacturer : "";
  const productName = product?.name !== "" ? product?.name : "";
  const urlRibbons = ribbonsPromoIconsImg(storeConfData?.IMAGE_SERVER_HOSTNAME);
  const attributes = getStoreOnlyAvailable(product, currentMarketId);
  const promociones = getPromoicons(attributes, isB2B, currentStoreId, currentMarketId);
  const orderRibbons = ordenarJerarquia(promociones);
  const quantityMeasure = product["x_measurements.quantityMeasure"]
    ? getUnidadMedida(product["x_measurements.quantityMeasure"])
    : "";
  const weightMeasure = product["x_measurements.weightMeasure"] ? getPesoMedida(product["x_measurements.weightMeasure"]) : ""
  const ratingPromedio = product["x_ratings.rating"] ? product["x_ratings.rating"] : 0;
  const ratingReviews = product["x_ratings.total_reviews"] ? product["x_ratings.total_reviews"] : 0;
  // const nominalQuantity = product?.["x_measurements.nominalQuantity"] ? parseInt(product["x_measurements.nominalQuantity"]).toString() : "1";
  const contentComponent = (
    <div
      className={`styled--productcard-container 
      ${product?.isWishProduct && isMobile && "styled--productcard-container-wishList"}`}>
      {product?.isWishProduct ? (
        <ComboLinks
          handleLink1={{
            type: "list",
            data: {
              wishListId: wishListId,
              partNumber: productPartNumber,
              productName: productName,
              giftListItemID: product?.giftListItemID,
            },
            text: "Mover de lista",
            option: "moveToList",
            handleFunctionLink: handleDeleteWishProduct,
          }}
          handleLink2={{
            type: "function",
            data: product,
            text: "Eliminar",
            handleFunctionLink: handleDeleteWishProduct,
          }}
        />
      ) : null}
      <StyledBox className={isMobile ? "styled--productcardimg-info" : ""}>
        {/* <LazyLoadComponent visibleByDefault={(window as any).__isPrerender__ || false}> */}
        <StyledProductCardImage
          partNumber={partNumber}
          isWishProduct={product?.isWishProduct}
          storeConfData={storeConfData}
          name={name}
          to={to}
          promociones={promociones}
          urlRibbons={urlRibbons}
        />
        {/* </LazyLoadComponent> */}
        {isMobile ? (
          <>
            <ProductCardBasicInfo
              productPartNumber={productPartNumber}
              productManufacturer={productManufacturer}
              productName={productName}
              ratingPromedio={ratingPromedio}
              ratingReviews={ratingReviews}
              to={to}
            />
          </>
        ) : null}
      </StyledBox>
      {!isMobile ? (
        <>
          <ProductCardBasicInfo
            productPartNumber={productPartNumber}
            productManufacturer={productManufacturer}
            productName={productName}
            ratingPromedio={ratingPromedio}
            ratingReviews={ratingReviews}
            to={to}
          />
          <StyledBox className={"styled--productcard-variante"}>
            <SuperSKU product={product} promociones={promociones} hasSSKU={hasSSKU} />
          </StyledBox>
        </>
      ) : (
        <StyledBox
          className={`styled--productcardvariante-mobile ${
            !hasSSKU ? "styled--productcardvariante-mobile-notSSKU" : ""
          }`}>
          <SuperSKU product={product} promociones={promociones} hasSSKU={hasSSKU} />
        </StyledBox>
      )}

      {/* {swatches.length > 0 && <StyledTypography align="center">{swatches}</StyledTypography>} */}
      <StyledBox className={isMobile ? "product-card-price-promocion" : ""}>
        {product?.type === "package" && finalProductData?.product !== undefined ? (
          <ProductPrice
            productData={finalProductData}
            weightMeasure={weightMeasure}
            quantityMeasure={quantityMeasure}
            promociones={promociones}
            urlRibbons={urlRibbons}
          />
        ) : (
          <ProductPrice
          weightMeasure={weightMeasure}
            productData={product}
            quantityMeasure={quantityMeasure}
            promociones={promociones}
            urlRibbons={urlRibbons}
          />
        )}

        <StyledBox className="produt-card-regularpromo">
          <PromoIcons orderRibbons={orderRibbons} urlRibbons={urlRibbons} />
        </StyledBox>
      </StyledBox>

      <StyledBox className={"product-card-compare"}>{compareBox}</StyledBox>
      <StyledBox className={`produt-card-caption ${!promociones ? "produt-card-notCaption" : ""}`}>
        {promociones &&
        ((promociones.includes("STORE_ONLY_AVAILABLE") && !isB2B) ||
          (promociones.includes("PRO_STORE_ONLY_AVAILABLE") && isB2B && loginStatus)) ? (
          <StyledTypography variant={"bodyBaseline"} className="color-gray300">
            {t("productBox.VentaExclusivaTienda")}
          </StyledTypography>
        ) : !loginStatus && isB2B ? (
          <StyledBox className="link-preferencial-price">
            <StyledLinkTextSecondary
              variant={"bodySubText"}
              font="12px"
              color="secondary"
              onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
              {t("productBox.Login")}
            </StyledLinkTextSecondary>
            <StyledTypography variant={"bodySubText"} className="color-gray300 left-margin-1">
              {t("productBox.PreferencialPrice")}
            </StyledTypography>
          </StyledBox>
        ) : (
          <>
            {product && parseInt(product?.["x_measurements.nominalQuantity"]) > 1 && (
              <StyledTypography variant="bodySubText" className={"color-gray300"}>
                {t("productDetail.CartOrderMinimalQuantity") + parseInt(product?.["x_measurements.nominalQuantity"])}{" "}
              </StyledTypography>
            )}
          </>
        )}
      </StyledBox>
      {promociones &&
      ((promociones.includes("STORE_ONLY_AVAILABLE") && !isB2B) ||
        ((promociones.includes("PRO_STORE_ONLY_AVAILABLE") || !loginStatus) && isB2B)) ? (
        <StyledBox className={"styled--showdetails-container"}>
          <Link to={to}>
            <StyledButton
              variant="outlined"
              className={"productBoxVerDetalle"}
              disableRipple={true}
              fullWidth
              onClick={null}>
              {t("productBox.VerDetalle")}
            </StyledButton>
          </Link>
        </StyledBox>
      ) : (
        <StyledBox className="styled--product-quantity top-margin-2 horizontal-margin-2">
          {isMobile ? (
            <ProductQuantity
              nominalQuantity={nominalQuantity}
              quantity={quantity}
              handleSetQuantity={handleSetQuantity}
              isNotZero={true}
            />
          ) : (
            <InputCantidad
              nominalQuantity={nominalQuantity}
              quantity={quantity}
              handleSetQuantity={handleSetQuantity}
            />
          )}
          <StyledBox className="styled--addtocart-container">
            {product?.type === "package" && productData?.components !== undefined ? (
              <>
                <AddKitToKart
                  kit={productData}
                  partNumber={partNumber}
                  productQuantity={parseInt(quantity)}
                  variant="outlined"
                  wording="Agregar"
                />
              </>
            ) : (
              <>
                <AddProductCart
                  quantity={quantity}
                  partnumber={partNumber}
                  variant="outlined"
                  wording="Agregar"
                  manufacturer={productManufacturer}
                  attributes={attributes}
                />
              </>
            )}
          </StyledBox>
        </StyledBox>
      )}
    </div>
  );

  if (productRecomendation) {
    return <EmarsysProduct product={product} />;
  }

  return (
    <>
      {/* {ribbonads.map(({ identifier, value }: any, i) => (
        <RibbonAd {...{ identifier, value, idx: i, key: i }} />
      ))} */}
      <StyledProductCard
        className={"product-card"}
        smWidth={product?.isWishProduct ? "100%" : `${windowSize.width - 5}px`}
        // to={to}
        style={{ position: "relative" }}
        state={linkState}
        id={catentryId ? `productCard_a_1_${catentryId}` : ""}
        {...onClickAction}>
        <StyledCardProduct
          testId={catentryId}
          {...{
            className: `product-card-shadow product-card-overflow ${compare ? "w-compare-box" : ""}`,
            contentComponent,
            cardActions,
          }}
        />
        {/* {isMobile ? null : <ProductBadgedPromotion promociones={productData.promociones} badge={productData.badge} />} */}
        {product?.isWishProduct ? null : <ProductBadgedPromotion promociones={promociones} badge="" brand={product?.manufacturer}/>}
      </StyledProductCard>
    </>
  );
}
export default ProductCard;
