import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Swiper } from "swiper/react";


export const StyledSwiper = styled((props: any) => <Swiper {...props} />)`
${({ theme }) => `

    .swiper-wrapper{
        // padding-bottom: ${theme.spacing(6)}px;
    }

    & .swiper-zoom-container{
        @media (min-width: 1366px) {
            max-width: 600px;
            max-height: 600px;
        }
    }

    & .swiper-zoom-container{
        & img{
            @media (min-width: 1366px) {
                max-height: 600px;
                max-width: 600px;
                min-width: 600px;
                min-height: 600px;
            }
            @media (min-width: 1024px) and (max-width: 1365px) {
                max-width: 480px;
                max-height: 480px;
                min-width: 480px;
                min-height: 480px;
            }
        }
    }

    &.main-swiper{
        max-width: 600px;
        max-height: 600px;

        @media (min-width: 1024px) and (max-width: 1365px) {
            max-width: 480px;
        }
    }

    & .zoom-selector{
        position: absolute;
        margin: 0px;
        padding: 0;
        width: 200px;
        height: 150px;
        background-color: #78787c4d;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    & .swiper-slide-next{
        transition: 0.3s;
        opacity: 0;
    }
    & .swiper-slide-prev{
        transition: 0.3s;
        opacity: 0;
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
        padding-top: ${theme.spacing(1)}px;
        background-color: #fff;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    &.thumbs{
        margin: 0px 0px 0px 10px;
        width: 100px;
        max-height: 100%;
        height: 600px;

        @media (min-width: 1024px) and (max-width: 1365px) {
            max-height: 480px;
        }

        & .swiper-wrapper{
            box-sizing: border-box;
        }

        & .swiper-slide {
            transition: 0.3s;
            opacity: 0.4;
            padding: 10px;
        }

        & .swiper-slide-thumb-active {
            transition: 0.3s;
            opacity: 0.9;
            & img{
                border: solid 1px ${theme.palette.primary.main};
                border-radius: 3px;
            }
        }

        & .swiper-slide{
            cursor: pointer;
        }
    }

    

    `}
`;

export const GalleryContainer = styled((props: any) => <Box {...props} />)`
    ${({ theme }) => `
    ${theme.breakpoints.down("lg")} {
        padding: 10px ${theme.spacing(10)}px 10px ${theme.spacing(10)}px;
    }

    ${theme.breakpoints.down("lg")} {
        padding: 10px;
    }

        & .nextButton{
            font-size: 40px;
            color: ${theme.palette.primary.main};
            background-color: #ffffff60;
            border: solid 1px ${theme.palette.primary.light};
            border-radius: 3px;
            cursor: pointer;

            position: absolute;
            right: 32px;
            width: 65px;
            bottom: 4%;
            z-index: 10;

        }

        & .prevButton{
            font-size: 40px;
            color: ${theme.palette.primary.main};
            background-color: #ffffff60;
            border: solid 1px ${theme.palette.primary.light};
            border-radius: 3px;
            cursor: pointer;

            position: absolute;
            right: 32px;
            width: 65px;
            top: 4%;
            z-index: 10;

        }

        & .prevButtonNone{
            display: none;
        }

        & .nextButtonNone{
            display: none;
        }

        & .hide-thumb-stepper{
            transition: 0.3s;
            display: none;
        }

        & .ar-viewer-container{
            height: 100%;
            & div{
                height: 100%;
            }
        }

        & .qr-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
                & svg{
                    font-size: 45px;
                }
                & img{
                    height: 300px;
                }
                & p{
                    margin: 10px 0;
                    max-width: 330px;
                    text-align: center;
                }
        
        }

    

       

    `}
`;

export const ZoomedContainer = styled((props: any) => <Box {...props} />)`
    ${({ theme }) => `
        & .zoomed-image{
            overflow: hidden;
            position: absolute;
            z-index: 500;
            // // right: -60%;
            margin: auto;
            // top: 19%;

            

            @media (min-width: 1025px) and (max-width: 1254px) {
                right: -64%;
                top: 2%
            }

            @media (min-width: 1255px) and (max-width: 1365px) {
                right: -45%;
                top: 2%;
            }
            
            @media (min-width: 1366px) and (max-width: 1500px) {
                right: -58%;
                top: 2%;
            }

            @media (min-width: 1501px) {
                right: -50%;
                top: 2%;
            }

            
            & img{
                max-width: none;
            }
        }
    `}
`;
export const CustomDiv = styled((props: any) => <div {...props} />)`
    ${({ theme }) => `
        &.cover-layer-pdp{
            height: 500px;
            display: none;
        }
    `}
`;