import { StyledContainer, StyledBox, StyledTypography } from "../../../../hdm/elements";
import React, { FC } from "react";

// import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import styled from "styled-components";
import ButtonHDM from "../cart-item/ButtonHDM";
import EmptyCart from "../../../../assets/homedepot/images/quickcart-empty.svg";
import { useDispatch } from "react-redux";
import { CLOSE_CART_DRAWER_ACTION } from "../../../../../src/redux/actions/drawer";
import { useTranslation } from "react-i18next";

interface NoItemsCartProps {
  showCart: any;
  setShowCart: any;
}

const StyledNoItemWrapper = styled(({ ...props }) => <StyledContainer {...props} />)`
  ${({ theme }) => `

        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(102vh - 60px);
        justify-content: space-between;

        & .empty-cart-title {
            margin-top: 10px;
        }

        & .empty-cart-box{
            padding-top: 10px;
        }

        & .__cart-icon-background {
            margin-top: 10px;
            border-radius: 50%;
            overflow: hidden;
            height: 125px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .__no-item-cart-icon {
            color: black;
            font-size: 90px;
        }

        .styled-emptycard-img {
          width: 130px,
          height: 130px;
        }
    `}
`;

const NoItemsCart: FC<NoItemsCartProps> = (props: any) => {
  // const { setShowCart } = props;

  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(
      CLOSE_CART_DRAWER_ACTION(() => {
        /* */
      })
    );
  };

  return (
    <StyledNoItemWrapper className="container">
      <StyledBox className="empty-cart-box">
        <StyledTypography variant="bodyBaseline" className="empty-cart-title">
          {t("MiniCart.CartIsEmpty")}
        </StyledTypography>

        <StyledBox className="__cart-icon-background">
          <img alt="Empty cart" className="styled-emptycard-img lazyload" src={EmptyCart} />
          {/* <ShoppingCartOutlinedIcon className="__no-item-cart-icon" /> */}
        </StyledBox>
        {/* <CardIcon /> */}
      </StyledBox>

      <StyledBox className="close-button">
        <ButtonHDM variant="default" text="Cerrar" onClick={() => handleClose()} />
      </StyledBox>
    </StyledNoItemWrapper>
  );
};

export default NoItemsCart;
