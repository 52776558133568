import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";
import theme, { themeOptions } from "../../../../hdm/themes/thd";


export const StyledSkeleton = (props)=>{
  const { noCard, sharp } = props;

  const styles = {
    backgroundColor:noCard?themeOptions.palette.gray[70]:"",
    borderRadius: sharp?"":"5px"
  }
  return <StyledSkeletonComponent {...props} style={styles} />;
}

const StyledSkeletonComponent = styled(Skeleton)`
${(props) => `
      background-color:#EEE;
      &.wide{
          width:100%;
      }    
`}
`;