import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { lineaGamaColorsRdc, hdmDrawerColorDataRdc, hdmColorsRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";

import styled from "styled-components";
import { StyledPromp } from "../../../components/custom-components/styled-promp";
import { StyledBox, StyledGrid, StyledLinkTextSecondary, StyledTypography } from "../../elements";

const ContainerColorSelected = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

    .styled--yourcolor-container {
        display: flex;
        flex-direction: row;
        padding-left: 5px;
        padding-right: 5px;
    }

    .styled--yourcolor-text {
        flex-grow: 1;
    }

    .styled--colortext-container {
        display: flex;
        flex-direction: column;
        height: 64px;
        border: 1px solid ${theme.palette.gray["50"]};
        margin-bottom: 5px;
    }

    .styled--color-container {
        height: 40px;
        background-color: rgb(${color?.R}, ${color?.G}, ${color?.B}); 
    }
  
    .styled--codecolor-container {
        display: flex;
        flex-direction: row;
        padding: 5px;
    }
  
    .styled--codigo-color {
        flex-grow: 1;
        margin-left: 5px;;
        color: ${theme.palette.gray["300"]};
    }

`}
`;

export const ColorSelected = (props) => {
  const { colorCodeSelected, isDrawer = true } = props;
  const { t } = useTranslation();
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    if (colorCodeSelected?.codigo !== "N/A" && isDrawer === false) {
      let url = window.location.href;
      const urlSplit = url.split("#colorCode:");

      if (urlSplit.length > 1) {
        url = `${urlSplit[0]}#colorCode:${colorCodeSelected?.codigo}`;
      } else {
        url = `${url}#colorCode:${colorCodeSelected?.codigo}`;
      }

      window.history.replaceState(null, "", url);
    }
  }, [colorCodeSelected]);

  const handleOpenBuyByColor = (gama) => {
    const searchGama = gama !== "#N/D" ? gama : "Pasteles";

    const selectedGamaColors = hdmLineaGamaColor.filter(
      (lineaGama) => lineaGama.linea === hdmDrawerColorData?.lineColorValue && lineaGama.gama === searchGama
    )[0];

    const query = {
      url: "",
      data: {
        ...hdmDrawerColorData,
        gamaColors: selectedGamaColors?.colores,
        isOpen: true,
        gama: searchGama,
      },
      option: "HDM_DRAWER_COLOR_DATA",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  return (
    <ContainerColorSelected color={{ R: colorCodeSelected?.R, G: colorCodeSelected?.G, B: colorCodeSelected?.B }}>
      <StyledBox className="styled--yourcolor-container">
        <StyledTypography variant="bodyBaseline" className="styled--yourcolor-text">
          {t("productDetail.BuyByColor.labels.yourColor")}
        </StyledTypography>
      
        {props.handleOpenBuyByColor && colorCodeSelected?.codigo !== "N/A" ? (
          <StyledLinkTextSecondary
            id="modifyColorLink"
            color="secondary"
            font="15px"
            onClick={() => handleOpenBuyByColor(colorCodeSelected?.gama)}>
            {t("productDetail.BuyByColor.labels.modify")}
          </StyledLinkTextSecondary>
        ) : null}
      </StyledBox>

      {colorCodeSelected?.base !== "Blanca" && colorCodeSelected?.codigo === "N/A" ? (
        <StyledBox className="vertical-padding-2">
          <StyledPromp variant="alert" message={t("productDetail.BuyByColor.labels.transparentBase")} />
        </StyledBox>
      ) : (
        <StyledGrid className="styled--colortext-container">
          <StyledBox className="styled--color-container"></StyledBox>
          <StyledBox className="styled--codecolor-container">
            <StyledTypography variant="bodyCaption" component="span" className="color-gray300">
              {colorCodeSelected?.codigo !== "N/A"
                ? t("productDetail.BuyByColor.labels.code")
                : colorCodeSelected?.nombre}
            </StyledTypography>
            <StyledTypography variant="bodyCaption" fontWeight={600} component="span" className="styled--codigo-color">
              {colorCodeSelected?.codigo !== "N/A" ? colorCodeSelected?.codigo : ""}
            </StyledTypography>
            <StyledTypography variant="bodyCaption" component="span" className="color-gray300">
              {colorCodeSelected?.codigo !== "N/A" ? colorCodeSelected?.nombre : ""}
            </StyledTypography>
          </StyledBox>
        </StyledGrid>
      )}
    </ContainerColorSelected>
  );
};
