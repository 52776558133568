/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, { Dispatch, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { site } from "./_foundation/constants/site";
import { initAxios } from "./_foundation/axios/axiosConfig";
import { initSite, useSite } from "./_foundation/hooks/useSite";
import LoginGuard from "./_foundation/guard/LoginGuard";
import { storageSessionHandler, localStorageUtil } from "./_foundation/utils/storageUtil";
import { LOCALE } from "./_foundation/constants/common";
import storeService from "./_foundation/apis/transaction/store.service";
import { PRODUCTION } from "./_foundation/constants/common";
//Custom libraries
import { ROUTE_CONFIG } from "./configs/routes";
import { CommerceEnvironment, DISCOVER_FEATURE, EMPTY_STRING, HYPHEN, UNDERSCORE } from "./constants/common";
import { Header } from "./components/header";
import { Extensions } from "./components/extensions";
import SuccessMessageSnackbar from "./components/widgets/message-snackbar/SuccessMessageSnackbar";
import ErrorMessageSnackbar from "./components/widgets/message-snackbar/ErrorMessageSnackbar";
import { IFRAME_RESIZER } from "./_foundation/constants/csr";
//Redux
import {
  GUEST_LOGIN_SUCCESS_ACTION,
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
  SAVE_MOBILE_SESSION_ACTION,
} from "./redux/actions/user";
import { FETCH_HDM_DATA_REQUESTED } from "./redux/action-types/hdmData";
import jsonStateCityList from "../src/components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";

//UI
import { StyledWrapper } from "./components/StyledUI";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";

//GA360
//UA
import GTMDLService from "./_foundation/gtm/ua/gtmDataLayer.service";
//GA4
import GA4GTMDLService from "./_foundation/gtm/ga4/gtmDataLayer.service";
import { SELLERS_GET_ACTION } from "./redux/actions/sellers";
import TagManager from "react-gtm-module";
import { withUseJsonESpots } from "./hdm/widgets/json-espot/json-espots";
import "./App.scss";
import dbColorsHDM from "./pinturashdm.json";
import { getPinturasJson } from "./hdm/functions/utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { UTMCookieContainer } from "./components/custom-components/utm-cookie-container/UTMCookieContainer";
import { onlineStoreRdc } from "./redux/selectors/hdmData";
import BackDropLoader from "./components/custom-components/backdrop-loader/BackdropLoader";
import LinkDOMElement from "./components/custom-components/link-dom-element/LinkDOMElement";
import FooterWithData from "./components/footer/FooterWithData";
import { getCookieValue } from "./components/custom-components/conversion-api/cookieHandler";
import { Style } from "@material-ui/icons";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import styled from "styled-components";
import * as ROUTES from "../src/constants/routes";
import { addCartPromo, updatePromotionDetails } from "./components/custom-components/Datalayer-Utils/dataLayer-func";



const FooterMainContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  &.footer-main-container {
    border: 2px solid red;
    display: none;
  }

`}
`;

declare global {
  interface Window {
    setRadioStoreValue: any;
    setStateSelected: any;
    setOpenDialogGps: any;
    setOpenMiniCart: any;
    setOpenMiniSingIn: any;
    setOpenDrawer: any;
    orderItemsTYP: any;
    cartTYP: any;
    person: any;
    flex: any;
    instanceFlex: any;
    checkoutProfile: any;
    ScarabQueue: any;
    $BV: any;
    microform: any;
    externalValidation3ds: any;
    showCheckoutPromos: any;
    console: any;
    AnalyticsWebInterface: any;
    webkit: any;
  }
}
const ScrollToTop = () => {
  React.useEffect(() => {
    if(process.env.NODE_ENV !== "development"){
      console.log = () => {
        return null
      };
    }
    //scroll to top on path change.
    setTimeout(() => {
      window.scrollTo(0, 0);
    });

    
  }, []);
  return <></>;
};

const RouteRenderer = () => {
  const { mySite } = useSite();
  const e = useRoutes(mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C);
  return e;
}

const App: React.FC = (props: any) => {
  const widgetName = getDisplayName(App);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite, storeDisplayName } = useSite();
  const { i18n } = useTranslation();

  const CancelToken = Axios.CancelToken;
  const MemRouteRenderer = useCallback(RouteRenderer, []);
  const statesCities = jsonStateCityList;

  const helperIcon = document.getElementById("helpShortcut") as HTMLInputElement | null;
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");
 
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const [discover, setDiscover] = React.useState<boolean>(false);
  const [isI18nLoaded, setIsI18nLoaded] = React.useState<boolean>(false);
  const isB2B = mySite?.isB2B;


  const storeConfData = useSelector(onlineStoreRdc);
  const [btPublicKey, setBtPublicKey] = useState<any>(null);
  const [isLivePerson, setIsLivePerson] = useState<any>(null);
  const [googleApiKey, setGoogleApiKey] = useState<any>(null);
  useEffect(() => {
    const logs = () => {
      if (storeConfData?.ENABLE_CONSOLE_LOGS === "false") {
        if (typeof window.console === "undefined") {
          window.console = {};
        }
        window.console.log = function () {
          // Do nothing
        };
      }
    };
    if (storeConfData?.BT_PUBLIC_KEY && storeConfData?.BT_ACTIVE_FLAG != "false") {
      setBtPublicKey(storeConfData?.BT_PUBLIC_KEY);
    }
    if (storeConfData?.googleAPIGeoLocateKey) {
      setGoogleApiKey(storeConfData?.googleAPIGeoLocateKey);
    }
    if (storeConfData?.LP_ACTIVE_FLAG && storeConfData?.LP_ACTIVE_FLAG != "false") {
      setIsLivePerson(true);
    }
    if (storeConfData?.GTM_TURN_OFF && storeConfData.GTM_TURN_OFF === "false") {
      const tagManagerArgs = {
        gtmId: storeConfData?.GTM_ID,
      };
      // Google tag manager
      TagManager.initialize(tagManagerArgs);
    }
    logs();
  }, [storeConfData]);

  useEffect(() => {
    i18n.on("loaded", () => {
      setIsI18nLoaded(true);
    });
  }, []);
  useEffect(() => {
    if (mySite) {
      getExclusive();
      getEstadosCiudades();
      getMarketStoreIds();
      setwishListOrderBy();
      getPinturas();
    }
  }, [mySite]);

  const setwishListOrderBy = () => {
    const query = {
      url: "",
      data: "Agregados más recientes",
      option: "WISH_LIST_PRODUCTS_ORDERBY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const getExclusive = () => {
    const query = {
      url: `/wcs/resources/store/${mySite.storeID}/online_store`,
      data: {},
      option: "ONLINE_STORE",
      fetch: true,
    };
    // const query = `/wcs/resources/store/${mySite.storeID}/online_store`;
    // const query = `/wcs/resources/store/${mySite.storeID}/hdm/storeview/byStates`;
    if (storeConfData === null) {
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  };

  const getFetchUniqueId = async (data) => {
    const storesData: any = [];

    await Axios.get(`/wcs/resources/store/${mySite.storeID}/hdm/physicalStore/ids?responseFormat=json`)
      .then((response) => response.data)
      .then((response) => {
        if (response?.DataList && response.DataList.length !== 0) {
          response.DataList.map((DataList) => {
            if (DataList?.PhysicalStore && DataList.PhysicalStore.length !== 0) {
              DataList.PhysicalStore.map((physicalStore) => {
                const storeData = data.find((store) => store?.stLocId === physicalStore?.storeName);
                // const storeData = {stLocId:physicalStore.storeName,...physicalStore}
                if (storeData) {
                  const mixStoreData = {
                    ...physicalStore,
                    ...storeData,
                    uniqueID: physicalStore?.uniqueID.toString(),
                  };
                  storesData.push(mixStoreData);
                }
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log("error lon lat", error);
      });

    return storesData;
  };

  const getMarketStoreIds = async () => {
    const storesData: any = [];
    statesCities.formScheduleObject.map((allStores) => {
      allStores.stores.map((store) => {
        storesData.push({
          stLocId: store.stLocId,
          marketId: store.marketId,
          stLocName: store.stLocName,
          shortName: store.shortName,
          storeName: store.storeName,
          address1: store.address1,
          address2: store.address2,
          city: store.city.trim(),
          cityId: store.cityId,
          zipcode: store.zipcode,
          phone: store.phone,
          latitude: store.latitude,
          longitude: store.longitude,
          scheduleDiystore: store.scheduleDiystore,
        });
      });
    });

    // function getUniqueListBy(arr, key) {
    //     return [...new Map(arr.map(item => [item[key], item])).values()]
    // }

    // const byCities = getUniqueListBy(storesData, 'city')

    let AllStoresData = [];

    const response = await getFetchUniqueId(storesData);
    AllStoresData = AllStoresData.concat(response);
    // await Promise.all(

    //   await byCities.map(async (marketCity: any) => {

    //     return true
    //   })
    // )

    const query = {
      url: "",
      data: AllStoresData,
      option: "HDM_STORES_DATA",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const getEstadosCiudades = () => {
    const EstadosCiudades: any[] = [];

    statesCities?.formstatesObject &&
      statesCities.formstatesObject.map((dataObj) => {
        const idEstado = dataObj.geonodeId;

        const dataEstado: any = statesCities.formScheduleObject.filter(
          (dataEstado) => dataEstado.stateId === idEstado
        )[0];

        const ciudadIn: string[] = [];
        const ciudadesSelected: any[] = [];

        dataEstado.stores.map((ciudad) => {
          const city = ciudad.city.trim();

          if (!ciudadIn.includes(city)) {
            ciudadIn.push(city);

            ciudadesSelected.push({
              stLocId: ciudad.stLocId,
              ciudad: city,
            });
          }
        });

        EstadosCiudades.push({
          idEstado: idEstado,
          estado: dataObj.state,
          ciudades: ciudadesSelected,
        });
      });

    const query = {
      url: "",
      data: EstadosCiudades,
      option: "ESTADOS_CIUDADES",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const getPinturas = async () => {
    const allLineasGamaColors = getPinturasJson(dbColorsHDM);

    const query = {
      url: "",
      data: allLineasGamaColors,
      option: "All_LINEA_GAMA_COLORS",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const setTranslate = () => {
    /**
     * language preference priority
     * 1. user context, to be implemented with language toggle
     * 2. localStorage (saved for 30 days).
     * 3. store default language.
     */
    // TODO: language toggle, update user language, read language from userContext if it is registered user.
    if (mySite) {
      //check if locale exists in local storage
      if (localStorageUtil.get(LOCALE) === null) {
        //locale does not exist in local storage
        //get language from site default. convert from id to string
        const locale = CommerceEnvironment.languageMap[mySite.defaultLanguageID].split("_").join("-");
        //check if language from site default matches the current store language
        if (locale !== i18n.languages[0]) {
          //if not then change language
          i18n.changeLanguage(locale);
        }
        //set locale into local storage
        localStorageUtil.set(LOCALE, locale.split("-").join("_"));
      } else {
        const locale = localStorageUtil.get(LOCALE).split(UNDERSCORE).join(HYPHEN);
        i18n.changeLanguage(locale);
      }
    }
  };
  initAxios(dispatch);

  /**
   * Function to check Discover is enabled for store based on storeId
   *
   * @param storeID
   */
  const isDiscoverEnabled = (storeID: string) => {
    const payload = {
      storeId: storeID,
      ...payloadBase,
    };
    storeService
      .getStoreEnabledFeaturesList(payload)
      .then((res) => {
        if (res.data && res.data.resultList) {
          setDiscover(res.data.resultList.includes(DISCOVER_FEATURE));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    if (mySite) {
      const payloadBaseInit = {
        ...payloadBase,
        init: true,
        fetchCatentries: true,
      };
      const WCToken = getCookieValue("WCToken");
      const WCTrustedToken = getCookieValue("WCTrustedToken");
      const isGuest = getCookieValue("isGuest") === "true" ? true : false;
      const userLoggedIn = getCookieValue("userLoggedIn") === "true" ? true : false;
      const personalizationID = getCookieValue("personalizationID");
      const resourceName = getCookieValue("resourceName");
      const userId = getCookieValue("userId");
      const creative_name = getCookieValue("creative_name");
      const creative_slot = getCookieValue("creative_slot");
      const key_words = getCookieValue("key_words");
      const list_partnumbers = getCookieValue("list_partnumbers");
      const promotion_id = getCookieValue("promotion_id");
      const promotion_name = getCookieValue("promotion_name");
      const promoCartDtls = getCookieValue("promoCartDtls");
      if (window.location.pathname.includes("/cart")) {
        payloadBaseInit.init = false;
        payloadBaseInit.fetchCatentries = false;
      }
      if (isMobileApp) {
        if (helperIcon !== null) {
          helperIcon.hidden = true;
        }
        if (WCToken !== null) {
          const payloadMobile = {
            ...payloadBase,
            WCToken,
            WCTrustedToken,
            isGuest,
            userLoggedIn,
            personalizationID,
            resourceName,
            userId,
          };
          dispatch(GUEST_LOGIN_SUCCESS_ACTION({ ...payloadMobile }));
        }
        if(creative_name !== null || creative_slot !== null || key_words !== null || list_partnumbers !== null || promotion_id !== null || promotion_name !== null){
          const trimmedStr = key_words.slice(1, -1);
          const arrayWithSpaces = trimmedStr.split(',');
          const resultArray = arrayWithSpaces.map(item => item.trim());
          const promoDtl = {
            list_partnumbers: JSON.parse(list_partnumbers),
            key_words: resultArray,
            promotion_name: promotion_name || "NA",
            promotion_id: promotion_id || "NA",
            creative_name: creative_name || "NA",
            creative_slot: creative_slot || "NA"
          };
          updatePromotionDetails(promoDtl);
        }
        if(promoCartDtls !== null){
          const parsedValue = JSON.parse(promoCartDtls)
        const output = parsedValue.map(item => {
          const newObject = {
            SKU: item?.partNumbers,
            promotion_name: item?.promotionName || "NA",
            promotion_id: item?.promotionId || "NA",
            creative_name: item?.creativeName || "NA",
            creative_slot: parseInt(item?.creativeSlot)
          }
          return newObject
        });
        if (output && output.length > 0){
          output.forEach(element => {
            addCartPromo(element);
          });
        }
        }
      }
      dispatch(INIT_STATE_FROM_STORAGE_ACTION({ ...payloadBaseInit }));
      dispatch(SELLERS_GET_ACTION({ ...payloadBase }));
      storageSessionHandler.triggerUserStorageListener(() =>
        dispatch(LISTEN_USER_FROM_STORAGE_ACTION({ ...payloadBase }))
      );
      setTranslate();
      isDiscoverEnabled(mySite.storeID);
      //GA360
      if (mySite.enableGA) {
        if (mySite.enableUA) {
          GTMDLService.initializeGTM(mySite.gtmID, mySite.gtmAuth, mySite.gtmPreview);
        }
        if (mySite.enableGA4 && !mySite.enableUA) {
          GA4GTMDLService.initializeGTM(mySite.gtmID, mySite.gtmAuth, mySite.gtmPreview);
        }
      }
    } else {
      initSite(site, dispatch);
    }
  }, [mySite, dispatch]);

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  const baseName = process.env.REACT_APP_ROUTER_BASENAME ? { basename: process.env.REACT_APP_ROUTER_BASENAME } : {};

  // public url path for accessing discoverui.js file.
  const publicUrlPath = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : EMPTY_STRING;

  // Google tag manager
  if (!window["dataLayer"]) {
    window["dataLayer"] = [];
  }

  console.log(isB2B && !window.location.pathname.includes(ROUTES.PRO_LEAD_FORM) && !window.location.pathname.includes("/expired-link"))

  const HeaderWithData = useMemo(
    () =>
      withUseJsonESpots(
        Header,
        window.location.pathname.includes(ROUTES.PRO_LEAD_FORM) ?
          []
        : isB2B
          ? [
              "MobileMenu_Departments_SuperiorSection",
              "ProMenu_SuperiorSection",
              "ProMenu_InferiorSection1",
              "ProMenu_InferiorSection2",
              "ProMenu_InferiorSection3",
              "ProMenu_InferiorSection4",
              "ProMenu_InferiorSection5",
            ]
          : [
              "MobileMenu_Departments_SuperiorSection",
              "Menu_SuperiorSection",
              "Menu_inferiorSection1",
              "Menu_inferiorSection2",
              "Menu_inferiorSection3",
              "Menu_inferiorSection4",
              "Menu_inferiorSection5",
            ]
      ),
    [mySite]
  );

  class esLocalizedUtils extends DateFnsUtils {
    getWeekdays() {
      return ["L", "M", "M", "J", "V", "S", "D"];
    }
  }

  return !isI18nLoaded ? (
    <></>
  ) : (
    mySite && (
      <BrowserRouter {...baseName}>
        <LinkDOMElement />
        <BackDropLoader />
        {/*<EspotHdm espotName="script_test_content"/>*/}
        <MuiPickersUtilsProvider utils={esLocalizedUtils} locale={esLocale}>
          <StyledWrapper data-testid="app-wrapper">
            <SuccessMessageSnackbar />
            <ErrorMessageSnackbar />
            <StyledGrid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              className="full-viewport-height">
              <StyledGrid item xs={false} id={"headerContainer"}>
                <UTMCookieContainer />
                { 
                  isMobileApp ? (
                    <div style={{ display: "none" }}>
                      <HeaderWithData />
                    </div>
                  ) : (
                    <>
                      <HeaderWithData />
                    </>
                  )
                }
                <LoginGuard />
                <ScrollToTop />
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{`${storeDisplayName}`}</title>
                  {discover && (
                    <script
                      src={`${publicUrlPath}/discover/discoverui.js?q=${Date.now()}`}
                      type="text/javascript"
                      async
                    />
                  )}
                  {window[IFRAME_RESIZER] && (
                    <script src="/iframeResizer.contentWindow.min.js" type="text/javascript" async />
                  )} 
                  {isLivePerson && <script src={`${publicUrlPath}/liveperson.min.js`} type="text/javascript" /> }
                  {btPublicKey && <script src={`//${btPublicKey}.btttag.com/btt.js`} type="text/javascript" async />}
                  {googleApiKey && (
                    <script
                      src={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&loading=async&v=weekly`}
                      defer
                    />
                  )} 
                    {storeConfData?.ADOBE_SCRIPT_SRC && storeConfData?.ENABLE_ADOBE_SCRIPT_FLAG && storeConfData?.ENABLE_ADOBE_SCRIPT_FLAG === "true"
                      && <script src={`${storeConfData?.ADOBE_SCRIPT_SRC}`} type="text/javascript" async />}
                    {storeConfData?.ADOBE_SCRIPT_FUNCTION && storeConfData?.ENABLE_ADOBE_SCRIPT_FLAG && storeConfData?.ENABLE_ADOBE_SCRIPT_FLAG === "true"
                      && <script type="text/javascript">{`${storeConfData?.ADOBE_SCRIPT_FUNCTION}`}</script>} 
                </Helmet>
              </StyledGrid>
              <StyledGrid style={{ width: "98%", margin: "0 auto", 
              // minHeight: "900px" 
              }} item xs className="full-width">
                <Suspense fallback={<BackDropLoader showLoader={true} />}>
                  <MemRouteRenderer />
                </Suspense>
              </StyledGrid>
              <FooterMainContainer 
              item xs={false} 
              id={"home--footer-container"} 
              className="footer-main-container" 
              style={{ paddingBottom: isMobileApp ? "108px" : "" }}>
                {/*<Footer />*/}
                {isMobileApp ? (
                  <div style={{ display: "none" }}>
                    <FooterWithData />
                  </div>
                ) : (
                  <FooterWithData />
                )}
              </FooterMainContainer>
              {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
            </StyledGrid>
          </StyledWrapper>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    )
  );
};

export default App;