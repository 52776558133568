import { useRef, useState } from "react";
import { StyledPopperMenu } from "../../../components/custom-components/styled-popper-menu";
import { WishListMenuItems } from "../../../components/pages/wish-list/wish-list-menu-items";
import { StyledBox, StyledLinkTextSecondary } from "../../elements";

export const LinkList = (props) => {
  const { text, placement, dataItems, wishListId, option } = props;

  if (option === "moveToList") {
    return <WishListMenuItems wishListData={dataItems} text={text} placement={placement} />;
  }

  return null;
};
