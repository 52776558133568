/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
//Redux
import * as ACTIONS from "../../action-types/catalog";
import axios from "axios";
import { calculateDiyProPrice } from "../../../hdm/functions/utils";
import { siteSelector } from "../../selectors/site";
import { loginStatusSelector } from "../../selectors/user";
import { getPromoicons, getStoreOnlyAvailable } from "../../../hdm/functions";
import { currentStoreSelector } from "../../selectors/current-store";
import { isNumber } from "lodash-es";
import { onlineStoreRdc } from "../../selectors/hdmData";

/**
 * Saga worker to invoke get product list API
 */


export const getProductsPrice = async ({products, data}) => {
// async function getProductsPrice ({products}) {

    console.log("proPriceIn", products)
    let partNumbers = ""

    products.map(p => {
      partNumbers =  `${partNumbers}&partNumber=${p?.partNumber}`
    })

    // const urlPrice = `/wcs/resources/store/11401/price?q=byPartNumbers${partNumbers}&uom=C62&profileName=IBM_Store_EntitledPrice_RangePrice_All&langId=-5`;
    const urlPrice = `/wcs/resources/store/11401/price?q=byPartNumbers${partNumbers}&langId=-5`;
    const allProPrices =  await axios.get(urlPrice, {
    headers: {
        "Content-Type": "application/json",
    },
    data: {},
    })
    .then((response) => response.data)
    .then((response) => {

      // console.log('rc', rc)
      return response
      
    })
    .catch((error) => {
      console.log("errorLeadtime", "--", error);
    });
    
    const allProPricesIndex = allProPrices?.EntitledPrice && allProPrices.EntitledPrice.reduce((acc, element) => {
      
      let proPriceData: any = element?.UnitPrice[0]
      if(proPriceData){
        proPriceData = proPriceData?.price?.value
      }
      if(proPriceData){        
        acc[element.partNumber] = proPriceData
      }
      return acc      
    }, {})
    
    products.map(p => {
        
      const attributes = getStoreOnlyAvailable(p, data.currentMarketId);
      const promociones = getPromoicons(attributes, data.isB2B, data.currentStoreId, data.currentMarketId);
      const promoNxM = promociones?.filter((prom) => prom.includes("PROMORIBBON_NXM"));

      if(promociones.includes("PROMOICON_GR")){

        calculateDiyProPrice(p, allProPricesIndex[p.partNumber])

      }else if(promociones.includes("PROMOICON_NLP")){
        calculateDiyProPrice(p, allProPricesIndex[p.partNumber])

      }
      // else if(promoNxM && promoNxM.length !== 0){
        
      //   return p
        
      // } 
      else {
        
          p.price.map(item => {
            if (item.usage === 'Offer' && allProPricesIndex[p.partNumber]) {
                item.regularValue = item.value
                item.value = allProPricesIndex[p.partNumber].toString(); // Change value property to "100"
                return item; // Return true if usage is 'Offer'
            }
            return item; // Return false if usage is not 'Offer'
          });

      }

    })

    return products
}

export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    
    if(payload && payload?.parameters && payload.parameters.contractId === ""){
      delete payload.parameters.contractId
    }
    const queryParams = payload?.parameters?.query
    const searchTerm = payload?.parameters?.searchTerm
    // if the sortBy is undefined put the sortBy 2
    const newQueryParams = {
      ...queryParams
    }
    if (!queryParams?.orderBy) {
      newQueryParams["orderBy"] = "0"
    }
    const newPayload = {
      ...payload,
      parameters: {
          ...payload?.parameters,
        query: {
          ...newQueryParams
        }
      },
    }
    const response = yield call(productsService.findProductsUsingGET, newPayload.parameters);
    
    const products = response?.data?.["contents"] ? response.data.contents : [];
    const mySite = yield select(siteSelector);
    const loginStatus = yield select(loginStatusSelector);
    const storeConfData = yield select(onlineStoreRdc);
    if(mySite.isB2B && loginStatus && products?.length > 0){
      
      const currentStore = yield select(currentStoreSelector);
      const currentStoreId = currentStore && currentStore?.currentStore?.stLocId;
      const currentMarketId = currentStore && currentStore?.currentStore?.marketId;

      const allProPrices = yield call(getProductsPrice, {
        products: products,
        data: {
          currentStoreId: currentStoreId,
          currentMarketId: currentMarketId,
          isB2B: mySite.isB2B
        }
      })

      yield put({
        type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
        response: response.data,
        url: response.config.url,
        payload: payload,
      });

    }else {

      yield put({
        type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
        response: response.data,
        url: response.config.url,
        payload: newPayload,
        storeConf: storeConfData
      });
    }

  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  console.log('GETproductXX2', action)
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);

    console.log('GETproductXX2', response)


    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* setBreadcrums(action: any) {
  try {
    const payload = action.payload;
    yield put({
      type: ACTIONS.BREADCRUM_SET_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  console.log('GETproductXX3', action)
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
