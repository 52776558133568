import { createStyles } from "@material-ui/styles";
import { create } from "domain";
import { palette } from "../color-palette";
export const general = {
  MuiContainer: createStyles({
    root: {
      "&.MuiContainer-maxWidthLg": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  }),

  MuiFormControl: createStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "0px",
      },

      "&.MuiOutlinedInput-notchedOutline span": {
        fontSize: "11px",
      },

      marginBottom: "0px",
      marginTop: "10px",
      "&.MuiTextField-root": {
        marginBottom: "0px",
        marginTop: "0px",
        "&.form-myaccount": {
          padding: "15px 5px 0 5px",
        },
        "&.form-myaccount > div": {
          marginTop: "0px"
        },
        "&.form-myaccount > label": {
          margin: "5px 0 0 2px"
        }
      },

      // Hover color
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.gray[100],
      },

      "& .MuiFormLabel-root": {
        color: palette.gray[200],
        fontSize: "15px",
        "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(11px, -5px) scale(1)",
          fontSize: "11px",
          color: palette.gray[200],
        },
        "&.general--styledSelect-label": {
          transform: "translate(11px, 12px) scale(1)",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.gray[200],
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.gray["100"],
        borderBottomWidth: "2px",
        borderBottomColor: palette.error.main,
      },
      "& #datePicker--component-label": {
          backgroundColor: "white",
          zIndex: 100,
          padding: "5px",
          marginLeft: "5px",
      },
      "& #datePicker--component-label + .MuiInputBase-root": {
        border: "1px solid" + palette.gray["100"],
        // padding: "10px",
        height: "44px",
        fontSize: "15px",
        fontWeight: 400,
        color: palette.gray["200"]
      },
      "& #datePicker--component": {
        paddingLeft: "10px"
      }
    },
  }),

  /*MuiInputBase: createStyles({
    root: {
      fontSize: "15px",
      fontWeight: 400,
      color: palette.gray["200"]
    },
  }),*/

  MuiTextField: createStyles({
    root: {
      width: "100%",

      // Hover color
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.primary.main,
        borderWidth: "1px",
      },

      "& .MuiFormLabel-root": {
        color: palette.gray[200],
        fontSize: "15px",
      },

      "& .MuiInputBase-root": {
        borderRadius: "0px",
        marginTop: "10px",
        height: "40px",
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "10px",
        },
      },
      "&.MuiOutlinedInput-notchedOutline legend": {
        border: `1px solid ${palette.gray[100]}`,
        fontSize: "11px !important", //TODO
      },
      "& .MuiInputBase-input.MuiOutlinedInput-input": {
        padding: "10px",
        height: "40px",
        "-webkit-box-sizing": "border-box",
        "-moz-box-sizing": "border-box",
        "box-sizing": "border-box",
      },

      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 24px) scale(1)",
      },

      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        color: `${palette.primary.main} !important`, //TODO
        transform: "translate(14px, 5px) scale(1) !important", //TODO
      },

      "& .PrivateNotchedOutline-legendLabelled-9": {
        fontSize: "11px",
      },

      "& .PrivateNotchedOutline-legendLabelled-10": {
        fontSize: "11px",
      },
    },
  }),

  MuiButton: createStyles({
    root: {
      width: "100%",
      borderRadius: "3px",
      height: "42px",
      padding: "10px",
      marginBottom: "10px",
      fontWeight: "bold",

      "&.MuiButton-outlinedPrimary:hover": {
        color: palette.text.expandedMenu,
        backgroundColor: palette.primary.main,
        border: `1px solid ${palette.primary.main}`,
      },
      "&.MuiButton-outlinedPrimary": {
        border: `2px solid ${palette.primary.main}`,
      },
      "&.MuiButton-outlinedSecondary": {
        border: `2px solid ${palette.secondary.main}`,
      },
      "&.MuiButton-outlinedSecondary:hover": {
        border: `2px solid ${palette.secondary.main}`,
        color: palette.text.expandedMenu,
        backgroundColor: palette.secondary.main,
      },
      "&.Mui-disabled": {
        border: `2px solid ${palette.gray["100"]}`,
      },
    },
  }),

  MuiFormGroup: createStyles({
    root: {
      "& .MuiFormControlLabel-root": {
        padding: "11px",
      },
      "& .MuiButtonBase-root.MuiIconButton-root": {
        padding: "0px",
      },
    },
  }),

  MuiListItem: createStyles({
    root: {
      "&.MuiListItem-gutters": {
        paddingLeft: "10px",
        paddingTop: "5px",
      },
    },
  }),

  MuiSelect: createStyles({
    root: {
      "&.MuiSelect-select": {
        fontSize: "15px",
        color: "#999999",
      },
      "& .MuiFormLabel-root": {
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 12px) scale(1)",
        },
      },
    },
  }),

  MuiPagination: createStyles({
    root: {
      "& .MuiPaginationItem-root": {
        height: "25px",
        minWidth: "25px",
        // backgroundColor: "#ffff"
      },

      "& .MuiPaginationItem-page": {
        // backgroundColor: "#ffff"
      },
    },
  }),
  MuiPaper: createStyles({
    root: {
      "&.MuiPaper-rounded": {
        borderRadius: "0px",
      },
      '&.MuiPaper-rounded:has([aria-labelledby="store-selector-select-label"])': {
        boxShadow: "0px 4px 4px rgba(153, 153, 153, 0.6)",
      },

      "&.MuiPopover-paper .MuiPickersBasePicker-container":{
        border: "1px solid " + palette.gray[200],
        borderRadius: "3px",
        alignItems: "center",
        width: "100%",
        borderTop: "2px solid " + palette.primary.main,
          "&.MuiPickersBasePicker-pickerView": {
            width: "100%",
          },
          "& .MuiTypography-root.MuiTypography-body1":{
            fontSize: "15px",
            fontWeight: "600",
          },
          "& .MuiTypography-root.MuiTypography-body2":{
            fontSize: "15px",
            fontWeight: "600",
          },
          "& .MuiPickersCalendarHeader-dayLabel":{
            fontSize: "15px",
            fontWeight: "600",
          }
      },

      "&.MuiDialog-paper": {
        width: "90%",
        maxWidth: "600px",
        minWidth: "288px",
        minHeight: "100px",
        maxHeight: "90%",

        "&.MuiPaper-rounded": {
          borderRadius: "0px",
        },
      },
    },
  }),

  MuiDialogContent: createStyles({
    root: {
      borderTop: "1px solid" + palette.gray[50],
      padding: "10px",
      paddingBottom: "0px",
    },
  }),

  MuiCheckbox: createStyles({
    root: {
      padding: "0px",
      color: palette.gray[50],
    },
  }),

  MuiAccordionSummary: createStyles({
    root: {
      width: "100%",
      lineHeight: "1.2",
    },
  }),

  MuiAccordion: createStyles({
    root: {
      borderRadius: "0px",
      paddingTop: "10px",
      marginBottom: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
      "&.MuiAccordion-rounded:last-child": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
      "&.MuiAccordion-rounded:first-child": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
    },
  }),

  MuiAccordionDetails: createStyles({
    root: {
      padding: "0px",
      paddingTop: "10px",
    },
  }),

  MuiIconButton: createStyles({
    root: {
      padding: "10px",
      "&:hover": {
        backgroundColor: "unset",
      },
      "&.MuiIconButton-edgeEnd": {},
    },
  }),

  MuiTypography: createStyles({
    root: {
      lineHeight: 1.2,
      "&.MuiTypography-body1": {
        lineHeight: 1.2,
        
      },
    },
  }),

  MuiButtonBase: createStyles({
    root: {
      "&.MuiCheckbox-colorPrimary": {
        color: palette.gray[50],
      },
      "&.MuiCheckbox-colorPrimary.Mui-checked": {
        color: palette.primary.main,
      },
    },
  }),
  MuiTouchRipple: createStyles({
    root: {
      "& .MuiTouchRipple-rippleVisible": {
        animationDuration: "0ms",
        display: "none",
      },
    },
  }),
  MuiStepConnector: createStyles({
    root: {
      "& .MuiStepConnector-lineHorizontal": {
        borderColor: palette.gray["100"],
      },
    },
  }),
  MuiTooltip: createStyles({
    tooltip: {
      backgroundColor: palette.gray["50"],
      color: palette.gray["500"],
      borderRadius: "0px",
      width: "100px",
      marginTop: "-3px",
      marginRight: "65px",
      borderTop: "2px solid " + palette.primary.main,
    },
  }),
  MuiFormHelperText: createStyles({
    root: {
      color: palette.error.main,
      fontSize: "10px",
      marginTop: "5px !important",
      marginBottom: "0px !important",
    },
  }),
  MuiRadio: {
    root: {
      color: palette.gray["50"],
      "&.Mui-checked": {
        color: palette.primary.main,
      },
    },
  },
};
