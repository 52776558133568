import { useState, useEffect } from "react";
import axios from "axios";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import { getCookieValue, setCookieCAPI } from "./cookieHandler";
import { getExternalId, triggerConversionPinterestPageVisit } from "./pinterest/pinterest-conversion-utils";
import { useSelector } from "react-redux";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { triggerConversionTikTokViewContent } from "./tiktok/tiktok-conversion-utils";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

interface Props {
  catentryDtls: string;
}

const ViewContentEvent = (props) => {
  const { catentryDtls } = props;
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const storeConfData = useSelector(onlineStoreRdc);

  const eventIdName = "mfbt";
  const { mySite } = useSite(); 

  const [ruta, setRuta] = useState(window.location.pathname);

  useEffect(() => {
    
    try {
      console.log(`-//Cookie and props values: ${catentryDtls.partNumber} \n ${catentryDtls.name} \n ${catentryDtls.manufacturer} \n 
      ${catentryDtls.price[1].value} \n ${catentryDtls.keyword}  \n marca: ${catentryDtls.manufacturer}`);
      triggerViewContent();
    } catch (e) {
      console.log("ViewContent error: " + e);
    }
  }, []);

  useEffect(() => {
    if(breadcrumbs.length >0){
      //Trigger pinterest pageVisit
      const eventId = getCookieValue("_mfbt");
      const mfbt = getCookieValue("mfbt");
      const epik = getCookieValue("_epik");
      const userAgent = window.navigator.userAgent;
      const eventSource = window.location.href;
      let marketId = "";
      const skuId = catentryDtls.partNumber;
      const price = getPriceDtls(catentryDtls);
      let category = "";
      try{
        marketId = getCookieValue(`WC_MarketId_${mySite.storeID}`);

        if(breadcrumbs.length >= 3){
          const newArr= breadcrumbs.slice(0, 3);
          category= newArr.filter(cat => cat.type_ !== undefined)
                      .map(cat => cat.label)
                      .join(" > ");
        }else{
          category= breadcrumbs.filter(cat => cat.type_ !== undefined)
                      .map(cat => cat.label)
                      .join(" > ");
        }
        console.log(`TEST de las cats: ${category}`);
      } catch(e){
        console.log("Error split cat");
      }
      const brandDtls = catentryDtls.manufacturer;
      const name = catentryDtls.name;
       
      try {
        if(storeConfData?.ENABLE_PINTEREST_CALL  && storeConfData?.ENABLE_PINTEREST_CALL =='true'){
          triggerConversionPinterestPageVisit(mySite.storeID, eventId, epik, userAgent, eventSource,
            skuId, name, marketId, price, brandDtls, category);
        }

        if(storeConfData?.ENABLE_TIKTOK_CALL && storeConfData?.ENABLE_TIKTOK_CALL=='true'){
          triggerConversionTikTokViewContent(mySite.storeID, mfbt, userAgent, category, price, skuId);
        }
      } catch (e) {
        console.log("Page visit error: " + e);
      }
    }
  }, [breadcrumbs]);

  const triggerViewContent = async () => {
    const eventId = setCookieCAPI(eventIdName);
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const epik = getCookieValue("_epik");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const marketId = getCookieValue("WC_MarketId_10351");
    const skuId = catentryDtls.partNumber;
    const price = getPriceDtls(catentryDtls);
    const category = catentryDtls.keyword;
    const brandDtls = catentryDtls.manufacturer;
    const name = catentryDtls.name;
    const externalId = getExternalId();
    const physical = getCookieValue(`WC_DefaultStore_${mySite.storeID}`);

    console.log(`--values on Cookies eventContent:
        eventID: ${eventId}\n
        fbc: ${fbc}\n
        fbp: ${fbp}\n 
        userAgent: ${userAgent}\n
        marketId: ${marketId}`);

    await axios
      .get(
        `/wcs/resources/store/${mySite.storeID}/hdm/facebook/viewcontent?responseFormat=json&mfbt=${eventId}&fbc=${fbc}&fbp=${fbp}&userAgent=${userAgent}&eventSource=${eventSource}&sku=${skuId}&marketId=${marketId}&price=${price}&category=${category}&physicalStoreId=${physical}&externalId=${externalId}`
      )
      .then((res) => {
        if (res && res?.data) {
          console.log("Service working good ");
        }
        console.log("Response value:  " + res);
      })
      .catch((error) => {
        console.log("Error pageview: " + error);
      });


      
  };

  const getPriceDtls = (catentryDtls) =>{
    let price = 0;
    if(mySite?.isB2B && catentryDtls && catentryDtls.price[0].value > 0 && catentryDtls.price[0].value < catentryDtls.price[1].value){
      price = catentryDtls.price[0].value;
    }else{
      if(catentryDtls && catentryDtls.price[1].value > 0 ){
        price = catentryDtls.price[1].value;
      }
    }
    return price;
  }

  return <></>;
};

export default ViewContentEvent;
