import { useTranslation } from "react-i18next";

import { CardMedia } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { StyledBox, StyledTypography } from "../../elements";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";

const ContainerStyledImages = styled(({ ...props }) => <CardMedia {...props} />)`
  ${({ theme }) => `

    &.styled--img-container {
        height: 90px;
        width: 90px;
        background-size: contain;
    }

`}
`;

const ContainerStyledHdmImage = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 90px;
    
    .styled--hdmimage-container {
        width: 100%;
        height: 90px;
        background-color: ${theme.palette.gray["50"]};
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .styled--img-container {
        height: 40px;
        width: 40px;
        background-size: contain;
        margin-bottom: 10px;
    }

`}
`;

export const MerchandisingAssociationImages = (props) => {
  const { selectedProducts, cdnUrl, LogoHDM, numberOfSelectedProducts } = props;
  const { t } = useTranslation();

  const allImages = selectedProducts.filter((product) => product?.isSelected === true);


  if (allImages.length === 0)
    return (
      <ContainerStyledHdmImage>
        <StyledBox className="styled--hdmimage-container">
          <CardMedia className="styled--img-container lazyload" src={LogoHDM} component="img" />
          <StyledTypography variant="bodyCaption">{t("productDetail.selectSomething")}</StyledTypography>
        </StyledBox>
      </ContainerStyledHdmImage>
    );

   
  return (
    <>
    
      {selectedProducts.map((product, index) => {
        if (!product?.isSelected) return;

        


        const urlProduct = product?.seo?.href ? product?.seo?.href : "";

        const urlImg = `${cdnUrl}/${product?.partNumber}/${product?.partNumber}-m.jpg`;
        let addIcon: any = <AddIcon className="color-gray300 margin-1"/>;
        if (index + 1 === selectedProducts?.length) {
          addIcon = null;
        }
        return (
          <>
            <Link to={urlProduct} target="_blank">
              <ContainerStyledImages className={`styled--img-container lazyload ${index === 0 ? "current-product":""}`} src={urlImg} component="img" />
            </Link>
            {(numberOfSelectedProducts > 1 && index !== 1) 
            || (index === 1 && numberOfSelectedProducts > 1 && selectedProducts[2]?.isSelected) ?
              addIcon
            : null}
          </>
        );
      })}
        
    </>
  );
};
