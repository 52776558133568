/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import { palette } from "../../../hdm/themes";

const CustomMenuItem = React.forwardRef((props: any, ref: any) => <MenuItem {...props} ref={ref} />);
/**
 * Styled component on @material-ui
 * @see https://material-ui.com/api/menu-item/
 */
const StyledMenuItem = styled(CustomMenuItem)`
  ${({ theme }) => `
  &.styledSelect--menuItems-color{
    &.MuiMenuItem-root {
      color: ${theme.palette.text.primary};
    }
  }
   &.searchBarItem{
     height:40px;
     padding:10px;
   }

   &.MuiMenuItem-root:hover {
     background-color:${theme.palette.gray[50]};

   }
   &.MuiMenuItem-root.__search-bar-titles:hover {
     background-color:white;
     a, span {
         color:  ${palette.gray[200]};
       }
   }
   &.MuiMenuItem-root {
     color: ${palette.gray[200]};

     &:hover {
       color: ${palette.gray[500]};
       a, span {
         color: ${palette.gray[500]};
       }
     }

     &:visited {
       color: ${theme.palette.text.primary};
     }

     &.bordered:not(:last-child) {
       border-bottom: 1px solid ${theme.palette.grey[300]};
     }

     p{
       padding-left:10px;
     }
   }

   .MuiTouchRipple-root {
     color: ${theme.palette.primary.main};
   }
   `}
`;

export { StyledMenuItem };
