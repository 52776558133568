import { Dimension } from "../dimensions";


export const SLPDP = {
    breadCrumb: {
        mobile: "100%",
        desktop: new Dimension(750, 32),
    },
    productSummary: {
        brand: new Dimension(105, 22),
        name: new Dimension(650, 30),
        productInfo: new Dimension(400, 20),
        rating: new Dimension(140, 30)
    },
    image: {
        main: new Dimension(750, 600),
        carousel: new Dimension(80, 80),
        mobile: {
            main: new Dimension(250, 480),
            swiperPoint: new Dimension(250, 25),
            countImages: new Dimension(50, 35),
            touchZoom: new Dimension(160,32)
        }
    },
    priceCard: {
        slot1: new Dimension(540, 20),
        price: new Dimension(270, 100),
        slot2: new Dimension(540, 20)
    },
    addToCart: new Dimension(210, 52),
    availability: {
        title: new Dimension(130, 25),
        availabilityOnline: new Dimension(280, 40),
        availabilityStore: new Dimension(400, 40),
        searchOtherStore: new Dimension(170, 30),
        mobile: {
            title: "20%",
            availabilityOnline: "50%",
            availabilityStore: "80%",
            searchOtherStore: "35%"
        }
    },
    about: {
        title: new Dimension(130, 25),
        content : new Dimension(130, 100),
        options : new Dimension(130, 30),
        mobile: {
            title: "30%",
            content: "100%",
            options: "50%"
        }
    },
    searchOtherStore: {
        storeName: new Dimension(100, 15),
        addressStore: new Dimension(100, 10),
        selectStore: new Dimension(100, 40)
    }


}