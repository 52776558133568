
import styled from "styled-components";
import { StyledBox } from "../../../hdm";


const StyledBoxHDM = styled((props: any) => <StyledBox {...props} />)`
    ${({ theme }) => `
    .register_benefits_content {
        background-color: ${theme.palette.gray[50]};
        margin-bottom:${theme.spacing(2)}px;
        border-radius:3px;

        li.ListItem-DrawerCart {
        padding-top:0px;
        padding-bottom:0px;
        }

        li>.ListItemIcon-Drawercart {
        color: ${theme.palette.primary.light};
        padding-right: ${theme.spacing(1)}px;
        }

        li>.ListItemText-DrawerCart {
        color: ${theme.palette.gray[300]};
        }
    }
    `}
`;

export const RegisterBenefits= () => {
    return(
    <StyledBoxHDM>
        <div id="Cart_ContinueToCheckout_Register_Content" className="Cart_ContinueToCheckout_Register_Content">
        <div className="register_benefits_content">
            <ul className="MuiList-root MuiList-padding">
            <li className="MuiListItem-root MuiListItem-gutters ListItem-DrawerCart">
                <div className="MuiListItemAvatar-root ListItemIcon-Drawercart">
                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault">
                    <svg className="MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                    <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"></path>
                    </svg>
                </div>
                </div>
                <div className="MuiListItemText-root ListItemText-DrawerCart">
                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Guarda tus direcciones y tarjetas y ahorra tiempo en tus compras en linea
                </span>
                </div>
            </li>
            <li className="MuiListItem-root MuiListItem-gutters ListItem-DrawerCart">
                <div className="MuiListItemAvatar-root ListItemIcon-Drawercart">
                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault">
                    <svg className="MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                    <path d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59 20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3zM2 19h10v-2H2v2z"></path>
                    </svg>
                </div>
                </div>
                <div className="MuiListItemText-root ListItemText-DrawerCart">
                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Consulta tu historial y revisa el estatus de tus pedidos
                </span>
                </div>
            </li>
            <li className="MuiListItem-root MuiListItem-gutters ListItem-DrawerCart">
                <div className="MuiListItemAvatar-root ListItemIcon-Drawercart">
                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault">
                    <svg className="MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                    <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>
                    </svg>
                </div>
                </div>
                <div className="MuiListItemText-root ListItemText-DrawerCart">
                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Crea listas con tus productos favoritos
                </span>
                </div>
            </li>
            <li className="MuiListItem-root MuiListItem-gutters ListItem-DrawerCart">
                <div className="MuiListItemAvatar-root ListItemIcon-Drawercart">
                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault">
                    <svg className="MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                    <path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z"></path>
                    </svg>
                </div>
                </div>
                <div className="MuiListItemText-root ListItemText-DrawerCart">
                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Recibe promociones y boletines especiales
                </span>
                </div>
            </li>
            </ul>
        </div>
        </div>
        {/*<EspotHdm espotName="Cart_ContinueToCheckout_Register" />*/}
        </StyledBoxHDM>
    )
}

export default RegisterBenefits;