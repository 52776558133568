import axios from "axios";
import React, { FC, useState, useEffect, useMemo, lazy } from "react";
import parse from "html-react-parser";
import { useLocation } from "react-router";

import { useSite } from "../../../_foundation/hooks/useSite";
import { paramCase } from "change-case";
import { useTranslation } from "react-i18next";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget";

import { withUseESpot } from "../../../_foundation/hooks/use-espot";
import { withESpotContext } from "../../../_foundation/context/espot-context";
import { useSelector } from "react-redux";
import { seoSelector } from "../../../redux/selectors/seo";
import Widget  from "../../commerce-widgets/e-marketing-spot-widget";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";

interface IEspotHDMProps {
  espotName: string;
  variable1?: string | number;
  variable2?: string | number;
  variable3?: string | number;
  variable4?: string | number;
}

const EspotHdm: FC<IEspotHDMProps> = React.memo(({ espotName, variable1, variable2, variable3, variable4 }) => {
  
  const location = useLocation();
  const { t } = useTranslation();

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(espotName ? espotName : "")}`,
        widgetName: "content-recommendation-widget",
        name: espotName ? espotName : "",
        properties: {
          emsName: espotName ? espotName : "",
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const seoConfig = useSelector(seoSelector);
  const keys = Object.keys(seoConfig);
  let identifier:string|undefined = undefined;
  if(keys.length > 0){
    identifier=keys[0];
  }
  if(identifier && seoConfig){
    crwProps.page = seoConfig[identifier].page;
  }

  const MemoizedWidget = useMemo(()=>{
    return <Widget widget={crwProps.widget} page={crwProps.page}  />
  },[])

  if(location.pathname === "/checkout/print-order") return (<></>)

  const params = {
    "nextTimeLimit": "-1",
    "marketingSpotIdentifier": "716014837",
    "behavior": "1",
    "eSpotName": "PDP-Price_InferiorSection"
}


  
  return MemoizedWidget

});

export default EspotHdm;
