import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledTypography } from "../typography";
import { useSite } from "../../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

const ContainerStyledSpan = styled(({ ...props }) => <StyledTypography component="span" {...props} />)`
  ${({ theme, isPdp = false, isB2B }) => `

  ${isPdp ? `margin-left: ${theme.spacing(1)}px;` : ""}
  
&.badge_plp {
  position: absolute;
  z-index: 2;
  padding: 2px 5px;
  background-color: #3e7697;
  color: #ffffff;
  font-size: 12px;
  top: -5px;
  // left: 15px;
  font-weight: 600;
}

&.badge_plp::after {
  content: "";
  height: 5px;
  background-color: "#bdbdbd";
  position: absolute;
  z-index: 0;
  top: 0;
  right: -4px;
  border-right: 4px solid transparent;
  border-bottom: 5px solid #5c5561;
}

&.badge_plp-hs {
  background-color: #e02631;
}

&.badge_plp-hs::after {
  border-bottom: 5px solid #000000;
}

&.badge_plp-bf {
  background-color: red;
}

&.badge_plp-bf::after {
  border-bottom: 5px solid #000000;
}

&.badge_plp-cd {
  background-color: #000000;
}

&.badge_plp-cd::after {
  border-bottom: 5px solid ${isB2B ? theme.palette.primary.main : "#333333"};
}

`}
`;

interface Props {
  promociones: string[];
  badge: string;
  isPdp?: boolean;
  brand: string;
}

const ProductBadgedPromotion = (props: Props) => {
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const { promociones, badge, isPdp=false, brand } = props;
  const storeConfData = useSelector(onlineStoreRdc);
  const exclusiveBrandsString = isB2B ? storeConfData?.EXCLUSIVE_BRAND_PRO : storeConfData?.EXCLUSIVE_BRAND_DIY;
  let showExclusiveBadge = false;
  if(exclusiveBrandsString && brand){
    const exclusiveBrands = exclusiveBrandsString.split("|");
    const matchingBrand = exclusiveBrands.filter((b) => b && b.toUpperCase() == brand?.toUpperCase());
    showExclusiveBadge =  matchingBrand?.length > 0;
  }

  const { t } = useTranslation();

  if (promociones && promociones.includes("PROMORIBBON_HS")) {
    return <ContainerStyledSpan className="badge_plp badge_plp-hs" isPdp={isPdp}>{t("productBox.HotSale")}</ContainerStyledSpan>;
  }
  if (promociones && promociones.includes("PROMORIBBON_BF")) {
    return <ContainerStyledSpan className="badge_plp badge_plp-bf" isPdp={isPdp}>{t("productBox.BuenFin")}</ContainerStyledSpan>;
  }
  if (promociones && promociones.includes("PROMORIBBON_CD")) {
    return <ContainerStyledSpan isB2B={isB2B} className="badge_plp badge_plp-cd" isPdp={isPdp}>{t("productBox.CyberDays")}</ContainerStyledSpan>;
  }
  if (badge && badge === "bestSeller") {
    return <ContainerStyledSpan className="badge_plp" isPdp={isPdp}>{t("productBox.BestSeller")}</ContainerStyledSpan>;
  }
  if (showExclusiveBadge || (promociones && promociones.includes("TAG_EXCLUSIVE"))) {
    return <ContainerStyledSpan className="badge_plp" isPdp={isPdp}>{t("productBox.Exclusivo")}</ContainerStyledSpan>;
  }

  return null;
};

export default ProductBadgedPromotion;
