import { StyledBox, StyledLink, StyledTypography } from "../../../hdm/elements";
import CheckIcon from "@material-ui/icons/Check";
import { palette } from "../../../hdm/themes";
import RoomIcon from "@material-ui/icons/Room";
import { SvgIcon } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  StyledRadioCardContainer,
  StyledUncheckedRadioIcon,
  StyledSuccessIconRadio,
  StyledRadioCardSuccessContainer,
} from "./container-checkout-profile";
import DireccionInfo from "./DireccionInfo";
import { LocationOnSharpIcon } from "../../../hdm/components/svg-icons/svg-icons";
import { useSite } from "../../../_foundation/hooks/useSite";

interface Props {
  misDirecciones: any;
  selectedAddress: any;
  isEditable: boolean;
  handleSelectedAddress: (direccion: any) => void;
  handleOpenDireccion: (option: string) => void;
  fromCheckoutProfiles?: boolean;
  logonId: string | undefined;
}

const Direcciones = (props: Props) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const {
    misDirecciones,
    selectedAddress,
    handleSelectedAddress,
    handleOpenDireccion,
    isEditable,
    fromCheckoutProfiles = false,
    logonId,
  } = props;
  if (!selectedAddress && !misDirecciones) return null;

  return (
    <StyledBox px={fromCheckoutProfiles ? 0 : 2}>
      {misDirecciones &&
        misDirecciones.map((direccion) => {
          if (selectedAddress?.nickName === direccion.nickName) {
            let canEditSelected = true;
            if(mySite.isB2B && direccion.nickName === logonId) {
              canEditSelected = false;
            }

            return (
              <>
                <StyledBox key={direccion.addressId} mt={2}>
                  <StyledRadioCardSuccessContainer
                    className={"container storeChecked"}
                    onClick={() => handleSelectedAddress(direccion)}>
                    <StyledRadioCardSuccessContainer item xs={8}>
                      <StyledTypography variant="bodySubText" style={{ paddingLeft: 10 }}>
                        {direccion.nickName === logonId ? t("Account.Labels.MainAddress") : direccion.nickName}
                      </StyledTypography>
                    </StyledRadioCardSuccessContainer>
                    <StyledRadioCardSuccessContainer item xs={4} className={"successIconRadio"}>
                      <StyledSuccessIconRadio>
                        <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                      </StyledSuccessIconRadio>
                    </StyledRadioCardSuccessContainer>
                  </StyledRadioCardSuccessContainer>
                </StyledBox>

                <StyledBox mt={2} display="flex">
                  <StyledBox>
                    <LocationOnSharpIcon width="15" height="15" fill={palette.gray[300]} />
                  </StyledBox>

                  <StyledBox width="100%" display="flex" alignItems="center">
                    <StyledBox flexGrow={1} pl={1}>
                      <DireccionInfo direccion={selectedAddress} direccionType="1" />
                    </StyledBox>

                    {isEditable && canEditSelected ? (
                      <StyledBox>
                        <StyledLink onClick={() => handleOpenDireccion("editar")}>
                          {t("AddressForm.Labels.Edit")}
                        </StyledLink>
                      </StyledBox>
                    ) : null}
                  </StyledBox>
                </StyledBox>
              </>
            );
          }
          if (direccion.addressType !== "Billing") {
            return (
              <StyledBox mt={2} key={direccion.addressId}>
                <StyledRadioCardContainer className={"container"} onClick={() => handleSelectedAddress(direccion)}>
                  <StyledUncheckedRadioIcon fontSize={"small"} />
                  <StyledTypography variant="bodySubText" color="secondary">
                    {direccion.nickName === logonId ? t("Account.Labels.MainAddress") : direccion.nickName}
                  </StyledTypography>
                </StyledRadioCardContainer>
              </StyledBox>
            );
          }
          return null;
        })}
    </StyledBox>
  );
};

export default Direcciones;
