/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, {
  useState,
  useEffect,
  useRef,
  // useMemo,
  useCallback,
  useMemo,
} from "react";

import { Navigate, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
// import {paramCase} from "change-case";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import { LOCALE } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";

//Custom libraries
// import {headerConfig} from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
// import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import MiniCart from "./MiniCart";
// import MegaMenu from "./MegaMenu";
import { SearchBar } from "../widgets/search-bar";
import AccountPopperContent from "./AccountPopperContent";

//Redux
import { userNameSelector, loginStatusSelector } from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
import { currentStoreSelector } from "../../redux/selectors/current-store";
//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener, Link } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import StoreIcon from "@material-ui/icons/Store";
import { Hidden } from "@material-ui/core";
import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledHeaderActions,
  StyledTypography,
  // StyledSwipeableDrawer,
  StyledGrid,
  StyledLink,
  StyledPaper,
  StyledBox,
} from "../../hdm";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { selectedSellersSelector, sellersSelector } from "../../redux/selectors/sellers";
import { INVENTORY, SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { Sellers } from "./sellers";
import { StyledList } from "@hcl-commerce-store-sdk/react-component";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styled from "styled-components";
import { palette } from "../../hdm/themes";
import HeaderAddress from "./HeaderAddress";
import StyledSwipeableDrawerHDM from "../custom-components/styled-drawer-hdm/StyledDrawerHDM";
import StyledListHDM from "../custom-components/styled-drawer-hdm/styled-list-hdm/StyledListHDM";
import CategoriesContainer from "./categories-menu/MenuMobile";
import StyledStoreSelector from "../custom-components/styled-store-selector/StyledStoreSelector";
import MenuDesktop from "./categories-menu/MenuDesktop";
import EspotHdm from "../custom-components/shared/EspotHdm";
import SignInPopUp from "../widgets/sign-in/SignInPopUp";
import { Dispatch } from "redux";
import {
  OPEN_SIGN_IN_POPUP_ACTION,
  CLOSE_SIGN_IN_POPUP_ACTION,
  CLOSE_CART_DRAWER_ACTION,
  CLOSE_REGISTER_POPUP_ACTION,
} from "../../redux/actions/drawer";
import RegistrationPopUp from "../widgets/registration/RegistrationPopUp";
import { withUseJsonESpots } from "../../hdm/widgets/json-espot/json-espots";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import Sticky from "react-sticky-el";
import { MapSiteNavigate } from "../pages/map-site/MapSiteNavigate";
import { InView } from "react-intersection-observer";
import EventId from "../custom-components/conversion-api/EventId";
import { useEmarsysTags } from "../../_foundation/hooks/use-emarsys-tags";
import { numItemsSelector } from "../../redux/selectors/order";
import useScript from "../custom-components/styled-carousel-hdm/useScript";
import { useCategoryBreadcrumbs } from "../../_foundation/hooks/use-category-breadcrums";
import { FETCH_HDM_DATA_REQUESTED } from "../../redux/action-types/hdmData";
import * as orderActions from "../../redux/actions/order";

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton testId={testId} className="header-actionsButton" variant="text" color="secondary" {...props}>
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};

export const ButtonifyHDM = styled((props: any) => <Buttonify {...props} />)`
  ${({ theme }) => `
      line-height: 1.2;
  `}
`;

const MarketplacePopper = ({ sellerConfig }) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guestSellerPicker = "guestSellerPicker";
  const { t } = useTranslation();
  const { mySite } = useSite();

  const onClickAway = useCallback((e) => {
    if (e.target.localName === "body") {
      return;
    }
    setOpen(false);
  }, []);

  return !mySite?.isB2B && sellerConfig.showSellerList ? (
    <>
      <div ref={btnRef}>
        <Buttonify testId="header-mp" onClick={setOpen.bind(null, !open)}>
          <StyledBox display="flex" flexDirection="column" alignItems="center" flexWrap="wrap">
            <StoreIcon />
            <StyledTypography variant="body1" component="p">
              {t("sellers.mp")}
            </StyledTypography>
          </StyledBox>
        </Buttonify>
      </div>
      <StyledAccountPopper
        id={guestSellerPicker}
        open={open}
        anchorEl={btnRef.current}
        placement="bottom-end"
        modifiers={{
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
          hide: { enabled: false },
        }}
        isB2B={mySite?.isB2B}
        className="account-popper">
        <ClickAwayListener onClickAway={onClickAway}>
          <StyledPaper className="vertical-padding-1 horizontal-padding-1">
            <StyledList disablePadding>
              <Sellers />
            </StyledList>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </>
  ) : null;
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */

export const StyledGridCart = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme, isB2B }) => `
      
      width: 74px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${theme.spacing(2)}px!important;
  `}
`;

export const AccountCircleIconHDM = styled((props: any) => <AccountCircleIcon {...props} />)`
  ${({ theme }) => `
      &.MuiSvgIcon-root{
        fill: ${palette.primary.main};
        width: 25px;
        height: 25px;
      }
      ${theme.breakpoints.down("sm")}{
        margin-right: ${theme.spacing(1)}px;
        /*&.MuiSvgIcon-root{
          width: 21px!important;
          height: 21px!important;
        }*/
      }

  `}
`;

export const MenuIconHDM = styled((props: any) => <MenuIcon {...props} />)`
  ${({ theme, isB2B }) => `
      color: ${palette.primary.main};
      width: 40px!important;
      height: 40px!important;
      margin-left: ${theme.spacing(isB2B ? 4 : 2)}px;
      margin-right: ${theme.spacing(2)}px;
      margin-top: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
  `}
`;

export const StyledContainerHDM = styled((props: any) => <StyledContainer {...props} />)`
  ${({ theme }) => `
      padding: 0;
      ${theme.breakpoints.up(1024)}{
        padding: 0 ${theme.spacing(2)}px;
        height: 80px;
      }
  `}
`;

export const StyledGridHeaderHDM = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      margin-top: ${theme.spacing(1)}px;
      width: 100%;
      // padding: 0 ${theme.spacing(2)}px;
      ${theme.breakpoints.up(1024)}{
        margin-top: 0;
        width: fit-content;
        & .MuiGrid-root{
          justify-content: start;
        }
      }
  `}
`;

export const StyledGridSearchHDM = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme, isB2B }) => `
      border-top: 1px solid ${palette.gray["50"]};
      margin-top: 4px;
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: space-between;  
      .header-topbarSection{
        height: 58px;
        width: 100%;
      }
      
      .stickyPos{
        position: fixed;
        background: ${isB2B ? theme.palette.gray["500"] : "white"};
        box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
      }
      
      .top{
        top: 0px!important;
      }

      ${theme.breakpoints.up(1024)}{
        margin-left: ${theme.spacing(4)}px;
        margin-right: ${theme.spacing(4)}px;
        border-top: none;
        width: fit-content;
        .header-topbarSection{
            width: 100%;
        }
        .stickyPos{
            position: relative;
          }
      }
  `}
`;

export const StyledGridContainerHDM = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      height: 58px;
      width: 100%;
      // justify-content: space-between;
      .header--styledGrid-actions{
        ${theme.breakpoints.up(1024)}{
          margin-right: ${theme.spacing(4)}px;
        }
      }
      
      .styled--myaccount-color {
        color: ${theme.palette.background.paper};
      }


  `}
`;

export const StyledGridAbsoluteHDM = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      display: flex;
      align-items: center;
      min-width: 64px;
      ${theme.breakpoints.up(1024)}{
        display:none;
      }

      .styled--accountpopper-container {
        border: 2px solid red;
      }
  `}
`;

export const StyledLinkHDM = styled((props: any) => <StyledLink {...props} />)`
  ${({ theme }) => `
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
  `}
`;

export const StyledGridSearch = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      width: 63%;
      max-width: 500px;
      min-width: 190px;
      ${theme.breakpoints.up(1024)}{
        width: 100%;
        max-width: none;
        min-width: none;
      }
      
      &.prosearch-notlogged-width {
        width: 100%;
        max-width: 100%;
        margin-left: 10px;
        margin-right: 20px;
      }
  `}
`;

export const StyledHeaderHDM = styled((props: any) => <StyledHeader {...props} />)`
  ${({ theme, ..._props }) => `
      box-shadow: #EEE 0 1px 1px;
      padding: 0px;
      ${_props.viewHeaderCart ? "display:none;" : ""}

      .styled--login-container {
        display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         width: 74px;
         height: 40px;
         padding: 0px;
         cursor: pointer;
         background: transparent;
       }

       .header-hdmlogo-pro {
        width: 20px;
        height: 20px;
      }
  `}
`;

export const StyledGridHDM = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `

      /*&.MuiGrid-container {
        webkit-flex-wrap: nowrap;
      }
      &.header-serchb2b-container {
        display: -webkit-flex;
        
        &.MuiGrid-container-root {
        }
      }*/

      & .MuiGrid-root{
        flex-wrap: nowrap;
      }
      


  ${theme.breakpoints.up(1024)}{
          width: 100%;
      }
  `}
`;

const StickyContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(4)}px;

    .styled--mapnavigate-card {
        height: 80px;
        margin-bottom: 5px;
        ${theme.breakpoints.down("sm")} {
        }

        .styled--navigate-fixSize-content {
          width: 100%;
          max-width: 1440px;
          margin: auto;
        }
    }
    
    .styled--navigate-border {
        border-bottom: solid 4px ${palette.gray["50"]};
        min-height: 90px;
    }
    
    .styled--link-departamento {
        font-weight: 600;
    }

    .title-ordenes-recientes {
        color: ${palette.gray["500"]};
        border-bottom: 2px solid ${palette.primary.main};
        padding-bottom: 7px;
    }

    .styled--text-elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 30ch;
    }
    `}
`;

const Header: React.FC = (props: any) => {
  const currentStore = useSelector(currentStoreSelector);
  const widgetName = getDisplayName(Header);
  const { w } = useWinDimsInEM();
  const navigate = useNavigate();
  const location = useLocation();
  useEmarsysTags(location);
  useCategoryBreadcrumbs();
  const expiredLinkPath = "/expired-link";
  
  useEffect(() => {
    const query = {
      url: "",
      data: location?.pathname ? location?.pathname : "",
      option: "FOOTER_ROUTE",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    
    
  }, [location?.pathname])

  
  

  // const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] = useState<boolean>(false);
  const [signInOpenPopUp, setSignInOpenPopUp] = useState<boolean>(false);

  const handleLoginPopUp = () => {
    setSignInOpenPopUp(false);
  };

  const myAccountElRef = useRef<HTMLButtonElement>(null);
  const myAccountElRefMobile = useRef<HTMLButtonElement>(null);
  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);

  const [, setLanguageTogglePopperOpen] = useState<boolean>(false);
  // const languageToggleElRef = useRef<HTMLButtonElement>(null);
  // const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  // const handleLanguageToggleClick = () => {
  //   setLanguageTogglePopperOpen(true);
  //   setMiniCartPopperOpen(false);
  //   setMyAccountPopperOpen(false);
  // };
  // const handleLanguageTogglePopperClose = () => {
  //   setLanguageTogglePopperOpen(false);
  // };
  // const toggleOpen = () => setOpen(!open);
  // const toggleSearchBar = () => setShowSearchBar(!showSearchBar);
  // const [showCart, setShowCart] = useState(false);

  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const { t } = useTranslation();
  const menuDrawerTitle = t("menu");
  const theme = useTheme();
  const dispatch = useDispatch<Dispatch<any>>();

  const addressDetails = useSelector(addressDetailsSelector);
  const userName = useSelector(userNameSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);

  const userPreviousLoggedIn = useRef();

  const locale = localStorageUtil.get(LOCALE);

  const isB2B = Boolean(mySite?.isB2B);
  const isShoppingEnabled = !isB2B || (isB2B && userLoggedIn);
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down(1023));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const checkInventory: boolean = mySite ? mySite.inventorySystem === INVENTORY.NON_ATP : false;

  const payloadBaseCart: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    checkInventory: checkInventory,
    widget: "CartWidget",
  };

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite && contractId && defaultCurrencyID && currentStore?.currentStore?.stLocId && window.location.pathname.includes("/cart")) {
      const payload = {
        ...payloadBaseCart,
        fetchCatentries: true,
        physicalStoreId: currentStore?.currentStore?.stLocId,
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
    }
  }, [currentStore?.currentStore?.stLocId]);

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    setLanguageTogglePopperOpen(false);
  };
  const handleMyAccountPopperClose = () => setMyAccountPopperOpen(false);

  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(
    () =>
      dispatch(
        CLOSE_CART_DRAWER_ACTION(() => {
          setMiniCartPopperOpen(false);
        })
      ),
    []
  );

  let navigateLocation;
  switch (location.pathname) {
    case ROUTES.ORDER_CONFIRMATION:
      navigateLocation = ROUTES.HOME;
      break;
    case ROUTES.ACCOUNT:
      navigateLocation = ROUTES.HOME;
      break;
    case ROUTES.COMPARE_ROUTE:
      navigateLocation = -1;
      break;
    case ROUTES.SHOP_THE_LOOK:
      navigateLocation = 0;
      break;
    case isB2B && ROUTES.CART:
      navigateLocation = ROUTES.HOME;
      break;
    default:
      navigateLocation = location.pathname;
  }

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payloadBase,
      })
    );

    navigate(navigateLocation);
  };

  const handleContractChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    navigate(navigateLocation);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payloadBase,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
    dispatch(CLOSE_SIGN_IN_POPUP_ACTION({}));
    dispatch(CLOSE_REGISTER_POPUP_ACTION({}));
    
    // return
    // navigate(
    //   {
    //     pathname: navigateLocation,
    //     search: location.search,
    //   },
    //   { replace: true }
    // );
  };

  useEffect(() => {
    userLoggedIn && dispatch(CLOSE_SIGN_IN_POPUP_ACTION({}));
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);

      const url = window.location.pathname;
      const newURL = url.replace(navigateLocation, "") + navigateLocation;

      // if (url === "/" || navigateLocation === "/" || navigateLocation === 0 || navigateLocation === -1) {
      //   window.location.reload();
      //   console.log("window.location.href", navigateLocation)
      // } else {
      //   window.location.href = url.replace(navigateLocation, "") + navigateLocation;
      //   console.log("window.location.href", window.location.href)
      // }

      //navigate(navigateLocation);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payloadBase,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setTopCategories(res.data.contents);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(res.data.contents));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, locale, selectedSellers]);

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  // const crwProps = useMemo(
  //     () => ({
  //         widget: {
  //             id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
  //             widgetName: "content-recommendation-widget",
  //             name: headerConfig.espot.eSpotName,
  //             properties: {
  //                 emsName: headerConfig.espot.eSpotName,
  //             },
  //         },
  //         page: {name: ""},
  //     }),
  //     //Content is language sensitive, so listen to translation change to render.
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     [t]
  // );

  const turnOffSearchBar = () => setShowSearchBar(false);
  const turnOnSearchBar = () => setShowSearchBar(true);
  // const turnOffOpen = () => setOpen(false);
  // const turnOnOpen = () => setOpen(true);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openStoreSelector, setOpenStoreSelector] = useState(false);
  const [currentEspot, setCurrentEspot] = useState<string>("home");

  const [scrollPosition, setScrollPosition] = useState(false);

  const [headerSpots, setHeaderSpots] = useState<any>(null);
  const [isSticky, seIsSticky] = useState<boolean>(false);

  const scrollCal = () => {
    window.scrollY >= 86 ? setScrollPosition(true) : setScrollPosition(false);
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener("scroll", scrollCal);
    };
    watchScroll();
    return () => {
      window.removeEventListener("scroll", scrollCal);
    };
  });

  useEffect(() => {
    // Load categories spot MobileMenu_Departments_SuperiorSection

    setHeaderSpots(props.data);
  }, [props.data]);

  function getJSON(data) {
    if (
      data?.baseMarketingSpotActivityData &&
      data?.baseMarketingSpotActivityData.length > 0 &&
      data?.baseMarketingSpotActivityData[0].marketingContentDescription &&
      data?.baseMarketingSpotActivityData[0].marketingContentDescription.length > 0 &&
      data?.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText
    ) {
      try {
        return {
          espot: data.eSpotName,
          content: JSON.parse(data.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText),
        };
      } catch {
        return {
          espot: data.eSpotName,
          content: data.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText,
        };
      }
    }

    return "";
  }

  const initESpot = async (pageName: string) => {
    const parameters: any = {
      storeId: storeID,
      name: pageName,
      catalogId: catalogID,
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    let eSpotRoot;
    try {
      const res = await eSpotService.findByName(parameters);
      eSpotRoot = res.data.MarketingSpotData[0];
    } catch (e) {
      eSpotRoot = null;
    }
    return eSpotRoot;
  };
  // const [scrollDirection, setScrollDirection] = useState('');

  let viewHeaderCart = false;
  if (
    location.pathname === ROUTES.CHECKOUT ||
    location.pathname === ROUTES.PRINT_ORDER ||
    location.pathname.includes(ROUTES.PRO_LEAD_FORM) ||
    location.pathname === expiredLinkPath ||
    location.pathname === ROUTES.PRO_AR_FEEDBACK
  ) {
    viewHeaderCart = true;
  }

  const openMenu = () => {
    setOpenDrawer(true);
  };

  const handleGoDepartments = () => {
    const anchor: any = document.getElementById("departments-card")?.offsetTop;
    // anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    window.scrollTo({ top: anchor - 110, behavior: "smooth" });
  };

  const handleGoSections = () => {
    const anchor: any = document.getElementById("other-section-card")?.offsetTop;
    // anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    window.scrollTo({ top: anchor - 110, behavior: "smooth" });
  };

  const handleChangeInView = (data) => {
    if (!data.entry.isIntersecting) {
      seIsSticky(true);
    } else {
      seIsSticky(false);
    }
  };

  //useScript("../../../_foundation/utils/lazyload.min.js", "");

  const storeSelectorHeader = <HeaderAddress openDrawer={() => setOpenStoreSelector(!openStoreSelector)} />;
  const MemoizedSuperiorSection = useMemo(()=>{
    return <EspotHdm espotName={isB2B ? "ProMainHeader_SuperiorSection" : "MainHeader_SuperiorSection"} />
  },[])
  const MemoizedInferiorSection = useMemo(()=>{
    return <EspotHdm espotName={isB2B ? "ProMainHeader_InferiorSection" : "MainHeader_InferiorSection"} />
  },[])

  

  return (
    <>
      {location.pathname !== ROUTES.PRO_LEAD_FORM && location.pathname !== expiredLinkPath && location.pathname !== ROUTES.PRO_AR_FEEDBACK ? (
        <>
          <SignInPopUp />
          <SessionErrorDialog />
          <EventId />
          <StyledHeaderHDM viewHeaderCart={viewHeaderCart} className={`${isB2B ? "proheader-background-color" : ""}`}>
            <StyledBox className="superior-espot-container">
                {MemoizedSuperiorSection}
            </StyledBox>
            <StyledContainerHDM id="Main_Header">
              <StyledGridHDM container alignItems="center" className={isB2B ? "header-serchb2b-container" : ""}>
                <StyledGridHeaderHDM item>
                  <StyledStoreSelector isOpen={openStoreSelector} setOpenDrawer={setOpenStoreSelector} />
                  <StyledGridContainerHDM
                    container
                    className="header-topbarSection"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}>
                    {/*<StyledGrid item>*/}
                    {/*  <Hidden mdUp>*/}
                    {/*    <button className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>*/}
                    {/*      <MenuIcon />*/}
                    {/*    </button>*/}
                    {/*  </Hidden>*/}
                    {/*</StyledGrid>*/}
                    <StyledGrid
                      id={"headerStart"}
                      style={{ display: "flex", justifyContent: "space-between" }}
                      item
                      xs={12}>
                      {storeSelectorHeader}
                      <StyledGridAbsoluteHDM style={{ justifyContent: "center" }}>
                        {isMobile && !userLoggedIn && (
                          <>
                            {isB2B ? (
                              <StyledGrid
                                item
                                className="styled--login-container"
                                onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                                <img
                                  src="https://cdn.homedepot.com.mx/PRO/icon/account-pro.svg"
                                  alt="home depot logo"
                                  className="header-hdmlogo-pro lazyload"
                                />
                              </StyledGrid>
                            ) : (
                              <Buttonify
                                className={"buttonify"}
                                testId="header-sign-in"
                                onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                                <>
                                  <AccountCircleIconHDM />
                                </>
                              </Buttonify>
                            )}
                            <RegistrationPopUp />
                          </>
                        )}
                        {userLoggedIn && isMobile && (
                          <>
                            {isB2B ? (
                              <StyledButton
                                ref={myAccountElRefMobile}
                                style={{ padding: 0 }}
                                className="styled--login-container"
                                onClick={handleMyAccountClick}>
                                <StyledBox className="styled--login-container">
                                  <img
                                    src="https://cdn.homedepot.com.mx/PRO/icon/account-pro.svg"
                                    alt="home depot logo"
                                    className="header-hdmlogo-pro lazyload"
                                  />
                                </StyledBox>
                              </StyledButton>
                            ) : (
                              <StyledButton
                                ref={myAccountElRefMobile}
                                variant="text"
                                color="secondary"
                                className="header-actionsButton"
                                onClick={handleMyAccountClick}
                                testId="heaher-action-account">
                                <StyledHeaderActions>
                                  <AccountCircleIconHDM />
                                </StyledHeaderActions>
                              </StyledButton>
                            )}
                            <StyledAccountPopper
                              id={myAccountPopperId + "Mobile"}
                              open={myAccountPopperOpen}
                              anchorEl={myAccountElRefMobile.current}
                              onClose={handleMyAccountPopperClose}
                              placement={"bottom-end"}
                              modifiers={{
                                flip: {
                                  enabled: false,
                                },
                                preventOverflow: {
                                  enabled: false,
                                  boundariesElement: "scrollParent",
                                },
                                hide: {
                                  enabled: false,
                                },
                              }}
                              isB2B={isB2B}
                              className="account-popper">
                              <ClickAwayListener onClickAway={handleMyAccountPopperClose}>
                                <StyledPaper className="horizontal-padding-2">
                                  <StyledTypography variant="body1" component="div">
                                    <AccountPopperContent
                                      handleClose={handleMyAccountPopperClose}
                                      handleOrgChange={handleOrgChange}
                                      handleContractChange={handleContractChange}
                                      handleLogout={handleLogout}
                                      isB2B={isB2B}
                                      userName={{ firstName, lastName }}
                                    />
                                  </StyledTypography>
                                </StyledPaper>
                              </ClickAwayListener>
                            </StyledAccountPopper>
                          </>
                        )}
                      </StyledGridAbsoluteHDM>
                    </StyledGrid>

                    {mySite != null && (
                      <></>
                      //  <StyledGrid item>
                      //    <div className="header-branding">
                      //      <ContentRecommendationWidget {...crwProps} />
                      //    </div>
                      //  </StyledGrid>
                    )}
                  </StyledGridContainerHDM>
                </StyledGridHeaderHDM>
                {isMobile ? null : (
                  <StyledGrid item style={{ flexGrow: 1 }} data-testid="search-bar-desktop-largetablet-element">
                    <SearchBar
                      showSearchBar={showSearchBar}
                      closeSearchBar={turnOffSearchBar}
                      openSearchBar={turnOnSearchBar}
                    />
                  </StyledGrid>
                )}
                {/* <Hidden smDown>
                </Hidden> */}

                <StyledSwipeableDrawerHDM
                  isOpen={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                  variant={"menu"}
                  title={menuDrawerTitle}
                  currentStore={currentStore}
                  hideCategories={() => setCurrentEspot("home")}>
                  {headerSpots && (
                    <>
                      <StyledListHDM
                        changeEspot={setCurrentEspot}
                        menuComponents={headerSpots}
                        display={currentEspot === "home"}
                      />
                      <CategoriesContainer
                        id="mainMenu_ss"
                        display={currentEspot !== "home"}
                        hideDrawer={() => setOpenDrawer(false)}
                        categories={topCategories}
                        hideCategories={() => setCurrentEspot("home")}
                        menuComponents={headerSpots.filter(
                          (item) => item && item.espot === "MobileMenu_Departments_SuperiorSection"
                        )}
                      />
                    </>
                  )}
                </StyledSwipeableDrawerHDM>

                <StyledGridSearchHDM id={"headerActions"} item className={"header--styledGrid-actions"} isB2B={isB2B}>
                  <StyledGridContainerHDM
                    id={"header-buttons"}
                    className={`header-topbarSection-search ${scrollPosition ? "stickyPos" : ""}`}
                    style={{ top: location?.pathname !== "/" ? "0px" : "0px" }}
                    container
                    alignItems="center"
                    direction="row">
                    <StyledGrid item>
                      {/* <Hidden mdUp> */}
                      {/*<button*/}
                      {/*    className="menu-hamburger"*/}
                      {/*    // data-testid="menu-hamburger-element"*/}
                      {/*    >*/}
                      {/*</button>*/}
                      {/* </Hidden> */}
                      {isMobile ? (
                        <MenuIconHDM onClick={openMenu} style={{ cursor: "pointer" }} isB2B={isB2B} />
                      ) : (
                        <></>
                      )}
                    </StyledGrid>
                    {/* <Hidden mdUp> */}
                    {/* </Hidden> */}
                    {isMobile ? (
                      <StyledGridSearch
                        item
                        data-testid="search-bar-mobile-element"
                        // className={isB2B && isMobile ? "prosearch-notlogged-width" : ""}
                        className={"prosearch-notlogged-width"}
                        style={{}}>
                        <SearchBar
                          showSearchBar={showSearchBar}
                          closeSearchBar={turnOffSearchBar}
                          openSearchBar={turnOnSearchBar}
                        />
                      </StyledGridSearch>
                    ) : (
                      // <><a href="#footer">to Footer</a></>
                      <></>
                    )}
                    {/*{isShoppingEnabled && (*/}
                    {/*    <StyledGrid item>*/}
                    {/*      <MiniCart*/}
                    {/*          id={miniCartPopperId}*/}
                    {/*          open={miniCartPopperOpen}*/}
                    {/*          handleClick={handleMiniCartClick}*/}
                    {/*          handleClose={handleMiniCartPopperClose}*/}
                    {/*          ref={miniCartElRef}*/}
                    {/*      />*/}
                    {/*    </StyledGrid>*/}
                    {/*)}*/}
                    {sellerConfig?.showSellerList ? <MarketplacePopper {...{ sellerConfig }} /> : null}
                    {userLoggedIn && !isMobile ? (
                      <StyledGrid item className={"header--styledGrid-actions"}>
                        {isB2B ? (
                          <StyledButton
                            ref={myAccountElRef}
                            style={{ padding: 0 }}
                            className="styled--login-container"
                            onClick={handleMyAccountClick}>
                            <StyledBox className="styled--login-container">
                              <img
                                src="https://cdn.homedepot.com.mx/PRO/icon/account-pro.svg"
                                alt="home depot logo"
                                className="header-hdmlogo-pro lazyload"
                              />
                              <StyledTypography variant="body1" component="p" className="styled--myaccount-color">
                                {isMobile ? t("Header.Actions.Account") : t("Header.Actions.YourAccount")}
                              </StyledTypography>
                            </StyledBox>
                          </StyledButton>
                        ) : (
                          <StyledButton
                            ref={myAccountElRef}
                            variant="text"
                            color="secondary"
                            className="header-actionsButton"
                            onClick={handleMyAccountClick}
                            testId="heaher-action-account">
                            <StyledHeaderActions>
                              <AccountCircleIconHDM />
                              <StyledTypography
                                variant="body1"
                                component="p"
                                className={`${isB2B ? "styled--myaccount-color" : ""}`}>
                                {isMobile ? t("Header.Actions.Account") : t("Header.Actions.YourAccount")}
                              </StyledTypography>
                            </StyledHeaderActions>
                          </StyledButton>
                        )}

                        <StyledAccountPopper
                          id={myAccountPopperId}
                          open={myAccountPopperOpen}
                          anchorEl={myAccountElRef.current}
                          onClose={handleMyAccountPopperClose}
                          placement={w <= 40 ? "bottom" : "bottom-end"}
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: false,
                              boundariesElement: "scrollParent",
                            },
                            hide: {
                              enabled: false,
                            },
                          }}
                          isB2B={isB2B}
                          className="account-popper">
                          <ClickAwayListener onClickAway={handleMyAccountPopperClose}>
                            <StyledPaper className="horizontal-padding-2">
                              <StyledTypography variant="body1" component="div">
                                <AccountPopperContent
                                  handleClose={handleMyAccountPopperClose}
                                  handleOrgChange={handleOrgChange}
                                  handleContractChange={handleContractChange}
                                  handleLogout={handleLogout}
                                  isB2B={isB2B}
                                  userName={{ firstName, lastName }}
                                />
                              </StyledTypography>
                            </StyledPaper>
                          </ClickAwayListener>
                        </StyledAccountPopper>
                      </StyledGrid>
                    ) : (
                      !isMobile && (
                        <>
                          {isB2B ? (
                            <StyledGrid
                              item
                              className="styled--login-container"
                              onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                              <img
                                src="https://cdn.homedepot.com.mx/PRO/icon/account-pro.svg"
                                alt="home depot logo"
                                className="header-hdmlogo-pro lazyload"
                              />
                              <StyledTypography variant="body1" component="p" className="styled--myaccount-color">
                                Iniciar sesión
                              </StyledTypography>
                            </StyledGrid>
                          ) : (
                            <StyledGrid
                              item
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                              <Link
                              // to={ROUTES.SIGNIN}
                              // onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}
                              >
                                <ButtonifyHDM
                                  style={{ background: "transparent" }}
                                  testId="header-sign-in"
                                  className="bottom-margin-1">
                                  <>
                                    <AccountCircleIconHDM />
                                    <StyledTypography
                                      variant="body1"
                                      component="p"
                                      className={`${isB2B ? "styled--myaccount-color" : ""}`}>
                                      Iniciar sesión
                                    </StyledTypography>
                                  </>
                                </ButtonifyHDM>
                              </Link>
                            </StyledGrid>
                          )}
                          <RegistrationPopUp />
                        </>
                      )
                    )}
                    {/* {isShoppingEnabled && ( */}
                    <StyledGridCart item isB2B={isB2B}>
                      <MiniCart
                        id={miniCartPopperId}
                        open={miniCartPopperOpen}
                        handleClick={handleMiniCartClick}
                        handleClose={handleMiniCartPopperClose}
                        ref={miniCartElRef}
                      />
                    </StyledGridCart>
                    {/* )} */}
                    {/*<StyledGrid item>*/}
                    {/*  <LanguageToggle*/}
                    {/*      id={languageTogglePopperId}*/}
                    {/*      open={languageTogglePopperOpen}*/}
                    {/*      handleClick={handleLanguageToggleClick}*/}
                    {/*      handleClose={handleLanguageTogglePopperClose}*/}
                    {/*      ref={languageToggleElRef}*/}
                    {/*  />*/}
                    {/*</StyledGrid>*/}
                  </StyledGridContainerHDM>
                </StyledGridSearchHDM>
              </StyledGridHDM>
            </StyledContainerHDM>

            {showSearchBar && (
              <Hidden xsUp>
                <StyledContainer className="bottom-padding-1">
                  <SearchBar
                    showSearchBar={showSearchBar}
                    closeSearchBar={turnOffSearchBar}
                    openSearchBar={turnOnSearchBar}
                  />
                </StyledContainer>
              </Hidden>
            )}
            

            {/* <Hidden smDown>
            </Hidden> */}

            {isMobile ? <></> : <MenuDesktop categories={topCategories} menuComponents={headerSpots} />}

            {/*<StyledSwipeableDrawer*/}
            {/*    anchor={useMediaQuery(theme.breakpoints.up("sm")) ? "top" : "left"}*/}
            {/*    open={open}*/}
            {/*    onClose={turnOffOpen}*/}
            {/*    onOpen={turnOnOpen}*/}
            {/*    className="header-menu"*/}
            {/*    data-testid="header-menu-drawer-element">*/}
            {/*    <StyledContainer>*/}
            {/*        <StyledGrid container spacing={2} className={"menu-container " + (open ? "open" : "")}>*/}
            {/*            <MegaMenu menutitle={t("MegaMenu.Title")} pages={topCategories}*/}
            {/*                      closeMegaMenu={turnOffOpen}></MegaMenu>*/}
            {/*        </StyledGrid>*/}
            {/*    </StyledContainer>*/}
            {/*</StyledSwipeableDrawer>*/}
          </StyledHeaderHDM>
          {location.pathname !== ROUTES.PRO_LEAD_FORM && location.pathname !== expiredLinkPath ? (
            <>
              <StyledBox className="inferior-espot-container">
                {MemoizedInferiorSection}
              </StyledBox>

            </>
          ) : null}
        </>
        ) : null}
    </>
  );
};

export { Header };
