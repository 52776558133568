import styled from "styled-components";
import { StyledBox, StyledGrid, StyledListItem, StyledTypography } from "../../../hdm/elements"
import { palette } from "../../../hdm/themes";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { tabletBreakpoint } from "../../../hdm/functions/utils";

const PasswordRequirementsContainer = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme, color, dotColor }) => `

    .requerimiento {
        color: ${palette.gray["300"]};
        display: list-item;
        padding: 0px;
        margin-bottom: 15px;
    }

    .requerimiento-error {
        color: ${palette.error.main};
        display: list-item;
        padding: 0px;
        margin-bottom: 15px;
        :before {
            color: ${palette.error.main};
        }
    }

    .requerimiento-ok {
        color: ${palette.highlight.main};
        display: list-item;
        padding: 0px;
        margin-bottom: 15px;
    }

    .none-underline {
        text-decoration: none;
    }

    .liststyle {
        list-style-type: disc;
    }

    .password-criterias-list {
        li {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: .5;
        }

        li:before {
            content: "•";
            padding-right: ${theme.spacing(1)}px;
            font-weight: 900;
            font-size: 28px;
        }
    }

    .styled-special-char li {
        line-height: 1;
    }

    .styled-criterias-container{
        width: calc(50% - 10px);
        ${theme.breakpoints.down(tabletBreakpoint())} {
            width: 100%;
        }
    }
    
`}
`;

interface Props {
    passwordValidations: any
    valReq: any
}
export const PasswordRequirements = (props: Props) => {

    const { passwordValidations, valReq } = props
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

    return (
        <PasswordRequirementsContainer>
        <StyledGrid container gap={isTablet ? 0 : 1}>
            <StyledGrid item className="styled-criterias-container">
                <StyledTypography component="ul" className="password-criterias-list">
                    <StyledTypography component="li" variant="bodySubText" className={passwordValidations.caracteresMin8 ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"}>
                    {t("ResetPassword.PasswordCriteria.Criteria1")}
                    </StyledTypography>
                </StyledTypography>
                <StyledTypography component="ul" className="password-criterias-list">
                    <StyledTypography component="li" variant="bodySubText" className={passwordValidations.lowerCaseLetter ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"}>
                    {t("ResetPassword.PasswordCriteria.Criteria2")}
                    </StyledTypography>
                </StyledTypography>
                <StyledTypography component="ul" className="password-criterias-list">
                    <StyledTypography component="li" variant="bodySubText" className={passwordValidations.upperCaseLetter ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"}>
                    {t("ResetPassword.PasswordCriteria.Criteria3")}
                    </StyledTypography>
                </StyledTypography>
            </StyledGrid>

            <StyledGrid item className="styled-criterias-container">
                <StyledTypography component="ul" className="password-criterias-list">
                    <StyledTypography component="li" variant="bodySubText" className={passwordValidations.anyNumber ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"}>
                    {t("ResetPassword.PasswordCriteria.Criteria4")}
                    </StyledTypography>
                </StyledTypography>
                <StyledTypography component="ul" className={`password-criterias-list ${isTablet ? "" : "styled-special-char"}`}>
                    <StyledTypography component="li" variant="bodySubText" className={passwordValidations.specialChar ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"}>
                    {t("ResetPassword.PasswordCriteria.Criteria5")}
                    </StyledTypography>
                </StyledTypography>
            </StyledGrid>
        </StyledGrid>
        </PasswordRequirementsContainer>
    )
}