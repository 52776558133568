/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

const CustomContainer = (props: any) => <Container maxWidth="lg" {...props} />;

const StyledContainer = styled(CustomContainer)`
  ${({ theme }) => `

    &.expanded-menu {
      width:inherit;
      height:${theme.spacing(5)}px;
      text-align: center;

      &.MuiContainer-root {
        width:inherit;
        margin:0;
        padding:0;
      }
    }

  `}
`;

export { StyledContainer };

export const StyledIconContainer = styled.div`
  width: 130px;
  height: 130px;
  background-color: #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`;

export const StyledEmptyOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

export const StyledColumnOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #777;
`;

export const StyledRowOrdersContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #777;
  margin: 5px 0;
`;

export const StyledInfoOrdersContainer = styled.div`
  width: 40%;
  color: #777;
  font-size: 16px;
`;

export const StyledButtonOrdersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #3e7697;
  font-size: 16px;
  text-align: center;
  height: 30px;
`;
