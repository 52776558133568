import { useTranslation } from "react-i18next"
import { StyledTypography } from "../../../hdm/elements"
import styled from "styled-components"


interface Props {
    direccion: any
    direccionType: string
    page?: string
}

const AddressField = styled((props: any) => <StyledTypography {...props} />)`
  ${({ theme }) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
    `}
`;

const DireccionInfo = (props: Props) => {

    const { addressLine, city, state, zipCode, isValid } = props.direccion
    const { direccionType, page="" } = props
    const { t } = useTranslation();

    if(page === "cart" && !isValid) {
        return(
            <>
                <StyledTypography variant="bodyBaseline">
                    {t("CheckoutProfile.noAddress")}
                </StyledTypography>
            </>
        );
    }

    if(direccionType == '1' && addressLine){

        return (
            <> 
            <AddressField variant="bodyBaseline">
                {addressLine && addressLine[0]} {addressLine && addressLine[1]}, {addressLine && addressLine[2]}
            </AddressField>
            <StyledTypography variant="bodyBaseline">
                {city}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline">
                {state} C.P. {zipCode}
            </StyledTypography>
            </>
        )
    } 

    return (
        <> 
        <AddressField variant="bodyBaseline">
            {addressLine && addressLine[0]} {addressLine && addressLine[1]}, {addressLine &&addressLine[2]}
        </AddressField>
        <StyledTypography variant="bodyBaseline">
            {city}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline">
            {state} C.P. {zipCode}
        </StyledTypography>
        </>
    )
}

export default DireccionInfo