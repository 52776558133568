import { PRO_STORE_BUYABLE_EXCLUDE, STORE_BUYABLE_EXCLUDE } from "../../constants/product-attribute"

const validateStoreExclude = (obj, valueExclude) => {

  if(obj?.values){
    const values = obj?.values[0]

    if(values && values?.value){
      const isExclude = values?.value.includes(valueExclude)
      console.log('isExclude', isExclude)
      if(!isExclude){
        return false
      }
    }
  }
  return true
}

const getPromoicons = (attributes: any, isB2B: boolean, currentStoreId: string, currentMarketId: string) => {


  if (attributes) {
    let promoIcons: Array<string>;

    if (isB2B) {

      promoIcons = [
        "PROMOICON_GR",
        "PROMOICON_CE",
        "PROMOICON_NLP",
        "PROMOICON_HV",
        "PROMORIBBON_COMBO",
        // "PROMOICON_FP",
        "PROMORIBBON_NXM_2BY1",
        "PROMORIBBON_NXM_3BY2",
        "PROMORIBBON_NXM_4BY3",
        "PROMORIBBON_NXM_5BY3",
        "PROMORIBBON_NXM_5BY4",
        "PROMORIBBON_NXM_6BY5",
        "PROMORIBBON_NXM_7BY6",
        "PROMORIBBON_NXM_8BY7",
        "PROMORIBBON_NXM_9BY8",
        "PROMORIBBON_NXM_10BY9",
        "PROMORIBBON_NXM_11BY10",
        "PROMORIBBON_NXM_12BY10",
        "PROMORIBBON_NXM_12BY11",
        "PROMORIBBON_NXM_16BY15",
        "PROMORIBBON_AHP",
        // "PROMOICON_CP",
        // "PROMOICON_MSI",
        "PROMORIBBON_BF",
        "PROMORIBBON_HS",
        "PROMORIBBON_CD",
        "PROMOICON_VF",
        "PROMOICON_PC",
        "ONLINE_ONLY_SELLING_STORE",
        "DROPSHIP_ONLY_SELLING_STORE",
        "PRO_STORE_ONLY_AVAILABLE",
        "PRO_STORE_BUYABLE_EXCLUDE",
        "PRO_MARKET_BUYABLE_EXCLUDE",
        "TAG_EXCLUSIVE",
        "COLOR",
      ];
    } else {
      promoIcons = [
        "PROMOICON_GR",
        "PROMOICON_CE",
        "PROMOICON_NLP",
        "PROMOICON_HV",
        "PROMORIBBON_COMBO",
        "PROMOICON_FP",
        "PROMORIBBON_NXM_2BY1",
        "PROMORIBBON_NXM_3BY2",
        "PROMORIBBON_NXM_4BY3",
        "PROMORIBBON_NXM_5BY3",
        "PROMORIBBON_NXM_5BY4",
        "PROMORIBBON_NXM_6BY5",
        "PROMORIBBON_NXM_7BY6",
        "PROMORIBBON_NXM_8BY7",
        "PROMORIBBON_NXM_9BY8",
        "PROMORIBBON_NXM_10BY9",
        "PROMORIBBON_NXM_11BY10",
        "PROMORIBBON_NXM_12BY10",
        "PROMORIBBON_NXM_12BY11",
        "PROMORIBBON_NXM_16BY15",
        "PROMORIBBON_AHP",
        "PROMOICON_CP",
        "PROMOICON_MSI",
        "PROMORIBBON_BF",
        "PROMORIBBON_HS",
        "PROMORIBBON_CD",
        "PROMOICON_VF",
        "PROMOICON_PC",
        "ONLINE_ONLY_SELLING_STORE",
        "DROPSHIP_ONLY_SELLING_STORE",
        "MARKET_BUYABLE_EXCLUDE",
        "STORE_ONLY_AVAILABLE",
        "STORE_BUYABLE_EXCLUDE",
        "TAG_EXCLUSIVE",
        "COLOR",
      ];
    }

    let atributos: string[] = attributes.map((obj) => { 

      // return obj.identifier
      let validAttr = true

      if(isB2B){
        if(obj.identifier === "PRO_MARKET_BUYABLE_EXCLUDE"){
          validAttr = validateStoreExclude(obj, currentMarketId)
        }
        if(obj.identifier === "PRO_STORE_BUYABLE_EXCLUDE"){
          validAttr = validateStoreExclude(obj, currentStoreId)
        }
        if(obj.identifier === "PRO_STORE_ONLY_AVAILABLE"){
          validAttr = validateStoreExclude(obj, currentMarketId)
        }

      }else {
        if(obj.identifier === "MARKET_BUYABLE_EXCLUDE"){
          validAttr = validateStoreExclude(obj, currentMarketId)
        }
        if(obj.identifier === "STORE_BUYABLE_EXCLUDE"){
          validAttr = validateStoreExclude(obj, currentStoreId)
        }
        if(obj.identifier === "STORE_ONLY_AVAILABLE"){
          validAttr = validateStoreExclude(obj, currentMarketId)
        }
      }

      if(validAttr) return obj.identifier
      
      return
    });
    
    atributos = atributos.filter((attr) => typeof attr === "string");

    const allPromoIcons = atributos.filter((item) => promoIcons.includes(item));

    const promoIconsPrueba = [
      // 'PROMOICON_GR',
      // "PROMOICON_CE",
      // "PROMOICON_NLP",
      // "PROMOICON_HV",
      // 'PROMORIBBON_COMBO',
      // "PROMOICON_FP",
      // "PROMORIBBON_NXM_2BY1",
      // 'PROMORIBBON_NXM_3BY2',
      // "PROMORIBBON_NXM_4BY3",
      // 'PROMORIBBON_NXM_5BY3',
      // 'PROMORIBBON_NXM_5BY4',
      // 'PROMORIBBON_NXM_6BY5',
      // 'PROMORIBBON_NXM_7BY6',
      // 'PROMORIBBON_NXM_8BY7',
      // 'PROMORIBBON_NXM_9BY8',
      // 'PROMORIBBON_NXM_10BY9',
      // 'PROMORIBBON_NXM_11BY10',
      // "PROMORIBBON_NXM_12BY10",
      // 'PROMORIBBON_NXM_12BY11',
      // "PROMORIBBON_NXM_16BY15",
      // "PROMORIBBON_AHP",
      // "PROMOICON_CP",
      // "PROMOICON_MSI",
      // 'PROMORIBBON_HS',
      // "PROMORIBBON_BF",
      // "PROMORIBBON_CD",
      // "PROMOICON_VF",
      // "PROMOICON_PC",
      // "ONLINE_ONLY_SELLING_STORE",
      // 'STORE_ONLY_AVAILABLE',
      "PRO_STORE_ONLY_AVAILABLE",
      // "COLOR"
    ].filter((item) => promoIcons.includes(item));

    return allPromoIcons;
  }

  return [];
};

const getUnidadMedida = (quantitymeasure: string) => {
  // const unidad = product?.quantityMeasure
  let unidadMedidaSelected = "";
  if (quantitymeasure && quantitymeasure !== "") {
    const unidadMedidas = {
      c62: "c62",
      C62: "c62",
      MTK: "m2",
      MTR: "m",
      KGM: "kg",
      LTR: "l",
    };

    unidadMedidaSelected = unidadMedidas[quantitymeasure];
  }
  return unidadMedidaSelected;
};

export const getPesoMedida = (weightMeasure: string) => {
  // const unidad = product?.quantityMeasure
  let unidadPesoMedidaSelected = "";
  if (weightMeasure && weightMeasure !== "") {
    const unidadMedidas = {
      BX: "caja",
      LM: "metro lineal",
    };

    unidadPesoMedidaSelected = unidadMedidas[weightMeasure];
  }
  return unidadPesoMedidaSelected;
};

const ribbonsPromoIconsImg = (cdn) => {
  return {
    PROMOICON_CE: cdn + "iconosPromos/SB.svg",
    PROMOICON_NLP: cdn + "iconosPromos/NLP.svg",
    PROMOICON_GR: cdn + "iconosPromos/remate.svg",
    PROMORIBBON_COMBO: cdn + "iconosPromos/combos.svg",
    PROMORIBBON_AHP: cdn + "iconosPromos/precio_mayoreo.svg",
    PROMOICON_CP: cdn + "iconosPromos/ahorramas_v.svg",
    PROMOICON_HV: cdn + "iconosPromos/hipoteca-verde.svg",
    PROMOICON_FP: cdn + "iconosPromos/FP.svg",
    PROMORIBBON_NXM_2BY1: cdn + "iconosPromos/2x1.svg",
    PROMORIBBON_NXM_3BY2: cdn + "iconosPromos/3x2.svg",
    PROMORIBBON_NXM_4BY3: cdn + "iconosPromos/4x3.svg",
    PROMORIBBON_NXM_5BY3: cdn + "iconosPromos/5x3.svg",
    PROMORIBBON_NXM_5BY4: cdn + "iconosPromos/5x4.svg",
    PROMORIBBON_NXM_6BY5: cdn + "iconosPromos/6x5.svg",
    PROMORIBBON_NXM_7BY6: cdn + "iconosPromos/7x6.svg",
    PROMORIBBON_NXM_8BY7: cdn + "iconosPromos/8x7.svg",
    PROMORIBBON_NXM_9BY8: cdn + "iconosPromos/9x8.svg",
    PROMORIBBON_NXM_10BY9: cdn + "iconosPromos/10x9.svg",
    PROMORIBBON_NXM_11BY10: cdn + "iconosPromos/11x10.svg",
    PROMORIBBON_NXM_12BY10: cdn + "iconosPromos/12x10.svg",
    PROMORIBBON_NXM_12BY11: "",
    PROMORIBBON_NXM_16BY15: cdn + "iconosPromos/16x15.svg",
  };
};

const ordenarJerarquia = (promociones: string[]) => {
  const jerarquia = [
    "PROMOICON_GR",
    "PROMORIBBON_COMBO",
    "PROMOICON_FP",
    "PROMORIBBON_NXM_2BY1",
    "PROMORIBBON_NXM_3BY2",
    "PROMORIBBON_NXM_4BY3",
    "PROMORIBBON_NXM_5BY3",
    "PROMORIBBON_NXM_5BY4",
    "PROMORIBBON_NXM_6BY5",
    "PROMORIBBON_NXM_7BY6",
    "PROMORIBBON_NXM_8BY7",
    "PROMORIBBON_NXM_9BY8",
    "PROMORIBBON_NXM_10BY9",
    "PROMORIBBON_NXM_11BY10",
    "PROMORIBBON_NXM_12BY10",
    "PROMORIBBON_NXM_12BY11",
    "PROMORIBBON_NXM_16BY15",
    "PROMORIBBON_AHP",
    "PROMOICON_CP",
    "PROMOICON_VF",
    "PROMOICON_PC",
    "PROMOICON_MSI",
    "ONLINE_ONLY_SELLING_STORE",
    "DROPSHIP_ONLY_SELLING_STORE",
    "MARKET_BUYABLE_EXCLUDE",
    "STORE_ONLY_AVAILABLE",
    "STORE_BUYABLE_EXCLUDE",
    "PRO_STORE_ONLY_AVAILABLE",
    "TAG_EXCLUSIVE",
  ];

  const arr1 = [
    "PROMOICON_GR",
    "PROMORIBBON_NXM_3BY2",
    "PROMORIBBON_AHP",
    "PROMORIBBON_NXM_2BY1",
    "PROMORIBBON_COMBO",
    "PROMOICON_CP",
    "PROMOICON_FP",
    "PROMORIBBON_BF",
  ];

  if (promociones) {
    let orderRibbons: string[] = JSON.parse(JSON.stringify(promociones));

    orderRibbons = orderRibbons.filter((item) => jerarquia.includes(item));
    // const res = arr1.filter(item => jerarquia.includes(item));

    orderRibbons.sort(function (a, b) {
      return jerarquia.indexOf(a) - jerarquia.indexOf(b);
    });

    return orderRibbons;
  }

  return [];
};

const ordenarJerarquiaCompare = (promociones: string[]) => {
  const jerarquia = [
    "PROMOICON_GR",
    "PROMOICON_NLP",
    "PROMOICON_CE",
    "PROMORIBBON_COMBO",
    "PROMOICON_FP",
    "PROMORIBBON_NXM_2BY1",
    "PROMORIBBON_NXM_3BY2",
    "PROMORIBBON_NXM_4BY3",
    "PROMORIBBON_NXM_5BY3",
    "PROMORIBBON_NXM_5BY4",
    "PROMORIBBON_NXM_6BY5",
    "PROMORIBBON_NXM_7BY6",
    "PROMORIBBON_NXM_8BY7",
    "PROMORIBBON_NXM_9BY8",
    "PROMORIBBON_NXM_10BY9",
    "PROMORIBBON_NXM_11BY10",
    "PROMORIBBON_NXM_12BY10",
    "PROMORIBBON_NXM_12BY11",
    "PROMORIBBON_NXM_16BY15",
    "PROMORIBBON_AHP",
    "PROMOICON_CP",
    "PROMOICON_VF",
    "PROMOICON_PC",
    "PROMOICON_MSI",
    "ONLINE_ONLY_SELLING_STORE",
    "DROPSHIP_ONLY_SELLING_STORE",
    "MARKET_BUYABLE_EXCLUDE",
    "STORE_ONLY_AVAILABLE",
    "STORE_BUYABLE_EXCLUDE",
    "PRO_STORE_ONLY_AVAILABLE",
    "TAG_EXCLUSIVE",
  ];

  const arr1 = [
    "PROMOICON_GR",
    "PROMORIBBON_NXM_3BY2",
    "PROMORIBBON_AHP",
    "PROMORIBBON_NXM_2BY1",
    "PROMORIBBON_COMBO",
    "PROMOICON_CP",
    "PROMOICON_FP",
    "PROMORIBBON_BF",
  ];

  if (promociones) {
    let orderRibbons: string[] = JSON.parse(JSON.stringify(promociones));

    orderRibbons = orderRibbons.filter((item) => jerarquia.includes(item));
    // const res = arr1.filter(item => jerarquia.includes(item));

    orderRibbons.sort(function (a, b) {
      return jerarquia.indexOf(a) - jerarquia.indexOf(b);
    });

    return orderRibbons;
  }

  return [];
};

const numberWithCommas = (product) => {
  const offerPrice = product?.price?.filter((precio) => precio.usage === "Offer")[0] ?? "";
  const displayPrice = product?.price?.filter((precio) => precio.usage === "Display")[0] ?? "";
  const componentes = product?.componentes;
  const components = product?.components;

  const allPrices = {
    displayPrice: "0.00",
    displayUnidad: "0",
    displayDecimal: "00",
    offerPrice: "0.00",
    offerUnidad: "0",
    offerDecimal: "00",
    listPrice: "0.00",
    listUnidad: "0",
    listDecimal: "00",
    listMedidaPrice: "0.00",
    listMedidaUnidad: "0",
    listMedidaDecimal: "00",
    offerMedidaPrice: "0.00",
    offerMedidaUnidad: "0",
    offerMedidaDecimal: "00",
  };
  if (componentes && componentes?.length > 0 && parseFloat(allPrices.listPrice) === 0) {
    let savings = 0;
    componentes.forEach((p) => {
      const offerPrice = p.price.filter((precio) => precio.usage === "Offer")[0];
      if (offerPrice?.value && parseFloat(offerPrice.value) > 0) {
        savings += parseFloat(offerPrice.value);
      }
    });

    allPrices.listPrice =
      savings && savings > 0
        ? savings
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
  } else if (components && components?.length > 0 && parseFloat(allPrices.listPrice) === 0) {
    let savings = 0;
    components.forEach((p) => {
      const offerPrice = p.price.filter((precio) => precio.usage === "Offer")[0];
      if (offerPrice?.value && parseFloat(offerPrice.value) > 0) {
        savings += parseFloat(offerPrice.value);
      }
    });

    allPrices.listPrice =
      savings && savings > 0
        ? savings
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
  }

  const field1 = offerPrice?.field1;

  let numOfKeys = Object.keys(offerPrice).length;
  if (field1) {
    numOfKeys = Object.keys(field1).length;
  }

  if (numOfKeys !== 0) {
    allPrices.displayPrice =
      displayPrice?.value && displayPrice?.value !== ""
        ? parseFloat(displayPrice.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.displayUnidad =
      displayPrice?.value && displayPrice?.value !== ""
        ? parseFloat(displayPrice.value)
            .toFixed(2)
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.displayDecimal =
      displayPrice?.value && displayPrice?.value !== ""
        ? parseFloat(displayPrice.value).toFixed(2).toString().split(".")[1]
        : "00";
    allPrices.listMedidaPrice =
      offerPrice?.field1?.LMP && offerPrice.field1.LMP !== ""
        ? parseFloat(offerPrice.field1.LMP)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.listMedidaUnidad =
      offerPrice?.field1?.LMP && offerPrice.field1.LMP !== ""
        ? offerPrice.field1.LMP.toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.listMedidaDecimal =
      offerPrice?.field1?.LMP && offerPrice.field1.LMP !== ""
        ? parseFloat(offerPrice.field1.LMP).toFixed(2).toString().split(".")[1]
        : "00";
    allPrices.listPrice =
      offerPrice?.field1?.LP && offerPrice.field1.LP !== ""
        ? parseFloat(offerPrice.field1.LP)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.listUnidad =
      offerPrice?.field1?.LP && offerPrice.field1.LP !== ""
        ? offerPrice.field1.LP.toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.listDecimal =
      offerPrice?.field1?.LP && offerPrice.field1.LP !== ""
        ? parseFloat(offerPrice.field1.LP).toFixed(2).toString().split(".")[1]
        : "00";
    allPrices.offerMedidaPrice =
      offerPrice?.field1?.OMP && offerPrice.field1.OMP !== ""
        ? parseFloat(offerPrice.field1.OMP)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.offerMedidaUnidad =
      offerPrice?.field1?.OMP && offerPrice.field1.OMP !== ""
        ? parseFloat(offerPrice.field1.OMP)
            .toFixed(2)
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.offerMedidaDecimal =
      offerPrice?.field1?.OMP && offerPrice.field1.OMP !== ""
        ? parseFloat(offerPrice.field1.OMP).toFixed(2).toString().split(".")[1]
        : "00";

    allPrices.offerPrice =
      offerPrice?.value && offerPrice?.value !== ""
        ? parseFloat(offerPrice.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.offerUnidad =
      offerPrice?.value && offerPrice?.value !== ""
        ? parseFloat(offerPrice.value)
            .toFixed(2)
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.offerDecimal =
      offerPrice?.value && offerPrice?.value !== ""
        ? parseFloat(offerPrice.value).toFixed(2).toString().split(".")[1]
        : "00";
  } else {
    allPrices.displayPrice =
      displayPrice?.value && displayPrice.value !== ""
        ? parseFloat(displayPrice.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.displayUnidad =
      displayPrice?.value && displayPrice.value !== ""
        ? displayPrice.value
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.displayDecimal =
      displayPrice?.value && displayPrice.value !== ""
        ? parseFloat(displayPrice.value).toFixed(2).toString().split(".")[1]
        : "00";
    allPrices.offerPrice =
      offerPrice?.value && offerPrice.value !== ""
        ? parseFloat(offerPrice.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    allPrices.offerUnidad =
      offerPrice?.value && offerPrice.value !== ""
        ? parseFloat(offerPrice.value)
            .toFixed(2)
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    allPrices.offerDecimal =
      offerPrice?.value && offerPrice.value !== ""
        ? parseFloat(offerPrice.value).toFixed(2).toString().split(".")[1]
        : "00";
  }

  // console.log('allPrices', allPrices)

  return allPrices;
};

const getRegularProPrice = (price) => {

  const regularPrice = price.find(price => price.usage === "Offer")

  if(regularPrice?.regularValue){
    return regularPrice.regularValue
  } 
  
  return null

}

const hasStoreBuyableExclude = (attributes, isB2B) => {
  return attributes && attributes.find((attr) => {
    if(isB2B){
      return attr?.identifier === PRO_STORE_BUYABLE_EXCLUDE;
    } else {
      return attr?.identifier === STORE_BUYABLE_EXCLUDE;
    }
  });
}

export {
  getPromoicons,
  ribbonsPromoIconsImg,
  ordenarJerarquia,
  ordenarJerarquiaCompare,
  getUnidadMedida,
  numberWithCommas,
  getRegularProPrice,
  hasStoreBuyableExclude,
};
