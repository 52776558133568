/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Step from "@material-ui/core/Step";

const StyledStep = styled(({ ...props }) => <Step {...props} />)`
  ${({ theme }) => `
    & .MuiStepIcon-active {
      color: ${theme.palette.primary.contrastText};
      border: 1px solid  ${theme.palette.primary.main};
      border-radius: 15px;
      & .MuiStepIcon-text {
        fill: ${theme.palette.primary.main} !important;
      }
    }
    
  `}
`;

export { StyledStep };
