import styled from "styled-components";
import { StyledGrid } from "../../../../hdm/elements";

const StyledCurrentSelectedStoreContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  & .currentSelectedStore--address-status{
    padding-left: ${theme.spacing(3)}px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .currentSelectedStore--address-statusOnly{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  & .currentSelectedStore--address-store{
    display: flex;
    align-items: center;
    justify-content: normal;
    & .currentSelectedStore--address-store-name{
        display: flex;
        align-items: center;
        justify-content: normal;
        & .MuiSvgIcon-root{
            font-size: 18px;
            color: ${theme.palette.primary.main};
        }
        & .MuiTypography-root{
            color: ${theme.palette.secondary.main};
        }
        .styled--headerterxt-color {
          color: ${theme.palette.background.paper};
      
        }
    }

    & .MuiSvgIcon-root{
        font-size: 14px;
        color: ${theme.palette.text.highlight};
    }
  }

  .styled--headerterxt-color {
    color: ${theme.palette.background.paper};

  }

    .currentSelectedStore{
    &--styledTypography{
      &-highlight{
        color: ${theme.palette.text.highlight};
      }
      &-secondary{
        color: ${theme.palette.text.secondary};
      }
      &-errorMain{
        color: ${theme.palette.error.main};
      }
      &-primary{
        color: ${theme.palette.text.primary};
      }
      &-capital{
        text-transform: uppercase;
      }
      &-secondaryPalette{
        color: ${theme.palette.secondary.main};
      }
  }
`}
`;
export { StyledCurrentSelectedStoreContainer };
