/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
// import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
//UI
import { useSite } from "../../../_foundation/hooks/useSite";

import SendIcon from "../../../assets/homedepot/images/send-mail.svg";
import SmsIcon from "../../../assets/homedepot/images/sms.svg";
import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
  StyledBox,
  StyledAccordionSummary,
  StyledAccordion,
  StyledAccordionDetails,
  StyledListItemText,
  StyledLink,
  StyledButton,
  StyledTextField,
} from "../../../hdm";
import { StyledListItem } from "@hcl-commerce-store-sdk/react-component";
import { Hidden, TextField, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { NEWSLETTER_CONFIRM, CHECKOUT, PRINT_ORDER, PRO_LEAD_FORM, PRO_AR_FEEDBACK } from "../../../constants/routes";
//Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Styles
import "../../../assets/homedepot/styles/sass/app.scss";

//Data hard code
import { footerOptionsList } from "./utils";
import styled from "styled-components";
import { palette } from "../../../hdm/themes";

import { SessionErrorDialog } from "../../widgets/session-error-modal";
import ConfirmationDialog from "../../widgets/confirmation-dialog/ConfirmationDialog";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { StyledPromp } from "../styled-promp";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../../redux/selectors/user";
import EspotHdm from "../shared/EspotHdm";
import { triggerSuscribe } from "../conversion-api/SuscribeEvent";
import DangerouslySetHtmlContent from "../DangerouslySetHtmlContent/DangerouslySetHtmlContent";
import { GtmLoaded } from "../gtm-events/Gtm-loaded";
import { hdmFooterRoute } from "../../../redux/selectors/hdmData";
import { SLStyledTypography } from "../skeleton-loader/components/SLStyledTypography";


import withSkeletonLoader from "../skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../skeleton-loader/components";
import { SLFooterElements } from "../skeleton-loader/components/dimensions/Footer";
import { StyledFooterNewsletter } from "./StyledFooterNewsletter";
import { StyledSkeleton } from "../skeleton-loader/components/StyledSkeleton";



interface IFooterRefsHeightProps {
  firstAccordion?: number | undefined;
  secondAccordion?: number | undefined;
  thirdAccordion?: number | undefined;
  fourthAccordion?: number | undefined;
}

const StyledBoxHDM = styled((props: any) => <div {...props} />)`
  ${({ theme }) => `
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      a{
        color: ${palette.secondary.main};
      }
      @media screen and (min-width: 960px){
        text-align: right;
        justify-content: flex-end;

      }
  `}
`;

const StyledFooterElementHDM = styled((props: any) => <StyledFooter {...props} />)`
  ${({ theme, ..._props }) => `
      background: transparent;
      ${_props.viewHeaderCart ? "display:none;" : ""}
  `}
`;

const StyledAccordionDetailsHDM = styled((props: any) => <StyledAccordionDetails {...props} />)`
  ${({ theme, accordionMaxHeight }) => `
      display: block;
      width: 100%;
      min-height: 100% !important;
  `}
`;

const StyledAccordionSummaryHDM = styled((props: any) => <StyledAccordionSummary {...props} />)`
  ${({ theme }) => `
      width: 100%;
  `}
`;

export const FooterNewsletter = (props) => {
  const { mySite } = useSite();
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [errorMail, setErrorMail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const icons = props.icons;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChangeSubscribeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSubscribeEmail(newValue);
  };

  const validarEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleEmarsysSubscribe = async () => {
    const result = validarEmail(subscribeEmail);

    if (result) {
      setErrorMail(false);
      setError(false);
      await axios
        .put<any>(`/wcs/resources/store/${mySite.storeID}/hdm/users/guestOptIn`, { email: subscribeEmail })
        .then((response) => {
          if (response.data.response.emarsysResponse === "OK") {
            navigate(NEWSLETTER_CONFIRM);
            setSubscribeEmail("");

            try {
              triggerSuscribe(subscribeEmail, mySite.storeID);
            } catch (e) {
              console.log("Suscribe error: " + e);
            }
          }
        })
        .catch(function (e) {
          console.log("another error happened:" + e.message);
          setError(true);
        });
    } else {
      setErrorMail(true);
      setError(false);
    }
  };

  const NewsLetter = ()=>{
    return (
      <>
        {/*E-spot | UI/UX ~ MainFooter_SuperSection*/}
        {/*<Hidden smDown>*/}
        <StyledBoxHDM>
          <a rel="noreferrer" href={props?.data && props?.data?.tyc[0]?.url} target={"_blank"}>
            <SLStyledTypography variant={"bodyBaseline"}
              options={
                {
                  width: SLFooterElements.tyc.getWidth(),
                  height: SLFooterElements.tyc.getHeight()
                }
              }
            >
              {props?.data && props?.data?.tyc[0]?.text}
            </SLStyledTypography>
          </a>
        </StyledBoxHDM>
        {/*</Hidden>*/}

        <StyledGrid id={"home--footer-newsLetterContainer"} container className={"footer-sup"}>
          <StyledGrid
            container
            // xs={12}
            className={"footer-sup__grid-up-container"}>
            <StyledGrid item sm={12} md={6} className={"footer-sup__grid-up-container-left"}>
              <SLStyledTypography variant={"headingSubtitle"}
                options={
                  {
                    width: SLFooterElements.newsletter.title.getWidth(),
                    height: SLFooterElements.newsletter.title.getHeight()
                  }
                }
              >
                {props?.data && props?.data?.newsletter?.title}
              </SLStyledTypography>
              {/* <h2 id="footer">Fotter hash tag ref</h2> */}
              <StyledTypography variant={"bodyBaseline"} className={"footer-sup__grid-up-container-left-text"}>
                {props?.data && props?.data?.newsletter?.subtitle}
              </StyledTypography>
            </StyledGrid>

            <StyledGrid item xs={12} md={6}>
              {/* Q<EspotHdm espotName="Mui_component"/>R */}
              <EspotHdm espotName={mySite?.isB2B ? "ProMainFooter_NewsletterSection" : "MainFooter_NewsletterSection"} />
              {/*<StyledGrid
              container
              // xs={12}
              justifyContent={"space-around"}
              alignItems={"flex-start"}
              className={"footer-sup__grid-up-container-right-cont"}>
                
              <StyledGrid item sm={10} md={7} className={"w-grid"}>
                {!isB2B ? 
                <>
                <StyledTextField
                  variant={"outlined"}
                  type={"email"}
                  label={props?.data && props?.data?.input?.placeholder}
                  value={subscribeEmail}
                  fullWidth
                  error={errorMail}
                  helperText={
                    errorMail  ? t("AddressForm.Msgs.EmailError") : ""
                  }
                  onChange={handleChangeSubscribeInput}></StyledTextField>
                </>
                : null
              </StyledGrid>
              <StyledGrid item sm={2} md={5}>
                <Hidden only={["sm", "xs"]}>
                  <StyledButton
                    variant={"outlined"}
                    className={"footer-sup__grid-up-container-right-button top-margin-2"}
                    onClick={handleEmarsysSubscribe}>
                    <AssetIcon icon={props.data && props.data.button.icon} iconSize={40} />
                    <StyledTypography variant={"textButtons"} weight="bold">
                      {props?.data && props?.data?.button?.title}
                    </StyledTypography>
                  </StyledButton>
                </Hidden>

                <Hidden only={["md", "lg", "xl"]}>
                  <StyledButton
                    variant={"outlined"}
                    className={"footer-sup__grid-up-container-right-button top-margin-2"}
                    onClick={handleEmarsysSubscribe}>
                    <AssetIcon icon={props?.data && props?.data?.button?.icon} iconSize={30} />aquiii
                  </StyledButton>
                </Hidden>
              </StyledGrid>
            </StyledGrid>

            <StyledTypography
              className={"footer-sup__grid-up-container-right-terminos"}
              variant={isMobile ? "bodyCaption" : "bodyBaseline"}>
              {props?.data && HTMLReactParser(props?.data?.input?.subtitle)}
            </StyledTypography>*/}
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            container
            // xs={12}
            className={"footer-sup__grid-down-container"}>
            <StyledGrid
              item
              sm={12}
              md={4}
              // justifyContent={"center"}
              // alignContent={"center"}
              className={"footer-sup__grid-down-container-left"}>
              <StyledTypography variant={"headingTitle"} component={"h4"} weight="light" fontFamily='"Open Sans Light"'>
                {props?.data && props?.data?.slogan}
              </StyledTypography>
            </StyledGrid>
            {icons}
          </StyledGrid>
        </StyledGrid>
      </>
    )
  }

  return withSkeletonLoader( <NewsLetter/>, props.data != null, loaders.footer.newsletter );
};

export const getEspotFromProps = (eSName: string, data: any) => {
  if (data) {
    const espot = data.filter((item) => item && item.espot === eSName);

    if (espot.length > 0) {
      return espot[0].content;
    } else {
      <></>;
    }
  }

  return null;
};

export const iconsComponent = (props) => (
  <StyledGrid sm={12} md={8} item className={"footer-sup__grid-down-container-right"}>
    {/*ICONS*/}
    {props?.contacts &&
      props.contacts.length > 0 &&
      props.contacts.map((contact, index) => {
        return <ContactBox key={index} contact={contact} />;
      })}
  </StyledGrid>
);

const ContactBoxIcon = ({ icon, ...props }) => {
  const PropIcon = lazy(() => import("@material-ui/icons/" + icon));

  if(icon.includes("https")){
    return (
      <AssetIcon icon={icon} iconSize={22} color={"primary"} />
    )
  }

  return (
    <ErrorBoundary fallback={<AssetIcon icon={icon} iconSize={22} color={"primary"} />}>
      <Suspense fallback={<div>...</div>}>
        <PropIcon fontSize={"small"} color={"primary"} />
      </Suspense>
    </ErrorBoundary>
  );
};

const AssetIcon = (props) => {
  const [image, setImage] = useState("");

  
  useEffect(() => {
    props.icon && setImage(props.icon);
  }, [props]);

  return <img src={image} alt={props.icon} width={props.iconSize} className="lazyload" />;
};

const ContactBox = (props) => {
  return (
    <StyledBox>
      <StyledBox className={"footer-sup__grid-down-container-right-icon"}>
        <ContactBoxIcon icon={props.contact && props.contact.icon} fontSize={"small"} color={"primary"} />
        <StyledTypography className={"footer-sup__grid-down-container-right-typo"} variant={"bodyBaseline"}>
          {props.contact && props.contact.title}
        </StyledTypography>
      </StyledBox>
      {props.contact && (
        <>
          <DangerouslySetHtmlContent html={props.contact.subtitle} />
        </>
      )}
    </StyledBox>
  );
};

function StyledFooterHDM(props: any) {
  const [widthDimension, setWidthDimension] = useState<number>(window.innerWidth);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // B2B data
  // const location = useLocation();
  const userLoggedIn = useSelector(loginStatusSelector);
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;

  const firstCardFooter = useRef<HTMLDivElement>(null);
  const secondCardFooter = useRef<HTMLDivElement>(null);
  const thirdCardFooter = useRef<HTMLDivElement>(null);
  const fourthCardFooter = useRef<HTMLDivElement>(null);
  const [isBTLoaded, setIsBTLoaded] = useState<boolean>(false);
  const [isGTMLoaded, setIsGTMLoaded] = useState<boolean>(false);

  const footerRoute = useSelector(hdmFooterRoute);
  const expiredLinkPath = "/expired-link";



  useEffect(() => {
    setWidthDimension(window.innerWidth);
  }, []);


  const FooterCardESpot = (props) => {
    const { useRef } = props;
    return (
      <StyledGrid item xs={12} md={(isB2B && userLoggedIn) || !isB2B ? 3 : 4} className={"footer-spots__container"}>
        <StyledAccordion
          defaultExpanded={widthDimension > 600}
          className={"footer-spots__container-accordion"}
          square={false}
          isFooterAccordion
        >
          <StyledAccordionSummaryHDM
            style={{ minWidth: "100%"}}
            disableRipple={true}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <StyledTypography variant={"headingSubtitle"}>{props?.data && props?.data?.title}</StyledTypography>
          </StyledAccordionSummaryHDM>
          <StyledBox ref={useRef}>
            <StyledAccordionDetailsHDM>
              {props?.data &&
                props?.data?.contents?.map((content, index) => (
                  <StyledListItem key={index}>
                    <StyledListItemText
                      primary={

                        <StyledTypography variant={"bodyBaseline"}>
                          {content.url?.includes("http") && !content.url?.includes("tel") ?
                          <a rel="noopener noreferrer" key={content.title} href={content.url} target="_blank">{content.title}</a>
                          : content.url?.includes("tel") ?
                          <a href={content?.url.replace(/-/g, '')}>{content?.title}</a>
                          :
                          <Link key={content.title} to={content.url} >
                            {content.title}
                          </Link>
                          }

                          
                        </StyledTypography>
                      }
                    />
                  </StyledListItem>
                ))}
            </StyledAccordionDetailsHDM>
          </StyledBox>
        </StyledAccordion>
      </StyledGrid>
    );
  };

  const FooterAccordionsContainer = () => {

    const FooterNavigationCard = () => {
      return (
        <>
          <StyledGrid id={"home--footer-footerAccordionContainer"} container spacing={3} className={"footer-spots"}>

            <FooterCardESpot
              data={getEspotFromProps(`${!isB2B ? "MainFooter_Navigation1" : "ProMainFooter_Navigation1"}`, props.data)}
              useRef={firstCardFooter}
            />
            <FooterCardESpot
              data={getEspotFromProps(`${!isB2B ? "MainFooter_Navigation2" : "ProMainFooter_Navigation2"}`, props.data)}
              useRef={secondCardFooter}
            />
            {(isB2B && userLoggedIn) || !isB2B ? (
              <>
                <FooterCardESpot
                  data={getEspotFromProps(`${!isB2B ? "MainFooter_Navigation3" : "ProMainFooter_Navigation3"}`, props.data)}
                  useRef={thirdCardFooter}
                />
              </>
            ) : null}
            <FooterCardESpot
              data={getEspotFromProps(`${!isB2B ? "MainFooter_Navigation4" : "ProMainFooter_Navigation4"}`, props.data)}
              useRef={fourthCardFooter}
            />
          </StyledGrid>
        </>
      )
    }
    const showComponent = props.data.length != 0; // condition to show component
    const Component = withSkeletonLoader(<FooterNavigationCard /> , showComponent , loaders.footer.navigation_card)

    return Component

  };

  const FooterInferior = (props) => {
    const Wrapper = (props) => {
      return <StyledBox>{props.children}</StyledBox>;
    };

    const SocialNetworkIcon = ({ icon, url, ...props }) => {
      let PropIcon: any = <></>;
      PropIcon = lazy(() => import("@material-ui/icons/" + icon));

      if(icon.includes("https")){
        return (
          <Wrapper>
              <a rel="noreferrer" target={"_blank"} href={url}>
                <AssetIcon icon={icon} iconSize={16} color={"primary"} />
              </a>
            </Wrapper>
        )
      }

      return (
        <ErrorBoundary
          fallback={
            <Wrapper>
              <a rel="noreferrer" target={"_blank"} href={url}>
                <AssetIcon icon={icon} iconSize={16} color={"primary"} />
              </a>
            </Wrapper>
          }>
          <Wrapper>
            <a rel="noreferrer" target={"_blank"} href={url}>
              <Suspense fallback={<StyledSkeleton variant="rect" width={20} height={20}/>}>
                <PropIcon />
              </Suspense>
            </a>
          </Wrapper>
        </ErrorBoundary>
      );
    };

    return (
      <StyledGrid container className={"footer-inferior"}>
        <StyledGrid item xs={12} md={12} lg={4} className={"footer-inferior__container"}>
          <StyledTypography variant={"bodyBaseline"} style={{ color: palette.gray["500"] }}>
            {props.data && props.data.title}
          </StyledTypography>
          <StyledBox className={"footer-inferior__container-icons"}>
            {props.data &&
              props.data.icons.map((data, index) => {
                return <SocialNetworkIcon key={index} icon={data.icon} url={data.url} />;
              })}
          </StyledBox>
        </StyledGrid>
        <StyledGrid item className={"footer-inferior__terms"} xs={12} md={12} lg={8}>
          <StyledTypography variant={"bodyBaseline"} style={{ color: palette.gray["500"] }}>
            {props.data && props.data.copyright}
          </StyledTypography>
        </StyledGrid>
      </StyledGrid>
    );
  };

  let viewHeaderCart = false;
  if (
    footerRoute === CHECKOUT ||
    footerRoute === PRINT_ORDER ||
    (footerRoute === PRO_LEAD_FORM || footerRoute.includes(PRO_LEAD_FORM + "/")) ||
    footerRoute === expiredLinkPath ||
    footerRoute === PRO_AR_FEEDBACK
  ) {
    viewHeaderCart = true;
  }



  return (
    <>
      <StyledFooterElementHDM style={viewHeaderCart ? { display: "none" } : {}}>
        <StyledContainer lg={5} style={viewHeaderCart ? { display: "none" } : {}}>
          {/* <FooterNewsletter
            data={
              isB2B ?
                getEspotFromProps("ProMainFooter_SuperiorSection", props.data)
                :
                getEspotFromProps("MainFooter_SuperiorSection", props.data)
            }
            icons={
              isB2B ?
                iconsComponent(getEspotFromProps("ProMainFooter_SuperiorSection", props.data))
                :
                iconsComponent(getEspotFromProps("MainFooter_SuperiorSection", props.data))
            }
          /> */}
          <StyledFooterNewsletter 
            espotName={isB2B ? "ProMainFooter_SuperiorSection": "MainFooter_SuperiorSection"}
            data={props.data}
          />
          <FooterAccordionsContainer />
          <FooterInferior data={
            isB2B ?
              getEspotFromProps("ProMainFooter_InferiorSection", props.data)
              :
              getEspotFromProps("MainFooter_InferiorSection", props.data)
          }
          />

        </StyledContainer>
      </StyledFooterElementHDM>

      <ConfirmationDialog />
      {!window.location.pathname.includes("/p/") && (
        <GtmLoaded isGTMLoaded={isGTMLoaded} setIsGTMLoaded={setIsGTMLoaded} isGTMPDP={false} />
      )}
    </>
  );
}

export default React.memo(StyledFooterHDM);
