import React, { FC } from "react";
//Components
import StyledListItemHDM from "./StyledListItemHDM";
//Data
import { MenuOptions } from "../../../../data";
import { StyledList } from "@hcl-commerce-store-sdk/react-component";
import EspotHdm from "../../shared/EspotHdm";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HTMLReactParser from "html-react-parser";

const StyledListHDM = ({ changeEspot, display, ...props }) => {
  const getContentAsStyledListItemHDM = (data) => {
    if (data.content) {
      const espotNum = data?.espot ? data.espot.slice(-1) : "";
      return <StyledListItemHDM cid={`mainMenu_is${espotNum}`} icon={<DashboardIcon />} title={HTMLReactParser(data.content)} setEspot={null} />;
    } else {
      return <></>;
    }
  };

  return (
    <StyledList component="nav" style={{ padding: 0, display: display ? "" : "none" }}>
      {props.menuComponents &&
        props.menuComponents.length > 0 &&
        getContentAsStyledListItemHDM(props.menuComponents[1])}

      <StyledListItemHDM
        icon={<DashboardIcon />}
        title={"Departamentos"}
        setEspot={() => changeEspot("Departamentos")}
        cid="Departamentos"
      />

      {props.menuComponents &&
        props.menuComponents.length > 0 &&
        props.menuComponents.map((espot, index) => {
          if (index <= 1) {
            return <></>;
          } else {
            return getContentAsStyledListItemHDM(espot);
          }
        })}
    </StyledList>
  );
};

export default StyledListHDM;
