import React from "react";
import { StyledTypography, StyledGrid } from "../../../hdm/elements";
import styled from "styled-components";
import StarIcon from "@material-ui/icons/Star";

const StyledRatingsStarsContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    display:flex;
  `}
`;

const StyledRatingsStars = (props) => {
  const stars = props.rating[2];
  return (
    <>
      <StyledRatingsStarsContainer>
        {[...Array(5).keys()].map((value) => {
          return (
            <>
              {React.Children.toArray(
                <StarIcon color={value + 1 <= stars ? "primary" : "disabled"} {...props} fontSize={"small"} />
              )}
            </>
          );
        })}

        <StyledTypography variant="bodyBaseline">
          &nbsp; {props?.inPDP === true ? "" : "y más"}{" "}
          {props?.count && props?.hits === true ? "(" + props.count + ")" : ""}{" "}
        </StyledTypography>
      </StyledRatingsStarsContainer>
    </>
  );
};

export default StyledRatingsStars;
