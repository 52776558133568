import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  Box, MenuItem, SvgIcon } from "@material-ui/core";
import { StyledBox, StyledGrid } from "../../../hdm";
import { StyledPopperMenu } from "../styled-popper-menu";

import { StyledLinkButton } from "../styled-store-selector/styled-link-button";

import { StyledShareButtonContainer } from "./StyledShareButtonContainer";
import styled from "styled-components";
import { Link } from "@material-ui/icons";
import ShareIcon from "@material-ui/icons/Share";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import * as successActions from "../../../redux/actions/success";

interface shareButtonProps {
  partNumber: string | number;
  className?: string;
  placement?: string;
  cid?: string;
}


const StyledShareButton: React.FC<shareButtonProps> = ({ ...props }) => {

  const { t } = useTranslation();
  const facebook = t("ShareButton.Facebook");
  const pinterest = t("ShareButton.Pinterest");
  const copiar = t("ShareButton.Copiar");
  const { partNumber, className, placement = "bottom-start", cid } = props;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [menuListOpen, setMenuListOpen] = useState(false);
  const [pinterestData, setPinterestData] = useState({url:"", media:"", description:""});
  const prevOpen = React.useRef(menuListOpen);
  const storeConfData = useSelector(onlineStoreRdc);
  const baseUrlImagesHDM = storeConfData?.IMAGE_SERVER_HOSTNAME;
  const dispatch = useDispatch();



  const ShareItemIcon = styled.img`
    width:25px;
    height:25px;
`;

useEffect(()=>{
  if(partNumber){
  
    const media =  baseUrlImagesHDM + 'productos/'+ partNumber +'/'+partNumber+".jpg";

    let url = 'https://www.homedepot.com.mx'
    if(window.location.hostname !== 'localhost'){
      url = window.location?.href
    }

    setPinterestData({url:encodeURI(url), media:encodeURI(media), description:encodeURI(document.title)})
    
  }

},[partNumber])



  const handleToggle = () => {
    if (!menuListOpen) {
      setMenuListOpen((prevOpen) => !prevOpen);
    }
  };

  const handlePinterest = useCallback(() => {
    const link = encodeURI(`https://www.pinterest.com/pin/create/button/?url=${pinterestData.url}&media=${pinterestData.media}&description=${pinterestData.description}`);
    window.open(link, '_blank');
  },[pinterestData]);

  const handleFacebook = useCallback(() => {
    const w = 450;
    const h = 600;
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=${w},height=${h},left=${left},top=${top}`;
    window.open(encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${document.URL}`), 'Facebook share', params);
  },[pinterestData]);


  const handleCopyLink = () => {
    navigator.clipboard.writeText(document.URL)

    const successMessage = {
      key: "success-message.COPY_URL",
      icon: "linkIcon",
      // messageParameters: { "0": wishListName.trim() },
    };

    dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
  }





  const MenuItems = () => {
    return (
      <>
        <MenuItem className="share-menu__link" onClick={() => handleFacebook()}>
          <StyledGrid className={"share--styledGrid-addCardTitleEdit"} alignItems="center">
            <ShareItemIcon src="https://cdn.homedepot.com.mx/assets/icons/thd-facebook-icon.svg"/>
            <StyledLinkButton variant="bodyBaseline">
              {facebook}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
        <MenuItem className="share-menu__link" onClick={() => handlePinterest()}>
          <StyledGrid className={"share--styledGrid-addCardTitleEdit"} alignItems="center">
          <ShareItemIcon src="https://cdn.homedepot.com.mx/assets/icons/thd-pinterest-icon.svg"/>
            <StyledLinkButton variant="bodyBaseline">
              {pinterest}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
        <MenuItem className="share-menu__link" onClick={handleCopyLink}>
          <StyledGrid className={"share--styledGrid-addCardTitleEdit"} alignItems="center">
            <StyledGrid alignItems="center" className={"padding-1 display-flex"}>
              <Link color="primary" className={"hdm-font-15"}/>
            </StyledGrid>
            <StyledLinkButton variant="bodyBaseline">
              {copiar}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
      </>
    );
  };

  return (
    <>
      <StyledShareButtonContainer id={cid} className={className}>
        <StyledBox id="" className="styledshare--svg-container" ref={anchorRef} onClick={handleToggle}>
          <ShareIcon />
          <Box className="styledshare--svg-line"></Box>
        </StyledBox>
        <StyledPopperMenu
          MenuItems={MenuItems}
          anchorRef={anchorRef}
          menuListOpen={menuListOpen}
          setMenuListOpen={setMenuListOpen}
          prevOpen={prevOpen}
          placement={placement}
        />
      </StyledShareButtonContainer>
    </>
  );
};
export { StyledShareButton };
