//Standard libraries
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { StyledCheckbox, StyledGrid, StyledMenuItem, StyledSwitch, StyledTypography } from "../../../hdm";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { orderItemsSelector } from "../../../redux/selectors/order";

import { checkOutStepRdc } from "../../../redux/selectors/hdmData";
import { StyledSelectHdm } from "../../../components/custom-components/styled-select";
import { CONSTANTS } from "../../../constants/order-item-table";
/**
 * Address card display component
 * displays the details of a single address
 * @param props
 */
const DeliveryInfoCard: React.FC<any> = (props: any) => {
  const orderItems = useSelector(orderItemsSelector);
  const stepActiveSelector = useSelector(checkOutStepRdc);
  const stepActive = stepActiveSelector ? parseInt(stepActiveSelector.currentStep) : 0;
  const { t } = useTranslation();
  const readOnlyShipping = props.readOnlyShipping ? props.readOnlyShipping : false;
  const [useMultipleShipment, setUseMultipleShipment] = useState<boolean>(false);
  let totalProducts = 0;
  const variant = props.variant ? props.variant : "checkout";
  const installmentOptions = ["Selecciona un plazo", "3 meses", "6 meses", "9 meses", "12 meses"];
  const handleChangeMSI = props.handleChangeMSI ? props.handleChangeMSI : null;
  const installmentData = props.installmentData ? props.installmentData : null;
  const deliveryMethod = props.deliveryMethod;
  const handleMultipleShipmentChange = () => {
    setUseMultipleShipment(!useMultipleShipment);
  };
  if (orderItems && orderItems.length > 0) {
    for (let i = 0; i < orderItems.length; i++) {
      totalProducts += parseInt(orderItems[i].quantity);
    }
  }
  const type = props.type;
  const deliveryLabel =
    type === "bopis" ? t("Shipping.Labels.ShippingDateBopis") : t("Shipping.Labels.ShippingDateDelivery");
  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const itemMSI =
    installmentOptions && installmentOptions.length > 0 ? (
      installmentOptions.map((installment, index) => (
        <StyledMenuItem value={installment} key={installment} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{installment}</StyledTypography>
        </StyledMenuItem>
      ))
    ) : (
      <StyledMenuItem value={""} className={"styledSelect--menuItems-color"} fullWidth>
        <StyledTypography variant="bodyBaseline">{t("AddressForm.Labels.Colony")}</StyledTypography>
      </StyledMenuItem>
    );
  return (
    <>
      {readOnlyShipping && (
        <>
          {variant === "checkout" && (
            <>
              <StyledGrid xs={12} className={"vertical-margin-2"}>
                <Divider />
              </StyledGrid>
            </>
          )}
          {type === "delivery" && (
            <>
              <StyledGrid xs={12} className={"top-margin-2 bottom-margin-2"}>
                <StyledTypography variant="bodyBaseline" component={"span"}>
                  {t("Shipping.Labels.ShippingCharge") + " "}
                </StyledTypography>
                <StyledTypography
                  variant="bodyBaseline"
                  component={"span"}
                  className={"deliveryInfoCard-styledTypography-highlight"}>
                  {t("Shipping.Labels.FreeShippingCharge")}
                </StyledTypography>
              </StyledGrid>
              {variant === "checkout" && stepActive === 1 && (
                <>
                  <StyledGrid item xs={12} className={"bottom-margin-2 deliveryInfoCard-styledGrid-checkboxDelivery"}>
                    <StyledCheckbox
                      checked={useMultipleShipment}
                      onChange={() => handleMultipleShipmentChange()}
                      color={"primary"}
                    />
                    <StyledTypography variant="bodyBaseline" color="secondary">
                      {t("Shipping.Labels.MultipleShippingSwitch") + " "} DD de MM YYYY
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
              <StyledGrid xs={12} className={"bottom-margin-2"}>
                <Divider />
              </StyledGrid>
            </>
          )}
          <StyledGrid xs={12} style={{ display: "flex" }}>
            {variant === "checkout" && (
              <>
                <StyledGrid xs={6}>
                  <StyledTypography variant="bodyBaseline" component={"span"}>
                    {deliveryLabel}
                  </StyledTypography>
                </StyledGrid>
                <Divider orientation="vertical" flexItem />
                <StyledGrid xs={6} className={"left-padding-2"}>
                  <StyledTypography variant="bodyBaseline" color="primary" weight="semibold" component={"span"}>
                    DD de MM YYYY
                  </StyledTypography>
                </StyledGrid>
              </>
            )}{" "}
            {variant === "orderResume" && (
              <StyledGrid xs={12}>
                <StyledTypography variant="bodyBaseline" component={"span"}>
                  {deliveryLabel}:
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" color="primary" weight="semibold" component={"span"}>
                  {" "}
                  DD de MM YYYY
                </StyledTypography>
              </StyledGrid>
            )}
          </StyledGrid>
          {((variant === "checkout" && stepActive === 1) || variant === "payment") && (
            <>
              {React.Children.toArray(
                orderItems.map((orderItem: any, index) => (
                  <>
                    {((type === "bopis" && orderItem.carrier === CONSTANTS.bopis) ||
                      (type !== "bopis" && orderItem.carrier === CONSTANTS.delivery)) && (
                      <>
                        {(variant === "checkout" || variant === "orderResume") && (
                          <StyledGrid xs={12} className={"top-margin-2 bottom-margin-2"}>
                            <Divider />
                          </StyledGrid>
                        )}
                        <StyledGrid xs={12}>
                          <StyledTypography
                            variant="bodySubText"
                            weight="semibold"
                            component={"span"}
                            className={"deliveryInfoCard-styledTypography-customSize"}>
                            {t("DeliveryInfoCard.Brand") + " "}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            component={"span"}
                            className={"deliveryInfoCard-styledTypography-customSize"}>
                            {orderItem.manufacturer + " " + orderItem.name}
                          </StyledTypography>
                        </StyledGrid>
                        <StyledGrid xs={12}>
                          <StyledTypography
                            variant="bodySubText"
                            component={"span"}
                            className={
                              "deliveryInfoCard-styledTypography-customSize deliveryInfoCard-styledTypography-darkGray"
                            }>
                            {t("DeliveryInfoCard.Price") + " "}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            weight={"semibold"}
                            component={"span"}
                            className={"deliveryInfoCard-styledTypography-customSize"}>
                            {formatter.format(orderItem.orderItemPrice)}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            component={"span"}
                            className={
                              "left-padding-2 deliveryInfoCard-styledTypography-customSize deliveryInfoCard-styledTypography-darkGray"
                            }>
                            {t("DeliveryInfoCard.Quantity") + " "}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            weight={"semibold"}
                            component={"span"}
                            className={"deliveryInfoCard-styledTypography-customSize"}>
                            {parseInt(orderItem.quantity)}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            component={"span"}
                            className={
                              "left-padding-2 deliveryInfoCard-styledTypography-customSize deliveryInfoCard-styledTypography-darkGray"
                            }>
                            {t("DeliveryInfoCard.Subtotal") + " "}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodySubText"
                            weight={"semibold"}
                            component={"span"}
                            className={"deliveryInfoCard-styledTypography-customSize"}>
                            {formatter.format(orderItem.orderItemPrice * orderItem.quantity)}
                          </StyledTypography>
                        </StyledGrid>
                        {variant === "payment" && (
                          <>
                            <StyledGrid xs={12}>
                              <StyledSelectHdm
                                items={itemMSI}
                                label={"Plazos"}
                                name={orderItem.orderItemId + "installment"}
                                handleChange={handleChangeMSI}
                                value={
                                  installmentData[orderItem.orderItemId + "installment"]
                                    ? installmentData[orderItem.orderItemId + "installment"]
                                    : installmentOptions[0]
                                }
                              />
                            </StyledGrid>
                            {orderItems.length - 1 > index && (
                              <StyledGrid xs={12} className={"top-margin-2 bottom-margin-2"}>
                                <Divider />
                              </StyledGrid>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ))
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export { DeliveryInfoCard };
