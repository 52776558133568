/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
//Custom libraries
import { AddressCard } from "../address-card";
import { useTranslation } from "react-i18next";
//UI
import { Hidden, useMediaQuery } from "@material-ui/core";
import { StyledTypography, StyledGrid } from "../../../hdm";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { tabletBreakpoint } from "../../functions/utils";

interface AddressListProps {
  cid: string;
  addressList: any[];
  setSelectedAddressId?: (v1?: any, v2?: any) => void; //selected address setter
  selectedAddressId?: string;
  selectedNickName?: string;
  actions?: any[];
  type?: boolean;
  hideEdit?: boolean;
  readOnlyShipping?: boolean;
  handleReadOnlyShipping?: any;
  variant?: string;
  deliveryMethod?: string;
  fromMyAccount?: boolean;
}

const StyledAddressListTitle = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      color: ${theme.palette.gray["300"]};
      padding: ${theme.spacing(1)}px 0px;
      
    `}
`;

/**
 * Address list display component
 * displays list of addresses
 * @param props
 */
const AddressList: React.FC<AddressListProps> = (props: any) => {
  const { t } = useTranslation();
  const { addressList, hideEdit, selectedNickName } = props;
  const setSelectedAddressId = props.setSelectedAddressId;
  const selectedAddressId = props.selectedAddressId;
  const actions = props.actions ? props.actions : false;
  const type = props.type ? props.type : false;
  const readOnlyShipping = props.readOnlyShipping ? props.readOnlyShipping : false;
  const handleReadOnlyShipping = props.handleReadOnlyShipping ? props.handleReadOnlyShipping : false;
  const variant = props.variant ? props.variant : "checkout";
  const deliveryMethod = props.deliveryMethod;
  const fromMyAccount = props.fromMyAccount;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  let mdSpace;
  if (readOnlyShipping) {
    mdSpace = 12;
  } else {
    mdSpace = 6;
  }
  
  return (
    <StyledGrid container spacing={2}>
      {addressList?.map((address: any) => {
        /*if the current address id is the selected one, will render*/
        if (address.addressId === selectedAddressId) {
          return (
            <>
              <StyledGrid item xs={12} md={mdSpace}>
                {fromMyAccount ? (
                  <StyledAddressListTitle item xs={12}>
                    <StyledTypography variant="bodySubText">{t("Payment.Labels.billingAddressList")}</StyledTypography>
                  </StyledAddressListTitle>
                ) : null}
                <StyledGrid item xs={12} key={address.nickName}>
                  <AddressCard
                    addressId={address.addressId}
                    nickName={address.nickName}
                    actions={actions}
                    type={type}
                    setSelectedAddressId={setSelectedAddressId}
                    selectedAddressId={selectedAddressId}
                    hideEdit={hideEdit}
                    readOnly={readOnlyShipping}
                    handleReadOnlyShipping={handleReadOnlyShipping}
                    variant={variant}
                    deliveryMethod={deliveryMethod}
                    fromMyAccount={fromMyAccount}
                    {...{ selectedNickName }}
                  />
                </StyledGrid>
              </StyledGrid>
            </>
          );
        } else {
          return null;
        }
      })}
      {!readOnlyShipping && (
        <>
          <StyledGrid item xs={12} md={6}>
            {fromMyAccount && !isMobile && addressList?.length > 1 ? (
              <StyledAddressListTitle item xs={12}>
                <StyledTypography variant="bodySubText">{t("Shipping.Labels.ShippingOtherAddress")}</StyledTypography>
              </StyledAddressListTitle>
            ) : null}
            {addressList?.map((address: any) => {
              /*if the current address id is not equals to the selected, will render*/
              if (address.addressId !== selectedAddressId) {
                return (
                  <StyledGrid item xs={12} key={address.nickName}>
                    <AddressCard
                      addressId={address.addressId}
                      nickName={address.nickName}
                      actions={actions}
                      type={type}
                      setSelectedAddressId={setSelectedAddressId}
                      selectedAddressId={selectedAddressId}
                      hideEdit={hideEdit}
                      readOnly={readOnlyShipping}
                      {...{ selectedNickName }}
                    />
                  </StyledGrid>
                );
              } else {
                return null;
              }
            })}
          </StyledGrid>
        </>
      )}
    </StyledGrid>
  );
};

export { AddressList };
