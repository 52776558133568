import { Dimension } from "../dimensions";

export const SLShoppingCart = {
  details: {
    title: new Dimension(144.11, 21.6),
    subtitle: new Dimension(348.88, 14.4),
    product: {
      delete: new Dimension(270, 30),
      info: {
        image: new Dimension(60, 60),
        brand: new Dimension(53, 22),
        name: new Dimension(330, 22),
        sku: new Dimension(80, 16),
      },
      price: {
        total: new Dimension(100, 40),
        subtotal: new Dimension(100, 40),
      },
      available: new Dimension(380, 60),
      homeDelivery: new Dimension(190, 40),
      bopis: new Dimension(180, 40),
    },
  },
  shippingMethod: {
    title: new Dimension(265, 280),
    subtitle: new Dimension(265, 280),
    homeDelivery: new Dimension(190, 40),
    bopis: new Dimension(180, 40),
  },
  deliveryZone: {
    title: new Dimension(265, 280),
    zone: new Dimension(265, 280),
    changeButton: new Dimension(265, 280),
  },
  summary: {
    title: new Dimension(265, 280),
    subtotal: new Dimension(265, 280),
    subtotalMessage: new Dimension(265, 280),
    subtotalPrice: new Dimension(265, 280),
    total: new Dimension(265, 280),
    totalSubtitle: new Dimension(265, 280),
    totalPrice: new Dimension(265, 280),
    continueButton: new Dimension(265, 280),
  },
};
