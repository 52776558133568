import { ChangeEvent, useEffect, useState } from "react";
import { StyledBox, StyledTextField } from "../../../hdm/elements"

import { InputAdornment } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { palette } from "../../../hdm/themes";
import { EMPTY_STRING } from "../../../constants/common";
import styled from "styled-components";
import { useSite } from "../../../_foundation/hooks/useSite";
import { tabletBreakpoint } from "../../../hdm/functions/utils";

const specialCharacters = /[-=_*?!@#S/(){}.,;:]/;
const upperCaseAlphabetLetters = /[A-ZÑ]/;
const lowerCaseAlphabetLetters = /[a-zñ]/;
const anyNumber = /\d/;

const PasswordNewPasswordsContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

    
    .styled--showpassword-icon {
        cursor: pointer;
        width: 20px;
        height: auto;
    }

    .styled-password-input {
        width: calc(50% - 10px);
        margin-bottom: 10px;
        margin-bottom: 10px;
         ${theme.breakpoints.down(tabletBreakpoint())} {
            width: 100%;
        }
    }
        
    .styled-confirmpassword-input {
        width: calc(50% - 10px);
        margin-bottom: 10px;
        margin-left: 20px;
         ${theme.breakpoints.down(tabletBreakpoint())} {
            width: 100%;
            margin-left: 0px;
        }
    }
    
  `}
`;

export const PasswordNewPasswords = (props) => {
    // {console.log("PROPS",props)}
    const { mySite } = useSite();
    const isB2B: boolean = mySite?.isB2B;

    const { 
        setErrorPasswordUsed 
    } = props;
    const { handlePasswordValidations, passwordValidations, handleCanContinueNewPassword ,errorPasswordUsed, isProForgotPasswprd} = props

    const [passwordNew, setNewPassword] = useState<string>("");
    const [passwordVerify, setVerifyPassword] = useState<string>("");

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);

    const [errorPassword, setErrorPassword] = useState({error: false, errorMsg: ''})
    const [errorPasswordVerify, setErrorPasswordVerify] = useState({error: false, errorMsg: ''})
    const [errorNewPassword, setErrorNewPassword] = useState({error: false, errorMsg: ''})

    useEffect(() => {
        canContinueNewPassword()
        //errorVerify()
    }, [passwordNew, passwordVerify])
    

    // const [passwordValidations, setPasswordValidations] = useState({
    //     caracteresMin8: false,
    //     lowerCaseLetter: false,
    //     upperCaseLetter: false,
    //     anyNumber: false,
    //     specialChar: false
    // });

    const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
        const val: string | null = evt.target.value;
        setNewPassword(val);
        handlePasswordValidations({
            caracteresMin8: val.length >= 8 ? true : false,
            lowerCaseLetter: lowerCaseAlphabetLetters.test(val) ? true : false,
            upperCaseLetter: upperCaseAlphabetLetters.test(val) ? true : false,
            anyNumber: anyNumber.test(val) ? true : false,
            specialChar: specialCharacters.test(val) ? true : false
        })
        // setPasswordValidations({
        //     caracteresMin8: val.length >= 8 ? true : false,
        //     lowerCaseLetter: lowerCaseAlphabetLetters.test(val) ? true : false,
        //     upperCaseLetter: upperCaseAlphabetLetters.test(val) ? true : false,
        //     anyNumber: anyNumber.test(val) ? true : false,
        //     specialChar: specialCharacters.test(val) ? true : false
        // })
        setErrorPassword({
            error: false,
            errorMsg: ''
        })
        
        if(passwordNew.trim() !== "" && passwordVerify.trim() !== "") {
            if(evt.target.value.trim() !== passwordVerify.trim()){
                setErrorPasswordVerify({
                    error: false,
                    errorMsg: ''
                })
            }else{
                setErrorPasswordVerify({
                    error: false,
                    errorMsg: ''
                })
            }
        }
    };

    const handleVerifyPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
        const val: string | null = evt.target.value;
        console.log("VALUES ",val)
        setVerifyPassword(val);
        setErrorPasswordVerify({
            error: false,
            errorMsg: ''
        })

        setErrorPasswordUsed({
            error: false,
            errorMsg: `""`
        })
    };

    const handleValReq = () => {

        if(
            !passwordValidations.caracteresMin8 
            || !passwordValidations.lowerCaseLetter
            || !passwordValidations.upperCaseLetter
            || !passwordValidations.anyNumber
            || !passwordValidations.specialChar
        ){
            setErrorPassword({
                error: true,
                errorMsg: 'Tu contraseña debe cumplir con todos los requisitos'
            })
        }else{
            setErrorPassword({
                error: false,
                errorMsg: ''
            })
        }
        props.handleValReq(true)

        if(passwordNew.trim() !== "" && passwordVerify.trim() !== "") {
            if(passwordNew.trim() !== passwordVerify.trim()){
                setErrorPasswordVerify({
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden, inténtalo de nuevo'
                })
            }else{
                setErrorPasswordVerify({
                    error: false,
                    errorMsg: ''
                })
            }
        }
    }

    const handleComparePassword = () => {
        if(passwordNew.trim() !== passwordVerify.trim()){
            setErrorPasswordVerify({
                error: true,
                errorMsg: 'Las contraseñas no coinciden, inténtalo de nuevo'
            })
        }else{
            setErrorPasswordVerify({
                error: false,
                errorMsg: ''
            })
        }
    }

   /* const errorVerify = () => {
        {console.log("ERRORR PROPS")}
        if(error){
            {console.log("ERRORR PROPS")}
/*            setErrorPasswordVerify({
                error: error,
                errorMsg: helperText
            })
        }
    }*/

    const canContinueNewPassword = () => {
        let isOk = false
        if (
          passwordNew.trim() !== EMPTY_STRING &&
          passwordVerify.trim() !== EMPTY_STRING
        ) {

            if(
                passwordValidations.caracteresMin8 
                && passwordValidations.lowerCaseLetter
                && passwordValidations.upperCaseLetter
                && passwordValidations.anyNumber
                && passwordValidations.specialChar
            ){
                if(passwordNew.trim() === passwordVerify.trim()){
                    console.log("CAN CONITNUE NEW PASSWORD")
                    isOk = true;
                }
            }

        }
        return handleCanContinueNewPassword({isOk, data: {passwordNew, passwordVerify}});
    };


    const handleShowPassword = () => {
        setShowPassword( prev => prev ? false : true);
    }

    const handleShowVerifyPassword = () => {
        setShowVerifyPassword( prev => prev ? false : true);
    }

    return (
        <PasswordNewPasswordsContainer>
        <StyledTextField
              margin="normal"
              fullWidth
              label={isProForgotPasswprd ? "Nueva contraseña" : "Contraseña"}
              type={showPassword ? "text" : "password"}
              placeholder=""
              name="logonPassword"
              onChange={(e) => handleNewPasswordChange(e, "logonPassword")}
              onBlur={handleValReq}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                                {showPassword 
                                ? <VisibilityOffOutlinedIcon onClick={handleShowPassword} style={{color: palette.gray["300"]}} className="styled--showpassword-icon"/> 
                                : <VisibilityOutlinedIcon onClick={handleShowPassword} style={{color: palette.gray["300"]}}  className="styled--showpassword-icon"/>}
                              </InputAdornment>
              }}
              error={errorPassword.error || props.errorPasswordUsed.error}
              helperText={errorPassword.errorMsg}
              className={`${isProForgotPasswprd ? "styled-password-input" : ""}`}
            />
            <StyledTextField
              margin="normal"
              fullWidth
              label="Confirmar contraseña"
              type={showVerifyPassword ? "text" : "password"}
              placeholder=""
              name="logonPasswordVerify"
              onChange={(e) => handleVerifyPasswordChange(e, "logonPasswordVerify")}
              onBlur={handleComparePassword}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                                {showVerifyPassword 
                                ? <VisibilityOffOutlinedIcon onClick={handleShowVerifyPassword} style={{color: palette.gray["300"]}} className="styled--showpassword-icon"/> 
                                : <VisibilityOutlinedIcon onClick={handleShowVerifyPassword} style={{color: palette.gray["300"]}} className="styled--showpassword-icon"/>}
                              </InputAdornment>
              }}
              error={errorPasswordVerify?.error ? errorPasswordVerify.error : errorPasswordUsed?.error ? errorPasswordUsed.error : false}
              
              helperText={errorPasswordVerify?.error ? errorPasswordVerify.errorMsg : errorPasswordUsed?.error ? errorPasswordUsed.errorMsg : ''}
              className={`${isProForgotPasswprd ? "styled-confirmpassword-input" : ""}`}
            />
        </PasswordNewPasswordsContainer>
    )
}