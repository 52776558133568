import { StyledAccordion, StyledBox, StyledCheckbox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { SLFooterElements } from "../dimensions/Footer";
import { SLPLP } from "../dimensions/PLP";
import { StyledSkeleton } from "../StyledSkeleton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const SLPLPFacet = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!isMobile) {
    return (
      <>
        <StyledAccordion defaultExpanded={true} square={false} style={{paddingRight:"0px"}}>
          <StyledGrid container spacing={2} justifyContent="space-between">
            <StyledGrid item xs={8}>
              <StyledSkeleton
                variant="rect"
                width={SLPLP.facet.title.getWidth()}
                height={SLPLP.facet.title.getHeight()}
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledGrid container justifyContent="flex-end">
                <StyledGrid>
                  <StyledSkeleton
                    variant="rect"
                    className={"right-margin-1"}
                    width={SLPLP.facet.shrinker.getWidth()}
                    height={SLPLP.facet.shrinker.getHeight()}
                  />
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          <StyledBox>
            {Array.from(Array(6).keys()).map((key) => (
              <StyledGrid container className={"vertical-margin-1"}>
                <StyledGrid item className={"right-margin-2"} xs={1}>
                  <StyledCheckbox disabled />
                </StyledGrid>
                <StyledGrid item xs={10}>
                  <StyledSkeleton
                    key={key}
                    variant="rect"
                    width={isMobile ? SLPLP.facet.elementMobile.getWidth() : SLPLP.facet.element.getWidth()}
                    height={SLPLP.facet.element.getHeight()}
                  />
                </StyledGrid>
              </StyledGrid>
            ))}
          </StyledBox>
        </StyledAccordion>
      </>
    );
  } else {
    return null;
  }
};

export const SLPLPCard = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("xs")) {
    const breakpoint = theme.breakpoints.down("xs").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }

  const stylesDesktop = { maxWidth: "285px", marginLeft:"2px" };
  const stylesMobile = { width: "100%" };

  return (
    <>
      {!isMobile && (
        <StyledAccordion defaultExpanded={true} square={false} style={isMobile ? stylesMobile : stylesDesktop}>
          <StyledGrid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={"bottom-margin-1"}
            xs={12}>
            <StyledSkeleton
              variant="rect"
              width={SLPLP.productCard.image.getWidth()}
              height={SLPLP.productCard.image.getHeight()}
            />
          </StyledGrid>

          <StyledBox>
            <StyledSkeleton
              className={"vertical-margin-1"}
              variant="rect"
              width={SLPLP.productCard.title.getWidth()}
              height={SLPLP.productCard.title.getHeight()}
            />
            <StyledSkeleton
              className={"bottom-margin-1"}
              variant="rect"
              width={SLPLP.productCard.rating.getWidth()}
              height={SLPLP.productCard.rating.getHeight()}
            />
            <StyledSkeleton
              className={"vertical-margin-6"}
              variant="rect"
              width={SLPLP.productCard.price.getWidth()}
              height={SLPLP.productCard.price.getHeight()}
            />
            <StyledSkeleton
              className={"vertical-margin-1"}
              variant="rect"
              width={SLPLP.productCard.compare.getWidth()}
              height={SLPLP.productCard.compare.getHeight()}
            />
            <StyledSkeleton
              className={"vertical-margin-2"}
              variant="rect"
              width={SLPLP.productCard.addToCart.getWidth()}
              height={SLPLP.productCard.addToCart.getHeight()}
            />
          </StyledBox>
        </StyledAccordion>
      )}
      {isMobile && (
        <StyledGrid item xs={12}>
          <StyledAccordion>
            <StyledGrid container>
              <StyledGrid item xs={5}>
                <StyledSkeleton variant="rect" width={"100%"} height={150} />
              </StyledGrid>
              <StyledGrid item xs={7} spacing={2} className={"left-padding-1"}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={32} />
                <StyledSkeleton variant="rect" className={"bottom-margin-1"} width={"50%"} height={20} />
                <StyledSkeleton variant="rect" width={"25%"} height={20} />
              </StyledGrid>
              <StyledGrid item xs={12} spacing={2} className={"top-margin-20"}>
                <StyledSkeleton variant="rect" className={"bottom-margin-1"} width={130} height={35} />
              </StyledGrid>
              <StyledGrid item xs={5} className={"top-margin-10 bottom-margin-1"}>
                <StyledSkeleton variant="rect" width={"100%"} height={42} />
              </StyledGrid>
              <StyledGrid item xs={7} spacing={2} className={"top-margin-10 bottom-margin-1 left-padding-1"}>
                <StyledSkeleton variant="rect" className={"wide"} height={42} />
              </StyledGrid>
            </StyledGrid>
          </StyledAccordion>
        </StyledGrid>
      )}
    </>
  );
};

export const SLPLPSearchTerm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <StyledSkeleton
        className={!isMobile ? "vertical-margin-0 right-margin-2 left-margin-1" : "left-margin-4 right-margin-4"}
        noCard
        variant="rect"
        width={isMobile ? SLPLP.searchTitle.mobile.getWidth() : SLPLP.searchTitle.desktop}
        height={SLPLP.searchTitle.mobile.getHeight()}
      />
    </>
  );
};
const SLPLPSortResults = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledSkeleton
      className={!isMobile ? "bottom-margin-2" : "left-margin-4 right-margin-4 vertical-margin-2"}
      noCard
      variant="rect"
      width={isMobile ? SLPLP.sortByMobile : SLPLP.sortBy.getWidth()}
      height={SLPLP.sortBy.getHeight()}
    />
  );
};
// const SLPLPTopComponent = () => {
//   return (
//     <StyledGrid container>
//       <StyledGrid className={"top-margin-3 bottom-margin-7"} container item xs={6}>
//         <SLPLPSearchTerm />
//       </StyledGrid>
//       <StyledGrid container item xs={6} direction="row-reverse">
//         <SLPLPSortResults />
//       </StyledGrid>
//     </StyledGrid>
//   );
// };
const SLPLPSearchTermResults = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <StyledSkeleton
        className={!isMobile ? "vertical-margin-0" : "left-margin-4"}
        noCard
        variant="rect"
        width={isMobile ? SLPLP.searchTitleCount.mobile.getWidth() : SLPLP.searchTitleCount.desktop.getWidth()}
        height={SLPLP.searchTitleCount.desktop.getHeight()}
      />
    </>
  );
};
const SLPLPTopComponentLayout = (props) => {
  return (
    <StyledGrid container className={ (!props.isMobile?" top-margin-7 ":"")} >

      <StyledGrid className={"bottom-margin-2"} container item xs={12} sm={12} md={6}>
        <SLPLPSearchTerm />
      </StyledGrid>
      <StyledGrid className={"bottom-margin-2"} container item xs={12} sm={12} md={2}>
        <SLPLPSearchTermResults />
      </StyledGrid>
      <StyledGrid container item xs={12} sm={12} md={4} direction="row-reverse">
        <SLPLPSortResults />
      </StyledGrid>
    </StyledGrid>
  );
};
const SLPLPTopComponent = (props) => {
  return (
    <StyledGrid container className={ (!props.isMobile?" top-margin-6 ":"")} >


      <StyledGrid className={"bottom-margin-2"} container item xs={12} sm={12} md={6}>
        <SLPLPSearchTerm />
      </StyledGrid>
      <StyledGrid className={"bottom-margin-2"} container item xs={12} sm={12} md={2}>
        <SLPLPSearchTermResults />
      </StyledGrid>
      <StyledGrid container item xs={12} sm={12} md={4} direction="row-reverse">
        <SLPLPSortResults />
      </StyledGrid>
    </StyledGrid>
  );
};

//PDP cards
export const SLPLPProductCards = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("xs")) {
    const breakpoint = theme.breakpoints.down("xs").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }
  const stylesMobile = { width: "100%", flexBasis: "auto", marginLeft: "15px", marginRight: "15px" };
  return (
    <StyledGrid container spacing={2} direction="row" className={"contenedorProductList"} >
      <StyledGrid container justifyContent={"space-between" + (!isMobile?" top-margin-4 ":"")}>
        <SLPLPTopComponentLayout isMobile={isMobile} />
      </StyledGrid>
      {Array.from(Array(12).keys()).map((key) => (
        <StyledGrid key={key} item xs={12} sm={12} md={6} lg={3} style={isMobile ? stylesMobile : {}}>
          <SLPLPCard/>
        </StyledGrid>
      ))}
      <StyledGrid container direction="column" justifyContent="center" alignItems="center">
        <StyledGrid item xs={4}>
          <StyledSkeleton variant="rect" width={SLPLP.paging.getWidth()} height={SLPLP.paging.getHeight()} />
        </StyledGrid>
        <StyledGrid item xs={4}>
          <StyledSkeleton variant="rect" width={SLPLP.pageResults.getWidth()} height={SLPLP.pageResults.getHeight()} />
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export const SLPLPFacets = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!isMobile) {
    return (
      <StyledGrid container spacing={2}>
        {Array.from(Array(6).keys()).map((key) => (
          <StyledGrid key={key} item xs={12}>
            <SLPLPFacet />
          </StyledGrid>
        ))}
      </StyledGrid>
    );
  } else {
    return null;
  }
};

export const SLPLPLayoutSlotTwo = () => {
  return (
    <>
      <SLPLPProductCards />
    </>
  );
};

export const SLPLPLayoutSlotTwoComponent = () => {
  return (
    <>
      <SLPLPProductCardsComponent />
    </>
  );
};

//PDP cards
const SLPLPProductCardsComponent = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("xs")) {
    const breakpoint = theme.breakpoints.down("xs").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }
  const stylesMobile = { width: "100%", flexBasis: "auto", marginLeft: "15px", marginRight: "15px" };
  return (
    <StyledGrid container spacing={2} direction="row" className={"contenedorProductList left-padding-1 right-padding-1"} >
      <StyledGrid container justifyContent={"space-between" +  (!isMobile?" top-margin-3 ":"")}>
        <SLPLPTopComponent isMobile={isMobile}/>
      </StyledGrid>
      {Array.from(Array(12).keys()).map((key) => (
        <StyledGrid key={key} item xs={12} sm={12} md={6} lg={3} style={isMobile ? stylesMobile : {}}>
          <SLPLPCard />
        </StyledGrid>
      ))}
      <StyledGrid container direction="column" justifyContent="center" alignItems="center">
        <StyledGrid item xs={4}>
          <StyledSkeleton variant="rect" width={SLPLP.paging.getWidth()} height={SLPLP.paging.getHeight()} />
        </StyledGrid>
        <StyledGrid item xs={4}>
          <StyledSkeleton variant="rect" width={SLPLP.pageResults.getWidth()} height={SLPLP.pageResults.getHeight()} />
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};



export const SLPLPBreadCrumbComponent = (props) => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }
  const nCards = isMobile ? 2 : 3;
  const desktopStyle = !props.padding ? "vertical-margin-2 left-margin-1" : "vertical-margin-2 left-padding-1"

  return (
    <StyledGrid
      container
      className={isMobile ? "vertical-margin-2 left-margin-4 right-margin-2" : desktopStyle}
      xs={12}
      direction="row"
      justifyContent="flex-start"
      spacing={2}>
      <StyledSkeleton noCard variant="rect" width={isMobile ? "24%" : 100} height={32} />
      {Array.from(Array(nCards).keys()).map((key) => (
        <>
          <ArrowRightIcon className={"horizontal-margin-2 top-margin-1"} fontSize="small" />
          <StyledSkeleton noCard variant="rect" width={isMobile ? "25%" : 100} height={32} />
        </>
      ))}
    </StyledGrid>
  );
};

export const SLPLPLayoutWithBreadCrumb = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }

  return (
    <div className="page top-padding-1" >
      <StyledContainer id={"plp-loader"}>
        <StyledGrid container spacing={2} >
          <SLPLPBreadCrumbComponent />
          <StyledGrid item xs={12} md={3} className={"sidebar facets--styledGrid-sideBar" + (!isMobile?" top-margin-6 ":"")}>
            {/* <SLPLPFacets /> */}
            {!isMobile && 
                <SLPLPFacets />
            }
          </StyledGrid>
          <StyledGrid item xs={12} md={3} className="productList--styledGrid-container">
            <SLPLPLayoutSlotTwo />
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </div>

  );
};
export const SLPLPLayout = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }

  return (
    <StyledContainer id={"plp-loader"} className={" top-margin-1"}>
      <StyledGrid container spacing={2}>
        <StyledGrid item xs={12} md={3} className="sidebar facets--styledGrid-sideBar top-margin-6">
          {/* <SLPLPFacets /> */}
          {!isMobile && <SLPLPFacets />}
        </StyledGrid>
        <StyledGrid item xs={12} md={3} className="productList--styledGrid-container">
          <SLPLPLayoutSlotTwo />
        </StyledGrid>
      </StyledGrid>
    </StyledContainer>
  );
};
