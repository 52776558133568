import { ChangeEvent, Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { StyledBox, StyledButton, StyledIconButton, StyledTextField, StyledTooltip } from "../../elements";
import HelpIcon from "../../../assets/homedepot/images/HelpIcon.js";
import { ClickAwayListener, Divider, InputAdornment } from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";
import { palette } from "../../themes";
import { lineaGamaColorsRdc, hdmColorsRdc, hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { StyledTooltipHdm } from "../../../components/custom-components/styled-tooltip-hdm";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { useSite } from "../../../_foundation/hooks/useSite";
import { SEARCH_CODE_PAINT_REGEX } from "../../functions/utils";

const DarkTooltipInput = withStyles({
  tooltip: {
    backgroundColor: "#EEEEEE !important",
    color: "#333",
    borderRadius: "0px",
    width: "100px",
    marginTop: "0px",
    marginRight: "65px",
    borderTop: "2px solid " + palette.primary.main,
  },
})(StyledTooltip);

const StyledHelpIcon = withStyles({
  root: {
    color: palette.primary.main,
    width: "14px",
    height: "14px",
    marginRight: "-25px",
  },
})(HelpIcon);

const ContainerSearchColor = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    .MuiInputBase-root {
      padding-right: 0px !important;
    }

    .MuiIconButton-root {
      padding: 5px;
    }

    .styled--searchcolor-container {
        display: flex;
        flex-direction: row;
    }

    .styled--search-input {
        width: calc(100% - 115px)
    }

    .styled--search-button {
        width: 110px;
        margin-left: 5px;
        margin-top:9px;

        border: 2px solid ${theme.palette.gray[200]};
        color: ${theme.palette.gray[200]};
        &:hover {
          border: 2px solid ${theme.palette.gray[400]};
          color: ${theme.palette.gray[400]};
          background-color: #ffffff;
        }

        &.MuiButton-root {
            margin-bottom: 0px;
        }
    }

    .styled--tooltip-button {
        margin-top: -20px;
        margin-right: 5px;
    }
`}
`;

export const SearchColor = () => {
  const { t } = useTranslation();
  const [colorCode, setColorCode] = useState("");
  const hdmColors = useSelector(hdmColorsRdc);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const dispatch = useDispatch<Dispatch<any>>();
  const [errorSearchColor, setErrorSearchColor] = useState<any>({ error: false, errorMsg: "" });
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const isMobile = false; //useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = event.target.value;
    if(val.trim() === ""){
      setColorCode(val)
    }else if(SEARCH_CODE_PAINT_REGEX.test(val)){
      setColorCode(val)
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorSearchColor({ error: false, errorMsg: "" });

    if (!colorCode) {
      setErrorSearchColor({
        error: true,
        errorMsg: t("productDetail.BuyByColor.Errors.required"),
      });
      return;
    }

    const selectedLineColor = hdmColors?.allLineColors.filter(
      (colors) => colors.linea === hdmDrawerColorData?.lineColorValue
    )[0];
    const colorCodeSelected = selectedLineColor?.colores.filter(
      (color) =>
        color.codigo.toLowerCase() === colorCode.toLowerCase() || color.nombre.toLowerCase() === colorCode.toLowerCase()
    )[0];

    if (colorCodeSelected === undefined) {
      setErrorSearchColor({
        error: true,
        errorMsg: t("productDetail.BuyByColor.Errors.notFound"),
      });
      return;
    }

    let changeBaseColor;

    if (hdmDrawerColorData && colorCodeSelected && hdmDrawerColorData?.base !== colorCodeSelected?.base) {
      const baseColor = hdmDrawerColorData?.superSKUAttributes.filter((attr) => attr.identifier.includes("BASE_"))[0];
      const base = baseColor.values.filter((val) => val.identifier === colorCodeSelected?.base)[0];

      const color = colorCodeSelected.codigo !== "N/A" ? colorCodeSelected.codigo : null;
      changeBaseColor = { name: baseColor?.identifier, value: base?.id, id: baseColor?.id, color: color };
    }

    if (colorCodeSelected) {
      const query = {
        url: "",
        data: {
          ...hdmDrawerColorData,
          isOpen: false,
          gamaColors: null,
          colorCodeSelected: changeBaseColor ? null : colorCodeSelected,
          changeBaseColor: changeBaseColor,
          colorCode: colorCodeSelected?.codigo,
        },
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };

      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  };

  return (
    <ContainerSearchColor>
      <form onSubmit={handleSubmit}>
        <StyledBox className="styled--searchcolor-container">
          <StyledBox className="styled--search-input">
            <StyledTextField
              margin="normal"
              name="colorCode"
              label={t("productDetail.BuyByColor.labels.codeColor")}
              onChange={handleChange}
              // onBlur={handleOnBlur}
              value={colorCode}
              inputProps={{ maxLength: 40 }}
              fullWidth
              autoComplete="colorCode"
              error={errorSearchColor.error}
              helperText={errorSearchColor.errorMsg}
              className="arggTT"
              InputProps={{
                endAdornment: <StyledTooltipHdm content={isB2B ?
                <EspotHdm espotName={"PRO_PDP_Tooltip_SearchColor"} />
                :
                <EspotHdm espotName={"PDP_Tooltip_SearchColor"} />
              } />,
              }}
              // InputProps={{
              //     endAdornment: (
              //         isMobile ? (
              //         <DarkTooltipInput
              //             // onClose={handleTooltipClose}
              //             // open={openTooltip}
              //             // onBlur={handleTooltipClose}
              //             // title={t("ToolTip.Labels.Number")}
              //             title={"tooltip"}
              //             placement="bottom"
              //         >
              //             <InputAdornment position="end"
              //             // onBlur={handleTooltipClose}
              //             >
              //             <ClickAwayListener
              //             // onClickAway={handleTooltipClose}
              //             onClickAway={() =>console.log('')}
              //             >
              //                 <StyledIconButton className={"styled--tooltip-button"}
              //                 // onClick={handleTooltipOpen}
              //                 >
              //                 <StyledHelpIcon/>
              //                 </StyledIconButton>
              //             </ClickAwayListener>
              //             </InputAdornment>
              //         </DarkTooltipInput>
              //         ) : (
              //         <DarkTooltipInput
              //         // title={t("ToolTip.Labels.Number")}
              //         title={
              //             <HdmE
              //         }
              //         placement="bottom">
              //             <InputAdornment position="end">
              //             <StyledIconButton className={"styled--tooltip-button"}>
              //                 <StyledHelpIcon/>
              //             </StyledIconButton>
              //             </InputAdornment>
              //         </DarkTooltipInput>
              //         )
              //     ),
              // }}
            />
          </StyledBox>

          <StyledButton variant="outlined" className="styled--search-button" onClick={handleSubmit}>
            {t("productDetail.BuyByColor.Buttons.search")}
          </StyledButton>
        </StyledBox>
      </form>
    </ContainerSearchColor>
  );
};
