export const SLOverlayComponent = () => {
    const styles: object = {
        position: "absolute",
        top:"-5px",
        left: "-5px",
        width: "calc(100% + 5px)",
        height: "calc(100% + 5px)",
        backgroundColor: "rgba(230,230,230,0.3)",
        zIndex: "999"
    }

    return (
        <div style={styles}></div>
    )
}