import { Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { StyledContainerHDM, StyledHeaderHDM } from "../../../../header/Header";
import { SLFooterElements } from "../dimensions/Footer";
import { StyledSkeleton } from "../StyledSkeleton";

const shadowCSS = "0 3px 6px rgba(0,0,0,.16)"

//Espot default
export const SLEspotSkeleton = () => {
    return (
        <>
            <StyledGrid container style={{width:"100%", padding:"10px 0", backgroundColor:"white"}}>
                <StyledGrid container style={{width:"100%",background:"white", rowGap:"10px"}}>
                    <StyledSkeleton sharp variant="rect" width={"100%"} height={20} />
                    <StyledSkeleton sharp variant="rect" width={"100%"} height={60} />
                </StyledGrid>
            </StyledGrid>
        </>
    );
}

{/* NotSkeleton_ */}
export const SLEspotNotSkeleton = () => {
    return (
        <>
            <StyledGrid container id="espotSkeleton">
                <StyledGrid item xs={12}>
                    <StyledSkeleton variant="text" className="wide" noCard/>
                </StyledGrid>
                <StyledGrid item xs={12}>
                    <StyledSkeleton variant="text" className="wide" noCard/>
                </StyledGrid>
            </StyledGrid>
        </>
    );
}

{/* Espot HCard */}
export const SLEspotHCard = () => {
    return (
        <StyledGrid container style={{width:"100%", padding: "5px"}}>
            <StyledGrid container style={{width:"100%", boxShadow: `${shadowCSS}`}}>
                <StyledGrid item style={{width:"100%", padding:"10px", background:"white"}}>
                    <StyledSkeleton sharp variant="rect" width={"inherit"} height={400} />
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}

{/* Espot VCard */}
export const SLEspotVCard = ({n}) => {
    const aspectRatios = ["25 / 9","4 / 3","1 / 1","1/1"]
    return (
    <>
        { n == 1 && <VCard aspectRatio={aspectRatios[n-1]}/>}
        { n == 2 && <VCard2y3 n={2} aspectRatio={aspectRatios[n-1]}/>}
        { n == 3 && <VCard2y3 n={3} aspectRatio={aspectRatios[n-1]}/>}
        { n == 4 && <VCard4 aspectRatio={aspectRatios[n-1]}/>}
    </>
    );
}

export const VCard = ({aspectRatio}) => {
    return (
        <StyledGrid container style={{padding:"5px"}}>
            <StyledGrid container style={{width:"100%",background:"white", boxShadow: `${shadowCSS}`}}>
                <StyledGrid item style={{width:"100%", padding:"10px"}}>
                    {/* Imagen */}
                    <StyledGrid item style={{width:"100%", height:"max-content", aspectRatio:`${aspectRatio}`}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                    </StyledGrid>

                    {/* Texto */}
                    <StyledGrid item style={{width:"148px", marginTop:"10px"}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={38} />
                        <StyledGrid item style={{width:"75%", marginTop:"10px", display:"flex", flexFlow:"column", rowGap:"10px"}}>
                            <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                            <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}

export const VCard2y3 = ({n, aspectRatio}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(540));

    const vCards : any[] = [];
    for (let i = 0; i < n; i++) {
        vCards.push(
            <StyledGrid container style={{width:"100%",background:"white", boxShadow: `${shadowCSS}`}}>
                <StyledGrid item style={{width:"100%", padding:"10px"}}>
                    {/* Imagen */}
                    <StyledGrid item style={{width:"100%", height:"max-content", aspectRatio:`${aspectRatio}`}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                    </StyledGrid>

                    {/* Texto */}
                    <StyledGrid item style={{width:"148px", marginTop:"10px"}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={38} />
                        <StyledGrid item style={{width:"75%", marginTop:"10px", display:"flex", flexFlow:"column", rowGap:"10px"}}>
                            <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                            <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container style={{flexWrap: `${isMobile ? "wrap" : "nowrap"}`, gap:"10px", padding:"5px"}}>
            {vCards}
        </StyledGrid>
    );
}

export const VCard4 = ({aspectRatio}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(540));
    const isUnder = useMediaQuery(theme.breakpoints.down(740));

    const cardWidth = isMobile ? "100%" : isUnder ? "calc( 50% - 5px)" : "calc( 25% - 7.5px)";

    const vCards : any[] = [];
    for (let i = 0; i < 4; i++) {
        vCards.push(
        <StyledGrid container style={{width: `${cardWidth}`,background:"white", boxShadow: `${shadowCSS}`}}>
            <StyledGrid item style={{width:"100%", padding:"10px"}}>
                {/* Imagen */}
                <StyledGrid item style={{width:"100%", height:"max-content", aspectRatio:`${aspectRatio}`}}>
                    <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                </StyledGrid>

                {/* Texto */}
                <StyledGrid item style={{width:"148px", marginTop:"10px"}}>
                    <StyledSkeleton sharp variant="rect" width={"100%"} height={38} />
                    <StyledGrid item style={{width:"75%", marginTop:"10px", display:"flex", flexFlow:"column", rowGap:"10px"}}>
                        <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                        <StyledSkeleton sharp variant="rect" width={"inherit"} height={16.8} />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
        )
    }

    return (
        <StyledGrid container style={{gap:"10px", padding:"5px"}}>
            {vCards}
        </StyledGrid>
    );
}

{/* Espot TBanner */}
export const SLEspotTBanner = ({n}) => {
    //Array de alturas
    const aspectRatios = ["21 / 6","21 / 8","14 / 6","21 / 10"]
    const paragraphHeight = [300,200,150,120]

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(540));

    let aspectRatio = aspectRatios[n-1]
    if(isMobile){
        aspectRatio = "25 / 14"
    }

    //Array de TBanner
    const tBanners : any[] = [];
    for (let i = 0; i < n; i++) {
        {/* Parrafo */}
        tBanners.push(
            <StyledGrid item style={{width:"100%", height:"max-content", aspectRatio:`${aspectRatio}`}}>
                <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container style={{width:"100%",padding:"5px"}}>
            <StyledGrid container style={{width:"100%",background:"white", boxShadow: `${shadowCSS}`, padding:"10px"}}>
                {/* Titulo */}
                <StyledGrid item className="vertical-margin-2" style={{width:"100%"}}>
                    <StyledSkeleton sharp variant="rect" width={141} height={30} />
                </StyledGrid>

                {/* Imagen */}
                <StyledGrid container style={{width:"100%", flexWrap: `${isMobile ? "wrap" : "nowrap"}`, gap:"10px"}}>
                    {tBanners}
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}

{/* Espot CBanners */}
export const SLEspotCBanner = ({n}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(540));
    return (
    <>
        { n == 1 && <CBannerIndividual isMobile={isMobile}/>}
        { n == 2 && <CBanner2y3 isMobile={isMobile} n={2}/>}
        { n == 3 && <CBanner2y3 isMobile={isMobile} n={3}/>}
        { n == 4 && <CBanner4 isMobile={isMobile}/>}
    </>
    );
}

export const CBannerIndividual = ({isMobile}) => {

    return (
        <StyledGrid container style={{padding:"5px"}}>
            <StyledGrid container style={{width:"100%", background:"white", boxShadow: `${shadowCSS}`}}>
                <StyledGrid container item style={{width:"100%", padding:"10px", flexWrap: `${isMobile ? "wrap" : "nowrap"}`, gap:"10px"}}>
                    {/* Rectangulo izquierdo */}
                    <StyledGrid container item style={{width: `${isMobile ? "100%" : "33.33%"}`,height:"max-content", aspectRatio:"16 / 9"}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                    </StyledGrid>
                    {/* Texto centrado */}
                    <StyledGrid container item style={{width: `${isMobile ? "100%" : "66.67%"}`}}>
                        <StyledGrid container item style={{width: `${isMobile ? "50%" : "100%"}`,  maxWidth:"375px", margin:"auto", alignItems:"center", flexDirection:"column", rowGap:"10px", justifyContent:"center"}}>
                            <StyledSkeleton sharp variant="rect" width={"100%"} height={isMobile ? 25 : 44} />
                            <StyledSkeleton sharp variant="rect" width={"60%"} height={isMobile ? 20 : 30} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}

export const CBanner2y3 = ({isMobile, n}) => {
    const maxWidth = n == 2 ? "272px" : "228px"
    const divWidth = isMobile ? "100%" : `${100/n}%`;
    const imgWidth = isMobile ? "100%" : "33.33%";
    const textWidth = isMobile ? "100%" : "66.67%";
    //Array de CBanners
    const cBanners : any[] = [];
    //Usa los CBanners
    for (let i = 0; i < n; i++) {
        //Espaciado entre cards (10px left en padding)
        let spacingClass = ""
        if(i > 0){ //Solo si no es el primero
            spacingClass = isMobile ? "top-padding-2" : "left-padding-2"
        }
        cBanners.push(
            <StyledGrid className= {`${spacingClass}`} container style={{width:`${divWidth}`}}>
                <StyledGrid container item style={{width:"100%", padding:"10px", background:"white", boxShadow: `${shadowCSS}`}}>
                    {/* Imagen */}
                    <StyledGrid container item style={{width:`${imgWidth}`, height:"max-content", aspectRatio:"1 / 1"}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                    </StyledGrid>
                    {/* Texto izquierdo */}
                    <StyledGrid container item style={{width:`${textWidth}`, alignItems:"start", flexDirection:"column", rowGap:"7%", justifyContent:"center"}}>
                        {isMobile ? <CBannerTextMobile maxWidth={`${maxWidth}`}/> : <CBannerText maxWidth={`${maxWidth}`}/>}
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container style={{padding:"5px"}}>
            {cBanners}
        </StyledGrid>
    );
}

{/* Texto izquierdo del CBanner */}
export const CBannerText = ({maxWidth}) => {

    return (
        <StyledGrid container item style={{width:"100%", maxWidth:`${maxWidth}`, height:"100%", alignItems:"start", flexDirection:"column", rowGap:"10px", justifyContent:"center", paddingLeft:"10px"}}>
            <StyledGrid container item style={{width:"100%",height:"30%", maxHeight:"38.4px"}}>
                <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
            </StyledGrid>
            <StyledGrid container item style={{width:"83%",height:"10%", maxHeight:"30px"}}>
                <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
            </StyledGrid>
        </StyledGrid>
    );
}

{/* Texto izquierdo del CBanner */}
export const CBannerTextMobile = ({maxWidth}) => {
    return (
        <StyledGrid container item style={{width:"100%",  maxWidth:`${maxWidth}`, height:"100%", alignItems:"start", flexDirection:"column", rowGap:"10px", justifyContent:"center"}}>
            <StyledGrid container item style={{width:"100%", padding:"10px 10px 0 0"}}>
                <StyledSkeleton sharp variant="rect" width={"100%"} height={25} />
            </StyledGrid>
            <StyledSkeleton sharp variant="rect" width={"83%"} height={20} />
        </StyledGrid>
    );
}

export const CBanner4 = ({isMobile}) => {
    const n = 4;
    const divWidth = isMobile ? "100%" : `${100/n}%`;
    //Array de CBanners
    const cBanners : any[] = [];
    //Usa los CBanners
    for (let i = 0; i < n; i++) {
        //Espaciado entre cards (10px top o left en margin)
        let spacingClass = ""
        if(i > 0){ //Solo si no es el primero
            spacingClass = isMobile ? "top-padding-2" : "left-padding-2"
        }
        cBanners.push(
            <StyledGrid container className= {`${spacingClass}`} style={{width:`${divWidth}`}}>
                <StyledGrid container item style={{width:"100%", padding:"10px", background:"white", boxShadow: `${shadowCSS}`}}>
                    {/* Imagen */}
                    <StyledGrid container item style={{width:"33.33%", height:"max-content", aspectRatio:"1 / 1"}}>
                        <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                    </StyledGrid>
                    {/* Texto izquierdo */}
                    <StyledGrid container item style={{width:"66.67%", alignItems:"start", flexDirection:"column", rowGap:"7%", justifyContent:"center", paddingLeft:"10px"}}>
                        <StyledGrid container item style={{width:"100%", maxWidth:"184px", height:"100%", alignItems:"start", flexDirection:"column", rowGap:"10px", justifyContent:"center"}}>
                            <StyledGrid container item style={{width:"100%",height:"30%", maxHeight:"38.4px"}}>
                                <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                            </StyledGrid>
                            <StyledGrid container item style={{width:"83%",height:"10%", maxHeight:"30px"}}>
                                <StyledSkeleton sharp variant="rect" width={"100%"} height={"100%"} />
                            </StyledGrid>
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container style={{padding:"5px"}}>
            {cBanners}
        </StyledGrid>
    );
}