
import { StyledBox, StyledTypography } from '../../elements'
import { Divider } from '@material-ui/core'
import { palette } from '../../themes'
import { ContainerSpecialTitle } from './comntainer-special-title'

interface Props {
    titulo: string
    width: string
}
export const SpecialTitle = (props: Props) => {

    const { titulo, width } = props
    return (
        <ContainerSpecialTitle>
            <StyledBox p={2} display="flex" justifyContent="center" alignItems="center">
                <StyledBox className="divider-title">
                <Divider style={{ backgroundColor: palette.gray["100"], height: 2 }} />
                </StyledBox>
                <StyledBox style={{width: width}} px={1}>
                <StyledTypography align="center" className="specialTitle" variant="headingMainTitle" component="h1">
                {titulo.toUpperCase()}
                </StyledTypography>
                </StyledBox>
                <StyledBox className="divider-title">
                <Divider style={{ backgroundColor: palette.gray["100"], height: 2 }} />
                </StyledBox>
            </StyledBox>
        </ContainerSpecialTitle>
    )
}
