/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { Fragment, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import { useSelector } from "react-redux";
//Custom libraries
import {
  ACCOUNT,
  DASHBOARD,
  ADDRESS_BOOK,
  ORDER_HISTORY,
  RECURRING_ORDERS,
  BUYER_MANAGEMENT,
  APPROVALS_MANAGEMENT,
  ORGANIZATION_MANAGEMENT,
  ORDER_APPROVAL,
  INPROGRESS_ORDERS,
  CHECKOUT_PROFILES,
  REQUISITION_LISTS,
  PAYMENTMETHOD,
  WISH_LIST,
  ADD_ADDRESS,
  ADD_PAYMENTMETHOD,
  CHECKOUT_PROFILE_CREATE,
  CHECKOUT_PROFILE_EDIT,
  EDIT_ADDRESS
  // APPROVE_ORDERS
} from "../../../constants/routes";
import { forUserIdSelector, userIdSelector } from "../../../redux/selectors/user";
import personService from "../../../_foundation/apis/transaction/person.service";
import { LOCALE } from "../../../_foundation/constants/common";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { IBM_ASSIGNED_ROLE_DETAILS, BUYER_ADMIN_ROLE, BUYER_APPROVAL_ROLE } from "../../../constants/common";
//UI
import {
  StyledLink,
  StyledListItem,
  StyledListItemText,
  StyledList,
  StyledSidebar,
  StyledTooltip,
} from "@hcl-commerce-store-sdk/react-component";
import { StyledBox, StyledLinkBox, StyledTypography } from "../../elements";
import { WalletIcon, ShoppingCartCheckoutIcon, PackageIcon, AssignmentTurnedInIcon, EditDocumentIcon, PublishedWithChangeIcon, GroupIcon, HomeOutlinedIcon, FavoriteIcon, ReceiptLongIcon } from "../../components/svg-icons";

interface CustomAccountSidebarProps {
  sectionsArray: any[];
}

/**
 * CustomAccountSidebarProps component
 * displays user's account links
 * @param props
 */
function CustomAccountSidebar(props: CustomAccountSidebarProps) {
  const { sectionsArray } = props;
  const { t } = useTranslation();
  const location: any = useLocation();

  let pathname = location.pathname

  if(location.pathname.includes(CHECKOUT_PROFILE_EDIT)){
    pathname = CHECKOUT_PROFILE_EDIT
  }
  if(location.pathname.includes(EDIT_ADDRESS)){
    pathname = EDIT_ADDRESS
  }

  return (
    <StyledBox aria-labelledby={t("Dashboard.Title")}>
      {sectionsArray.map((v: any, sectionIndex: number) => (
        <Fragment key={sectionIndex}>
          <StyledTypography variant="bodyBaseline" fontWeight={600} className="bottom-margin-2 color-gray500">
              {v.title}
          </StyledTypography>
          {v.pages.map((pageObj: any, index: number) => {
            const uniqueIndex = `${sectionIndex}${index}`;
            return pageObj.link ? (
              pageObj.page.includes(pathname) ? (
                <StyledLinkBox
                    disabledTitle={""}
                    id={"account--menuLink-order"}
                    title={pageObj.title}
                    description={pageObj.description}
                    url={pageObj.link}
                    icon={pageObj.icon}
                    selected={true}
                    page={pageObj.icon}
                  />
              ) : (
                <StyledLinkBox
                    disabledTitle={""}
                    id={"account--menuLink-order"}
                    title={pageObj.title}
                    description={pageObj.description}
                    url={pageObj.link}
                    icon={pageObj.icon}
                    selected={false}
                    page={pageObj.icon}
                  />
              )
            ) : (
              <StyledTooltip title={`${t("AccountLinks.DisabledMessage")}`} key={uniqueIndex}>
                <div>
                  <StyledListItem className="section-disabled">
                    <StyledListItemText primary={pageObj.title} />
                  </StyledListItem>
                </div>
              </StyledTooltip>
            );
          })}
        </Fragment>
      ))}
    </StyledBox>
  );
  return (
    <StyledList component="nav" aria-labelledby={t("Dashboard.Title")}>
      {sectionsArray.map((v: any, sectionIndex: number) => (
        <Fragment key={sectionIndex}>
          <StyledListItem className="section-title">
            <StyledTypography variant="overline" component="span">
              {v.title}
            </StyledTypography>
          </StyledListItem>
          {v.pages.map((pageObj: any, index: number) => {
            const uniqueIndex = `${sectionIndex}${index}`;
            return pageObj.link ? (
              pageObj.link === location.pathname ? (
                <StyledListItem key={uniqueIndex} selected={true}>
                  <StyledListItemText primary={pageObj.title} />
                </StyledListItem>
              ) : (
                <StyledLink key={uniqueIndex} to={pageObj.link} className="section-link">
                  <StyledListItem>
                    <StyledListItemText primary={pageObj.title} />
                  </StyledListItem>
                </StyledLink>
              )
            ) : (
              <StyledTooltip title={`${t("AccountLinks.DisabledMessage")}`} key={uniqueIndex}>
                <div>
                  <StyledListItem className="section-disabled">
                    <StyledListItemText primary={pageObj.title} />
                  </StyledListItem>
                </div>
              </StyledTooltip>
            );
          })}
        </Fragment>
      ))}
    </StyledList>
  );
}

interface AccountSidebarProps {
  isB2B?: boolean;
}

const useSectionArray = (isB2B: boolean) => {
  const widgetName = getDisplayName(AccountSidebar);
  const { t } = useTranslation();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const userId = useSelector(userIdSelector);
  const forUserId = useSelector(forUserIdSelector);

  const locale = localStorageUtil.get(LOCALE);

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const [buyerRole, setBuyerRole] = useState<string[]>([]);

  const getPerson = () => {
    const param = {
      userId: forUserId ?? userId,
      profileName: IBM_ASSIGNED_ROLE_DETAILS,
      ...payloadBase,
    };
    personService
      .findByUserId(param)
      .then((response) => response.data)
      .then((data) => {
        const roleDetail = data?.rolesWithDetails;
        if (roleDetail) {
          checkBuyerAdmin(roleDetail);
        }
      })
      .catch((e) => {
        console.log("Could not retrieve role details");
      });
  };

  const checkBuyerAdmin = (roleDetail) => {
    const roles: string[] = [];
    for (const value of roleDetail) {
      roles.push(String(value.roleId));
    }
    setBuyerRole(roles);
  };

  const sectionsArray_B2C = [
    {
      title: t("MyAccount.AccountSettings"),
      pages: [
        {
          title: t("MyAccount.Sidebar.OrderHistory"),
          description: "Da seguimiento a tus órdenes realizadas",
          link: ORDER_HISTORY,
          icon: <PackageIcon />,
          page: [ORDER_HISTORY]
        },
        {
          title: t("MyAccount.Sidebar.AddressBook"),
          description: "Crea y administra tus direcciones",
          link: ADDRESS_BOOK,
          icon: <HomeOutlinedIcon />,
          page: [ADDRESS_BOOK, ADD_ADDRESS, EDIT_ADDRESS]
        },
        {
          title: t("MyAccount.Sidebar.PaymentMethod"),
          description: "Guarda y administra tus métodos de pago",
          link: PAYMENTMETHOD,
          icon: <WalletIcon />,
          page: [PAYMENTMETHOD, ADD_PAYMENTMETHOD]
        },
        {
          title: t("MyAccount.Sidebar.CheckoutProfiles"),
          description: "Crea perfiles para comprar más rápido",
          link: CHECKOUT_PROFILES,
          icon: <ShoppingCartCheckoutIcon />,
          page: [CHECKOUT_PROFILES, CHECKOUT_PROFILE_CREATE, CHECKOUT_PROFILE_EDIT]
        },
        {
          title: t("MyAccount.Sidebar.Wishlists"),
          description: "Crea y administra tus listas guardadas",
          link: WISH_LIST,
          icon: <FavoriteIcon />,
          page: [WISH_LIST]
        },
      ],
    },
  ];

  const orderManagement = {
    title: t("Dashboard.OrderManagement"),
    pages: [
      {
        title: t("MyAccount.Sidebar.OrderHistory"),
        description: "Da seguimiento a tus órdenes realizadas",
        link: ORDER_HISTORY,
        icon: <PackageIcon />,
        page: [ORDER_HISTORY]
      },
      {
        title: "Mis cotizaciones",
        description: "Dar seguimiento a tus cotizaciones",
        link: INPROGRESS_ORDERS,
        icon: <ReceiptLongIcon />,
        page: [INPROGRESS_ORDERS]
      },
      // {
      //   title: "Mis órdenes recurrentes",
      //   description: "Administra tus listas de órdenes frecuentes",
      //   link: RECURRING_ORDERS,
      //   icon: <PublishedWithChangeIcon />,
      //   page: [RECURRING_ORDERS]
      // },
      // {
      //   title: t("Dashboard.RequisitionLists"),
      //    description: "",
      //   link: REQUISITION_LISTS,
      //   icon: "",
      //   page: [REQUISITION_LISTS]
      // },
      
    ],
  };

  const accountSetting_B2B = {
    title: t("Dashboard.AccountSettings"),
    pages: [
      // {
      //   title: t("MyAccount.Sidebar.OrderHistory"),
      //    description: "",
      //   link: ORDER_HISTORY,
      //   icon: <PackageIcon />,
      //   page: [ORDER_HISTORY]
      // },
      {
        title: t("MyAccount.Sidebar.AddressBook"),
        description: "Crea y administra tus direcciones",
        link: ADDRESS_BOOK,
        icon: <HomeOutlinedIcon />,
        page: [ADDRESS_BOOK, ADD_ADDRESS, EDIT_ADDRESS]
      },
      {
        title: t("MyAccount.Sidebar.PaymentMethod"),
        description: "Guarda y administra tus métodos de pago",
        link: PAYMENTMETHOD,
        icon: <WalletIcon />,
        page: [PAYMENTMETHOD, ADD_PAYMENTMETHOD]
      },
      {
        title: t("MyAccount.Sidebar.CheckoutProfiles"),
        description: "Crea perfiles para comprar más rápido",
        link: CHECKOUT_PROFILES,
        icon: <ShoppingCartCheckoutIcon />,
        page: [CHECKOUT_PROFILES, CHECKOUT_PROFILE_CREATE, CHECKOUT_PROFILE_EDIT]
      },
      {
        title: t("MyAccount.Sidebar.Wishlists"),
        description: "Crea y administra tus listas guardadas",
        link: WISH_LIST,
        icon: <FavoriteIcon />,
        page: [WISH_LIST]
      },
    ],
  };

  const B2B_Admin = {
    title: t("AdminTools.adminTools"),
    pages: [
      {
        title: t("AdminTools.orgManagement"),
        description: "",
        link: ORGANIZATION_MANAGEMENT,
        icon: "",
        page: [ORGANIZATION_MANAGEMENT]
      },
      {
        title: t("AdminTools.buyerManagement"),
        description: "Administra los usuarios y permisos",
        link: BUYER_MANAGEMENT,
        icon: "",
        page: [BUYER_MANAGEMENT]
      },
      {
        title: t("AdminTools.orgAndBuyer"),
        description: "",
        link: APPROVALS_MANAGEMENT,
        icon: <AssignmentTurnedInIcon />,
        page: [APPROVALS_MANAGEMENT]
      },
    ],
  };

  const formatSectionArray = () => {
    if (!isB2B) {
      return sectionsArray_B2C;
    } else {
      const array: any[] = [];
      
      const orders = { ...orderManagement };
      const settings = { ...accountSetting_B2B };
      array.push(orders);
      array.push(settings);
      // if (buyerRole.includes("-24")) {
      if (buyerRole.includes(BUYER_ADMIN_ROLE)) {
        array.push({ ...B2B_Admin });
      } else if (buyerRole.includes(BUYER_APPROVAL_ROLE)) {
        orders.pages = [
          {
            title: "Mis aprobaciones",
            description: "Aprueba o rechaza ordenes de compradores",
            link: ORDER_APPROVAL,
            icon: <AssignmentTurnedInIcon />,
            page: [ORDER_APPROVAL]
          },
          ...orderManagement.pages,
        ];
        settings.pages = [
          {
            title: "Mis compradores",
            description: "Administra los usuarios y permisos",
            link: BUYER_MANAGEMENT,
            icon: <GroupIcon />,
            page: [BUYER_MANAGEMENT]
          },
          ...accountSetting_B2B.pages,
        ];
      }
      // else {

      //   settings.pages = [
      //     {
      //       title: "Mis compradores",
      //       link: BUYER_MANAGEMENT,
      //       icon: <GroupIcon />,
      //       page: [BUYER_MANAGEMENT]
      //     },
      //     ...accountSetting_B2B.pages,
      //   ];

      //   array.push(settings);
      // }
    // } else {
    //   const array: any[] = [];
    //   array.push(accountSetting_B2B);
    //   const orders = { ...orderManagement };
    //   array.push(orders);
    //   if (buyerRole.includes(BUYER_ADMIN_ROLE)) {
    //     array.push({ ...B2B_Admin });
    //   } else if (buyerRole.includes(BUYER_APPROVAL_ROLE)) {
    //     orders.pages = [
    //       ...orderManagement.pages,
    //       {
    //         title: t("Dashboard.ApproveOrders"),
    //         link: ORDER_APPROVAL,
    //         icon: "",
    //         page: [ORDER_APPROVAL]
    //       },
    //     ];
    //   }
      return array;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionsArray = useMemo(() => formatSectionArray(), [buyerRole, isB2B, locale]);

  React.useEffect(() => {
    if (userId || forUserId) {
      getPerson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, forUserId]);

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sectionsArray };
};

/**
 * AccountSidebar component
 * displays user's b2c or b2b account links in a sidebar
 * @param props
 */
function AccountSidebar(props: AccountSidebarProps) {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = props.isB2B || mySite.isB2B;
  const title = isB2B ? t("Dashboard.Title") : t("MyAccount.Title");
  const titleLink = isB2B ? DASHBOARD : ACCOUNT;

  const { sectionsArray } = useSectionArray(isB2B);

  return (
    <CustomAccountSidebar sectionsArray={sectionsArray} />
    // <StyledSidebar
    //   title={title}
    //   sidebarContent={<CustomAccountSidebar sectionsArray={sectionsArray} />}
    //   linkTo={titleLink}
    //   breakpoint="md"
    //   scrollable={true}
    // />
  );
}

export default AccountSidebar;
