import { useState, useEffect } from "react";

import { StyledButton } from "../../../../elements/button";
import { palette } from "../../../../themes/color-palette";
import { Divider, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  ContainerStyledBox,
  ContainerStyledButton,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
} from "../../containers-precio-promocion";

import AhorraMasDialog from "./AhorraMasDialog";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import StyledSwipeableDrawerHDM from "../../../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { StyledLink, StyledTypography } from "../../../../elements";
import { AllowScrollWithTop, DisableScrollWithTop, tabletBreakpoint } from "../../../../functions/utils";
import AhorraMasSeccionUno from "./AhorraMasSeccionUno";
import AhorraMasSeccionDos from "./AhorraMasSeccionDos";
import AhorraMasProducto from "./AhorraMasProducto";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  brand: any;
  attributes: any;
  partNumber: any;
  shortDescription: any;
  nominalQuantity: any;
  ahorraMas: any;
  articulos: any;
}

export const AhorraMasPromo = (props: Props) => {
  const { ahorraMas, articulos, brand, attributes, partNumber, shortDescription, nominalQuantity } = props;

  const [openProductos, setOpenProductos] = useState<boolean>(false);
  const [promoAhorro, setpromoAhorro] = useState<any>("");
  const [ahorramasValues, setAhorramasValues] = useState<any>(null);
  const theme = useTheme();
  const is1366 = useMediaQuery(theme.breakpoints.up(1366));
  const storeConfData = useSelector(onlineStoreRdc);
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    InicializarAhorraMas();
  }, [ahorraMas]);

  const InicializarAhorraMas = () => {
    if (!ahorraMas.field1 && ahorraMas.field1.trim() === "") return;

    function precioWithComas(precio: number) {
      const result = precio
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return result;
    }

    const ahorraValues: any = {
      compra: ahorraMas.field1.trim().split("|")[0],
      compraGrupo: ahorraMas.field1.trim().split("|")[1],
      ahorra: ahorraMas.field1.trim().split("|")[2],
      ahorraGrupo: ahorraMas.field1.trim().split("|")[3],
    };

    ahorraValues.compra = precioWithComas(parseFloat(ahorraValues.compra));
    if (ahorraValues.tipoAhorro === "monto") {
      ahorraValues.ahorra = precioWithComas(parseFloat(ahorraValues.ahorra));
    }

    setAhorramasValues(ahorraValues);
  };

  const handleOpenProductos = () => {
    if (isMobile) {
      DisableScrollWithTop();
    }
    setOpenProductos(true);
  };

  const handleCloseProductos = () => {
    AllowScrollWithTop();
    setOpenProductos(false);
  };

  if (!ahorramasValues) return null;

  let tipoAhorro = "";
  if (ahorraMas.tipoAhorro === "porcentaje") {
    tipoAhorro = "%";
  }
  if (ahorraMas.tipoAhorro === "monto") {
    tipoAhorro = "$";
  }

  let allProducts = "/search?searchTerm=";
  articulos &&
    articulos.map((articulo, index) => {
      if (index === 0) {
        allProducts = `${allProducts}${articulo?.partNumber}`;
      } else {
        allProducts = `${allProducts}, ${articulo?.partNumber}`;
      }
    });

  return (
    <>
      <ContainerStyledBox className="ahorra-mas-container">
        <ContainerStyledBox flexGrow={1} className="ahorra-mas-subcontainer">
          <ContainerStyledBox className="promoicon-container">
            <ContainerStyledCardMedia
              image={storeConfData?.IMAGE_SERVER_HOSTNAME + "iconosPromos/ahorramas_v.svg"}
              className="promo_plp-ribbon-cp"
              title="Ahorra mas"
              component="img"
            />
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold" className="ahorramas-text">
              Obten un ahorro adicional
            </ContainerStyledTypography>
          </ContainerStyledBox>
          <ContainerStyledBox>
            <ContainerStyledTypography variant="bodySubText" component="span">
              Compra&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodySubText" weight="semibold" component="span">
              {`$${ahorramasValues.compra}`} en {ahorramasValues.compraGrupo}&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodySubText" component="span">
              y obtén&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodySubText" weight="semibold" component="span">
              {tipoAhorro === "$" ? tipoAhorro : ""}
              {ahorramasValues.ahorra}
              {tipoAhorro === "%" ? tipoAhorro : ""}&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodySubText" component="span">
              de ahorro en&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodySubText" weight="semibold" component="span">
              {ahorramasValues.ahorraGrupo}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: palette.gray["50"] }} />
        <ContainerStyledBox className="aplicar-container">
          <ContainerStyledBox>
            {/* <StyledButton variant="text" disableRipple onClick={handleOpenProductos}> */}
            <ContainerStyledTypography varinat="bodyBaseline" className="aplicar-text" onClick={handleOpenProductos}>
              {is1366 ? "Aplicar promoción" : "Aplicar"}
            </ContainerStyledTypography>
            {/* </StyledButton> */}
          </ContainerStyledBox>
        </ContainerStyledBox>

        {isTablet ? (
          <AhorraMasDialog
            brand={brand}
            attributes={attributes}
            partNumber={partNumber}
            shortDescription={shortDescription}
            nominalQuantity={nominalQuantity}
            openProductos={openProductos}
            ahorramasValues={promoAhorro}
            ahorraMas={ahorraMas}
            handleCloseProductos={handleCloseProductos}
            articulos={articulos}
          />
        ) : (
          <StyledSwipeableDrawerHDM
            isOpen={openProductos}
            setOpenDrawer={handleCloseProductos}
            variant={"saveMore"}
            title={t("productDetail.SaveMore")}>
            <>
              <AhorraMasSeccionUno
                brand={brand}
                attributes={attributes}
                partNumber={partNumber}
                shortDescription={shortDescription}
                nominalQuantity={nominalQuantity}
                ahorramasValues={ahorramasValues}
                handleClose={handleCloseProductos}
              />
              <AhorraMasSeccionDos ahorramasValues={ahorramasValues} ahorraMas={ahorraMas} />

              {articulos &&
                articulos.map((articulo, index) => {
                  return <AhorraMasProducto key={index} articulo={articulo} />;
                })}

              <ContainerStyledBox className="dialog-seccionthree">
                <Link to={allProducts} target="_blank">
                  <ContainerStyledButton variant="contained">{t("productDetail.SeeAllOptions")}</ContainerStyledButton>
                </Link>
                <ContainerStyledTypography varinat="bodySubText" weight="semibold" component="span">
                  {t("productDetail.SeePromotionAtCart")}&nbsp;
                </ContainerStyledTypography>
                <StyledLink to="/terminos-condiciones" target="_blank">
                  {t("productDetail.TermsAndConditions")}
                </StyledLink>
              </ContainerStyledBox>
            </>
          </StyledSwipeableDrawerHDM>
        )}
      </ContainerStyledBox>
    </>
  );
};
