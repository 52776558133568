/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Foundation libraries
//Redux
import {
  AccountReducerState,
  CatalogReducerState,
  OrderReducerState,
  ErrorReducerState,
  SearchReducerState,
  OrderDetailsMapReducerState,
  ApiReducerState,
  WishListReducerState,
  CategoryReducerState,
  SellerInfoState,
  hdmDataReducerState,
  DrawerInterface,
  BackDropLoaderInterface,
} from "./reducerStateInterface";

const currentStoreDefaultState = {};

const accountDefaultState: AccountReducerState = {
  orders: null,
  address: null,
};
const wishListDefaultState: WishListReducerState = {
  list: null,
};
const catalogDefaultState: CatalogReducerState = {
  productList: [],
  productListTotal: -1,
  priceMode: "1",
  facets: null,
  facetPrice: null,
  selectedFacets: {},
  selectedFacetLimits: [],
  selectedFacetPrices: { min: -1, max: -1 },
  selectedPageOffset: 1,
  selectedSortOption: "0",
  breadcrumbs: [],
  selectFacetRemove: false,
  url: "",
  productCache: {
    byId: {},
    container: [],
    idx: 0,
    MAX: 36,
  },
  spellcheck: [],
  selectedProduct: "",
};
const orderDefaultState: OrderReducerState = {
  cart: null,
  numItems: 0,
  orderItems: [],
  catentries: null,
  isCheckoutDisabled: false,
  shipInfos: null,
  shipModes: [],
  payMethods: [],
  isRecurringOrderDisabled: false,
  isFetching: false,
  allowableShipModes: [],
  activeInprogressOrder: null,
  allowablePaymethods: [],
};
const errorDefaultState: ErrorReducerState = {
  errorKey: null,
  errorCode: null,
  errorTitleKey: null,
  errorMsgKey: null,
  handled: null,
  errorMessage: "",
  errorParameters: "",
};

const categoriesDefaultState: CategoryReducerState = {
  categories: [],
};

const searchDefaultState: SearchReducerState = {
  keywords: [],
};

const orderDetails: OrderDetailsMapReducerState = {};

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
};

const sellerDefaultState: SellerInfoState = {
  showSellerList: false,
  showSellerFacets: false,
  sellers: [],
  langId: -1,
};

const hdmDataDefaultState: hdmDataReducerState = {
  isFetching: false,
  exclusive: null,
  estadosCiudades: null,
  deliveryZones: null,
  pdpQuantity: null,
  onlineStore: null,
  checkOutStep: { currentStep: "0", completedStep: [false, false, false] },
  installationPartNumber: "",
  installationPrice: 0,
  pdpSeo: null,
  cuentaMisOrdenesFiltros: [],
  guestUserData: { email: "", firstName: "", lastName: "", phone: "" },
  hdmStoresData: null,
  deliveryMethodId: { delivery: "", bopis: "" },
  paymentSequence: null,
  wishListSelectedProduct: null,
  wishListProductsOrderBy: "",
  wishListSearchProduct: "",
  wishListProductsQuantity: [],
  lineaGamaColors: null,
  hdmColors: null,
  hdmDrawerColorData: null,
  hdmPDPproductData: null,
  bopisDrawerCart: false,
  orderResumeOptData: null,
  emarsysSearchTagData: null,
  emarsysCategoryTagData: null,
  emarsysPDPTagData: null,
  emarsysTYPTagData: null,
  emarsysPDPTagFinished: null,
  emarsysWidgetType: null,
  emarsysAdditionalReady: null,
  emarsysRegularReady: null,
  storeBuyableExclude: null,
  loaderCheckoutStep: "addressForm",
  footerRoute: "",
  hdmEmarsysBundleItemsReady: null,
};

const drawerDefaultState: DrawerInterface = {
  open: false,
  cartSource: "",
  isSignInPopUpOpen: false,
  isRegisterPopUpOpen: false,
  isLoginDrawerOpen: false,
  src: "",
};

const backdropLoaderState: BackDropLoaderInterface = {
  open: false,
  src: undefined,
  idx: undefined,
};

export const defaultStates = {
  account: accountDefaultState,
  api: apiDefaultState,
  catalog: catalogDefaultState,
  order: orderDefaultState,
  user: { initiatedFromStorage: false },
  error: errorDefaultState,
  seo: {},
  contract: {},
  search: searchDefaultState,
  organization: {},
  context: {},
  success: {},
  confirmation: {},
  recurringOrder: { resultList: [] },
  orderDetails,
  checkoutProfile: { curUserProfiles: [] },
  site: { currentSite: null },
  wishList: wishListDefaultState,
  categories: categoriesDefaultState,
  sellers: sellerDefaultState,
  currentStore: currentStoreDefaultState,
  hdmData: hdmDataDefaultState,
  drawer: drawerDefaultState,
  backdropLoader: backdropLoaderState,
  fyflogin: { loginError: false },
};

export const clearState = (o: any) => {
  for (const variableKey in o) {
    if (Object.prototype.hasOwnProperty.call(o, variableKey)) {
      delete o[variableKey];
    }
  }
};

const initStates = {
  ...defaultStates,
};
export default initStates;
