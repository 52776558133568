import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";

import { ContainerInformacionAdicional } from "./containers-informacion-adicional";
import { StyledBox, StyledTypography, StyledLink } from "../../elements";
import { Divider } from "@material-ui/core";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import ManualesDescargables from "./ManualesDescargables";
import { palette } from "../../themes/color-palette";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSite } from "../../../_foundation/hooks/useSite";
import { getProductModel } from "../../functions/utils";

interface Props {
  isOpen: boolean;
  handleIsOpen: () => void;
  partNumber: any;
  model: any;
  longDescription: any;
  legalDescription: any;
}

const DrawerInformacionAdicional = (props: Props) => {
  const { isOpen, handleIsOpen, partNumber, model, longDescription, legalDescription } = props;
  const [manuales, setManuales] = useState<any>(null);
  const storeConfData = useSelector(onlineStoreRdc);
  const { t } = useTranslation();
  const additionalInfoDrawerTitle = t("InformacionAdicional.infoAdicional");
  
  const { mySite } = useSite();
  const isB2B:boolean = mySite?.isB2B;
  const additionalInfoSuperiorEspotName:string = isB2B ? "PRO_PDP-AdditionalInfo_SuperiorSection" : "PDP-AdditionalInfo_SuperiorSection"; 
  const additionalInfoMiddleEspotName:string = isB2B ? "PRO_PDP-AdditionalInfo_MiddleSection" : "PDP-AdditionalInfo_MiddleSection"; 
  const additionalInfoInferiorSectionEspotName:string = isB2B ? "PRO_PDP-AdditionalInfo_InferiorSection" : "PDP-AdditionalInfo_InferiorSection"; 

  useEffect(() => {
    if(partNumber){
      checkFiles(partNumber);
    }

    return () => {
      setManuales(null);
    };
  }, [partNumber]);

  const checkFiles = async (partNumber: string) => {
    const files = [
      {
        orden: "1",
        type: "Garantía",
        url: `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-g.pdf`,
      },
      {
        orden: "2",
        type: "Manual de usuario",
        url: `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-m.pdf`,
      },
      {
        orden: "3",
        type: "Especificaciones Técnicas",
        url: `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-e.pdf`,
      },
    ];

    function ordenarObjeto(a, b) {
      if (a.orden < b.orden) {
        return -1;
      }
      if (a.orden > b.orden) {
        return 1;
      }
      return 0;
    }

    const filesExist: any = [];

    await Promise.all(
      files.map((urlFile, index) => {
        const request = new XMLHttpRequest();
        request.open("HEAD", urlFile.url, true);
        request.send();
        request.onload = function () {
          if (request.status == 200) {
            filesExist.push(urlFile);
          }
          if (index === 2) {
            filesExist.sort(ordenarObjeto);
            setManuales(filesExist);
          }
        };
        request.onerror = function () {
          console.log("requestError", request);
          if (index === 2) {
            filesExist.sort(ordenarObjeto);
            setManuales(filesExist);
          }
        };
      })
    );
  };

  let descripcionLarga: any = longDescription;

  const dotNoNum = /\.(?!\d)/;

  if (longDescription) {
    descripcionLarga = descripcionLarga.split(dotNoNum);
  }

  return (
    <>
      <StyledSwipeableDrawerHDM
        isOpen={isOpen}
        setOpenDrawer={handleIsOpen}
        variant={"informacion-adicional"}
        title={additionalInfoDrawerTitle}>
        <ContainerInformacionAdicional>
          <StyledBox className="drawer-container">
            <StyledBox className="informacion-spot">
              <EspotHdm espotName={additionalInfoSuperiorEspotName} />
            </StyledBox>
            <StyledBox className="modelo-sku">
              <StyledTypography variant="bodySubtext" className="informacion-modelo" component="span">
                Modelo {model}
              </StyledTypography>
              <StyledTypography variant="bodySubtext" className="informacion-sku" component="span">
                SKU {partNumber}
              </StyledTypography>
            </StyledBox>
            <StyledBox className="descripcion-larga">
              {descripcionLarga &&
                descripcionLarga.map((desc, index) => {
                  if (desc.trim() === "." || desc.trim() === "") return null;
                  return (
                    <StyledTypography key={index} variant="bodyBaseline" paragraph>
                      {desc.trim()}.
                    </StyledTypography>
                  );
                })}
            </StyledBox>

            <StyledTypography variant="bodySubtext" className="informacion-legal">
              {legalDescription}
            </StyledTypography>

            <StyledBox className="informacion-spot">
              <EspotHdm espotName={additionalInfoMiddleEspotName} />
            </StyledBox>
            <Divider style={{ marginBottom: 10, backgroundColor: palette.gray["50"] }} />
            {manuales && manuales.length !== 0 ? <ManualesDescargables manuales={manuales} /> : null}

            <StyledBox className="informacion-spot">
              <EspotHdm espotName={additionalInfoInferiorSectionEspotName} />
            </StyledBox>
          </StyledBox>
        </ContainerInformacionAdicional>
      </StyledSwipeableDrawerHDM>
    </>
  );
};

export default DrawerInformacionAdicional;
