import { typography } from "./typography";
import { palette } from "./color-palette";
import { merge, sharedOverrides } from "./shared-theme";
import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    tablet: true; // adds the `tablet` breakpoint
  }
}

const {
  primary,
  // secondary,
  // main
} = palette;

export const themeOptions = {
  name: "Thd",
  MuiButtonBase: {
    defaultProps: {
      // The props to apply
      disableripple: true, // No more ripple, on the whole application 💣!
      fontSize: 16,
      fontWeight: 500,
    },
  },
  palette: {
    ...palette,
  },
  button: {
    backgroundColor: primary.main,
    "&:hover": {
      backgroundColor: primary.main,
    },
  },
  child: {
    backgroundColor: primary.main,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  typography: {
    ...typography,
  },
  spacing: 5,
  fontWeights: {
    thin: 200,
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
  },
};

const combinedOverides = merge(sharedOverrides, themeOptions);
const theme = createTheme(combinedOverides);

export default theme;
