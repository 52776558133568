import { useState } from "react";
import { Divider } from "@material-ui/core";
import { StyledBox, StyledLink, StyledLinkTextSecondary, StyledTypography, StyledButton } from "../../../../hdm/elements";
import ProductBoxInfo from "../../../custom-components/styled-productbox-info/ProductBoxInfo";
import StyledSwipeableDrawerHDM from "../../../custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { OrderHistorySteper } from "./OrderHistorySteper";
import { useTranslation } from "react-i18next";
import { StyledCartRowPaint } from "../../../custom-components/styled-cart-row-paint";
import styled from "styled-components";
import { CONSTANTS } from "../../../../constants/order-item-table";

const ProductMyOrderContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  //  border: ${theme.palette.gray["500"]};

  border-bottom: 1px solid ${theme.palette.gray["50"]};

  &.styled-border-first {
    border-top: 1px solid ${theme.palette.gray["50"]};
  }

  .styled-border-left {
    border-left: 1px solid ${theme.palette.gray["50"]};
  }

  .styled-border-bottom {
    border-bottom: 1px solid ${theme.palette.gray["50"]};
  }

  .styled-fontsize-10 {
    font-size: 10px;
  }

  .styled-terms-conditions {
    display: flex;
    text-align: center;
  }

  .product-quantity {
    font-size: 15px;
  }
`}
`;


interface Props {
  order: any;
  hasInstallation?: any;
  productData: any;
  orderStatus?: any;
  index: number;
  reference?: string | undefined;
}

const ProductoMiOrden = (props: Props) => {
  const { t } = useTranslation();
  const productOrderDrawerTitle = t("ProductOrder.deliveryTracking");
  const { order, hasInstallation, productData, orderStatus, reference } = props;
  const [isOpen, setIsOpen] = useState(false);
  const index = props.index
  function customPriceWithComas(price: any) {
    const result = {
      displayPrice: parseFloat(price)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      unitPrice: parseFloat(price)
        .toFixed(2)
        .toString()
        .split(".")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      decimalPrice: parseFloat(price).toFixed(2).toString().split(".")[1],
    };
    return result;
  }

  
  const productPrice = order?.orderItemPrice * 1;
  const productDiscount = Math.abs(order?.totalAdjustment?.value * 1);
  
  const promoNxM =
  productData &&
  productData[0]?.attributes?.filter((attribute) => attribute?.identifier?.includes("PROMORIBBON_NXM"));
  const promoNumNxM = promoNxM && promoNxM[0]?.name?.split(" ").slice(-1);
  
  const promotions = order?.adjustment?.[0]?.description;
  const productFullPrice =
  order &&
  customPriceWithComas(
    parseFloat(order.unitPrice) + parseFloat(order.unitPrice) * (parseFloat(order.xitem_taxRate) / 100)
  );
  
  console.log("productsYYY", order)
  // console.log("typTest", productFullPrice + productDiscount)
  // console.table({
  //   productFullPrice: productFullPrice,
  //   productDiscount: productDiscount
  // })

  const selectOrdenEstatus = () => {
    const deliveryEstatus = {
      G: "Recibida",
      S: "En preparación",
      M: "En proceso",
      T: "En camino",
      D: "Entregada",
      R: "En proceso",
      P: "En transferencia",
      X: "Cancelado",
      PX: "En proceso",
    };

    const pickUpEstatus = {
      G: "Recibida",
      S: "En preparación",
      M: "En proceso",
      ER: "Lista para recoger",
      R: "En proceso",
      P: "En transferencia",
      X: "Cancelado",
      D: "Entregada",
      PX: "En proceso",
    };

    // console.log('item product', order.xitem_orderItemHistStatusAndTimestamp.split('@@'))

    // const recibidaDate = new Date(order.createDate)

    // let hours = recibidaDate.getHours()
    // let minutes: any = recibidaDate.getMinutes()
    // const ampm = hours >= 12 ? 'pm' : 'am'
    // hours = hours % 12
    // hours = hours ? hours : 12 // the hour '0' should be '12'
    // minutes = minutes < 10 ? `0${minutes}` : minutes
    // const strTime = `${hours}:${minutes} ${ampm}`

    // const recibida = `${recibidaDate.getDate()} ${mesesNumeroLetras[recibidaDate.getMonth()]} ${recibidaDate.getFullYear()} ${strTime}`

    // console.log('recibida', recibida)

    // const ItemHistoryStatus = order.xitem_orderItemHistStatusAndTimestamp.split('@@')

    // ItemHistoryStatus.map(itemHisStatus => {

    //     console.log('itemHisStatus', itemHisStatus)
    //     const statusArray = itemHisStatus.split('_')
    //     console.log('statusArray', statusArray)

    //     const status = statusArray[0]
    //     const fechaStatus = `${statusArray[1]}`

    //     console.log('statusFecha: ', status, ' - ', fechaStatus )

    // })

    if (order.shipModeCode === "Mail") {
      return deliveryEstatus[order.x_statusFinal.estatus];
    }

    if (order.shipModeCode === "PickupInStore") {
      return pickUpEstatus[order.x_statusFinal.estatus];
    }
  };

  function priceWithComas(price: any) {
    const result = parseFloat(price)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return result;
  }

  let productBoxDataInstallation: any = null;
  let unitPriceFormatInstallation: any = null;
  let subTotalPriceFormatInstallation: any = null;

  if (hasInstallation) {
    productBoxDataInstallation = {
      thumbnail: hasInstallation.thumbnail,
      manufacturer: hasInstallation.manufacturer,
      name: hasInstallation.name,
      shortDescription: hasInstallation.shortDescription,
      partNumber: hasInstallation.partNumber,
      isInstalacion: true,
      skuInstallation: order.partNumber,
      isTYP: order?.isTYP,
    };

    // unitPriceFormatInstallation = customPriceWithComas(hasInstallation.unitPrice);
    unitPriceFormatInstallation =
      hasInstallation && hasInstallation?.adjustment && hasInstallation?.adjustment?.length !== 0
        ? customPriceWithComas(
            parseFloat(hasInstallation.unitPrice) + parseFloat(hasInstallation.salesTax) / hasInstallation.quantity
          )
        : customPriceWithComas(
            parseFloat(hasInstallation.unitPrice) +
              parseFloat(hasInstallation.salesTax) / hasInstallation.quantity +
              (parseFloat(hasInstallation.totalAdjustment.value) +
                parseFloat(hasInstallation.totalAdjustment.value) * (parseFloat("16") / 100)) /
                parseFloat(hasInstallation.quantity)
          );
    // subTotalPriceFormatInstallation = customPriceWithComas(parseFloat(hasInstallation.orderItemPrice));
    subTotalPriceFormatInstallation =
      hasInstallation &&
      customPriceWithComas(
        parseFloat(hasInstallation.orderItemPrice) +
          parseFloat(hasInstallation.salesTax)
      );
  }

  const productBoxData = {
    thumbnail: order.thumbnail,
    manufacturer: order.manufacturer,
    name: order.name,
    partNumber: order.partNumber,
    isInstalacion: order.isInstalacion,
  };

  const unitPriceFormat =
    order && order?.adjustment && order?.adjustment?.length !== 0
      ? customPriceWithComas(
          parseFloat(order.unitPrice) + parseFloat(order.unitPrice) * (parseFloat(order.xitem_taxRate) / 100)
        )
      : customPriceWithComas(
          parseFloat(order.unitPrice) +
            parseFloat(order.unitPrice) * (parseFloat(order.xitem_taxRate) / 100) +
            (parseFloat(order.totalAdjustment.value) +
              parseFloat(order.totalAdjustment.value) * (parseFloat(order.xitem_taxRate) / 100)) /
              parseFloat(order.quantity)
        );

  const subTotalPriceFormat =
    order &&
    customPriceWithComas(
      parseFloat(order.orderItemPrice) +
        parseFloat(order.orderItemPrice) * (parseFloat(order.xitem_taxRate) / 100) +
        (parseFloat(order.totalAdjustment.value) +
          parseFloat(order.totalAdjustment.value) * (parseFloat(order.xitem_taxRate) / 100))
    );

  // const orderEstatus = selectOrdenEstatus()
  const orderDetail = JSON.parse(JSON.stringify(order));
  orderDetail.isMobile = true;
  orderDetail.verHistorial = true;
  // orderDetail.orderEstatus = orderEstatus ? orderEstatus : 'En proceso'

  return (
    <ProductMyOrderContainer className={`product-container ${index === 0 ? "styled-border-first" : ""}`}>

      <StyledBox>
        <StyledBox display="flex" className="styled-border-bottom">
          <StyledBox width={2 / 3} pt={2} pb={orderDetail?.isInstalacion ? 2 : 0}>
            <ProductBoxInfo productBoxData={productBoxData} />
            {orderDetail?.isInstalacion || orderDetail?.isTYP ? null : (
              <StyledBox py={2}>
                <StyledLinkTextSecondary className="color-secondary" onClick={() => setIsOpen(true)}>
                  Seguimiento de entrega
                </StyledLinkTextSecondary>
              </StyledBox>
            )}
            <StyledCartRowPaint rowData={order} variant="TYP" />
          </StyledBox>

          <StyledBox display="flex" alignItems="center" justifyContent="center" width={1 / 3} className="styled-border-left">
            <StyledBox align="center">
              <StyledTypography variant="bodyCaption" className="color-gray300">
                Precio
              </StyledTypography>
              <StyledTypography
                className="product-price"
                variant="bodyBaseline"
                weight="semibold"
                >
                <sup className="styled-fontsize-10">$</sup>
                {unitPriceFormat.unitPrice}
                <sup className="styled-fontsize-10">{unitPriceFormat.decimalPrice}</sup>
              </StyledTypography>
            </StyledBox>
          </StyledBox>
        </StyledBox>

      </StyledBox>

      <StyledBox display="flex">
        {orderDetail?.isTYP ? null : (
          <StyledBox width={1 / 3} py={2} align="center">
            <StyledTypography variant="bodyCaption" className="color-gray300">
              Estatus
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold">
              {orderDetail?.x_HistorialStatus[0]?.estatus}
            </StyledTypography>
          </StyledBox>
        )}

        <StyledBox width={1 / 3} py={2} align="center">
          <StyledTypography variant="bodyCaption" className="color-gray300">
            Cantidad
          </StyledTypography>
          <StyledTypography className={"product-quantity"} variant="bodyBaseline" weight="semibold">
            {parseInt(orderDetail.quantity)}
          </StyledTypography>
        </StyledBox>

        {orderDetail?.isTYP ? (
          <StyledBox width={1 / 3} pr={2} display="flex" align="center" justifyContent="center" flexDirection="column" className="styled-border-left">
            {productDiscount && !promotions ? (
              <>
                <StyledTypography variant="bodyCaption" className="color-gray300">
                  Precio regular
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight="semibold">
                  <sup className="styled-fontsize-10">$</sup>
                  <span style={{ textDecoration: "line-through" }}>{productFullPrice.unitPrice}</span>
                  <sup className="styled-fontsize-10">{productFullPrice.decimalPrice}</sup>
                </StyledTypography>
                <StyledBox>
                  <Divider orientation="vertical" />
                </StyledBox>
              </>
            ) : promotions ? (
              <>
                <StyledTypography variant="bodyCaption" className="color-gray300">
                  Promoción
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight="semibold">
                  {promoNumNxM ? promoNumNxM : CONSTANTS.promotions[promotions] ? CONSTANTS.promotions[promotions] : promotions}
                </StyledTypography>
                <StyledBox>
                  <Divider orientation="vertical" />
                </StyledBox>
              </>
            ) : null}
          </StyledBox>
        ) : null}

        <StyledBox display="flex" py={2} alignItems="center" justifyContent="center" width={1 / 3} className="styled-border-left">
          <StyledBox>
            <StyledTypography variant="bodyCaption" className="color-gray300">
              Subtotal
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold">
              {parseInt(subTotalPriceFormat.unitPrice) > 0 ?
                <>
                  <sup className="styled-fontsize-10">$</sup>
                  {subTotalPriceFormat.unitPrice}
                  <sup className="styled-fontsize-10">{subTotalPriceFormat.decimalPrice}</sup>
                </>
              : 
                <>
                  {t("OrderTotalSummary.Labels.Free")}
                </>  
              }

            </StyledTypography>
          </StyledBox>
        </StyledBox>
      </StyledBox>

      {hasInstallation ? (
        <>
          <StyledBox mb={2}>
            <Divider />
          </StyledBox>

          {orderDetail?.isTYP ? null : (
            <>
              <StyledBox display="flex" mb={2}>
                <StyledTypography variant="bodyBaseline">Instalaciones</StyledTypography>

                <StyledBox px={2}>
                  <Divider orientation="vertical" />
                </StyledBox>

                <StyledTypography variant="bodyBaseline" weight="semibold">
                  Te llamaremos para agendar la fecha
                </StyledTypography>
              </StyledBox>
              <StyledBox>
                <Divider />
              </StyledBox>
            </>
          )}

          <StyledBox display="flex">
            <StyledBox width={2 / 3} pt={2} pb={2}>
              <ProductBoxInfo productBoxData={productBoxDataInstallation} />
              <StyledCartRowPaint rowData={order} variant="TYP" />
            </StyledBox>

            <StyledBox>
              <Divider orientation="vertical" />
            </StyledBox>

            <StyledBox display="flex" alignItems="center" justifyContent="center" width={1 / 3}>
              <StyledBox align="center">
                <StyledTypography variant="bodyCaption" className="color-gray300">
                  Precio
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight="semibold">
                  <sup className="styled-fontsize-10">$</sup>
                  {unitPriceFormatInstallation.unitPrice}
                  <sup className="styled-fontsize-10">{unitPriceFormatInstallation.decimalPrice}</sup>
                </StyledTypography>
              </StyledBox>
            </StyledBox>
          </StyledBox>

          <StyledBox>
            <Divider />
          </StyledBox>

          <StyledBox display="flex">
            <StyledBox width={1 / 3} py={2} align="center">
              <StyledTypography variant="bodyCaption" className="color-gray300">
                Cantidad
              </StyledTypography>
              <StyledTypography variant="bodyBaseline" weight="semibold">
                {parseInt(orderDetail.quantity)}
              </StyledTypography>
            </StyledBox>

            <StyledBox width={1 / 3} py={2} align="center"></StyledBox>

            <StyledBox>
              <Divider orientation="vertical" />
            </StyledBox>

            <StyledBox display="flex" py={2} alignItems="center" justifyContent="center" width={1 / 3}>
              <StyledBox>
                <StyledTypography variant="bodyCaption" className="color-gray300">
                  Subtotal
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight="semibold" >
                  <sup className="styled-fontsize-10">$</sup>
                  {subTotalPriceFormatInstallation.unitPrice}
                  <sup className="styled-fontsize-10">{subTotalPriceFormatInstallation.decimalPrice}</sup>
                </StyledTypography>
              </StyledBox>
            </StyledBox>
          </StyledBox>
          <StyledBox className="bottom-margin-2">
            {/* <Divider /> */}
          </StyledBox>
          
          <StyledBox className="styled-terms-conditions bottom-margin-2" >
            <StyledTypography variant="bodyCaption" className="color-gray200">
              Consulta los
            </StyledTypography>
            <StyledLink to={"/condiciones-servicios-instalacion"} className="left-margin-1">
              <StyledTypography variant="bodyCaption">
                Terminos y condiciones
              </StyledTypography>
            </StyledLink>
          </StyledBox>
          
        </>
      ) : null}

      {orderDetail.isInstalacion ? null : (
        <StyledSwipeableDrawerHDM
          isOpen={isOpen}
          setOpenDrawer={setIsOpen}
          variant={"seguimiento-entrega"}
          title={productOrderDrawerTitle}>
          <StyledBox display="flex" justifyContent="space-between" flexDirection="column" px={2} height="100%">
          <StyledBox>
            <StyledBox mt={4}>
              <ProductBoxInfo productBoxData={productBoxData} />
            </StyledBox>
            {/* <EstatusMiOrdenCard orderDetails={orderDetail}/> */}
              <StyledBox mt={4}>
                <OrderHistorySteper orderDetails={orderDetail} />
              </StyledBox>
            </StyledBox>
            {/* Sigue tu entrega en tiempo real */}
            {reference !== "" && (
              <StyledBox  mb={2}>
                <StyledButton
                  testId="realtime-tracking"
                  color="primary"
                  variant="outlinedPrimary"
                  size="small"
                  href={reference}
                  style={{ float: "right" }}
                  target="_blank"
                  // disabled= {statusCheck}>
                  >
                  Sigue tu entrega en tiempo real
                </StyledButton>
              </StyledBox>
            )}
          </StyledBox>
        </StyledSwipeableDrawerHDM>
      )}
    </ProductMyOrderContainer>
  );
};

export default ProductoMiOrden;
