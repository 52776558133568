/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const ComponentWrapper = (props: any) => <Grid {...props} />;

const StyledGrid = styled(ComponentWrapper)`
  ${({ theme }) => `
    &.form-myaccount-selected{
      padding: 15px 5px 0 5px;
      > * {
        > * {
          margin-top: 0px;
        }
      }
    }
    & .cart-card-instruction{
        margin-bottom: 0;
        width: 100%;
        & .card-card-title{
            display: flex;
            font-size: 20px;
            font-weight: 300;
            text-align: center;
            margin: 0 0 5px 0;
            color: #333;
            gap: 10px;
            justify-content: center;
        }
        & .cart-card-description{
            display: flex;
            justify-content: center;
            margin: 0;
            color: #777;
            font-size: 1em;
            text-align:center;
            padding: 0 10px 10px;
        }
        & .handyman-icon{
            color: #777;
            font-size: large;
        }
    }
    &.alignleft-flexcontent-mobile {
      ${theme.breakpoints.down("sm")} {
        margin-left: auto;
      }
    }

    &.sidebar {
      ${theme.breakpoints.down("sm")} {
        padding: 0;
      }
    }

    &.contenedorProductList {
      margin-bottom: ${theme.spacing(2)}px;
      ${theme.breakpoints.down("sm")} {
        /*direction: colum*/
        justify-content: center;
        align-items; center;
      }

      ${theme.breakpoints.up("xl")} {
        /*border: 1px solid orange;*/
        /*justify-content: space-between;*/
        
      }
     

    }

    &.MuiGrid-grid-lg-3 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 25%;
  }

    &.facets--styledGrid-sideBar{
      .productFilter--container-desktop{
        ${theme.breakpoints.down("sm")} {
          display: none;
        }
      }

      ${theme.breakpoints.down("md")} {
        min-width: 22%;
        max-width: 22%;
        flex-basis: 22%;
      }
      ${theme.breakpoints.down("xs")} {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
      ${theme.breakpoints.up("lg")} {
        min-width: 18%;
        max-width: 18%;
        flex-basis: 25%;
      }
    }

    &.productList--styledGrid-container{
      ${theme.breakpoints.up("sm")} {
        min-width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
      ${theme.breakpoints.up("md")} {
        min-width: 70%;
        max-width: 88%;
        flex-basis: 78%;
      }
      ${theme.breakpoints.up("lg")} {
        min-width: 70%;
        max-width: 82%;
        flex-basis: 82%;
      }

    }

    ${theme.breakpoints.down("xs")} {
      &.checkout-actions {
        flex-direction: column;

        .MuiGrid-item {
          .MuiButtonBase-root {
            width: 100%;
          }
        }
      }
    }

    .product-card-padding {
      ${theme.breakpoints.down("sm")} {
        padding: 10px;
        width: 100%;
      }
    }
  `}
`;

export { StyledGrid };
