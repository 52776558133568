import React, { FC } from "react";
import styled from "styled-components";
import { StyledFormControl, StyledInputLabel, StyledSelect, StyledMenuItem } from "../../../hdm/elements";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../skeleton-loader/actions/sl-actions";


interface ISorterSelecthdmProps {
    sortOptions: [];
    selectedSortOption: string;
    onSortOptionChange: (event) => void;
    cid: any; //Unique identifier used for layouts.
    translation: any; //The translation JSON object for i18n.
    isB2B: boolean;
}

const StyledHDMFormControl = styled((props: any) => <StyledFormControl {...props} />)`
    ${({ theme, isB2B }) => `
    min-width: 225px;

    ${isB2B ?
        "margin-bottom: 20px;"
        :
        "margin-top: 20px; margin-left: 5px;"
    };


    ${theme.breakpoints.down("sm")} {
        ${isB2B ?
            "padding-left: 15px; margin-top: 10px; margin-bottom: 5px;"
            :
            "margin-top: 20px; margin-left: 5px;"
        };

    }

    & .inputlabel-sortby{
        background-color: transparent;
        transform: translate(14px, -5px) scale(.95) !important;
    }

    & .MuiFormControl-root .MuiOutlinedInput-root{
        height: 46px;
    }

    & .MuiSelect-root.MuiSelect-select {
        color: ${theme.palette.text.primary};
    }

    & svg{
        color: ${theme.palette.primary.main};
    }

    & .MuiSelect-nativeInput{
        background-color: red !important;
    }

    



        `}
`;



const SorterSelecthdm: FC<ISorterSelecthdmProps> = ({ sortOptions, selectedSortOption, onSortOptionChange, cid, translation, isB2B }) => {

const loaderState = useSelector(backdropLoaderStateAndSrcSelector)
const isSelectDisabled = loaderState.src === LoaderActions.PLP_GET_PRODUCTS_SORT 
                        || loaderState.src === LoaderActions.PLP_GET_PRODUCTS_FACET_SELECTION
                        || loaderState.src === LoaderActions.PLP_GET_PRODUCTS_PAGE_CHANGE


return (
    <StyledHDMFormControl variant="outlined" fullWidth isB2B={isB2B} disabled={isSelectDisabled} >
        <StyledInputLabel id="sort-selection" className="inputlabel-sortby" >  Ordenar por</StyledInputLabel>
        <StyledSelect
            label={"Ordenar por"}
            labelId="sort-selection"

            // preventFocus
            id={`productGrid_select_6_${cid}`}
            value={selectedSortOption}
            IconComponent={KeyboardArrowDown}
            // native
            onChange={(event: any) => onSortOptionChange(event)}
            fullWidth
        >
        {/* {sortOptions?.map((option: any, index: number) => (
            <option value={option.value} key={option.value} id={`productGrid_option_7_${index}_${cid}`}>
            {translation.optiontranslationKey[index]}
            </option>
        ))} */}
        {sortOptions?.map((option: any, index: number) => (
            <StyledMenuItem value={option.value} key={option.value} id={`productGrid_option_7_${index}_${cid}`}>
            {translation.optiontranslationKey[index]}
            </StyledMenuItem>
        ))}
        </StyledSelect>
    </StyledHDMFormControl>
);
};

export default SorterSelecthdm;
