import { useTranslation } from "react-i18next";

import TimerIcon from "@material-ui/icons/Timer";

import { ContainerStyledBox, ContainerStyledTypography } from "./containers-disponibilidad";

const TiempoEstimadoEntrega = (props) => {
  const { leadTime } = props
  const { t } = useTranslation();
  
  return (
    <>
      <ContainerStyledBox  display="flex" height={22} alignItems="flex-end" className="tiempo-estimado">
        <TimerIcon className="styled--timer-icon" style={{ marginBottom: "-1px" }} />
        <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
        {" "}{parseInt(leadTime)} - {parseInt(leadTime) + 2} {"días"}
        </ContainerStyledTypography>
        <ContainerStyledTypography variant="bodyBaseline" component="span">
          {" "}{t("productDetail.Availability.labels.estimatedDelivery")}
        </ContainerStyledTypography>
      </ContainerStyledBox>
    </>
  );
};

export default TiempoEstimadoEntrega;
