import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@material-ui/core";
import { palette } from "../../../../themes/color-palette";

import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
} from "../../containers-precio-promocion";

import ProductQuantity from "../../../../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import AddProductCart from "../../../../functions/AddProductCart";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";

interface Props {
  articulo: any;
}
const AhorraMasProducto = (props: Props) => {
  const { articulo } = props;

  const nominalQuantity = articulo?.["x_measurements.nominalQuantity"]
    ? articulo?.["x_measurements.nominalQuantity"]
    : "1";

  const [quantity, setQuantity] = useState<string>("1");
  const storeConfData = useSelector(onlineStoreRdc);

  const handleSetQuantity = (value: string) => {
    setQuantity(value);
  };

  const imageURL = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${articulo?.partNumber}/${articulo?.partNumber}-m.jpg`;

  return (
    <>
      <ContainerStyledBox className="dialog-seccion-productos">
        <ContainerStyledBox display="flex">
          <ContainerStyledBox>
            <ContainerStyledCardMedia image={imageURL} className="dialog-ahorramas-image" title="" component="img" />
          </ContainerStyledBox>
          <ContainerStyledBox className="dialog-marca-descripcion">
            <ContainerStyledBox className="product-title-text">
              <ContainerStyledTypography variant="bodyBaseline" weight="semibold" component="span">
                {articulo.manufacturer}&nbsp;
              </ContainerStyledTypography>
              <ContainerStyledTypography variant="bodyBaseline" component="span">
                {articulo.name}&nbsp;
              </ContainerStyledTypography>
            </ContainerStyledBox>
            <ContainerStyledTypography variant="bodySubText" className="dialog-textsku" component="p">
              SKU {articulo.partNumber}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>

        <ContainerStyledBox className="input-less-more">
          <ProductQuantity
            nominalQuantity={nominalQuantity}
            quantity={quantity}
            handleSetQuantity={handleSetQuantity}
          />
          <ContainerStyledBox className="button-agregar">
            <AddProductCart
              quantity={quantity}
              partnumber={articulo.partNumber}
              variant="outlined"
              wording="Agregar"
              manufacturer={articulo?.manufacturer}
              attributes={articulo?.attributes}
            />
          </ContainerStyledBox>
        </ContainerStyledBox>
        <Divider style={{ color: palette.gray[50] }} />
      </ContainerStyledBox>
    </>
  );
};

export default AhorraMasProducto;
