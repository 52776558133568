export interface ShopperActiosProductAttributesInterface {
    storeOnlyAvailable: boolean;
    promoIconPC: boolean;
}

interface ShopperActionPriceWithCommas {
    offerPrice: string;
    offerMedidaPrice: string;
}


export interface ShopperActionsDataInterface {
    marketAvailable: number;
    currentStoreAvailable: number;
    field2: string;
    price: number;
    attributes: ShopperActiosProductAttributesInterface;
    quantityMeasure: string;
    priceWithCommas: ShopperActionPriceWithCommas;
    addToCart:any;
}

export const shopperActionsDataInitData:ShopperActionsDataInterface = {
    marketAvailable: 0,
    currentStoreAvailable: 0,
    field2: "",
    price: 0,
    attributes: { storeOnlyAvailable: false, promoIconPC: false },
    quantityMeasure: "",
    priceWithCommas: {
        offerPrice: "0",
        offerMedidaPrice: "0"
    },
    addToCart: undefined
}

export enum ShopperActionsList {
    SET_MARKET_AVAILABILITY,
    SET_PRICE,
    SET_FIELD2,
    SET_ATTRIBUTES,
    SET_QUANTITY_MEASURE,
    SET_PRICE_WITH_COMMAS,
    LOAD_DATA
}

export function shopperActionsReducer(state: ShopperActionsDataInterface, action) {
    switch (action.type) {
        case ShopperActionsList.LOAD_DATA:
            return { ...state, ...action.payload }
        default:
            throw new Error();
    }
}