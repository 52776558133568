import styled from "styled-components";
import { Card } from "@material-ui/core";

export const StyledCardEmpty = styled(({ ...props }) => <Card {...props} />)`
  ${({ theme }) => `

  & a{
      -webkit-box-align: baseline;
    }

  .no-white-spaces{
    white-space: normal;
  }

  .colorIconPaper {
    color: #FFFFFF;
  }
`}
`;
