/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { tabletBreakpoint } from "../../functions/utils";
import { useState } from "react";

const StyledCarouselProvider = styled(({ ...props }) => <CarouselProvider {...props} />)`
  ${({ theme }) => `
    position: relative;
    padding: 0 ${theme.spacing(0)}px;

    .carousel__back-button,
    .carousel__next-button {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      height: ${theme.spacing(15)}px;
      width: ${theme.spacing(8)}px;
      border: none;
      /*border-radius: 50%;*/
      background: #ffffff;
      opacity: 0.6;
      border: 1px solid ${theme.palette.primary.main};

      & svg.MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
        width:24px;
      }

      &:hover {
        cursor: pointer;
        color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.light};
      }
    }

    .carousel__back-button {
      left: ${theme.spacing(2)}px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .carousel__next-button {
      right: ${theme.spacing(2)}px;
      padding-left: 0px;
      padding-right: 0px;
    }

    div {
      outline: none;
    }

    & .prc-dotGroup {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 10px;

      .carousel__dot {
        height: 15px;
        width: 15px;
        margin: 5px;
        border-radius: 50%;
        border-color: unset;
        border:none;
        background-color: #cccccc;
        padding: 0;
      }

      .carousel__dot--selected {
        background-color: ${theme.palette.primary.main};
      }
    }
    
   `}
`;

const StyledSlide = styled(({ ...props }) => <Slide {...props} />)`
  ${({ theme }) => `
   ${useMediaQuery(theme.breakpoints.down("md")) ? "height: 444px;" : ""}
   ${useMediaQuery(theme.breakpoints.down("sm")) ? "height: 205px;" : ""}
   ${useMediaQuery(theme.breakpoints.down("xs")) ? "height: 170px;" : ""}
   text-align: center;
   > .carousel__inner-slide {
     height: -moz-available;
     height: -webkit-fill-available;
     height: stretch;
   }
   `}
`;

const StyledContentCarouselSlider = ({ slidesList, controls }: any) => {
  const { interval, isPlaying, playDirection, infinite } = controls;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const totalSlides = slidesList.length;

  const [isGTMLoaded, setIsGTMLoaded] = useState<boolean>(false);
  const [isGTMPDP, setIsGTMPDP] = useState<boolean>(false);

  return (
    <StyledCarouselProvider
      {...{ totalSlides, interval, isPlaying, playDirection, infinite }}
      naturalSlideWidth={100}
      naturalSlideHeight={20}>
      <Slider>
        {slidesList.map((slide: any, i: number) => (
          <StyledSlide index={i} key={i}>
            {slide}
          </StyledSlide>
        ))}
      </Slider>
      {!isMobile ? (
        <>
          <ButtonBack>
            <ChevronLeftIcon />
          </ButtonBack>
          <ButtonNext>
            <ChevronRightIcon />
          </ButtonNext>
        </>
      ) : null}
      <DotGroup className="prc-dotGroup" />
    </StyledCarouselProvider>
  );
};
export { StyledContentCarouselSlider };
