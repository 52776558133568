import { KeyboardArrowDown } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Divider, Tooltip } from '@material-ui/core'
import {
  StyledFormControl,
  StyledGrid,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTypography,
  StyledFormHelperText,
  StyledBox,
} from "../../../hdm";
import { StyledStoreSelectorContainer } from "../styled-store-selector/styled-store-selector-container/StyledStoreSelectorContainer";
import { palette } from "../../../hdm/themes";
import { StyledTooltipHdm } from "../styled-tooltip-hdm";

const RowIconSpan = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  width: ${theme.spacing(5)}px;
  padding-top: 7px;
  height: 40px;
  display: inline-block;
  text-align: center;
  margin-right: ${theme.spacing(1)}px;
  z-index: 0;
  cursor: pointer;
}
`}
`;

const StyledKeyboardArrowDown = styled(({ ...props }) => <KeyboardArrowDown {...props} />)`
  ${({ theme }) => `
  display:flex;
  font-size: 24px;
`}
`;

const StyledSelectHdm = (props) => {
  const {isSccSelector = false} = props;
  const { t } = useTranslation();
  const handleChange = props.handleChange;
  const onBlur = props.onBlur;
  const value = props.value;
  const items = props.items ? props.items : null;
  const name = props.name ? props.name : null;
  const autoComplete = props.autoComplete ? props.autoComplete : null;
  const label = props.label;
  const required = props.required ? true : false;
  const disabled = props.disabled ? true : false;
  const error = props?.error ? props.error : false;
  const className = props?.className ? props.className : "";
  const helperText = props.helperText ? props.helperText : "";
  const defaultValue = props.defaultValue ? props.defaultValue : "";
  const id = props.id ? props.id : "styledSelect";

  const [openSelect, setOpenSelect] = useState<boolean>(false)
  const handleOpenSelect = () => {
    setOpenSelect(!openSelect)
  }

  useEffect(() => {
    setOpenSelect(false)
  }, [handleChange])

  return (
    <>
      <StyledStoreSelectorContainer>
        <StyledFormControl variant="outlined" fullWidth>
          <StyledInputLabel 

            id="select-label" 
            className={
              `${disabled ? "general--styledSelect-label-disabled general--styledSelect-label" : "general--styledSelect-label"}
              ${isSccSelector ? "tooltip-container" : ""}
              `
            }
          >
            {label}
            {isSccSelector ? <StyledTooltipHdm content={t("ProSccProducts.TooltipText")}/> : null}
      
          </StyledInputLabel>
          <StyledGrid style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledSelect
              {...props}
              fullWidth={true}
              labelId="select-label"
              id={id}
              style={{ position: "absolute" }}
              onChange={handleChange}
              onClose={handleOpenSelect}
              onOpen={handleOpenSelect}
              open={openSelect}
              >
              {items}
            </StyledSelect>
            <StyledGrid className={"storeLocator--styledGrid-arrowSelect"}>
              <StyledBox py={1.5} style={{zIndex: 2}}>
                <Divider orientation="vertical" style={{ backgroundColor: palette.gray["100"] }} />
              </StyledBox>

              <RowIconSpan onClick={disabled ? null : handleOpenSelect} >
                <StyledKeyboardArrowDown color="primary" />
              </RowIconSpan>
            </StyledGrid>
          </StyledGrid>
          {error && (
            <StyledFormHelperText>
              {helperText}
            </StyledFormHelperText>
          )}
        </StyledFormControl>
      </StyledStoreSelectorContainer>
    </>
  );
};

export { StyledSelectHdm };
