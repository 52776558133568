//Return the pageName to be used on BTT events based on Regex
export const getPageName = (pathname) => {
  const ShoppingCartRegex = new RegExp(`/cart$`);
  const PromotionsRegex = new RegExp(`/promotions$`);
  const OrderShippingBillingRegex = new RegExp(`/checkout$`);
  const CatalogRegex = new RegExp(`/catalogo-extendido$`);
  const OrderShippingBillingConfirmationRegex = new RegExp(`/thank-you$`);
  const StoreFinderRegex = new RegExp(`/buscador-tiendas$`);
  const ShopTheLookRegex = new RegExp(`/inspiracion-compra-espacio$`);
  const HelpRegex = new RegExp(`/ayuda$`);
  const ServicesRegex = new RegExp(`/servicios$`);
  const UserRegistrationRegex = new RegExp(`/signup$`);
  const GeoLocationStoreRegex = new RegExp(`/buscador-tiendas$`);
  const LogonFormRegex = new RegExp(`/login$`);
  const AntiFraudRegex = new RegExp(`/orden-declinada$`);

  const ProductDetailsRegex = new RegExp(`/p/`);
  const SearchDisplayRegex = new RegExp(`/s/`);
  const DepartmentRegex = new RegExp(`/b/([^/]+)$`);
  const CategoryRegex = new RegExp(`/b/([^/]+)/([^/]+)/?$`);
  const SubCategoryRegex = new RegExp(`/b/([^/]+)/([^/]+)/([^/]+)/?$`);

  return ShoppingCartRegex.test(pathname)
    ? "ShoppingCart"
    : PromotionsRegex.test(pathname)
    ? "Promotions"
    : OrderShippingBillingRegex.test(pathname)
    ? "OrderShippingBilling"
    : CatalogRegex.test(pathname)
    ? "Catalog"
    : OrderShippingBillingConfirmationRegex.test(pathname)
    ? "OrderShippingBillingConfirmation"
    : StoreFinderRegex.test(pathname)
    ? "StoreFinder"
    : ShopTheLookRegex.test(pathname)
    ? "ShopTheLook"
    : HelpRegex.test(pathname)
    ? "Help"
    : ServicesRegex.test(pathname)
    ? "Services" //
    : UserRegistrationRegex.test(pathname)
    ? "UserRegistration"
    : GeoLocationStoreRegex.test(pathname)
    ? "GeoLocationStore"
    : LogonFormRegex.test(pathname)
    ? "LogonForm"
    : AntiFraudRegex.test(pathname)
    ? "AntiFraud"
    : ProductDetailsRegex.test(pathname)
    ? "ProductDetails"
    : SearchDisplayRegex.test(pathname)
    ? "SearchDisplay"
    : DepartmentRegex.test(pathname)
    ? "Department"
    : CategoryRegex.test(pathname)
    ? "Category"
    : SubCategoryRegex.test(pathname)
    ? "SubCategory"
    : pathname == "/pro/"
    ? "HomePage"
    : pathname == ""
    ? "HomePage"
    : pathname == "/"
    ? "HomePage"
    : "OtherPage";
};

//This function will handle in localStorage if it's Virtual Trun
export const saveVTFlag = (prevUrl) => {
  sessionStorage.setItem("VTEnabled", prevUrl);
};

export const verifyVT = () => {
  const pageNameValue = getPageName(window.location.pathname);
  const VTEnable = sessionStorage.getItem("VTEnabled");
  if (VTEnable) {
    return `${pageNameValue}-VT`;
  } else {
    return pageNameValue;
  }
};
