import { Divider } from "@material-ui/core";
import styled from "styled-components";
import { StyledBox, StyledLink, StyledLinkTextSecondary } from "../../elements";
import { LinkList } from "./LinkList";

const ContainerComboLinks = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  display: flex;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &.padding-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .styled--link-container {
    width: calc(50% - 2px);
    text-align: center;

  }

  .styled--link-function {
    font-weight: 500;
    color: ${theme.palette.secondary.main};
  }

  .styled--linkfunction-disabled {
    font-weight: 500;
    color: ${theme.palette.gray["200"]};
  }
  
  .styled--divider-color {
    backgrounf-color: ${theme.palette.gray["100"]};

  }

  .styled--url-link {
    text-decoration: none;
  }

`}
`;

const ContainerHorizontalDivider = styled(({ ...props }) => <Divider {...props} />)`
  ${({ theme }) => `

    background-color: ${theme.palette.gray["50"]};
    
`}
`;

export const ComboLinks = (props) => {
  const { handleLink1, handleLink2, page = "" } = props;

  return (
    <>
      {/* <StyledBox className="vertical-padding-2" >
                <ContainerHorizontalDivider />
            </StyledBox> */}
      <ContainerComboLinks className={`${page === "wishlist" ? "padding-0" : null}`}>
        <StyledBox className="styled--link-container">
          {handleLink1.type === "function" ? (
            <StyledLinkTextSecondary
              className={`${handleLink1?.disabled ? "styled--linkfunction-disabled" : "styled--link-function"}`}
              onClick={handleLink1?.disabled ? null : () => handleLink1.handleFunctionLink(handleLink1.data)}>
              {handleLink1.text}
            </StyledLinkTextSecondary>
          ) : handleLink1.type === "list" ? (
            <LinkList
              text={handleLink1.text}
              placement={"bottom-start"}
              dataItems={handleLink1.data}
              option={handleLink1.option}
            />
          ) : (
            <StyledLink to={handleLink1.url} className="styled--url-link">
              {handleLink1.text}
            </StyledLink>
          )}
        </StyledBox>
        <StyledBox>
          <Divider orientation="vertical" className="styled--divider-color" />
        </StyledBox>
        <StyledBox className="styled--link-container">
          {handleLink2.type === "function" ? (
            <StyledLinkTextSecondary
              className="styled--link-function"
              onClick={(e) => handleLink2.handleFunctionLink(handleLink2.data, e)}>
              {handleLink2.text}
            </StyledLinkTextSecondary>
          ) : (
            <StyledLink to={handleLink2.data} className="styled--url-link">
              {handleLink2.text}
            </StyledLink>
          )}
        </StyledBox>
      </ContainerComboLinks>
    </>
  );
};
