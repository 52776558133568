/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { CONSTANTS } from "../../constants/order-item-table";
// import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
import { INVENTORY_STATUS } from "../../constants/order";
import { ORDER_CONFIGS, PAGINATION_CONFIGS } from "../../configs/order";
//Redux
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import { forUserIdSelector, loginStatusSelector, userIdSelector } from "../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../redux/selectors/organization";
//UI
import DeleteIconHDM from "../../assets/homedepot/images/delete-trashcan.svg";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";

//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector, orderItemsSelector } from "../../redux/selectors/order";
import productsService from "../apis/search/products.service";
// import { useWinDimsInEM } from "./use-win-dims-in-em";
import {
  EMPTY_STRING,
  STRING_TRUE,
  // XS_MOBILE_W
} from "../../constants/common";
import { get } from "lodash-es";
import storeUtil from "../../utils/storeUtil";
import { CustomTable, useCustomTable, withCustomTableContext } from "../../hdm/components";
import {
  StyledBox,
  StyledFormControl,
  StyledGrid,
  StyledIconButton,
  StyledPaper,
  StyledRadioGroup,
  StyledTypography,
} from "../../hdm/elements";
import { useTableUtils } from "../../hdm/hooks";
import { TableColumnDef, TableConstants } from "../../hdm";
import { Divider, Hidden } from "@material-ui/core";
// import { style } from '@material-ui/system';
import { StyledLinkButton } from "../../components/custom-components/styled-store-selector/styled-link-button";
import StyledPriceFormat from "../../components/custom-components/styled-price-format/StyledPriceFormat";
import ProductQuantity from "../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import { debounce } from "lodash-es";
import _ from "lodash";
import React from "react";
import { StyledRadioCard } from "../../components/custom-components/styled-store-selector/styled-radio-card/StyledRadioCard";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import { formatProductPrice } from "../../hdm/functions/utils";
import InventarioTiendaFisisca from "../../hdm/components/pdp-disponibilidad/InventarioTiendaFisisca";

import { hdmStoresDataRdc, hdmColorsRdc, onlineStoreRdc } from "../../redux/selectors/hdmData";
import * as customInv from "../../_foundation/utils/inventoryAvailability";
import { StyledPromp } from "../../components/custom-components/styled-promp";
import { StyledCartRowPaint } from "../../components/custom-components/styled-cart-row-paint";
import { StyledDivider } from "../../components/header/categories-menu/categories-styles";
import { Skeleton } from "@material-ui/lab";
import { FETCH_HDM_DATA_REQUESTED } from "../../redux/action-types/hdmData";
import { getPromoicons } from "../../hdm/functions";
import { genericErrorSelector } from "../../redux/selectors/error";
import { StyledWishListButton } from "../../components/custom-components/styled-wishList-button";
import {
  triggerAddToCartDL,
  triggerRemoveFromCartDL,
  triggerSelectItemDL,
} from "../../components/custom-components/Datalayer-Utils/dataLayer-func";
import StyledSwipeableDrawerHDM from "../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import EspotHdm from "../../components/custom-components/shared/EspotHdm";
import { ProductInstalationContainer } from "../../components/custom-components/styled-product-instalation-card/ProductInstalationHDMStyles";
import ProductInstalationHDM from "../../components/custom-components/styled-product-instalation-card/ProductInstalationHDM";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../redux/actions/backdrop-loader";
import { backdropLoaderSelector, backdropLoaderStateAndSrcSelector } from "../../redux/selectors/backdrop-loader";
import withSkeletonLoader from "../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../components/custom-components/skeleton-loader/components";
import { LoaderActions } from "../../components/custom-components/skeleton-loader/actions/sl-actions";
import { SLOverlayComponent } from "../../components/custom-components/skeleton-loader/components/SLOverlayComponent";
import { triggerConversionsPinterestAddToCart } from "../../components/custom-components/conversion-api/pinterest/pinterest-conversion-utils";

const DetailPanel = ({ rowData, ...props }) => {
  const { attributes: rawData } = rowData;
  const cellStyle = { verticalAlign: "middle" };
  const { t } = useTranslation();
  const attrs = rawData?.filter((a) => STRING_TRUE === a.displayable);

  // generate headers array
  const columns = attrs?.map((a, i) => ({
    title: a.name,
    idProp: "name",
    keyLookup: { key: `attr_${i}_value` },
    display: { cellStyle },
  }));

  // generate single row out of all attribute values
  const data = [
    attrs?.reduce((n, v, i) => {
      n[`attr_${i}_value`] = storeUtil.csValue(get(v, "values[0].value", t("CommerceEnvironment.inventoryStatus.NA")));
      return n;
    }, {}),
  ];

  const className = "detailPanel table-tablet";
  const style = { width: "auto", border: "0" };
  const D = useMemo(() => withCustomTableContext(CustomTable), []);
  return columns?.length > 0 ? (
    <D
      {...{
        t,
        data,
        columns,
        style,
        className,
        outerClassName: "order-item-table-drawer",
        labels: { emptyMsg: "InprogressItems.noItems" },
      }}
    />
  ) : null;
};

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const useOrderItemTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const storeSelector = useSelector(currentStoreSelector);
  const currentMarketId = storeSelector?.currentStore?.marketId;
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const contractId = useSelector(currentContractIdSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
  const dataProps = props.data;
  const { tableState, setTableState } = useCustomTable();
  const { setValueForCell } = useTableUtils();
  const [actionData, setActionData] = useState<any>(null);
  const variant = props.variant ? props.variant : "cart";
  const usableShippingInfoCart = props.usableShippingInfoCart;
  const servicesInstallationInCart = props.servicesInstallationInCart;
  const servicesInstallationInCartIds = props.servicesInstallationInCartIds;
  const currentStore = useSelector(currentStoreSelector);
  const orderItems = useSelector(orderItemsSelector);

  const deliveryMethod = props.deliveryMethod;
  // const stockAvailableStore = props.stockStore;
  const [stockAvailableStore, setStockAvailableStore] = useState<any>([]);
  const isTV = props.isTV;
  const storeConfData = useSelector(onlineStoreRdc);
  const [bopisModeCarrier, setBopisModeCarrier] = useState("");
  const [deliveryModeCarrier, setDeliveryModeCarrier] = useState("");

  const isDropShipBopisEnabledFlag =
    storeConfData?.isDropShipBopisEnabled !== undefined ? storeConfData.isDropShipBopisEnabled : "false";

  useEffect(() => {
    setStockAvailableStore(props.stockStore);
  }, [props.stockStore]);

  const readOnly = props.readOnly !== undefined ? props.readOnly : true;
  const miniCartView = props.miniCartView !== undefined ? props.miniCartView : false;

  /**
   * Initialize table data by making a copy
   * Material-table alters the input data, so data cannot be of immutable type
   * @returns Copy of the data prop
   */
  const data = useMemo(() => {
    if (variant === "TYP") {
      const newData = dataProps.filter((dataP) => dataP.carrier === deliveryMethod);
      return newData;
    } else {
      const newData = (dataProps ?? []).map((oi) => ({ ...oi }));
      return newData;
    }
  }, [dataProps]);

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  //update this flag as need later
  const pagination = !readOnly && !miniCartView;
  const handleMiniCartClose = props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
  const { seller } = props;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const isB2B = mySite?.isB2B;
  const contract = useSelector(currentContractIdSelector);
  const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
  const cart = useSelector(cartSelector);
  const handleClick = () => {
    setOpenDrawer(true);
  };

  const payloadBase: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  // useEffect(() => {
  //   if (data && data.length > 0 && !data[0]?.name) {
  //     dispatch(orderActions.GET_CART_ACTION({ ...payloadBase, fetchCatentries: true }));
  //   }
  // }, []);

  /**
   * Initialize quantity data per order item
   * @returns quantities object for each order item
   */
  const initQuantityData = () => {
    const newData: any = {};
    if (dataProps) {
      dataProps.map((oi) => {
        if (oi.quantity) {
          try {
            const parsedQty = parseInt(oi.quantity);
            if (parsedQty > 0) {
              newData[oi.orderItemId] = parsedQty;
            }
          } catch (e) {
            console.log("Could not parse quantity");
          }
        }
        return null;
      });
    }
    return newData;
  };
  const [quantityList, setQuantityList] = useState<any>(initQuantityData());
  const defaultOptions = {
    toolbar: false,
    header: !miniCartView,
    paging: pagination,
    pageSize: PAGINATION_CONFIGS.pageLimit,
    pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
    actionsColumnIndex: -1,
    fixedColumns: {
      left: 0,
      right: 0,
    },
  };
  const options = props.options !== undefined ? props.options : defaultOptions;

  const capitalizedFirstLetter = (productName: string) => {
    const firstLetter = productName?.charAt(0);
    const remainingLetters = productName?.slice(1).toLowerCase();
    return firstLetter + remainingLetters;
  };

  const columns = useMemo(
    () => {
      const productosDisponibles: any = [];
      const cancels: Canceler[] = [];
      const payloadBase: any = {
        currency: defaultCurrencyID,
        contractId: contractId,
        widget: "Order Item Table",
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
        currentStore: currentStore,
      };
      const itemValCalc = ({ rowData: r }) => r.name || r.partNumber;
      const oaValCalc = ({ rowData: r }) => {
        return r.availableDate === ""
          ? r.orderItemInventoryStatus === INVENTORY_STATUS.available ||
            r.orderItemInventoryStatus === INVENTORY_STATUS.allocated
            ? t("CommerceEnvironment.inventoryStatus.Available")
            : t("CommerceEnvironment.inventoryStatus.OOS")
          : r.availableDate <= new Date()
          ? t("CommerceEnvironment.inventoryStatus.Available")
          : r.orderItemInventoryStatus !== INVENTORY_STATUS.backordered
          ? t("CommerceEnvironment.inventoryStatus.Available")
          : t("CommerceEnvironment.inventoryStatus.Backordered");
      };
      const priceCalc = ({ rowData: r }) => Number(r.orderItemPrice);
      const quantityCalc = ({ rowData: r }) => Number(quantityList[r.orderItemId]);

      const QuantityDisplay = (props: any) => (
        <StyledTypography>{quantityList[props.rowData.orderItemId]}</StyledTypography>
      );

      const QuantityDisplayPaintsMiniCart = (props: any) => (
        <>
          <StyledGrid className="styledCartRowPaint--styledGrid-QuantityDisplayPaintsMiniCart">
            <StyledTypography variant="bodyBaseline">
              {t("productDetail.Quantity")} {quantityList[props.rowData.orderItemId]}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"}>
              {formatProductPrice(props.rowData.orderItemPrice)}
            </StyledTypography>
          </StyledGrid>
        </>
      );

      const OrderItemPrice = (props: any) => {
        const priceAmount =
          parseFloat(props.rowData.orderItemPrice) +
          parseFloat((props.rowData.totalAdjustment && props.rowData.totalAdjustment.value) || 0);

        return (
          <StyledPriceFormat
            priceUnformatted={priceAmount}
            TypographyVariant="textSecondaryPrice"
            discountType={priceAmount === 0}
          />
        );
      };
      let blockDropshipOrder = false;
      const ThumbnailCell = ({ rowData, ...props }) => {
        const inventoryAvailability = {
          currentInventoryDelivery: "999",
          hasInventoryDelivery: true,
          hasInventoryBopis: true,
          deliveryType: "",
          hasStockDelivery: true,
          hasStockBopis: true,
          availableQuantityInMarket: "999",
          bopisStock: "999",
          errorDelivery: false,
          errorBopis: false,
        };
        let isDropshipBopisDisabled = false;
        let isBopisDisabled = false;
        // const isDropship = rowData?.xitem_isDropShip && rowData?.xitem_isDropShip === "true" ? true : false;

        // Merchadising assoc logic
        const merchandisingAssociations =
          rowData?.merchandisingAssociations !== undefined ? rowData.merchandisingAssociations : [];
        let services: Array<any> = [];
        if (merchandisingAssociations && merchandisingAssociations !== undefined) {
          services = merchandisingAssociations.filter(
            (merchandisingAssociation) =>
              merchandisingAssociation.associationType && merchandisingAssociation.associationType === "TechnicalVisit"
          );
          // If we dont found TechnicaVisit, so we will check Installation
          if (services.length === 0) {
            services = merchandisingAssociations.filter(
              (merchandisingAssociation) =>
                merchandisingAssociation.associationType && merchandisingAssociation.associationType === "Installation"
            );
          }
        }

        if (rowData?.orderItemInventoryStatus === INVENTORY_STATUS.available &&
          rowData?.xitem_itemLevelDeliveryType === "1"
        ) {
          // If isDropShipBopisEnabledFlag = false all Dropship Skus will be disabled when is bopis
          if (isDropShipBopisEnabledFlag === "false") {
            blockDropshipOrder = true;
          }
          const { attributes } = rowData;
          let fultypeArr = [];
          if (attributes !== undefined) {
            fultypeArr = attributes.filter((value) => value.identifier === "DROPSHIP_DISABLED_PRODUCTS");
            if (fultypeArr != null && fultypeArr.length > 0) {
              isBopisDisabled = true;
            }
          }
          if (blockDropshipOrder || isBopisDisabled) {
            isDropshipBopisDisabled = true;
          }
        }
        let brand = "";
        const [deliveryType, setDeliveryType] = useState("");
        const paintItemNumber = rowData?.xitem_colorCode ? rowData.xitem_colorCode.split(",") : [];

        const thumbnailMini = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${rowData.partNumber}/${rowData.partNumber}-m.jpg`;

        const shippingMode = usableShippingInfoCart?.filter(
          (usableShipping) => usableShipping.orderItemId === rowData.orderItemId
        );

        if (shippingMode?.length > 0) {
          const filtShippingMode = shippingMode[0].usableShippingMode;
          const itemShippingModeBopis = filtShippingMode.filter(
            (usableShipping) => usableShipping.shipModeCode === CONSTANTS.bopis
          );
          setBopisModeCarrier(itemShippingModeBopis[0].carrier);
          const itemShippingModeDelivery = filtShippingMode.filter(
            (usableShipping) => usableShipping.shipModeCode === CONSTANTS.delivery
          );
          setDeliveryModeCarrier(itemShippingModeDelivery[0].carrier);
        }

        const handleDeliveryType = (value) => {
          if (value !== "") {
            setDeliveryType(value);
            deliveryTypeChange(value, rowData);
          }
        };

        useEffect(() => {
          if (deliveryType === "") {
            setDeliveryType(rowData.carrier);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [rowData]);

        /**
         * Changes the delivery method for each item sended
         * @param item The selected order item
         */
        const deliveryTypeChange = async (deliverType: string, items: any) => {
          let deliveryTypeId = "";
          if (shippingMode?.length > 0) {
            const filtShippingMode = shippingMode[0].usableShippingMode;
            const itemShippingModeBopis = filtShippingMode.filter(
              (usableShipping) => usableShipping.carrier === deliverType
            );
            deliveryTypeId = itemShippingModeBopis[0].shipModeId;
          }

          if (items.orderItemId) {
            dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.DELIVERY_TYPE_CHANGE, idx: items.orderItemId }));
            if (deliverType === CONSTANTS.delivery) {
              const payloadCart = {
                orderId: "*",
                orderItem: [{ orderItemId: rowData.orderItemId, contractId: contractId }],
                x_deliverType: CONSTANTS.deliveryShipType,
                x_page: CONSTANTS.x_pageCartItemUpdate,
                x_pageLoad: CONSTANTS.x_pageLoadCartItemUpdate,
                x_addressId:
                  usableShippingInfoCart && usableShippingInfoCart[0].usableShippingAddress
                    ? usableShippingInfoCart[0]?.usableShippingAddress[0]?.addressId
                    : undefined,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                x_realtimeInventory: ORDER_CONFIGS.x_realtimeInventory,
              };

              await Axios.put(
                `/wcs/resources/store/${mySite.storeID}/cart/@self/update_order_item?responseFormat=json`,
                payloadCart
              ).catch((error) => {
                dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
                console.log(error);
              });

              const payload = {
                ...payloadBase,
                orderItemId: [
                  { orderItemId: rowData.orderItemId, quantity: rowData.quantity, shipModeId: deliveryTypeId },
                ],
                deliverType: deliverType,
                x_realtimeInventory: CONSTANTS.x_realtimeInventory,
                x_deliverType: CONSTANTS.x_deliverType,
                x_page: CONSTANTS.x_pageCartItemUpdate,
                x_pageLoad: CONSTANTS.x_pageLoadCartItemUpdate,
              };

              await dispatch(orderActions.UPDATE_SHIPMODE_ACTION(payload));
            } else {
              const payloadCart = {
                orderId: "*",
                orderItem: [{ orderItemId: rowData.orderItemId, contractId: contractId, shipModeId: deliveryTypeId }],
                x_deliverType: CONSTANTS.x_bopis,
                x_page: CONSTANTS.x_pageCartItemUpdate,
                x_pageLoad: CONSTANTS.x_pageLoadCartItemUpdate,
                fetchCart: false,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                x_realtimeInventory: ORDER_CONFIGS.x_realtimeInventory,
              };

              await Axios.put(
                `/wcs/resources/store/${mySite.storeID}/cart/@self/update_order_item?responseFormat=json`,
                payloadCart
              ).catch((error) => {
                dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
                console.log(error);
              });

              const payload = {
                ...payloadBase,
                orderItemId: [
                  {
                    orderItemId: rowData.orderItemId,
                    quantity: rowData.quantity,
                    shipModeId: deliveryTypeId,
                    physicalStoreId: currentStore?.currentStore?.uniqueID,
                  },
                ],
                x_storeZipcode: currentStore.currentStore.zipcode,
                x_realtimeInventory: CONSTANTS.x_realtimeInventory,
                x_deliverType: CONSTANTS.x_bopis,
                x_page: CONSTANTS.x_pageCartItemUpdate,
                x_pageLoad: CONSTANTS.x_pageLoadCartItemUpdate,
              };

              await dispatch(orderActions.UPDATE_SHIPMODE_ACTION(payload));
            }
          }
        };

        if (rowData.attributes) {
          const extractBrand = rowData.attributes.filter((brand) => brand.identifier === "Marca");
          brand = extractBrand[0]?.values[0]?.value ? extractBrand[0]?.values[0]?.value : "";
        }

        const promoNxM =
          rowData && rowData?.attributes?.filter((attribute) => attribute?.identifier?.includes("PROMORIBBON_NXM"));
        const promoNumNxM = promoNxM && promoNxM[0]?.name?.split(" ").slice(-1);
        const promoNlP =
          rowData?.attributes?.filter((attribute) => attribute.identifier === "PROMOICON_NLP").length > 0;

        const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,
            orderItemId: orderItemId,
            fetchCatentries: true,
          };
          //setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            AsyncCall.sendRemoveFromCartEvent(item, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };

        const UnitOfMeasurement = (props: any) => {
          const [value, setValue] = useState<any>(null);
          const dataItem = props.rowData;
          const payloadBase: any = {
            physicalStoreId: currentStore?.currentStore?.stLocId,
            currency: defaultCurrencyID,
            contractId: contractId,
            widget: "Order Item Table",
            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
          };

          const validateMeasure = (unit) => {
            return ["MTK", "MTR", "KGM", "LTR"].includes(unit) ? false : true;
          };

          useEffect(() => {
            if (validateMeasure(dataItem.unitUom)) return;
            const parameters: any = {
              ...payloadBase,
              id: dataItem.productId,
              contractId: contractId,
            };
            productsService
              .findProductsUsingGET(parameters)
              .then((productData: any) => {
                setValue(productData.data.contents[0]);
              })
              .catch((e) => {
                console.log("Could not retrieve product details page information", e);
              });
          }, []);

          let unit_measurement = "";
          let price = "";
          if (value && value != null) {
            const offetData = value.price.filter((b) => b.usage === "Offer")[0];

            if ("field1" in offetData) {
              price =
                Object.keys(offetData.field1).length > 0
                  ? offetData.field1.OMP
                    ? `$${parseFloat(offetData.field1.OMP).toFixed(2)}`
                    : ""
                  : "";
            } else {
              price = "";
            }

            if (price != "") {
              switch (dataItem.unitUom) {
                case "MTK":
                  unit_measurement = " m\u00B2";
                  break;
                case "MTR":
                  unit_measurement = " m";
                  break;
                case "KGM":
                  unit_measurement = " kg";
                  break;
                case "LTR":
                  unit_measurement = " l";
                  break;
                default:
                  unit_measurement = "";
              }
            }
          }
          return (
            <>
              {value ? (
                <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                  {price + unit_measurement}
                </StyledTypography>
              ) : null}
            </>
          );
        };

        const DisponibilidadEnTienda = ({ rowData, ...props }) => {
          const productData = {
            disponibilidadEnTienda:
              deliveryType === CONSTANTS.delivery
                ? inventoryAvailability.availableQuantityInMarket
                : inventoryAvailability.bopisStock,
            showIcon: false,
            showStore: false,
            viewCart: true,
            shipModeCode: deliveryType,
            bahiaData: "",
            tiendaNombre: "",
            locationName: "",
          };
          return (
            <InventarioTiendaFisisca
              productData={productData}
              isB2B={mySite.isB2B}
              bahiaData={productData?.bahiaData}
              disponibilidadEnTienda={Number(productData?.disponibilidadEnTienda)}
              tiendaNombre={productData?.tiendaNombre}
              locationName={productData?.locationName}
            />
          );
        };

        const subTotalMobile = rowData.adjustment ? (
          <StyledGrid
            container
            className={"productDetails--styledGrid-productContainer productDetails--styledGrid-productContainerHeight"}>
            <StyledGrid item xs={4} className={"productDetails--styledGrid-productPriceContainer product-promotion mobile-promotion-conatiner"}>
              {mySite.isB2B ?
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {promoNumNxM || CONSTANTS.promotions[rowData.adjustment[0].description] || rowData.adjustment[0].description 
                ?  t("productDetail.CartOrderItemPromotion")
                :  t("productDetail.CartOrderItemRegularPrice")
                }
              </StyledTypography>
              :
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {t("productDetail.CartOrderItemPromotion")}
              </StyledTypography>
              }
              {mySite.isB2B ?
              <StyledTypography variant="bodyBaseline" weight="semibold" style={{ textAlign: "center" }}>
                 {promoNumNxM
                    ? promoNumNxM
                    : CONSTANTS.promotions[rowData.adjustment[0].description]
                    ? CONSTANTS.promotions[rowData.adjustment[0].description]
                    : rowData.adjustment[0].description 
                    ? rowData.adjustment[0].description
                    : <StyledPriceFormat
                    priceUnformatted={rowData.unitPrice}
                    TypographyVariant="textSecondaryPrice"
                    strikethrough={true}
                  />
                 }
              </StyledTypography>
              :
              <StyledTypography variant="bodyBaseline" weight="semibold" style={{ textAlign: "center" }}>
                {promoNumNxM
                  ? promoNumNxM
                  : CONSTANTS.promotions[rowData.adjustment[0].description]
                  ? CONSTANTS.promotions[rowData.adjustment[0].description]
                  : rowData.adjustment[0].description}
              </StyledTypography>
              }
            </StyledGrid>
            <StyledGrid
              item
              xs={4}
              className={
                "productDetails--styledGrid-productPriceContainer productDetails--styledGrid-productLeftContainer product-saving"
              }>
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {t("productDetail.CartOrderItemYouSave")}
              </StyledTypography>

              {rowData.totalAdjustment.value && (
                <StyledPriceFormat
                  priceUnformatted={rowData.totalAdjustment.value * -1}
                  TypographyVariant="textSecondaryPrice"
                  discountType={true}
                />
              )}
            </StyledGrid>
            <StyledGrid item xs={4} className={"productDetails--styledGrid-productPriceContainer product-subtotal"}>
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {t("productDetail.CartOrderItemSubTotal")}
              </StyledTypography>
              {/*mobile*/}
              <StyledPriceFormat
                priceUnformatted={
                  parseFloat(rowData.orderItemPrice) +
                  parseFloat((rowData.totalAdjustment && rowData.totalAdjustment.value) || 0)
                }
                TypographyVariant="textSecondaryPrice"
              />
            </StyledGrid>
          </StyledGrid>
        ) : !rowData?.adjustment && parseFloat(rowData.totalAdjustment.value) !== 0 ? (
          <StyledGrid
            container
            className={"productDetails--styledGrid-productContainer productDetails--styledGrid-productContainerHeight"}>
            <StyledGrid
              item
              xs={8}
              className={
                "productDetails--styledGrid-productLeftContainer " +
                (variant === "TYP" ? "productDetails--styledGrid-productLeftContainerTYP" : "")
              }>
              {variant === "TYP" && (
                <StyledGrid xs={6} className={"productDetails--styledGrid-productPriceContainer"}>
                  <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                    {t("productDetail.Quantity")}
                  </StyledTypography>
                  <StyledTypography variant="bodyBaseline" weight="semibold">
                    {parseInt(rowData?.quantity)}
                  </StyledTypography>
                </StyledGrid>
              )}
              {variant === "cart" &&
                rowData.adjustment === undefined &&
                parseFloat(rowData.totalAdjustment.value) !== 0 && (
                  <StyledGrid xs={12} className={"productDetails--styledGrid-productPriceContainer"}>
                    <StyledBox width="100%" display="flex">
                      <StyledBox
                        width={1 / 2}
                        className={"productDetails--styledGrid-productPriceContainer product-promotion"}
                        style={{ textAlign: "center" }}>
                        <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                          {promoNlP
                            ? t("productDetail.CartOrderItemRegularPrice")
                            : t("productDetail.CartOrderItemPromotion")}
                        </StyledTypography>
                        <StyledTypography variant="bodyBaseline" fontWeight={600}>
                          {promoNlP ? (
                            <StyledPriceFormat
                              priceUnformatted={rowData.unitPrice}
                              TypographyVariant="textSecondaryPrice"
                              strikethrough
                            />
                          ) : (
                            t("productDetail.SaveMore")
                          )}
                        </StyledTypography>
                      </StyledBox>
                      <StyledBox
                        width={1 / 2}
                        className={"productDetails--styledGrid-productPriceContainer product-saving"}>
                        <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                          {t("productDetail.CartOrderItemYouSave")}
                        </StyledTypography>
                        <StyledPriceFormat
                          priceUnformatted={rowData.totalAdjustment.value * -1}
                          TypographyVariant="textSecondaryPrice"
                          discountType={true}
                        />
                      </StyledBox>
                    </StyledBox>
                  </StyledGrid>
                )}
            </StyledGrid>
            <StyledGrid item xs={4} className={"productDetails--styledGrid-productPriceContainer product-subtotal"}>
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {t("productDetail.CartOrderItemSubTotal")}
              </StyledTypography>
              <StyledPriceFormat
                priceUnformatted={parseFloat(rowData.orderItemPrice) - rowData.totalAdjustment.value * -1}
                TypographyVariant="textSecondaryPrice"
              />
            </StyledGrid>
          </StyledGrid>
        ) : (
          <StyledGrid
            container
            className={"productDetails--styledGrid-productContainer productDetails--styledGrid-productContainerHeight"}>
            <StyledGrid
              item
              xs={8}
              className={
                "productDetails--styledGrid-productLeftContainer " +
                (variant === "TYP" ? "productDetails--styledGrid-productLeftContainerTYP" : "")
              }>
              {variant === "TYP" && (
                <StyledGrid xs={6} className={"productDetails--styledGrid-productPriceContainer"}>
                  <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                    {t("productDetail.Quantity")}
                  </StyledTypography>
                  <StyledTypography variant="bodyBaseline" weight="semibold">
                    {parseInt(rowData?.quantity)}
                  </StyledTypography>
                </StyledGrid>
              )}
            </StyledGrid>
            <StyledGrid item xs={4} className={"productDetails--styledGrid-productPriceContainer product-subtotal"}>
              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                {t("productDetail.CartOrderItemSubTotal")}
              </StyledTypography>
              <StyledPriceFormat priceUnformatted={rowData.orderItemPrice} TypographyVariant="textSecondaryPrice" />
            </StyledGrid>
          </StyledGrid>
        );

        const subTotalPromotions = (
          <>
            <StyledGrid container className={"productDetails--styledGrid-productContainer"}>
              <StyledGrid item xs={8} lg={8}>
                <StyledGrid
                  className={
                    "productDetails--styledGrid-productLeftContainer " +
                    (variant === "TYP" ? "productDetails--styledGrid-productLeftContainerTYP" : "")
                  }>
                  <StyledBox
                    className="mini-image right-margin-2"
                    sx={{
                      minWidth: 60,
                      height: 70,
                      width: 60,
                      maxHeight: { xs: 70, md: 100 },
                      maxWidth: { xs: 80, md: 100 },
                    }}>
                    <Link to={rowData?.seo?.href ? rowData?.seo?.href : ""} onClick={() => {handleMiniCartClose();
                      triggerSelectItemDL(
                      mySite.storeID,
                      isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
                      contract,
                      rowData.partNumber,
                      1,
                      itemAffiliation,
                      currentMarketId,
                      isB2B
                    );}}>
                      {!rowData.partNumber.includes("SI") ? (
                        <StyledBox
                          className="right-margin-2"
                          component="img"
                          sx={{
                            height: 60,
                            width: 60,
                            minWidth: 60,
                            minHeight: 60,
                            maxHeight: { xs: 60, md: 60 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="Product"
                          // src={rowData.thumbnail}
                          // src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                          src={
                            rowData.thumbnail
                              ? thumbnailMini
                              : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                          }
                        />
                      ) : (
                        <span className="material-symbols-sharp productDetails--icon-checkIconInstallations">
                          check
                        </span>
                      )}
                    </Link>
                  </StyledBox>
                  <StyledGrid>
                    <StyledGrid className={"productDetails--styledGrid-productInformationContainer"}>
                      {/* Product data 1 */}
                      {rowData.manufacturer && (
                        <StyledTypography
                          variant="bodyBaseline"
                          weight="semibold"
                          component="span"
                          className="product-brand">
                          {rowData?.seo && rowData?.seo?.href
                            ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                              rowData.manufacturer
                              ? rowData.manufacturer
                              : rowData.partNumber.includes("SI")
                              ? "Incluye instalación de " + rowData.name
                              : ""
                            : // </Link>
                            rowData.name
                            ? brand
                            : rowData.partNumber}{" "}
                        </StyledTypography>
                      )}

                      {/* Product data 2 */}
                      {!rowData.partNumber.includes("SI") ? (
                        <StyledTypography
                          variant="bodyBaseline"
                          component="span"
                          className="product-name"
                          style={{
                            paddingRight: "5px",
                            wordBreak: "break-word",
                          }}>
                          {rowData?.seo && rowData?.seo?.href
                            ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                              rowData.name
                              ? rowData.name
                              : rowData.partNumber
                            : // </Link>
                            rowData.name
                            ? rowData.name
                            : rowData.partNumber}
                        </StyledTypography>
                      ) : (
                        ""
                      )}
                    </StyledGrid>
                    {/* Para la descripción de los servicios */}
                    {rowData.partNumber.includes("SI") ? (
                      <StyledTypography
                        variant="bodyBaseline"
                        component="span"
                        style={{
                          paddingRight: "5px",
                          wordBreak: "break-word",
                        }}>
                        {rowData?.seo && rowData?.seo?.href
                          ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                            rowData.name && rowData.partNumber.includes("SI")
                            ? rowData.shortDescription
                            : ""
                          : // </Link>
                          rowData.name && rowData.partNumber.includes("SI")
                          ? rowData.shortDescription
                          : ""}
                      </StyledTypography>
                    ) : (
                      ""
                    )}

                    {/* Product data 2 */}
                    <StyledTypography variant="bodySubtext" className="deliveryZone--typography-darkGray product-sku">
                      {!miniCartView && t("OrderItemTable.Labels.SKU")}
                      {rowData.partNumber}
                    </StyledTypography>
                  </StyledGrid>
                </StyledGrid>
                <Hidden mdUp>
                  <>
                    {rowData?.xitem_colorCode && rowData?.xitem_colorCode !== EMPTY_STRING && (
                      <StyledGrid xs={12} className={"productDetails--styledGrid-productLeftContainerTYP"}>
                        <StyledCartRowPaint {...{ rowData }} QuantityCell={QuantityCell} />
                      </StyledGrid>
                    )}
                  </>
                </Hidden>
              </StyledGrid>
              <StyledGrid
                item
                xs={4}
                md={variant === "TYP" ? 4 : 2}
                lg={variant === "TYP" ? 4 : 2}
                className={"productDetails--styledGrid-productPriceContainer product-price"}>
                <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                  {t("productDetail.CartOrderItemPrice")}
                </StyledTypography>
                <StyledPriceFormat priceUnformatted={rowData.unitPrice} TypographyVariant="textSecondaryPrice" />
                <UnitOfMeasurement rowData={rowData} />
              </StyledGrid>
              {variant === "cart" && (
                <>
                  <Hidden smDown>
                    <StyledGrid
                      item
                      xs={4}
                      md={2}
                      lg={2}
                      className={"productDetails--styledGrid-productPriceContainer product-subtotal"}>
                      <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                        {t("productDetail.CartOrderItemSubTotal")}
                      </StyledTypography>
                      <StyledPriceFormat
                        priceUnformatted={rowData.orderItemPrice}
                        TypographyVariant="textSecondaryPrice"
                      />
                    </StyledGrid>
                  </Hidden>
                </>
              )}
            </StyledGrid>
            {variant === "TYP" && subTotalMobile}
            {variant === "cart" && <Hidden mdUp>{subTotalMobile}</Hidden>}
          </>
        );
        const NominalQuantity = () => {
          if (rowData?.unitQuantity && parseInt(rowData.unitQuantity) > 1) {
            return (
              <StyledGrid className={"bottom-margin-1"}>
                <StyledTypography variant="bodyCaption" component="span" className={"color-gray300"}>
                  {t("productDetail.CartOrderMinimalQuantity")}
                </StyledTypography>
                <StyledTypography variant="bodyCaption" fontWeight={600} component="span" className="color-gray300">
                  {parseInt(rowData.unitQuantity)}
                </StyledTypography>
              </StyledGrid>
            );
          }
          return <></>;
        };

        const TechnicalVisitLineItem = () => {
          return (
            <StyledPaper
              className={"productDetails--styledPaper-productContainer" + " productDetails--styledPaper-noBoxShadow"}>
              <StyledTypography variant="textSegment" align="center" gutterBottom>
                {t("Cart.Labels.TechnicalVisitTitle")}
              </StyledTypography>
              <Divider className="vertical-margin-2" />

              <StyledTypography variant="textSegment" align="center">
                {t("Cart.Labels.TechnicalVisitSubtitle")}
              </StyledTypography>
              <Divider className="vertical-margin-2" />

              <StyledGrid container className={"productDetails--styledGrid-productOptionsDividerContainer"}>
                <StyledGrid item xs={12} md={8} lg={8}></StyledGrid>
                <StyledGrid item xs={12} md={4} lg={4}>
                  <StyledGrid container>
                    <StyledGrid
                      item
                      xs={6}
                      md={8}
                      lg={7}
                      className={"productDetails--styledGrid-productOptionsDivider"}></StyledGrid>
                    <StyledGrid item xs={6} md={4} lg={5}>
                      <StyledLinkButton
                        className="delete-from-cart"
                        onClick={() => onQuantityUpdate("0", rowData)}
                        variant="bodyBaseline">
                        {t("productDetail.CartOrderDeleteItem")}
                      </StyledLinkButton>
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>

              <Hidden only={["xs", "sm"]}>
                <StyledBox
                  width={"100%"}
                  className={"productDetails--styledGrid-productContainer"}
                  style={{ display: "flex" }}>
                  <StyledBox width={4 / 8} className={"productDetails--styledGrid-productLeftContainer "}>
                    <StyledBox>
                      <StyledGrid className={"productDetails--styledGrid-productInformationContainer"}>
                        {/* Product data 1 */}

                        <StyledTypography className="product-brand" variant="bodyBaseline">
                          {"ID:"}
                          {rowData?.partNumber?.split("_")[0]}
                        </StyledTypography>

                        {/* Product data 2 */}
                        <StyledTypography
                          className={rowData.manufacturer ? "product-name" : "product-name-noManufacturer"}
                          variant="bodyBaseline"
                          weight="semibold"
                          style={{
                            paddingRight: "5px",
                            wordBreak: "break-word",
                          }}>
                          {rowData?.name}
                        </StyledTypography>
                      </StyledGrid>

                      <StyledTypography variant="bodyBaseline">
                        {"Un profesional acudira a tu domicillio para evaluar tus necesidades de instalacion."}

                        <StyledTypography
                          variant="bodyBaseline"
                          className="drawer-link"
                          component="span"
                          onClick={handleClick}>
                          {"¿Qué incluye?"}
                        </StyledTypography>
                      </StyledTypography>
                      <Divider className="vertical-margin-2" />

                      <StyledTypography
                        className={rowData.manufacturer ? "product-name" : "product-name-noManufacturer"}
                        variant="bodyBaseline"
                        weight="semibold"
                        style={{
                          paddingRight: "5px",
                          wordBreak: "break-word",
                        }}>
                        {"Candtidad:"}
                      </StyledTypography>
                      <StyledTypography variant="bodyBaseline">
                        {"Subtotal:"}
                        {!rowData?.xitem_colorCode && (
                          <StyledGrid item xs>
                            <OrderItemPrice rowData={rowData} />
                          </StyledGrid>
                        )}
                      </StyledTypography>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
              </Hidden>

              <StyledSwipeableDrawerHDM
                setOpenDrawer={setOpenDrawer}
                isOpen={openDrawer}
                variant="instalation"
                title={t("ProductInstalation.installationSpecifications")}>
                <StyledGrid container className="installation-specs-drawer-container" style={{ padding: "10px" }}>
                  <StyledGrid item xs={12}>
                    {rowData?.longDescription &&
                      rowData.longDescription.map((copy) => {
                        return <StyledTypography variant="bodyBaseline">{copy}</StyledTypography>;
                      })}
                  </StyledGrid>
                  <StyledGrid item>
                    <EspotHdm espotName="PDP-Drawer_Installation" />
                  </StyledGrid>
                </StyledGrid>
              </StyledSwipeableDrawerHDM>
            </StyledPaper>
          );
        };

        const CartExceptions = () => {
          const genericError: any = useSelector<any>(genericErrorSelector);

          const isSOMUnavailable = genericError?.errorKey === "_ERR_INVENTORY_GENERIC_SYSTEM_EXCEPTION" ? true : false;
          let canBeTransferred = false;
          const openBopisDeliveryDrawer = () => {
            const query = {
              url: "",
              data: true,
              option: "BOPIS_DRAWER_CART",
              fetch: false,
            };
            dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
          };
          // Pending error messages from V8
          if (isSOMUnavailable) {
            return (
              <StyledGrid item xs={12} md={12} lg={12}>
                <StyledGrid xs={12} className={"bottom-margin-1"}>
                  <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderOutOfStockDelivery")} />
                </StyledGrid>
              </StyledGrid>
            );
          }
          if (rowData?.orderItemInventoryStatus !== INVENTORY_STATUS.available) {
            if (isDropshipBopisDisabled) {
              return (
                <StyledGrid item xs={12} md={12} lg={12}>
                  <StyledGrid xs={12} className={"bottom-margin-1"}>
                    <StyledPromp variant="checkoutAlert" message={t("productDetail.NotAvaliableDropship")} />
                  </StyledGrid>
                </StyledGrid>
              );
            } else {
              return (
                <StyledGrid item xs={12} md={12} lg={12}>
                  <StyledGrid xs={12} className={"bottom-margin-1"}>
                    <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderOutOfStockDelivery")} />
                  </StyledGrid>
                </StyledGrid>
              );
            }
          }
          // BOPIS
          if (rowData?.xitem_itemLevelDeliveryType === "1") {
            if (isDropshipBopisDisabled) {
              return (
                <StyledGrid item xs={12} md={12} lg={12}>
                  <StyledGrid xs={12} className={"bottom-margin-1"}>
                    <StyledPromp variant="checkoutAlert" message={t("productDetail.NotAvaliableDropship")} />
                  </StyledGrid>
                </StyledGrid>
              );
            }
            if (
              rowData?.xitem_shopCartTransferFlag === "1" &&
              (rowData?.xitem_SplitdeliveredfromMDC === "true" || rowData?.xitem_SplitdeliveredfromHUB === "true") &&
              !isDropshipBopisDisabled
            ) {
              canBeTransferred = true;
            }
            if (
              (isDropshipBopisDisabled && isDropShipBopisEnabledFlag === "true" && !isBopisDisabled) ||
              (!isDropshipBopisDisabled &&
                (rowData?.xitem_shopCartItemdate_HD?.includes("_") ||
                  rowData?.xitem_shopCartItemdate_BOPIS?.includes("_")))
            ) {
              canBeTransferred = true;
            }
          }

          if (
            (deliveryType === CONSTANTS.bopis &&
              !canBeTransferred &&
              !inventoryAvailability.hasInventoryBopis &&
              inventoryAvailability.hasStockDelivery) ||
            isSOMUnavailable
          ) {
            const CartOrderOutOfStockBopis = (
              <StyledBox>
                {t("productDetail.CartOrderOutOfStockBopis.storeOutOfStock")}
                <StyledTypography variant={"bodySubText"} weight="semibold" component="span">
                  {t("productDetail.CartOrderOutOfStockBopis.homeDelivery")}
                </StyledTypography>
                {t("productDetail.CartOrderOutOfStockBopis.or")}
                <StyledLinkButton variant="bodySubText" onClick={() => openBopisDeliveryDrawer()} component="span">
                  {t("productDetail.CartOrderOutOfStockBopis.otherStores")}
                </StyledLinkButton>
                {t("productDetail.CartOrderOutOfStockBopis.withInventory")}
              </StyledBox>
            );
            return (
              <StyledGrid xs={12}>
                {rowData?.xitem_shopCartTransferFlag === "1" &&
                rowData?.xitem_SplitdeliveredfromMDC !== "true" &&
                rowData?.xitem_SplitdeliveredfromHUB !== "true" &&
                !isDropshipBopisDisabled ? (
                  <>
                    <StyledPromp variant="checkoutAlert" message={CartOrderOutOfStockBopis} />
                  </>
                ) : deliveryType === CONSTANTS.bopis && inventoryAvailability.hasStockDelivery ? (
                  <>
                    <StyledPromp variant="checkoutAlert" message={CartOrderOutOfStockBopis} />
                  </>
                ) : (
                  <>
                    {isSOMUnavailable ? (
                      <>
                        <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderOutOfStockDelivery")} />
                      </>
                    ) : (
                      <>
                        <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderNoInventory")} />
                      </>
                    )}
                  </>
                )}
              </StyledGrid>
            );
          }
          if (
            (deliveryType === CONSTANTS.delivery &&
              !inventoryAvailability.hasInventoryDelivery &&
              inventoryAvailability.hasStockDelivery) ||
            (deliveryType === CONSTANTS.bopis &&
              !inventoryAvailability.hasInventoryBopis &&
              inventoryAvailability.hasStockBopis) ||
            (deliveryType === CONSTANTS.delivery &&
              !inventoryAvailability.hasInventoryDelivery &&
              !inventoryAvailability.hasStockDelivery)
          ) {
            return (
              <StyledGrid xs={12}>
                <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderNoInventory")} />
              </StyledGrid>
            );
          }
          const attributes = getPromoicons(
            rowData?.attributes,
            mySite.isB2B,
            currentStore?.currentStore?.stLocId,
            currentMarketId
          );
          if (
            (attributes && attributes.includes("STORE_ONLY_AVAILABLE")) ||
            attributes?.includes("PRO_STORE_ONLY_AVAILABLE")
          ) {
            return (
              <StyledGrid xs={12}>
                <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderBopisOnly")} />
              </StyledGrid>
            );
          }
          if (
            (attributes && attributes.includes("STORE_BUYABLE_EXCLUDE")) ||
            attributes?.includes("PRO_STORE_BUYABLE_EXCLUDE")
          ) {
            return (
              <StyledGrid xs={12}>
                <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderNoInventory")} />
              </StyledGrid>
            );
          }
          if (rowData?.attributes && deliveryType === CONSTANTS.bopis && !isDropshipBopisDisabled && canBeTransferred) {
            let dateStringAlert = "";
            if (rowData?.xitem_shopCartItemdate_BOPIS) {
              const dateArrayAlertOneDate = rowData.xitem_shopCartItemdate_BOPIS.split("_");
              const dateArrayAlert = dateArrayAlertOneDate[0].split("-");
              dateStringAlert =
                dateArrayAlert[2] + " " + CONSTANTS.stringMonths[dateArrayAlert[1]] + " " + dateArrayAlert[0] + ".";
            }
            let stringDate;
            if (dateStringAlert === "") {
              stringDate = (
                <>
                  {t("productDetail.CartOrderBopis") + " "}
                  <Skeleton width={100} variant="text" className="display-inlineBlock" />
                </>
              );
            } else {
              stringDate = <>{t("productDetail.CartOrderBopis") + dateStringAlert}</>;
            }
            return (
              <StyledGrid xs={12}>
                <StyledPromp variant="gray" message={stringDate} />
              </StyledGrid>
            );
          }
          // RegularPricePromotion
          if(mySite.isB2B && rowData.adjustment && (rowData.adjustment?.[0].description === "NxM" || rowData.adjustment?.[0].description === "Precio Mayoreo" || rowData.adjustment?.[0].description === "Combo")){
            const messagePromopt = t("productDetail.RegularPricePromotion", { precio: parseFloat(rowData.regularPrice).toFixed(2) }) 
            return (
              <StyledGrid xs={12}>
                <StyledPromp variant="gray" message={messagePromopt} />
              </StyledGrid>
            );
          }

          return <></>;
        };
        const DeliveryRadioSelector = () => {
          return (
            <StyledGrid item xs={12} md={5} lg={5} className="product-delivery">
              <StyledGrid container>
                <StyledFormControl variant="outlined" fullWidth className={"noVerticalMargin-delivery"}>
                  <StyledRadioGroup
                    name="radio-delivery-selector"
                    value={deliveryType}
                    onChange={(radioStore) => handleDeliveryType(radioStore.target.value)}>
                    <StyledGrid container spacing={2}>
                      <StyledGrid item xs={6} align="center" className="product-home-delivery-selector">
                        <StyledRadioCard
                          variant="delivery"
                          value={deliveryModeCarrier}
                          type={inventoryAvailability.errorDelivery === true ? "warning" : ""}
                          noMarginBottom
                          noMarginTop
                        />
                      </StyledGrid>
                      <StyledGrid item xs={6} align="center" className="product-bopis-selector">
                        <StyledRadioCard
                          variant="bopis"
                          value={bopisModeCarrier}
                          type={inventoryAvailability.errorBopis === true || isDropshipBopisDisabled ? "warning" : ""}
                          noMarginBottom
                          noMarginTop
                          disabled={isDropshipBopisDisabled}
                        />
                      </StyledGrid>
                    </StyledGrid>
                  </StyledRadioGroup>
                </StyledFormControl>
              </StyledGrid>
            </StyledGrid>
          );
        };
        const loaderState = useSelector(backdropLoaderStateAndSrcSelector);
        const isALoaderActionSelected = () => {
          return (
            loaderState.src == LoaderActions.DELIVERY_TYPE_CHANGE || loaderState.src == LoaderActions.ADD_TV_TO_CART
          );
        };
        const shouldShowOverlay = (orderItemId) => {
          return isALoaderActionSelected() && loaderState.idx !== orderItemId;
        };
        return (
          <>
            {!miniCartView && !rowData.partNumber.includes("SI") && !rowData.partNumber.includes("TV") && !isTV ? (
              <>
                <StyledPaper
                  className={
                    "productDetails--styledPaper-productContainer" +
                    " productDetails--styledPaper-noBoxShadow" +
                    (variant === "TYP" ? " productDetails--styledPaper-noPadding " : "")
                  }
                  elevation={0}
                  style={{ position: "relative" }}>
                  {variant === "cart" && (
                    <>
                      {shouldShowOverlay(rowData.orderItemId) && <SLOverlayComponent />}
                      <StyledGrid container className={"productDetails--styledGrid-productOptionsDividerContainer"}>
                        <StyledGrid item xs={12} md={8} lg={8}></StyledGrid>
                        <StyledGrid item xs={12} md={4} lg={4}>
                          <StyledGrid container>
                            <StyledGrid
                              item
                              xs={6}
                              md={8}
                              lg={7}
                              className={"productDetails--styledGrid-productOptionsDivider"}>
                              <StyledWishListButton
                                partNumber={rowData.partNumber}
                                isWishProduct={false}
                                variant={"checkout"}
                                placement={"bottom"}
                              />
                            </StyledGrid>
                            <StyledGrid item xs={6} md={4} lg={5}>
                              <StyledLinkButton
                                className="delete-from-cart"
                                onClick={() => onQuantityUpdate("0", rowData)}
                                variant="bodyBaseline">
                                {t("productDetail.CartOrderDeleteItem")}
                              </StyledLinkButton>
                            </StyledGrid>
                          </StyledGrid>
                        </StyledGrid>
                      </StyledGrid>
                    </>
                  )}
                  {/* Product image */}
                  {variant !== "TYP" && (
                    <Hidden only={["xs", "sm"]}>
                      <StyledBox
                        width={"100%"}
                        className={"productDetails--styledGrid-productContainer"}
                        style={{ display: "flex" }}>
                        <StyledBox
                          width={4 / 8}
                          className={
                            "productDetails--styledGrid-productLeftContainer " +
                            (variant === "TYP" ? "productDetails--styledGrid-productLeftContainerTYP" : "")
                          }>
                          <StyledBox
                            className="mini-image right-margin-2"
                            sx={{
                              minWidth: 60,
                              height: 70,
                              width: 60,
                              maxHeight: { xs: 70, md: 100 },
                              maxWidth: { xs: 80, md: 100 },
                            }}>
                            <Link to={rowData?.seo?.href ? rowData?.seo?.href : ""} onClick={() => {handleMiniCartClose();
                              triggerSelectItemDL(
                              mySite.storeID,
                              isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
                              contract,
                              rowData.partNumber,
                              1,
                              itemAffiliation,
                              currentMarketId,
                              isB2B
                            );}}>
                            
                              <StyledBox
                                className="right-margin-2"
                                component="img"
                                sx={{
                                  height: 60,
                                  width: 60,
                                  minWidth: 60,
                                  minHeight: 60,
                                  maxHeight: { xs: 60, md: 60 },
                                  maxWidth: { xs: 350, md: 250 },
                                }}
                                alt="Product"
                                // src={rowData.thumbnail}
                                // src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                                src={
                                  rowData.thumbnail
                                    ? thumbnailMini
                                    : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                                }
                              />
                            </Link>
                          </StyledBox>
                          <StyledBox>
                            <StyledGrid className={"productDetails--styledGrid-productInformationContainer"}>
                              {/* Product data 1 */}
                              {rowData.manufacturer && (
                                <StyledTypography
                                  className="product-brand"
                                  variant="bodyBaseline"
                                  weight="semibold"
                                  component="span">
                                  {rowData?.seo && rowData?.seo?.href
                                    ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                                      rowData.manufacturer && rowData.manufacturer
                                    : // </Link>
                                    rowData.name
                                    ? brand
                                    : rowData.partNumber}{" "}
                                </StyledTypography>
                              )}

                              {/* Product data 2 */}
                              <StyledTypography
                                className={rowData.manufacturer ? "product-name" : "product-name-noManufacturer"}
                                variant="bodyBaseline"
                                component="span"
                                style={{
                                  paddingRight: "5px",
                                  wordBreak: "break-word",
                                }}>
                                {rowData?.seo && rowData?.seo?.href
                                  ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                                    rowData.name
                                    ? rowData.name
                                    : rowData.partNumber
                                  : // </Link>
                                  rowData.name
                                  ? rowData.name
                                  : rowData.partNumber}
                              </StyledTypography>
                            </StyledGrid>
                            {/* Product data 2 */}
                            <StyledTypography
                              variant="bodySubtext"
                              className="deliveryZone--typography-darkGray product-sku">
                              {!miniCartView && t("OrderItemTable.Labels.SKU")}
                              {rowData.partNumber}
                            </StyledTypography>
                          </StyledBox>
                        </StyledBox>
                        {rowData.adjustment === undefined && parseFloat(rowData.totalAdjustment.value) === 0 && (
                          <StyledBox width={2 / 8}></StyledBox>
                        )}
                        {rowData.adjustment === undefined && parseFloat(rowData.totalAdjustment.value) !== 0 && (
                          <StyledBox width={2 / 8}></StyledBox>
                        )}

                        {rowData.adjustment !== undefined && parseFloat(rowData.totalAdjustment.value) !== 0 && mySite.isB2B ? (
                        <StyledBox
                          width={1 / 8}
                          className={"productDetails--styledGrid-productPriceContainer product-price"}>
                          <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                            {t("productDetail.CartOrderItemPrice")}
                          </StyledTypography>
                          {promoNumNxM || CONSTANTS.promotions[rowData.adjustment[0].description] || rowData.adjustment[0].description 
                          ?  
                          <StyledPriceFormat
                            priceUnformatted={rowData.unitPrice}
                            TypographyVariant="textSecondaryPrice"
                          />
                          :  
                          <StyledPriceFormat
                            priceUnformatted={rowData.regularPrice}
                            TypographyVariant="textSecondaryPrice"
                          />
                          }
                          <UnitOfMeasurement rowData={rowData} />
                        </StyledBox>
                        ) : (
                        <StyledBox
                          width={1 / 8}
                          className={"productDetails--styledGrid-productPriceContainer product-price"}>
                          <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                            {t("productDetail.CartOrderItemPrice")}
                          </StyledTypography>
                          <StyledPriceFormat
                            priceUnformatted={rowData.unitPrice}
                            TypographyVariant="textSecondaryPrice"
                          />
                          <UnitOfMeasurement rowData={rowData} />
                        </StyledBox>
                        )}     


                        {rowData.adjustment !== undefined && parseFloat(rowData.totalAdjustment.value) !== 0 ? (
                          <>
                            <StyledBox
                              width={1 / 8}
                              className={"productDetails--styledGrid-productPriceContainer product-promotion right-padding-2"}
                              style={{ textAlign: "center" }}>
                                {mySite.isB2B ?
                                <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                                  {promoNumNxM || CONSTANTS.promotions[rowData.adjustment[0].description] || rowData.adjustment[0].description 
                                  ?  t("productDetail.CartOrderItemPromotion")
                                  :  t("productDetail.CartOrderItemRegularPrice")
                                  }
                                </StyledTypography>
                                :
                                <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                                  {t("productDetail.CartOrderItemPromotion")}
                                </StyledTypography>
                                }
                              {mySite.isB2B ?
                              <StyledTypography variant="bodyBaseline">
                                {promoNumNxM
                                  ? promoNumNxM
                                  : CONSTANTS.promotions[rowData.adjustment[0].description]
                                  ? CONSTANTS.promotions[rowData.adjustment[0].description]
                                  : rowData.adjustment[0].description 
                                  ? rowData.adjustment[0].description
                                  : <StyledPriceFormat
                                  priceUnformatted={rowData.unitPrice}
                                  TypographyVariant="textSecondaryPrice"
                                  strikethrough={true}
                                />
                                }
                              </StyledTypography>
                              :
                              <StyledTypography variant="bodyBaseline" style={{ fontWeight: "600" }}>
                                {promoNumNxM
                                  ? promoNumNxM
                                  : CONSTANTS.promotions[rowData.adjustment[0].description]
                                  ? CONSTANTS.promotions[rowData.adjustment[0].description]
                                  : rowData.adjustment[0].description}
                              </StyledTypography>
                               }
                            </StyledBox>
                            <StyledBox
                              width={1 / 8}
                              className={"productDetails--styledGrid-productPriceContainer product-saving right-padding-2"}>
                              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                                {t("productDetail.CartOrderItemYouSave")}
                              </StyledTypography>
                              <StyledPriceFormat
                                priceUnformatted={rowData.totalAdjustment.value * -1}
                                TypographyVariant="textSecondaryPrice"
                                discountType={true}
                              />
                            </StyledBox>
                          </>
                        ) : rowData.adjustment === undefined && parseFloat(rowData.totalAdjustment.value) !== 0 ? (
                          <>
                            {/* <StyledBox
                              width={1 / 8}
                              className={"productDetails--styledGrid-productPriceContainer"}
                              style={{ textAlign: "center" }}>
                              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                                {t("productDetail.CartOrderItemPromotion")}
                              </StyledTypography>
                              <StyledTypography variant="bodyBaseline" style={{ fontWeight: "600" }}>
                                Remate
                              </StyledTypography>
                            </StyledBox> */}
                            <StyledBox
                              width={1 / 8}
                              className={"productDetails--styledGrid-productPriceContainer product-saving"}>
                              <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                                {t("productDetail.CartOrderItemYouSave")}
                              </StyledTypography>
                              <StyledPriceFormat
                                priceUnformatted={rowData.totalAdjustment.value * -1}
                                TypographyVariant="textSecondaryPrice"
                                discountType={true}
                              />
                            </StyledBox>
                          </>
                        ) : null}
                        <StyledBox
                          width={1 / 8}
                          className={"productDetails--styledGrid-productPriceContainer product-subtotal"}>
                          <StyledTypography variant="bodyCaption" className="deliveryZone--typography-darkGray">
                            {t("productDetail.CartOrderItemSubTotal")}
                          </StyledTypography>
                          <StyledPriceFormat
                            priceUnformatted={parseFloat(rowData.orderItemPrice) - rowData.totalAdjustment.value * -1}
                            TypographyVariant="textSecondaryPrice"
                          />
                        </StyledBox>
                      </StyledBox>
                    </Hidden>
                  )}

                  {variant === "TYP" ? (
                    subTotalPromotions
                  ) : (
                    <Hidden only={["md", "lg", "xl"]}>{subTotalPromotions}</Hidden>
                  )}

                  {!readOnly && (
                    <>
                      <StyledGrid container>
                        <Hidden smDown>
                          <>
                            {rowData?.xitem_colorCode && rowData?.xitem_colorCode !== EMPTY_STRING && (
                              <StyledGrid item xs={12}>
                                <StyledCartRowPaint {...{ rowData }} QuantityCell={QuantityCell} />
                              </StyledGrid>
                            )}
                          </>
                        </Hidden>
                        <StyledGrid item xs={12} md={6} lg={5}>
                          {/*<SeccionDisponibilidadEnTienda {...{rowData}} />*/}
                          <StyledGrid container>
                            <StyledGrid item xs={4} className={"top-margin-2 "}>
                              <NominalQuantity />
                              <QuantityCell
                                {...{ rowData }}
                                inventoryAvailability={inventoryAvailability}
                                deliverType={deliveryType}
                              />
                            </StyledGrid>
                            <StyledGrid
                              item
                              xs={8}
                              className={"productDetails--styledGrid-productInventoryLabel product-stock"}>
                              {rowData?.orderItemInventoryStatus === INVENTORY_STATUS.available && (
                                <DisponibilidadEnTienda {...{ rowData }} />
                              )}
                            </StyledGrid>
                          </StyledGrid>
                        </StyledGrid>
                        <StyledGrid item md={1} lg={2}></StyledGrid>
                        <DeliveryRadioSelector />
                      </StyledGrid>
                      {paintItemNumber && paintItemNumber.length > 11 && (
                        <StyledGrid className={"top-margin-2"}>
                          <StyledPromp
                            variant="checkoutAlert"
                            message={t("productDetail.CartOrderItemPaintMaxItems")}
                          />
                        </StyledGrid>
                      )}
                      <StyledGrid xs={12} className={"productDetails--styledGrid-cartExceptionsContainer"}>
                        <CartExceptions />
                      </StyledGrid>
                    </>
                  )}

                  {/* Show SI */}
                  <StyledGrid item xs={12} sm={12} md={12} lg={12} id={"cart--orderItemTable-container"}>
                    {services && services.length > 0 ? (
                      <>
                        {/* Validate if the SI is already checked (isTV checked if the orderItems array contains the SI Object) */}
                        {services.map((service, key) => {
                          if (service.associationType === "Installation") {
                            return (
                              <ProductInstalationHDM
                                installationIsChecked={servicesInstallationInCart.includes(service.partNumber)}
                                servicesInCart={servicesInstallationInCartIds}
                                isCart={true}
                                cartQty={rowData.quantity}
                                merchandisingAssociations={service}
                              />
                            );
                          }
                        })}
                      </>
                    ) : null}
                  </StyledGrid>
                </StyledPaper>
              </>
            ) : (
              <>
                {miniCartView ? (
                  !rowData.partNumber.includes("SI") && !rowData.partNumber.includes("TV") ? (
                    <>
                      {
                        <StyledBox display="flex" justifyContent="space-between" width={"100%"}>
                          <StyledBox className="mini-imagex">
                            {rowData?.seo?.href ? (
                              <Link to={rowData?.seo?.href ? rowData?.seo?.href : ""} onClick={() => {handleMiniCartClose();
                                triggerSelectItemDL(
                                mySite.storeID,
                                isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
                                contract,
                                rowData.partNumber,
                                1,
                                itemAffiliation,
                                currentMarketId,
                                isB2B
                              );}}>
                                <StyledBox
                                  component="img"
                                  alt="Product"
                                  // src={rowData.thumbnail}
                                  // src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                                  src={
                                    rowData.thumbnail
                                      ? thumbnailMini
                                      : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                                  }
                                />
                              </Link>
                            ) : (
                              <StyledBox
                                sx={{
                                  height: 60,
                                  width: 60,
                                  minWidth: 60,
                                  minHeight: 60,
                                  maxHeight: { xs: 60, md: 60 },
                                  maxWidth: { xs: 350, md: 250 },
                                }}></StyledBox>
                            )}
                          </StyledBox>
                          {/* Product data 1 */}
                          <StyledBox item className={"productDetails--styledGrid-productInformationContainerx"}>
                            <StyledTypography
                              className="product-brand"
                              variant="bodyBaseline"
                              weight="semibold"
                              component="span">
                              {rowData?.seo && rowData?.seo?.href
                                ? // <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                                  rowData.manufacturer
                                  ? rowData.manufacturer
                                  : ""
                                : // </Link>
                                rowData.name
                                ? brand
                                : rowData.partNumber}
                            </StyledTypography>

                            {/* Product data 2 */}
                            <StyledTypography
                              variant="bodyBaseline"
                              component="span"
                              className={rowData.manufacturer ? "product-name" : "product-NoBrand"}
                              // style={{
                              //   paddingRight: "5px",
                              //   paddingLeft: "5px",
                              //   wordBreak: "break-word",
                              // }
                            >
                              {!miniCartView && t("OrderItemTable.Labels.SKU")}
                              {capitalizedFirstLetter(rowData?.name)}
                            </StyledTypography>
                            <StyledTypography
                              variant="bodySubText"
                              component="p"
                              className="product-sku"
                              // style={{ display: "none" }}
                            >
                              {rowData?.partNumber}
                            </StyledTypography>
                          </StyledBox>
                          {/* Delete icon*/}
                          <StyledGrid item>
                            <DeleteActionCell {...{ rowData }} />
                          </StyledGrid>
                        </StyledBox>
                      }

                      {/* {rowData.seo && rowData.seo.href ? (
                    <Link to={rowData.seo?.href} onClick={handleMiniCartClose ? handleMiniCartClose : null}>
                      <StyledAvatar
                        alt={rowData.name}
                        src={rowData.thumbnail}
                        style={{ margin: "0", justifyContent: "flex-start" }}
                      />
                    </Link>
                  ) : (
                    <StyledAvatar alt={rowData.name} src={rowData.thumbnail} />
                  )} */}
                    </>
                  ) : (
                    <StyledBox display="flex" justifyContent="space-between" width={"100%"}>
                      {/* Product data 1 */}
                      <StyledBox item className={"productDetails--styledGrid-productInformationContainerx"}>
                        {/* Product data 2 */}
                        <StyledTypography variant="bodyBaseline" component="span" className="product-name">
                          {capitalizedFirstLetter(rowData?.name)}
                        </StyledTypography>
                        <br></br>
                        <StyledTypography variant="bodyBaseline" component="span" className="product-shordesc">
                          {rowData?.shortDescription}
                        </StyledTypography>
                      </StyledBox>
                      {/* Delete icon*/}
                      <StyledGrid item>
                        <DeleteActionCell {...{ rowData }} />
                      </StyledGrid>
                    </StyledBox>
                  )
                ) : null}
              </>
            )}
            {isTV && rowData.partNumber.includes("TV") ? (
              <StyledGrid
                className={"productDetails--styledPaper-productContainer" + " productDetails--styledPaper-noBoxShadow"}>
                {/*<TechnicalVisitHeading /> */}
                <TechnicalVisitLineItem />
              </StyledGrid>
            ) : (
              <></>
            )}
          </>
        );
      };

      /**
       * Dispatch quantity update action for order item
       * @param item The selected order item
       */

      const onQuantityUpdate = (quantityString: string, item: any) => {
        let quantityInit = quantityString;
        let x_colorDetails: any = null;
        if (item?.paintQuantity) {
          quantityInit = (parseInt(item.quantity) - parseInt(item.paintQuantity) + parseInt(quantityString)).toString();
          x_colorDetails = quantityString + "|" + item.paintCode;
        }

        const quantities = [quantityInit];
        const orderItemIds = [item.orderItemId];
        if (item && item.partNumber) {
          dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.PRODUCT_QUANTITY_UPDATE, idx: item.orderItemId }));
          if (item.partNumber.includes("SI")) {
            const installationForPartNumber = item.partNumber.split("_")[2];
            const partNumberQuantityOnCart = orderItems
              .filter((orderItem) => orderItem.partNumber === installationForPartNumber)
              .reduce((prev, curr) => {
                return prev + parseInt(curr.quantity);
              }, 0);

            if (parseInt(quantityString) > partNumberQuantityOnCart) {
              quantityString = partNumberQuantityOnCart.toString();
              quantities.pop();
              quantities.push(quantityString);
            }
          } else {
            // New Qty - Old Qty
            const updateItemBy = parseInt(quantityString) - parseInt(item.quantity);
            const installationsOnCart = orderItems.filter(
              (orderItem) => orderItem.partNumber.includes("SI") && orderItem.partNumber.includes(item.partNumber)
            );

            const installationsQuantityOnCart = installationsOnCart.reduce((prev, curr) => {
              return prev + parseInt(curr.quantity);
            }, 0);
            // Get current qty of the installations for the SKU
            const partNumberQuantityOnCart = orderItems
              .filter((orderItem) => orderItem.partNumber === item.partNumber)
              .reduce((prev, curr) => {
                return prev + parseInt(curr.quantity);
              }, 0);
            // Current qty +- products to add/remove
            const partsAfterUpdate = partNumberQuantityOnCart + updateItemBy;

            let newQtyfInstallions = installationsQuantityOnCart - partsAfterUpdate;

            installationsOnCart.forEach((installation) => {
              if (newQtyfInstallions >= parseInt(installation.quantity) && newQtyfInstallions !== 0) {
                quantities.push("0");
                orderItemIds.push(installation.orderItemId);
                newQtyfInstallions -= parseInt(installation.quantity);
              } else if (newQtyfInstallions !== 0) {
                const installationQuantityToDelete = parseInt(installation.quantity) - newQtyfInstallions;
                quantities.push(installationQuantityToDelete.toString());
                orderItemIds.push(installation.orderItemId);
                newQtyfInstallions -= installationQuantityToDelete;
              }
            });
            if (updateItemBy < 0) {
              //remove_from_cart_DL
              try {
                const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
                triggerRemoveFromCartDL(
                  item.partNumber,
                  contractId,
                  updateItemBy,
                  mySite.storeID,
                  mySite?.isB2B && loginStatus && proCustomerLevel
                    ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel
                    : currentStore?.currentStore?.stLocId,
                  itemAffiliation,
                  currentStore?.currentStore?.marketId,
                  mySite?.isB2B
                );
              } catch (e) {
                console.log("Error removeFromCart", e);
              }
            }
            //Add items
            if (updateItemBy > 0) {
              const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
              triggerAddToCartDL(
                mySite.storeID,
                mySite?.isB2B && loginStatus && proCustomerLevel
                  ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel
                  : currentStore?.currentStore?.stLocId,
                contractId,
                item.partNumber,
                updateItemBy,
                itemAffiliation,
                currentStore?.currentStore?.marketId,
                mySite?.isB2B
              );

              triggerConversionsPinterestAddToCart(
                mySite.storeID,
                mySite?.isB2B && loginStatus && proCustomerLevel ? storeSelector?.currentStore?.stLocId + "_" + proCustomerLevel : storeSelector?.currentStore?.stLocId,
                contractId,
                item.partNumber,
                updateItemBy,
                storeSelector?.currentStore?.marketId,
                mySite?.isB2B,
                cart.orderid
              );
            }
          }
        }
        if (item) {
          try {
            const quantity = parseInt(quantityString);
            if (quantity >= 0) {
              let payload = {
                ...payloadBase,
                quantity: quantities,
                orderItemId: orderItemIds,
                fetchCatentries: true,
                x_realtimeInventory: CONSTANTS.x_realtimeInventory,
                x_deliverType: item.description === CONSTANTS.delivery ? CONSTANTS.x_deliverType : CONSTANTS.x_bopis,
                x_page: CONSTANTS.x_page,
                x_pageLoad: CONSTANTS.x_pageLoad,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                contractId: contractId,
              };
              if (x_colorDetails !== null && x_colorDetails !== EMPTY_STRING) {
                payload = {
                  ...payloadBase,
                  quantity: quantities,
                  orderItemId: orderItemIds,
                  fetchCatentries: true,
                  x_colorDetails: x_colorDetails,
                  x_updateColor: "true",
                };
              }
              dispatch(orderActions.UPDATE_ITEM_ACTION(payload));
              quantityList[item.orderItemId] = quantity;
            } else {
              if (isNaN(quantity)) {
                dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
                return;
              }
              const payload = {
                ...payloadBase,
                orderItemId: item.orderItemId,
                fetchCatentries: true,
              };
              dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
            }
          } catch (e) {
            console.log("Could not parse quantity");
            dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
          }
        } else {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
        }
      };

      const QuantityCell = ({ rowData, ...props }) => {
        const rowPaint = props?.rowPaint ? props.rowPaint : null;
        const genericError: any = useSelector<any>(genericErrorSelector);
        const isSOMUnavailable = genericError?.errorKey === "_ERR_INVENTORY_GENERIC_SYSTEM_EXCEPTION" ? true : false;
        const inventoryAvailability = props?.inventoryAvailability ? props.inventoryAvailability : undefined;
        const isPaint =
          rowData?.xitem_colorCode && rowData?.xitem_colorCode !== "" && rowPaint?.paintQuantity ? true : false;
        let tempStock = "999";
        let isBopisDisabled = false;
        let isDeliveryDisabled = false;

        if (
          rowData?.xitem_itemLevelDeliveryType === "1" &&
          rowData?.xitem_shopCartTransferFlag === "1" &&
          rowData?.xitem_SplitdeliveredfromMDC !== "true" &&
          rowData?.xitem_SplitdeliveredfromHUB !== "true"
        ) {
          isBopisDisabled = true;
        }
        if (rowData?.orderItemInventoryStatus !== INVENTORY_STATUS.available || isSOMUnavailable) {
          isBopisDisabled = true;
          isDeliveryDisabled = true;
        }

        const deliveryType = props?.deliveryType ? props.deliveryType : rowData.shipModeCode;
        //let tempStock2 = "0";
        const unitQuantity = rowData && parseInt(rowData?.unitQuantity).toString();
        let availableQuantityInMarket = "999";
        let bopisStock = "999";

        if (stockAvailableStore !== undefined && stockAvailableStore.length > 0) {
          const itemStock = stockAvailableStore?.filter((itm) => itm.productId == rowData.productId);
          const parseQty: string = Number(itemStock[0]?.availableQuantity || "0").toString()
          bopisStock =
            itemStock.length > 0 && itemStock[0].availableQuantity !== undefined
              ? parseInt(parseQty).toString()
              : "0";

          availableQuantityInMarket =
            itemStock.length > 0 && itemStock[0].x_AvailableQuantityInMarket !== undefined
              ? parseInt(itemStock[0].x_AvailableQuantityInMarket).toString()
              : "0";

          if (deliveryType === CONSTANTS.bopis) {
            tempStock = bopisStock;
          }
          if (deliveryType === CONSTANTS.delivery) {
            tempStock = availableQuantityInMarket;
          }
        } else {
          tempStock = "9999";
        }
        const quantityPaintInit = rowPaint?.paintQuantity ? rowPaint?.paintQuantity : 0;
        const [quantity, setQuantity] = useState<string>(
          isPaint ? quantityPaintInit : quantityList[rowData.orderItemId]
        );

        if (inventoryAvailability !== undefined) {
          inventoryAvailability["currentInventoryDelivery"] = tempStock;
          inventoryAvailability["hasInventoryDelivery"] = availableQuantityInMarket >= quantity;
          inventoryAvailability["hasInventoryBopis"] = bopisStock >= quantity;
          inventoryAvailability["deliveryType"] = deliveryType;
          inventoryAvailability["hasStockDelivery"] = availableQuantityInMarket !== "0";
          inventoryAvailability["hasStockBopis"] = bopisStock !== "0";
          inventoryAvailability["availableQuantityInMarket"] = availableQuantityInMarket;
          inventoryAvailability["bopisStock"] = bopisStock;
          inventoryAvailability["errorBopis"] = isBopisDisabled ? true : false;
          inventoryAvailability["errorDelivery"] = isDeliveryDisabled ? true : availableQuantityInMarket < quantity;
        }

        const handleSetQuantity = (value: string) => {
          // if (parseInt(unitQuantity) > 1 && parseInt(value) < parseInt(unitQuantity)) return;
          setQuantity(value);
          if (parseInt(value) >= parseInt(unitQuantity) || parseInt(value) === 0) {
            if (!isPaint) {
              debouncedSearch(value, rowData);
            } else {
              debouncedUpdatePaint(value, rowData, rowPaint);
            }
          }
        };

        const debouncedSearch = React.useRef(
          debounce(async (value, rowPaint) => {
            onQuantityUpdate(value, rowPaint);
          }, 1200)
        ).current;

        const debouncedUpdatePaint = React.useRef(
          debounce(async (value, rowData, rowPaint) => {
            onQuantityUpdate(value, { ...rowData, ...rowPaint });
          }, 1200)
        ).current;

        // if (
        //   parseInt(unitQuantity) > parseInt(quantity) &&
        //   parseInt(quantity) !== 0 &&
        //   window?.location?.pathname.includes("/cart")
        // ) {
        //   // console.log((parseInt(unitQuantity) - parseInt(quantity)) + parseInt(quantity))
        //   const newQuantityUpdate = parseInt(unitQuantity) - parseInt(quantity) + parseInt(quantity);
        //   handleSetQuantity(newQuantityUpdate.toString());
        // }

        /*const debouncedUpdate = _.debounce(function (value: string, item: any) {
          onQuantityUpdate(value, item);
        }, 3000);*/

        if (miniCartView && (isPaint ? false : rowData?.xitem_colorCode)) {
          return <QuantityDisplayPaintsMiniCart rowData={rowData} />;
        }
        return !miniCartView && readOnly ? (
          <QuantityDisplay rowData={rowData} />
        ) : (
          <>
            <ProductQuantity
              nominalQuantity={unitQuantity}
              quantity={quantity}
              handleSetQuantity={handleSetQuantity}
              isCart={true}
              isPaint={isPaint}
              maxValue={!miniCartView ? tempStock : ""}
              withButtons={!isPaint || miniCartView}
              disabled={isPaint ? false : rowData?.xitem_colorCode ? true : false}
            />
          </>
        );
      };

      const ItemDetailsCell = ({ rowData, ...props }) => {
        return (
          <>
            <StyledGrid
              container
              justifyContent="space-between"
              className={miniCartView ? "styled-qunatity-price" : ""}
              wrap={miniCartView ? "nowrap" : undefined}>
              {/* Condition to change viw for SI and TV */}
              {miniCartView && (
                <>
                  {!rowData.partNumber.includes("SI") && !rowData.partNumber.includes("TV") ? (
                    <StyledGrid item xs className={!rowData?.xitem_colorCode && "qty-price-section"}>
                      {/* Add & substract quantity */}
                      <StyledCartRowPaint
                        rowData={rowData}
                        variant="cart"
                        QuantityCell={QuantityCell}
                        isMiniCart={miniCartView}
                        onCloseDrawer={handleMiniCartClose}
                      />
                      <QuantityCell {...{ rowData }} />
                    </StyledGrid>
                  ) : (
                    <StyledBox display="flex" justifyContent="space-between" width={"100%"}>
                      <StyledTypography variant="bodyBaseline" component="span" className="product-quantity">
                        {t("OrderItemTable.Labels.Quantity") + " " + ~~rowData?.quantity}
                      </StyledTypography>
                    </StyledBox>
                  )}

                  {!rowData?.xitem_colorCode && (
                    <StyledGrid item xs className="qty-price">
                      <OrderItemPrice rowData={rowData} />
                    </StyledGrid>
                  )}
                </>
              )}

              {rowData.freeGift === "true" && (
                <StyledTypography variant="overline" color="textSecondary">
                  {t("OrderItemTable.Labels.Gift")}
                </StyledTypography>
              )}
              {!miniCartView && isRecurringOrderFeatureEnabled && rowData.disallowRecurringOrder === "1" && (
                <StyledTypography variant="overline" color="textSecondary">
                  {t("OrderItemTable.Labels.NonRecurring")}
                </StyledTypography>
              )}
            </StyledGrid>
            {/* MINICART UI*/}
          </>
        );
      };

      const DeleteActionCell = ({ rowData, ...props }) => {
        const cart = useSelector(cartSelector);
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const itemMemberId = rowData.xitem_memberId;
        const [clicked, setClicked] = useState<boolean>(false);
        const disabled =
          clicked || rowData.freeGift === "true" || (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch action to remove selected order item
         * @param item The selected order item
         */
        /*const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,
            orderItemId: orderItemId,
            fetchCatentries: true,
          };
          setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            AsyncCall.sendRemoveFromCartEvent(item, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };*/

        return (
          <>
            {miniCartView ? (
              <img
                className="styled-delete-trash-icon"
                alt="Trashcan icon"
                src={DeleteIconHDM}
                onClick={() => onQuantityUpdate("0", rowData)}
              />
            ) : (
              <StyledIconButton
                disabled={disabled}
                color="primary"
                className={"miniCartPopper--icon-trashcan delete-from-cart styled-delete-trash-button"}
                onClick={() => onQuantityUpdate("0", rowData)}>
                <DeleteOutlineIcon />
              </StyledIconButton>
            )}
          </>

          // <StyledIconButton
          //   disabled={disabled}
          //   color="primary"
          //   className={"miniCartPopper--icon-trashcan delete-from-cart styled-delete-trash-button"}
          //   onClick={() => onQuantityUpdate("0", rowData)}>
          //   {/* Delete icon */}
          //   {miniCartView ? (
          //     <img
          //       className="styled-delete-trash-icon"
          //       alt="Trashcan icon"
          //       src={DeleteIconHDM}
          //     />
          //   ) : (
          //     <DeleteOutlineIcon />
          //   )}
          // </StyledIconButton>
        );
      };

      const columnsMobile: TableColumnDef[] = [
        {
          title: "",
          idProp: "orderItemId",
          keyLookup: {
            key: CONSTANTS.thumbnail,
          },
          display: {
            cellStyle: {
              textAlign: "center",
            },
            template: ThumbnailCell,
          },
        },
        {
          // title: t("OrderItemTable.Labels.ItemDetails"),
          title: "",
          keyLookup: {
            key: CONSTANTS.name,
          },
          sortable: { fn: itemValCalc },
          display: {
            template: ItemDetailsCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.Status"),
          keyLookup: {
            key: CONSTANTS.orderItemInventoryStatus,
          },
          sortable: { fn: oaValCalc },
          display: {
            template: ({ rowData, ...props }) => (
              <>
                {rowData.availableDate === ""
                  ? rowData.orderItemInventoryStatus === INVENTORY_STATUS.available ||
                    rowData.orderItemInventoryStatus === INVENTORY_STATUS.allocated
                    ? t("CommerceEnvironment.inventoryStatus.Available")
                    : t("CommerceEnvironment.inventoryStatus.OOS")
                  : rowData.availableDate <= new Date()
                  ? t("CommerceEnvironment.inventoryStatus.Available")
                  : rowData.orderItemInventoryStatus !== INVENTORY_STATUS.backordered
                  ? t("CommerceEnvironment.inventoryStatus.Available")
                  : t("CommerceEnvironment.inventoryStatus.Backordered")}
              </>
            ),
          },
        },

        {
          title: t("OrderItemTable.Labels.Quantity"),
          keyLookup: {
            key: CONSTANTS.quantity,
          },
          sortable: { numeric: true, fn: quantityCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: QuantityCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.Price"),
          keyLookup: {
            key: CONSTANTS.orderItemPrice,
          },
          sortable: { numeric: true, fn: priceCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: ({ rowData, ...props }) => <OrderItemPrice rowData={rowData} />,
          },
        },
        {
          title: t("OrderItemTable.Labels.Actions"),
          keyLookup: {
            key: CONSTANTS.orderItemActions,
          },

          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: DeleteActionCell,
          },
        },
      ];

      const columnsDesktop: TableColumnDef[] = [
        {
          title: "",
          idProp: "orderItemId",
          keyLookup: {
            key: CONSTANTS.thumbnail,
          },
          display: {
            template: ThumbnailCell,
          },
        },
      ];

      let columns = miniCartView ? columnsMobile : columnsDesktop;

      if (readOnly) {
        columns = columns.filter((col) => col.keyLookup.key !== CONSTANTS.orderItemActions);
      }
      if (miniCartView) {
        columns = columns.filter(
          (col) =>
            col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus &&
            col.keyLookup.key !== CONSTANTS.quantity &&
            col.keyLookup.key !== CONSTANTS.orderItemPrice &&
            col.keyLookup.key !== CONSTANTS.orderItemActions
        );
      }

      // hide panel for any records with not attributes
      data
        .filter((r) => !r.attributes?.length)
        .forEach((r, idx) => {
          setValueForCell(TableConstants.NOPANEL, true, r, columns, tableState, () => {
            return;
          });
        });

      setTableState({ ...tableState });

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      mySite,
      miniCartView,
      handleMiniCartClose,
      isRecurringOrderFeatureEnabled,
      contractId,
      dispatch,
      CancelToken,
      readOnly,
      quantityList,
      defaultCurrencyID,
      data,
      stockAvailableStore,
    ]
  );

  useEffect(() => {
    setQuantityList(initQuantityData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!miniCartView) {
      setActionData({
        grids: [{ xs: true }],
        extraActions: [
          <>
            <div>
              <StyledTypography variant="textSegment" gutterBottom>
                {t("productDetail.CartOrderTittle")}
              </StyledTypography>
            </div>
            <StyledGrid item xs={12}>
              <Divider className="vertical-margin-2" />
            </StyledGrid>
            <StyledGrid item xs={12} align="center">
              <StyledTypography variant="bodySubtext" className="deliveryMethod--typography-darkGray">
                {t("productDetail.CartOrderLabel")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Divider className="top-margin-2" />
            </StyledGrid>
          </>,
        ],
      });
    }
  }, [miniCartView]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    columns,
    data,
    options,
    detailPanel: DetailPanel,
    labels: {
      labelRowsSelect: t("OrderItemTable.Labels.PageSizeLabel"),
      labelDisplayedRows: t("OrderItemTable.Labels.RowCount"),
      firstTooltip: t("OrderItemTable.Labels.FirstPage"),
      previousTooltip: t("OrderItemTable.Labels.PreviousPage"),
      nextTooltip: t("OrderItemTable.Labels.NextPage"),
      lastTooltip: t("OrderItemTable.Labels.LastPage"),
    },
    t,
    miniCartView,
    handleMiniCartClose,
    actionData,
  };
};
