import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { StyledBox } from "../../../hdm/elements";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import useScript from "../styled-carousel-hdm/useScript";

    interface IBazaarVoiceProps {
        productId: string | number;
        // showAdditionalInfo?: boolean;
    }


    const BazaarVoiceContainer = styled((props: any) => <StyledBox {...props} />)`
    ${({children, theme}) => `
            &.bz-container{
                margin: 5px;
            }

            & .bv_main_container{
                // justify-content: end;
                padding-left: 10px !important;

                & div.bv_button_component_container{
                    display: none !important; 
                }

                & div.bv_main_container_row_flex{
                    display: none !important;
                }

                & #ratings-summary{
                    padding-right: 0px !important;
                }

                & div.bv_stars_component_container{
                    padding-right: 0px !important;
                }

                & div.bv_numReviews_text{
                    color: #000000 !important;
                }

                & div.bv_avgRating_component_container {
                    margin-left: 5px !important;
                    color: #000000 !important;
                }
            }
        `}
    `;

    const BazaarVoice: FC<IBazaarVoiceProps> = ({ productId }) => {

        const storeConfData = useSelector(onlineStoreRdc);

        console.log(storeConfData.BAZAARVOICE_URL)


        //* Bazaar Voice script added to index.html
        // useEffect(() => {
        //     const elementRef = document.getElementById(`script-body-${productId}`)  
        //     if(!elementRef) return

        //     const script = document.createElement('script');
        //     script.src = "https://apps.bazaarvoice.com/deployments/home-depotmx/main_site/production/es_MX/bv.js";
        //     script.async = true;
        //     elementRef.appendChild(script);
        //     return () => {
        //         elementRef.removeChild(script);
        //     }
        // }, [productId])

        useScript(`https:${storeConfData.BAZAARVOICE_URL}`, "")

        

    return (
        <BazaarVoiceContainer className="bz-container" id={`script-body-${productId}`}>
            
            <div data-bv-show="rating_summary" data-bv-product-id={productId} data-bv-seo="true"></div>

        </BazaarVoiceContainer>
    );
    };

export default BazaarVoice;
