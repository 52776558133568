/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const CustomGrid = (props: any) => <Grid {...props} />;

const StyledGridProductContainer = styled(CustomGrid)`
  ${({ theme }) => `
    margin-bottom: 0px;
    margin-left: 2px;
   /* border: 1px solid red;*/
   `}
`;

export { StyledGridProductContainer };
