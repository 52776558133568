import { response } from "msw/lib/types";
// import { call, put } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import * as ACTIONS from "../../action-types/hdmData";
import { axiosGetInventory } from "../../api/hdmData";

const call: any = Effects.call;

export function* onLoadGetInventory(query) {
  try {
    if (query.fetch) {
      const response: any = yield call(axiosGetInventory, query);
      yield Effects.put({
        type: ACTIONS.FETCH_HDM_DATA_SUCCESS,
        payload: { response: response, option: query.option },
      });
    } else {
      yield Effects.put({
        type: ACTIONS.FETCH_HDM_DATA_SUCCESS,
        payload: { response: query.data, option: query.option },
      });
    }
  } catch (error) {
    console.log("error", error);
    yield Effects.put({ type: ACTIONS.FETCH_HDM_DATA_ERROR, payload: error });
  }

  return "Hello One";
}
