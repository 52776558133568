import { associatedAccountsType } from "../interfaces/ProLoginInterfaces";

// export const formatAssociatedAccounts = (associatedAccounts:associatedAccountsType<string>) => {
export const formatAssociatedAccounts = (associatedAccounts:any) => {

    const formatedArray:string[] = [];
    const associatedAccountsValues = associatedAccounts?.orgDetails;
    const associatedAccountLogonsId = associatedAccounts?.logonIds;

    for (const key in associatedAccountsValues) {
        const obj:any = {
            key: key,
            value: [associatedAccountsValues?.[key], associatedAccountLogonsId?.[key]]
        };
        formatedArray.push(obj);
    }

    return formatedArray;
}