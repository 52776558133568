import styled from "styled-components";
import { StyledPaper } from "../../../hdm";

export const StyledRegistrationContainer = styled((props: any) => <StyledPaper {...props} />)`
${({ theme, isMobile, passwordValidations, isPopUp, variant}) => `
    width: ${isMobile ? "auto" : isPopUp ? "100%" : 50+"%"};
    padding: ${theme.spacing(isPopUp ? 0 : 6)}px ${theme.spacing(isMobile ? 3 : isPopUp ? 4 : 10)}px ${theme.spacing(isPopUp ? 0 : 6)}px ${theme.spacing(isMobile ? 3 : isPopUp ? 4 : 10)}px;
    box-shadow: initial;
    display: flex;
    overflow:unset;
    justify-content: center;
    @media (max-width: 960px){
        margin-left: ${theme.spacing(isPopUp ? 0 : 1)}px;
        margin-right: ${theme.spacing(isPopUp ? 0 : 1)}px;
    }

    &.no-padding{
        padding:0px
    }

    &.item-padding{
        padding: 2.5px
    }

    & .primary-color-divider{
        width: fit-content;
        border-bottom: solid 2px ${theme.palette.primary.main}; 
    }

    & .gray-color-divider{
        border-bottom: solid 1px ${theme.palette.gray["50"]}; 
        flex-grow: 1;
        margin-bottom: ${theme.spacing(1)}px;
    }

    & .password-criterias-list{
        & ul{
            list-style: none;

            & li{
                display: flex;
                align-items: center;
                color: ${theme.palette.gray["300"]};
                max-height: ${theme.spacing(4)}px;
            }

            & li:before{
                content: "•";
                padding-right: ${theme.spacing(1)}px;
                font-weight: 900;
                font-size: 28px;
                color: ${theme.palette.gray["300"]};
            }
        }
    }

    & .show-password-icon{
        cursor: pointer;
        color: ${theme.palette.gray["300"]};
        font-size: large;
    }

    & .terms-conditions-and-privacy-link{
        color: ${theme.palette.secondary.main};
        cursor: pointer;
    }

    & .sign-in-link-container{
        text-align: initial;
    }

    & .subscribe-checkbox{
        transform: translateX(-5px);
    }

    & .MuiFormHelperText-root{
        color: ${theme.palette.error.main};
    }

    ${passwordValidations &&
        `
        & #first-requirement{
            color: ${passwordValidations.firstRequirement === 2 
                    ? theme.palette.success.main 
                    : passwordValidations.firstRequirement === 1 
                    ? theme.palette.error.main 
                    : theme.palette.gray["300"]};
        }
            & #first-requirement:before{
                color: ${passwordValidations.firstRequirement === 2 
                        ? theme.palette.success.main 
                        : passwordValidations.firstRequirement === 1 
                        ? theme.palette.error.main 
                        : theme.palette.gray["300"]};
            }
    
        & #second-requirement{
            color: ${passwordValidations.secondRequirement === 2 
                    ? theme.palette.success.main 
                    : passwordValidations.secondRequirement === 1 
                    ? theme.palette.error.main 
                    : theme.palette.gray["300"]};
        }
            & #second-requirement:before{
                color: ${passwordValidations.secondRequirement === 2 
                        ? theme.palette.success.main 
                        : passwordValidations.secondRequirement === 1 
                        ? theme.palette.error.main 
                        : theme.palette.gray["300"]};
            }
    
        & #third-requirement{
            color: ${passwordValidations.thirdRequirement === 2 
                    ? theme.palette.success.main 
                    : passwordValidations.thirdRequirement === 1 
                    ? theme.palette.error.main 
                    : theme.palette.gray["300"]};
        }
            & #third-requirement:before{
                color: ${passwordValidations.thirdRequirement === 2 
                        ? theme.palette.success.main 
                        : passwordValidations.thirdRequirement === 1 
                        ? theme.palette.error.main 
                        : theme.palette.gray["300"]};
            }
    
        & #fourth-requirement{
            color: ${passwordValidations.fourthRequirement === 2 
                    ? theme.palette.success.main 
                    : passwordValidations.fourthRequirement === 1 
                    ? theme.palette.error.main 
                    : theme.palette.gray["300"]};
        }
            & #fourth-requirement:before{
                color: ${passwordValidations.fourthRequirement === 2 
                        ? theme.palette.success.main 
                        : passwordValidations.fourthRequirement === 1 
                        ? theme.palette.error.main 
                        : theme.palette.gray["300"]};
            }
    
        & #fifth-requirement{
            margin-top: ${isMobile && isPopUp && theme.spacing(1)}px;
            color: ${passwordValidations.fifthRequirement === 2 
                    ? theme.palette.success.main 
                    : passwordValidations.fifthRequirement === 1 
                    ? theme.palette.error.main 
                    : theme.palette.gray["300"]};
        }
            & #fifth-requirement:before{
                color: ${passwordValidations.fifthRequirement === 2 
                        ? theme.palette.success.main 
                        : passwordValidations.fifthRequirement === 1 
                        ? theme.palette.error.main 
                        : theme.palette.gray["300"]};
            }
        `}

    
    
`}
`;
export const StyledRegistrationEspotContainer = styled((props: any) => <StyledPaper {...props} />)`
${({ theme, isMobile }) => `
    width: ${isMobile ? "auto" : 50+"%"}; 
    height: ${isMobile ? 500+"px" : "auto"};
    background-color: ${theme.palette.gray["100"]};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: initial;

`}
`;