import styled from "styled-components";
import { StyledGrid, StyledBox } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes/color-palette";
import { tabletBreakpoint } from "../../../hdm/functions/utils";

export const ContainerPaymentMethod = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, fromCheckOutProfiles }) => `
    padding: ${theme.spacing(fromCheckOutProfiles ? 2 : 1)}px;

    .cyberSource--flex-container{
        height: 40px;
        padding-left: ${theme.spacing(2)}px;
        margin-top: ${theme.spacing(2)}px;
        border: 1px solid  ${theme.palette.gray["100"]};
        align-items: center;
        display: flex;
        padding-right: ${theme.spacing(1)}px;
    }

    .cyberSource--flex-iFrame{
        height: 40px;
        width: 100%;
    }

    .cyberSource--flex-containerError{
        border-bottom: 2px solid ${theme.palette.error.main};
    }

    .cyberSource--flex-container:hover{
        border: 1px solid  ${theme.palette.primary.main};
    }

    .cyberSource--img-hideLogo{
        display: none;
        width: 33px;
    }

    .cyberSource--img-cardLogo{
        height: 16px;
        margin-right: ${theme.spacing(2)}px;
    }

    .width-display{
        width: calc(50% - 5px);
        display: inline-block;
    }

    .right-margin{
        margin-right: ${theme.spacing(2)}px;
    }
  `}
`;
