/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const dimensions = {
  header: {
    height: {
      desktop: 88,
      mobile: 64,
    },
  },
  menu: {
    height: {
      desktop: 40,
    },
  },
  searchBar: {
    DesktopWidth: 600,
    height: 40,
    MobileWidth: 240,
  },
  productCard: {
    swatch: 20,
    thumbnail: 285,
  },
  pagination: {
    button: 40,
  },
  inputFields: {
    height: 40,
  },
  styledProductCard: {
    width: 285,
    height: 285,
  },
  styledPromocionCardmedia: {
    width: 25,
    height: 25,
  },
};
