import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Standard libraries
import Axios, { Canceler } from "axios";
//Components
import { useTranslation } from "react-i18next";
import StyledSwipeableDrawerHDM from "../../custom-components/styled-drawer-hdm/StyledDrawerHDM";
import {
  StyledFormControl,
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
  StyledTypography,
  StyledGrid,
  StyledRadioGroup,
} from "../../../hdm/elements";
import { StyledStoreSelectorContainer } from "./styled-store-selector-container/StyledStoreSelectorContainer";
import { StyledLinkButton } from "./styled-link-button/StyledLinkButton";
import styled from "styled-components";
import { StyledRadioCard } from "./styled-radio-card/StyledRadioCard";
import StyledDialogStore from "./styled-dialog-store/StyledDialogStore";
import { Divider } from "@material-ui/core/";
//Foundation libraries
import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";
import { pdpSeoRdc } from "../../../redux/selectors/hdmData";

import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import jsonStateCityList from "./json-stores-info/StoreLocatorStateCitiesList.json";
import defaultData from "./json-stores-info/defaultData.json";
import CurrentSelectedStore from "../styled-current-selected-store/CurrentSelectedStore";
import { getCookie, setCookie } from "./cookieHandler";
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { SET_CURRENT_STORE_ACTION } from "../../../redux/actions/current-store";
import { currentStoreSelector } from "../../../redux/selectors/current-store";

import { getSite, useSite } from "../../../_foundation/hooks/useSite";
import { current } from "@reduxjs/toolkit";
import { StyledBox } from "@hcl-commerce-store-sdk/react-component";
import { guestStatusSelector, loginStatusSelector } from "../../../redux/selectors/user";
import { INIT_STATE_FROM_STORAGE_ACTION, LISTEN_USER_FROM_STORAGE_ACTION } from "../../../redux/actions/user";
import getDisplayName from "react-display-name";
import { localStorageUtil, storageSessionHandler } from "../../../_foundation/utils/storageUtil";
import { SELLERS_GET_ACTION } from "../../../redux/actions/sellers";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

import * as ROUTES from "../../../constants/routes";
import { useLocation } from 'react-router';
import { seoSelector } from "../../../redux/selectors/seo";

interface IStoreSelectorProps {
  isOpen: boolean;
  showHeader?: boolean;
  children?: JSX.Element[] | JSX.Element;
  setOpenDrawer: (boolean) => void;
  externalStoreSelected?: any;
}

interface CityStores {
  stateId: string;
  stores: Array<any>;
}

const GpsIcon = styled(({ ...props }) => <GpsFixedIcon {...props} />)`
  ${({ theme }) => `
  margin-right: ${theme.spacing(1)}px;
  vertical-align:middle;
  margin-top: -4px;
  width: 18px;
`}
`;
const StyledKeyboardArrowRight = styled(({ ...props }) => <KeyboardArrowRight {...props} />)`
  ${({ theme }) => `
  vertical-align:middle;
  margin-top: -2px;
  width: 25px;
`}
`;
const StyledKeyboardArrowLeft = styled(({ ...props }) => <KeyboardArrowLeft {...props} />)`
  ${({ theme }) => `
  vertical-align:middle;
  margin-top: -2px;
  margin-left: -10px;
`}
`;
const StyledLocationOnIcon = styled(({ ...props }) => <LocationOnIcon {...props} />)`
  ${({ theme }) => `
  vertical-align:middle;
  margin-right: ${theme.spacing(1)}px;
  width: 14px;
  margin-top: -1px;
`}
`;
const StyledKeyboardArrowDown = styled(({ ...props }) => <KeyboardArrowDown {...props} />)`
  ${({ theme }) => `
  display:flex;
  font-size: 24px;
`}
`;

const RowIconSpan = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  width: ${theme.spacing(5)}px;
  padding-top: 7px;
  height: 40px;
  display: inline-block;
  text-align: center;
  margin-right: ${theme.spacing(1)}px;
  z-index: -1;
}
`}
`;

let isChangeStore = false

const StyledStoreSelector: FC<IStoreSelectorProps> = ({ isOpen, setOpenDrawer, showHeader, externalStoreSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const maxExpTime = 365;
  const minExpTime = 1;
  const storeDefault = t("storeLocator.defaultStoreId");
  const stateDefault = t("storeLocator.defaultStateId");
  const selectStoreTitle = t("StoreSelector.stores");
  const geoLocationCookieName = "geoLocationHDP";
  const geoLocationLatitudeCookieName = t("storeLocator.latitudeCookieName");
  const geoLocationLongitudeCookieName = t("storeLocator.longitudeCookieName");
  const stateList = jsonStateCityList.formstatesObject;
  const cityList = jsonStateCityList.formScheduleObject;
  const [stateSelected, setStateSelected] = useState("");
  const [storeCities, setStoreCities] = useState<CityStores>();
  const [radioStoreValue, setRadioStoreValue] = useState("");
  const [moreInfoState, setMoreInfoState] = useState(false);
  const [stlocIdMoreInfo, setStlocIdMoreInfo] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [enableGeolocButton, setEnableGeolocButton] = useState(true);
  const [isLoadingGeolocalization, setIsLoadingGeolocalization] = useState(false);
  
  const storeConfData = useSelector(onlineStoreRdc);
  const [menuChange, setMenuChange] = useState(isOpen);
  const btEnvironment = storeConfData?.BT_ENVIRONMENT;
  const isBTEnabled = storeConfData?.BT_ACTIVE_FLAG;
  const { mySite } = useSite();
  const externalStoreSelect = externalStoreSelected;
  const seoRdc = useSelector(pdpSeoRdc);
  const currentStore = useSelector(currentStoreSelector);
  const navigate = useNavigate();
  const [isReduxState, setIsReduxState] = useState(false);
  const divRef = React.useRef<HTMLButtonElement>(null);
  const isB2B: boolean = mySite?.isB2B ? mySite.isB2B : false;

  const userLoggedIn = useSelector(loginStatusSelector);
  const isGuest = useSelector(guestStatusSelector);
  const seoConfig = useSelector(seoSelector);

  const location: any = useLocation();

  window.setRadioStoreValue = setRadioStoreValue;
  window.setStateSelected = setStateSelected;
  window.setOpenDialogGps = setOpenDialog;

  const widgetName = getDisplayName(StyledStoreSelector);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  // useEffect(() => {
  //   if (userLoggedIn && !isGuest) {
  //     sendStoreDataForHeader();
  //   }
  // }, [userLoggedIn, isGuest]);

  useEffect(() => {
    if (currentStore?.currentStore && currentStore.currentStore?.isExternal === true) {
      setIsReduxState(currentStore?.currentStore?.isExternal);
      setRadioStoreValue(currentStore.currentStore.stLocId);
      setStateSelected(currentStore.currentStore.stateId);
    } else {
      setIsReduxState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    if (stateSelected !== "") {
      setMoreInfoState(false);
      const findStores = cityList.filter((stores) => stores.stateId === stateSelected)[0];
      setStoreCities(findStores);
      document.cookie = `${'WC_stProv'}=${stateSelected};expires=Session;path=/;`;
      document.cookie = `${'WC_stFind'}=${'1'};expires=Session;path=/;`;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);

  useEffect(() => {
    if (!isOpen) {
      handleMoreInfoChange(0, false);

      if (menuChange == true) {
        //BTT purpose
        if (isBTEnabled && isBTEnabled != "false") {
          try {
            window["bttUT"].end({ pageName: "StoreLocator-VT", txnName: btEnvironment });
          } catch (error) {
            console.log("Error triggering bt storeLocator");
          }
        }
      }
    } else {
      //BTT purpose
      if (isBTEnabled && isBTEnabled != "false") {
        try {
          window["bttUT"].start({ pageName: "StoreLocator-VT", txnName: btEnvironment });
        } catch (error) {
          console.log("Error triggering bt storeLocator");
        }
      }
    }
    setMenuChange(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (radioStoreValue !== "") {
      const geoLocationCookieS = getCookie(geoLocationCookieName);
      if (geoLocationCookieS !== "") {
        const geoLocationCookie = JSON.parse(geoLocationCookieS);
        if (radioStoreValue !== "" && geoLocationCookie.hasPreviousAnswer) {
          if (geoLocationCookie.stLocId !== radioStoreValue) {
            buildCookie(true, true, maxExpTime);
          }
        }
        if (!isReduxState) {
          sendStoreDataForHeader();
        } else {
          setIsReduxState(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioStoreValue]);

  const sendStoreDataForHeader = async() => {
    const searchParams = new URLSearchParams(location.search);
    const store_code = searchParams.get("store_code");
    
    let storeValue = store_code ?? radioStoreValue;
    let stateValue = stateSelected;
    if (storeValue === "" || !storeValue) {
      storeValue = storeDefault;
    }
    if (stateValue === "" || !stateValue) {
      stateValue = stateDefault;
    }

    const findStores: CityStores = cityList.filter((states: any) =>
      states.stores.some((cities) => cities.stLocId === storeValue)
    )[0];
    const findStore = findStores?.stores.filter((stores: any) => stores.stLocId === storeValue)[0];

    const defaultPayload = {
      findStore: {
        ...findStore,
        stateId: stateSelected,
        isExternal: false,
      },
    };

    const setDefaultStorePayload = {
      defaultStore: storeValue,
      fromPageName: "2",
    };

    await Axios.post(
      `/wcs/resources/store/${mySite.storeID}/hdm/store/setDefault?responseFormat=json`,
      setDefaultStorePayload
    )
      .then((response) => response.data)
      .then((res) => {
        if (res.uniqueId) {
          const payload = {
            findStore: {
              ...findStore,
              uniqueID: res.uniqueId,
              stateId: stateSelected,
              isExternal: false,
            },
          };
            if(isB2B){
              setCookie("WC_MarketId_11401", payload.findStore.marketId, 1)            
              setCookie("WC_DefaultStore_11401", payload.findStore.stLocId, 1)            
            }else {
              setCookie("WC_MarketId_10351", payload.findStore.marketId, 1)            
              setCookie("WC_DefaultStore_10351", payload.findStore.stLocId, 1)            
            }
            dispatch(SET_CURRENT_STORE_ACTION(payload));
          } else {
            if(isB2B){
              setCookie("WC_MarketId_11401", defaultPayload.findStore.marketId, 1)
              setCookie("WC_DefaultStore_11401", defaultPayload.findStore.stLocId, 1)            
            }else {
              setCookie("WC_MarketId_10351", defaultPayload.findStore.marketId, 1)
              setCookie("WC_DefaultStore_10351", defaultPayload.findStore.stLocId, 1)            
            }
            dispatch(SET_CURRENT_STORE_ACTION(defaultPayload));
        }
        if (seoRdc && seoRdc !== "") {
          navigate(seoRdc);
        }else if(location?.pathname){
          if (location?.pathname.startsWith(ROUTES.PRODUCT_PAGE) && !store_code) {
              navigate(`${location?.pathname + location?.hash}${searchParams.size > 0 ? `?${searchParams.toString()}` : ""}`);
            }
        }
      })
      .catch(error => {
        console.log('error', error)
        dispatch(SET_CURRENT_STORE_ACTION(defaultPayload));
      })
      // if(isChangeStore){
      //   window.location.reload();
      // }
  };

  const buildCookie = (withParams, hasPreviousAnswer, time) => {
    let geoLocationCookie = {
      hasPreviousAnswer: hasPreviousAnswer,
      stLocId: "",
      stateId: "",
    };
    if (withParams) {
      const locId = radioStoreValue !== "" ? radioStoreValue : storeDefault;
      geoLocationCookie = {
        hasPreviousAnswer: hasPreviousAnswer, //The user already set a store
        stLocId: locId, //Store locator id
        stateId: stateSelected, //Store state id
      };
    }
    setCookie(geoLocationCookieName, "", 0);
    const geoLocationCookieS = JSON.stringify(geoLocationCookie);
    setCookie(geoLocationCookieName, geoLocationCookieS, time);
  };

  const geolocalizationInit = (geoStatePermission) => {
    const geoLocationCookieS = getCookie(geoLocationCookieName);

    if (geoLocationCookieS !== "") {
      const geoLocationCookie = JSON.parse(geoLocationCookieS);
      if (geoLocationCookie && geoLocationCookie?.stLocId !== "") {
        setStateSelected(geoLocationCookie.stateId);
        setRadioStoreValue(geoLocationCookie.stLocId);
      } else {
        setStateSelected(stateDefault);
        setRadioStoreValue(storeDefault);
        if (geoStatePermission === "granted" || geoStatePermission === "prompt") {
          if (geoLocationCookie && !geoLocationCookie.hasPreviousAnswer && geoLocationCookie.stLocId === "" && !navigator.userAgent.toLowerCase().includes('prerender')) {
            setOpenDialog(true);
          }
        }
      }
    } else {
      setStateSelected(stateDefault);
      setRadioStoreValue(storeDefault);
      buildCookie(false, false, minExpTime);
      if (geoStatePermission === "granted" || geoStatePermission === "prompt") {
        //if LIA keep closed
        const params = new URLSearchParams(window.location.search);
        /**Check for store_code for LIA feature*/
        const store_code = params.get("store_code");
        if (store_code) {
          setOpenDialog(false);
          buildCookie(false, true, minExpTime);
        } else {
          setOpenDialog(true);
        }
      } else {
        buildCookie(false, true, minExpTime);
      }
    }
    if (geoStatePermission === "granted" || geoStatePermission === "prompt") {
      setEnableGeolocButton(true);
    } else {
      setEnableGeolocButton(false);
    }
  };
  useEffect(
    () => {
      if (navigator?.permissions) {
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
          geolocalizationInit(result.state);
        });
      } else {
        geolocalizationInit("prompt");
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleStateChange = (selectState) => {
    setStateSelected(selectState.target.value);
  };

  const handleStoreChange = (radioStore) => {
    const searchParams = new URLSearchParams(location.search);
    const store_code = searchParams.get("store_code");
    if(store_code) {
      searchParams.delete("store_code");
      const newUrl = `${window.location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ""}`;
      window.history.pushState({}, "", newUrl);
    }
    
    setOpenDrawer(false);
    isChangeStore = true
    setRadioStoreValue(radioStore);
  };

  const handleMoreInfoChange = (storeData, value) => {
    if (value) {
      setStlocIdMoreInfo(storeData.stLocId);
    } else {
      setStlocIdMoreInfo(0);
    }
    setMoreInfoState(value);
  };

  const handleAcceptGeolocalization = () => {
    setMoreInfoState(false);
    setIsLoadingGeolocalization(true);
    navigator.geolocation.getCurrentPosition(saveGeolocation, geoLocationError);
  };

  const saveGeolocation = (geolocation) => {
    setCookie(geoLocationLatitudeCookieName, "", 0);
    setCookie(geoLocationLongitudeCookieName, "", 0);
    setCookie(geoLocationLatitudeCookieName, geolocation.coords.latitude, maxExpTime);
    setCookie(geoLocationLongitudeCookieName, geolocation.coords.longitude, maxExpTime);

    storeLocatorService
      .findStores({
        latitude: geolocation.coords.latitude,
        longitude: geolocation.coords.longitude,
        radius: 100
      })
      .then((response) => response.data)
      .then((res) => {
        if (res?.PhysicalStore) {
          setGeoStore(res.PhysicalStore[0].storeName);
        } else {
          handleCancelGeolocalization();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setGeoStore = (stLocId) => {
    const findStores = cityList.filter((states: any) => states.stores.some((cities) => cities.stLocId === stLocId))[0];
    setStateSelected(findStores.stateId);
    handleStoreChange(stLocId);
    buildCookie(true, true, maxExpTime);
    setIsLoadingGeolocalization(false);
    setOpenDialog(false);
  };

  const geoLocationError = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      setEnableGeolocButton(false);
      setIsLoadingGeolocalization(false);
      setOpenDialog(false);
      setOpenDrawer(true);
    }
  };

  const handleCancelGeolocalization = () => {
    setOpenDialog(false);
    buildCookie(false, true, minExpTime);
  };

  const handleOpenStoreSelect = () => {
    if (location.pathname === ROUTES.CHECKOUT) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
    handleCancelGeolocalization();
  };
  window.setOpenDrawer = setOpenDrawer;
  const MoreInfoStore = (props) => {
    return (
      <>
        <StyledGrid style={!props.display ? { display: "none" } : {}}>
          <Divider />
          <StyledGrid className={"storeSelector--styledGrid-goBackLink"}>
            <StyledGrid className={"storeSelector--styledGrid-locatorContainerBottom"}>
              <StyledLinkButton
                variant="bodyBaseline"
                className={"storeSelector--styledTypography-link"}
                onClick={() => handleMoreInfoChange(0, false)}>
                <StyledKeyboardArrowLeft color="secondary" viewBox="0 0 20 24" />
                {t("storeLocator.goBackStore")}
              </StyledLinkButton>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid>
            <StoreDetails stLocId={props.stlocId} showSchedule={true} />
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };
  const StoreDetails = (props) => {
    const stLocId = props.stLocId;
    const storeDetails = storeCities?.stores.filter((stores) => stores.stLocId === stLocId)[0];

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const d = new Date(yyyy + "-" + mm + "-" + dd);
    const day = d.getDay();
    let schedule = defaultData?.scheduleDiystore?.schedule;
    let storeDetail = defaultData;
    if (storeDetails?.scheduleDiystore) {
      schedule = storeDetails?.scheduleDiystore?.schedule;
      storeDetail = storeDetails;
    } else {
      schedule = defaultData?.scheduleDiystore?.schedule;
    }
    return (
      <>
        <StyledGrid>
          <StyledTypography variant="headingFeatured" className={"storeSelector--styledTypography-capital"}>
            {storeDetails?.storeName} #{storeDetails?.stLocId}
          </StyledTypography>
          <div style={{ display: "flex" }}>
            <CurrentSelectedStore storeDetails={storeDetails} variant="storeStatus" />
          </div>
          <StyledGrid className={"storeLocator-styledGrid-addressInfo"}>
            <StyledTypography variant="bodySubText" className={"storeSelector--styledTypography-secondary"}>
              {storeDetail?.address1 + ", "} {storeDetail?.address2 + ", "} {storeDetail?.city + ", "}
              {t("storeLocator.cp") + " "} {storeDetail?.zipcode}{" "}
            </StyledTypography>
          </StyledGrid>
          <StyledTypography
            variant="bodySubText"
            weight="semibold"
            style={!props.showSchedule ? { display: "none" } : { padding: "4px 0" }}>
            {t("storeLocator.storeHours")}
            <br />
          </StyledTypography>
          <StyledGrid
            component="ul"
            className="storeSelector-listDecoration"
            container
            style={!props.showSchedule ? { display: "none" } : {}}>
            {React.Children.toArray(
              schedule.map((days: any, index) => (
                <>
                  <StyledGrid component="li" item xs={3} md={3} style={{ padding: "4px 0" }}>
                    <StyledTypography variant="bodySubText" weight={index === day ? "semibold" : ""}>
                      {days.dayName}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid component="li" item xs={8} md={8} style={{ padding: "4px 0", listStyleType: "none" }}>
                    <StyledTypography variant="bodySubText" weight={index === day ? "semibold" : ""}>
                      {days.open} {" " + t("storeLocator.to") + " "} {days.close}
                    </StyledTypography>
                  </StyledGrid>
                </>
              ))
            )}
          </StyledGrid>
          <StyledGrid style={!props.showSchedule ? { display: "none" } : {}}>
            <StyledGrid className={"storeSelector--styledGrid-callLinkMoreInfo"}>
              <CallLink value={storeDetail?.phone} />
            </StyledGrid>
            <Divider />
            <StyledGrid className={"storeSelector--styledGrid-showMap"}>
              <StyledLocationOnIcon fontSize={"small"} color={"secondary"} />
              <StyledTypography variant="bodyBaseline">
                <a
                  href={t("storeLocator.mapsUrl", { lat: storeDetail?.latitude, long: storeDetail?.longitude })}
                  target="_blank"
                  rel="noreferrer">
                  {t("storeLocator.showMap")}
                </a>
              </StyledTypography>
              <StyledKeyboardArrowRight color="primary" />
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };
  const CallLink = (props) => {
    return (
      <>
        <StyledTypography variant="bodyBaseline">
          <a href={"tel:" + props.value}>{props.value}</a>
        </StyledTypography>
      </>
    );
  };

  const StoresList = (props) => {
    return (
      <>
        <StyledFormControl
          variant="outlined"
          fullWidth
          style={!props.display ? { display: "none" } : {}}
          className={"noMarginTop"}>
          <StyledRadioGroup
            name="radio-store-selector"
            value={radioStoreValue}
            onChange={(radioStore) => handleStoreChange(radioStore.target.value)}>
            {React.Children.toArray(
              storeCities?.stores
                .sort((a, b) => a.storeName.localeCompare(b.storeName))
                .map((store: any) => {
                  let phone = defaultData.phone;
                  if (store?.phone) {
                    phone = store?.phone;
                  }
                  return (
                    <>
                      <Divider />
                      <StyledGrid className={"storeSelector--styledGrid-FormContainer"}>
                        <StoreDetails stLocId={store.stLocId} showSchedule={false} />
                        <StyledGrid className={"storeSelector--styledGrid-moreInfoContainer"}>
                          <CallLink value={phone} />
                          <StyledLinkButton variant="bodyBaseline" onClick={() => handleMoreInfoChange(store, true)}>
                            {t("storeLocator.moreInfo")}
                          </StyledLinkButton>
                        </StyledGrid>
                        <StyledRadioCard
                          value={store.stLocId}
                          checked={store.stLocId === radioStoreValue ? true : false}
                        />
                      </StyledGrid>
                    </>
                  );
                })
            )}
          </StyledRadioGroup>
        </StyledFormControl>
      </>
    );
  };

  return (
    <>
      <StyledDialogStore
        title={t("storeLocator.dialogAskLocationTittle")}
        children={t("storeLocator.dialogAskLocationContent")}
        accept={t("storeLocator.dialogAskLocationAccept")}
        cancel={t("storeLocator.dialogAskLocationCancel")}
        content1={t("storeLocator.dialogAskLocationSubContent1")}
        content2={t("storeLocator.dialogAskLocationSubContent2")}
        content3={t("storeLocator.dialogAskLocationSubContent3")}
        terms={{ text: t("storeLocator.dialogAskLocationPrivacy"), url: t("storeLocator.dialogAskLocationPrivacyURL") }}
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={handleAcceptGeolocalization}
        onClose={handleCancelGeolocalization}
        onStoreSelect={handleOpenStoreSelect}
        isLoading={isLoadingGeolocalization}
      />
      <StyledSwipeableDrawerHDM
        isOpen={isOpen}
        setOpenDrawer={setOpenDrawer}
        variant={"stores"}
        currentStore={currentStore}
        setStateSelected={setStateSelected}
        title={selectStoreTitle}>
        <StyledStoreSelectorContainer>
          <StyledGrid className={"storeSelector--styledGrid-container"}>
            <StyledGrid className={"storeSelector--styledGrid-locatorContainer"}>
              <StyledLinkButton
                variant="bodyBaseline"
                onClick={() => setOpenDialog(true)}
                className={"storeSelector--styledTypography-link"}
                style={!enableGeolocButton ? { display: "none" } : {}}>
                <GpsIcon color="secondary" fontSize="small" />
                {t("storeLocator.useLocation")}
              </StyledLinkButton>
            </StyledGrid>
            <StyledFormControl variant="outlined" fullWidth>
              <StyledInputLabel id="store-selector-select-label">{t("storeLocator.selectLabel")}</StyledInputLabel>
              <StyledBox className={"storeSelector--styledBox"} ref={divRef}>
                <StyledSelect
                  fullWidth
                  labelId="store-selector-select-label"
                  id="store-selector-select"
                  label={t("storeLocator.selectLabel")}
                  value={stateSelected}
                  style={{ position: "absolute" }}
                  onChange={(selectState) => handleStateChange(selectState)}
                  MenuProps={{
                    anchorReference: divRef.current,
                    anchorPosition: { top: "200", left: "400" },
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                  }}>
                  <StyledMenuItem value="" fullWidth disabled>
                    <StyledTypography variant="bodyBaseline">{t("storeLocator.stateSelect")}</StyledTypography>
                  </StyledMenuItem>
                  {stateList.map((state) => (
                    <StyledMenuItem
                      value={state.geonodeId}
                      key={state.geonodeId}
                      className={"styledSelect--menuItems-color"}
                      fullWidth>
                      <StyledTypography variant="bodyBaseline">{state.state}</StyledTypography>
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledGrid className={"storeLocator--styledGrid-arrowSelect"}>
                  <RowIconSpan>
                    <StyledKeyboardArrowDown color="primary" />
                  </RowIconSpan>
                </StyledGrid>
              </StyledBox>
            </StyledFormControl>
            <StoresList display={!moreInfoState} />
            <MoreInfoStore display={moreInfoState} stlocId={stlocIdMoreInfo} />
          </StyledGrid>
        </StyledStoreSelectorContainer>
      </StyledSwipeableDrawerHDM>
    </>
  );
};

export default StyledStoreSelector;
