import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AgregarArticulo from "../../../../functions/AgregarArticulo";
import { palette } from "../../../../themes";
import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
} from "../../containers-precio-promocion";
import ProductoGratisRadio from "./ProductoGratisRadio";

import AddIcon from "@material-ui/icons/Add";

const ProductoGratisContent = (props) => {
  const {
    promotionDesc,
    imageURL,
    selectedImage,
    articulos,
    selectedArticulo,
    handleSelectedArticulo,
    partNumberGift,
    partNumber,
    quantity,
    baseQty,
    giftQty,
    product,
    handleCloseProductos,
    productData,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <ContainerStyledBox mx={2} my={2}>
        <ContainerStyledTypography variant="bodyBaseline" component="span">
          {promotionDesc}
        </ContainerStyledTypography>
      </ContainerStyledBox>

      <ContainerStyledBox display="flex" height={150} alignItems="center" justifyContent="center">
        <ContainerStyledBox align="center">
          <ContainerStyledCardMedia
            image={imageURL}
            className="selected-productogratis-image"
            title=""
            component="img"
          />
          <ContainerStyledTypography variant="bodyBaseline" component="span">
            {t("productDetail.ThisProduct")}
          </ContainerStyledTypography>
        </ContainerStyledBox>
        <ContainerStyledBox>
          <AddIcon
            className="add-icon"
            style={{ fontSize: "24px", color: palette.gray[200], marginLeft: "5px", marginRight: "5px" }}
          />
        </ContainerStyledBox>
        <ContainerStyledBox align="center">
          <ContainerStyledCardMedia
            image={selectedImage}
            className="selected-productogratis-image"
            title=""
            component="img"
          />
          <ContainerStyledTypography variant="bodyBaseline" weight="semibold" component="span">
            {t("productDetail.Gift")}
          </ContainerStyledTypography>
        </ContainerStyledBox>
      </ContainerStyledBox>

      <ContainerStyledBox px={2} py={2}>
        {articulos.map((articulo) => {
          let selected = false;

          if (selectedArticulo) {
            if (selectedArticulo.partNumber === articulo.partNumber || articulos.length === 1) {
              selected = true;
            }
          }

          return (
            <ProductoGratisRadio
              articulo={articulo}
              handleSelectedArticulo={handleSelectedArticulo}
              selected={selected}
            />
          );
        })}
      </ContainerStyledBox>

      <ContainerStyledBox mx={2}>
        <AgregarArticulo
          partNumberGift={partNumberGift}
          partNumber={partNumber}
          quantity={baseQty}
          giftQty={giftQty}
          selectedArticulo={selectedArticulo}
          handleCloseProductos={handleCloseProductos}
        />

        <ContainerStyledTypography variant="bodyBaseline" className="productoregalo-text" component="span">
          {t("productDetail.SeePromotionAtCart")}
        </ContainerStyledTypography>
        <Link to="terminos-condiciones" target="_blank">
          <ContainerStyledTypography variant="bodyBaseline" className="terminos-condiciones" component="span">
            &nbsp;{t("productDetail.TermsAndConditions")}
          </ContainerStyledTypography>
        </Link>
      </ContainerStyledBox>
    </>
  );
};

export default ProductoGratisContent;
