import { Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { StyledBox } from "../box";
import { StyledButton } from "../button";
import { StyledTypography } from "../typography";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "styled-components";
import { StyledLinkTextSecondary } from "../text-link";
import { palette } from "../../themes";

const ChevronLinkContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

    &.styled--chevronlink-container {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .styled--chevronicon-hover {
        color: ${palette.gray["500"]};
    }

    .styled--chevronicon-hover:hover {
        color: ${palette.primary.main};
    }

    .styled--chevronlink-icon {
      width: 10px;
      height: 10px;
    }

`}
`;

export const ChevronLink = (props) => {
  const { text, isSticky, handleGo, margin } = props;

  return (
    <ChevronLinkContainer className={`styled--chevronlink-container ${margin ? 'left-margin-4' : ''}`} onClick={handleGo}>
      <StyledLinkTextSecondary
        variant="bodyBaseline"
        className={isSticky ? "color-secondary" : "" + "styled--chevronicon-hover"}>
        {text}
      </StyledLinkTextSecondary>
      <ChevronRightIcon color="primary" className="left-margin-1 styled--chevronlink-icon" />
    </ChevronLinkContainer>
  );
};
