/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, ChangeEvent } from "react";
import * as ROUTES from "../../../constants/routes";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BAD_REQUEST, OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import personService from "../../../_foundation/apis/transaction/person.service";
// Custom libraries
import addressUtil from "../../../../src/utils/addressUtil";
import { EMPTY_STRING } from "../../../constants/common";
import { genericErrorSelector } from "../../../redux/selectors/error";

//UI
import {
  StyledButton,
  StyledTextField,
  StyledGrid,
  StyledTypography,
  StyledLink,
  StyledBox,
} from "../../../hdm/elements";
import { Divider, InputAdornment, useMediaQuery, useTheme } from "@material-ui/core";
//redux
import * as successActions from "../../../redux/actions/success";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { StyledRegistrationContainer } from "../registration/RegistrationStyles";
import { MailSharp } from "@material-ui/icons";

import { ForgotPasswordConfirmation } from "../../pages/forgot-password/ForgotPasswordConfirmation";
import {
  CLOSE_CART_DRAWER_ACTION,
  CLOSE_LOGIN_DRAWER_ACTION,
  CLOSE_SIGN_IN_POPUP_ACTION,
  OPEN_CART_DRAWER_ACTION,
  OPEN_LOGIN_DRAWER_ACTION,
  OPEN_SIGN_IN_POPUP_ACTION,
} from "../../../redux/actions/drawer";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

function ResetPassword({ ...props }) {
  const navigate = useNavigate();
  const widgetName = getDisplayName(ResetPassword);
  const [validationCode, setValidationCode] = React.useState<string>(EMPTY_STRING);
  const [passwordNew, setNewPassword] = React.useState<string>(EMPTY_STRING);
  const [passwordVerify, setVerifyPassword] = React.useState<string>(EMPTY_STRING);
  const [logonInputProps, setLogonInputProps] = useState<any>({});
  const [passwordConfirmation, setPasswordConfirmation] = useState<boolean>(false);
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = React.useState<string>(EMPTY_STRING);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(960));
  const { mySite } = useSite();

  const [showPassword, setShowPassword] = useState({
    showFirstInputPassword: false,
    showVerifyInputPassword: false,
  });

  const [showInputError, setShowInputError] = useState({
    emailError: false,
    emailVerifyError: false,
    isBothEmailsEqual: false,
    emailMsgError: "",
    lastNameError: false,
    passwordError: false,
    passwordVerifyError: false,
  });
  const [passwordValidations, setPasswordValidations] = useState({
    firstRequirement: 0,
    secondRequirement: 0,
    thirdRequirement: 0,
    fourthRequirement: 0,
    fifthRequirement: 0,
  });

  const { mySite: site } = useSite();

  const { t } = useTranslation();
  const validationCodeLabel = t("ResetPassword.ValidationCodeLabel");
  const newPasswordLabel = t("ResetPassword.NewPasswordLabel");
  const verifyPasswordLabel = t("ResetPassword.VerifyPasswordLabel");
  const submitButton = t("ResetPassword.SavePassword");
  const emailLabel = t("ResetPassword.EmailLabel");
  const logonIdLabel = t("ResetPassword.LogonIDLabel");
  const title = t("ResetPassword.Title");
  const subtitle = t("ResetPassword.Subtitle");
  const reminder = t("ResetPassword.Reminder");
  const createNewPassword = t("ResetPassword.CreateNewPassword");
  const signInTitle = t("SignIn.SignInTitle");
  const isB2B = site.isB2B;
  const dispatch = useDispatch();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const storeConfData = useSelector(onlineStoreRdc);
  const genericError: any = useSelector(genericErrorSelector);
  const [errorPasswordUsed, setErrorPasswordUsed] = useState({error: false})

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if(genericError && genericError?.errorCode){
      if(genericError.errorCode === '2260'){
        setErrorPasswordUsed({
            error: true,
        })
      }
    }
  }, [genericError])

  const handleValidationCodeChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setIsInvalidCode(false);
    setValidationCode(val);
  };
  useEffect(() => {
    if (site) {
      if (site.isB2B) {
        setLogonInputProps({
          maxLength: 100,
          type: "text",
        });
      } else {
        setLogonInputProps({
          maxLength: 100,
          type: "email",
          placeholder: "name@domain.com",
        });
      }
    }
  }, [site]);

  const handleEmailLogonIdChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    props.setEmail(val);
  };
  const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setNewPassword(val);
    setErrorPasswordUsed({
      error: passwordNew !== evt.target.value ? false : true,
    })
  };

  const handleVerifyPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setVerifyPassword(val);
  };

  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */
  const canContinue = (): boolean => {
    let flag: boolean = false;
    if (
      validationCode !== EMPTY_STRING &&
      passwordNew !== EMPTY_STRING &&
      passwordVerify !== EMPTY_STRING &&
      passwordVerify === passwordNew &&
      !showInputError.passwordVerifyError &&
      !showInputError.passwordError
    ) {
      flag = isB2B ? true : addressUtil.validateEmail(props.email);
    }
    return flag;
  };

  const canResendVerificationCodeButtonContinue = (): boolean => {
    return props.email ? (isB2B ? true : addressUtil.validateEmail(props.email)) : false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const storeID = site.storeID;
    const parameters: any = {
      responseFormat: "application/json",
      storeId: storeID,
      body: {
        logonId: props.email,
        resetPassword: "true",
        xcred_validationCode: validationCode,
        logonPassword: passwordNew,
        xcred_logonPasswordVerify: passwordVerify,
      },
      ...payloadBase,
    };
    personService
      .updatePerson(parameters)
      .then((res) => {
        if (res.status === OK) {
          setPasswordConfirmation(true);

          //if popUp
          if (props.isPopUp) {
            setTimeout(() => {
              dispatch(CLOSE_SIGN_IN_POPUP_ACTION({}));
              setTimeout(() => {
                props.setTitle && props.setTitle(signInTitle);
                dispatch(OPEN_SIGN_IN_POPUP_ACTION({}));
              }, 500);
            }, 3000);
          } else if (props.isDrawer) {
            setTimeout(() => {
              dispatch(CLOSE_LOGIN_DRAWER_ACTION({}));
              setTimeout(() => {
                props.setTitle && props.setTitle(signInTitle);
                dispatch(OPEN_LOGIN_DRAWER_ACTION({}));
              }, 500);
            }, 3000);
          } else {
            setTimeout(() => {
              navigate(ROUTES.SIGNIN);
            }, 3000);
          }

          const payload = {
            logonId: props.email,
          };

          Axios.post(
            `/wcs/resources/store/${mySite.storeID}/hdm/mailing/sendPasswordSaved?responseFormat=json&langId=-5`,
            payload
          )
            .then((res) => {
              console.log("Update password mail sent");
            })
            .catch((error) => {
              console.log(error);
            });

          const successMessage = {
            key: "success-message.PASSWORD_RESET_SUCCESS",
          };

          //dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        }
      })
      .catch((err) => {
        if (err.response.status === BAD_REQUEST) {
          if (err.response.data.errors[0].errorParameters === "validationCode") {
            setIsInvalidCode(true);
          }
          if (err.response.data.errors[0].errorCode === "2190") {
            setInvalidCode(t("ResetPassword.Msgs.InvalidCode"));
          }
          if (err.response.data.errors[0].errorCode === "2195") {
            setInvalidCode(t("ResetPassword.Msgs.ExpiredCode"));
          }
        }
        return;
      });
  };

  useEffect(() => {
    if (props.setTitle) {
      props.setTitle(newPasswordLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* show or hide input password */

  const handleShowPassword = (inputType: string) => {
    if (inputType === "first-input") {
      setShowPassword((prevState): any => ({
        ...prevState,
        showFirstInputPassword: prevState.showFirstInputPassword ? false : true,
      }));
    }
    if (inputType === "second-input") {
      setShowPassword((prevState): any => ({
        ...prevState,
        showVerifyInputPassword: prevState.showVerifyInputPassword ? false : true,
      }));
    }
  };

  /* show or hide text helper errors */
  const { emailError, emailVerifyError, isBothEmailsEqual, emailMsgError, passwordError, passwordVerifyError } =
    showInputError;

  /* Visual handler for password requirements */
  const { firstRequirement, secondRequirement, thirdRequirement, fourthRequirement, fifthRequirement } =
    passwordValidations;
  const specialCharacters = /[-=_*?!@#$/(){}.,;:]/;
  const upperCaseAlphabetLetters = /[A-ZÑ]/;
  const lowerCaseAlphabetLetters = /[a-zñ]/;
  const anyNumber = /\d/;

  useEffect(() => {
    setPasswordValidations((prevState): any => ({
      ...prevState,
      firstRequirement: passwordNew.length > 7 ? 2 : 0,
      secondRequirement: lowerCaseAlphabetLetters.test(passwordNew) ? 2 : 0,
      thirdRequirement: upperCaseAlphabetLetters.test(passwordNew) ? 2 : 0,
      fourthRequirement: anyNumber.test(passwordNew) ? 2 : 0,
      fifthRequirement: specialCharacters.test(passwordNew) ? 2 : 0,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordNew]);

  const handleOnBlurPassword = () => {
    setPasswordValidations((prevState): any => ({
      ...prevState,
      firstRequirement: prevState.firstRequirement === 0 ? 1 : prevState.firstRequirement,
      secondRequirement: prevState.secondRequirement === 0 ? 1 : prevState.secondRequirement,
      thirdRequirement: prevState.thirdRequirement === 0 ? 1 : prevState.thirdRequirement,
      fourthRequirement: prevState.fourthRequirement === 0 ? 1 : prevState.fourthRequirement,
      fifthRequirement: prevState.fifthRequirement === 0 ? 1 : prevState.fifthRequirement,
    }));
    setShowInputError((prevState): any => ({
      ...prevState,
      passwordError:
        firstRequirement !== 2 ||
        secondRequirement !== 2 ||
        thirdRequirement !== 2 ||
        fourthRequirement !== 2 ||
        fifthRequirement !== 2
          ? true
          : false,
    }));
  };

  const handleInputPasswordError = () => {
    let error = "";

    if (errorPasswordUsed.error) {
      error = errorPasswordUsed.error
        ? t("ResetPassword.Msgs.OldPasswordReuse")
        : props.isDrawer || isMobile
        ? ""
        : " ";
    }
    if (passwordError) {
      error = passwordError
        ? t("RegistrationLayout.Msgs.NoFullRequirementsPassword")
        : props.isDrawer || isMobile
        ? ""
        : " ";
    }
    if (showInputError.passwordVerifyError) {
      error = showInputError.passwordVerifyError
        ? t("RegistrationLayout.Msgs.NoMatchPasswords")
        : props.isDrawer || isMobile
        ? ""
        : " ";
    }

    if (error === "") {
      error = props.isDrawer || isMobile ? "" : " ";
    }

    return error;
  };

  return passwordConfirmation ? (
    <ForgotPasswordConfirmation setTitle={props.setTitle} />
  ) : (
    <>
      <StyledGrid container justifyContent="center" alignItems="center" spacing={1}>
        <StyledGrid item xs={12}>
          <StyledTypography variant="bodyBaseline" weight="regular" className={"bottom-margin-1"}>
            {createNewPassword}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item className={"vertical-margin-1"}>
          <img
            src={storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/reset-password/send-mail.svg"}
            alt={storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/reset-password/send-mail.svg"}
            width={props.isDrawer || isMobile ? "100" : "150"}
            className="lazyload"></img>
        </StyledGrid>
      </StyledGrid>

      <form noValidate>
        <StyledGrid container justifyContent="center" alignItems="center" spacing={1}>
          <StyledGrid item xs={12}>
            <StyledTypography variant="bodyBaseline" weight="regular" className={"bottom-margin-1"}>
              {subtitle}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledTypography variant="bodyBaseline" weight="regular" className="color-gray200 bottom-margin-1 ">
              {reminder}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledTextField
              className={"TextField-disabled"}
              required
              fullWidth
              inputProps={logonInputProps}
              value={props.email}
              placeholder={EMPTY_STRING}
              name="email"
              InputProps={{
                startAdornment: <InputAdornment position="start">{<MailSharp fontSize="small" />}</InputAdornment>,
              }}
              error={isB2B ? false : !addressUtil.validateEmail(props.email)}
              disabled
              variant="filled"
              helperText={
                !addressUtil.validateEmail(props.email) && !isB2B ? t("SignIn.Msgs.InvalidFormat") : EMPTY_STRING
              }
              onChange={(e) => handleEmailLogonIdChange(e, "email")}
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label={<StyledTypography variant="body1">{validationCodeLabel}</StyledTypography>}
              autoFocus
              placeholder={EMPTY_STRING}
              name="validationCode"
              error={isInvalidCode}
              helperText={isInvalidCode ? invalidCode : ""}
              onChange={(e) => handleValidationCodeChange(e, "validationCode")}
            />
          </StyledGrid>
          {/* Password validations */}
          <StyledRegistrationContainer
            className="top-margin-2 vertical-padding-3 item-padding no-shadow"
            isMobile={isMobile}
            passwordValidations={passwordValidations}
            isPopUp={true}>
            <StyledGrid container className={"bottom-margin-2"}>
              <StyledGrid item xs={12} sm={12} md={12} lg={12}>
                <StyledTypography component="h1" variant="bodyBaseline" className="vertical-margin-2 validations-title">
                  {t("RegistrationLayout.RegistrationPasswordValidation")}
                </StyledTypography>
              </StyledGrid>

              <StyledGrid item lg={props.isDrawer ? 12 : 5} className="password-criterias-list">
                <StyledTypography component="ul">
                  <StyledTypography component="li" variant="bodySubText" id="first-requirement">
                    {t("ResetPassword.PasswordCriteria.Criteria1")}
                  </StyledTypography>
                  <StyledTypography component="li" variant="bodySubText" id="second-requirement">
                    {t("ResetPassword.PasswordCriteria.Criteria2")}
                  </StyledTypography>
                  <StyledTypography component="li" variant="bodySubText" id="third-requirement">
                    {t("ResetPassword.PasswordCriteria.Criteria3")}
                  </StyledTypography>
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item lg={props.isDrawer ? 12 : 7} className="password-criterias-list">
                <StyledTypography component="ul">
                  <StyledTypography component="li" variant="bodySubText" id="fourth-requirement">
                    {t("ResetPassword.PasswordCriteria.Criteria4")}
                  </StyledTypography>
                  <StyledTypography component="li" variant="bodySubText" id="fifth-requirement">
                    {t("ResetPassword.PasswordCriteria.Criteria5")}
                  </StyledTypography>
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledRegistrationContainer>
          <StyledGrid item container className={"bottom-margin-2"} spacing={1}>
            <StyledGrid item xs={props.isDrawer || isMobile ? 12 : 6}>
              <StyledTextField
                margin="normal"
                required
                fullWidth
                label={<StyledTypography variant="body1">{newPasswordLabel}</StyledTypography>}
                type="password"
                placeholder={EMPTY_STRING}
                name="logonPassword"
                inputProps={{
                  maxLength: 50,
                  type: showPassword.showFirstInputPassword ? "" : "password",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword.showFirstInputPassword ? (
                        <VisibilityOffOutlinedIcon
                          onClick={() => handleShowPassword("first-input")}
                          className="show-password-icon"
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          onClick={() => handleShowPassword("first-input")}
                          className="show-password-icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleNewPasswordChange(e, "logonPassword")}
                onBlur={handleOnBlurPassword}
                error={passwordError || errorPasswordUsed.error}
                helperText={handleInputPasswordError()}
              />
            </StyledGrid>
            <StyledGrid item xs={props.isDrawer || isMobile ? 12 : 6}>
              <StyledTextField
                margin="normal"
                required
                fullWidth
                label={<StyledTypography variant="body1">{verifyPasswordLabel}</StyledTypography>}
                type="password"
                placeholder={EMPTY_STRING}
                name="logonPasswordVerify"
                InputLabelProps={{ style: { fontSize: 80 } }}
                inputProps={{
                  maxLength: 50,
                  type: showPassword.showVerifyInputPassword ? "" : "password",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword.showVerifyInputPassword ? (
                        <VisibilityOffOutlinedIcon
                          onClick={() => handleShowPassword("second-input")}
                          className="show-password-icon"
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          onClick={() => handleShowPassword("second-input")}
                          className="show-password-icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                onBlur={() =>
                  setShowInputError((prevState): any => ({
                    ...prevState,
                    passwordVerifyError: passwordNew !== passwordVerify ? true : false,
                  }))
                }
                error={showInputError.passwordVerifyError || errorPasswordUsed.error}
                helperText={
                  showInputError.passwordVerifyError
                    ? t("RegistrationLayout.Msgs.NoMatchPasswords")
                    : props.isDrawer || isMobile
                    ? ""
                    : " "
                }
                onChange={(e) => {
                  handleVerifyPasswordChange(e, "logonPasswordVerify");

                  if (e.target.value.length >= passwordNew.length) {
                    setShowInputError((prevState): any => ({
                      ...prevState,
                      passwordVerifyError: passwordNew !== e.target.value ? true : false,
                    }));
                  } else {
                    setShowInputError((prevState): any => ({ ...prevState, passwordVerifyError: false }));
                  }
                }}
              />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledButton
              testId="reset-password-submit"
              color="primary"
              type="button"
              className="login-process-button bottom-margin-2"
              disabled={!canContinue()}
              onClick={handleSubmit}>
              {submitButton}
            </StyledButton>
          </StyledGrid>

          <StyledGrid item>
            <StyledGrid container spacing={1} justifyContent="center" alignItems="center" direction="row">
              <StyledGrid item xs={12}>
                <StyledTypography variant="body1" align="center" className="bottom-margin-2">
                  {t("ResetPassword.PasswordNotRecieved")}
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledBox align="center">
                  <StyledButton
                    testId="reset-password-resend-verification-code"
                    variant="outlined"
                    type="button"
                    className="login-process-button"
                    disabled={!canResendVerificationCodeButtonContinue()}
                    onClick={props.resendCode}>
                    {t("ResetPassword.ResendVerificationCode")}
                  </StyledButton>
                </StyledBox>
              </StyledGrid>
              <StyledGrid item xs={12}>
                <Divider className="top-margin-2" />
              </StyledGrid>
              <StyledGrid item>
                <StyledTypography variant="body1" component="span">
                  {t("ResetPassword.AccountInfoRemember")}
                {props.isPopUp ? ( 
                  <StyledTypography
                      variant="body1"
                      component="span"
                      className={"link"}
                      onClick={props.handleRememberPassword}>
                      {t("ResetPassword.SignIn")}
                    </StyledTypography>
                  ) : (
                    <StyledLink variant="body1" to={ROUTES.SIGNIN}>{t("ResetPassword.SignIn")}</StyledLink>
                  )}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </form>
    </>
  );
}

export { ResetPassword };
