//Standard libraries
import { createAction } from "@reduxjs/toolkit";

import { OPEN_CART_DRAWER, CLOSE_CART_DRAWER, 
    OPEN_SIGN_IN_POPUP, CLOSE_SIGN_IN_POPUP, 
    OPEN_REGISTER_POPUP, CLOSE_REGISTER_POPUP,
    OPEN_LOGIN_DRAWER,CLOSE_LOGIN_DRAWER } from "../action-types/drawer";

const OPEN_CART_DRAWER_ACTION = createAction<any>(OPEN_CART_DRAWER);
const CLOSE_CART_DRAWER_ACTION = createAction<any>(CLOSE_CART_DRAWER);
const OPEN_SIGN_IN_POPUP_ACTION = createAction<any>(OPEN_SIGN_IN_POPUP);
const CLOSE_SIGN_IN_POPUP_ACTION = createAction<any>(CLOSE_SIGN_IN_POPUP);
const OPEN_REGISTER_POPUP_ACTION = createAction<any>(OPEN_REGISTER_POPUP);
const CLOSE_REGISTER_POPUP_ACTION = createAction<any>(CLOSE_REGISTER_POPUP);

const OPEN_LOGIN_DRAWER_ACTION = createAction<any>(OPEN_LOGIN_DRAWER);
const CLOSE_LOGIN_DRAWER_ACTION = createAction<any>(CLOSE_LOGIN_DRAWER);

export { 
    OPEN_CART_DRAWER_ACTION, 
    CLOSE_CART_DRAWER_ACTION, 
    OPEN_SIGN_IN_POPUP_ACTION, 
    CLOSE_SIGN_IN_POPUP_ACTION,
    OPEN_REGISTER_POPUP_ACTION,
    CLOSE_REGISTER_POPUP_ACTION,
    OPEN_LOGIN_DRAWER_ACTION,
    CLOSE_LOGIN_DRAWER_ACTION 
};
