/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Foundation libraries
// import { localStorage, sessionStorageUtil } from "../../_foundation/utils/storageUtil";
//Redux
import { PERMANENT_STORE_DAYS } from "../../configs/common";
import initStates from "./initStates";
import { FyFLoginReducerState } from "./reducerStateInterface";
import { FYF_LOGIN_SUCCESS_ACTION, fyfloginErrorAction } from "../actions/fyflogin";
import { EMAIL, FYF, STATUS } from "../../_foundation/constants/common";
import { TableActions } from "@hcl-commerce-store-sdk/react-component";

/**
 * FyFLogin reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const userReducer = createReducer(initStates.fyflogin, (builder) => {
  builder.addCase(FYF_LOGIN_SUCCESS_ACTION, (state: FyFLoginReducerState | any, action: AnyAction) => {
    const data = action.payload;
    const page = data?.page || "";
    if (page === FYF) {
      localStorage.removeItem(EMAIL);
      localStorage.removeItem(FYF);
      localStorage.removeItem(STATUS);
      state.status = data.status;
      state.temporalToken = data.temporalToken;
      state.loginError = false;
      localStorage.setItem(STATUS, data.status);
      localStorage.setItem(EMAIL, data.email);
      localStorage.setItem(FYF, data.temporalToken);
      // sessionStorageUtil.setItem(STATUS, data.status);
    }
    return state;

    //else is init from storage.
  });

  builder.addCase(fyfloginErrorAction, (state: FyFLoginReducerState | any, action: AnyAction) => {
    const { status } = action?.error?.response?.data || {};

    localStorage.removeItem(EMAIL);
    localStorage.removeItem(FYF);
    localStorage.removeItem(STATUS);
    Object.assign(state, { loginError: true, status: status, temporalToken: "" });

    return state;
  });
});

export default userReducer;
