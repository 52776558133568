/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const HOME = "/";

//Accountgit sta
export const SIGNIN = "/login";
export const SIGNIN_PRO = "/login";
export const SIGNUP = "/signup";
export const BUYER_REGISTRATION = "/buyer-registration";
export const ORG_REGISTRATION = "/organization-registration";
export const ACCOUNT = "/account";
export const DASHBOARD = "/dashboard";
export const FORGOT_PASSWORD = "/forgot-password";

//Order
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const CHECKOUT_SHIPPING = `${CHECKOUT}/shipping`;
export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`;
export const CHECKOUT_REVIEW = `${CHECKOUT}/review`;
export const ORDER_DECLINED = "/orden-declinada";
export const ORDER_CONFIRMATION = "/thank-you";
export const ORDER_HISTORY = `${ACCOUNT}/orders`;
export const WISH_LIST = `${ACCOUNT}/wishlist`;
// Debe ser una url que no este configurada
export const WISH_LIST_DISPLAY_VIEW = `${ACCOUNT}/WishListDisplayView`;
export const WISH_LIST_ID = "/:wishListId";
export const VIEW_WISH_LIST_ROUTE = `${WISH_LIST_DISPLAY_VIEW}${WISH_LIST_ID}`;
export const RECURRING_ORDERS = "/recurring-orders";
export const INPROGRESS_ORDERS = "/inprogress-orders";
export const REQUISITION_LISTS = "/requisition-lists";
export const VIEW_UPLOAD_LOGS = "/view-upload-logs";
export const ORDER_DETAILS = ORDER_HISTORY;
export const ORDER_ID_PARAM = "/:orderId";
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`;
export const LIST_ID_PARAM = "/:orderId";
export const PRINT_ORDER = `${CHECKOUT}/print-order`;
export const MSI_ONLINE = "/meses-sin-intereses-en-linea";

//User
export const ADDRESS_BOOK = `${ACCOUNT}/addressbook`;
export const ADD_ADDRESS = `${ACCOUNT}/add-address`;
export const EDIT_ADDRESS = `${ACCOUNT}/edit-address`;
export const ADDRESS_ID_PARAM = "/:addressId";
export const EDIT_ADDRESS_ROUTE = `${EDIT_ADDRESS}${ADDRESS_ID_PARAM}`;
export const NEWSLETTER_CONFIRM = "/HDMGuestusersOptInCmd";
export const SEARCH_STORES = "/buscador-tiendas";
export const MAP_SITE = "/mapa-sitio";

export const PAYMENTMETHOD = `${ACCOUNT}/wallet`;
export const ADD_PAYMENTMETHOD = `${ACCOUNT}/add-card`;
export const EDIT_PAYMENTMETHOD = `${ACCOUNT}/edit-card`;

//Search
export const SEARCH = "/s";

//Product
export const PRODUCT_PAGE_EMARSYS = "/p/";
export const PRODUCT_PAGE = "/p/";
export const PRODUCT_PDP_PREFIX = "/p";
export const PRODUCT_PDP_PARAM = "/:productSEO";
export const PRODUCT_PDP_ROUTE = `${PRODUCT_PDP_PREFIX}${PRODUCT_PDP_PARAM}`;

export const CATALOG_PAGE_EMARSYS = "/b/";
export const CATALOG_PREFIX = "/b";
export const CATALOG_PARAM = "/:catalogName";
export const SUBCATALOG_PARAM = "/:subcatalogName";
export const SUBSUBCATALOG_PARAM = "/:subsubcatalogName";
export const CATALOG_SEARCH_ROUTE = `${CATALOG_PREFIX}${CATALOG_PARAM}${SUBCATALOG_PARAM}${SUBSUBCATALOG_PARAM}`;
export const SUBCATALOG_SEARCH_ROUTE = `${CATALOG_PREFIX}${CATALOG_PARAM}${SUBCATALOG_PARAM}`;
export const SUBSUBCATALOG_SEARCH_ROUTE = `${CATALOG_PREFIX}${CATALOG_PARAM}${SUBCATALOG_PARAM}${SUBSUBCATALOG_PARAM}`;

//register user access only
export const REGISTER_PROTECTED = "register";
//only guest and generic user access
export const GENERIC_PROTECTED = "generic";

export const BUYER_MANAGEMENT = "/buyer-management";
export const APPROVALS_MANAGEMENT = "/approvals-management";
export const ORGANIZATION_MANAGEMENT = "/organization-management";
export const ORDER_APPROVAL = "/order-approval";
export const TERMS_CONDITIONS = "/terminos-condiciones-servicios-instalacion";

//Checkout Profile
export const CHECKOUT_PROFILES = `${ACCOUNT}/checkout-profiles`;
export const CHECKOUT_PROFILE_CREATE = `${ACCOUNT}/create-checkout-profile`;
export const CHECKOUT_PROFILE_EDIT = `${ACCOUNT}/edit-checkout-profile`;
export const CHECKOUT_PROFILE_PARAM = "/:profileId";
export const CHECKOUT_PROFILE_EDIT_ROUTE = `${CHECKOUT_PROFILE_EDIT}${CHECKOUT_PROFILE_PARAM}`;

// in-progress orders + items
export const IP_ORDER_DETAILS = "/inprogress-order-details";
export const IP_ORDER_DETAILS_ROUTE = `${IP_ORDER_DETAILS}${ORDER_ID_PARAM}`;

// Reqisition List + items
export const REQUISITION_LIST_DETAILS = "/requisition-list-details";
export const REQUISITION_LIST_DETAILS_ROUTE = `${REQUISITION_LIST_DETAILS}${LIST_ID_PARAM}`;

export const COMPARE_ROUTE = "/compare-products";
export const SHOP_THE_LOOK = "/shop-the-look";

//Errors client side
export const ERROR_PAGE = "/error-page";
export const ERROR_PAGE_500 = "/error-page-500";

//Not Available
export const PORDUCT_NOT_AVAILABLE = "/not-available";

//PRO
//Lead
export const PRO_LEAD_FORM = "/pro-lead";
export const PRO_LEAD_PARAMS = "/:storeId/:token/:store";
// export const PRO_LEAD_PARAMS = "/:params";
export const PRO_LEAD_FORM_PARAMS = `${PRO_LEAD_FORM}${PRO_LEAD_PARAMS}`;

export const PRO_EXPIRED_LINK = "/expired-link";

export const PRO_AR_FEEDBACK = "/CreditAnalystThankYouPage";
