import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StyledBox, StyledGrid, StyledLinkTextSecondary, StyledTypography } from "../../../hdm/elements";
import AddIcon from "@material-ui/icons/Add";

import { getWishListSelector, wishListItemMoveSelector } from "../../../redux/selectors/wish-list";
import { StyledLinkButton } from "../../custom-components/styled-store-selector/styled-link-button";
import { StyledPopperMenu } from "../../custom-components/styled-popper-menu";
import { useWishList } from "../../../_foundation/hooks/use-wishlist";
import StyledSwipeableDrawerHDM from "../../custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { StyledWrapper } from "../../StyledUI";
import CreateWishList from "./create-wish-list";

export const WishListMenuItems = (props) => {
  const { wishListData, text, placement } = props;
  const { t } = useTranslation();
  const wishListDrawerTitle = t("WishList.wishListDrawerTitle");
  const { addToWishList, deleteWishListItem } = useWishList();
  const [openDialogCreateWishList, setOpenDialogCreateWishList] = useState(false);

  const userWishList = useSelector(getWishListSelector);
  // const { text, placement, dataItems, wishListId } = props
  const [menuListOpen, setMenuListOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(menuListOpen);

  const handleCancelarLista = () => {
    setOpenDialogCreateWishList(false);
  };

  const handleToggle = () => {
    if (!menuListOpen) {
      setMenuListOpen((prevOpen) => !prevOpen);
    }
  };

  const handleMoveItemToWishList = async (dataWishList) => {
    addToWishList({
      partNumber: wishListData.partNumber,
      externalId: dataWishList.uniqueID,
      isMoveToList: {
        externalId: wishListData.wishListId,
        giftListItemID: wishListData.giftListItemID,
        productName: wishListData.productName,
      },
    });
  };

  const handleAddList = () => {
    setOpenDialogCreateWishList(true);
  };

  const MenuItems = () => {
    return (
      <>
        {userWishList &&
          userWishList.length > 0 &&
          userWishList
            .filter((wish) => wish.uniqueID !== wishListData.wishListId)
            .map((list, index) => (
              <MenuItem key={"menuItem" + index} onClick={() => handleMoveItemToWishList(list)}>
                <StyledTypography key={"styledTypography" + index} variant="bodyBaseline">
                  {list.description}
                </StyledTypography>
              </MenuItem>
            ))}
        <MenuItem>
          <StyledGrid className={"wishList--styledGrid-addCardTitleEdit"}>
            <AddIcon color={"primary"} className={"wishList--styledGrid-addDirectionIcon"} />
            <StyledLinkButton variant="bodyBaseline" onClick={() => handleAddList()}>
              {t("WishList.button.createNewList")}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
      </>
    );
  };

  return (
    <>
      <StyledBox ref={anchorRef} onClick={handleToggle}>
        <StyledLinkTextSecondary className="styled--link-function">{text}</StyledLinkTextSecondary>
      </StyledBox>

      <StyledSwipeableDrawerHDM
        isOpen={openDialogCreateWishList}
        setOpenDrawer={setOpenDialogCreateWishList}
        variant={"wishList-drawer"}
        title={wishListDrawerTitle}>
        <StyledWrapper>
          <StyledGrid className={"horizontal-padding-2"}>
            <CreateWishList
              handleCancelarLista={handleCancelarLista}
              userWishList={userWishList}
              variant={"drawer"}
              partNumber={wishListData.partNumber}
              isMoveToList={{
                externalId: wishListData.wishListId,
                giftListItemID: wishListData.giftListItemID,
                productName: wishListData.productName,
              }}
            />
          </StyledGrid>
        </StyledWrapper>
      </StyledSwipeableDrawerHDM>

      <StyledPopperMenu
        MenuItems={MenuItems}
        anchorRef={anchorRef}
        menuListOpen={menuListOpen}
        setMenuListOpen={setMenuListOpen}
        prevOpen={prevOpen}
        placement={placement}
      />
    </>
  );
};
