import styled from "styled-components";
import { StyledGrid } from "../../../hdm/elements";
const StyledShareButtonContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .share--styledGrid-addDirectionIcon{
    width: 20px;
    height: 20px;
    margin-left: -${theme.spacing(1)}px;
  }

  .share--styledGrid-addCardTitleEdit{
    display: flex;
  }

  .styledshare--svg-container{
    width: fit-content;
    padding: ${theme.spacing(1)}px;
    box-shadow: 0 3px 6px ${theme.palette.gray["50"]};
    border-radius: 50%;
    color: ${theme.palette.primary.main};
    display: flex;
    margin-right: ${theme.spacing(2)}px;
    background-color: white;
    cursor: pointer;
    & svg{
        font-size: 20px;
    }
    :hover{
      position: relative;

      .styledshare--svg-line {
        display: block;
      }
    }

    & .styledshare--svg-line {
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      height: 3px;
      background: ${theme.palette.primary.main};
      display: none;
    }
  }
  

  .styledshare--svg-wishProduct{
    cursor: default;
  }
`}
`;
export { StyledShareButtonContainer };
