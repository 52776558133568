import { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import personService from "../../../_foundation/apis/transaction/person.service";
import { useTranslation } from "react-i18next";
import { OK } from "http-status-codes";
import { EMPTY_STRING } from "../../../constants/common";

import { genericErrorSelector, sessionErrorSelector } from "../../../redux/selectors/error";

import {
  StyledBox,
  StyledButton,
  StyledLink,
  StyledTextField,
  StyledTypography,
  StyledListItem,
} from "../../../hdm/elements";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { palette } from "../../../hdm/themes";
import { PasswordRequirements } from "./PasswordRequirements";
import { PasswordNewPasswords } from "./PasswordNewPasswords";
interface Props {
  editPassword: boolean;
  handleEditPassword(action: boolean): void;
  isProForgotPasswprd?: boolean;
}

const ChangePassword = (props: Props) => {
  const { editPassword, handleEditPassword, isProForgotPasswprd } = props;
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [canContinueNewPassword, setCanContinueNewPassword] = useState<boolean>(false);
  const [valReq, setValReq] = useState<boolean>(false);
  const [passwordOld, setOldPassword] = useState<string>("");
  const [passwordTemp, setTempPassword] = useState<string>("");
  const [passwordNew, setNewPassword] = useState<string>("");
  const [passwordVerify, setVerifyPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);
  const [passwordValidations, setPasswordValidations] = useState({
    caracteresMin8: false,
    lowerCaseLetter: false,
    upperCaseLetter: false,
    anyNumber: false,
    specialChar: false,
  });
  const genericError: any = useSelector(genericErrorSelector);
  const sessionError = useSelector(sessionErrorSelector);
  const [errorPasswordOld, setErrorPasswordOld] = useState({ error: false, errorMsg: "" });
  const [errorPassword, setErrorPassword] = useState({ error: false, errorMsg: "" });
  const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(false);
  const [errorPasswordUsed, setErrorPasswordUsed] = useState({ error: false, errorMsg: "" });

  const { mySite: site } = useSite();
  const widgetName = getDisplayName(ChangePassword);
  const { mySite } = useSite();
  const isB2B: boolean = mySite?.isB2B


  const { t } = useTranslation();
  const title = t("ChangePassword.Title");
  const currentPasswordLabel = t("ChangePassword.CurrentPasswordLabel");
  const newPasswordLabel = t("ChangePassword.NewPasswordLabel");
  const verifyPasswordLabel = t("ChangePassword.VerifyPasswordLabel");
  const saveLabel = t("ChangePassword.SaveLabel");
  const cancelLabel = t("ChangePassword.CancelLabel");

  const specialCharacters = /[-=_*?!@#S/(){}.,;:]/;
  const upperCaseAlphabetLetters = /[A-ZÑ]/;
  const lowerCaseAlphabetLetters = /[a-zñ]/;
  const anyNumber = /\d/;

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    console.log("genericError", genericError);

    if (genericError && genericError?.errorCode) {
      if (genericError.errorCode === "2050") {
        setErrorPasswordOld({
          error: true,
          errorMsg: "La contraseña es incorrecta.",
        });
      }
      if (genericError.errorCode === "2260") {
        disabledConfirmButton = true;
        setTempPassword(passwordNew);

        setErrorPasswordUsed({
          error: true,
          errorMsg: `"Esta contraseña ya fue usada anteriormente"`,
        });

        /*setErrorPassword({
                    error: true,
                    errorMsg: `"Esta contraseña ya fue usada anteriormente"`
                })*/
      }
    }
  }, [genericError]);

  const handleOldPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setOldPassword(val);
    setErrorPasswordOld({
      error: false,
      errorMsg: "",
    });
  };

  const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setNewPassword(val);
    setPasswordValidations({
      caracteresMin8: val.length >= 8 ? true : false,
      lowerCaseLetter: lowerCaseAlphabetLetters.test(val) ? true : false,
      upperCaseLetter: upperCaseAlphabetLetters.test(val) ? true : false,
      anyNumber: anyNumber.test(val) ? true : false,
      specialChar: specialCharacters.test(val) ? true : false,
    });
    setErrorPassword({
      error: false,
      errorMsg: "",
    });
  };

  const handleClose = () => {
    setOldPassword("");
    setVerifyPassword("");
    setNewPassword("");
    handleEditPassword(false);
  };

  const handleSuccess = () => {
    handleClose();
    setOpenSuccess(true);
  };

  const handleSubmit = (props: any) => {
    props.preventDefault();
    const storeID = site.storeID;
    console.log("pas", passwordNew);
    const parameters: any = {
      responseFormat: "application/json",
      storeId: storeID,
      body: {
        resetPassword: "true",
        xcred_logonPasswordOld: passwordOld,
        logonPassword: passwordNew,
        xcred_logonPasswordVerify: passwordVerify,
      },
      ...payloadBase,
    };
    personService
      .updatePerson(parameters)
      .then((res) => {
        console.log("res", res);
        if (res.status === OK) {
          handleSuccess();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const canContinue = () => {
    let isOk = false;
    if (
      passwordOld.trim() !== EMPTY_STRING &&
      passwordNew.trim() !== EMPTY_STRING &&
      passwordVerify.trim() !== EMPTY_STRING &&
      passwordTemp.trim() !== passwordNew.trim()
    ) {
      //
      isOk = true;
    }
    return isOk;
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => (prev ? false : true));
  };

  const handleShowVerifyPassword = () => {
    setShowVerifyPassword((prev) => (prev ? false : true));
  };

  const handlePasswordValidations = (data) => {
    setPasswordValidations(data);
  };

  const handleValReq = (data) => {
    setValReq(data);
  };

  const handleCanContinueNewPassword = (data) => {
    setNewPassword(data.data.passwordNew);
    setVerifyPassword(data.data.passwordVerify);
    setCanContinueNewPassword(data);
    handleEditPassword(data)
  };

  /*    const handleErrorVerifyPassword = (data) => {

    }*/

  let disabledConfirmButton = true;
  if (canContinueNewPassword && canContinue()) {
    disabledConfirmButton = false;
  }

  // Confirm button validations
  useEffect(() => {
    if (
      passwordValidations.anyNumber &&
      passwordValidations.caracteresMin8 &&
      passwordValidations.lowerCaseLetter &&
      passwordValidations.specialChar &&
      passwordValidations.upperCaseLetter &&
      passwordNew.trim() === passwordVerify.trim() &&
      passwordOld?.length > 0
    ) {
      setDisableConfirmButton(true);
    } else {
      setDisableConfirmButton(false);
    }
  }, [passwordValidations, passwordOld, passwordNew, passwordVerify]);

  // console.table({
  //     passwordNew: passwordNew,
  //     passwordVerify: passwordVerify,
  //     passwordOld: passwordOld
  // })

  if(isB2B && isProForgotPasswprd){

    return (
      <>
       <StyledBox>
        <StyledTypography variant="bodyBaseline" className="gray500 bottom-margin-1">
          {t("ResetPassword.ProPasswordRequirements")}
        </StyledTypography>
      </StyledBox>
      <StyledBox mt={2}>
        <PasswordRequirements passwordValidations={passwordValidations} valReq={valReq} />
      </StyledBox>
      <form name="signInForm" noValidate onSubmit={handleSubmit}>
        <PasswordNewPasswords
          handlePasswordValidations={handlePasswordValidations}
          handleValReq={handleValReq}
          passwordValidations={passwordValidations}
          handleCanContinueNewPassword={handleCanContinueNewPassword}
          errorPasswordUsed={errorPasswordUsed}
          setErrorPasswordUsed={setErrorPasswordUsed}
          isProForgotPasswprd={isProForgotPasswprd}
        />
        {/**/}
      </form>
      </>
    )
  }


  return (
    <>
      <StyledBox>
        <StyledTypography variant="bodyBaseline" className="gray500">
          Ingresa una nueva contraseña. Tu contraseña debe incluir al menos:
        </StyledTypography>
      </StyledBox>
      <StyledBox mt={2}>
        <PasswordRequirements passwordValidations={passwordValidations} valReq={valReq} />
        {/* <StyledListItem className={passwordValidations.caracteresMin8 ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"} variant="bodySubText">8 caracteres</StyledListItem>
            <StyledListItem className={passwordValidations.lowerCaseLetter ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"} variant="bodySubText">Una letra minúscula (a - z)</StyledListItem>
            <StyledListItem className={passwordValidations.upperCaseLetter ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"} variant="bodySubText">Una letra mayúscula (A - Z)</StyledListItem>
            <StyledListItem className={passwordValidations.anyNumber ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"} variant="bodySubText">Un número (0 - 9)</StyledListItem>
            <StyledListItem className={passwordValidations.specialChar ? "requerimiento-ok" : valReq ? "requerimiento-error" : "requerimiento"} variant="bodySubText">Un caracter especial (- _ * ? ! @ # $ / = . , ; :)</StyledListItem> */}
      </StyledBox>
      <form name="signInForm" noValidate onSubmit={handleSubmit}>
        <PasswordNewPasswords
          handlePasswordValidations={handlePasswordValidations}
          handleValReq={handleValReq}
          passwordValidations={passwordValidations}
          handleCanContinueNewPassword={handleCanContinueNewPassword}
          errorPasswordUsed={errorPasswordUsed}
          setErrorPasswordUsed={setErrorPasswordUsed}
        />
        {/**/}
        <StyledTextField
          margin="normal"
          fullWidth
          label="Contraseña actual"
          type={showPassword ? "text" : "password"}
          placeholder=""
          name="logonPasswordOld"
          onChange={(e) => handleOldPasswordChange(e, "logonPasswordOld")}
          error={errorPasswordOld.error}
          helperText={errorPasswordOld.errorMsg}
          className="styled-currentPassword-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <VisibilityOffOutlinedIcon
                    onClick={handleShowPassword}
                    style={{ color: palette.gray["300"], cursor: "pointer", width: "20px", height: "11px" }}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    onClick={handleShowPassword}
                    style={{ color: palette.gray["300"], cursor: "pointer" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {/* <StyledDialogActions> */}
        <StyledBox mt={2}>
          <StyledButton
            variant="outlined"
            color="primary"
            type="submit"
            // disabled={disabledConfirmButton && !disableConfirmButton}
            disabled={!disableConfirmButton}>
            Guardar y confirmar
          </StyledButton>
        </StyledBox>
        {/* <StyledBox>
              <StyledButton
                color="secondary"
                onClick={handleClose}
                className="">
                {cancelLabel}
              </StyledButton>
            </StyledBox> */}

        <StyledBox align="center" pt={2} pb={2}>
          <StyledLink className="none-underline" onClick={handleClose}>
            Cancelar
          </StyledLink>
        </StyledBox>
        {/* </StyledDialogActions> */}
      </form>
    </>
  );
};

export default ChangePassword;
