import {
  SLAccountProfilesWithFilter,
  SLAddressCards,
  SLCardProductsOrder,
  SLMainAddressCard,
  SLOrderDetails,
  SLPaymentCards,
  SLPaymentContainer,
  SLPaypalPayment,
  SLPersonalInfoComponent,
  SLSimpleRecentOrderCard,
  SLRecentOrdersComponent,
  SLRecentOrdersComponentWithFilter,
} from "./elements/Account";
import { SLCredictAR } from "./elements/CredictAR";
import { SLFindStoreComponent } from "./elements/FindStore";
import { SLFooterNavigationCards, SLFooterNewsLetter } from "./elements/Footer";
import { SLHeaderEspotMenu } from "./elements/Header";
import { SLPDPAboutThisProduct, SLPDPAddToCart, SLPDPAvailability, SLPDPProductImageCard, SLPDPProductPriceCard, SLPDPProductSummaryCard, SLPDPBreadCrumb, SLPDPEspotUXComponentCard, SLPDPEspotCrossSellComponentCard} from "./elements/PDP";
import { SLPLPFacets, SLPLPLayoutSlotTwo, SLPLPProductCards } from "./elements/PLP";
import { SLProductCartAsRow, SLCartShippingMethod, SLCartDeliveryZone, SLMiniCartItemCard, SLTVComponent } from "./elements/ShoppingCart";
import {
  SLWishListCardComponent,
  SLWishListCardComponentCards,
  SLWishListCardComponentWithoutTitle,
  SLWishListLayout,
  SLWishListListCard,
} from "./elements/WishListLayot";
import { StyledSkeleton } from "./StyledSkeleton";

export const loaders = {
  checkout:{
    stepTwo:{
      editLink: <StyledSkeleton variant="rect" width={60} height={20} />
    }
  },
  header: {
    espotMenu: <SLHeaderEspotMenu />,
  },
  footer: {
    newsletter: <SLFooterNewsLetter />,
    navigation_card: <SLFooterNavigationCards />,
  },
  plp: {
    productCard: <SLPLPLayoutSlotTwo />,
    facet: <SLPLPFacets />,
    productsFound: <StyledSkeleton noCard variant="rect" width={100} height={20} />,
    addButton: <StyledSkeleton className={"bottom-margin-2 wide"} variant="rect"  height={40} />,
    addButtonOutlined: <StyledSkeleton className={"bottom-margin-2 wide"} variant="rect"  height={42} />

  },
  pdp:{
    breadcrumb: <SLPDPBreadCrumb/>,
    summaryCard: <SLPDPProductSummaryCard/>,
    imageCard: <SLPDPProductImageCard/>,
    priceCard: <SLPDPProductPriceCard/>,
    addToCart: <SLPDPAddToCart/>,
    availabilityCard: <SLPDPAvailability/>,
    aboutCard: <SLPDPAboutThisProduct/>,
    espotUxComponent: <SLPDPEspotUXComponentCard/>,
    espotCrossSellComponent: <SLPDPEspotCrossSellComponentCard/>,
},
  account: {
    mainAddress: <SLMainAddressCard />,
    personalInfo: <SLPersonalInfoComponent />,
    recentOrders: <SLRecentOrdersComponent />,
    recentOrdersCard: {
      a:<SLSimpleRecentOrderCard margin/>,
      b:<SLSimpleRecentOrderCard />,
      c:<SLSimpleRecentOrderCard margin top/>,
      d:<SLSimpleRecentOrderCard top/>
    },
    recentOrdersWithFilter: <SLRecentOrdersComponentWithFilter />,
    addressList: <SLAddressCards isMobile={null} isTablet={null}/>,
    profiles: (isMobile, isTablet)=>{ return <SLAccountProfilesWithFilter isMobile={isMobile} isTablet={isTablet} />},
    profilesCreate: {
        paymentMethods: <SLPaymentContainer/>
    },
    payments: {
      bankCards: <SLPaymentCards />,
      paypal: <SLPaypalPayment />,
    },
    wishList: {
      layout: <SLWishListLayout />,
      cardComponent: <SLWishListCardComponent />,
      cardComponentWithoutTitle: <SLWishListCardComponentWithoutTitle />,
      listCard: <SLWishListListCard/>
    },
    orderDetails: {
      orderDetailsContainer: (isMobile, isB2B) => (<SLOrderDetails isMobile={isMobile} isB2B={isB2B} />),
      productsOrderDetails: <SLCardProductsOrder />
    }
    
  },
  findStore: <SLFindStoreComponent />,
  secondary: {
    miniCart: {
      price: <StyledSkeleton variant="rect" width={70} height={18} />,
      quantity: <StyledSkeleton variant="rect" width={175} height={18} />,
      card: <SLMiniCartItemCard />,
    },
    cart: {
      itemCard: <SLProductCartAsRow />,
      deliveryAddress: <SLCartShippingMethod />,
      deliveryZone: <SLCartDeliveryZone />,
      availability: <StyledSkeleton variant="rect" width={180} height={18} />,
      tv: <SLTVComponent/>
  },
  },
  credictAr: <SLCredictAR />
};
