import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hdmDrawerColorDataRdc, onlineStoreRdc, pdpQuantityRdc } from "../../../../../redux/selectors/hdmData";

import { StyledDialog } from "../../../../elements/dialog";
import { StyledIconButton } from "../../../../elements/icon-button";
import { StyledButton } from "../../../../elements/button";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import { Divider } from "@material-ui/core";
import { palette } from "../../../../themes/color-palette";

import AgregarArticulo from "../../../../functions/AgregarArticulo";
import { AllowScroll, DisableScroll, tabletBreakpoint } from "../../../../functions/utils";

import ProductoGratisRadio from "./ProductoGratisRadio";
import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledCardMedia,
  ContainerRadioCardGrid,
  ContainerUncheckedRadioIcon,
} from "../../containers-precio-promocion";
import { useTranslation } from "react-i18next";
import ProductoGratisContent from "./ProductoGratisContent";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import StyledSwipeableDrawerHDM from "../../../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";

interface Props {
  openProductos: boolean;
  handleCloseProductos: () => void;
  articulos: any;
  product: any;
  partNumber: any;
  promotionDesc: any;
  baseQty: any;
  giftQty: any;
}
const ProductoGratisDialog = (props: Props) => {
  const { openProductos, handleCloseProductos, articulos, product, partNumber, promotionDesc, baseQty, giftQty } =
    props;
  const storeConfData = useSelector(onlineStoreRdc);
  const quantity = useSelector(pdpQuantityRdc);
  const [selectedImage, setSelectedImage] = useState<string>(
    storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/elige-opcion.svg"
  );
  const [selectedArticulo, setSelectedArticulo] = useState<any>(null);
  const [partNumberGift, setPartNumberGift] = useState<any>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  const handleSelectedArticulo = (articulo: any) => {
    const imageURL = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${articulo?.partNumber}/${articulo?.partNumber}-m.jpg`;
    setSelectedImage(imageURL);
    setSelectedArticulo(articulo);
    setPartNumberGift(articulo.partNumber);
  };

  useEffect(() => {
    if (!openProductos) {
      setSelectedImage(storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/elige-opcion.svg");
      setSelectedArticulo(null);
      setPartNumberGift(null);
      // setSelectedImg(false);
    }
  }, [openProductos]);

  const imageURL = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-m.jpg`;

  return (
    <>
      {isTablet ? (
        <StyledDialog open={openProductos} onClose={handleCloseProductos} fullScreen scroll={"paper"}>
          <ContainerStyledBox className="dialog-ahorramas-container">
            <ContainerStyledBox width={1 / 6}></ContainerStyledBox>
            <ContainerStyledBox width={4 / 6} className="dialog-titulo">
              <ContainerStyledTypography align="center" variant="textSegment">
                {t("productDetail.GiftProduct")}
              </ContainerStyledTypography>
            </ContainerStyledBox>
            <ContainerStyledBox width={1 / 6} className="dialog-close">
              <StyledIconButton
                aria-label="cerrar"
                className="dialog-close"
                size="small"
                onClick={handleCloseProductos}>
                <CancelOutlinedIcon fontSize="small" style={{ color: palette.gray["100"] }} />
              </StyledIconButton>
            </ContainerStyledBox>
          </ContainerStyledBox>
          <Divider style={{ backgroundColor: palette.gray["50"] }} />

          <DialogContent dividers={true} style={{ padding: 0 }}>
            <ProductoGratisContent
              promotionDesc={promotionDesc}
              imageURL={imageURL}
              selectedImage={selectedImage}
              articulos={articulos}
              selectedArticulo={selectedArticulo}
              handleSelectedArticulo={handleSelectedArticulo}
              partNumberGift={partNumberGift}
              partNumber={partNumber}
              quantity={quantity}
              baseQty={baseQty}
              giftQty={giftQty}
              product={product}
              handleCloseProductos={handleCloseProductos}
            />
          </DialogContent>
        </StyledDialog>
      ) : (
        <StyledSwipeableDrawerHDM
          isOpen={openProductos}
          setOpenDrawer={handleCloseProductos}
          variant={"giftProduct"}
          title={t("productDetail.GiftProduct")}>
          <ProductoGratisContent
            promotionDesc={promotionDesc}
            imageURL={imageURL}
            selectedImage={selectedImage}
            articulos={articulos}
            selectedArticulo={selectedArticulo}
            handleSelectedArticulo={handleSelectedArticulo}
            partNumberGift={partNumberGift}
            partNumber={partNumber}
            quantity={quantity}
            baseQty={baseQty}
            giftQty={giftQty}
            product={product}
            handleCloseProductos={handleCloseProductos}
          />
        </StyledSwipeableDrawerHDM>
      )}
    </>
  );
};

export default ProductoGratisDialog;
