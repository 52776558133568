import { useEffect, useState } from "react"
import { CORA_BUNDLE_AR_JS } from "../../../constants/common"

const useScript = (url, integrity, async = true, crossOrigin = "anonymous") => {

    useEffect(() => {
        const script = document.createElement("script")

        script.src = url

        script.async = async

        console.log("loaded script", url)
        if(url === CORA_BUNDLE_AR_JS){
            setTimeout(() => {
                document.body.appendChild(script)
            }, 500);
        }else{

            document.body.appendChild(script)
        }
        

        return () => {
            document.body.removeChild(script)
        }
    }, [url, async])

}

export default useScript