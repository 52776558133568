import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from "../../../hdm";
import { CLOSE_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import { isOpenSignInPopUp } from "../../../redux/selectors/drawer";
import { SignInLayout } from "./SignInLayout";
import { Divider } from "@material-ui/core";

// interface ISignInPopUp {
    // isOpen: boolean;
    // onClose: (boolean) => void;
    // }

const SignInPopUpContainer = styled((props: any) => <StyledDialog {...props} />)`
    ${({ theme }) => `
        @media (max-width: 960px){
            margin-left: ${theme.spacing(0)}px;
            margin-right: ${theme.spacing(0)}px;
        }
        .MuiPaper-root.MuiDialog-paper {
            max-width: fit-content;
        }
        & .MuiDialogContent-root {
            padding: 0;
            border: none;
        }

        & .divider{
            margin: 10px 0;
        }

        & .forgot-password-link{
            margin: 20px 0;
        }

        & .no-account-title{
            padding: 10px 0;
        }
        & .sign-in-link-container{
            margin-top: 20px;
        }

        & .divider{
            margin: 10px 0;
        }
    `}
`;

const SignInPopUp = () => {

    const { t } = useTranslation();

    const isOpen = useSelector(isOpenSignInPopUp)
    const dispatch = useDispatch<Dispatch<any>>();
    const signInTitle = t("SignIn.SignInTitle");

    const [title, setTitle] = useState("");

    useEffect(()=>{
                setTitle(signInTitle)
    },[signInTitle])

    const closeHandler = ()=>{   
        dispatch(CLOSE_SIGN_IN_POPUP_ACTION({}))
        setTimeout(()=>setTitle(signInTitle),1000)

    }
    
    return(
        
            <SignInPopUpContainer 
                open={isOpen} 
                onClose={closeHandler} 
                aria-labelledby="confirm-dialog" 
                disableEnforceFocus
                >
                <StyledDialogTitle title={title} onClickHandler={closeHandler}>

                </StyledDialogTitle>
                <StyledDialogContent>
                    <SignInLayout isPopUp setTitle={setTitle} closeHandler={closeHandler}/>
                </StyledDialogContent>
            </SignInPopUpContainer>
        
    )
}

export default SignInPopUp;