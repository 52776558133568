import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState, useRef } from "react";
import { ContainerStyledBox, ContainerStyledButton, ContainerStyledTextField } from "./containers-style";
import { useDebounce } from "use-debounce";

interface Props {
  quantity: string;
  handleSetQuantity: (value: string) => void;
  isCart?: boolean;
  nominalQuantity: string;
  isMiniCartInput?: boolean;
  maxValue?: string;
  withButtons?: boolean;
  disabled?: boolean;
  isNotZero?: boolean;
  isPdp?: boolean;
  isPaint?: boolean;
}

const ProductQuantity = (props: Props) => {
  const {
    quantity,
    handleSetQuantity,
    nominalQuantity,
    isCart,
    maxValue,
    withButtons = true,
    disabled = false,
    isNotZero = false,
    isPdp,
    isPaint
  } = props;

  // parseInt(productData.disponibilidadEnTienda)
  const [cantidadMaxima, setCantidadMaxima] = useState<any>(null);

  useEffect(() => {
    if (!maxValue || maxValue == "") {
      setCantidadMaxima(parseInt("9999"));
    }
    /* if(maxValue) {
      setCantidadMaxima(parseInt(maxValue))
    } */
    if (isCart && maxValue !== undefined) {
      setCantidadMaxima(parseInt(maxValue));
    }
    // else {
    //   if(productData && productData?.marketQuantity){
    //     setCantidadMaxima(parseInt(productData.marketQuantity))
    //   }
    // }
  }, [maxValue]);

  function isPositiveInteger(str: string) {
    if (typeof str !== "string") {
      return false;
    }

    if (str.trim() === "") {
      return true;
    }

    if (str.trim().length > 4) {
      return false;
    }

    const num = Number(str);

    if (props.isCart) {
      if (Number.isInteger(num)) {
        return true;
      }
    } else {
      // if (Number.isInteger(num) && num > 0) {
      if (Number.isInteger(num)) {
        return true;
      }
    }

    return false;
  }

    window["handleChangeQuantityByCalculator"] = (quantity: number) => {

      return handleChangeQuantity(quantity.toString())

    }

  const [debouncedValue] = useDebounce(quantity, 1000);

  useEffect(() => {
    if (!isPaint) {
      handleChangeQuantity(debouncedValue)
    }
  }, [debouncedValue])
  
  const handleChangeQuantity = (ev: React.ChangeEvent<HTMLInputElement> | string) => {
    let valor
    let type = ''
    if(typeof ev === 'string'){
      valor = ev
      type = 'calculator'
    } else {
      valor = ev
    }

    if (cantidadMaxima && !isCart) {
      if (parseInt(valor) > cantidadMaxima) {
        handleSetQuantity(cantidadMaxima.toString());
      }
    }
    
    if (isPositiveInteger(valor)) {
      if (valor.trim() !== "") {
        if (isCart) {
          if (parseInt(valor.trim()) === 0) {
            handleSetQuantity(valor);
          } else {
            if (parseInt(nominalQuantity) > parseInt(valor)) {
              handleSetQuantity(parseInt(nominalQuantity).toString())
            } else {
              handleSetQuantity(parseInt(valor).toString())
            }
            if (maxValue && parseInt(maxValue) < parseInt(valor)) {
              handleSetQuantity(parseInt(maxValue).toString())
            }
          }
        } else {
          if (isPdp && parseInt(valor.charAt(0))) {
            handleSetQuantity(parseInt(nominalQuantity).toString())
          }
          if (isPdp && parseInt(nominalQuantity) > parseInt(valor)) {
            handleSetQuantity(parseInt(nominalQuantity).toString())
          } else {
            handleSetQuantity(parseInt(valor).toString())
          }
        }      
      }

      if(type === 'calculator'){
        return valor

      }else {
        handleSetQuantity(valor);
      }
    }else if(type === 'calculator'){
      return nominalQuantity.toString()
    }
  };

  const handleBlurQuantity = (ev: React.FocusEvent<HTMLInputElement>) => {
    let valor = ev.target.value;

    if (parseInt(valor.trim()) === 0) {
      valor = parseInt(valor).toString();
      handleSetQuantity(valor);
    }

    if (
      (valor.trim() !== "" && valor.trim() !== "0" && parseInt(valor.trim()) <= parseInt(nominalQuantity)) ||
      valor.trim() === ""
    ) {
      valor = parseInt(nominalQuantity).toString();
      handleSetQuantity(valor);
    }

    if (cantidadMaxima) {
      if (parseInt(valor) > cantidadMaxima) {
        handleSetQuantity(cantidadMaxima.toString());
        return;
      }
    }
  };

  const handleChangeQuantityLess = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (quantity === "") {
      handleSetQuantity("1");
    }

    const num: number = Number(quantity);
    // if(props.isCart){
    if (Number.isInteger(num)) {

      let total = parseInt(quantity) - 1

      if (isNotZero && parseInt(nominalQuantity) > total) {
        return;
      }

      if (num === 0) {
        return;
      }

      total =
        parseInt(nominalQuantity) === num ? parseInt(quantity) - parseInt(nominalQuantity) : parseInt(quantity) - 1;
      handleSetQuantity(total.toString());
    }
    // }
    // else{
    //   if (Number.isInteger(num) && num > 0) {
    //     if (num === 1) {
    //       return;
    //     }
    //     if(parseInt(nominalQuantity) >= num) {
    //       return
    //     }
    //     const total = parseInt(quantity) - 1;
    //     handleSetQuantity(total.toString());
    //   }
    // }
  };

  const handleChangeQuantityMore = (ev: React.ChangeEvent<HTMLInputElement>) => {

    if (Number.isNaN(parseInt(quantity))) {
      handleSetQuantity(nominalQuantity.toString());
      return;
    }

    if (quantity === "") {
      handleSetQuantity("1");
    }

    if (cantidadMaxima) {
      if (parseInt(quantity) + 1 > cantidadMaxima) {
        handleSetQuantity(quantity);
        return;
      }
    }

    if (quantity !== "" && quantity === "0" && parseInt(quantity) + 1 <= parseInt(nominalQuantity)) {
      handleSetQuantity(nominalQuantity.toString());
      return;
    }

    const num: number = Number(quantity);
    if (Number.isInteger(num)) {
      if (num >= 9999) {
        handleSetQuantity("9999");
        return;
      }
      const total = parseInt(quantity) + 1;
      handleSetQuantity(total.toString());
    }
  };

  return (
    <>
      <ContainerStyledBox className="contenedor-cantidad">
        {withButtons && (
          <ContainerStyledBox>
            <ContainerStyledButton
              className={"button-less quantity-input-less"}
              disableRipple={true}
              onClick={disabled ? null : handleChangeQuantityLess}
              disabled={disabled}>
              <RemoveIcon style={{ fontSize: "15px" }} />
            </ContainerStyledButton>
          </ContainerStyledBox>
        )}
        <ContainerStyledBox>
          <ContainerStyledTextField
            value={quantity}
            className={"textfield-input-cantidad"}
            inputProps={{ min: 0 }}
            onChange={disabled ? null : (e) => {
              const value = e.target.value;
              if (!isNaN(value)) {
                  handleSetQuantity(value);
              }
            }}
            onBlur={handleBlurQuantity}
            disabled={disabled}
          />
        </ContainerStyledBox>
        {withButtons && (
          <ContainerStyledBox style={{}}>
            <ContainerStyledButton
              className={"button-more quantity-input-more"}
              disableRipple={true}
              onClick={disabled ? null : handleChangeQuantityMore}
              disabled={disabled}>
              <AddIcon style={{ fontSize: "15px" }} />
            </ContainerStyledButton>
          </ContainerStyledBox>
        )}
      </ContainerStyledBox>
    </>
  );
};

export default ProductQuantity;
