/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
//Custom libraries
import AccountSidebar from "../../widgets/account-sidebar/AccountSidebar";
import { TitleLayout } from "../../widgets/title";
import CheckoutProfileShipping from "./CheckoutProfileShipping";
import CheckoutProfileBilling from "./CheckoutProfileBilling";
import { ACCOUNT, CHECKOUT_PROFILES, CHECKOUT_PROFILE_CREATE } from "../../../constants/routes";
//UI
import {
  StyledContainer,
  StyledPaper,
  StyledStep,
  StyledStepLabel,
  StyledStepper,
  StyledGrid,
  StyledBreadcrumbs,
  StyledLink,
  StyledTypography,
  StyledBox,
} from "../../../hdm/elements";
import { useCheckoutProfileContext, withCheckoutProfileContext } from "./checkout-profile.context";
import useCheckoutProfile from "../../../_foundation/hooks/use-checkout-profile";
import { BreadcrumbWidget } from "../../../hdm/commerce-widgets";
import { ContainerCheckoutProfile } from "./container-checkout-profile";
import ProfileCreate from "./ProfileCreate";
import { checkoutProfileSelector } from "../../../redux/selectors/checkout-profile";
import { useDispatch, useSelector } from "react-redux";
import { useSite } from "../../../_foundation/hooks/useSite";
import { CPROF_FETCH_ALL_ACTION } from "../../../redux/actions/checkout-profile";
import getDisplayName from "react-display-name";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { CLOSE_BACKDROP_LOADER } from "../../../redux/action-types/backdrop-loader";

const breadCrum = [
  {
    label: "Mi cuenta",
    type_: "",
    value: "00000",
    seo: {
      href: ACCOUNT,
    },
  },
  {
    label: "Mis perfiles de compra",
    type_: "",
    value: "00000",
    seo: {
      href: CHECKOUT_PROFILES,
    },
  },
  {
    disabled: true,
    label: "Editar perfil de compra",
    type_: "",
    value: "00000",
    seo: {
      href: "/edit-checkout-profile",
    },
  },
  {
    disabled: true,
    label: "Crear perfil de compra",
    type_: "",
    value: "00000",
    seo: {
      href: "/edit-checkout-profile",
    },
  },
];

/**
 * Checkout component
 * displays shipping, billing, payment, review sections
 * @param props
 */
const CheckoutProfileEdit: React.FC = (props: any) => {
  const params = useParams();
  const widget = getDisplayName(CheckoutProfileEdit);
  const cancels: Canceler[] = [];
  const CancelToken = Axios.CancelToken;
  const payloadBase: any = {
    widget,
    cancelToken: new CancelToken((c) => cancels.push(c)),
  };
  const dispatch = useDispatch();
  const profileId = params.profileId;
  const { activeStep, updateProfile } = useCheckoutProfileContext();
  const { landed } = useCheckoutProfile();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const [updateDataProfile, setUpdatedataProfile] = useState<any>(null);
  const profileDetails = useSelector(checkoutProfileSelector);
  const [profileList, setProfileList] = useState<any[]>([]);
  let filteredprofileDetails = null;
  //to transform the checkout profile details from json object to profileList array
  const getCheckoutProfileDetails = useMemo(
    () => (response) => {
      const p = response.curUserProfiles.filter((p) => p.xchkout_ProfileId && p.xchkout_ProfileName);
      return p;
    },
    []
  );

  const getAndSetEditProfile = () => {
    if (profileId && profileList) {
      if (profileList) {
        filteredprofileDetails = profileList?.filter((profile) => profile.xchkout_ProfileId === profileId)[0];
      }
      if (filteredprofileDetails) {
        setUpdatedataProfile({
          profile: filteredprofileDetails,
        });
      }
    }
  };

  useEffect(() => {
    if (mySite && profileDetails?.curUserProfiles?.length === 0) {
      dispatch(CPROF_FETCH_ALL_ACTION({ ...payloadBase }));
    }
  }, [mySite, profileDetails]);
  useEffect(() => {
    const rc = getCheckoutProfileDetails(profileDetails);
    setProfileList(rc);
  }, [profileDetails]);
  useEffect(() => {
    if (profileList !== null) {
      getAndSetEditProfile();
    }
  }, [profileList]);

  return (
    <StyledContainer>
      <ContainerCheckoutProfile>
        <StyledBox>
          <BreadcrumbWidget cid="" breadcrumbs={breadCrum} />
        </StyledBox>

        <StyledGrid container spacing={2}>
          {updateDataProfile && <ProfileCreate updateDataProfile={updateDataProfile} />}
        </StyledGrid>
      </ContainerCheckoutProfile>
    </StyledContainer>
  );
  return (
    <StyledContainer cid="create-checkout-profile" spacing={2}>
      {updateProfile ? (
        <StyledBreadcrumbs className="vertical-padding-2">
          <StyledLink to={CHECKOUT_PROFILES}>
            <StyledTypography variant="h4">{t("CheckoutProfile.TitleCreate")}</StyledTypography>
          </StyledLink>
          <span>
            <StyledTypography variant="h4">{t("CheckoutProfile.EditProfile")}</StyledTypography>
          </span>
        </StyledBreadcrumbs>
      ) : (
        <TitleLayout title={t("CheckoutProfile.CreateCheckoutTitle")} />
      )}

      <StyledGrid container spacing={2}>
        <StyledGrid item xs={12} md={3} className="sidebar">
          <AccountSidebar />
        </StyledGrid>

        <StyledGrid container item xs={12} md={9} spacing={2} direction="column">
          <StyledGrid container className="vertical-margin-1">
            <StyledPaper style={{ flex: "1" }}>
              <StyledStepper activeStep={activeStep}>
                <StyledStep key="Shipping">
                  <StyledStepLabel>{t("CheckoutProfile.ShippingInformation")}</StyledStepLabel>
                </StyledStep>
                <StyledStep key="Billing">
                  <StyledStepLabel>{t("CheckoutProfile.BillingInformation")}</StyledStepLabel>
                </StyledStep>
              </StyledStepper>
            </StyledPaper>
          </StyledGrid>
          {landed ? activeStep === 0 ? <CheckoutProfileShipping /> : <CheckoutProfileBilling /> : null}
        </StyledGrid>
      </StyledGrid>
    </StyledContainer>
  );
};

export default withCheckoutProfileContext(CheckoutProfileEdit);
