import styled from "styled-components";
import { StyledGrid } from "../../../../../hdm";
import { StyledSkeleton } from "../StyledSkeleton";

export const SLCredictContainer = styled((props: any) => <StyledGrid {...props} />)`
    ${({ theme }) => `
       
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .skeleton-credictar-card {
             max-width: 700px;
        }

    `}
`;

const SLcredictARLayout = () => {
    return (
        <>
            <SLCredictContainer container>
                    <StyledGrid item xs={12} className="skeleton-credictar-card">
                        <StyledSkeleton className="top-margin-2" variant="rect" height={348} noCard />
                    </StyledGrid>
            </SLCredictContainer>
        </>

    );
}

export const SLCredictAR = () => {
    return (

        <SLcredictARLayout />


    );
}