/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/fyflogin";
import * as WORKERS from "../workers/fyflogin";

export function* watchSaga() {
  yield takeLatest(ACTIONS.FYF_LOGIN_REQUESTED, WORKERS.fyflogin);
}
