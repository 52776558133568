/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Rating from "@material-ui/lab/Rating";

const StyledRating = styled(({ ...props }) => <Rating {...props} />)`
  ${({ theme }) => `
    
  
   `}
`;

export { StyledRating };
