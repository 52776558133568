import { useEffect, useState } from "react";
import { Span, Div } from "../../containers-precio-promocion";
import StyledSwipeableDrawerHDM from "../../../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import EspotHdm from "../../../../../components/custom-components/shared/EspotHdm";
import { WorkspacePremiumSvg } from "../../../svg-icons";
import { palette } from "../../../../themes/";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../functions";

export const MesesSinIntereses = (props) => {
  const { catentryData } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const msiDrawerTitle = t("MSI");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if(catentryData){

      const priceString = numberWithCommas(catentryData);
      priceString &&
        setPrice(
          (
            (parseFloat(priceString.offerUnidad.replace(",", "")) +
              parseFloat(priceString.offerDecimal.replace(",", ""))) /
            12
          ).toFixed(2)
        );
    }
  }, [catentryData]);

  function handleOpenDrawerMsi() {
    setIsOpen(true);
  }

  return (
    <>
      <Div className="msi-container">
        <div>
          {/* <svg height="25" width="25" viewBox="-10 -4 40 40">
            <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
          </svg> */}
          <Div style={{ marginLeft: "-2px" }}>
            <WorkspacePremiumSvg />
          </Div>
        </div>
        <div>
          <Span className="msi-text">Meses sin intereses</Span>
          <Span className="msi-disponible-text">disponibles</Span>
        </div>
      </Div>
      <div>
        <Span className="msi-precio-text">${price}</Span>
        <Span className="msi-meses-text">{t("productDetail.Promotions.MSI.Month")}</Span>
        <sup>
          <Span className="msi-symbol">†</Span>
        </sup>
        {/* <Span className="msi-ver-opciones" onclick="myFunction()">Ver opciones</Span> */}
        <Span className="msi-ver-opciones" onClick={handleOpenDrawerMsi}>
          {t("productDetail.Promotions.MSI.SeeOptions")}
        </Span>
      </div>

      <StyledSwipeableDrawerHDM isOpen={isOpen} setOpenDrawer={setIsOpen} variant={"msi"} title={msiDrawerTitle}>
        <EspotHdm espotName="PDP_MSI" />
      </StyledSwipeableDrawerHDM>
    </>
  );
};
