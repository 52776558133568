import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import { StyledButton } from "../../../../hdm";
import styled from "styled-components";
import BackDropLoader from "../../backdrop-loader/BackdropLoader";
import { LoaderActions } from "../../skeleton-loader/actions/sl-actions";
import { backdropLoaderStateAndSrcSelector } from "../../../../redux/selectors/backdrop-loader";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../../../redux/actions/backdrop-loader";

const PdfDownloadButton = styled((props: any) => <StyledButton {...props} />)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3)}px;
    &.drawer-downloader{
      margin: ${theme.spacing(2)}px;
      width: 95%;
    }
`}
`;

const PdfDownloader = ({ isOpen, rootElementId, downloadFileName, buttonText = "Descargar instrucciones" }) => {
  const dispatch = useDispatch();
  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);
  const input = document.getElementById(rootElementId) as HTMLElement;

  const downloadPdfDocument = async () => {
    dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions }));
    try {
      input.classList.remove("hidePdf");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const canvas = await htmlToImage.toCanvas(input, {
        style: { position: "absolute", opacity: "1", right: "-1000px" },
      });
      input.classList.add("hidePdf");

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height], true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "JPEG", 20, 20, pdfWidth - 40, pdfHeight - 40);
      pdf.save(`${downloadFileName}.pdf`);
      window.open(pdf.output("bloburl"), "_blank");
    } catch (error) {
      input?.classList?.add("hidePdf");
      console.error("Error generating PDF:", error);
    }
    dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
  };
  return (
    <>
      <BackDropLoader showLoader={loaderState.open} />
      <PdfDownloadButton className={isOpen ? "drawer-downloader" : ""} onClick={downloadPdfDocument} variant="outlined">
        {buttonText}
      </PdfDownloadButton>
    </>
  );
};

export default PdfDownloader;
