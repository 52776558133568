import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";

import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

import { Divider } from "@material-ui/core"
import { Link } from "react-router-dom"
import styled from "styled-components"
import ProductQuantity from "../../../components/custom-components/styled-cantidad-producto/ProductQuantity"
import PrecioPesoMedida from "../../components/precio-peso-medida"
import { StyledBox, StyledCheckbox, StyledFormControlLabel, StyledTypography } from "../../elements"
import ProductPrice from "../../elements/product-card/styled-product-price"
import { getPesoMedida, getPromoicons, getUnidadMedida, ribbonsPromoIconsImg } from "../../functions"
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { useEffect, useState } from "react";

const ContainerStyledProductInformation = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme }) => `

&.styled--productinformation-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    ${theme.breakpoints.down("xs")} {
        flex-direction: column;
    }
}

.styled--productname-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    ${theme.breakpoints.down("xs")} {
        margin-bottom: 10px;
    }

}

.styled--product-name {
    max-width: 300px;
    max-height: 35px;
    overflow: hidden;
}

.styled--pricequantity-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.styled--productmeasure-container {
    width: calc(100% / 2);
}

.styled--price-container {
    display: flex;
    justify-content: start;

}

.styled--productprice-container {

}

.styled--product-price {
    display: flex;
    justify-content: center;
}

.styled--productname-link {
    color: ${theme.palette.gray["500"]};
}

`}
`

export const MerchandisingAssociationProductInfo = (props) => {

    const { product, handleOnChange, handleInputChange, thisProduct, className="" } = props
    const { t } = useTranslation();
    const storeConfData = useSelector(onlineStoreRdc);
    const { mySite } = useSite();
    const isB2B = Boolean(mySite?.isB2B);
    const storeSelector = useSelector(currentStoreSelector);
    const currentStoreId = storeSelector.currentStore.stLocId;
    const currentMarketId = storeSelector.currentStore.marketId;
    const [quantity, setQuantity] = useState("0")
    const handleSetQuantity = (value: string) => {
        handleInputChange(value, product?.partNumber)
        setQuantity(value)
    };

    useEffect(() => {
        if(!product?.isSelected) {
            setQuantity("0")
        }
    }, [product?.isSelected]) 

    const attributes = thisProduct?.attributes ? thisProduct.attributes : null;
    const promociones = getPromoicons(attributes, isB2B, currentStoreId, currentMarketId);
    const urlRibbons = ribbonsPromoIconsImg(storeConfData?.IMAGE_SERVER_HOSTNAME);

    let nominalQuantity = product["x_measurements.nominalQuantity"] ? product["x_measurements.nominalQuantity"] : "1"
    nominalQuantity = parseInt(nominalQuantity).toString()
    const urlProduct = product?.seo?.href
    const quantityMeasure = product?.["x_measurements.quantityMeasure"] ? getUnidadMedida(product?.["x_measurements.quantityMeasure"]) : ""
    const weightMeasure = product?.["x_measurements.weightMeasure"] ? getPesoMedida(product["x_measurements.weightMeasure"]) : "";
    let isMeassure = false
    if(quantityMeasure === 'm2' || quantityMeasure === 'm'){
        isMeassure = true
    }

    return (
        <ContainerStyledProductInformation className={`styled--productinformation-container ${className}`}>
            <StyledBox className="styled--productname-container">
                <StyledBox>
                    <StyledFormControlLabel
                        control={
                        <StyledCheckbox
                            size="small"
                            color="primary"
                            value={product?.partNumber}
                            onChange={(e) => handleOnChange(e.target)}
                            id={product?.partNumber}
                            checked={product?.isSelected}
                        />
                        }
                    />
                </StyledBox>
                <StyledBox className="styled--product-name">
                    <Link to={urlProduct} target="_blank" className="styled--productname-link">
                    <StyledBox >
                        {thisProduct ? 
                        <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
                        {t("productDetail.thisProduct")}
                        </StyledTypography>
                        :
                        null}
                        <StyledTypography variant="bodyBaseline" component="span">{product?.name}</StyledTypography>
                    </StyledBox>
                    </Link>
                </StyledBox>
            </StyledBox>

            <StyledBox className="styled--pricequantity-container">

                <StyledBox flexGrow={1}>
                    <StyledBox className="styled--price-container">
                        {isMeassure ?
                        <>
                        <StyledBox className="styled--productmeasure-container">
                            <PrecioPesoMedida 
                            catentryData={product} 
                            promociones={promociones}
                            quantityMeasure={quantityMeasure}
                            weightMeasure={weightMeasure}
                            isSmallSize="meassurePrice" />
                        </StyledBox>
                        <StyledBox className="horizontal-margin-1">
                            <Divider orientation="vertical"  />
                        </StyledBox>
                        </>
                        :
                        null
                        }

                        <StyledBox className="styled--productprice-container">
                            <ProductPrice 
                            productData={product} 
                            weightMeasure={weightMeasure}
                            urlRibbons={urlRibbons}
                            promociones={promociones}
                            quantityMeasure={quantityMeasure}
                            isSmallSize="unitPrice" />
                        </StyledBox>
                    </StyledBox>
                </StyledBox>


                <StyledBox className="right-margin-2">
                    <ProductQuantity
                    nominalQuantity={nominalQuantity}
                    quantity={product?.isSelected ? product?.buyQuantity : quantity}
                    handleSetQuantity={handleSetQuantity}
                    />
                </StyledBox>

            </StyledBox>

        </ContainerStyledProductInformation>
    )
}