import { StyledCardEmpty } from "../../elements/card";
import { ContainerStyledBox, ContainerStyledTypography } from "./containers-disponibilidad";
import { Divider } from "@material-ui/core";

import TiempoEstimadoEntrega from "./TiempoEstimadoEntrega";
import InventarioMercado from "./InventarioMercado";
import InventarioTiendaFisisca from "./InventarioTiendaFisisca";

import EventSharpIcon from "@material-ui/icons/EventSharp";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";

import { StyledBox, StyledTypography } from "../../elements";
import { useTranslation } from "react-i18next";
import KitAvailabilityByStore from "./KitAvailabilityByStore";

const mesesNumeroLetras = {
  0: "ene",
  1: "feb",
  2: "mar",
  3: "abr",
  4: "may",
  5: "jun",
  6: "jul",
  7: "ago",
  8: "sep",
  9: "oct",
  10: "nov",
  11: "dic",
};

export interface KitAvailabilityDataInterface {
  kitAvailabilityInfoByStore: Array<any>;
  getCompKitInventoryForAnotherStore: any;
  kitMarketAvailability: number;
  kitCurrentStoreAvailability: number;
  nextAvailableDate: string;
}

interface Props {
  currentStoreId: any;
  orderRibbons: any;
  isB2B: boolean;
  marketInventory: any;
  leadTime: any;
  tiendaNombre: any;
  locationName: any;
  productData: any;
  kitAvailabilityData: KitAvailabilityDataInterface;
}


const KitAvailability = (props: Props) => {
  const {
    currentStoreId,
    orderRibbons,
    isB2B,
    productData,
    marketInventory,
    leadTime,
    tiendaNombre,
    locationName,
    kitAvailabilityData
  } = props;


  const { t } = useTranslation();

  if(!productData || !kitAvailabilityData) return null

  let nextAvailableInventoryDate: any = "";
  if (kitAvailabilityData?.nextAvailableDate !== "") {
    nextAvailableInventoryDate = new Date(kitAvailabilityData?.nextAvailableDate);

    const dia = nextAvailableInventoryDate.getDate();
    nextAvailableInventoryDate = `${parseInt(dia) < 10 ? `0${dia}` : dia} ${mesesNumeroLetras[nextAvailableInventoryDate.getMonth()]
      } ${nextAvailableInventoryDate.getFullYear()}`;
  }
  const extendedCatalogImgStyle = { height: "100px", width: "auto", verticalAlign: "middle", display: "table-cell" };
  const extendedCatalogTxtStyle = { display: "table-cell", verticalAlign: "middle" };

  return (
    <>
      <ContainerStyledBox className="disponibilidad-container">
        <StyledCardEmpty className="padding-2 product-availability-card">
          <StyledBox className="title-disponibilidad">
            <ContainerStyledTypography variant="textSegment">
              {t("productDetail.Availability.labels.availability")}{" "}
            </ContainerStyledTypography>
          </StyledBox>

          <Divider />

          {(orderRibbons?.includes("PROMOICON_PC") || orderRibbons?.includes("PROMOICON_VF")) &&
            leadTime ? (
            <StyledBox>
              <StyledBox className="vertical-margin-2">
                <TiempoEstimadoEntrega leadTime={leadTime} />
              </StyledBox>
              <Divider />
            </StyledBox>
          ) : null}

          <StyledBox>
            <StyledBox className="vertical-margin-2">
              <InventarioMercado
                marketInventory={marketInventory}
                currentStoreId={currentStoreId}
                orderRibbons={orderRibbons}
                isKit
                kitMarketAvailability={kitAvailabilityData.kitMarketAvailability}
              />
            </StyledBox>
            <Divider />
          </StyledBox>

          <StyledBox>
            <StyledBox className="vertical-margin-2">
              <InventarioTiendaFisisca
                isB2B={isB2B}
                productData={productData}
                isKit
                kitMarketAvailability={kitAvailabilityData.kitCurrentStoreAvailability}
                bahiaData={productData?.bahiaData}
                disponibilidadEnTienda={productData?.disponibilidadEnTienda}
                tiendaNombre={tiendaNombre}
                locationName={locationName}
              />
            </StyledBox>
            <Divider />
          </StyledBox>

          {kitAvailabilityData.kitCurrentStoreAvailability === 0 ? (
            <>
              <StyledBox className="vertical-padding-2">
                <ErrorSharpIcon className="styled--errorsharp-icon" />
                <StyledTypography className="left-padding-2" variant="bodyBaseline" component="span">
                  {t("productDetail.Availability.labels.thisProductIs")}
                </StyledTypography>
                <StyledTypography className="left-padding-1" variant="bodyBaseline" fontWeight={600} component="span">
                  {t("productDetail.Availability.labels.soldout")}
                </StyledTypography>
              </StyledBox>
              <Divider />
              {nextAvailableInventoryDate !== "" ? (
                <>
                  <StyledBox className="vertical-padding-2">
                    <EventSharpIcon className="styled--eventsharp-icon" />
                    <StyledTypography className="left-padding-2" variant="bodyBaseline" component="span">
                      {t("productDetail.Availability.labels.findItFrom")}
                    </StyledTypography>
                    <StyledTypography
                      className="left-padding-1"
                      variant="bodyBaseline"
                      fontWeight={600}
                      component="span">
                      {nextAvailableInventoryDate}
                    </StyledTypography>
                  </StyledBox>
                  <Divider />
                </>
              ) : null}
            </>
          ) : null}
          {orderRibbons?.includes("PROMOICON_PC") ||
            orderRibbons?.includes("PROMOICON_VF") ||
            orderRibbons?.includes("ONLINE_ONLY_SELLING_STORE") ? (
            <StyledBox>
              <StyledBox className="vertical-margin-2" style={{ display: "table" }}>
                <img
                  alt="Empty cart"
                  style={extendedCatalogImgStyle}
                  src="https://cdn.homedepot.com.mx/iconosPromos/catalogo-extendido-dc.svg"
                  className="lazyload"
                />
                <StyledTypography
                  className="left-padding-2"
                  variant="bodyBaseline"
                  component="span"
                  style={extendedCatalogTxtStyle}>
                  {t("productDetail.Availability.labels.catalogExtended")}
                  <ContainerStyledTypography variant="bodyBaseline" weight="bold" component="span">
                    {tiendaNombre}, {locationName}
                  </ContainerStyledTypography>
                </StyledTypography>
              </StyledBox>
              <Divider />
            </StyledBox>
          ) : null}

          <KitAvailabilityByStore
            productData={productData}
            kitAvailabilityInfoByStore={kitAvailabilityData.kitAvailabilityInfoByStore}
            getCompKitInventoryForAnotherStore={kitAvailabilityData.getCompKitInventoryForAnotherStore} />
        </StyledCardEmpty>
      </ContainerStyledBox>
    </>
  );
};

export default KitAvailability;
