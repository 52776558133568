/*
 ***************************************************
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 ***************************************************
 */

import { thdTheme as ThdTheme } from "./themes";

export * from "./components";
export * from "./elements";
export * from "./commerce-layouts";
export * from "./commerce-widgets";
export * from "./types";
export * from "./hooks";

const themes = { ThdTheme };
// const CurrentTheme = themes[process.env.REACT_APP_STORENAME || "Emerald"];
const CurrentTheme = themes["ThdTheme"];
export { CurrentTheme };
