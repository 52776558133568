import { useEffect } from "react";

const useScriptInHead = (url, integrity, async = true, crossOrigin = "anonymous") => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.src = url;

    script.async = async;
    head.appendChild(script);
    console.log("loaded script in head tag");

    return () => {
      head.removeChild(script);
    };
  }, [url, async]);
};

export default useScriptInHead;
