import { StyledCardEmpty } from "../../elements/card";
import { ContainerStyledBox, ContainerStyledTypography } from "./containers-disponibilidad";
import { Divider } from "@material-ui/core";

import TiempoEstimadoEntrega from "./TiempoEstimadoEntrega";
import InventarioMercado from "./InventarioMercado";
import InventarioTiendaFisisca from "./InventarioTiendaFisisca";
import BuscarEnOtraTienda from "./BuscarEnOtraTienda";
import EventSharpIcon from "@material-ui/icons/EventSharp";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";

import { StyledBox, StyledTypography } from "../../elements";
import { useTranslation } from "react-i18next";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

const mesesNumeroLetras = {
  0: "ene",
  1: "feb",
  2: "mar",
  3: "abr",
  4: "may",
  5: "jun",
  6: "jul",
  7: "ago",
  8: "sep",
  9: "oct",
  10: "nov",
  11: "dic",
};

interface Props {
  isB2B: boolean;
  productId: any;
  partNumber: any;
  orderRibbons: any;
  bahiaData: any;
  leadTime: any;
  currentStoreId: any;
  tiendaNombre: any;
  locationName: any;
  marketInventory: any;
  attributes?: string | any;
}

const PdpDisponibilidad = (props: Props) => {
  const {
    isB2B,
    productId,
    partNumber,
    orderRibbons,
    bahiaData,
    leadTime,
    currentStoreId,
    tiendaNombre,
    locationName,
    marketInventory,
    attributes,
  } = props;
  const storeConfData = useSelector(onlineStoreRdc);
  const { t } = useTranslation();
  const fullFillmentTypes =
    attributes && attributes?.filter((attr) => attr?.identifier === "FULFILLMENT_TYPE")[0]?.values[0]?.value;
  let fullFillmentType = Array.isArray(fullFillmentTypes) ? fullFillmentTypes[0] : fullFillmentTypes;
  console.log("fullFillmentTypess ", fullFillmentTypes);
  if (fullFillmentTypes && fullFillmentTypes !== null) {
    if (
      typeof fullFillmentTypes === "string" &&
      fullFillmentTypes === "Online Only" &&
      orderRibbons?.includes("ONLINE_ONLY_SELLING_STORE")
    ) {
      fullFillmentType = "Online Only";
    } else if (
      Array.isArray(fullFillmentTypes) &&
      fullFillmentTypes?.includes("Online Only") &&
      orderRibbons?.includes("ONLINE_ONLY_SELLING_STORE")
    ) {
      fullFillmentType = "Online Only";
    }
    if (
      typeof fullFillmentTypes === "string" &&
      fullFillmentTypes === "Dropship" &&
      orderRibbons?.includes("DROPSHIP_ONLY_SELLING_STORE")
    ) {
      fullFillmentType = "Dropship";
    } else if (
      Array.isArray(fullFillmentTypes) &&
      fullFillmentTypes?.includes("Dropship") &&
      orderRibbons?.includes("DROPSHIP_ONLY_SELLING_STORE")
    ) {
      fullFillmentType = "Dropship";
    }
  }

  let disponibilidadEnTienda: any = 0;
  let nextAvailableInventoryDate;
  let availableQuantityInMarket: any = 0;
  if (marketInventory) {
    disponibilidadEnTienda = marketInventory.find((market) => market.physicalStoreName === currentStoreId);
    disponibilidadEnTienda = disponibilidadEnTienda?.availableQuantity ?? 0;
    nextAvailableInventoryDate =
      disponibilidadEnTienda?.x_isNextAvaialbleDate && disponibilidadEnTienda?.x_NextAvailableInventoryDate
        ? disponibilidadEnTienda?.x_NextAvailableInventoryDate
        : "";
    availableQuantityInMarket =
      disponibilidadEnTienda?.length > 0 && disponibilidadEnTienda?.x_AvailableQuantityInMarket
        ? parseInt(disponibilidadEnTienda?.x_AvailableQuantityInMarket)
        : 0;

  }

  let nextAvailableDate: any = "";
  let showNextInventoryDate = false;
  let showWhenIsDropship = false;
  let hideStoreInventory = false;
  let hideMarketInventory = false;
  if (nextAvailableInventoryDate !== "") {
    nextAvailableDate = new Date(`${nextAvailableInventoryDate}T00:00:00`);

    const dia = nextAvailableDate.getDate();
    nextAvailableDate = `${parseInt(dia) < 10 ? `0${dia}` : dia} ${
      mesesNumeroLetras[nextAvailableDate.getMonth()]
    } ${nextAvailableDate.getFullYear()}`;
  }
  const extendedCatalogImgStyle = { height: "100px", width: "auto", verticalAlign: "middle", display: "table-cell" };
  const extendedCatalogTxtStyle = { display: "table-cell", verticalAlign: "middle" };

  console.log("fullFillmentTypes ", fullFillmentType);
  console.log("marketInventory ", marketInventory);
  console.log("disponibilidadEnTienda ", disponibilidadEnTienda);
  console.log("nextAvailableInventoryDate ", nextAvailableInventoryDate);
  console.log("orderRibbons ", orderRibbons); //Contiene los attributos que le corresponden al SKU, pero falta detectar el tipo de SKU que es usando el fulfiltype

  if (fullFillmentType === "Special Order" || availableQuantityInMarket === 999999999) {
    hideStoreInventory = true;
  } else if (fullFillmentType === "Dropship" && nextAvailableInventoryDate !== "") {
    hideMarketInventory = true;
    hideStoreInventory = true;
    showNextInventoryDate = true;
  } else if (fullFillmentType === "Dropship" && nextAvailableInventoryDate === "") {
    hideStoreInventory = true;
    showWhenIsDropship = true;
  }
  const ProductAvailability = () => {
    return (
      <>
        <ContainerStyledBox className="disponibilidad-container">
          <StyledCardEmpty className="padding-2 product-availability-card">
            <StyledBox className="title-disponibilidad">
              <ContainerStyledTypography variant="textSegment">
                {t("productDetail.Availability.labels.availability")}{" "}
              </ContainerStyledTypography>
            </StyledBox>

            <Divider />

            {(fullFillmentType === "Dropship" || fullFillmentType === "Special Order") && leadTime ? (
              <StyledBox>
                <StyledBox className="vertical-margin-2">
                  <TiempoEstimadoEntrega leadTime={leadTime} />
                </StyledBox>
                <Divider />
              </StyledBox>
            ) : null}

            {!hideMarketInventory && (
              <StyledBox>
                <StyledBox className="vertical-margin-2">
                  <InventarioMercado
                    fullFillmentType={fullFillmentType}
                    currentStoreId={currentStoreId}
                    marketInventory={marketInventory}
                    orderRibbons={orderRibbons}
                  />
                </StyledBox>
                <Divider />
              </StyledBox>
            )}

            {!hideStoreInventory && (
              <StyledBox>
                <StyledBox className="vertical-margin-2">
                  <InventarioTiendaFisisca
                    isB2B={isB2B}
                    bahiaData={bahiaData}
                    disponibilidadEnTienda={disponibilidadEnTienda}
                    tiendaNombre={tiendaNombre}
                    locationName={locationName}
                    orderRibbons={orderRibbons}
                    fullFillmentType={fullFillmentType}
                  />
                </StyledBox>
                <Divider />
              </StyledBox>
            )}

            {showNextInventoryDate && storeConfData?.DROPSHIP_PDP_NEXTINVAVLDATE === "YES" && (
              <>
                <StyledBox className="vertical-padding-2">
                  <ErrorSharpIcon className="styled--errorsharp-icon" />
                  <StyledTypography className="left-padding-2" variant="bodyBaseline" component="span">
                    {t("productDetail.Availability.labels.thisProductIs")}
                  </StyledTypography>
                  <StyledTypography className="left-padding-1" variant="bodyBaseline" fontWeight={600} component="span">
                    {t("productDetail.Availability.labels.soldout")}
                  </StyledTypography>
                </StyledBox>
                <Divider />
                <StyledBox className="vertical-padding-2">
                  <EventSharpIcon className="styled--eventsharp-icon" />
                  <StyledTypography className="left-padding-2" variant="bodyBaseline" component="span">
                    {t("productDetail.Availability.labels.findItFrom")}
                  </StyledTypography>
                  <StyledTypography className="left-padding-1" variant="bodyBaseline" fontWeight={600} component="span">
                    {nextAvailableInventoryDate}
                  </StyledTypography>
                </StyledBox>
                <Divider />
              </>
            )}
            {hideStoreInventory || orderRibbons.includes("ONLINE_ONLY_SELLING_STORE") ? (
              <StyledBox>
                <StyledBox className="vertical-margin-2" style={{ display: "table" }}>
                  <img
                    alt="Empty cart"
                    style={extendedCatalogImgStyle}
                    src="https://cdn.homedepot.com.mx/iconosPromos/catalogo-extendido-dc.svg"
                    className="lazyload"
                  />
                  <StyledTypography
                    className="left-padding-2"
                    variant="bodyBaseline"
                    component="span"
                    style={extendedCatalogTxtStyle}>
                    {t("productDetail.Availability.labels.catalogExtended")}
                    <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
                      {tiendaNombre}, {locationName}
                    </ContainerStyledTypography>
                  </StyledTypography>
                </StyledBox>
                <Divider />
              </StyledBox>
            ) : null}

            {(showWhenIsDropship ||
              fullFillmentType === "Special Order" ||
              fullFillmentType === "Online Only" ||
              fullFillmentType === "Store") && (
              <BuscarEnOtraTienda
                marketInventory={marketInventory}
                productId={productId}
                partNumber={partNumber}
                currentStoreId={currentStoreId}
              />
            )}
          </StyledCardEmpty>
        </ContainerStyledBox>
      </>
    );
  };
  return withSkeletonLoader(<ProductAvailability />, productId, loaders.pdp.availabilityCard);
};

export default PdpDisponibilidad;
