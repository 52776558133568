import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
  Span,
} from "../../containers-precio-promocion";

interface Props {
  precioMayoreo: any;
}
export const PrecioMayoreo = (props: Props) => {
  const { precioMayoreo } = props;
  const storeConfData = useSelector(onlineStoreRdc);

  const ahorro = precioMayoreo.longDesc.split("||");

  return (
    <>
      <ContainerStyledBox className="ahorra-mas-subcontainer">
        <ContainerStyledBox className="promoicon-container">
          <ContainerStyledCardMedia
            image={storeConfData?.IMAGE_SERVER_HOSTNAME + "iconosPromos/precio_mayoreo.svg"}
            className="promo_plp-ribbon-ahp"
            title="Ahorra mas"
            component="img"
          />
          <ContainerStyledBox ml={1}>
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold">
              {`Obtén ${ahorro[1]}% ahorro`}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerStyledBox>
      <ContainerStyledBox>
        <ContainerStyledTypography variant="bodySubText" component="span">
          Por pieza al comprar&nbsp;
        </ContainerStyledTypography>
        <ContainerStyledTypography variant="bodySubText" weight="semibold" component="span">
          {ahorro[0]}&nbsp;
        </ContainerStyledTypography>
        <ContainerStyledTypography variant="bodySubText" component="span">
          piezas o más
          <sup>
            <Span>†</Span>
          </sup>
        </ContainerStyledTypography>
      </ContainerStyledBox>
    </>
  );
};
