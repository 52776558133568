/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";

type JsonLPProps = {
  payload: object;
};

/**
 * The layout for Category Card, which is used in Category Recommendation
 * widget layout.
 * @param category - a category object that contains category's info such as name, description, link, thumbnail etc
 */
export const JsonLP: React.FC<JsonLPProps> = (props) => {
  const { payload } = props;

  return (
    <script type="application/ld+json">
        {JSON.stringify(payload)}
    </script>
  );
};
