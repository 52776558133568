import styled from "styled-components";
import { StyledBox } from "../../../hdm";

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.product-card-precio {
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }
`}
`;

