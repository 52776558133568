import { createReducer, AnyAction } from "@reduxjs/toolkit";

import * as ACTIONS from "../action-types/drawer";
import initStates from "./initStates";
import { DrawerInterface } from "./reducerStateInterface";


const drawerReducer = createReducer(initStates.drawer, (builder) => {
    
    builder.addCase(ACTIONS.OPEN_CART_DRAWER, 
    (state: DrawerInterface, action: AnyAction) => {
        const { payload } = action
        state.cartSource = payload.source
        state.open = true;    
    });
    
    builder.addCase(ACTIONS.CLOSE_CART_DRAWER, 
    (state: DrawerInterface, action: AnyAction) => {
        state.open = false;    
    });

    builder.addCase(ACTIONS.OPEN_SIGN_IN_POPUP, 
    (state: DrawerInterface, action: AnyAction) => {
        return {...state, isSignInPopUpOpen: true, src: action.payload.src ?? action.payload.src }    
    });

    builder.addCase(ACTIONS.CLOSE_SIGN_IN_POPUP, 
    (state: DrawerInterface, action: AnyAction) => {
        return {...state, isSignInPopUpOpen: false, src:""}    
    });

    builder.addCase(ACTIONS.OPEN_REGISTER_POPUP, 
    (state: DrawerInterface, action: AnyAction) => {
        return {...state, isRegisterPopUpOpen: true}    
    });
    
    builder.addCase(ACTIONS.CLOSE_REGISTER_POPUP, 
    (state: DrawerInterface, action: AnyAction) => {
        return {...state, isRegisterPopUpOpen: false}    
    });

    
    builder.addCase(ACTIONS.OPEN_LOGIN_DRAWER, 
        (state: DrawerInterface, action: AnyAction) => {
            return {...state, isLoginDrawerOpen: true}    
        });

    builder.addCase(ACTIONS.CLOSE_LOGIN_DRAWER, 
        (state: DrawerInterface, action: AnyAction) => {
            return {...state, isLoginDrawerOpen: false}    
        });
});


export default drawerReducer;