import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { getCookie, setCookie } from "../styled-store-selector/cookieHandler";
import { createExternalIdValue } from "../conversion-api/pinterest/pinterest-conversion-utils";

const UTMCookieContainer = () => {
  const cookieName = "WC_CampaignTrace";
  const utmExpiryCookieName = "UTMEXPIRY";
  const userLoggedIn = useSelector(loginStatusSelector);

  const setUTMCookie = (val) => {
    document.cookie = `${cookieName}=${val};expires=Session;path=/;`;
  };

  function processUTMParams() {
    const url = window.location.href;
    const utmSourceRegex = /utm_source=([^&]+)/;
    const utmSourceMatch = url.match(utmSourceRegex);

    if (utmSourceMatch) {
      const indexMatch = url.indexOf("utm_source");
      const utmSourceValue = utmSourceMatch[1];
      const existingCookieValue = getCookie(cookieName);
      const UTMValue = url.substring(indexMatch, url.length);

      if (existingCookieValue && existingCookieValue === "NA") {
        // Si la cookie existe y tiene el valor 'NA', la inicializamos como un objeto vacío
        setUTMCookie(JSON.stringify({}));
      }

      let existingCookieData = {};

      try {
        existingCookieData = existingCookieValue ? JSON.parse(existingCookieValue) : {};
      } catch (error) {
        console.log("Error in cookie analysis. A cookie will be created as empty object");
        existingCookieData = {};
      }

      if (!existingCookieData[utmSourceValue]) {
        existingCookieData[utmSourceValue] = [UTMValue];
      } else {
        const existingUrls = existingCookieData[utmSourceValue];
        if (!existingUrls.includes(UTMValue)) {
          existingUrls.push(UTMValue);
        }
      }

      setUTMCookie(JSON.stringify(existingCookieData));
    }
  }

  //obtain urls query params
  const searchParams = new URLSearchParams(window.location.search);

  const paramsKeys: string[] = [];
  for (const [key] of searchParams.entries()) {
    paramsKeys.push(key);
  }

  const searchParamsToString = searchParams.toString();
  //look for utm_ prefix

  const utmIdx = searchParamsToString.indexOf("utm_");

  let cookieValue = "NA";
  //this means that utm_ query params are in url
  if (utmIdx !== -1) {
    cookieValue = searchParamsToString.slice(utmIdx);
  }

  useEffect(() => {

    //Create the external_id as sessionIdentifier for pinterest in App entry
    createExternalIdValue(); 
    
    const handleBeforeUnload = (event) => {
      // event.preventDefault();

      // create a 1 min expiration cookie
      const second = 1 / 24 / 60 / 60;
      const expiryTime = second * 15;
      setCookie(utmExpiryCookieName, 1, expiryTime);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const cookie = getCookie(cookieName);
  const utmExpiryCookie = getCookie(utmExpiryCookieName);

  //if cookie exist and utmExpiryCookie is not available
  if (cookie && cookieValue !== "NA") {
    processUTMParams();
  } else if (!utmExpiryCookie) {
    document.cookie = `${cookieName}=NA;expires=Session;path=/;`;
  }
  //if cookie doesnt exist
  if (!cookie) {
    processUTMParams();
  }

  return <></>;
};
export { UTMCookieContainer };
