import { Dimension } from "../dimensions";


export const SLPLP = {
    searchTitle: {
        desktop: "100%",
        mobile: new Dimension(450, 30),
    },
    searchTitleCount: {
        desktop: new Dimension(130, 30),
        mobile: new Dimension(200, 30),
    },
    sortBy: new Dimension(230, 40),
    sortByMobile: "100%",
    productCard: {
        image: new Dimension(265, 280),
        title: new Dimension(270, 30),
        rating: new Dimension(135, 20),
        price: new Dimension(135, 50),
        compare: new Dimension(135, 20),
        addToCart: new Dimension(270, 50),
    },
    paging: new Dimension(210, 30),
    pageResults: new Dimension(260, 20),
    facet: {
        title: new Dimension(130, 20),
        shrinker: new Dimension(30, 20),
        element: new Dimension(180, 25),
        elementMobile: new Dimension(160, 25),
    },
}