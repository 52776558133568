import React, { FC } from "react";
import styled from "styled-components";
import { StyledButton } from "../../../../hdm/elements";

interface IButtonHDMProps {
  text: string | number | JSX.Element;
  variant: Tvariant;
  disabled?: boolean;
  onClick?: () => void;
  type?: string;
  id?: string;
}

type Tvariant = "contained" | "outlined" | "default";

const StyledButtonHDM = styled((props: any) => <StyledButton {...props} />)`
  ${({ theme }) => `
        &.shopnow-button{
            color: ${theme.palette.text.expandedMenu};
            margin-bottom: ${theme.spacing(2)}px;
            background-color: ${theme.palette.primary.main};
            text-transform: none;
            box-shadow: none;
                &:hover{
                    background-color: ${theme.palette.primary.dark};
                    //TODO: add animation
                    // transform: translateX(10px);
                    // transition: all .2s ease-in;
                }
                //TODO: change theme
                & .MuiButton-label{
                    font-size: 16px;
                }
        }



        &.gotocar-button{
            // // color: ${theme.palette.primary.main};
            // // border-color: ${theme.palette.primary.main};
            // // border: 2px ${theme.palette.primary.main} solid;
            text-transform: none;
                &:hover{
                    background-color: ${theme.palette.primary.main};
                    color: ${theme.palette.text.expandedMenu};
                    //TODO: add animation
                    // transform: translateX(10px);
                    // transition: all .2s ease-in;
                }
                //TODO: change theme
                & .MuiButton-label{
                    font-size: 16px;
                }
                & .MuiButton-outlined.Mui-disabled {
                  border: 2px solid rgba(0, 0, 0, 0.12);
                }
        }

        &.text-button{
              color: #3E7697;
              width: auto;
              margin: 0;
              padding: 0;
                &:hover{
                    background-color: transparent;
                }

                & .MuiButton-label{
                    font-size: 15px;
                    font-weight: 400;
                }
        }

        &.Mui-disabled {
          border: 2px solid rgba(0, 0, 0, 0.12);
          background-color: rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);

        }
    `}
`;

const ButtonHDM: FC<IButtonHDMProps> = ({ children, variant, text, onClick, disabled = false, type, ...props }) => {
  const id = props?.id ? props.id : null;
  return (
    <>
      {variant === "contained" && (
        <StyledButtonHDM
          id={id}
          type={type}
          variant="contained"
          color="primary"
          className="shopnow-button"
          disableripple="true"
          onClick={onClick}
          disabled={disabled}>
          {text}
        </StyledButtonHDM>
      )}

      {variant === "outlined" && (
        <StyledButtonHDM
          id={id}
          variant="outlined"
          color="primary"
          className=""
          disableripple="true"
          onClick={onClick}
          disabled={disabled}>
          {text}
        </StyledButtonHDM>
      )}

      {variant === "default" && (
        <StyledButtonHDM
          id={id}
          variant="default"
          color="secondary"
          className="text-button"
          disableripple="true"
          onClick={onClick}>
          {text}
        </StyledButtonHDM>
      )}
    </>
  );
};

export default ButtonHDM;
