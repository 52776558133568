import React, { FC } from "react";
import { ListItemIcon } from "@material-ui/core";
import {
  StyledBox,
  StyledListItem,
  StyledTypography,
} from "../../../../hdm";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  listItemText: {
    "& > div": {
      "& > a": {
      fontSize: '15px !important',
      textDecoration: 'none !important',
      color:"#333333 !important",
    },
  },
},
  listItemTextAlt: {
      "& > a": {
      fontSize: '15px !important',
      textDecoration: 'none !important',
      color:"#333333 !important",
    },
  },
});

const StyledListItemHDM = ({ icon, title, setEspot, cid="" }) => {
  const classes = useStyles();
  return (
    <StyledBox id={cid} onClick={() => setEspot && setEspot(title)}>
      <StyledListItem button className={"list-item"}>
        <ListItemIcon className={"list-item__icon"}>{icon}</ListItemIcon>
        <StyledTypography style={{whiteSpace: "normal"}} variant={"bodyBaseline"} className={`list-item__text ${classes.listItemText} ${classes.listItemTextAlt}`}>
          {title}
        </StyledTypography>
      </StyledListItem>
    </StyledBox>
  );
};

export default StyledListItemHDM;
