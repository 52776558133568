import { Hidden } from "@material-ui/core";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledButton, StyledCheckbox, StyledChip, StyledFormControlLabel, StyledGrid, StyledNumberInput, StyledSwatch, StyledTypography } from "../../elements"
import StyledRatingsStars from "../../../components/custom-components/styled-ratings-stars/StyledRatingsStars";
import CurrentSelectedStore from "../../../components/custom-components/styled-current-selected-store/CurrentSelectedStore";
import { Fragment, useEffect, useState } from "react";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { formatFilterWidgetLabelforPromos } from "../../functions/utils";
import { useSelector } from "react-redux";
import { selectedFacetsSelector } from "../../../redux/selectors/catalog";

const StyledPriceInputsContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .price-input{
    @media screen and (max-width: 600px){
      min-width: 140px !important;
      margin-bottom: 10px;
    }
  }

    .middle-text-container{
      display: flex;
      align-items: center;
      padding-bottom: ${theme.spacing(2)}px;
    }

    .middle-text{
      padding-left: ${theme.spacing(7)}px;

      @media screen and (max-width: 600px){
        padding-left: ${theme.spacing(10)}px;
      }
    }

`}
`;

const StyledBrandFilterContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    &.panel2{
      @media screen and (max-width: 600px){
        display: flex;
        flex-direction: column;
      }
    }
`}
`;

const IconExpand = styled((props) => (
    <div {...props}>
      <div className="n">
        <RemoveIcon className="icon-filter-size" htmlColor="#333" viewBox="0 -5 24 24" />{" "}
      </div>
      <div className="y" style={{ marginTop: "5px" }}>
        <AddIcon htmlColor="#333" className={"productFilter--styledIcon-padding icon-filter-size"} />
      </div>
    </div>
  ))`
    & > .y {
      display: block;
    }
    & > .n {
      display: none;
    }
    .Mui-expanded & > .n {
      display: block;
    }
    .Mui-expanded & > .y {
      display: none;
    }
  `;

type Props = {
    index: number;
    isMobile: boolean;
    facet: any;
    isPriceFacet: (facet: any) => boolean;
    getFacetTitle: (facet: any) => string;
    priceLabel: string;
    priceSelected: boolean;
    minPrice: number | null;
    maxPrice: number | null;
    formattedPriceDisplay: any;
    clearPriceFacet: () => void;
    cid: string;
    onInputClick: (event: object) => void;
    minPriceLabel: string;
    onMinPriceChange: (v: number) => void;
    validatePriceRange: () => boolean;
    maxPriceLabel: string;
    onMaxPriceChange: (v: number) => void;
    isSubmitButtonEnabled: boolean;
    onPriceSubmit: () => void;
    filterLabel: string;
    // onFacetChange: (selection: string, label: string) => void;
    onFacetChange: any;
    isFacetSelected: (v: string) => boolean;
    isCategoryFacet: (facet: any) => boolean;
    FACET_CATEGORY_VALUE_PREFIX: string;
    showMoreButton: (facet: any) => any;
    showLessButton: (facet: any) => any;
    toggleFacetLimit: (
        event: any, //: MouseEvent<HTMLAnchorElement>,
        facetValue: string
    ) => void;
    showMoreLabel: string;
    showLessLabel: string;
  
}

const facetEntryName = {
    "Catalogo Extendido": "Catalogo extendido",
    "Hipoteca Verde": "Hipoteca verde",
    "Precio Mayoreo": "Precio mayoreo",
};

const ProductFilterAccordion = (props: Props) => {

    const {
        index,
        isMobile,
        facet,
        isPriceFacet,
        getFacetTitle,
        priceLabel,
        priceSelected,
        minPrice,
        maxPrice,
        formattedPriceDisplay,
        clearPriceFacet,
        cid,
        onInputClick,
        minPriceLabel,
        onMinPriceChange,
        validatePriceRange,
        maxPriceLabel,
        onMaxPriceChange,
        isSubmitButtonEnabled,
        onPriceSubmit,
        filterLabel,
        onFacetChange,
        isFacetSelected,
        isCategoryFacet,
        FACET_CATEGORY_VALUE_PREFIX,
        showMoreButton,
        showLessButton,
        toggleFacetLimit,
        showMoreLabel,
        showLessLabel
    } = props

    const setOpenStoreSelector = window.setOpenDrawer;

    const selectedFacets = useSelector(selectedFacetsSelector);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    useEffect(() => {
        let entrySelected = false
        if(Object.keys(selectedFacets).length > 0){
            console.log("selectedFacetsRRR", selectedFacets)
            console.log("siHay", facet)
            facet?.entry && facet.entry.map(entry => {

                if(selectedFacets[entry.value]){
                    entrySelected = true
                }
            })
        }

        if(entrySelected){
            setExpanded(true)
            console.log("seAbrioQWE", isPriceFacet(facet) ? priceLabel : getFacetTitle(facet))
        }

    }, [selectedFacets])
    

    return (
        <>
        {index < 5 && !isMobile ?
        <StyledAccordion
        defaultExpanded={index < 5 && !isMobile}
        key={facet.value}
        className={"productFilter--accordion-mobile"}>
        <StyledAccordionSummary
          className={"productFilter--facets-tittle product-filter__title"}
          expandIcon={<IconExpand className={"productFilter-icon-expand"} />}
          disableRipple>
          <StyledTypography variant={"bodyBaseline"} weight="semibold">
            {isPriceFacet(facet) ? priceLabel : getFacetTitle(facet)}
          </StyledTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {isPriceFacet(facet) && (index < 5) ? (
            <>
              {priceSelected && minPrice && minPrice > -1 && maxPrice && maxPrice > -1 ? (
                <StyledChip
                  size="medium"
                  label={formattedPriceDisplay}
                  onClick={() => clearPriceFacet()}
                  onDelete={() => clearPriceFacet()}
                />
              ) : (
                <StyledPriceInputsContainer
                  container
                  className="price-filter price-filter-input"
                  id={`productFilter_div_12_${index}_${cid}`}>
                  <StyledGrid container item onClick={onInputClick}>
                    <StyledGrid item xs={4} className="bottom-margin-1">
                      <StyledNumberInput
                        className="price-input price-input-min"
                        value={minPrice !== null ? minPrice : ""}
                        min={0}
                        precision={0}
                        placeholder={minPriceLabel}
                        onChange={(valueAsNumber: number) => onMinPriceChange(valueAsNumber)}
                        error={!validatePriceRange()}
                      />
                    </StyledGrid>

                    <StyledGrid item xs={3} className="middle-text-container">
                      <StyledTypography variant={"bodyBaseline"} weight="semibold" className="middle-text">
                        a
                      </StyledTypography>
                    </StyledGrid>

                    <StyledGrid item xs={4} className="bottom-margin-1">
                      <StyledNumberInput
                        className="price-input price-input-max"
                        value={maxPrice !== null ? maxPrice : ""}
                        min={0}
                        precision={0}
                        placeholder={maxPriceLabel}
                        onChange={(valueAsNumber: number) => onMaxPriceChange(valueAsNumber)}
                        error={!validatePriceRange()}
                      />
                    </StyledGrid>
                  </StyledGrid>
                  <Hidden smDown>
                    <StyledGrid xs={12} item className="top-margin-2">
                      <StyledButton
                        variant="outlined"
                        testId={`productFilter_price_button_19_${index}_${cid}`}
                        disabled={!isSubmitButtonEnabled}
                        size="small"
                        className="price-go"
                        id={`productFilter_button_19_${index}_${cid}`}
                        onClick={() => onPriceSubmit()}>
                        {filterLabel}
                      </StyledButton>
                    </StyledGrid>
                  </Hidden>
                </StyledPriceInputsContainer>
              )}
            </>
          ) : (index < 5) ? (
            <StyledBrandFilterContainer
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={`panel${index}`}>
              {facet.entry &&
                facet.entry.map((entry: any, _: number) => (
                  <Fragment key={entry.value}>
                    {entry.image ? (
                      <StyledSwatch
                        style={{
                          backgroundImage: `url("${entry.image}")`,
                        }}
                        onClick={() => onFacetChange("entry.value", entry.label)}
                        size="medium"
                        selected={isFacetSelected(entry.value)}
                      />
                    ) : (
                      <>
                        {entry.value.startsWith("store.") ? (
                          <>
                            <StyledFormControlLabel
                              className={"top-margin-1 full-width"}
                              control={
                                <StyledCheckbox
                                  type="checkbox"
                                  color="primary"
                                  style={{ marginLeft: "-2px" }}
                                  checked={isFacetSelected(
                                    (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                                  )}
                                  onChange={(e) =>
                                    onFacetChange( (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value,
                                      entry.label,
                                      e
                                    )
                                  }
                                />
                              }
                              label={
                                <StyledTypography variant={"bodyBaseline"} style={{ display: "flex" }}>
                                  {facet.value === "ratings.raw" ? (
                                    <StyledRatingsStars rating={entry.label} count={entry.count} />
                                  ) : (
                                    "Disponible en esta tienda " + "(" + entry.count + ")"
                                  )}
                                </StyledTypography>
                              }
                            />
                            <StyledGrid
                              className={"productFilter--facets-storeAvailability"}
                              onClick={() => setOpenStoreSelector(true)}>
                              <CurrentSelectedStore variant={"storeName"} />
                            </StyledGrid>
                          </>
                        ) : (
                          <StyledFormControlLabel
                            className={`top-margin-1 full-width product-filter__option ${
                              isFacetSelected(
                                (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                              )
                                ? "filter-selected"
                                : ""
                            }`}
                            control={
                              <StyledCheckbox
                                type="checkbox"
                                color="primary"
                                style={{ marginLeft: "-2px" }}
                                checked={isFacetSelected(
                                  (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                                )}
                                onChange={(e: any) =>
                                  onFacetChange(
                                    (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value,
                                    facet.value != "x_ratings.rating" ? (
                                      entry.label
                                    ) : (
                                      <StyledRatingsStars
                                        rating={entry.label}
                                        count={entry.count}
                                        hits={false}
                                      />
                                    ),
                                    e
                                  )
                                }
                              />
                            }
                            label={
                              <>
                                <StyledTypography variant={"bodyBaseline"} style={{ display: "flex" }}>
                                  {facet.value === "x_ratings.rating" ? (
                                    <StyledRatingsStars
                                      rating={entry.label}
                                      count={entry.count}
                                      hits={true}
                                    />
                                  ) : (
                                    (facetEntryName[entry.label]
                                      ? formatFilterWidgetLabelforPromos(facetEntryName[entry.label])
                                      : formatFilterWidgetLabelforPromos(entry.label)) +
                                    " " +
                                    (facet.value != "include_non_available" ? "(" + entry.count + ")" : "")
                                  )}
                                </StyledTypography>
                              </>
                            }
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                ))}
              <div className="top-margin-1 full-width">
                {facet.entry && showMoreButton(facet) && (
                  <StyledLinkButton
                    variant={"bodyBaseline"}
                    id={`productFilter_a_26_${index}_${cid}`}
                    onClick={(event) => toggleFacetLimit(event, facet.value)}>
                    {showMoreLabel}
                  </StyledLinkButton>
                )}
                {facet.entry && showLessButton(facet) && (
                  <StyledLinkButton
                    variant={"bodyBaseline"}
                    id={`productFilter_a_27_${index}_${cid}`}
                    onClick={(event) => toggleFacetLimit(event, facet.value)}>
                    {showLessLabel}
                  </StyledLinkButton>
                )}
              </div>
            </StyledBrandFilterContainer>
          ) : (
            <></>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
        :
        <StyledAccordion
        expanded={expanded}
        onChange={handleChange(index)}
        key={facet.value}
        className={"productFilter--accordion-mobile"}>
        <StyledAccordionSummary
          className={"productFilter--facets-tittle product-filter__title"}
          expandIcon={<IconExpand className={"productFilter-icon-expand"} />}
          disableRipple>
          <StyledTypography variant={"bodyBaseline"} weight="semibold">
            {isPriceFacet(facet) ? priceLabel : getFacetTitle(facet)}
          </StyledTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {isPriceFacet(facet) && (index < 5 || expanded) ? (
            <>
              {priceSelected && minPrice && minPrice > -1 && maxPrice && maxPrice > -1 ? (
                <StyledChip
                  size="medium"
                  label={formattedPriceDisplay}
                  onClick={() => clearPriceFacet()}
                  onDelete={() => clearPriceFacet()}
                />
              ) : (
                <StyledPriceInputsContainer
                  container
                  className="price-filter price-filter-input"
                  id={`productFilter_div_12_${index}_${cid}`}>
                  <StyledGrid container item onClick={onInputClick}>
                    <StyledGrid item xs={4} className="bottom-margin-1">
                      <StyledNumberInput
                        className="price-input price-input-min"
                        value={minPrice !== null ? minPrice : ""}
                        min={0}
                        precision={0}
                        placeholder={minPriceLabel}
                        onChange={(valueAsNumber: number) => onMinPriceChange(valueAsNumber)}
                        error={!validatePriceRange()}
                      />
                    </StyledGrid>

                    <StyledGrid item xs={3} className="middle-text-container">
                      <StyledTypography variant={"bodyBaseline"} weight="semibold" className="middle-text">
                        a
                      </StyledTypography>
                    </StyledGrid>

                    <StyledGrid item xs={4} className="bottom-margin-1">
                      <StyledNumberInput
                        className="price-input price-input-max"
                        value={maxPrice !== null ? maxPrice : ""}
                        min={0}
                        precision={0}
                        placeholder={maxPriceLabel}
                        onChange={(valueAsNumber: number) => onMaxPriceChange(valueAsNumber)}
                        error={!validatePriceRange()}
                      />
                    </StyledGrid>
                  </StyledGrid>
                  <Hidden smDown>
                    <StyledGrid xs={12} item className="top-margin-2">
                      <StyledButton
                        variant="outlined"
                        testId={`productFilter_price_button_19_${index}_${cid}`}
                        disabled={!isSubmitButtonEnabled}
                        size="small"
                        className="price-go"
                        id={`productFilter_button_19_${index}_${cid}`}
                        onClick={() => onPriceSubmit()}>
                        {filterLabel}
                      </StyledButton>
                    </StyledGrid>
                  </Hidden>
                </StyledPriceInputsContainer>
              )}
            </>
          ) : (index < 5 || expanded) ? (
            <StyledBrandFilterContainer
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={`panel${index}`}>
              {facet.entry &&
                facet.entry.map((entry: any, _: number) => (
                  <Fragment key={entry.value}>
                    {entry.image ? (
                      <StyledSwatch
                        style={{
                          backgroundImage: `url("${entry.image}")`,
                        }}
                        onClick={() => onFacetChange("entry.value", entry.label)}
                        size="medium"
                        selected={isFacetSelected(entry.value)}
                      />
                    ) : (
                      <>
                        {entry.value.startsWith("store.") ? (
                          <>
                            <StyledFormControlLabel
                              className={"top-margin-1 full-width"}
                              control={
                                <StyledCheckbox
                                  type="checkbox"
                                  color="primary"
                                  style={{ marginLeft: "-2px" }}
                                  checked={isFacetSelected(
                                    (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                                  )}
                                  onChange={(e) =>
                                    onFacetChange( (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value,
                                      entry.label,
                                      e
                                    )
                                  }
                                />
                              }
                              label={
                                <StyledTypography variant={"bodyBaseline"} style={{ display: "flex" }}>
                                  {facet.value === "ratings.raw" ? (
                                    <StyledRatingsStars rating={entry.label} count={entry.count} />
                                  ) : (
                                    "Disponible en esta tienda " + "(" + entry.count + ")"
                                  )}
                                </StyledTypography>
                              }
                            />
                            <StyledGrid
                              className={"productFilter--facets-storeAvailability"}
                              onClick={() => setOpenStoreSelector(true)}>
                              <CurrentSelectedStore variant={"storeName"} />
                            </StyledGrid>
                          </>
                        ) : (
                          <StyledFormControlLabel
                            className={`top-margin-1 full-width product-filter__option ${
                              isFacetSelected(
                                (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                              )
                                ? "filter-selected"
                                : ""
                            }`}
                            control={
                              <StyledCheckbox
                                type="checkbox"
                                color="primary"
                                style={{ marginLeft: "-2px" }}
                                checked={isFacetSelected(
                                  (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value
                                )}
                                onChange={(e: any) =>
                                  onFacetChange(
                                    (isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : "") + entry.value,
                                    facet.value != "x_ratings.rating" ? (
                                      entry.label
                                    ) : (
                                      <StyledRatingsStars
                                        rating={entry.label}
                                        count={entry.count}
                                        hits={false}
                                      />
                                    ),
                                    e
                                  )
                                }
                              />
                            }
                            label={
                              <>
                                <StyledTypography variant={"bodyBaseline"} style={{ display: "flex" }}>
                                  {facet.value === "x_ratings.rating" ? (
                                    <StyledRatingsStars
                                      rating={entry.label}
                                      count={entry.count}
                                      hits={true}
                                    />
                                  ) : (
                                    (facetEntryName[entry.label]
                                      ? formatFilterWidgetLabelforPromos(facetEntryName[entry.label])
                                      : formatFilterWidgetLabelforPromos(entry.label)) +
                                    " " +
                                    (facet.value != "include_non_available" ? "(" + entry.count + ")" : "")
                                  )}
                                </StyledTypography>
                              </>
                            }
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                ))}
              <div className="top-margin-1 full-width">
                {facet.entry && showMoreButton(facet) && (
                  <StyledLinkButton
                    variant={"bodyBaseline"}
                    id={`productFilter_a_26_${index}_${cid}`}
                    onClick={(event) => toggleFacetLimit(event, facet.value)}>
                    {showMoreLabel}
                  </StyledLinkButton>
                )}
                {facet.entry && showLessButton(facet) && (
                  <StyledLinkButton
                    variant={"bodyBaseline"}
                    id={`productFilter_a_27_${index}_${cid}`}
                    onClick={(event) => toggleFacetLimit(event, facet.value)}>
                    {showLessLabel}
                  </StyledLinkButton>
                )}
              </div>
            </StyledBrandFilterContainer>
          ) : (
            <></>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
        }
        </>
    )
}


export default ProductFilterAccordion