/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Select from "@material-ui/core/Select";

const StyledSelect = styled(({ ...props }) => <Select disableripple="true" IconComponent={"h"} {...props} InputLabelProps={{shrink: false, required: false}}/>)`
  ${({ theme, disabled }) => `
  height: 40px;
  .MuiSelect-select:focus{
    background-color: unset;
  }
  &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #CCCCCC;
    border-bottom-width: 2px;
    border-bottom-color: red;
  } 
  &.home-delivery-drawer{
    height: 48px;
  }
  &.MuiInputBase-formControl {
    background-color: ${disabled ? '#EEEEEE' : 'unset'};
    border: ${disabled ? '1px solid #CCCCCC' : 'unset'};
  }
  &.home-delivery-drawer{
    margin-bottom: 10px;
  }
 
  `}
`;

export { StyledSelect };
