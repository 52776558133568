import { StyledGrid } from "@hcl-commerce-store-sdk/react-component"
import { CircularProgress } from "@material-ui/core"
import { StyledBox, StyledButton, StyledTypography } from "../../../../hdm"
import { palette } from "../../../../hdm/themes"

export const ButtonWithLoader = (props) => {
    const { showLoader, text, loaderText } = props
    const styles = {
        backgroundColor: palette.gray[50],
        border: `2px solid ${palette.gray[200]}`
    }
    const ButtonContent = () => {
        return (
            <StyledBox container alignContent="center" item

                style={{
                    display: "flex",
                    justifyContent: "end",
                    marginLeft: "-20px"
                }}
            >
                <CircularProgress
                    className="right-margin-1"
                    style={{ color: palette.gray[200] }}
                    size={"15px"}
                    thickness={5} />
                <StyledTypography style={{ color: palette.gray[200] }} variant="bodyBaseline" weight="semibold">
                    {loaderText}
                </StyledTypography>
            </StyledBox>
        )
    }

    return (
        <StyledButton
            style={showLoader ? {...styles} : {}}
            {...props}
        >
            {showLoader ? <ButtonContent /> : text}

        </StyledButton>
    )
}