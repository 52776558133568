import { useState, useEffect } from "react";

import { StyledButton } from "../../../../elements/button";
import { palette } from "../../../../themes/color-palette";
import { Divider, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
} from "../../containers-precio-promocion";

import ProductoGratisDialog from "./ProductoGratisDialog";
import { AllowScroll, DisableScroll, tabletBreakpoint } from "../../../../functions/utils";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import { useTranslation } from "react-i18next";
import StyledSwipeableDrawerHDM from "../../../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import ProductoGratisContent from "./ProductoGratisContent";

interface Props {
  articulos: any;
  product: any;
  partNumber: any;
  promotionDesc: any;
  baseQty: any;
  giftQty: any;
}

export const ProductoGratis = (props: Props) => {
  //console.log("propsProductGratis => ", props)
  const { partNumber, articulos, product, promotionDesc, baseQty, giftQty } = props;

  const [openProductos, setOpenProductos] = useState<boolean>(false);
  const theme = useTheme();
  const is1366 = useMediaQuery(theme.breakpoints.up(1366));
  const storeConfData = useSelector(onlineStoreRdc);
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  useEffect(() => {
    if(!window.location.pathname.includes("/p/")){
      if (openProductos) {
        DisableScroll();
      } else {
        AllowScroll();
      }
    }
  }, [openProductos]);

  const handleOpenProductos = () => {
    setOpenProductos(true);
  };

  const handleCloseProductos = () => {
    setOpenProductos(false);
  };

  return (
    <>
      <ContainerStyledBox className="producto-gratis-container">
        <ContainerStyledBox flexGrow={1} className="producto-gratis-subcontainer">
          <ContainerStyledBox className="promoicon-container">
            <ContainerStyledCardMedia
              image={storeConfData?.IMAGE_SERVER_HOSTNAME + "iconosPromos/FP.svg"}
              className="promo_plp-ribbon-fp"
              title="Producto gratis"
              component="img"
            />
            <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} className="productogratis-text">
              {t("productDetail.AdditionalProduct")}
            </ContainerStyledTypography>
          </ContainerStyledBox>
          <ContainerStyledBox>
            <ContainerStyledTypography variant="bodySubText">
              {t("productDetail.FreeBuyingThisProduct")} <sup>†</sup>
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
        {/* <Divider orientation="vertical" flexItem style={{ backgroundColor: palette.gray["50"] }} /> */}
        <ContainerStyledBox className="aplicar-container">
          <ContainerStyledBox>
            <ContainerStyledTypography varinat="bodyBaseline" className="aplicar-text" onClick={handleOpenProductos}>
              {is1366 ? "Aplicar" : "Aplicar"}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>

        <ProductoGratisDialog
          openProductos={openProductos}
          handleCloseProductos={handleCloseProductos}
          articulos={articulos}
          product={product}
          partNumber={partNumber}
          promotionDesc={promotionDesc}
          baseQty={baseQty}
          giftQty={giftQty}
        />
      </ContainerStyledBox>
    </>
  );
};
