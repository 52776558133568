// HoC for skeleton loader

import { Skeleton } from "@material-ui/lab";
import { ReactComponentElement, Suspense, lazy, useState } from "react";
import { StyledSkeleton } from "../components/StyledSkeleton";
import { SLEspotSkeleton, SLEspotHCard, SLEspotVCard, SLEspotTBanner, SLEspotCBanner } from "../../../custom-components/skeleton-loader/components/elements/Espot";
import { loaders } from "../components";
const loaderOptions = {
  skeleton: <SLEspotSkeleton />,
  logo: <Skeleton variant="rect" width={60} height={60} />,
  topHeaderSection: <Skeleton variant="rect" width={"100%"} height={22.8} />,
  bottomHeaderSection: <Skeleton variant="rect" width={"100%"} height={5} />,
  hCard: <SLEspotHCard />,
  vCard: <SLEspotVCard n={1} />,
  vCard2: <SLEspotVCard n={2} />,
  vCard3: <SLEspotVCard n={3} />,
  vCard4: <SLEspotVCard n={4} />,
  tBanner: <SLEspotTBanner n={1} />,
  tBanner2: <SLEspotTBanner n={2} />,
  tBanner3: <SLEspotTBanner n={3} />,
  tBanner4: <SLEspotTBanner n={4} />,
  cBanner: <SLEspotCBanner n={1} />,
  cBanner2: <SLEspotCBanner n={2} />,
  cBanner3: <SLEspotCBanner n={3} />,
  cBanner4: <SLEspotCBanner n={4} />,
};

const withEspotSkeletonLoader = (
  component: JSX.Element,
  showCondition: boolean | undefined, //true shows the espot, false shows the skeleton
  name: string | undefined
): any => {
  let SkeletonLoader: JSX.Element | null = (
    null
  );

  const defaultTimeout = 3000;
  //Si detecta la palabra Prueba, los carga por mas tiempo
  //SOLO PARA MOTIVOS DE PRUEBA, BORRAR ESTA CONDICIONAL ANTES DE LIBERAR
  /*if (name?.includes("Prueba")) {
    console.log("Espot prueba",)
    showCondition = false;
    defaultTimeout *= 100;
  }*/

  // write prefix suffix logic
  //console.log("espot name", name)
  if (name !== undefined && name !== null) {
    if (name.endsWith("_Skeleton")) {
      SkeletonLoader = loaderOptions.skeleton;
    }
    else if (name.endsWith("_Logo")) {
      SkeletonLoader = loaderOptions.logo;
    }
    else if (name === "MainHeader_SuperiorSection") {
      SkeletonLoader = loaderOptions.topHeaderSection;
    }
    else if (name === "MainHeader_InferiorSection") {
      SkeletonLoader = loaderOptions.bottomHeaderSection;
    }
    else if (name?.endsWith("_HCard")) {
      SkeletonLoader = loaderOptions.hCard;
    }
    else if (name.endsWith("_VCard")) {
      SkeletonLoader = loaderOptions.vCard;
    }
    else if (name.endsWith("_VCard2")) {
      SkeletonLoader = loaderOptions.vCard2;
    }
    else if (name.endsWith("_VCard3")) {
      SkeletonLoader = loaderOptions.vCard3;
    }
    else if (name.endsWith("_VCard4")) {
      SkeletonLoader = loaderOptions.vCard4;
    }
    else if (name.endsWith("_TBanner")) {
      SkeletonLoader = loaderOptions.tBanner;
    }
    else if (name.endsWith("_TBanner2")) {
      SkeletonLoader = loaderOptions.tBanner2;
    }
    else if (name.endsWith("_TBanner3")) {
      SkeletonLoader = loaderOptions.tBanner3;
    }
    else if (name.endsWith("_TBanner4")) {
      SkeletonLoader = loaderOptions.tBanner4;
    }
    else if (name.endsWith("_CBanner")) {
      SkeletonLoader = loaderOptions.cBanner;
    }
    else if (name.endsWith("_CBanner2")) {
      SkeletonLoader = loaderOptions.cBanner2;
    }
    else if (name.endsWith("_CBanner3")) {
      SkeletonLoader = loaderOptions.cBanner3;
    }
    else if (name.endsWith("_CBanner4")) {
      SkeletonLoader = loaderOptions.cBanner4;
    }
    else if( name === "PDP-UXcomponent"){
      SkeletonLoader = loaders.pdp.espotUxComponent;
    }
    else if( name === "PDP-CrossSell"){
      SkeletonLoader = loaders.pdp.espotCrossSellComponent;
    }
  }

  if (SkeletonLoader === null) {
    SkeletonLoader = component;
  }

  const LoaderComponent = (props) => {
    const { show } = props;
    const [timedCondition, setTimedCondition] = useState(false);


    setTimeout(() => {
      !show && setTimedCondition(true);
    }, defaultTimeout);



    return <>{show ? component : SkeletonLoader}</>;
  };

  return <LoaderComponent show={showCondition} />;
};

export default withEspotSkeletonLoader;
