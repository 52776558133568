import { Divider, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm"
import theme from "../../../../../hdm/themes/thd";
import { StyledSkeleton } from "../StyledSkeleton"
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import WishList from "../../../../pages/wish-list/wish-list";
import { SLPLP } from "../dimensions/PLP";

export const SLWishListCardComponentCards = (props) => {


    return (
        <StyledGrid container item xs={12} direction="row" justifyContent="flex-start" spacing={2}>
            <StyledGrid item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid item>
                <SLPLPCardWishList />
            </StyledGrid>
        </StyledGrid>
    )
}

export const SLWishListCardComponentWithoutTitle = (props) => {

    let isMobile = false;
    if (theme.breakpoints.down("sm")) {
        const breakpoint = theme.breakpoints.down("sm").replace("@media", "")
        isMobile = window.matchMedia(breakpoint).matches
    }

    return (

        !isMobile
            ?
            <StyledGrid container item xs={12} direction="row" justifyContent="flex-start" spacing={2} className={"left-margin-1 bottom-margin-4"}>
                < StyledGrid item >
                    <SLPLPCardWishList />
                </StyledGrid >
                <StyledGrid item>
                    <SLPLPCardWishList />
                </StyledGrid>
                <StyledGrid item>
                    <SLPLPCardWishList />
                </StyledGrid>
                <StyledGrid item>
                    <SLPLPCardWishList />
                </StyledGrid>
            </StyledGrid >
            :
            <StyledGrid container>
                <StyledGrid container spacing={2}>
                    <StyledGrid item xs={12}>
                        <SLWishListCardMobile />
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                        <SLWishListCardMobile />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
    )
}
export const SLWishListCardComponent = () => {
    return (
        <StyledGrid container xs={10} direction="row" justifyContent="flex-start" >
            <StyledGrid style={{ marginTop: "21px", marginBottom: "31px" }} className={"left-margin-2"} item xs={12}>
                <StyledSkeleton noCard variant="rect" width={300} height={30} />
            </StyledGrid>
            <StyledGrid className={"horizontal-margin-2"} item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid className={"right-margin-2"} item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid className={"right-margin-2"} item>
                <SLPLPCardWishList />
            </StyledGrid>
            <StyledGrid className={"right-margin-2"} item>
                <SLPLPCardWishList />
            </StyledGrid>
        </StyledGrid>
    )
}

export const SLWishListListCard = () => {
    let isMobile = false;
    if (theme.breakpoints.down("sm")) {
        const breakpoint = theme.breakpoints.down("sm").replace("@media", "")
        isMobile = window.matchMedia(breakpoint).matches
    }

    return !isMobile ? <SLWishListListCardDesktop /> : <SLWishListCardMobile />
}
export const SLWishListLayout = () => {
    let isMobile = false;
    if (theme.breakpoints.down("sm")) {
        const breakpoint = theme.breakpoints.down("sm").replace("@media", "")
        isMobile = window.matchMedia(breakpoint).matches
    }

    return (
        <StyledContainer id={"WishList"}>
            {!isMobile ? <SLWishListLayoutDesktop /> : <SLWishListLayoutMobile />}
        </StyledContainer>
    )
}

export const SLPLPCardWishList = () => {
    const theme = useTheme();
    let isMobile = false;
    if (theme.breakpoints.down("sm")) {
      const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
      isMobile = window.matchMedia(breakpoint).matches;
    }
  
    const stylesDesktop = { maxWidth: "280px" };
    const stylesMobile = { width: "100%" };
  
    return (
      <>
        {!isMobile && (
          <StyledAccordion defaultExpanded={true} square={false} style={isMobile ? stylesMobile : stylesDesktop}>
            <StyledGrid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={"bottom-margin-1"}
              xs={12}>
              <StyledSkeleton
                variant="rect"
                width={260}
                height={SLPLP.productCard.image.getHeight()}
              />
            </StyledGrid>
  
            <StyledBox>
              <StyledSkeleton
                className={"vertical-margin-1"}
                variant="rect"
                width={260}
                height={SLPLP.productCard.title.getHeight()}
              />
              <StyledSkeleton
                className={"bottom-margin-1"}
                variant="rect"
                width={SLPLP.productCard.rating.getWidth()}
                height={SLPLP.productCard.rating.getHeight()}
              />
              <StyledSkeleton
                className={"vertical-margin-6"}
                variant="rect"
                width={SLPLP.productCard.price.getWidth()}
                height={SLPLP.productCard.price.getHeight()}
              />
              <StyledSkeleton
                className={"vertical-margin-1"}
                variant="rect"
                width={SLPLP.productCard.compare.getWidth()}
                height={SLPLP.productCard.compare.getHeight()}
              />
              <StyledSkeleton
                className={"vertical-margin-2"}
                variant="rect"
                width={260}
                height={SLPLP.productCard.addToCart.getHeight()}
              />
            </StyledBox>
          </StyledAccordion>
        )}
        {isMobile && (
          <StyledGrid item xs={12}>
            <StyledAccordion>
              <StyledGrid container>
                <StyledGrid item xs={4}>
                  <StyledSkeleton variant="rect" width={150} height={150} />
                </StyledGrid>
                <StyledGrid item xs={8} spacing={2}>
                  <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={32} />
                  <StyledSkeleton variant="rect" className={"bottom-margin-1"} width={130} height={20} />
                  <StyledSkeleton variant="rect" width={60} height={20} />
                </StyledGrid>
                <StyledGrid item xs={12} spacing={2} className={"top-margin-20"}>
                  <StyledSkeleton variant="rect" className={"bottom-margin-1"} width={130} height={35} />
                </StyledGrid>
                <StyledGrid item xs={4} className={"top-margin-10 bottom-margin-1"}>
                  <StyledSkeleton variant="rect" width={150} height={42} />
                </StyledGrid>
                <StyledGrid item xs={8} spacing={2} className={"top-margin-10 bottom-margin-1"}>
                  <StyledSkeleton variant="rect" className={"wide"} height={42} />
                </StyledGrid>
              </StyledGrid>
            </StyledAccordion>
          </StyledGrid>
        )}
      </>
    );
  };
export const SLWishListLayoutDesktop = () => {


    return (
        <>
            <StyledGrid container item className={"left-padding-1 vertical-margin-2 left-margin-2"} xs={12} direction="row" justifyContent="flex-start" spacing={2}>
                <StyledSkeleton noCard variant="rect" width={100} height={32} />
                <ArrowRightIcon className={"horizontal-margin-2"} fontSize="small" />
                <StyledSkeleton noCard variant="rect" width={100} height={32} />
                <ArrowRightIcon className={"horizontal-margin-2"} fontSize="small" />
                <StyledSkeleton noCard variant="rect" width={100} height={32} />
                <ArrowRightIcon className={"horizontal-margin-2"} fontSize="small" />
                <StyledSkeleton noCard variant="rect" width={100} height={32} />
            </StyledGrid>

            <StyledGrid style={{ width: "98%", margin: "0 auto" }} className="full-width">
                <StyledGrid className={"left-margin-3"} container>

                    <StyledBox style={{ maxWidth: "260px" }}>
                        <StyledGrid className={"bottom-margin-2"} item xs={12}>
                            <StyledSkeleton noCard variant="rect" width={250} height={72} />
                        </StyledGrid>
                        <StyledGrid item xs={12}>
                            <StyledSkeleton noCard variant="rect" width={250} height={170} />
                        </StyledGrid>
                    </StyledBox>
                    <StyledBox style={{ maxWidth: "calc(100% - 260px)" }}>
                        <StyledGrid container>
                            <StyledGrid style={{ marginTop: "21px", marginBottom: "31px" }} className={"left-margin-2"} item xs={12}>
                                <StyledSkeleton noCard variant="rect" width={300} height={30} />
                            </StyledGrid>
                            <StyledGrid container spacing={2} className={"left-margin-2"} >
                                <StyledGrid item>
                                    <SLPLPCardWishList />
                                </StyledGrid>
                                <StyledGrid item>
                                    <SLPLPCardWishList />
                                </StyledGrid>
                                <StyledGrid item>
                                    <SLPLPCardWishList />
                                </StyledGrid>
                                <StyledGrid item>
                                    <SLPLPCardWishList />
                                </StyledGrid>
                            </StyledGrid>
                        </StyledGrid>
                    </StyledBox>
                </StyledGrid>
            </StyledGrid>
        </>

    )
}

const SLWishListCardMobile = () => {

    const stylesMobile = { width: "100%" };

    return (
        <>
            <StyledAccordion defaultExpanded={true} square={false} style={stylesMobile}>
                <StyledGrid
                    container
                    className={"bottom-margin-1"}
                >

                    <StyledBox className={"right-margin-2"} style={{ maxWidth: "150px" }}>
                        <StyledSkeleton
                            variant="rect"
                            width={150}
                            height={150}
                        />
                    </StyledBox>
                    <StyledBox style={{ width: "calc( 100% - 160px)" }}>
                        <StyledSkeleton
                            className={"vertical-margin-1"}
                            variant="rect"
                            width={"100%"}
                            height={SLPLP.productCard.title.getHeight()}
                        />
                        <StyledSkeleton
                            className={"bottom-margin-1"}
                            variant="rect"
                            width={100}
                            height={SLPLP.productCard.rating.getHeight()}
                        />
                        <StyledSkeleton
                            className={"bottom-margin-1"}
                            variant="rect"
                            width={100}
                            height={SLPLP.productCard.rating.getHeight()}
                        />
                    </StyledBox>


                </StyledGrid>

                <StyledSkeleton
                    className={"vertical-margin-1"}
                    variant="rect"
                    width={SLPLP.productCard.price.getWidth()}
                    height={SLPLP.productCard.price.getHeight()}
                />

                <StyledGrid
                    container
                    className={"bottom-margin-1"}
                >
                    <StyledBox className={"right-margin-2"} style={{ maxWidth: "150px" }}>
                        <StyledSkeleton
                            className={"vertical-margin-2"}
                            variant="rect"
                            width={150}
                            height={SLPLP.productCard.addToCart.getHeight()}
                        />
                    </StyledBox>
                    <StyledBox style={{ width: "calc( 100% - 160px)" }}>
                        <StyledSkeleton
                            id="sdfsd"
                            className={"vertical-margin-2"}
                            variant="rect"
                            width={"100%"}
                            height={SLPLP.productCard.addToCart.getHeight()}
                        />
                    </StyledBox>

                </StyledGrid>


            </StyledAccordion>
        </>
    );
};
export const SLWishListLayoutMobile = () => {

    return (
        <StyledGrid style={{ width: "100%", margin: "0 auto" }} className="full-width">
            <StyledGrid container>
                <StyledGrid container item className={"left-padding-1 vertical-margin-2"} xs={12} direction="row" justifyContent="flex-start" spacing={2}>
                    <StyledSkeleton noCard variant="rect" width={100} height={32} />
                    <ArrowRightIcon className={"horizontal-margin-2"} fontSize="small" />
                    <StyledSkeleton noCard variant="rect" width={100} height={32} />
                    <ArrowRightIcon className={"horizontal-margin-2"} fontSize="small" />
                    <StyledSkeleton noCard variant="rect" width={100} height={32} />
                </StyledGrid>

                <StyledGrid container>
                    <StyledGrid style={{ marginTop: "21px" }} item xs={12}>
                        <StyledSkeleton noCard variant="rect" width={300} height={30} />
                    </StyledGrid>
                    <StyledGrid style={{ marginTop: "5px", marginBottom: "31px" }} container xs={12}>
                        <StyledSkeleton noCard variant="rect" width={100} height={20} />
                    </StyledGrid>
                    <StyledGrid container spacing={2}>
                        <StyledGrid item xs={12}>
                            <SLWishListCardMobile />
                        </StyledGrid>
                        <StyledGrid item xs={12}>
                            <SLWishListCardMobile />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>

            </StyledGrid>
        </StyledGrid>
    )
}

export const SLWishListListCardDesktop = () => {
    let isMobile = false;
    if (theme.breakpoints.down("sm")) {
        const breakpoint = theme.breakpoints.down("sm").replace("@media", "")
        isMobile = window.matchMedia(breakpoint).matches
    }

    const cardSize = isMobile ? 12 : 6;


    return (
        <StyledBox className="styled--right-card">
            <StyledGrid container spacing={2}>
                <StyledGrid item xs={cardSize}>
                    <StyledSkeleton noCard className="wide" variant="rect" height="100px" />
                </StyledGrid>
                <StyledGrid item xs={cardSize}>
                    <StyledSkeleton noCard className="wide" variant="rect" height="100px" />
                </StyledGrid>
            </StyledGrid>
        </StyledBox>
    )
}