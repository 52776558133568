/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, Fragment } from "react";

//UI
import {
  StyledGrid,
  StyledButton,
  StyledCheckbox,
  StyledChip,
  StyledFormControlLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledSwatch,
  StyledNumberInput,
  StyledPaper,
  StyledTypography,
} from "../../elements";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button/StyledLinkButton";
import CurrentSelectedStore from "../../../components/custom-components/styled-current-selected-store/CurrentSelectedStore";
import StyledStoreSelector from "../../../components/custom-components/styled-store-selector/StyledStoreSelector";
import StyledRatingsStars from "../../../components/custom-components/styled-ratings-stars/StyledRatingsStars";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import ButtonHDM from "../../../components/custom-components/styled-cart-drawer/cart-item/ButtonHDM";
import { StyledBox } from "../../elements/box/styled-box";
import FilterListIcon from "@material-ui/icons/FilterList";
import { StyledStickyPaper } from "../../../components/custom-components/styled-sticky-paper";
import { useTranslation } from "react-i18next";
import { formatFilterWidgetLabelforPromos } from "../../functions/utils";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import ProductFilterAccordion from "./product-filter-accordion";

const StyledProductFilterContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  &.productFilter--container-desktop{
    margin-top: 35px;
  }
  .productFilter-icon-expand{
    padding: ${theme.spacing(2)}px;
  }
  .productFilter--facets-storeAvailability{
    padding-left: ${theme.spacing(5)}px;

    margin-right: ${theme.spacing(2)}px;
    cursor: pointer;
    width: 100%;
  }

  .productFilter--facets-cards{
    ${theme.breakpoints.up("md")}{
      padding-top: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(2)}px;
    }
    margin-top: 5px;
    max-width: 250px;
    box-shadow: #cccccc 0 3px 6px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
    padding-right: 0;
  }

  .MuiPaper-elevation0 {
    box-shadow: none;
    padding-right: 0;
  }

  .productFilter--facets-tittle{
    height: 0px;  

    &.MuiIconButton-root .MuiIconButton-edgeEnd {
      margin-right: -5px;
    }

    & svg{
      font-size: 24px
    }

    &.MuiIconButton-edgeEnd{
      padding:0px;
    }
  }

  &.productFilter--styledIcon-padding{
    &.MuiIconButton-edgeEnd{
      padding:0px;
    }
  }


  .productFilter--stickyPaper-container{
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    padding-top: ${theme.spacing(2)}px;
  }
  
  .price-input{
    max-width: 100px !important;
    min-width: 95px !important;
  }

`}
`;

const StyledProductFilterContainerMobile = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  .productFilter--accordion-mobile{
    margin-bottom: 0px;
  }
  .MuiIconButton-edgeEnd{
    padding: 0px;
  }
  height: 92%;
  overflow-y: scroll;
.productFilter--facets-storeAvailability{
  padding-left: ${theme.spacing(5)}px;
  cursor: pointer;
  width: 100%;
}


`}
`;

const StyledPriceInputsContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .price-input{
    @media screen and (max-width: 600px){
      min-width: 140px !important;
      margin-bottom: 10px;
    }
  }

    .middle-text-container{
      display: flex;
      align-items: center;
      padding-bottom: ${theme.spacing(2)}px;
    }

    .middle-text{
      padding-left: ${theme.spacing(7)}px;

      @media screen and (max-width: 600px){
        padding-left: ${theme.spacing(10)}px;
      }
    }

`}
`;

const StyledBrandFilterContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    &.panel2{
      @media screen and (max-width: 600px){
        display: flex;
        flex-direction: column;
      }
    }
`}
`;
interface ProductFilterProps {
  onInputClick: (event: object) => void;
  toggleFacetLimit: (
    event: any, //: MouseEvent<HTMLAnchorElement>,
    facetValue: string
  ) => void;
  showMoreButton: (facet: any) => any;
  showLessButton: (facet: any) => any;
  clearPriceFacet: () => void;
  validatePriceRange: () => boolean;
  onMaxPriceChange: (v: number) => void;
  onMinPriceChange: (v: number) => void;
  isFacetSelected: (v: string) => boolean;
  onFacetChange: (selection: string, label: string) => void;
  showFacet: (facet: any) => boolean;
  isCategoryFacet: (facet: any) => boolean;
  isPriceFacet: (facet: any) => boolean;
  getFacetTitle: (facet: any) => string;
  onPriceSubmit: () => void;
  isSubmitButtonEnabled: boolean;
  facets: Array<any> | null;
  priceSelected: boolean;
  FACET_CATEGORY_VALUE_PREFIX: string;
  cid: string;
  priceLabel: string;
  maxPriceLabel: string;
  minPriceLabel: string;
  filterLabel: string;
  showMoreLabel: string;
  showLessLabel: string;
  filterByLabel: string;
  minPrice: number | null;
  maxPrice: number | null;
  formattedPriceDisplay: any;
}

/**
 * Product Filter component
 * displays price, brand and other facets
 * @param props
 */

const IconExpand = styled((props) => (
  <div {...props}>
    <div className="n">
      <RemoveIcon className="icon-filter-size" htmlColor="#333" viewBox="0 -5 24 24" />{" "}
    </div>
    <div className="y" style={{ marginTop: "5px" }}>
      <AddIcon htmlColor="#333" className={"productFilter--styledIcon-padding icon-filter-size"} />
    </div>
  </div>
))`
  & > .y {
    display: block;
  }
  & > .n {
    display: none;
  }
  .Mui-expanded & > .n {
    display: block;
  }
  .Mui-expanded & > .y {
    display: none;
  }
`;

const facetEntryName = {
  "Catalogo Extendido": "Catalogo extendido",
  "Hipoteca Verde": "Hipoteca verde",
  "Precio Mayoreo": "Precio mayoreo",
};
export const ProductFilterWidget: React.FC<ProductFilterProps> = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const facetDrawerTitle = t("Facet");

  const {
    onInputClick,
    toggleFacetLimit,
    showMoreButton,
    showLessButton,
    clearPriceFacet,
    isSubmitButtonEnabled,
    validatePriceRange,
    onMaxPriceChange,
    onMinPriceChange,
    isFacetSelected,
    onFacetChange,
    showFacet,
    isCategoryFacet,
    isPriceFacet,
    getFacetTitle,
    facets,
    priceSelected,
    onPriceSubmit,
    FACET_CATEGORY_VALUE_PREFIX,
    cid,
    priceLabel,
    maxPriceLabel,
    minPriceLabel,
    filterByLabel,
    filterLabel,
    showMoreLabel,
    showLessLabel,
    minPrice,
    maxPrice,
    isStoreOnly,
    setIsStoreOnly,
    formattedPriceDisplay,
  } = props;

  const StickyButtonApplyMobile = () => {
    return (
      <StyledProductFilterContainer className="productFilter--stickyContainerDrawer-mobile">
        <StyledGrid>
          <StyledPaper className="productFilter--stickyPaper-container">
            <ButtonHDM variant="outlined" text="Aplicar" onClick={() => setFilters()} />
          </StyledPaper>
        </StyledGrid>
      </StyledProductFilterContainer>
    );
  };
  const stickyButtonFilterMobile = (
    <>
      <StyledGrid>
        <StyledPaper className="productFilter--stickyPaper-container">
          <StyledButton onClick={() => setOpenDrawer(true)}>
            <FilterListIcon fontSize="medium" />
            Filtrar
          </StyledButton>
        </StyledPaper>
      </StyledGrid>
    </>
  );

  const setFilters = () => {
    onPriceSubmit();
    setOpenDrawer(false);
  };

  const contentFacets = (
    <>
      {facets &&
        facets.map(
          (facet: any, index: number) =>
            facet &&
            showFacet(facet) && (
              <StyledPaper className={"productFilter--facets-cards"}>
                <ProductFilterAccordion 
                  index={index}
                  isMobile={isMobile}
                  facet={facet}
                  isPriceFacet={isPriceFacet}
                  getFacetTitle={getFacetTitle}
                  priceLabel={priceLabel}
                  priceSelected={priceSelected}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  formattedPriceDisplay={formattedPriceDisplay}
                  clearPriceFacet={clearPriceFacet}
                  cid={cid}
                  onInputClick={onInputClick}
                  minPriceLabel={minPriceLabel}
                  onMinPriceChange={onMinPriceChange}
                  validatePriceRange={validatePriceRange}
                  maxPriceLabel={maxPriceLabel}
                  onMaxPriceChange={onMaxPriceChange}
                  isSubmitButtonEnabled={isSubmitButtonEnabled}
                  onPriceSubmit={onPriceSubmit}
                  filterLabel={filterLabel}
                  onFacetChange={onFacetChange}
                  isFacetSelected={isFacetSelected}
                  isCategoryFacet={isCategoryFacet}
                  FACET_CATEGORY_VALUE_PREFIX={FACET_CATEGORY_VALUE_PREFIX}
                  showMoreButton={showMoreButton}
                  showLessButton={showLessButton}
                  toggleFacetLimit={toggleFacetLimit}
                  showMoreLabel={showMoreLabel}
                  showLessLabel={showLessLabel}
                />
              </StyledPaper>
            )
        )}
    </>
  );

  return (
    <>
      <StyledProductFilterContainer className="productFilter--container-desktop">
        {!isMobile && 
          facets && facets.length >= 0 ?
          contentFacets:
          loaders.plp.facet
        }
        {/* <Facets/> */}
      </StyledProductFilterContainer>
      {facets && facets.length > 0 ? <StyledStickyPaper content={stickyButtonFilterMobile} /> : null}

      <StyledSwipeableDrawerHDM
        isOpen={openDrawer}
        setOpenDrawer={setOpenDrawer}
        variant={"facet"}
        title={facetDrawerTitle}>
        <StyledProductFilterContainerMobile>{contentFacets}</StyledProductFilterContainerMobile>
        <StickyButtonApplyMobile />
      </StyledSwipeableDrawerHDM>
    </>
  );
};
