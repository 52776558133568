
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";



function LinkDOMElement(props) {

  const targetNode = document.getElementById("root");
  const config = { childList: true, subtree: true };
  let timeOut: NodeJS.Timeout | null = null;
  let currentElem: HTMLElement | null = null;
  const location = useLocation();
  const [scrollEnabled, setScrollEnabled] = useState(true);


  const callback = (mutationList, observer) => {

    //if callback is called clear any defiend timeout
    if (timeOut !== null) {
      clearTimeout(timeOut)
    }
    //declare timeout
    timeOut = setTimeout(() => {
      scrollIntoElement();
    }, 300)


  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  targetNode && observer.observe(targetNode, config);

  const scrollIntoElement = 
    () => {
      const hash = location.hash;

      if (hash !== '') {
        const currentHash = hash.substring(1);
        currentElem = document.getElementById(currentHash);
      }
      if (currentElem !== null) {
        scrollEnabled && currentElem.scrollIntoView({ behavior: "auto" });
        setScrollEnabled(false)
        observer.disconnect();
        timeOut && clearTimeout(timeOut);
      } 
    }

    useEffect(() => {
      return () => {
        observer.disconnect();
      }
    }, [location])  


  return (
    <>
    </>
  );
}
export default LinkDOMElement;
