/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../../constants/routes";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import personService from "../../../_foundation/apis/transaction/person.service";
//Custom libraries
import { ResetPassword } from "../../widgets/reset-password";
import addressUtil from "../../../utils/addressUtil";
import { EMPTY_STRING } from "../../../constants/common";
//UI
import {
  StyledButton,
  StyledTextField,
  StyledTypography,
  StyledPaper,
  StyledBox,
  StyledGrid,
  StyledLink,
} from "../../../hdm/elements/";
import { Divider } from "@material-ui/core";
//redux
import * as successActions from "../../../redux/actions/success";
import { StyledRegistrationContainer } from "../../widgets/registration/RegistrationStyles";
import { StyledWrapper } from "../../StyledUI";
import { useNavigate } from "react-router";

const ForgotPasswordLayout: any = ({ setTitle, ...props }) => {
  const widgetName = getDisplayName(ForgotPasswordLayout);
  const [email, setEmail] = React.useState<string>(EMPTY_STRING);
  const [confirmationEmail, setConfirmationEmail] = React.useState<string>(EMPTY_STRING);
  const [forgotPasswordState, setForgotPasswordState] = React.useState<boolean>(true);
  const { mySite: site } = useSite();
  const { t } = useTranslation();
  const forgotPasswordTitle = t("ForgotPassword.Title");
  //const resetPasswordTitle = t("ResetPassword.Title");
  const validationCodeLabel = t("ResetPassword.ValidationCodeLabel");
  const logonIdLabel = t("ForgotPassword.LogonIDLabel");
  const resetPasswordTitle = t("ResetPassword.Title");

  const emailLabel = t("ForgotPassword.EmailLabel");
  const emailConfirmationLabel = t("ForgotPassword.EmailConfirmationLabel");
  const sendCodeButton = t("ForgotPassword.SendVerificationCodeButton");
  const contentText = t("ForgotPassword.ContentText");
  const contentTextB2B = t("ForgotPassword.ContentTextLogonID");
  const validationCodeButton = t("ForgotPassword.ValidationCodeButton");
  const CodeRecieved = t("ForgotPassword.CodeRecieved");
  const isB2B = site.isB2B;
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const [emailDoesNotExists, setEmailDoesNotExists] = useState<boolean>(false);

  const navigate = useNavigate();

  const [showInputError, setShowInputError] = useState({
    emailError: false,
    emailVerifyError: false,
    isBothEmailsEqual: false,
    emailMsgError: "",
    lastNameError: false,
    passwordError: false,
    passwordVerifyError: false,
  });
  const [passwordValidations, setPasswordValidations] = useState({
    firstRequirement: 0,
    secondRequirement: 0,
    thirdRequirement: 0,
    fourthRequirement: 0,
    fifthRequirement: 0,
  });

  /* show or hide text helper errors */
  const { emailError, emailVerifyError, isBothEmailsEqual, emailMsgError, passwordError, passwordVerifyError } =
    showInputError;

  /* Visual handler for password requirements */
  const { firstRequirement, secondRequirement, thirdRequirement, fourthRequirement, fifthRequirement } =
    passwordValidations;

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleEmail = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setEmail(val);
  };
  const handleConfirmationEmail = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
    const val: string | null = evt.target.value;
    setConfirmationEmail(val);
  };

  const handleForgotPasswordState = () => {
    setForgotPasswordState(false);
  };

  const handleSuccess = () => {
    handleForgotPasswordState();
  };

  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */
  const canContinue = (): boolean => {
    return (
      email !== EMPTY_STRING &&
      (isB2B || addressUtil.validateEmail(email)) &&
      confirmationEmail !== EMPTY_STRING &&
      (isB2B || addressUtil.validateEmail(confirmationEmail)) &&
      email === confirmationEmail
    );
  };
  const handleSubmit = (props: any) => {
    props.preventDefault();
    props.stopPropagation();
    const storeID = site.storeID;
    const parameters: any = {
      responseFormat: "application/json",
      storeId: storeID,
      body: {
        logonId: email,
        resetPassword: "true",
        challengeAnswer: "-",
      },
      ...payloadBase,
    };
    personService
      .updatePerson(parameters)
      .then((res) => {
        if (res.status === OK) {
          handleSuccess();
          const successMessage = {
            key: "success-message.RESEND_VERIFICATION_CODE",
          };
          //dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        }
      })
      .catch((err) => {
        handleSuccess();
      });
  };

  const getForgotPasswordState = () => {
    return forgotPasswordState;
  };

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setTitle) {
      setTitle(resetPasswordTitle);
    }
  }, [setTitle, resetPasswordTitle]);

  const handleRememberPassword = () => {
    if (props.isPopUp) {
      setTitle(t("ResetPassword.SignIn"));
    }

    if (props.isDrawer) {
      setTitle(t("ResetPassword.ContinueYouOrder"));
    }

    if (props.setShowForgotPasswordForm) {
      props.setShowForgotPasswordForm(false);
    }
  };

  return (
    <StyledWrapper style={{ overflow: "hidden" }} className="no-overflow">
      <div>
        <StyledGrid container justifyContent="center">
          <StyledGrid item xs={12}>
            <div>
              {forgotPasswordState ? (
                <>
                  {isB2B ? (
                    <StyledTypography variant="body1">{contentTextB2B}</StyledTypography>
                  ) : (
                    <>
                      <StyledTypography variant="bodyBaseline" weight={"regular"} className={"top-margin-1"}>
                        {contentText}
                      </StyledTypography>
                    </>
                  )}
                  <form name="forgotPasswordForm" noValidate onSubmit={handleSubmit}>
                    <StyledTextField
                      margin="normal"
                      required
                      label={
                        isB2B ? (
                          <StyledTypography variant="body1">{logonIdLabel}</StyledTypography>
                        ) : (
                          <StyledTypography variant="body1">{emailLabel}</StyledTypography>
                        )
                      }
                      fullWidth
                      name="email"
                      maxLength="100"
                      type={isB2B ? "text" : "email"}
                      // onChange={(e) => handleEmail(e, "email")}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        handleEmail(e, "email");
                        setEmailDoesNotExists(false);
                        if (email.length < 1) {
                          setShowInputError((prevState): any => ({ ...prevState, emailError: false }));
                        }
                      }}
                      error={!addressUtil.validateEmail(email) && !isB2B ? true : emailDoesNotExists ? true : false}
                      inputProps={{
                        maxLength: 100,
                        placeholder: isB2B ? EMPTY_STRING : "encarnacion@dominio.com",
                      }}
                      helperText={
                        !addressUtil.validateEmail(email) && !isB2B
                          ? t("ForgotPassword.Msgs.InvalidFormat")
                          : emailDoesNotExists
                          ? t("ForgotPassword.EmailDoesNotExists")
                          : EMPTY_STRING
                      }
                      onBlur={() =>
                        setShowInputError((prevState): any =>
                          isB2B ? false : { ...prevState, emailError: !addressUtil.validateEmail(email) ? true : false }
                        )
                      }
                    />

                    <StyledTextField
                      margin="normal"
                      required
                      label={
                        isB2B ? (
                          <StyledTypography variant="body1">{logonIdLabel}</StyledTypography>
                        ) : (
                          <>
                            <StyledTypography variant="body1">{emailConfirmationLabel}</StyledTypography>
                          </>
                        )
                      }
                      fullWidth
                      name="emailConfirmation"
                      maxLength="100"
                      type={isB2B ? "text" : "email"}
                      // onChange={(e) => handleConfirmationEmail(e, "email")}
                      onChange={(e) => {
                        setVerifyEmail(e.target.value);
                        handleConfirmationEmail(e, "email");
                        setEmailDoesNotExists(false);
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          isBothEmailsEqual: email !== verifyEmail ? true : false,
                        }));
                        if (verifyEmail.length < 1) {
                          setShowInputError((prevState): any => ({ ...prevState, emailVerifyError: false }));
                        }
                      }}
                      error={!addressUtil.validateEmail(email) && !isB2B ? true : emailDoesNotExists ? true : false}
                      inputProps={{
                        maxLength: 100,
                        placeholder: isB2B ? EMPTY_STRING : "correo@dominio.com",
                      }}
                      // helperText={
                      //   !addressUtil.validateEmail(confirmationEmail) && !isB2B ? t("ForgotPassword.Msgs.InvalidFormat") : EMPTY_STRING
                      // }
                      onBlur={() => {
                        setShowInputError((prevState): any => ({
                          ...prevState,
                          emailVerifyError: email !== verifyEmail ? true : false,
                          emailMsgError: !addressUtil.validateEmail(verifyEmail)
                            ? t("RegistrationLayout.Msgs.InvalidFormat")
                            : addressUtil.validateEmail(verifyEmail) && email !== verifyEmail
                            ? t("RegistrationLayout.Msgs.NoMatchEmails")
                            : addressUtil.validateEmail(verifyEmail) && isBothEmailsEqual
                            ? ""
                            : "",
                        }));
                      }}
                      // helperText={
                      //   !isB2B ? emailVerifyError && emailMsgError
                      //   : emailDoesNotExists ? t("ForgotPassword.EmailDoesNotExists")
                      //   : EMPTY_STRING
                      // }
                      helperText={
                        !isB2B && emailVerifyError
                          ? emailMsgError
                          : emailDoesNotExists
                          ? t("ForgotPassword.EmailDoesNotExists")
                          : EMPTY_STRING
                      }
                    />
                    <StyledBox className="vertical-margin-2" align="center">
                      <StyledButton
                        testId="forgot-password-send-validation-code"
                        color="primary"
                        type="submit"
                        className="login-process-button"
                        disabled={!canContinue()}>
                        {sendCodeButton}
                      </StyledButton>
                    </StyledBox>
                    <StyledGrid container alignItems="center" direction="row" justifyContent="center" spacing={6}>
                      {/* 
                  <StyledGrid item xs={12}>
                    <StyledGrid container alignItems="center" direction="column" justifyContent="center" spacing={1}>
                      <StyledGrid item xs={12}>
                        <StyledTypography variant="body1">{CodeRecieved}</StyledTypography>
                      </StyledGrid>
                      <StyledGrid item xs={12}>
                        <StyledButton
                          testId="forgot-password-verification-code"
                          color="secondary"
                          className="border-solid-bold login-process-button"
                          onClick={handleForgotPasswordState}
                          disabled={!canContinue()}>
                          {validationCodeButton}
                        </StyledButton>
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>
                  */}
                      <StyledGrid item xs={12}>
                        <Divider />
                        <StyledGrid
                          container
                          alignItems="left"
                          direction="row"
                          justifyContent="left"
                          className={"vertical-margin-2"}>
                          <StyledGrid item>
                            <StyledTypography variant="body1" component="span">
                              {t("ForgotPassword.AccountInfoRemember") + "  "}
                            </StyledTypography>
                            <StyledTypography variant="body1" component="span">
                              {props.setShowForgotPasswordForm ? (
                                <StyledTypography
                                  variant="body1"
                                  component="span"
                                  className={"link"}
                                  onClick={handleRememberPassword}>
                                  {t("ForgotPassword.SignIn")}
                                </StyledTypography>
                              ) : (
                                <StyledLink to={ROUTES.SIGNIN}>{t("ForgotPassword.SignIn")}</StyledLink>
                              )}
                            </StyledTypography>
                          </StyledGrid>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledGrid>
                  </form>
                </>
              ) : (
                <>
                  <ResetPassword
                    email={email}
                    setEmail={setEmail}
                    resendCode={handleSubmit}
                    isDrawer={props.isDrawer}
                    isPopUp={props.isPopUp}
                    setTitle={setTitle}
                    forgotPasswordState={forgotPasswordState}
                    handleRememberPassword={handleRememberPassword}
                  />
                </>
              )}
            </div>
          </StyledGrid>
        </StyledGrid>
      </div>
    </StyledWrapper>
  );
};

export default ForgotPasswordLayout;
