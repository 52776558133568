import React, { FC } from "react";
import styled, { css } from "styled-components";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";

// Icons
import "./RoomvoVisualSupport.css";
import { SvgIcon, Grid } from "@material-ui/core";
import { ReactComponent as ViewInRoomvoIcon } from "../../../assets/homedepot/images/scene-iconNaranjaClaro.svg";
import { palette } from "../../../hdm/themes";
interface IRoomvoVisualSupportProps {
  isRoomvoEnabled: boolean;
  isRoomvoVisualSupportEnabled: string;
  isMobileView?: boolean;
  SKU?: string;
}
const RoomvoStyledContainer = styled(({ ...props }) => <Grid {...props} />)`
  ${({ theme, fixed }) => `
        height: auto;
        width: 100%;
        background-color: #ffffff;
        box-shadow: #cccccc 0 3px 6px;
        padding: ${theme.spacing(2)}px;
        margin: 0 0 10px 0;
        z-index: 5;


        
        
    `}
`;

const RoomvoVisualSupport: FC<IRoomvoVisualSupportProps> = ({
  isRoomvoEnabled,
  isRoomvoVisualSupportEnabled,
  isMobileView = false,
  SKU,
}) => {
  return isRoomvoEnabled && (isRoomvoVisualSupportEnabled === "2" || isRoomvoVisualSupportEnabled === "3") ? (
    <RoomvoStyledContainer container className="roomvo-container" 
    style={{
      display: "none",
    }}
    data-product-type-str="floor" >
      <StyledBox
        className="roomvo-stimr roomvo-visual-links-container"
        style={{
          visibility: "hidden",
        }}
        id={"roomvo"}
        data-sku={SKU}
        role="button"
        data-active="true"
        data-roomvo-vendor-code={SKU}>
        <StyledBox style={{ marginRight: "10px", display: "flex" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@48,600,1,0"
          />
          <StyledTypography
            component="span"
            className="material-symbols-sharp fill"
            style={{ color: "#f96302", fontSize: "60px" }}>
            scene
          </StyledTypography>
        </StyledBox>
        <StyledBox style={{ justifyContent: "flex-start", display: "flex", width: "100%", marginRight: "10px" }}>
          <StyledTypography
            style={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              color: "#3E7697",
              fontSize: "1em",
            }}>
            <StyledTypography
              variant="bodyBaseline"
              component="span"
              style={{ fontWeight: "600", marginBottom: "5px" }}>
              Ver en mi espacio
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component="span">
              Crea un diseño virtual, combina con otros productos y visualiza tu proyecto
            </StyledTypography>
          </StyledTypography>
          <StyledBox
            style={{
              height: "60px",
              width: "40px",
              border: "1px solid #f96302",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#F96302",
            }}>
            <StyledTypography component="span" className="material-icons">
              chevron_right
            </StyledTypography>
          </StyledBox>
        </StyledBox>
      </StyledBox>
    </RoomvoStyledContainer>
  ) : null;
};

export default RoomvoVisualSupport;
