import { useEffect } from "react";
import styled from "styled-components";
import { StyledBox } from "../box";
import { StyledTextField } from "../text-field";

export const ContainerStyledInputCantidad = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
  
  .styled--inputproduct-box {
    width: 56px;

    &.MuiFormControl-root.MuiTextField-root {
      margin-bottom: 10px;
    }
  }
 
`}
`;

interface Props {
  quantity: string;
  handleSetQuantity: (value: string) => void;
  nominalQuantity: string;
}

const InputCantidad = (props: Props) => {
  const { quantity, handleSetQuantity, nominalQuantity } = props;


  useEffect(() => {
    handleSetQuantity(nominalQuantity)
  }, [])
  
  function isPositiveInteger(str: string) {
    if (typeof str !== "string") {
      return false;
    }

    if (str.trim() === "") {
      return true;
    }

    if (str.trim().length > 4) {
      return false;
    }

    const num = Number(str);

    if (Number.isInteger(num) && num > 0) {
      return true;
    }

    return false;
  }

  const handleChangeQuantity = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let valor = ev.target.value;

    if (isPositiveInteger(valor)) {
      if (valor.trim() !== "") {
        valor = parseInt(valor).toString();
        // if(parseInt(nominalQuantity) >= parseInt(valor)) {
        //   valor = parseInt(nominalQuantity).toString();
        // }
      }

      handleSetQuantity(valor);
    }
  };

  const handleBlurQuantity = (ev: React.FocusEvent<HTMLInputElement>) => {
    let valor = ev.target.value;
      if (valor.trim() === "") {
        valor = parseInt(nominalQuantity).toString();
        
        handleSetQuantity(valor);
      }else if (
          (valor.trim() !== "" && valor.trim() !== "0" && parseInt(valor.trim()) <= parseInt(nominalQuantity)) ||
          valor.trim() === ""
        ) {
          valor = parseInt(nominalQuantity).toString();
          handleSetQuantity(valor);
      }else {
        handleSetQuantity(valor);
      }
  };

  return (
    <>
      <ContainerStyledInputCantidad>
        <StyledTextField
          value={quantity}
          className={"styled--inputproduct-box"}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          onChange={handleChangeQuantity}
          onBlur={handleBlurQuantity}
        />
      </ContainerStyledInputCantidad>
    </>
  );
};

export default InputCantidad;
