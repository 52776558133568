import styled from "styled-components";
import { StyledGrid, StyledBox } from "../../elements";
import { tabletBreakpoint } from "../../functions/utils";
import { palette } from "../../themes/color-palette";

export const ContainerPdP = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, slotTwoHeight }) => `

    &.pdp-container {

        display: flex;
        ${theme.breakpoints.up(tabletBreakpoint())} {
            flex-direction: row;
            justify-content: space-between;

        }
        
        ${theme.breakpoints.down(tabletBreakpoint())} {
            flex-direction: column;
        }

        margin-bottom: 10px;

    }

    .pdp-slot1 {
        ${slotTwoHeight};
        position: relative;
        z-index: 10;
        ${theme.breakpoints.up(tabletBreakpoint())} {
            width: calc(60% - 5px);          
        }
        
        ${theme.breakpoints.down(tabletBreakpoint())} {
            width: 100%            
        }
    }

    .pdp-slot2 {
        ${slotTwoHeight};
        ${theme.breakpoints.up(tabletBreakpoint())} {
            width: calc(40% - 5px);        
        }
        
        ${theme.breakpoints.down(tabletBreakpoint())} {
            width: 100%           
        }
    }

    .styled--sticky-container {
      min-height: 300px !important;
    }

    .sticky-summary-gallery {
      position: sticky;
      top: 0;
    }

    .kits--styledGrid-quantityField{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 120px;
        ${theme.breakpoints.down("md")} {
          width: 60px;
        }
      }
    
      .kits--styledGrid-container{
        border-bottom: 1px solid ${theme.palette.gray[50]};
      }
    
      .kits--styledGrid-container:first-of-type{
        border-top: 1px solid ${theme.palette.gray[50]};
      }
    
      .kits--styledGrid-flexContainer{
        display: flex;
      }

      .kits--styledGrid-borderRight{
        border-right: 1px solid ${theme.palette.gray[50]};
      }

      .drawerKits--styledGrid-container{
        border: 1px solid ${theme.palette.gray[100]};
        border-radius: ${theme.spacing(1)}px;
        margin: ${theme.spacing(2)}px;
      }

      .summary-gallery-helper-active {
        animation: summaryGalleryActive 0.3s forwards; /* Added 'forwards' */
      }
    
      .summary-gallery-helper-desactive {
        animation: summaryGalleryDesactive 0.3s forwards; /* Added 'forwards' */
      }
    
      @keyframes summaryGalleryActive {
        0% {
          height: 0px; /* Start position */
        }
        100% {
          height: 70px; /* End position */
        }
      }
    
      @keyframes summaryGalleryDesactive {
        0% {
          height: 70px; /* Start position */
        }
        100% {
          height: 0px; /* End position */
        }
      }

`}
`;
