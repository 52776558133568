import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useWishList } from "../../../_foundation/hooks/use-wishlist";
import { MenuItem, Box } from "@material-ui/core";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";
import { getWishListSelector } from "../../../redux/selectors/wish-list";
import { StyledPopperMenu } from "../styled-popper-menu";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AddIcon from "@material-ui/icons/Add";
import { StyledLinkButton } from "../styled-store-selector/styled-link-button";
import { StyledWishListButtonContainer } from "./StyledWishListButtonContainer";
import { useNavigate } from "react-router";
import { loginStatusSelector } from "../../../redux/selectors/user";
import StyledSwipeableDrawerHDM from "../styled-drawer-hdm/StyledDrawerHDM";
import CreateWishList from "../../pages/wish-list/create-wish-list";
import { StyledWrapper } from "../../StyledUI";
import { useSite } from "../../../_foundation/hooks/useSite";
import { OPEN_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import { WISH_LIST_FAVORITES } from "../../../hdm/functions/constants";

interface WishListButtonProps {
  partNumber: string | number | any[];
  className?: string;
  placement?: string;
  isWishProduct: boolean;
  variant?: string;
  cid?: string;
}
const StyledWishListButton: React.FC<WishListButtonProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const wishListDrawerTitle = t("WishList.wishListDrawerTitle");
  const navigate = useNavigate();
  const { partNumber, className, placement = "bottom-start", isWishProduct, variant = "", cid} = props;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [menuListOpen, setMenuListOpen] = useState(false);
  const [isInWishList, setIsInWishList] = useState(false);
  const [skuWasAdded, setSkuWasAdded] = useState(false)
  const [openDialogCreateWishList, setOpenDialogCreateWishList] = useState(false);
  const prevOpen = React.useRef(menuListOpen);
  const userWishList: any[] = useSelector(getWishListSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const { addToWishList, createDefaultWishList } = useWishList();
  const { mySite } = useSite();

  const loginStatus = useSelector(loginStatusSelector);
  const isB2B = Boolean(mySite?.isB2B);

  const location = window.location.pathname

  const handleCancelarLista = () => {
    setOpenDialogCreateWishList(false);
  };

  const handleAddItemToWishList = async (externalIdentifier, partNumber) => {
    const bodyItemWishList = { externalId: externalIdentifier, partNumber: partNumber };
    await addToWishList(bodyItemWishList);
    setIsInWishList(true);
    setMenuListOpen(false);
    // const message = {
    //   key: "WishList.snackBar.success",
    //   link: {
    //     url: ROUTES.WISH_LIST,
    //     textKey: "WishList.snackBar.showList",
    //   },
    //   icon: "wishlist",
    // };
    // dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(message));
  };

  const handleCreateDefultWishList = async (partNumber) => {
    await createDefaultWishList(partNumber)
    setIsInWishList(true);
    setMenuListOpen(false)
  }

  const handleToggle = () => {

    if (!menuListOpen) {
      setMenuListOpen((prevOpen) => !prevOpen);
    }
  };

  useEffect(() => {
    if (!isWishProduct) {
      checkIfProductISInWishList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partNumber]);

  const checkIfProductISInWishList = () => {
    let isInWishListVar = false;
    if (userWishList && userWishList.length > 0) {
      userWishList.map((list) => {
        if (list?.item) {
          list.item.map((item) => {
            if (item.partNumber === partNumber) {
              isInWishListVar = true;
            }
          });
        }
      });
      setIsInWishList(isInWishListVar);
    }
  };

  const handleAddList = () => {
    //navigate(ROUTES.WISH_LIST);
    setOpenDialogCreateWishList(true);
  };
  const handleSkuAdded = (wasAdded: boolean) => {
    if (wasAdded) {
      setSkuWasAdded(true)
    }
  }
  let hasDefaultWishList = false

  console.log("isInWishListRRRR", isInWishList)
  console.log("isInWishListRRRR", location)

  const MenuItems = () => {
    return (
      <>
        {userWishList &&
          userWishList.length > 0 &&
          userWishList.map((list, index) => {

            if(list.description === WISH_LIST_FAVORITES){
              hasDefaultWishList = true
            }

            return (
              <MenuItem
                className="wishlist-menu__link"
                key={"menuItem" + index}
                onClick={() => handleAddItemToWishList(list.externalIdentifier, partNumber)}>
                <StyledTypography key={"styledTypography" + index} variant="bodyBaseline">
                  {list.description}
                </StyledTypography>
              </MenuItem>
            )
        })}
        {!userWishList || !hasDefaultWishList ?
        <MenuItem
        className="wishlist-menu__link"
        key={"menuItemDefault"}
        onClick={() => handleCreateDefultWishList(partNumber)}>
        <StyledTypography key={"styledTypography0"} variant="bodyBaseline">
        {WISH_LIST_FAVORITES}
        </StyledTypography>
      </MenuItem>
        :
        null
        }

        {loginStatus && (
        <MenuItem className="wishlist-menu__link">
          <StyledGrid className={"wishList--styledGrid-addCardTitleEdit"}>
            <AddIcon color={"primary"} className={"wishList--styledGrid-addDirectionIcon"} />
            <StyledLinkButton variant="bodyBaseline" onClick={() => handleAddList()}>
              {t("WishList.labels.createNewList")}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
        )}
        {!loginStatus && isB2B &&(
        <MenuItem className="wishlist-menu__link">
          <StyledGrid className={"wishList--styledGrid-addCardTitleEdit"}>
            <AddIcon color={"primary"} className={"wishList--styledGrid-addDirectionIcon"} />
            <StyledLinkButton variant="bodyBaseline" onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
              {t("WishList.labels.AddToMyList")}
            </StyledLinkButton>
          </StyledGrid>
        </MenuItem>
        )}
      </>
    );
  };

  if (!userLoggedIn) {
    return <></>;
  }

  if((isWishProduct || isInWishList) && location.includes("/cart")){
    return null
  }
  if (location.includes("/WishListDisplayView")) {
    
    return (
      <StyledWishListButtonContainer className={className}>
        <StyledBox className={"styledWishList--svg-container styledWishList--svg-wishProduct"} ref={anchorRef}>
          <FavoriteIcon />
        </StyledBox>
      </StyledWishListButtonContainer>
    );
   
  }

  return (
    <>
      <StyledSwipeableDrawerHDM
        isOpen={openDialogCreateWishList}
        setOpenDrawer={setOpenDialogCreateWishList}
        variant={"wishList-drawer"}
        title={wishListDrawerTitle}>
        <StyledWrapper>
          <StyledGrid className={"horizontal-padding-2"}>
            <CreateWishList
              handleCancelarLista={handleCancelarLista}
              userWishList={userWishList}
              variant={"drawer"}
              partNumber={partNumber}
              handleSkuAdded={handleSkuAdded}
            />
          </StyledGrid>
        </StyledWrapper>
      </StyledSwipeableDrawerHDM>
      <StyledWishListButtonContainer className={className}>
        {variant === "checkout" ? (
          <StyledBox ref={anchorRef} onClick={handleToggle}>
            <StyledLinkButton className="add-to-list" variant="bodyBaseline">
              {t("productDetail.CartOrderAddList")}
            </StyledLinkButton>
          </StyledBox>
        ) : variant === "checkout-orderSummary" ? (
          <StyledBox ref={anchorRef} onClick={handleToggle} className="styledWishList--orderSummary-container ">
            <FavoriteBorderIcon width="15" height="15" className="styledWishList--orderSummary-favoriteBorderIcon"/>
            <StyledLinkButton className="add-to-list" variant="bodyBaseline">
            {t("productDetail.CartOrderAddAllList")}
            </StyledLinkButton>
          </StyledBox>
        ) : (
          <StyledBox id={cid} className="styledWishList--svg-container" ref={anchorRef} onClick={handleToggle}>
            {menuListOpen || isInWishList || skuWasAdded ? (
              <FavoriteIcon />
            ) : (
              <>
                <FavoriteBorderIcon id="favoriteBorderIcon" /> <FavoriteIcon id="favoriteIcon" />
                <Box className="styledWishList--svg-line"></Box>
              </>
            )}
          </StyledBox>
        )}

        {!isInWishList ?
        <StyledPopperMenu
          MenuItems={MenuItems}
          anchorRef={anchorRef}
          menuListOpen={menuListOpen}
          setMenuListOpen={setMenuListOpen}
          prevOpen={prevOpen}
          placement={placement}
          variant={variant}
        />
        :
        null
        }
      </StyledWishListButtonContainer>
    </>
  );
};
export { StyledWishListButton };
