import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { StyledTypography, StyledBox, StyledGrid } from "../../../hdm/elements";
import { StyledCurrentSelectedStoreContainer } from "./styled-current-selected-store-container/StyledCurrentSelectedStoreContainer";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSite } from "../../../_foundation/hooks/useSite";
import { getCookieValue } from "../conversion-api/cookieHandler";


const CurrentSelectedStore = (props) => {

  const { isHeader } = props
  const currentStore = useSelector(currentStoreSelector);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const { mySite } = useSite();

  const isB2B = Boolean(mySite?.isB2B);
  const todayDate = new Date();
  const ddDate = String(todayDate.getDate()).padStart(2, "0");
  const mmDate = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyyDate = todayDate.getFullYear();
  const dDate = new Date(yyyyDate + "-" + mmDate + "-" + ddDate);
  const dayDate = dDate.getDay();
  let nextDay = dayDate + 1;
  if (nextDay === 7) {
    nextDay = 0;
  } else {
    nextDay = dayDate + 1;
  }

  if(currentStore?.currentStore?.city){
    const stateCookieId = getCookieValue("WC_stProv");
    if (stateCookieId === currentStore.currentStore.stateId) {
      document.cookie = `${'WC_stCity'}=${currentStore.currentStore.cityId};expires=Session;path=/;`;
    } else {
      document.cookie = "WC_stCity=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }

  /*variant = storeStatus || full || storeName */
  const variant = props.variant ? props.variant : "storeStatus";
  const storeDetails = props?.storeDetails ? props?.storeDetails : currentStore.currentStore;
  const schedule = storeDetails?.scheduleDiystore?.schedule;
  let openAt = "08:00 a.m.";
  let closesAt = "10:00 p.m.";
  let openAtNexDay = "08:00 a.m.";

  if (schedule) {
    openAt = schedule[dayDate].open;
    closesAt = schedule[dayDate].close;
    openAtNexDay = schedule[dayDate].open;
  }

  const getIsOpenStoreSchedule = () => {
    const openAt24 = get24HoursTime(openAt);
    const closesAt24 = get24HoursTime(closesAt);

    const sDate = getFullDateTime(false);
    const date = Date.parse(sDate);
    if (date >= openAt24 && date <= closesAt24) {
      return true;
    } else {
      return false;
    }
  };

  const getFullDateTime = (dateOnly) => {
    const today = new Date();
    const day = today.getDate().toString();
    const month = (today.getMonth() + 1).toString();
    const hours = today.getHours().toString();
    const minutes = today.getMinutes().toString();
    const seconds = today.getSeconds().toString();
    const date = today.getFullYear() + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");
    const time = hours.padStart(2, "0") + ":" + minutes.padStart(2, "0") + ":" + seconds.padStart(2, "0");

    if (dateOnly) {
      return date;
    } else {
      return date + "T" + time + "Z";
    }
  };

  const get24HoursTime = (time) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() === t("storeLocator.pm") && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() === t("storeLocator.am") && hours === 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    const date = getFullDateTime(true);
    return Date.parse(date + "T" + sHours + ":" + sMinutes + ":00Z");
  };

  useEffect(() => {
    if (storeDetails) {
      setIsOpen(getIsOpenStoreSchedule());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetails]);

  return (
    <StyledCurrentSelectedStoreContainer>
      <StyledBox
        className={"currentSelectedStore--address-store"}
        style={variant === "full" || variant === "storeName" ? {} : { display: "none" }}>
        <LocationOnIcon />
        <StyledBox className={"currentSelectedStore--address-store-name"}>
          <StyledTypography variant={"bodySubText"} className={isB2B && isHeader ? "styled--headerterxt-color" : ""}>
            {storeDetails?.storeName}, {storeDetails?.shortName}
          </StyledTypography>
          <ExpandMoreIcon className={"currentSelectedStore--expandMoreIcon-small"} />
        </StyledBox>
      </StyledBox>
      <StyledGrid
        className={
          variant === "storeStatus"
            ? "currentSelectedStore--address-statusOnly"
            : "currentSelectedStore--address-status"
        }
        style={variant === "storeName" ? {display: "none"} : {}}>
        {isOpen ? (
          <>
            <StyledTypography
              variant="bodySubText"
              weight="semibold"
              className={isB2B && isHeader ? "styled--headerterxt-color" : "currentSelectedStore--styledTypography-highlight"} 
              >
              {t("storeLocator.open")}
            </StyledTypography>
            <StyledTypography variant="bodySubText" className={isB2B && isHeader ? "styled--headerterxt-color" : "currentSelectedStore--styledTypography-secondary"}>
              {" "}
              - {t("storeLocator.closesAt")} {closesAt}
            </StyledTypography>
          </>
        ) : (
          <>
            <StyledTypography
              variant="bodySubText"
              weight="semibold"
              className={isB2B && isHeader ? "styled--headerterxt-color" : "currentSelectedStore--styledTypography-errorMain"}>
              {t("storeLocator.close")}&nbsp;
            </StyledTypography>
            <StyledTypography variant="bodySubText" className={isB2B && isHeader ? "styled--headerterxt-color" : "currentSelectedStore--styledTypography-secondary"}>
              {" "}
              - {t("storeLocator.openAt")} {openAtNexDay}
            </StyledTypography>
          </>
        )}
      </StyledGrid>
    </StyledCurrentSelectedStoreContainer>
  );
};

export default CurrentSelectedStore;
