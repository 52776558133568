import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllEspecificacionesTecForKits } from "../../functions/utils";
import { StyledGrid, StyledPaper, StyledTypography } from "../../elements";
import { KitProductDisplay } from "./kit-product-display";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { ContainerPdP } from "../product-details/container-pdp";
import { StyledWrapper } from "../../../components/StyledUI";
import { Divider } from "@material-ui/core";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";

import { useNavigate } from "react-router";
import EspecificacionesTecnicas from "../../components/pdp-acerca-de-este-producto/EspecificacionesTecnicas";

export const CustomTableKits = (props) => {
  const navigate = useNavigate();
  const [openDialogSpecifications, setOpenDialogSpecifications] = useState<boolean>(false);
  const [dataDialogSpecifications, setDataDialogSpecifications] = useState<any>({});
  const { tableData } = props;
  const rowsData = tableData.data;
  const { t } = useTranslation();
  const specificationsDrawerTitle = t("KitSpecifications");
  const handleGoToProductPage = (href) => {
    navigate(href);
  };

  const DrawerContent = () => {
    return (
      <StyledWrapper data-testid="app-wrapper">
        <ContainerPdP>
          <StyledGrid className={"drawerKits--styledGrid-container bottom-margin-2"}>
            <StyledGrid className="padding-2">
              <KitProductDisplay
                row={dataDialogSpecifications}
                showSpecifications={false}
                isDrawer={true}
                setOpenDialogSpecifications={setOpenDialogSpecifications}
                setDataDialogSpecifications={setDataDialogSpecifications}
              />
            </StyledGrid>
            <Divider className={"vertical-margin-2"} />
            <StyledGrid className="padding-2">
              <StyledTypography variant="textSegment">{t("productDetail.AboutProductKit")}</StyledTypography>
              <Divider className={"vertical-margin-2"} />
              <StyledTypography variant="bodyBaseline">{dataDialogSpecifications?.shortDescription}</StyledTypography>
              <StyledTypography variant="textSegment" className={"top-margin-2"}>
                {t("productDetail.TechnicalSpecificationsProductKit")}
              </StyledTypography>
              <Divider className={"vertical-margin-2"} />
              <EspecificacionesTecnicas
                allEspecificacionesTec={getAllEspecificacionesTecForKits(dataDialogSpecifications?.attributes)}
                isTablet={true}
                isKit={true}
              />
              {dataDialogSpecifications?.seo?.href && (
                <StyledLinkButton
                  variant="bodyBaseline"
                  className={"top-margin-2"}
                  onClick={() => handleGoToProductPage(dataDialogSpecifications?.seo?.href)}>
                  {t("productDetail.GoToProductPageKit")}
                </StyledLinkButton>
              )}
            </StyledGrid>
          </StyledGrid>
        </ContainerPdP>
      </StyledWrapper>
    );
  };

  return (
    <>
      <StyledSwipeableDrawerHDM
        isOpen={openDialogSpecifications}
        setOpenDrawer={setOpenDialogSpecifications}
        variant={"kit-specifications"}
        title={specificationsDrawerTitle}>
        <DrawerContent />
      </StyledSwipeableDrawerHDM>
      <StyledPaper className={"vertical-padding-2 horizontal-padding-2"} elevation={0}>
        <StyledTypography variant="textSegment" className={"bottom-padding-2"}>
          {t("productDetail.KitTittle")}
        </StyledTypography>
        {/* Product image */}
        {rowsData &&
          rowsData.length > 0 &&
          rowsData.map((row) => {
            return (
              <StyledGrid container direction="row" className={"kits--styledGrid-container vertical-padding-2"}>
                <KitProductDisplay
                  row={row}
                  showSpecifications={true}
                  setOpenDialogSpecifications={setOpenDialogSpecifications}
                  setDataDialogSpecifications={setDataDialogSpecifications}
                />
                <StyledGrid item className={"kits--styledGrid-quantityField"}>
                  <StyledGrid>
                    <StyledTypography variant="bodyCaption" className="color-gray300">
                      {t("productDetail.Quantity")}
                    </StyledTypography>
                    <StyledTypography variant="bodyBaseline" weight="semibold">
                      {parseInt(row.quantity)}
                    </StyledTypography>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            );
          })}
      </StyledPaper>
    </>
  );
};
