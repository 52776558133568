import { createReducer, AnyAction } from "@reduxjs/toolkit";

import * as ACTIONS from "../action-types/backdrop-loader";
import initStates from "./initStates";
import { BackDropLoaderInterface } from "./reducerStateInterface";
import { PRODUCT_GET_ERROR, PRODUCT_GET_SUCCESS, PRODUCT_LIST_GET_ERROR, PRODUCT_LIST_GET_REQUESTED, PRODUCT_LIST_GET_SUCCESS } from "../action-types/catalog";
import { CART_GET_ERROR, CART_GET_SUCCESS, CART_RESET_ERROR, COPY_CART_ERROR, ITEM_ADD_ERROR, ITEM_REMOVE_ERROR, ITEM_UPDATE_ERROR, ITEM_UPDATE_SUCCESS, SHIPMODE_UPDATE_ERROR, SHIPMODE_UPDATE_SUCCESS } from "../action-types/order";
import { FETCH_HDM_DATA_ERROR, FETCH_HDM_DATA_SUCCESS } from "../action-types/hdmData";
import { WISHLIST_GET_ERROR, WISHLIST_GET_SUCCESS } from "../action-types/wish-list";

const backdropLoaderReducer = createReducer(initStates.backdropLoader, (builder) => {
  builder.addCase(ACTIONS.OPEN_BACKDROP_LOADER, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = true;
    state.src = action.payload.src;
    state.idx = action.payload.idx;
  });

  builder.addCase(ACTIONS.CLOSE_BACKDROP_LOADER, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(PRODUCT_LIST_GET_SUCCESS, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });
  builder.addCase(PRODUCT_LIST_GET_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(PRODUCT_GET_SUCCESS, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(PRODUCT_GET_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(CART_GET_SUCCESS, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(CART_GET_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(ITEM_ADD_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(ITEM_REMOVE_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(ITEM_UPDATE_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(COPY_CART_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(CART_RESET_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(WISHLIST_GET_SUCCESS, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(WISHLIST_GET_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  builder.addCase(SHIPMODE_UPDATE_ERROR, (state: BackDropLoaderInterface, action: AnyAction) => {
    state.open = false;
    state.src = undefined;
    state.idx = undefined;
  });

  

  
});


export default backdropLoaderReducer;
