/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { palette } from "../../themes";
import { StyledGrid } from "../grid";
import { StyledBox } from "../box";

const StyledRadio = styled(({ ...props }) => <Radio {...props} />)`
  ${({ theme }) => `
    padding: 0;

    &.hidden {
      display: none;
    }
  `}
`;

const StyledUncheckedRadioIcon = styled(({ ...props }) => <RadioButtonUncheckedIcon {...props} />)`
  ${({ theme }) => `
    color: ${palette.gray["100"]} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
`}
`;

const StyledRadioCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 40px;
    align-items: center;

    &.container{
      outline: 1px solid ${theme.palette.secondary.main};
      border-radius: 1px;

      &:hover {
        outline: 1px solid ${theme.palette.primary.main};
        cursor: pointer;
      }
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
      &:hover {
        outline: 1px solid ${theme.palette.success.main};
      }
    }

    &.successIconRadio{
      justify-content: flex-end;
    }
  `}
`;

const StyledRadioCheckedCardContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 40px;
    align-items: center;

    &.container{
      outline: 1px solid ${theme.palette.secondary.main};
      border-radius: 1px;

      &:hover {
        outline: 1px solid ${theme.palette.primary.main};
        cursor: pointer;
      }
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
      &:hover {
        outline: 1px solid ${theme.palette.success.main};
      }
    }

    .successIconRadio{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 25px;
      background-color: ${theme.palette.success.main}
    }

    .styled--check-success {
      color: ${theme.palette.text.expandedMenu}
    }
  `}
`;

export { StyledRadio, StyledUncheckedRadioIcon, StyledRadioCardContainer, StyledRadioCheckedCardContainer };
