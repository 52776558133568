import styled from "styled-components";
import { StyledTextField } from "../../../hdm/elements/text-field";
import { StyledBox } from "../../../hdm/elements/box";
import { StyledButton } from "../../../hdm/elements/button";

import { palette } from "../../../hdm/themes/color-palette";

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.contenedor-cantidad {
    display: flex;
    // height: 50px;
    align-items: flex-end;
    margin-right: 10px;
  }
  .MuiTextField-root .MuiInputBase-root {
    height: 42px;
    margin-top: 0px;
  }
`}
`;

export const ContainerStyledButton = styled(({ ...props }) => <StyledButton {...props} />)`
  ${({ theme }) => `
  
  &.button-less {
    background-color: #ffffff;
    color: #F96302;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    border-radius: 0px;
    max-width: 34px;
    min-width: 34px;
    max-height: 42px;
    min-height: 42px;

  }

  &.button-more {
    background-color: #ffffff;
    color: #F96302;
    border-top: 1px solid ${palette.gray["100"]};
    border-bottom: 1px solid ${palette.gray["100"]};
    border-right: 1px solid ${palette.gray["100"]};
    border-radius: 0px;
    max-width: 34px;
    min-width: 34px;
    max-height: 42px;
    min-height: 42px;
  }

  :disabled {
    color: ${palette.gray["100"]};
    background-color: ${palette.gray["50"]};
    border-top: 1px solid ${palette.gray["100"]};
    border-bottom: 1px solid ${palette.gray["100"]};
    border-right: 1px solid ${palette.gray["100"]};
    border-left: 1px solid ${palette.gray["100"]};
  }
`}
`;

export const ContainerStyledTextField = styled(({ ...props }) => <StyledTextField {...props} />)`
  ${({ theme }) => `

  &.MuiTextField-root .MuiInputBase-input.MuiOutlinedInput-input {
    font-weight: 600;
    width: 57px;
    height: 42px;
    text-align: center;
    font-family: Open Sans,Helvetica Neue,Arial,sans-serif;
    font-size: 15px;
  }
  
  &.MuiFormControl-root.MuiTextField-root {
    margin-bottom: 10px;
  }

  &.textfield-input-cantidad {
    background-color: ${palette.background.paper};
  }

  .MuiInputBase-input.Mui-disabled {
    background-color: ${palette.gray["50"]};
  }
  
`}
`;
