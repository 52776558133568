/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import Button from "@material-ui/core/Button";
import styled from "styled-components";

import { dimensions } from "../../themes/variables";

const searchBarDesktopWidth = dimensions.searchBar.DesktopWidth;
const searchBarMobileWidth = dimensions.searchBar.MobileWidth;
const searchBarHeight = dimensions.searchBar.height;

const StyledSearchBar = styled.div`
  ${({ theme }) => `
  .blank{
    font-weight:400;
  }
  position: relative;
  .searchBar-results {
    position: absolute;
    left: 0;
    width: 100%;
    top:${searchBarHeight}px;
    text-align: left;
    background-color: ${theme.palette.background.paper};
    box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
    z-index:999;


    .MuiListItem-root {
      margin-right: ${theme.spacing(1)}px;
      margin-left: ${theme.spacing(0)}px;
      padding-right: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(2)}px;
      max-height:40px;
      height:40px;
    }
    @media (max-width: 700px){
      .MuiListItem-root {

        padding: ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;

      }
      .MuiMenuItem-root{
          min-height:40px;
        }

    }

  }
  .MuiInputAdornment-positionStart{
      margin:0px;

    }

  .MuiInputAdornment-positionEnd{
    margin:0px;

  }

  .MuiInputBase-root {
    height: ${searchBarHeight}px;
    padding:0px!important;
  }

  .MuiFormControl-root {
    display: flex;
    width:100%;
    flex-basis: 41.666667%;
    flex-shrink: 1;
    margin: 0;

    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }

    .MuiOutlinedInput-input {
      height: ${searchBarHeight}px;
      padding-left: ${theme.spacing(2)}px;
    }
  }
  @media (max-width: 700px){
      .MuiFormControl-root {
      width:${searchBarMobileWidth}px;
      margin: 0;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        height: ${searchBarHeight}px;
        padding-left: ${theme.spacing(2)}px;
      }
    }
  }

  .MuiOutlinedInput-root {
    padding-right: 0;
  }

  .MuiInputBase-input {
    padding-right: 0;
    padding-left: 0;
  }

  &.__searchBar{
    max-width: 600px;
    
    ${theme.breakpoints.down(1023)} {
      max-width: 100%
      ;
    }
    .__search-bar-titles{
      cursor:default;
    }


    input::placeholder {
        color:${theme.palette.gray[200]};
        font-family:${theme.typography.fontFamily};
        font-size: 15px;
      }

    .MuiInputBase-root{
      margin:0px;
      .searchIcon{
        cursor:pointer;
        align-items: center;
        justify-content:center;
        background-color:white;
        width:35px;
        height:40px;
        margin-left:0px;
        padding:auto;
        display:flex;
        .searchbarIcon{
          margin:0px;
          color : ${theme.palette.primary.main};
          width: 25px;
          height: 25px;
        }

      }

      .typingInput{
        cursor:pointer;
        align-items: center;
        justify-content:center;
        background-color: ${theme.palette.primary.main};
        width:35px;
        height:40px;
        margin-left:0px;
        padding:auto;
        display:flex;
        .typingbarInput {
          margin:0px;
          color: white;
          width: 25px;
          height: 25px;
        }
      }

      :hover{
        .MuiOutlinedInput-notchedOutline{
          border-color:${theme.palette.gray[200]};
        }
      }

      &.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
          border: 1px solid ${theme.palette.primary.main};
        }
      }

    }

  }

  &.proheader-searchbar-backgroundcolor {
    background-color: ${theme.palette.background.paper};
  }

  & .checkout--search-icon {
    background-color: ${theme.palette.primary.main};
    cursor: pointer;
    height: 100%;
    max-height: 100%;
    
    svg {
      color: ${theme.palette.background.paper}
    }
  }

  `}
`;

export { StyledSearchBar };

export const StyledSearchBarButton = styled(({ ...props }) => <Button {...props} />)`
  ${({ theme }) => `
    display: flex;
    width: ${theme.spacing(4.5)}px;
    height: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down("sm")} {
      min-width: ${theme.spacing(3)}px;
      height: auto;
    }

    .MuiSvgIcon-root {
      color: ${theme.palette.text.secondary};
    }

    &:hover {
      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    }

    &.active {
      background-color: ${theme.palette.action.disabledBackground};

      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    }

    &:focus {
      outline: none;
    }
  `}
`;
