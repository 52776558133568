
import { StyledAccordion, StyledGrid, StyledContainer } from "../../../../../hdm";

import { StyledSkeleton } from "../StyledSkeleton";
import { useMediaQuery } from "@material-ui/core";
import { tabletBreakpoint } from "../../../../../hdm/functions/utils";
import theme from "../../../../../hdm/themes/thd";
export const SLFindStoreComponent = () => {
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

    return (
        <>
        <StyledContainer>
            <StyledGrid item xs={12} id="sofff">
                <StyledGrid item xs={12}>
                    <StyledSkeleton  noCard variant="rect" className={"wide bottom-margin-1"} height={45}/>
                </StyledGrid>
                <StyledAccordion>
                    <StyledGrid container className={ isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
                        <StyledGrid item xs={3} spacing={2}>
                            <StyledSkeleton variant="rect" className={"wide bottom-margin-3"} height={40} />
                            <StyledSkeleton variant="rect" className={"bottom-margin-3"} width={140} height={40} />

                            <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={400} />
                            <StyledSkeleton variant="rect" className={"wide"} height={3}  />
                            <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={400} />
                            <StyledSkeleton variant="rect" className={"wide"} height={3}  />
                            <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={400} />
                            <StyledSkeleton variant="rect" className={"wide"} height={3}  />
                        </StyledGrid>
                        <StyledGrid container justifyContent="flex-end" item xs={9}>
                            <StyledSkeleton variant="rect" className={"wide left-margin-1"} height={1300}/>
                        </StyledGrid>
                    </StyledGrid>
                </StyledAccordion>
            </StyledGrid>
        </StyledContainer>

        </>

    );
}
