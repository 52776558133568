import { useState, useEffect, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperClass from "swiper";
import ReactPlayer from "react-player/youtube";
import { Pagination, Navigation } from "swiper";

import { StyledSwiper, CarouselChip, StyledBulletPagination } from "./containers-informacion-adicional";
import { StyledTypography, StyledCardMedia, StyledBox, StyledButton } from "../../elements";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

// import "./styles.css";

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import { SmartDisplaySvg } from "../../components/svg-icons";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  videos: any;
}
const VideoPlayer = (props: Props) => {
  const { videos } = props;
  const [activeIndex, setIsActiveIndex] = useState(1);
  const [playingVideo, setIsPlayingVideo] = useState<any>(null);
  const swiperRef = useRef<SwiperClass>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onSlideChangeHandler = () => {
    const index = swiperRef?.current?.realIndex ? swiperRef.current.realIndex : 0;
    setIsPlayingVideo(null);
    setIsActiveIndex(index + 1);
  };

  const handleClickPreviwe = () => {
    const index = swiperRef?.current?.realIndex ? swiperRef.current.realIndex : 0;
    setIsPlayingVideo(videos[index]);
  };

  const handleOnPause = () => {
    // setIsPlayingVideo(null)
  };

  const handleOnEnded = () => {
    setIsPlayingVideo(null);
  };

  const handlePlayVideo = (video) => {
    setIsPlayingVideo(video);
  };

  const handleError = (videoUrl: string) => {
    if(!ReactPlayer.canPlay(videoUrl)) throw new Error("Couldn't load video url");
  }

  if (videos.length === 0) return null;

  return (
    <>
      <CarouselChip
        size="medium"
        className="carrousel-chip-total-images"
        label={
          <StyledTypography variant="bodyBaseline">
            {activeIndex}/{videos.length}
          </StyledTypography>
        }
      />
      <StyledSwiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
        slidesPerView={1}
        pagination={{
          el: ".swiper-pagination-videos",
          type: "bullets",
          clickable: true,
          renderBullet: function (index, className) {
            console.log("index", index);
            return '<span class="' + className + '"></span>';
          },
        }}
        navigation={{
          nextEl: ".swiper-button-nextx",
          prevEl: ".swiper-button-prevx",
        }}
        modules={[Pagination, Navigation]}
        onSlideChange={onSlideChangeHandler}
        style={{ height: "auto" }}>
        {videos &&
          videos.map((video, index) => {
            const videoId = video.attachmentAssetPath.split("v=")[1].split("&")[0];
            let light = true;

            if (playingVideo && playingVideo.attachmentAssetPath === video.attachmentAssetPath) {
              light = false;
            }

            return (
              <SwiperSlide key={index}>
                  <>
                    <ReactPlayer
                      url={video.attachmentAssetPath}
                      playing={!light}
                      controls
                      width={"100%"}
                      light={light}
                      playIcon={<SmartDisplaySvg />}
                      onClickPreview={handleClickPreviwe}
                      onPause={handleOnPause}
                      onEnded={handleOnEnded}
                      onError={() => handleError(video.attachmentAssetPath)}
                    />
                  </>
              </SwiperSlide>
            );

            if (playingVideo && playingVideo.attachmentAssetPath === video.attachmentAssetPath) {
              return (
                <SwiperSlide key={index}>
                  <ReactPlayer
                    url={video.attachmentAssetPath}
                    // playing={true}
                    controls
                    width={"100%"}
                    height={"clac(100%)"}
                  />
                </SwiperSlide>
              );
            }
            return (
              <SwiperSlide key={index}>
                <StyledCardMedia
                  image={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
                  title={"video"}
                  // className={"promo_plp-ribbon-hv"}
                  style={{ width: "100%", margin: "0px" }}
                  component="img"
                  onClick={() => handlePlayVideo(video)}
                />
              </SwiperSlide>
            );
          })}
      </StyledSwiper>

      <StyledBulletPagination display="flex" flexDirection="row" justifyContent="center" alignItems="center" my={4}>
        {!isMobile && (
          <StyledBox>
            <StyledButton
              size="small"
              variant="default"
              color="secondary"
              className="swiper-button-prevx buttton-color">
              <KeyboardArrowLeft /> Anterior
            </StyledButton>
          </StyledBox>
        )}
        <StyledBox mx={10}>
          <div style={{ minWidth: "32px" }} className="swiper-pagination-videos" />
        </StyledBox>
        {!isMobile && (
          <StyledBox>
            <StyledButton
              size="small"
              variant="inherit"
              color="secondary"
              className="swiper-button-nextx buttton-color">
              Siguiente
              <KeyboardArrowRight />
            </StyledButton>
          </StyledBox>
        )}
      </StyledBulletPagination>
    </>
  );
};

export default VideoPlayer;
