import styled from "styled-components";
import { StyledBox } from "../../../hdm";

export const StyledTooltipContainer = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  .MuiInputBase-root {
    padding-right: 0px !important;
  }

  .styledTooltipHdm-tooltip-icon{
      color: ${theme.palette.primary.light};
      width: 14px;
      height: 14px;
      margin-top:  ${theme.spacing(1)}px;
      margin-right:  ${theme.spacing(1)}px;
  }
  .styledTooltipHdm-tooltip-button{
    // margin: ${theme.spacing(3)}px;
    padding: 0px;
  }
`}
`;
