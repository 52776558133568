
// Standard libraries
import React, { useEffect, useState } from "react";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
//redux
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import { RestorePageOutlined } from "@material-ui/icons";


export const useJsonEspot: any = (eSName:string[]) => {

  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const [jsonData, setJsonData] = useState<any>([]);

  let isMounted = true;

  useEffect(()=>{
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  },[])

  useEffect(()=>{
    if(storeID !== "" && catalogID !==""){
      const responses = eSName.map((espot)=> {
        return initESpot(espot)
      })

      Promise.all(responses)
        .then((jsons)=>{
          const mktexts =  jsons.map((data)=>getJSON(data))
          setJsonData(mktexts)
        })

      const res = initESpot(eSName[0]);
      res.then((data)=>
        getJSON(data)
      )
    }
    return () => { isMounted = false }
  },[storeID,catalogID])

  function getJSON(data){
    if( data?.baseMarketingSpotActivityData &&
    data?.baseMarketingSpotActivityData.length > 0 &&
    data?.baseMarketingSpotActivityData[0].marketingContentDescription &&
    data?.baseMarketingSpotActivityData[0].marketingContentDescription.length > 0 &&
    data?.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText){
      try{
        return {
          espot: data.eSpotName, 
          content:JSON.parse(data.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
        }
      }catch{
        return {
          espot: data.eSpotName, 
          content:data.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText
        }
      }
    }

    return {};
  }

  const initESpot = async (pageName: string) => {
    const parameters: any = {
      storeId: storeID,
      name: pageName,
      catalogId: catalogID,
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    let eSpotRoot;
    try {
      const res = await eSpotService.findByName(parameters);
      eSpotRoot = res.data.MarketingSpotData[0];
    } catch (e) {
      eSpotRoot = null;
    }
    return eSpotRoot;
  };

  return jsonData;
};

  /**
 * A high order component that wraps a component needs processed eSpot data.
 * @param Component the wrapping component.
 * @returns A component that has ability to process eSpot data.
 */
export const withUseJsonESpots =
(Component: React.ComponentType<any>, eSName:string[]): React.FC =>
() => {
  const responses = useJsonEspot(eSName);
    return <Component  data={responses}></Component>;
};


