import styled from "styled-components";
import { StyledTextField } from "../../elements/text-field";
import { StyledGrid, StyledChip } from "../../elements";
import { StyledButton } from "../../elements/button";
import { StyledAvatar } from "../../elements/avatar";
import { Box } from '@material-ui/core'
import { StyledTypography, StyledBox } from "../../elements";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RoomIcon from "@material-ui/icons/Room";
import { Swiper } from "swiper/react";

import { palette } from "../../themes/color-palette";

export const ContainerInformacionAdicional = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .container-informacion {   
    margin-bottom: 10px;
  }

  .titulo-informacion {
    margin-bottom: 10px;
  }

  .descripcion-informacion {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }

  .link-mas-informacion {
    cursor: pointer;
    margin-top: 10px;
    color: ${palette.secondary.main}
  }

  .drawer-container {
    margin: 10px;
  }

  .informacion-modelo {
    color: ${palette.gray["200"]};
    margin-right: 15px;
  }

  .informacion-sku {
    color: ${palette.gray["200"]};
    margin-right: 15px;
  }

  .descripcion-larga {
    margin-bottom: 10px;
  }

  .informacion-legal {
    margin-bottom: 10px;
    color: ${palette.gray["200"]};
  }

  .modelo-sku {
    margin-bottom: 10px;
  }

  .informacion-spot {
    margin-bottom: 10px;
  }

`}
`;

export const ContainerDescargable = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .descargable-seccion-container {
    margin-bottom: 10px;
  }

  .descargable-seccion {
    margin-bottom: 10px;
  }

  .descargable-card {
    margin-bottom: 10px;
    border: 1px solid ${palette.gray["50"]};
  }

  .descargable-container {
    padding-left: 5px;
    display: flex;
    height: 40px;
    align-items: center;
  }

  .download-container {
    display: flex;
    height: 30px;
    padding: 5px;
    padding-left: 10px;
    align-items: center;
    background-color: ${palette.gray["50"]};
  }

  .download {
    color: ${palette.secondary.main};
    text-decoration: underline;
    cursor: pointer;
  }

  .download-icon {
    margin-right: 10px;
    font-size: 13px;
    color: ${palette.secondary.main};
  }

  .desciption-icon {
    margin-right: 10px;
    font-size: 20px;
    color: ${palette.primary.light};
  
  }

`}
`;

export const CarouselChip = styled((props: any) => <StyledChip {...props} />)`
  ${({ theme }) => `

    &.chip-hidden{
        display: none;
    }

    &.carrousel-chip-total-images{
        z-index: 2;
        position: absolute;
        right: ${theme.spacing(4)}px;
        background-color: ${theme.palette.gray["200"]}60;
        color: ${theme.palette.primary.contrastText};

        & .chip-close-icon{
            display: flex;
        }
    }

    `}
`;

export const StyledSwiper = styled((props: any) => <Swiper {...props} />)`
  ${({ theme }) => `

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
      bottom: 0;
      position: relative;
      padding-top: ${theme.spacing(4)}px;
      background-color: #e4e4e4;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    /*.swiper {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }*/
    
    /*.swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
    
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }*/
  
`}
`;

export const StyledBulletPagination = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .buttton-color {
        &.MuiButton-root {
            color: ${theme.palette.secondary.main} !important;

        }
    }

`}
`;