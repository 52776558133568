/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import { StyledIconLabel } from "../icon-label";
import { StyledPaper } from "../paper";
import { StyledButton } from "../button/styled-button";
import { StyledGrid } from "../grid";
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { ChevronRightSharp } from "@material-ui/icons";
import { palette } from "../../themes";

const StyledButtonBoxWrapper = styled(({ ...props }) => <StyledPaper {...props} />)`
  ${({ theme }) => `
    display: flex;
    padding: ${theme.spacing(2)}px;
    min-height: 151px;
    cursor: pointer;

    ${theme.breakpoints.down("sm")} {
      min-height: unset
    }


    .grid-container-arrow-add-payment-card {
      display: flex;
      justify-content: end
    }

    ${theme.breakpoints.up(961)} {
      .grid-container-arrow-add-payment-card {
        display: none;
      }
    }

    &.disabled {
      h6, p {
        color: ${theme.palette.text.disabled};
      }

      .MuiSvgIcon-root {
        color: ${theme.palette.text.disabled};
      }
    }

    ${theme.breakpoints.up(961)} {
      .container-button-add-payment-card {
        justify-content: center;
      }
    }

    .add-button-container{
      max-height: 197px;
    }

    .styled-chevron-icon {
      color: ${theme.palette.primary.main};
      height: 12px;
    }
  `}
`;

interface ButtonBoxProps {
  msg: string;
  icon: any;
  button: string;
  disabled?: boolean;
  buttonAction?: any;
  testId: string;
  paddingTopButton?: string;
  paddingLeftButton?: string;
  expiredCard?: boolean;
  className?: string;
  isMobile?: boolean;
}

export function StyledButtonBox({
  msg,
  icon,
  button,
  disabled,
  buttonAction,
  testId,
  paddingTopButton,
  paddingLeftButton,
  expiredCard,
  className
}: ButtonBoxProps) {
  return (
    <StyledButtonBoxWrapper
      className={`${disabled ? "disabled container-button-box-wrapper" : "container-button-box-wrapper"} ${className}`}
      onClick={buttonAction}>
      <StyledGrid
        container
        className={`container-button-add-payment-card`}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={expiredCard ? { minHeight: "180px" } : {}}>
        <StyledIconLabel
          icon={icon}
          label={msg}
          variant="bodyBaseline"
          paddingTopText={paddingTopButton}
          paddingLeftText={paddingLeftButton}
        />
        {buttonAction && (
          <StyledGrid item xs={2} sm={2} className={"grid-container-arrow-add-payment-card"}>
            <ChevronRightSharp className="styled-chevron-icon" />
            {/*<StyledButton color="primary" size="small" fullWidth onClick={buttonAction} testId={testId}>
              {button}
            </StyledButton>*/}
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledButtonBoxWrapper>
  );
}
