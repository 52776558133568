/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const INVENTORY_STATUS = {
  available: "Available",
  allocated: "Allocated",
  backordered: "Backordered",
  unallocated: "Unallocated",
};

export const PAYMENT = {
  policies: {
    COD: true,
    MasterCard: true,
    VISA: true,
    AMEX: true,
    oxxo: true,
    giftCard: true,
    openPay: true,
    paypal: true,
    creditAr: true,
  },
  paymentMethodName: {
    cod: "PayInStore",
    mc: "MasterCard",
    visa: "VISA",
    amex: "AMEX",
    carnet: "CARNET",
    oxxo: "Oxxo",
    giftCard: "GiftCard",
    openPay: "OpenPay",
    paypal: "PayPal",
    master: "Master Card",
    creditAr: "CreditAR"
  },
};

export const RECURRING_ORDER_OPTIONS = [
  {
    key: "EVERYDAY",
    value: "0",
    fulfillmentInterval: "1",
    fulfillmentIntervalUOM: "DAY",
    translationKey: "CommerceEnvironment.recurringOrderFrequency.Everyday",
  },
  {
    key: "EVERYWEEK",
    value: "1",
    fulfillmentInterval: "1",
    fulfillmentIntervalUOM: "WEE",
    translationKey: "CommerceEnvironment.recurringOrderFrequency.EveryWeek",
  },
  {
    key: "EVERYTWOWEEKS",
    value: "2",
    fulfillmentInterval: "2",
    fulfillmentIntervalUOM: "WEE",
    translationKey: "CommerceEnvironment.recurringOrderFrequency.EveryTwoWeeks",
  },
  {
    key: "EVERYTHREEWEEKS",
    value: "3",
    fulfillmentInterval: "3",
    fulfillmentIntervalUOM: "WEE",
    translationKey: "CommerceEnvironment.recurringOrderFrequency.EveryThreeWeeks",
  },
  {
    key: "EVERYFOURWEEKS",
    value: "4",
    fulfillmentInterval: "4",
    fulfillmentIntervalUOM: "WEE",
    translationKey: "CommerceEnvironment.recurringOrderFrequency.EveryFourWeeks",
  },
];

export const SHIPMODE = {
  shipModeCode: {
    PickUp: "PickupInStore",
    Mail: "Mail",
  },
};

export const EXPIRY = {
  MONTHS: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  YEARS: ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031"],
};

export const PO_NUMBER = "poNumber";

export const ORDER_STATUS = {
  BackOrdered: "B",
  Submitted: "I",
  Approved: "M",
  NoInventory: "L",
  ApprovalDenied: "N",
  PendingOrder: "P",
  Released: "R",
  Shipped: "S",
  ShippedSE: "SE",
  ShippedER: "ER",
  PendingApproval: "W",
  Canceled: "X",
};

export const SHIPPING_METHOD = {
  Delivery: "2",
  Bopis: "1",
}

export const COOKIES_PAYMENT = {
  cookieName: "WC_Payment_Content",
  paymentMethodName: {
    PayAtStore: "PayAtStore",
    mc: "Cybersource",
    VISA: "Cybersource",
    amex: "Cybersource",
    carnet: "Cybersource",
    Oxxo: "OXXO",
    giftCard: "GiftCard",
    OpenPay: "Transfer",
    PayPal: "PaypalExpress",
    master: "Cybersource",
    pointsBbva: "PuntosBBVA",
  },
};

export const ACCOUNT_CC = "account";
export const CC_CVC = "cc_cvc";
export const EXPIRE_MONTH = "expire_month";
export const EXPIRE_YEAR = "expire_year";
export const ACCOUNT_FOR_VIEW_CC = "accountForView";
export const PRIVATE_ORDER_TYPE = "ORD";
export const SHARRED_ORDER_TTYPE = "SHR";

export const RESOURCE_NAME_CART = "cart";
export const N_ITEMS_ADDED = "addedNSuccessfully";
export const DROPSHIP_ONLY_SELLING_STORE = "DROPSHIP_ONLY_SELLING_STORE";
