import styled from "styled-components";
import { StyledGrid } from "../../../hdm/elements";
const StyledPopperMenuContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .popper-container{
    margin-top: ${theme.spacing(1)}px;
    z-index: 10;

    .MuiMenuItem-root{
      min-height: 30px;
    }
  }
  .popper--menuList-container{
    border-top: 2px solid  ${theme.palette.primary.main};
    padding-top: 0px;
    padding-bottom: ${theme.spacing(1)}px;
    max-height: 285px;
    min-width: 140px;
    overflow: auto;
    li{
      margin-top: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(1)}px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &.popper--cart-container {
      width: 195px;
      border-top: none;
      border-bottom: 2px solid  ${theme.palette.primary.main};
    }
    &.popper--cart-container-product {
      max-height: 165px;
    }
  }
`}
`;
export { StyledPopperMenuContainer };
