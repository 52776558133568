import styled from "styled-components";
import { StyledGrid } from "../../../hdm";

export const StyledPrompContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    
  .styledPromp--styledGrid-descriptionContainer{
    display: flex;
    padding: ${theme.spacing(1)}px;
  }

  &.styledPromp--styledGrid-checkout .styledPromp--styledGrid-descriptionContainer {
    padding: ${theme.spacing(1) + 1}px;
  }

  .styledPromp-styledGrid-alert{
    background-color: ${theme.palette.alert.light};
    border: 1px solid  ${theme.palette.alert.main};
  }

  .styledPromp-styledGrid-checkout-alert{
    background-color: ${theme.palette.alert.light};
    border: 1px solid  ${theme.palette.alert.main};
  }


  .styledPromp-styledGrid-gray{
    background-color: ${theme.palette.gray["50"]};
  }

  .styledPromp-styledGrid-error{
    background-color: ${theme.palette.error.light};
    border: 1px solid  ${theme.palette.error.main};
    align-items: center;
  }

  .styledPromp-styledGrid-success{
    background-color: ${theme.palette.highlight.light};
    border: 1px solid  ${theme.palette.highlight.main};
  }

  .styledPromp--typography-shippingGray{
    color: ${theme.palette.text.alert};
  }

  .styledPromp--styledGrid-descriptionContainer-b{
    align-items: flex-start;
  }

  .styledPromp--icon-highlight{
    filter: invert(40%) sepia(37%) saturate(6720%) hue-rotate(132deg) brightness(101%) contrast(101%);
    margin-right: ${theme.spacing(1)}px;
  }

  .styledPromp--icon-infoIcon{
    color: ${theme.palette.alert.main};
    width: 14px;
    height: 14px;
  }
  .styledPromp--icon-infoIcon-cart{
    color: ${theme.palette.alert.main};
    width: 14px;
    height: 14px;
  }

  .styledPromp--icon-blockIcon{
    color: ${theme.palette.error.main};
    margin-right: ${theme.spacing(1)}px;
    width: ${theme.spacing(2)}px;
    height: ${theme.spacing(2)}px;
  }

  .styledPromp--styledGrid-descriptionContainer-b .styledPromp--icon-blockIcon{
    margin-top: 2px;
  }

  .styledPromp--icon-CheckCircle{
    color: ${theme.palette.success.main};
    margin-right: ${theme.spacing(1)}px;
    width: ${theme.spacing(3)}px;
    height: ${theme.spacing(3)}px;
  }

  .styledPromp--styledGrid-messageContainer{
    align-self: center;
  }
`}
`;
