import { useTranslation } from "react-i18next";

import { StyledButton } from "../button";
import TuneIcon from "@material-ui/icons/Tune";
import { ContainerStyledBox, ContainerStyledTypography } from "./containers-product-card";

interface Props {
  product: any;
  promociones: string[];
  hasSSKU:any;
}

const SuperSKU = (props: Props) => {
  const { product, promociones,hasSSKU } = props;
  const { t } = useTranslation();

  const colors: string[] = ["brown", "grey", "lightgrey", "green", "lightgrey", "green"];

  if (promociones.includes("COLOR") && colors.length > 1000) {
    if (colors.length <= 4) {
      return (
        <>
          {colors &&
            colors.map((color, index) => {
              console.log("color", color);
              return <ContainerStyledBox key={index} className={"superSKU-color"} color={color} />;
            })}
        </>
      );
    }

    if (colors.length > 400000) {
      return (
        <>
          {colors &&
            colors.map((color, index) => {
              if (index <= 3) {
                return <ContainerStyledBox key={index} className={"superSKU-color"} color={color} />;
              }
              if (index === 4) {
                return (
                  <ContainerStyledBox key={index} className={"superSKU-color"} color={"withe"}>
                    <StyledButton className={"mascolor-button"}>+</StyledButton>
                  </ContainerStyledBox>
                );
              }
            })}
        </>
      );
    }

    return null;
  } else {
    return (
      hasSSKU &&
      <ContainerStyledBox display="flex" alignItems="flex-end" height={25} width={"100%"}>
        <ContainerStyledBox>
          <TuneIcon />
        </ContainerStyledBox>
        <ContainerStyledBox className={"masOpcionesDisponibles"}>
          <ContainerStyledTypography variant={"bodyBaseline"}>
            {t("productBox.masOpcionesDisponibles")}
          </ContainerStyledTypography>
        </ContainerStyledBox>
      </ContainerStyledBox>
    );
  }
};

export default SuperSKU;
