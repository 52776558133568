import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import { StyledBox, StyledButton, StyledLinkTextSecondary, StyledTypography } from "../../elements";

import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Divider } from "@material-ui/core";

const ContainerDialogEliminar = styled((props) => <Dialog {...props} />)`
  ${({ theme }) => `
& svg{
  font-size: 20px;
}

&.dialog-eliminar-direccion {

  .MuiPaper-root.MuiDialog-paper {
    max-width: 400px;
  }

}

.styled--closeicon-container {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.styled--title-center {
  text-align: center;
}

.styled--close-icon {
  color: ${theme.palette.gray["100"]}
}

.styled--cancel-buttom {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: ${theme.palette.secondary.main}
}

.styled--actionbutons-container {
  padding: 10px;
}

.styled--sub-title {
  margin-bottom: 10px;
}

`}
`;

export const ModalSimpleDelete = (props) => {
  const { openElinarDialog, handleCloseEliminar, handleAEliminarAction, data, title, subtitle, buttonTitle } = props;

  return (
    <ContainerDialogEliminar
      open={openElinarDialog}
      onClose={handleCloseEliminar}
      fullWidth={false}
      maxWidth={"xs"}
      className="dialog-eliminar-direccion"
      disableScrollLock={true}>
      <StyledBox p={2}>
        <StyledTypography variant="textSegment" className="styled--title-center">
          {title}
        </StyledTypography>
        <StyledBox className="styled--closeicon-container" onClick={handleCloseEliminar}>
          <CancelOutlinedIcon className="styled--close-icon" />
        </StyledBox>
      </StyledBox>

      <StyledBox className="bottom-margin-2">
        <Divider />
      </StyledBox>

      <StyledBox className="styled--actionbutons-container">
        <StyledTypography variant="textSegment" className="styled--sub-title">
          {subtitle}
        </StyledTypography>
        <StyledButton variant="outlined" onClick={() => handleAEliminarAction(data)}>
          {buttonTitle}
        </StyledButton>
        <StyledLinkTextSecondary className="styled--cancel-buttom" onClick={handleCloseEliminar}>
          Cancelar
        </StyledLinkTextSecondary>
      </StyledBox>
    </ContainerDialogEliminar>
  );
};
