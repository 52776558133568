import { ClickAwayListener, Grow, MenuItem, MenuList, Popper } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledPaper, StyledTypography } from "../../../hdm";
import { StyledPopperMenuContainer } from "./StyledPopperMenuContainer";

const StyledPopperMenu = ({ ...props }) => {
  const { t } = useTranslation();
  const { anchorRef, setMenuListOpen, menuListOpen, MenuItems, prevOpen, placement, variant } = props;


  React.useEffect(() => {
    prevOpen.current = menuListOpen;
  }, [menuListOpen]);
  return (
    <StyledPopperMenuContainer>
      <Popper
        className={"popper-container"}
        open={menuListOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "right top",
            }}>
            <StyledPaper>
              <StyledGrid>
                <ClickAwayListener onClickAway={() => setMenuListOpen(false)}>
                  <MenuList
                    autoFocusItem={menuListOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    className={`popper--menuList-container 
                      ${variant === "checkout-orderSummary" ? "popper--cart-container" : variant === "checkout" ? "popper--cart-container-product" : "" }`}>
                    <MenuItems/>
                  </MenuList>
                </ClickAwayListener>
              </StyledGrid>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </StyledPopperMenuContainer>
  );
};
export { StyledPopperMenu };
