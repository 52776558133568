import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
  Span
} from "../../containers-precio-promocion";

interface Props {
  promoribbon: string;
  nXm: any;
  urlRibbons: any;
}

export const NporM = (props: Props) => {
  const { promoribbon, nXm, urlRibbons } = props;
  // return null

  return (
    <>
      <ContainerStyledBox className="ahorra-mas-subcontainer">
        <ContainerStyledBox className="promoicon-container">
          <ContainerStyledCardMedia
            image={urlRibbons[promoribbon]}
            className="promo_plp-ribbon-nxms"
            title="NxM"
            component="img"
          />
          <ContainerStyledBox ml={1}>
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold">
              Obtén 1 pieza gratis
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerStyledBox>
      <ContainerStyledBox>
        <ContainerStyledTypography variant="bodySubText" component="span">
          Por cada&nbsp;
        </ContainerStyledTypography>
        <ContainerStyledTypography variant="bodySubText" weight="semibold" component="span">
          {nXm.baseQty}&nbsp;
        </ContainerStyledTypography>
        <ContainerStyledTypography variant="bodySubText" component="span">
          piezas que agregues a tu carrito
          <sup>
            <Span>†</Span>
          </sup>
        </ContainerStyledTypography>
      </ContainerStyledBox>
    </>
  );
};
