import React, { FC } from "react";
import styled from "styled-components";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";

// Icons
import "./VisualSupport.css";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ViewInArIcon } from "../../../assets/homedepot/images/viewInArBlack.svg";
import { ReactComponent as CircleIcon } from "../../../assets/homedepot/images/circleIcon.svg";

interface IVisualSupportProps {
  ArApiKey: string;
  isArEnabled: boolean;
  visualSupportSlideHandler?: (value: string) => void;
  isMobileView?: boolean;
  SKU?: string;
}

const StyledVisualSupportContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    .visual-links-container{
        display: flex;
        align-items: center;

        & p{
            margin-left: ${theme.spacing(1)}px;
            color: ${theme.palette.secondary.main};
            cursor: pointer;
                &:focus{
                    color: ${theme.palette.primary.main};
                }
        }
    }

    .ar-mobile-container{
        width: 100%;
    }

    .svg-container{
        width: fit-content;
        padding: ${theme.spacing(1)}px;
        box-shadow: 0 3px 6px ${theme.palette.gray["200"]};
        border-radius: 50%;
        display: flex;
    
        & svg{
            font-size: 25px;
            color: ${theme.palette.primary.main};
            }
    }

    }
    `}
`;

const VisualSupport: FC<IVisualSupportProps> = ({
  ArApiKey,
  isArEnabled,
  visualSupportSlideHandler,
  isMobileView = false,
  SKU = "",
}) => {
  return isArEnabled ? (
      <StyledVisualSupportContainer container spacing={2} className="visual-support">
        <StyledGrid item xs={7} md={6} className="visual-links-container align-right-desktop">
          <StyledTypography
            tabindex="0"
            style={{ display: "inherit", alignItems: "center" }}
            onClick={() => visualSupportSlideHandler?.("qr-view")}
            id={isMobileView ? "cora-ar-3d-viewer" : "realidadAumentada"}
            data-sku={SKU}
            data-token={ArApiKey}
            lang="es"
            hidelogo="">
            <StyledBox className="svg-container" marginRight={"5px"}>
              <SvgIcon>
                <ViewInArIcon />
              </SvgIcon>
            </StyledBox>
            {"Realidad aumentada"}
          </StyledTypography>
        </StyledGrid>

        <StyledGrid item xs={5} md={6} className="visual-links-container hide-view">
          <StyledTypography
            tabindex="0"
            style={{ display: "inherit", alignItems: "center" }}
            onClick={() => visualSupportSlideHandler?.("ar-view")}
            id={isMobileView ? "cora-ar-3d-viewer" : "vista360"}
            data-sku={SKU}
            data-token={ArApiKey}
            lang="es"
            hidelogo="">
            <StyledBox className="svg-container" marginRight={"5px"}>
              <SvgIcon>
                <CircleIcon />
              </SvgIcon>
            </StyledBox>
            {"Vista 360°"}
          </StyledTypography>
        </StyledGrid>
      </StyledVisualSupportContainer>
  ) : null;
};

export default VisualSupport;
