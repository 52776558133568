import React, { FC, ChangeEvent } from "react";
import { StyledBox, StyledTypography } from "../../../hdm/elements";
import styled from "styled-components";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, IconButton } from "@material-ui/core";
// Icon
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from "react-i18next";


interface IPagination {
    pageCountTotal?: any;
    selectedPage?: any;
    onPageChange: any;
}

const useStyles = makeStyles({
    ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex"
    }
});


const PaginatorWrapper = styled((props: any) => <StyledBox {...props} />)`
${({ theme, children }) => `
    &.wrapper{
        display: flex;
        justify-content: center;
        margin-bottom: ${theme.spacing(4)}px;
        margin-top: ${theme.spacing(4)}px;
    }
        & ul{
            display: flex;
            padding: 0;
            flex-wrap: wrap;
            align-items: center;

            & li{
                margin: 0 3px !important;

                & .numbered-button{
                    &:hover{
                        border: 1px solid ${theme.palette.primary.main} !important;
                    }
                }

                & .arrow-button{
                    color: ${theme.palette.secondary.main};
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    `}
`;

const Paginationhdm: FC<IPagination> = ({ pageCountTotal, onPageChange, selectedPage }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const { items } = usePagination({
            count: pageCountTotal,
            onChange: (event: ChangeEvent<unknown>, value: number) => onPageChange(value),
            hidePrevButton: selectedPage === 1 ? true : false,
            hideNextButton: selectedPage === pageCountTotal ? true : false,
            siblingCount: 1,
            boundaryCount: 1,
            page: selectedPage
        });



    return (
        <PaginatorWrapper className="wrapper">
            <ul className={classes.ul}>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children:any = null;
                    
                    if (pageCountTotal > 7 && page === pageCountTotal && items[index-1].type === "end-ellipsis"){return"";}
                    
                    if (type === "start-ellipsis" ) {
                        children = "…";
                    } else if ( type === "end-ellipsis" ) {
                        children = (
                            <StyledTypography variant="bodyBaseline" class="color-gray200">
                                {t("Pagination.to")} {pageCountTotal}
                            </StyledTypography>
                        );
                    }
                    else if (type === "page") {
                        children = (
                            <IconButton
                                className="numbered-button"
                                style={{
                                    fontWeight: selected ? "bold" : undefined,
                                    backgroundColor: selected ? "#F96302" : "white",
                                    minWidth: 25,
                                    width: 25,
                                    height: 25,
                                    fontSize: 15,
                                    border: selected ? "1px solid #F96302"  : "1px solid #CCCCCC",
                                    color: selected ? "white" : "#3E7697",
                                    borderRadius: "50%",
                                }}
                                {...item}
                            >
                                {page}
                            </IconButton>
                        );
                    } else {
                        children = (
                            <IconButton
                                className="arrow-button"
                            {...item}>
                                {type === "previous" ?
                                    <>
                                        <ChevronLeftIcon/>
                                        <Hidden xsDown>
                                            <StyledTypography variant="bodyBaseline">
                                                {t("Pagination.before")}
                                            </StyledTypography>
                                        </Hidden>
                                    </>
                                    :
                                    <>
                                        <Hidden xsDown>
                                            <StyledTypography variant="bodyBaseline">
                                                {t("Pagination.next")}
                                            </StyledTypography>
                                        </Hidden>
                                        <ChevronRightIcon/>
                                    </>
                                }
                            </IconButton>
                        );
                    }
                    return (
                        <li key={index} style={{ margin: "auto 0" }}>
                            {children}
                        </li>
                    );
                })}
            </ul>
        </PaginatorWrapper>
    );
};

export default Paginationhdm;
