
import { StyledBox, StyledTypography } from '../../elements'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import styled from 'styled-components'
import { Divider } from '@material-ui/core';

const StyledDrawerTitle = styled(({...props}) => <StyledBox {...props} />)`

${({theme}) => `
    
    .styled--drawer-title {
        padding: 10px;
    }
    
    .styled--closebutton-left {
        position: absolute;
        left: 0px;
        top: 0px;
        padding: 10px;
    }

    .styled--closebutton-right {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 10px;
    }
    
    .styled--drawer-closebutton {
        color: ${theme.palette.gray["100"]}; 
        cursor: pointer;
    }

    .styled--divider-container {
        margin-bottom: 10px;
    }

`}
`


export const DrawerTitle = (props) => {

    const { handleCloseDrawer, title , closeButtonAlign } = props

    let position = 'styled--closebutton-left'

    if(closeButtonAlign === 'right'){
        position = 'styled--closebutton-right'
    }
    
    return (
        <StyledDrawerTitle>

            <StyledBox className="styled--drawer-title" >
                <StyledBox className={`${position}`} >
                    <CancelOutlinedIcon className="styled--drawer-closebutton" onClick={handleCloseDrawer} />
                </StyledBox>
                <StyledTypography variant="textSegment" align="center">
                    {title}
                </StyledTypography>
            </StyledBox>
            <Divider className='styled--divider-container' />

        </StyledDrawerTitle>
    )

}