import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import { StyledCardEmpty } from "../../elements/card/styled-card-empty";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledIconButton,
  StyledLinkTextSecondary,
  StyledTextField,
  StyledTooltip,
  StyledTypography,
} from "../../elements";
import { ContainerCompararColor } from "./containers-comparar-color";
import { ClickAwayListener, Divider, InputAdornment } from "@material-ui/core";
import { palette } from "../../themes/color-palette";
import TitleCard from "../../../components/custom-components/styled-title-card/TitleCard";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles, useTheme } from "@material-ui/core/styles";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { lineaGamaColorsRdc, hdmColorsRdc, hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";

import { ColorSelected } from "./ColorSelected";
import { ColorsPaletteDefault } from "./ColorsPaletteDefault";
import { SearchColor } from "./SearchColor";
import { BuyByColorDrawer } from "./BuyByColorDrawer";

import dbColors from "./csvjson.json";
import dbColorsHDM from "../../../pinturashdm.json";
import dbBerelHDM from "./berelhdm.json";

const ContainerBuyByColor = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.styled--buybycolor-container {
    margin-bottom: 10px;
  }

  `}
`;

const ComprarColor = (props) => {
  const { product, hasProductBaseColor, brand, partNumber } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [gamaSelected, setGamaSelected] = useState("");
  const [lineColor, setLineColor] = useState("");
  const [colorCodeSelected, setColorCodeSelected] = useState<any>(null);
  const [paintDataSelected, setPaintDataSelected] = useState<any>(null);
  const [baseSelected, setBaseSelected] = useState<any>(null);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const [gamaColors, setGamaColors] = useState<any>(null);
  const dispatch = useDispatch<Dispatch<any>>();
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);

  useEffect(() => {
    inicializarJson();
    if (hdmLineaGamaColor && hdmLineaGamaColor?.lenght !== 0 && hdmColors && hdmColors?.lenght !== 0) {
      inicializarHDMJson();
      setPaintDataSelected(hasProductBaseColor);
    }
  }, [hdmLineaGamaColor, hdmColors, hasProductBaseColor]);

  useEffect(() => {
    if (hdmDrawerColorData && hdmDrawerColorData?.colorCodeSelected) {
      setColorCodeSelected(hdmDrawerColorData?.colorCodeSelected);
      setGamaSelected(hdmDrawerColorData?.colorCodeSelected?.gama);
    }
  }, [hdmDrawerColorData]);

  const inicializarJson = () => {
    const lineasBerel: any = [];
    const basesBerel: any = [];
    const hueBerel: any = [];

    dbBerelHDM.BEREL.map((berel) => {
      if (!lineasBerel.includes(berel.Linea)) {
        lineasBerel.push(berel.Linea);
      }
      if (!basesBerel.includes(berel.Base)) {
        basesBerel.push(berel.Base);
      }
      if (!hueBerel.includes(berel["Hue "])) {
        hueBerel.push(berel["Hue "]);
      }
    });

    // console.log("dbColors", dbColors);

    // const lineaPremium = dbColors.filter((color) => color.Linea === "Premium");
    // const lineaPremiumPlus = dbColors.filter((color) => color.Linea === "Premium Plus" && color.Base === 'Deep');
    // const professionalAdvantage = dbColors.filter((color) => color.Linea === "Professional Advantage");
    // const ultra = dbColors.filter((color) => color.Linea === "ULTRA");

    // console.log('lineaPremium', lineaPremium, lineaPremium?.length)
    // console.log('lineaPremiumPlus', lineaPremiumPlus, lineaPremiumPlus?.length)
    // console.log('professionalAdvantage', professionalAdvantage, professionalAdvantage?.length)
    // console.log('ultra', ultra, ultra?.length)
    return;
    // const base: any = [];
    // const hue: any = [];
    // lineaPremium.map((color) => {
    //   if (!base.includes(color.Base.trim())) {
    //     base.push(color.Base.trim());
    //   }
    //   if (!hue.includes(color.Hue.trim())) {
    //     hue.push(color.Hue.trim());
    //   }
    // });

    // console.log("base", base, base?.length);
    // console.log("hue", hue, hue?.length);

    // const basePasteles = ultra.filter((color) => color.Base === "Media" && color.Hue === "Pasteles");
    // const baseGrises = ultra.filter((color) => color.Base === "Media" && color.Hue === "Grises");
    // const baseCafes = ultra.filter((color) => color.Base === "Media" && color.Hue === "Cafés");
    // const baseRojos = ultra.filter((color) => color.Base === "Media" && color.Hue === "Rojos");
    // const baseNaranjas = ultra.filter((color) => color.Base === "Media" && color.Hue === "Naranjas");
    // const baseAmarillos = ultra.filter((color) => color.Base === "Media" && color.Hue === "Amarillos");
    // const baseVerdes = ultra.filter((color) => color.Base === "Media" && color.Hue === "Verdes");
    // const baseAquas = ultra.filter((color) => color.Base === "Media" && color.Hue === "Aquas");
    // const baseAzules = ultra.filter((color) => color.Base === "Media" && color.Hue === "Azules");
    // const baseMorados = ultra.filter((color) => color.Base === "Media" && color.Hue === "Morados");

    // const colorPateles: any = [];
    // const colorGrises: any = [];
    // const colorCafes: any = [];
    // const colorRojos: any = [];
    // const colorNaranjas: any = [];
    // const colorAmarillos: any = [];
    // const colorVerdes: any = [];
    // const colorAquas: any = [];
    // const colorAzules: any = [];
    // const colorMorados: any = [];

    // basePasteles.map((color) => {
    //   colorPateles.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });
    // console.log("basePasteles", basePasteles, basePasteles?.length);
    // console.log("colorPateles", colorPateles, colorPateles?.length);

    // baseGrises.map((color) => {
    //   colorGrises.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });
    // console.log("baseGrises", baseGrises, baseGrises?.length);
    // console.log("colorGrises", colorGrises, colorGrises?.length);

    // baseCafes.map((color) => {
    //   colorCafes.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseCafes", baseCafes, baseCafes?.length);
    // console.log("colorCafes", colorCafes, colorCafes?.length);

    // baseRojos.map((color) => {
    //   colorRojos.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseRojos", baseRojos, baseRojos?.length);
    // console.log("colorRojos", colorRojos, colorRojos?.length);

    // baseNaranjas.map((color) => {
    //   colorNaranjas.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseNaranjas", baseNaranjas, baseNaranjas?.length);
    // console.log("colorNaranjas", colorNaranjas, colorNaranjas?.length);

    // baseAmarillos.map((color) => {
    //   colorAmarillos.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseAmarillos", baseAmarillos, baseAmarillos?.length);
    // console.log("colorAmarillos", colorAmarillos, colorAmarillos?.length);

    // baseVerdes.map((color) => {
    //   colorVerdes.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseVerdes", baseVerdes, baseVerdes?.length);
    // console.log("colorVerdes", colorVerdes, colorVerdes?.length);

    // baseAquas.map((color) => {
    //   colorAquas.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseAquas", baseAquas, baseAquas?.length);
    // console.log("colorAquas", colorAquas, colorAquas?.length);

    // baseAzules.map((color) => {
    //   colorAzules.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseAzules", baseAzules, baseAzules?.length);
    // console.log("colorAzules", colorAzules, colorAzules?.length);

    // baseMorados.map((color) => {
    //   colorMorados.push({
    //     nombre: color.Nombre,
    //     codigo: color["Código color"],
    //     R: color.R,
    //     G: color.G,
    //     B: color.B,
    //   });
    // });

    // console.log("baseMorados", baseMorados, baseMorados?.length);
    // console.log("colorMorados", colorMorados, colorMorados?.length);

    // lineaPremium.map(color => {

    //   const linea = {
    //     linea: {

    //     }
    //   }
    // })
  };

  const inicializarHDMJson = () => {
    const pinturasHDM = hdmLineaGamaColor;
    // const hasProductBaseColor = hasProductBaseColor;
    // let isPaint = false
    // let branchColor = ''
    // let lineColorValue = ''

    if (brand !== "" && hasProductBaseColor?.base) {
      // hdmColors?.allBranchColors.map(branch => {
      //   if(productData?.manufacturer.includes(branch)){
      //     isPaint = true
      //     branchColor = branch
      //   }
      // })
      // if(!isPaint) return
      // isPaint = false
      // hdmColors?.allBaseColors.map(base => {
      //   if(base === productData?.hasProductBaseColor?.base ){
      //     isPaint = true
      //   }
      // })
      // if(!isPaint) return
      // isPaint = false
      // let founded = false
      // hdmColors.allPaintLines.map(line => {
      //   if(productData?.manufacturer.toLowerCase().includes(line.toLowerCase()) && !founded){
      //     lineColorValue = line
      //     isPaint = true
      //     founded = true
      //   }
      // })
      // if(!isPaint) return
    }

    let colorBase: any = null;
    let baseGamaColorDefault: any = null;

    // const colorCodeHash = window.location.hash
    let colorCode;
    // if(colorCodeHash){
    //   colorCode = colorCodeHash.split(':')[1]

    // }
    let gama;

    if (!hasProductBaseColor?.colorCode) {
      colorBase = pinturasHDM.filter(
        (database) =>
          database?.marca === hasProductBaseColor?.branchColor &&
          database?.linea === hasProductBaseColor?.lineColor &&
          database.gama === "#N/D"
      )[0];
      baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === hasProductBaseColor?.baseColor)[0];
      colorCode = baseGamaColorDefault?.valor;
    } else {
      colorBase = pinturasHDM.filter(
        (database) =>
          database?.marca === hasProductBaseColor?.branchColor && database?.linea === hasProductBaseColor?.lineColor
      );

      colorBase.map((baseGama) => {
        const colorSelect = baseGama?.colores.filter((color) => color.codigo === hasProductBaseColor?.colorCode);
        if (colorSelect?.length !== 0) {
          gama = baseGama?.gama;
          baseGamaColorDefault = colorSelect[0];
          return baseGama;
        } else {
          return null;
        }
      });

      colorCode = baseGamaColorDefault?.codigo;
    }

    if (gama) {
      setGamaSelected(gama);
    }
    setColorCodeSelected(baseGamaColorDefault);
    setIsOpen(false);
    setBaseSelected(colorBase);
    setLineColor(hasProductBaseColor?.lineColorValue);

    const query = {
      url: "",
      data: {
        ...hdmDrawerColorData,
        gamaColors: null,
        isOpen: false,
        lineColorValue: hasProductBaseColor?.lineColor,
        base: hasProductBaseColor?.baseColor,
        colorCodeSelected: baseGamaColorDefault,
        colorCode: colorCode,
        partNumber: partNumber
      },
      option: "HDM_DRAWER_COLOR_DATA",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const handleOpenBuyByColor = (gama) => {
    setIsOpen(true);
    const selectedGamaColors = hdmLineaGamaColor.filter(
      (lineaGama) => lineaGama.linea === lineColor && lineaGama.gama === gama
    )[0];
    setGamaColors(selectedGamaColors?.colores);

    const query = {
      url: "",
      data: {
        ...hdmDrawerColorData,
        gamaColors: selectedGamaColors?.colores,
        isOpen: true,
      },
      option: "HDM_DRAWER_COLOR_DATA",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    // setGamaSelected(gama);
  };

  const handleCloseBuyByColor = () => {
    setIsOpen(false);
  };

  // return (
  //   <div>
  //     <Skeleton variant="text" />
  //     <Skeleton variant="circle" width={40} height={40} />
  //     <Skeleton variant="rect" width={210} height={118} />
  //   </div>
  // );

  if (!hdmLineaGamaColor || !baseSelected) return null;
  return (
    <ContainerBuyByColor className="styled--buybycolor-container" id="buybycolor">
      <StyledCardEmpty className="padding-2">
        <TitleCard title={t("productDetail.BuyByColor.labels.buyByColorCardTitle")} alinear="left" />

        <ColorSelected
          handleOpenBuyByColor={handleOpenBuyByColor}
          colorCodeSelected={colorCodeSelected}
          gamaSelected={gamaSelected}
          isDrawer={false}
        />

        <ColorsPaletteDefault
          handleOpenBuyByColor={handleOpenBuyByColor}
          gamaSelected={gamaSelected}
          isDrawer={false}
        />

        <SearchColor />
      </StyledCardEmpty>

      <BuyByColorDrawer />
    </ContainerBuyByColor>
  );
};

export default ComprarColor;
