/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import StepConnector  from "@material-ui/core/StepConnector";

const StyledStepConnector = styled(({ ...props }) => <StepConnector {...props} />)`
  ${({ theme, checkout, activeStep }) => `
    
  
    // margin-top: ${checkout ? "0" : "10"}px;
    margin-top: 10px;
    
    .MuiStepConnector-lineHorizontal {
        border-top-style: solid;
        border-top-width: 2px;
    }

    :nth-child(${activeStep === 1 || activeStep === 2 ? 2 : "" }){
      & span{
        border-color: ${theme.palette.primary.main};
      }
    }

    :nth-child(${activeStep === 2 ? 4 : "" }){
      & span{
        border-color: ${theme.palette.primary.main};
      }
    }


  `}
`;

export { StyledStepConnector };
