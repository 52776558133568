import styled from "styled-components";
import { StyledTextField } from "../../elements/text-field";
import { StyledGrid } from "../../elements/grid";
import { StyledBox } from "../../elements/box";
import { StyledButton } from "../../elements/button";
import { StyledAvatar } from "../../elements/avatar";
import { StyledTypography } from "../../elements/typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RoomIcon from "@material-ui/icons/Room";

import { palette } from "../../themes/color-palette";

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    &.acerca-de-container {        
        margin-bottom: 10px;
    }

    &.informacion-adicional {
        justify-content: space-between;
    }

    &.container-atributos {
        padding: 10px;
    }

    &.background-especificacion-w {
        background-color: ${palette.background.paper}
    }
    
    &.background-especificacion-g {
        background-color: ${palette.gray["50"]}
    }

`}
`;

export const ContainerStyledTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `

    &.text-informacion-adicional {
        padding-top: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: ${palette.secondary.main}
    }
    &.text-espicificaciones {
        padding-top: 10px;
        padding-left: 10px;
        cursor: pointer;
        color: ${palette.secondary.main}
    }

`}
`;
