/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

import React, { useMemo, useEffect, useState, Dispatch, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { InView } from "react-intersection-observer";
import Sticky from "react-sticky-el";
import { useNavigate } from "react-router";
import useScriptInHead from "../../../components/custom-components/styled-carousel-hdm/useScriptInHead";
import ProductNotAvailable from "../../pages/not-available/ProductNoAvailable";
//REDUX
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { SET_CURRENT_STORE_ACTION } from "../../../redux/actions/current-store";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { onlineStoreRdc, hdmStoresDataRdc } from "../../../redux/selectors/hdmData";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { lineaGamaColorsRdc, hdmColorsRdc } from "../../../redux/selectors/hdmData";
import { orderItemsSelector } from "../../../redux/selectors/order";

//FUNCTIONS
import { tabletBreakpoint, checkIfHasColorBase, getBrowser } from "../../functions/utils";

import { PORDUCT_NOT_AVAILABLE } from "../../../../src/constants/routes";

//UI
import { StyledGrid, StyledPDPContainer, StyledBox, StyledCardEmpty } from "../../elements";
import { get } from "lodash-es";
import { useSite } from "../../../_foundation/hooks/useSite";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ContainerPdP } from "./container-pdp";

import jsonStateCityList from "../../../components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";
import InformacionAdicional from "../../components/pdp-informacion-adicional/InformacionAdicional";
import PdpCardPrecio from "../../components/pdp-card-precio/CardPrecio";
import PdpDisponibilidad from "../../components/pdp-disponibilidad/Disponibilidad";
import ComprarColor from "../../components/pdp-comparar-color/BuyByColor";
import QuantityHeader from "../../components/pdp-quantity-header/QuantityHeader";
import AcercaDeEsteProducto from "../../components/pdp-acerca-de-este-producto/AcercaDeEsteProducto";
import ProductSummaryCardHDM from "../../../components/custom-components/styled-product-summary-card/ProductSummaryCardHDM";
import ShopperActionsPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsPDPHDM";
import CarouselHDM from "../../../components/custom-components/styled-carousel-hdm/CarouselHDM";
import CarouselHDMDesktop from "../../../components/custom-components/styled-carousel-hdm/CarouselHDMDesktop";
import ProductInstalationHDM from "../../../components/custom-components/styled-product-instalation-card/ProductInstalationHDM";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";

import { JsonLP } from "../../components/json-lp/json-lp";
import SuperSkuContainer from "../../components/pdp-super-sku/SuperSkuContainer";
import RoomvoVisualSupport from "../../../components/custom-components/styled-visual-support/RoomvoVisualSupport";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import ViewContentEvent from "../../../components/custom-components/conversion-api/ViewContentEvent";
// import Emarsys from "./Emar../../../components/custom-components/conversion-api/ViewContentEvent
import { gtmPDPLoaded } from "../../../hdm/functions/utils";
import { GtmLoaded } from "../../../components/custom-components/gtm-events/Gtm-loaded";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import BackDropLoader from "../../../components/custom-components/backdrop-loader/BackdropLoader";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import styled from "styled-components";
import { CHROME, OFF_SET_HEIGHT_80 } from "../../../constants/common";
import { ProductDetailsContainer } from "./product-detail-container";
import { hasStoreBuyableExclude } from "../../functions/getPromoicons";

const ShoperActionheader = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, start, end }) => `

  .header-shoperaction-chrome {
    position: fixed;
    z-index: 999;
    top: -90px;

    animation: shoperaction-header linear both;
    animation-timeline: scroll(root block);
    animation-range: ${start}px ${end}px;
  }

  @keyframes shoperaction-header {
    to {
      top: 0px
    }
  }

  .header-shoperaction-hidden {
    display: none;
    height: 0px;
  }

  .header-shoperaction-container {
    display: block;
    position: fixed;
    z-index: 999;
    top: 0px;
  }



  `}
`;

interface ProductDetailsWidgetProps {
  seller: any;
  catentryData: any;
  productPartNumber: any;
  product: any;
  showCarousel: boolean;
  carouselImages: any;
  changeMainImage: (index: number) => void;
  index: number;
  displayFullImage: string;
  displayName: string;
  displayPartNumber: string;
  displayShortDesc: string;
  promotion: any;
  displayOfferPrice: number;
  displayListPrice: number;
  definingAttrList: any;
  skuColor: string;
  onAttributeChange: (id: string, value: string) => void;
  currentSelection: any;
  updateProductQuantity: (n: number) => void;
  availability: any;
  addToCart: () => void;
  inventoryAvailableFlag: boolean;
  buyableFlag: boolean;
  productDetailTabsChildren: any;
  translation: any;
  formattedPriceDisplayOffer: any;
  formattedPriceDisplayList: any;
  formattedPriceDisplayNull: any;
  loginStatus: boolean;
  isB2B: boolean;
  isSharedOrder: boolean;
  physicalStorePrice: any;
  SIGNIN: string;
  marketInventory: Array<any>;
  addToRLButton: any;
  queryParams?: any;
  products?: any;
}

export const ProductDetailsWidget: React.FC = (props: any) => {
  const {
    aRIdentifier,
    roomvoIdentifier,
    catentryData,
    name,
    proPriceReady,
    shortDescription,
    longDescription,
    legalDescription,
    brand,
    partNumber,
    productId,
    model,
    customBrandExclude,
    attributes,
    promociones,
    orderRibbons,
    urlRibbons,
    allEspecificacionesTec,
    attachedImages,
    videos,
    quantityMeasure,
    weightMeasure,
    bahiaData,
    leadTimeProduct,
    currentStoreId,
    tiendaNombre,
    locationName,
    product,
    displayFullImage: fullImage,
    displayName,
    displayPartNumber,
    displayShortDesc,
    displayOfferPrice,
    currentSelection,
    loginStatus,
    isB2B,
    marketInventory,
    queryParams,
    products,
    hasBreadcrumb
  } = props;

  const [hasProductBaseColor, setHasProductBaseColor] = useState<any>(null);
  const [showCarritoHeader, setShowCarritoHeader] = useState<boolean>(false);
  const [nominalQuantity, setNominalQuantity] = useState<string>("1");
  const [productNotBuyable, setProductNotBuyable] = useState<boolean>(false);
  const [isGTMLoaded, setIsGTMLoaded] = useState<boolean>(false);
  const [partNumberBT, setPartNumberBT] = useState<any>(null);
  const [categoryBT, setCategoryBT] = useState<any>(null);
  const [categoryBT2, setCategoryBT2] = useState<any>(null);
  const [categoryBT3, setCategoryBT3] = useState<any>(null);

  const espotUXcomponent = isB2B ? "PRO_PDP-UXcomponent" : "PDP-UXcomponent";
  const espotCrossSell = isB2B ? "PRO_PDP-CrossSell" : "PDP-CrossSell";

  const summaryGalleryContainerRef = useRef<HTMLDivElement | null>(null)
  const pdpSlotOneRef = useRef<HTMLDivElement | null>(null)
  const pdpSlotTwoRef = useRef<HTMLDivElement | null>(null)
  const shoperActionRef = useRef<HTMLDivElement | null>(null)
  //superSKU FLAG
  const [isParentSSKU, setIsParentSSKU] = useState<boolean>(false);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const widgetName = getDisplayName("ProductDetailsWidget");
  const { mySite } = useSite();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const is1440 = useMediaQuery(theme.breakpoints.down(1440));
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const dispatch = useDispatch<Dispatch<any>>();
  const pdpQty: any | number = useSelector<any>((state) =>
    state.hdmDataReducer.pdpQuantity ? state.hdmDataReducer.pdpQuantity : 1
  );
  //SELECTORS
  const storeSelector = useSelector(currentStoreSelector);
  const breadcrumbsValue = useSelector(breadcrumbsSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const hdmOrderItemsSelector = useSelector(orderItemsSelector);

  /*==== Ar api key =====*/

  const arApiKey = storeConfData?.AR_APITOKENKEY;
  const isArEnabled = storeConfData?.ENABLE_AR;
  const isVisualSupportEnabled = aRIdentifier ? aRIdentifier : ""; //product ? product["x_visualsupport.field2"] : "";
  // const productSku: string = product?.items[0]?.partNumber;

  /*==== ROOMVO changes =====*/
  const isRoomvoEnabled = storeConfData?.ENABLE_ROOMVO;
  const isRoomvoVisualSupportEnabled = roomvoIdentifier ? roomvoIdentifier : ""; //product ? product["x_visualsupport.field2"] : "";

  const tiendaUniqueID = storeSelector.currentStore.uniqueID;
  const cityList = jsonStateCityList.formScheduleObject;
  // Call roomvo script if product has roomvo enabled
  useScriptInHead(
    isRoomvoEnabled && (isRoomvoVisualSupportEnabled === "2" || isRoomvoVisualSupportEnabled === "3")
      ? "https://cdn.roomvo.com/static/scripts/b2b/homedepot_mexico.js"
      : "",
    ""
  );
  const payloadBase: any = {
    storeId: mySite.storeID,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const currentMarket = marketInventory && marketInventory?.find((market) => market?.physicalStoreName === currentStoreId);
  const field2 = currentMarket && currentMarket?.x_customField2 ? parseInt(currentMarket?.x_customField2) : 0;
  const availableQuantityInMarket = currentMarket && currentMarket?.x_AvailableQuantityInMarket ? parseInt(currentMarket?.x_AvailableQuantityInMarket) : 0;

  const mktInventory = marketInventory && availableQuantityInMarket ? availableQuantityInMarket : field2 && field2;

  let schemaPartNumber = "";
  if (currentSelection?.sku && currentSelection?.sku?.length !== 0 && currentSelection?.sku[0]?.partNumber) {
    schemaPartNumber = currentSelection?.sku[0].partNumber.replace("P", "");
  }

  const producPrice = product?.price?.find((p) => p.usage === "Offer").value;
  const calculateProductPrice =
    producPrice !== "0.0" ? producPrice : catentryData?.price?.find((p) => p.usage === "Offer").value;
  const CDN_URL = "https://cdn.homedepot.com.mx/";
  const jsonLPPayload = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: displayName,
    "@id": `${window.location.origin}${window.location.pathname}`,
    image: [product?.items && product.items.length > 0 && CDN_URL + product?.items[0]?.fullImageRaw],
    description: displayShortDesc,
    sku: schemaPartNumber,
    mpn: schemaPartNumber,
    brand: {
      "@type": "Thing",
      name: product?.manufacturer ?? "",
    },
    offers: {
      "@type": "Offer",
      url: `${window.location.origin}${window.location.pathname}`,
      priceCurrency: "MXN",
      price: calculateProductPrice,
      price_sqm: quantityMeasure === "m2" ? parseFloat(catentryData?.price[1].field1?.OMP).toFixed(2) : "",
      priceValidUntil: "",
      availability: `${mktInventory === 0 ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"}`,
    },
  };

  const detectedBrowser = getBrowser();

  //on component load
  useEffect(() => {
    document.getElementById("headerContainer")?.scrollIntoView();
    dispatch(OPEN_BACKDROP_LOADER_ACTION({}));

    return () => {
      dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(queryParams ?? "");
      /**Check for store_code for LIA feature*/
      const store_code = params.get("store_code");
      if (store_code) {
        //change current store to given store_code
        const findStores = cityList.filter((states: any) =>
          states.stores.some((cities) => cities.stLocId === store_code)
        )[0];
        const findStore = findStores?.stores.filter((stores: any) => stores.stLocId === store_code)[0];
        let stLocId = "";

        await Axios.get(
          `/wcs/resources/store/${mySite.storeID}/storelocator/latitude/${findStore.latitude}/longitude/${findStore.longitude}?maxItems=1&radius=10&langId=-5`
        )
          .then((response) => response.data)
          .then((res) => {
            stLocId = res.PhysicalStore[0].uniqueID;
          });

        const defaultPayload = {
          findStore: {
            ...findStore,
            uniqueID: stLocId,
            stateId: findStores.stateId,
            isExternal: true,
          },
        };
        dispatch(SET_CURRENT_STORE_ACTION(defaultPayload));
      }
    };
    fetchData();
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [queryParams]);

  useEffect(() => {
    if (
      product &&
      breadcrumbsValue &&
      breadcrumbsValue?.length !== 0 &&
      ((marketInventory && marketInventory?.length !== 0) || marketInventory === undefined) &&
      currentStoreId &&
      catentryData &&
      catentryData?.stLocId === currentStoreId &&
      tiendaUniqueID &&
      currentSelection &&
      currentSelection?.sku &&
      currentSelection?.sku.length !== 0 &&
      hdmLineaGamaColor &&
      hdmLineaGamaColor?.lenght !== 0 &&
      hdmColors &&
      hdmColors?.lenght !== 0
    ) {
      inicializarProductDetail();
      displayRoomvoSection();
    } else if (
      products === undefined &&
      !product &&
      !catentryData
    ) {
      productNotAvailable();
    }

    //check if parent partNumber is sSKU
    product && setIsParentSSKU(product.partNumber.split("_SS").length == 2);

    return () => {
      // setProductData(null);
      let query: any = null;
      query = {
        url: "",
        data: "",
        option: "PDP_SEO",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: null,
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: null,
        option: "PDP_PRODUCT_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
      if (!catentryData) {
      query = {
        url: "",
        data: null,
        option: "PDPSTORE_BUYABLE_EXCLUDE",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
      setProductNotBuyable(false);
      }
    };
  }, [product, tiendaUniqueID, breadcrumbsValue, currentSelection, marketInventory, catentryData, products]);

  useEffect(() => {
    let newNominalQuantity: any = "1";
    if (hdmOrderItemsSelector && hdmOrderItemsSelector?.length !== 0 && catentryData) {
      const productInCart = hdmOrderItemsSelector.find(
        (orderItem) => orderItem?.partNumber === catentryData?.partNumber
      );
      if (productInCart && catentryData?.nominalQuantity) {
        newNominalQuantity = parseInt(catentryData?.nominalQuantity) - parseInt(productInCart?.quantity);
        if (newNominalQuantity <= 0) {
          setNominalQuantity("1");
          newNominalQuantity = "1";
        } else {
          newNominalQuantity = catentryData?.nominalQuantity;
          setNominalQuantity(newNominalQuantity);
        }
      } else if (catentryData?.nominalQuantity) {
        newNominalQuantity = catentryData?.nominalQuantity;
        setNominalQuantity(newNominalQuantity);
      }
    } else {
      newNominalQuantity = catentryData?.nominalQuantity;
      setNominalQuantity(newNominalQuantity);
    }

    if (!newNominalQuantity) {
      newNominalQuantity = "1";
    }

    const query = {
      url: "",
      data: newNominalQuantity,
      option: "PDP_QUANTITY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  }, [hdmOrderItemsSelector, catentryData]);

  const skusAsAttrs = useMemo(() => {
    return (product?.items ?? []).map((p) => {
      const rc = {};
      p.attributes
        ?.filter(({ usage: u }) => u === "Defining")
        .forEach((a) => (rc[a.identifier] = get(a, "values[0].identifier")));
      return rc;
    });
  }, [product]);

  const inicializarProductDetail = async () => {
    // navigate(ERROR_PAGE, {state:{errorCode:'500'}})
    window.scrollTo({ top: 0, behavior: "instant" });
    if (catentryData?.type && catentryData?.type === "item") {

      const storeBuyableExclude = hasStoreBuyableExclude(attributes, isB2B);

      if (storeBuyableExclude) {
        setProductNotBuyable(true);
        let query;

        query = {
          url: "",
          data: catentryData?.seo?.href,
          option: "PDP_SEO",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

        query = {
          url: "",
          data: true,
          option: "PDPSTORE_BUYABLE_EXCLUDE",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
        setTimeout(() => {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
        }, 1000);
        return;
      }

      const isPaint = breadcrumbsValue.some((category) => category.label === "Pinturas");
      const productBaseColor: any = checkIfHasColorBase(hdmColors, {
        manufacturer: brand,
        attributes: attributes,
      });

      let baseGamaColorDefault: any = null;
      if (isPaint && productBaseColor) {
        const { branchColor, lineColor, baseColor } = productBaseColor;
        const pinturasHDM = hdmLineaGamaColor;
        const colorBase = pinturasHDM.filter(
          (database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === "#N/D"
        )[0];
        baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0];

        const colorCodeHash = window.location.hash;
        let colorCode;
        if (colorCodeHash) {
          colorCode = colorCodeHash.split(":")[1];
        }

        productBaseColor.colorCode = colorCode;
        setHasProductBaseColor(productBaseColor)
      }

      const nomQuantity = catentryData?.["x_measurements.nominalQuantity"] ? parseInt(catentryData["x_measurements.nominalQuantity"]).toString() : "1";

      setNominalQuantity(nomQuantity);
      //datalayer
      const arrayCat = breadcrumbsValue.filter((e) => e.type_ === "FACET_ENTRY_CATEGORY");
      if (arrayCat.length > 0) {
        setPartNumberBT(partNumber);
        if (arrayCat[0]) {
          setCategoryBT(arrayCat[0].label ? arrayCat[0].label : "NA");
        }
        if (arrayCat[1]) {
          setCategoryBT2(arrayCat[1].label ? arrayCat[1].label : "NA");
        }
        if (arrayCat[2]) {
          setCategoryBT3(arrayCat[2].label ? arrayCat[2].label : "NA");
        }
      }

      let query;

      query = {
        url: "",
        data: catentryData?.seo?.href,
        option: "PDP_SEO",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      query = {
        url: "",
        data: {
          partNumber: partNumber,
          longDescription: longDescription,
          attachments: catentryData?.attachments,
          productDetailTabsChildren: allEspecificacionesTec,
          legalDescription: legalDescription,
          storeInventory: marketInventory
        },
        option: "PDP_PRODUCT_DATA",
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

      if (!product.partNumber.includes("_SS")) {
        setTimeout(() => {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
        }, 2000);
      }
    }
  };
  const displayRoomvoSection = async () => {
    const elmts = document.querySelectorAll<HTMLElement>(".roomvo-stimr");
     for (let n = 0; n < elmts.length; ++n) {
      if ("hidden" === elmts[n].style.visibility) {
        // Select the node that will be observed for mutations
        const roomvoDiv = document.getElementById("roomvo") as HTMLElement;
        let observer: any = null;
        // Options for the observer (which mutations to observe)
        const configOptions = { attributes: true };
        let isDisplayRoomvoDiv = false;
        // Callback function to execute when mutations are observed
        const callback = (roomvoDivMutationList, observer) => {
          for (const mutation of roomvoDivMutationList) {
            if (!isDisplayRoomvoDiv && mutation.type === "attributes" && mutation.attributeName === "style") {
              const elmts = document.querySelectorAll<HTMLElement>(".roomvo-stimr"); 
              for (let n = 0; n < elmts.length; ++n) {
                if ("visible" === elmts[n].style.visibility) {
                  const a = document.querySelectorAll<HTMLElement>(".roomvo-container");
                   for (let b = 0; b < a.length; ++b) {
                    if ("none" === a[b].style.display) {
                      a[b].style.display = 'block';
                      isDisplayRoomvoDiv = true;
                      if (observer) {
                        observer.disconnect();
                      }
                    }
                  }
                  break;
                }
              }
            }
          }
        };
        observer = new MutationObserver(callback);
        observer.observe(roomvoDiv, configOptions);
      }
    }
  };
  const productNotAvailable = () => {
    setProductNotBuyable(true);
    let query;

    query = {
      url: "",
      data: catentryData?.seo?.href,
      option: "PDP_SEO",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

    query = {
      url: "",
      data: true,
      option: "PDPSTORE_BUYABLE_EXCLUDE",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    setTimeout(() => {
      dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
    }, 1000);
    return;
  }

  const storeBuyableExclude = hasStoreBuyableExclude(attributes, isB2B);

  if (productNotBuyable || storeBuyableExclude) return <ProductNotAvailable breadcrumbsValue={breadcrumbsValue} />;

  // Validar si el servicio se encuentra en el carrito



  return (
    <ProductDetailsContainer 
      displayName={displayName}
      catentryData={catentryData}
      partNumber={partNumber}
      displayOfferPrice={displayOfferPrice}
      currentSelection={currentSelection}
      nominalQuantity={nominalQuantity}
      marketInventory={marketInventory}
      currentStoreId={currentStoreId}
      promociones={promociones}
      quantityMeasure={quantityMeasure}
      loginStatus={loginStatus}
      brand={brand}
      name={name}
      proPriceReady={proPriceReady}
      model={model}
      detectedBrowser={detectedBrowser}
      customBrandExclude={customBrandExclude}
      urlRibbons={urlRibbons}
      aRIdentifier={aRIdentifier}
      fullImage={fullImage}
      attachedImages={attachedImages}
      product={product}
      attributes={attributes}
      shortDescription={shortDescription}
      productId={productId}
      weightMeasure={weightMeasure}
      orderRibbons={orderRibbons}
      roomvoIdentifier={roomvoIdentifier}
      bahiaData={bahiaData}
      leadTimeProduct={leadTimeProduct}
      tiendaNombre={tiendaNombre}
      locationName={locationName}
      allEspecificacionesTec={allEspecificacionesTec}
      displayPartNumber={displayPartNumber}
      longDescription={longDescription}
      isParentSSKU={isParentSSKU}
      hasProductBaseColor={hasProductBaseColor}
      legalDescription={legalDescription}
      videos={videos}
      isGTMLoaded={isGTMLoaded}
      setIsGTMLoaded={setIsGTMLoaded}
      partNumberBT={partNumberBT}
      categoryBT={categoryBT}
      categoryBT2={categoryBT2}
      categoryBT3={categoryBT3}
      isB2B={isB2B}
      isPdp={true}
      hasBreadcrumb={hasBreadcrumb}
    />
  )
  }
