import styled from "styled-components";
import { StyledGrid } from "../../../hdm";

export const ProductInstalationContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme, ...props }) => `
        max-width: 100%;
        height: auto;
        margin: 0 0 ${theme.spacing(2)}px 0;
        background-color: #ffffff;
        box-shadow: #cccccc 0 3px 6px;
        padding: ${theme.spacing(2)}px;
        
        @media (max-width: 1024px){
            margin: ${theme.spacing(2)}px ${theme.spacing(2)}px;
        }

        & .technical-visits-container {
            width: 100%;
            display: flex;
            gap: 5px;
        
            & .sub-container{
                border: solid 1px ${theme.palette.gray["50"]};
                padding: 5px;
                min-width: 300px;
                & .remove-item {
                    display:flex;
                    justify-content: flex-end;
                    border-bottom: solid 1px ${theme.palette.gray["50"]};
                    margin-bottom: ${theme.spacing(2)}px;
                }
                & .title-container{
                    display: flex;
                    & .handyman-icon{
                        color: ${theme.palette.primary.main};
                        font-size: large;
                    }
                }

                & .instalation-card-title{
                    margin-bottom: ${theme.spacing(2)}px;
                    margin-left: ${theme.spacing(2)}px;
                }

                & .margin-bottom{
                    margin-bottom: ${theme.spacing(1)}px;
                }

                & .service-description{
                    margin-left: ${props.service == "SI" ? "24" : "0"}px;
                    display: flex;

                    & .drawer-link{
                        color: ${theme.palette.secondary.main};
                        cursor: pointer;
                    }
                }
                & .qty-and-subtotal{
                    margin-left: 2px;
                    padding-top: ${theme.spacing(2)}px;
                    border-top: solid 1px ${theme.palette.gray["50"]};

                    justify-content: space-between;
                }
                & .services-header{
                display: flex;
                justify-content: space-between;
                }
                & .service-subtotal-a2c {
                    display: flex;
                    justify-content: ${
                      props.service != "TV" || (props.service == "TV" && props.installationIsChecked && props.isCart)
                        ? "flex-end"
                        : "space-between"
                    };
                }
                    @media (max-width: 1024px){
                    
                        justify-content: space-between;
                    }
                    & .subtotal-container_inst_cart
                    {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 12px;
                        & p{
                            color : #000000;
                            font-family: "Open Sans",Helvetica,Arial,sans-serif;
                            width: fit-content;
                        }
                        & .price {
                            font-size: 18px;
                            font-weight: 400;
                            color: #77777;
                        }
                        & .price-numeric{
                            font-family: "Open Sans",Helvetica,Arial,sans-serif;
                            font-size: 18px;
                            color : #33333;
                        }
                    
                    }
                    & .subtotal-container{
                        display: flex;
                        justify-content: flex-start;
                        font-size: 12px;
                        & p{
                            color : #D41616;
                            font-family: "Open Sans",Helvetica,Arial,sans-serif;
                            width: fit-content;
                        }
                        & .price {
                            font-size: 18px;
                            font-weight: 400;
                            color: #77777;
                        }
                        & .price-numeric{
                            font-family: "Open Sans",Helvetica,Arial,sans-serif;
                            font-size: 18px;
                            color : #33333;
                        }
                    
                    }
                    & .Addsharp{
                        vertical-align: middle;
                        color: #f96302;
                        font-size:18px;
                    }
                    & .aggregar-link{
                        cursor: pointer;
                        font-size: 13px;
                        color: #3E7697;
                    }
                    & .already-added-link{
                        font-size: 13px;
                        color: #00a950;
                    }

                    }
                }    
            }
        }
        & .tv-cart-a2c-container{
            width: max-content;
            min-width: 300px; 
            border: solid 1px #eee;
            padding: 10px;
            box-sizing: border-box;
            margin-left: 0;
            box-shadow:none;
            margin-right: 10px;
        }
        & .tv-cart-to-be-added-name-section{            
            margin-left: 0;
            padding-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
        }

        & .tv-cart-to-be-added-price-action-section{
            display: flex;
            justify-content: space-between;
        }

        & .terms-and-conditions-container{
            margin-top: ${theme.spacing(2)}px;
            color: ${theme.palette.gray["200"]};

            & .terms-and-conditions-link{
                color: ${theme.palette.secondary.main};
                cursor: pointer;
            }
        }

        & .free-installation-text{
            color: ${theme.palette.success.main};
        }
       

        & .installation-specs-drawer-container{
            padding: ${theme.spacing(2)}px;
        }

    `}
`;
