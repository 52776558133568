import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../../../redux/selectors/hdmData";
import { ContainerStyledBox, ContainerStyledCardMedia } from "../../containers-precio-promocion";

export const Combo = () => {
  const storeConfData = useSelector(onlineStoreRdc);
  return (
    <>
      <ContainerStyledBox className="promoicon-container">
        <ContainerStyledCardMedia
          image={storeConfData?.IMAGE_SERVER_HOSTNAME + "iconosPromos/combos.svg"}
          className="promo_plp-ribbon-combo"
          title="Combo"
          component="img"
        />
      </ContainerStyledBox>
    </>
  );
};
