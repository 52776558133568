/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";
import { dimensions } from "../../themes/variables";

const thumbnailSizeHeight = dimensions.styledProductCard.height;

const ComponentWrapper = (props: any) => <CardMedia {...props} />;

const StyledCardMediaProduct = styled(ComponentWrapper)`
  ${({ theme }) => `

  &.styled--product-img {

    ${theme.breakpoints.down("sm")} {
      height: 150px;
      width: 150px;
      background-size: contain;
      /*border: 1px solid red;*/
    }
  
    ${theme.breakpoints.up("sm")} {
      height: 285px;
      background-size: contain;
      /*border: 2px solid red;*/
    }
  }

`}
`;

const StyledCardMediaHipoteca = styled(({ ...props }) => <CardMedia {...props} />)`
  &.promo_plp-ribbon-hv {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &.promo_plp-ribbon-hv-pdp {
    width: 45px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;
  }
`;

export { StyledCardMediaProduct, StyledCardMediaHipoteca };
