/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { lazy, Suspense } from "react";
//Custom libraries
import * as ROUTES from "../constants/routes";
import CheckoutProfileEdit from "../components/pages/checkout-profile/CheckoutProfileEdit";
import { loaders } from "../components/custom-components/skeleton-loader/components";
import { SLWishListLayout } from "../components/custom-components/skeleton-loader/components/elements/WishListLayot";
import withSkeletonLoader from "../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";

//use it on elements that require skeleton loader
const withSuspense = (Children)=>{
  return(
    <Suspense fallback={loaders.findStore}>
      <Children/>
    </Suspense>
  )
}

//Common pages
const SEO = lazy(() => import("../_foundation/seo/SEO"));

//component
const SignIn = lazy(() => import("../components/pages/sign-in/SignIn"));
const SignUp = lazy(() => import("../components/pages/sign-up/SignUp"));
const ForgotPassword = lazy(() => import("../components/pages/forgot-password/ForgotPassword"));
const AddressBook = lazy(() => import("../components/pages/address-book/AddressBook"));
const EditAddress = lazy(() => import("../components/pages/address-book/EditAddress"));
const AddAddress = lazy(() => import("../components/pages/address-book/AddAddress"));
const SearchResults = lazy(() => import("../components/pages/search-results/SearchResults"));
const NewsLetterConfirm = lazy(() => import("../components/pages/newsLetter-confirmation/"));

const PaymentMethodBook = lazy(() => import("../components/pages/payment-method-book/PaymentMethodBook"));
const AddPaymentMethod = lazy(() => import("../components/pages/payment-method-book/AddPaymentMethod"));

const Cart = lazy(() => import("../components/widgets/cart/cart-widget"));

//checkout
const Shipping = lazy(() => import("../components/pages/checkout/shipping/Shipping"));
const Billing = lazy(() => import("../components/widgets/checkout-payment"));

const Review = lazy(() => import("../components/widgets/checkout-review"));
const CheckoutProfiles = lazy(() => import("../components/pages/checkout-profile/CheckoutProfiles"));
const PrintOrder = lazy(() => import("../hdm/components/print-order"));

//Emerald pages
const Account = lazy(() => import("../components/pages/_emerald/account/Account"));
const SearchStores = withSuspense(lazy(() => import("../hdm/commerce-widgets/hdm-search-stores/SearchStores")));
const MapSite = lazy(() => import("../components/pages/map-site/MapSite"));
// const MapSite = lazy(() => import("../components/pages/map-site/MapSite"));

// const Account = lazy(() => import("../hdm/pages/_emerald/account/Account"));

//Wish List
const WishList = lazy(() => import("../components/pages/wish-list/wish-list"));
const ViewWishList = lazy(() => import("../components/pages/wish-list/view-wish-list"));

//Sapphire pages
const Dashboard = lazy(() => import("../components/pages/_sapphire/dashboard/Dashboard"));
const OrderHistoryPage = lazy(() => import("../components/pages/_sapphire/order/OrderHistoryPage"));
// const OrderHistoryPage = lazy(() => import("../hdm/pages/_sapphire/order/OrderHistoryPage"));
const RecurringOrders = lazy(() => import("../components/pages/_sapphire/order/RecurringOrdersPage"));
const OrderDetailsPage = lazy(() => import("../components/pages/_sapphire/order/OrderDetailsPage"));
const BuyerUserRegistration = lazy(
  () => import("../components/pages/_sapphire/buyer-user-registration/BuyerUserRegistration")
);
const BuyerOrganizationRegistration = lazy(
  () => import("../components/pages/_sapphire/buyer-organization-registration/BuyerOrganizationRegistration")
);
const AdminTools = lazy(() => import("../components/pages/_sapphire/adminTools/AdminTools"));
const CheckoutProfileCreate = lazy(() => import("../components/pages/checkout-profile/CheckoutProfileCreate"));
const InprogressOrderDetailsPage = lazy(
  () => import("../components/pages/_sapphire/order/inprogress-order-details-page")
);

const InprogressOrders = lazy(() => import("../components/pages/_sapphire/order/inprogress-orders"));

const RequisitionLists = lazy(() => import("../components/pages/_sapphire/requisition-lists/requisition-lists"));

const RequisitionListDetailsPage = lazy(
  () => import("../components/pages/_sapphire/requisition-lists/requisition-list-details-page")
);
const ViewUploadLogs = lazy(() => import("../components/pages/_sapphire/requisition-lists/ViewUploadLogs"));

const Compare = lazy(() => import("../components/pages/compare/compare"));

const ErrorPages = lazy(() => import("../hdm/pages/error/ErrorPage"));
const ErrorPage500 = lazy(() => import("../hdm/pages/error/ErrorPage500"));
const ProductNotAvailable = lazy(() => import("../hdm/pages/not-available/ProductNoAvailable"));

//Pro
const ProLeadForm = lazy(() => import("../components/pages/pro-lead/ProLeadMainPage"));
const ExpiredLinkPage = lazy(() => import("../components/pages/expired-email-link/ExpiredLinkMainPage"));
const ArFeedbackPage = lazy(() => import("../components/pages/ar-feedback/ArFeedbackMainPage"));

const CheckoutRouteConfig = (props) => [
  {
    key: ROUTES.CHECKOUT,
    path: ROUTES.CHECKOUT,
    element: <Shipping {...props} />,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT,
    path: ROUTES.CHECKOUT_PAYMENT,
    element: <Billing {...props} />,
  },
  {
    key: ROUTES.CHECKOUT_REVIEW,
    path: ROUTES.CHECKOUT_REVIEW,
    element: <Review {...props} />,
  },
];

const CompareConfig = {
  key: ROUTES.COMPARE_ROUTE,
  path: ROUTES.COMPARE_ROUTE,
  exact: true,
  element: <Compare />,
};

const B2BRouteConfig: any[] = [
  {
    key: ROUTES.DASHBOARD,
    path: ROUTES.DASHBOARD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Dashboard />,
  },
  {
    key: ROUTES.SIGNIN_PRO,
    path: ROUTES.SIGNIN_PRO,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignIn />,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ForgotPassword />,
  },
  {
    key: ROUTES.ORG_REGISTRATION,
    path: ROUTES.ORG_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <BuyerOrganizationRegistration />,
  },
  {
    key: ROUTES.BUYER_REGISTRATION,
    path: ROUTES.BUYER_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <BuyerUserRegistration />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfileCreate />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT_ROUTE,
    path: ROUTES.CHECKOUT_PROFILE_EDIT_ROUTE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfileEdit />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT,
    path: ROUTES.CHECKOUT_PROFILE_EDIT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfileCreate />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfiles />,
  },
  {
    key: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Account />,
  },
  {
    key: ROUTES.CART,
    path: ROUTES.CART,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Cart />,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <OrderHistoryPage />,
  },
  {
    key: ROUTES.RECURRING_ORDERS,
    path: ROUTES.RECURRING_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <RecurringOrders />,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <OrderDetailsPage />,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddressBook />,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <EditAddress />,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddAddress />,
  },
  {
    key: ROUTES.PAYMENTMETHOD,
    path: ROUTES.PAYMENTMETHOD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <PaymentMethodBook />,
  },
  {
    key: ROUTES.ADD_PAYMENTMETHOD,
    path: ROUTES.ADD_PAYMENTMETHOD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddPaymentMethod />,
  },
  {
    key: ROUTES.ORGANIZATION_MANAGEMENT,
    path: ROUTES.ORGANIZATION_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AdminTools />,
  },
  {
    key: ROUTES.BUYER_MANAGEMENT,
    path: ROUTES.BUYER_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AdminTools />,
  },
  {
    key: ROUTES.APPROVALS_MANAGEMENT,
    path: ROUTES.APPROVALS_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AdminTools />,
  },
  {
    key: ROUTES.ORDER_APPROVAL,
    path: ROUTES.ORDER_APPROVAL,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AdminTools />,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <InprogressOrderDetailsPage />,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <InprogressOrders />,
  },
  {
    key: ROUTES.REQUISITION_LISTS,
    path: ROUTES.REQUISITION_LISTS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <RequisitionLists />,
  },
  {
    key: ROUTES.WISH_LIST,
    path: ROUTES.WISH_LIST,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <WishList />,
  },
  {
    key: ROUTES.VIEW_WISH_LIST_ROUTE,
    path: ROUTES.VIEW_WISH_LIST_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <ViewWishList />,
  },
  {
    key: ROUTES.REQUISITION_LIST_DETAILS,
    path: ROUTES.REQUISITION_LIST_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <RequisitionListDetailsPage />,
  },
  {
    key: ROUTES.VIEW_UPLOAD_LOGS,
    path: ROUTES.VIEW_UPLOAD_LOGS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <ViewUploadLogs />,
  },
  {
    key: ROUTES.NEWSLETTER_CONFIRM,
    path: ROUTES.NEWSLETTER_CONFIRM,
    exact: true,
    element: <NewsLetterConfirm />,
  },
  CompareConfig,
  {
    key: "other",
    path: "/*",
    exact: true,
    element: <SEO />,
  },
  {
    key: ROUTES.PRO_LEAD_FORM,
    path: ROUTES.PRO_LEAD_FORM,
    exact: true,
    element: <ProLeadForm />,
  },
  {
    key: ROUTES.PRO_LEAD_FORM_PARAMS,
    path: ROUTES.PRO_LEAD_FORM_PARAMS,
    exact: true,
    element: <ProLeadForm />,
  },
  {
    key: ROUTES.PRO_EXPIRED_LINK,
    path: ROUTES.PRO_EXPIRED_LINK,
    exact: true,
    element: <ExpiredLinkPage />,
  },
  {
    key: ROUTES.SEARCH_STORES,
    path: ROUTES.SEARCH_STORES,
    exact: true,
    element: SearchStores,
  },
  {
    key: ROUTES.PRO_AR_FEEDBACK,
    path: ROUTES.PRO_AR_FEEDBACK,
    exact: true,
    element: <ArFeedbackPage />,
  },
];
const B2CRouteConfig: any[] = [
  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignIn />,
  },
  {
    key: ROUTES.SIGNUP,
    path: ROUTES.SIGNUP,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignUp />,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ForgotPassword />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfileCreate />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT_ROUTE,
    path: ROUTES.CHECKOUT_PROFILE_EDIT_ROUTE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfileEdit />,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <CheckoutProfiles />,
  },
  {
    key: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Account />,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddressBook />,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <EditAddress />,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddAddress />,
  },
  {
    key: ROUTES.PAYMENTMETHOD,
    path: ROUTES.PAYMENTMETHOD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <PaymentMethodBook />,
  },
  {
    key: ROUTES.ADD_PAYMENTMETHOD,
    path: ROUTES.ADD_PAYMENTMETHOD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <AddPaymentMethod />,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <OrderHistoryPage />,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <OrderDetailsPage />,
  },
  {
    key: ROUTES.PRINT_ORDER,
    path: ROUTES.PRINT_ORDER,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <PrintOrder />,
  },
  {
    key: ROUTES.SEARCH_STORES,
    path: ROUTES.SEARCH_STORES,
    exact: true,
    element: SearchStores ,
  },
  {
    key: ROUTES.MAP_SITE,
    path: ROUTES.MAP_SITE,
    exact: true,
    element: <MapSite />,
  },
  {
    key: ROUTES.ERROR_PAGE,
    path: ROUTES.ERROR_PAGE,
    exact: true,
    element: <ErrorPages />,
  },
  {
    key: ROUTES.PORDUCT_NOT_AVAILABLE,
    path: ROUTES.PORDUCT_NOT_AVAILABLE,
    exact: true,
    element: <ProductNotAvailable />,
  },
  {
    key: ROUTES.ERROR_PAGE_500,
    path: ROUTES.ERROR_PAGE_500,
    exact: true,
    element: <ErrorPage500 />,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <InprogressOrderDetailsPage />,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <InprogressOrders />,
  },
  {
    key: ROUTES.NEWSLETTER_CONFIRM,
    path: ROUTES.NEWSLETTER_CONFIRM,
    exact: true,
    element: <NewsLetterConfirm />,
  },
  CompareConfig,
  {
    key: ROUTES.WISH_LIST,
    path: ROUTES.WISH_LIST,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <WishList />,
  },
  {
    key: ROUTES.VIEW_WISH_LIST_ROUTE,
    path: ROUTES.VIEW_WISH_LIST_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <ViewWishList />,
  },
  {
    key: ROUTES.PRODUCT_PDP_PREFIX,
    path: ROUTES.PRODUCT_PDP_ROUTE,
    exact: true,
    element: <SEO />,
  },
  {
    key: ROUTES.CATALOG_PREFIX,
    path: ROUTES.CATALOG_SEARCH_ROUTE,
    exact: true,
    element: <SEO />,
  },
  {
    key: ROUTES.CATALOG_PREFIX,
    path: ROUTES.SUBCATALOG_SEARCH_ROUTE,
    exact: true,
    element: <SEO />,
  },
  {
    key: ROUTES.CATALOG_PREFIX,
    path: ROUTES.SUBSUBCATALOG_SEARCH_ROUTE,
    exact: true,
    element: <SEO />,
  },
  {
    key: "other",
    path: "/*",
    exact: true,
    element: <SEO />,
  },
];
export const ROUTE_CONFIG = {
  B2B: B2BRouteConfig,
  B2C: B2CRouteConfig,
  Checkout: CheckoutRouteConfig,
};
