/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//Custom libraries
import { AddressCard } from "../address-card";
import { useTranslation } from "react-i18next";
//UI
import { StyledGrid, StyledTypography } from "../../../hdm";
import withSkeletonLoader from "../../custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { SLAddressCards } from "../../custom-components/skeleton-loader/components/elements/Account";
import { loaders } from "../../custom-components/skeleton-loader/components";

interface AddressListProps {
  cid: string;
  addressList: any[]|undefined;
  setSelectedAddressId?: (v1?: any, v2?: any) => void; //selected address setter
  selectedAddressId?: string;
  selectedNickName?: string;
  actions?: any[];
  type?: boolean;
  hideEdit?: boolean;
  readOnlyShipping?: boolean;
  handleReadOnlyShipping?: any;
  variant?: string;
  deliveryMethod?: string;
  deliveryDates?: any;
  manualShippingDate?: any;
  setManualShippingDate?: (v: string) => void | undefined;
  oneTimeDeliveryOfMixedCart: any;
  setOneTimeDeliveryOfMixedCart: any;
  isInstallation?: any;
  isDrawer?: boolean;
  orgAddress?: any;
}

/**
 * Address list display component
 * displays list of addresses
 * @param props
 */
const AddressList: React.FC<AddressListProps> = (props: any) => {
  const { t } = useTranslation();
  const { addressList, hideEdit, selectedNickName, variant = "checkout" } = props;
  const setSelectedAddressId = props.setSelectedAddressId;
  const selectedAddressId = props.selectedAddressId;
  const actions = props.actions ? props.actions : false;
  const type = props.type ? props.type : false;
  const readOnlyShipping = props.readOnlyShipping ? props.readOnlyShipping : false;
  const handleReadOnlyShipping = props.handleReadOnlyShipping ? props.handleReadOnlyShipping : false;
  // const variant = props.variant ? props.variant : "checkout";
  const deliveryMethod = props.deliveryMethod;
  const deliveryDates = props.deliveryDates;
  const manualShippingDate = props?.manualShippingDate;
  const setManualShippingDate = props?.setManualShippingDate ? props.setManualShippingDate : undefined;
  const oneTimeDeliveryOfMixedCart = props.oneTimeDeliveryOfMixedCart ? props.oneTimeDeliveryOfMixedCart : undefined;
  const setOneTimeDeliveryOfMixedCart = props.setOneTimeDeliveryOfMixedCart ? props.setOneTimeDeliveryOfMixedCart : undefined;
  const isInstallation = props?.isInstallation === true ? true : false;
  const isDrawer = props.isDrawer;
  let mdSpace;
  if (readOnlyShipping) {
    mdSpace = 12;
  } else {
    mdSpace = 6;
  }

  const AddresListComponent = () => {

    if (addressList && addressList.length==0){
      <StyledTypography variant="subtitle2">{t("AddressBook.NoAddrForFilterMsg")}</StyledTypography>
    }
    
    return (
      <>
        {variant !== "MyAccount" ? (
          <StyledGrid container spacing={3}>
            {addressList?.map((address: any) => {
              /*if the current address id is the selected one, will render*/
              if (address.addressId === selectedAddressId) {
                return (
                  <StyledGrid item xs={12} md={mdSpace} className={readOnlyShipping && variant != "orderResume" ? "addressList--styledGrid-noPaddingTop" : ""}>
                    <StyledGrid item xs={12} key={address.nickName} >
                      <AddressCard
                        addressId={address.addressId}
                        nickName={address.nickName}
                        actions={actions}
                        type={type}
                        setSelectedAddressId={setSelectedAddressId}
                        selectedAddressId={selectedAddressId}
                        hideEdit={hideEdit}
                        readOnly={readOnlyShipping}
                        handleReadOnlyShipping={handleReadOnlyShipping}
                        variant={variant}
                        deliveryMethod={deliveryMethod}
                        deliveryDates={deliveryDates}
                        manualShippingDate={manualShippingDate}
                        setManualShippingDate={setManualShippingDate}
                        oneTimeDeliveryOfMixedCart={oneTimeDeliveryOfMixedCart}
                        setOneTimeDeliveryOfMixedCart={setOneTimeDeliveryOfMixedCart}
                        {...{ selectedNickName }}
                        isInstallation={isInstallation}
                        isDrawer={isDrawer}
                      />
                    </StyledGrid>
                  </StyledGrid>
                );
              } else {
                return null;
              }
            })}
            {!readOnlyShipping && (
              <>
                <StyledGrid item xs={12} md={6}>
                  {addressList?.map((address: any) => {
                    /*if the current address id is not equals to the selected, will render*/
                    if (address.addressId !== selectedAddressId) {
                      return (
                        <>
                          <StyledGrid item xs={12} key={address.nickName}>
                            <StyledGrid item xs={12}>
                              <AddressCard
                                addressId={address.addressId}
                                nickName={address.nickName}
                                actions={actions}
                                type={type}
                                setSelectedAddressId={setSelectedAddressId}
                                selectedAddressId={selectedAddressId}
                                hideEdit={hideEdit}
                                readOnly={readOnlyShipping}
                                variant={variant}
                                isInstallation={isInstallation}
                                {...{ selectedNickName }}
                              />
                            </StyledGrid>
                          </StyledGrid>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </StyledGrid>

              </>
            )}
          </StyledGrid>
        ) : null}

        {variant === "MyAccount" ? (
          <>
            {addressList?.map((address: any) => (
              <StyledGrid item xs={12} md={4} key={address.nickName}>
                <StyledGrid item xs={12}>
                  <AddressCard
                    addressId={address.addressId}
                    nickName={address.nickName}
                    actions={actions}
                    type={type}
                    setSelectedAddressId={setSelectedAddressId}
                    selectedAddressId={selectedAddressId}
                    hideEdit={hideEdit}
                    readOnly={readOnlyShipping}
                    variant={variant}
                    {...{ selectedNickName }}
                  />
                </StyledGrid>
              </StyledGrid>
            ))}
          </>
        ) : null}
      </>
    );
  }


  return <AddresListComponent/>

};

export { AddressList };
