import React, { FC, useEffect, useMemo } from "react";
//Components
import { AllowScroll, DisableScroll } from "../../../hdm/functions/utils";
import { StyledBox, StyledSwipeableDrawer, StyledTypography } from "../../../hdm/elements/";
// Ui
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
//Styles

//Images
import HomeDepotLogo from "../../../assets/homedepot/images/TheHomeDepot.png";
import HomeDepotLogoProBlack from "../../../assets/homedepot/images/TheHomeDepotProBlack.svg";
import styled from "styled-components";
import { palette } from "../../../hdm/themes";
import { useTranslation } from "react-i18next";
import { useSite } from "../../../_foundation/hooks/useSite";
import { Divider } from "@material-ui/core";
import { EMPTY_STRING } from "../../../constants/common";

type Tvariant =
  | "menu"
  | "stores"
  | "cart"
  | "facet"
  | "especificaciones-tecnicas"
  | "disponibilidad"
  | "msi"
  | "delivery"
  | "bopis"
  | "informacion-adicional"
  | "order"
  | "orderResume"
  | "instalation"
  | "promotions"
  | "payment-instructions"
  | "instructions-oxxo"
  | "seguimiento-entrega"
  | "checkout-profiles"
  | "mini-sign-in"
  | "super-sku"
  | "kit-specifications"
  | "wishList-drawer"
  | "buy-by-color"
  | "giftProduct"
  | "saveMore"
  | "checkout"
  | "instructions-creditAr";

interface IDrawerProps {
  isOpen: boolean;
  children?: JSX.Element[] | JSX.Element;
  setOpenDrawer: (boolean) => void;
  variant?: Tvariant;
  hideCategories?: () => void;
  title?: string;
  showMemoized?: boolean;
  currentStore?: any;
  setStateSelected?: (any) => void;
  disableSwipeToOpen?: boolean;
}

const StyledSwipeableDrawers = styled((props: any) => <StyledSwipeableDrawer {...props} />)`
  ${({ theme, ..._props }) => `
    .MuiDrawer-paper{
      //position: relative;
      ${
        _props?.anchor === "bottom"
          ? ""
          : `
      width: 90% !important;
      padding: 0!important;
      overflow-x: hidden;
      max-width: 360px!important;
      min-width: 288px!important;
      `
      }
      
    }

    .card-drawer-details-container .MuiPaper-root {
      border-radius: 0;
    }

    .card-drawer-details-container .MuiAccordionSummary-root {
      border-bottom: 2px solid #CCCCCC;
      min-height: 47px;
      opacity: 1;
    }
    .card-drawer-details-container .MuiAccordionDetails-root {
      padding: 0;
    }
    .card-drawer-details-container .MuiListItem-root {
      padding: 6px 15px;
    }
    .card-drawer-details-container .MuiListItem-root a {
      color: #5796BA;
      font-size: 0.9rem;
    }

    .card-drawer-details-container {
      padding: 0px;
    }
    .card-drawer-details-container .MuiAccordionSummary-content {
      padding: 0;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 10px;
      margin: 0;
      font-size: 1.15em;
      font-weight: 600;

    }
    ul.popup__body-list .MuiSvgIcon-root {
      color:#FD8331;
      font-size:17px;
    }
    ul.popup__body-list .MuiTypography-root {
      font-size:17px;
      line-height:1;
      white-spaces: normal; 
    }
    .card-drawer-details-container .title { 
      font-size: 1.15rem;
      font-weight: 600;
    }

    .card-drawer-details-container .MuiIconButton-label {
      color: #FD8331;
      padding-right: 8px;
      font-size: 1.15em;
      font-weight: 600;
    }
    ul.popup__body-list {
      padding-left: 25px;
      margin-top: 0px;
      margin-bottom: 15px;
    }
    
    ul.popup__body-list .check {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }

    ul.popup__body-list li::marker {
        font-size: 25px;
        color: #f96302;
    }
    ul.popup__body-list--checked{
      list-style-type: none;
      top: -2px;
      padding-left: 0px;
    }
    ul.popup__body-list--bullet li::marker {
      display: none;
      padding-left: -5px;
      top: -2px;
      text-align: center;
    }

    ol.popup__body-list {
      counter-reset: count;
      list-style: none;
      margin-top: 10px;
      padding-left: 7px;
    }
    ol.popup__body-list li {
        margin: 0 0 0.5rem 0;
        counter-increment: count;
        position: relative;
        padding-left: 25px;
    }
    ol.popup__body-list li::before {
        content: counter(count);
        color: #fff;
        font-size: 1rem;
        position: absolute;
        left: -5px;
        top: -2px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        background: #f96302;
        border-radius: 50%;
        text-align: center;
    }
    .popup__body-benefits {
      border: solid 1px #eee;
      display: flex;
      justify-content: space-around;
      text-align: center;
      padding: 10px;
      margin-top: 20px;
    }
    .popup__body-benefits--subtitle {
      font-weight: 600;
      margin: 10px;
    }
    .popup__body-benefits--img {
        width: 50px;
    }
    .popup__footer {
      font-size: 0.9em;
      margin-top: 15px;
    }
    .MuiDrawer-root{
      width: 90%;
    }

    &.left-align {
      justify-content: flex-start;
    }
    
    .right-align {
      justify-content: flex-end;
    }

    .justify-content-left{
      justify-content: left !important;
    }
    .drawer{
      &__header-menu{
        max-width: 360px!important;
        min-width: 288px!important;
        width: 100%;
        position: sticky;
        top: 0px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        // border-bottom: 1px solid ${palette.gray["50"]};
        padding: 10px 0;
        img{
          width: ${_props?.isB2B ? `40px` : `32px`};
          height: ${_props?.isB2B ? `40px` : `32px`};
        }

        h6{
          margin-left: 5px;
          padding-left: 5px;
          font-weight: 300!important;
        }
        
        &-text{
          margin-left: ${theme.spacing(2)}px;
        }
      }

      &__icon-cont{
        height:50px;
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
          &-close{
            color: ${palette.gray["100"]};
            cursor: pointer;
            margin: ${theme.spacing(1)}px;
            font-size: 25px;
            &-checkout{
              width:20px;
              height:20px;
            }
            &-creditAr {
              color: ${palette.gray["200"]};
            }
          }
      }

      &__content{
        // margin-top: 50px;
        width: 100%;
        height: 100%;
        z-index: -1;
        .MuiTypography-root {
            //margin-left: -3px; TBD
        }
        &.fixed{
          overflow-y: hidden;
        }
      }

    }

    .drawer--homedepot-prologo {
      width: 40px;
      height: 40px;
    }

    .drawer_baseic_separator {
      padding-top: 10px;
      padding-bottom: 10px;
      &.instructions-creditAr {
        padding: 0;
        margin-top: ${theme.spacing(2)}px;
        margin-bottom: ${theme.spacing(2)}px;
      }
    }
      
  `}
`;

interface IHeaderMenu {
  variant: string;
  setOpenDrawer: (boolean) => void;
  hideCategories?: () => void;
  title?: string;
  currentStore: any;
  setStateSelected: any;
}
const HeaderMenu = ({
  variant,
  setOpenDrawer,
  hideCategories,
  currentStore,
  setStateSelected,
  ...props
}: IHeaderMenu) => {
  const { t } = useTranslation();

  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);

  const closeDrawer = () => {
    if (currentStore && setStateSelected) {
      setStateSelected(currentStore.currentStore.stateId);
    }
    setOpenDrawer(false);
    if (hideCategories) {
      setTimeout(() => {
        hideCategories();
      }, 200);
    }
  };

  /**
   * No agregar más titulos de drawer de esta manera ya que el código se vuelve poco sostenible
   * */
  return (
    <>
      <StyledBox className={"drawer__header-menu " + (variant === "instructions-oxxo" ? "justify-content-left" : "")}>
        <StyledBox
          className={`drawer__icon-cont ${
            variant === "stores" || variant === "facet" || variant === "menu" || variant === "seguimiento-entrega"
              ? "left-align"
              : "right-align"
          }`}>
          <HighlightOffIcon
            onClick={closeDrawer}
            className={`drawer__icon-cont-close
            ${variant === "checkout" && "drawer__icon-cont-close-checkout"}
            ${variant === "instructions-creditAr" && "drawer__icon-cont-close-creditAr"}`}
          />
        </StyledBox>
        {variant === "menu" && (
          <img
            src={isB2B ? HomeDepotLogoProBlack : HomeDepotLogo}
            className={isB2B ? "drawer--homedepot-prologo lazyload" : ""}
            alt="home depot logo"
          />
        )}
        <StyledTypography
          variant={variant === "promotions" ? "bodyBaseline" : "textSegment"}
          className={"drawer__header-menu-text "}>
          {props.title ? (
            props.title
          ) : (
            <>
              {variant === "menu" && "Menú"}
              {variant === "stores" && "Elige una tienda"}
              {variant === "cart" && "Productos en mi carrito"}
              {variant === "facet" && "Filtrar y ordenar"}
              {variant === "especificaciones-tecnicas" && "Especificaciones Técnicas"}
              {variant === "disponibilidad" && "Buscar disponibilidad"}
              {variant === "msi" && "Meses sin intereses"}
              {variant === "delivery" && "Zona de entrega"}
              {variant === "bopis" && "Tienda de entrega"}
              {variant === "informacion-adicional" && "Información adicional"}
              {variant === "order" && "Continúa con tu orden"}
              {variant === "instalation" && "Especificaciones de instalación"}
              {variant === "promotions" && t("Promotions.Labels.Title")}
              {variant === "payment-instructions" && t("PaymentInstructions.Labels.Title")}
              {variant === "instructions-oxxo" && t("OrderConfirmation.OrderComplete.Oxxo.DrawerTitle")}
              {variant === "seguimiento-entrega" && "Seguimiento de entrega"}
              {variant === "checkout-profiles" && "Compra rápida"}
              {variant === "mini-sign-in" && "Continúa con tu orden"}
              {variant === "super-sku" && "Selecciona tus opciones"}
              {variant === "kit-specifications" && "Especificaciones"}
              {variant === "wishList-drawer" && "Crear nueva lista"}
              {variant === "buy-by-color" && "Comprar por color"}
              {variant === "giftProduct" && "Con regalo"}
              {variant === "saveMore" && "Ahorra más"}
              {variant === "checkout" && t("DeliveryZone.ChooseDeliveryzoneCheckout.Title")}
            </>
          )}
        </StyledTypography>
      </StyledBox>
      <StyledBox
        className={`drawer_baseic_separator ${
          variant === "instructions-creditAr" ? "instructions-creditAr" : EMPTY_STRING
        }`}>
        <Divider />
      </StyledBox>
    </>
  );
};

const StyledSwipeableDrawerHDM: FC<IDrawerProps> = ({
  isOpen,
  setOpenDrawer,
  children,
  variant = "menu",
  hideCategories,
  showMemoized,
  currentStore,
  setStateSelected,
  ...props
}) => {
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const disableSwipeToOpen = props?.disableSwipeToOpen === true ? true : true;

  const showMemoizedChildren = showMemoized !== undefined ?? showMemoized;
  const initMemoizedChildren = useMemo(() => {
    return children;
  }, []);

  const updatedMemoizedChildren = useMemo(() => {
    return children;
  }, [children]);

  const closeDrawer = () => {
    if (currentStore && setStateSelected) {
      setStateSelected(currentStore.currentStore.stateId);
    }

    if (variant === "menu" && hideCategories) {
      setTimeout(() => {
        hideCategories();
      }, 200);
    }
    setOpenDrawer(false);
  };
  let idContainer = "";
  switch (variant) {
    case "stores":
      idContainer = "storeSelectorList";
      break;
    case "checkout-profiles":
      idContainer = "checkoutProfileSelectorList";
      break;
    case "menu":
      idContainer = "mainMenu";
      break;
  }

  return (
    <StyledSwipeableDrawers
      anchor={
        variant === "stores" || variant === "facet" || variant === "menu" || variant === "seguimiento-entrega"
          ? "left"
          : variant === "orderResume"
          ? "bottom"
          : "right"
      }
      open={isOpen}
      className={"drawer"}
      onOpen={() => setOpenDrawer(true)}
      onClose={closeDrawer}
      disableSwipeToOpen={disableSwipeToOpen}
      disableEnforceFocus
      isB2B={isB2B}>
      {variant !== "orderResume" && (
        <>
          {variant === "menu" && (
            <HeaderMenu
              variant={"menu"}
              setOpenDrawer={setOpenDrawer}
              hideCategories={hideCategories}
              title={props.title}
              currentStore={currentStore}
              setStateSelected={setStateSelected}
            />
          )}
          {variant !== "menu" && (
            <HeaderMenu
              currentStore={currentStore}
              setStateSelected={setStateSelected}
              variant={variant}
              setOpenDrawer={setOpenDrawer}
              title={props.title}
            />
          )}
        </>
      )}
      <StyledBox id={idContainer} className={variant === "facet" ? "drawer__content fixed" : "drawer__content"}>
        {showMemoizedChildren ? initMemoizedChildren : updatedMemoizedChildren}
      </StyledBox>
    </StyledSwipeableDrawers>
  );
};

export default StyledSwipeableDrawerHDM;
