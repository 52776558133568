import { Divider } from "@material-ui/core";
import StyledPriceFormat from "../../../components/custom-components/styled-price-format/StyledPriceFormat";
import { StyledBox, StyledButton, StyledGrid, StyledTypography } from "../../elements";

import { useTranslation } from "react-i18next";
import { getUnidadMedida, numberWithCommas } from "../../functions";
import styled from "styled-components";
import AddProductCart from "../../functions/AddProductCart";
import { hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "react";
import { OPEN_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { useSite } from "../../../_foundation/hooks/useSite";

const ContainerStyledTotalPrice = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

    &.styled--priceformat-container {
        width: 100%;
    }

    .styled--price-format {
        display: flex;
        align-items: end;
        width: 100%;
        height: 30px;
        margin-left: 10px;
        ${theme.breakpoints.down("xs")} {
            margin-left: 0px;
        }
    }

    .styled--addtocart-container {
        max-width: 300px;
        margin-left: 10px;
        ${theme.breakpoints.down("xs")} {
            max-width: 100%;
            margin-left: 0px;

        }
    }

`}
`;

export const MerchandisingAssociationTotalPrice = (props) => {
  const { selectedProducts, isDesktop } = props;

  const { t } = useTranslation();
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite } = useSite();

  const loginStatus = useSelector(loginStatusSelector);
  const isB2B = mySite?.isB2B;

  if (!selectedProducts) return null;

  let totalPrice: number = 0;
  const partNumber: any = [];
  const quantity: any = [];
  let hasMeassure = false;
  let unityMeasure = "";
  let wholeMeasure = "";
  selectedProducts.map((product) => {
    if (product?.isSelected) {
      partNumber.push(product?.partNumber);
      quantity.push(product?.buyQuantity);

      const price = numberWithCommas(product);
      const productPrice = parseFloat(price?.offerPrice.replaceAll(",", "")) * parseInt(product?.buyQuantity);
      totalPrice = totalPrice + productPrice;

      const quantityMeasure = product?.["x_measurements.quantityMeasure"]
        ? getUnidadMedida(product?.["x_measurements.quantityMeasure"])
        : "";

      if (quantityMeasure === "m2" || quantityMeasure === "m") {
        hasMeassure = true;
        unityMeasure = "metro lineal";
        wholeMeasure = "por caja";
      }
    }
  });

  const allImages = selectedProducts.filter((product) => product?.isSelected === true);

  return (
    <>
      {!isDesktop ? null : <Divider orientation="vertical" />}

      <ContainerStyledTotalPrice className="styled--priceformat-container">
        {!isDesktop ? (
          <StyledBox width="100%" className="padding-2">
            <Divider />
          </StyledBox>
        ) : null}
        <StyledBox className="styled--price-format bottom-margin-2">
          <StyledTypography variant="bodyBaseline" className="right-margin-2" component="span">
            {t("productDetail.getBundledItems")}
          </StyledTypography>
          <StyledPriceFormat priceUnformatted={totalPrice} isMerchandising={true} />
        </StyledBox>

        {hasMeassure ? (
          <StyledTypography variant="bodyCaption" className="left-margin-2 vertical-margin-2 color-gray200">
            Precio calculado con el precio {wholeMeasure} / {unityMeasure}
          </StyledTypography>
        ) : null}

        <StyledBox className="styled--addtocart-container">
          {isB2B && !loginStatus ?
          <StyledButton
            variant="contained"
            className={"productBoxVerDetalle"}

            disableRipple={true}
            fullWidth
            onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}
            >
            {t("productDetail.LoginToAddCart")}
          </StyledButton>
          :
          <AddProductCart
            quantity={quantity}
            partnumber={partNumber}
            variant="outlined"
            wording="Agregar todo al carrito"
            colorCodeSelected={hdmDrawerColorData?.colorCode}
            disabled={allImages?.length === 0 ? true : false}
          />
          }
        </StyledBox>
      </ContainerStyledTotalPrice>
    </>
  );
};
