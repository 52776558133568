/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import { StyledTypography, StyledBox, StyledLink, StyledCardEmpty } from "../../elements";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { palette } from "../../themes";
const StyledAccountLink = styled(({ ...props }) => <StyledLink {...props} />)`
  ${({ theme }) => `
    
    &.account-link {
      text-decoration: none;
    }
    .gray300 {
      color: ${palette.gray["300"]};
    }
    .card-resumen-cuentas {
      margin-bottom: 10px;
    }

    .link-descripcion {
      width: calc(100% - 39px);
      padding: 10px;
    }
  `}
`;

interface LinkBoxProps {
  id?: string;
  title: string;
  disabledTitle: string;
  description: string;
  url: string;
  icon: any;
  disabled?: boolean;
  selected?: string | boolean;
  page: string;
}

export function StyledLinkBox({
  title,
  id = "",
  disabledTitle,
  description,
  disabled,
  icon,
  url,
  selected,
  page
}: LinkBoxProps) {

  return (
    <StyledAccountLink id={id} to={url} title={description} className="account-link">
      <StyledCardEmpty className="card-resumen-cuentas" style={{ border: selected ? `1px solid ${palette.primary.main}` : "" }}>
        <StyledBox display="flex">
          <StyledBox className="link-descripcion">
            <StyledBox display="flex" alignItems="center">
              <StyledBox>{icon}</StyledBox>
              <StyledTypography variant="bodyBaseline" weight="semibold" style={{ marginLeft: "5px" }}>
                {title}
              </StyledTypography>
            </StyledBox>
            <StyledBox>
              <StyledTypography variant="bodyBaseline" className="gray300">
                {description}
              </StyledTypography>
            </StyledBox>
          </StyledBox>
          <StyledBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: "39px", backgroundColor: selected ? palette.primary.main : "" }}>
            <ChevronRightIcon
              style={{ height: "24px",  color: selected ? palette.background.paper : palette.primary.main }}
            />
          </StyledBox>
        </StyledBox>
      </StyledCardEmpty>
    </StyledAccountLink>
  );
}
