import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProductQuantity from "../../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import { getPesoMedida, getPromoicons, getUnidadMedida, numberWithCommas, ribbonsPromoIconsImg } from "../../functions";
import AddProductCart from "../../functions/AddProductCart";

import { ContainerStyledEmarsysCard, StyledCardMediaProduct, ContainerStyledRating } from "./Container-emarsys-product";
import { StyledBox, StyledTypography, StyledButton, StyledCardEmpty, StyledLinkTextSecondary } from "../../elements";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { checkIfHasColorBase } from "../../functions/utils";
import { lineaGamaColorsRdc, hdmColorsRdc } from "../../../redux/selectors/hdmData";
import { StyledProductCardImage } from "../../elements/product-card/Styled-product-card-image";
import { CardMedia } from "@material-ui/core";
import ProductCardBasicInfo from "../../elements/product-card/Styled-product-card-basic-info";
import ProductPrice from "../../elements/product-card/styled-product-price";
import { useTranslation } from "react-i18next";
import { useSite } from "../../../_foundation/hooks/useSite";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { OPEN_SIGN_IN_POPUP_ACTION } from "../../../redux/actions/drawer";
import { currentStoreSelector } from "../../../redux/selectors/current-store";

interface Props {
  product: any;
}

const EmarsysProduct = (props: Props) => {
  const { product } = props;

  // const [isReady, setIsReady] = useState(false)
  // const [colorCode, setColorCode] = useState<any>(null)
  // const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  // const hdmColors = useSelector(hdmColorsRdc);
  const [quantity, setQuantity] = useState<string>("1");
  const storeConfData = useSelector(onlineStoreRdc);
  const { t } = useTranslation()
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const loginStatus = useSelector(loginStatusSelector);
  const dispatch = useDispatch();
  const storeSelector = useSelector(currentStoreSelector);
  const currentMarketId = storeSelector?.currentStore?.marketId;
  const currentStoreId = storeSelector.currentStore.stLocId;
  // useEffect(() => {
  //   if (product && hdmLineaGamaColor && hdmColors && hdmLineaGamaColor?.lenght !== 0 && hdmColors?.lenght !== 0) {
  //     // buscarColorBase();
  //   }

  //   return () => {
  //     console.log('')
  //   };
  // }, [product, hdmLineaGamaColor, hdmColors]);

  // const buscarColorBase = async () => {

  //   const hasProductBaseColor = await checkIfHasColorBase(hdmColors, product)

  //   let baseGamaColorDefault: any = null
  //   if(hasProductBaseColor){
  //     const { branchColor, lineColor, baseColor } = hasProductBaseColor
  //     const pinturasHDM = hdmLineaGamaColor
  //     const colorBase = pinturasHDM.filter((database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === '#N/D')[0];
  //     baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0]
  //     console.log("hasProductBaseColor XX", hasProductBaseColor);
  //     console.log("baseGamaColorDefault XX", baseGamaColorDefault);
  //     console.log("partNumber XX", product["identifier.mpn.raw"]);
  //     // colorCode = baseGamaColorDefault?.valor
  //     setColorCode(baseGamaColorDefault?.valor)
  //   }

  //   setIsReady(true)

  // }

  if (!product || !storeConfData) return null


  const manufacturer = product?.manufacturer ? product?.manufacturer : ""
  const attributes = product?.attributes ? product?.attributes : null
  const urlRibbons = ribbonsPromoIconsImg(storeConfData?.IMAGE_SERVER_HOSTNAME);
  const promociones = getPromoicons(attributes, isB2B, currentStoreId, currentMarketId);
  const ratingPromedio = product["x_ratings.rating"] ? product["x_ratings.rating"] : 0;
  const ratingReviews = product["x_ratings.total_recommendations"] ? product["x_ratings.total_recommendations"] : 0;
  const ratingTotalReviews = product["x_ratings.total_reviews"] ? product["x_ratings.total_reviews"] : 0;
  let quantityMeasure = product["x_measurements.quantityMeasure"]
    ? getUnidadMedida(product["x_measurements.quantityMeasure"])
    : "";
  const weightMeasure = product["x_measurements.weightMeasure"] ? getPesoMedida(product["x_measurements.weightMeasure"]) : ""
  const nominalQuantity = product?.["x_measurements.nominalQuantity"]
    ? parseInt(product["x_measurements.nominalQuantity"]).toString()
    : "1";
  const weightmeasure = product["x_measurements.weightMeasure"] ? product["x_measurements.weightMeasure"] : "";
  const offerPrice = product.price.filter((precio) => precio.usage === "Offer")[0];
  const displayPrice = product.price.filter((precio) => precio.usage === "Display")[0];
  const to = product?.seo?.href ? product?.seo?.href : ""

  const handleSetQuantity = (value: string) => {
    setQuantity(value);
  };

  function ahorroWithComas(ahorro: number) {
    const result = ahorro
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parseFloat(result);
  }

  const price = numberWithCommas(product);

  let isMeassure = false;
  if (quantityMeasure !== "") {
    isMeassure = true;
  }

  let ahorro: number = 0;

  if (quantityMeasure.toLowerCase() === "c62") {
    if (price.listPrice && price.offerPrice) {
      ahorro = parseFloat(price.listPrice) - parseFloat(price.offerPrice);
      ahorro = ahorroWithComas(ahorro);
    }
    quantityMeasure = "";
    isMeassure = false;
  } else {
    if (price.listMedidaPrice && price.offerMedidaPrice) {
      ahorro = parseFloat(price.listMedidaPrice) - parseFloat(price.offerMedidaPrice);
      ahorro = ahorroWithComas(ahorro);
    }
  }
  return (
    <ContainerStyledEmarsysCard data-scarabitem={product?.id} className="scarab-item">
      <StyledCardEmpty>
        <StyledBox className="styled--emarsysproductcard-container" >
          <StyledBox className={"product-card-img-info"}>
            <StyledProductCardImage
              partNumber={product?.partNumber}
              isWishProduct={product?.isWishProduct}
              storeConfData={storeConfData}
              name={product?.name}
              to={to}
              promociones={promociones}
              urlRibbons={urlRibbons}
              isEmarsysProduct={true}
            />

            <ProductCardBasicInfo
              productPartNumber={product?.partNumber}
              productManufacturer={manufacturer}
              productName={product?.name}
              ratingPromedio={ratingPromedio}
              ratingReviews={ratingTotalReviews}
              to={to}
              isEmarsysProduct={true}
            />

          </StyledBox>

          <ProductPrice
            productData={product}
            weightMeasure={weightMeasure}
            quantityMeasure={quantityMeasure}
            promociones={promociones}
            urlRibbons={urlRibbons}
          />

          <StyledBox className={`produt-card-caption ${!promociones ? "produt-card-notCaption" : ""}`}>
            {promociones &&
              ((promociones.includes("STORE_ONLY_AVAILABLE") && !isB2B) ||
                (promociones.includes("PRO_STORE_ONLY_AVAILABLE") && isB2B && loginStatus)) ? (
                <StyledTypography variant={"bodyBaseline"} className="color-gray300">
                  {t("productBox.VentaExclusivaTienda")}
                </StyledTypography>
              ) : !loginStatus && isB2B ? (
                <StyledBox className="link-preferencial-price">
                  <StyledLinkTextSecondary variant={"bodySubText"} font="12px" color="secondary" onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                  {t("productBox.Login")}
                  </StyledLinkTextSecondary>
                  <StyledTypography variant={"bodySubText"} className="color-gray300 left-margin-1">
                  {t("productBox.PreferencialPrice")}
                  </StyledTypography>
                </StyledBox>
              ) : (
                <>
                  {product && parseInt(product?.["x_measurements.nominalQuantity"]) > 1 && (
                    <StyledTypography variant="bodySubText" className={"color-gray300"}>
                      {t("productDetail.CartOrderMinimalQuantity") + parseInt(product?.["x_measurements.nominalQuantity"])}{" "}
                    </StyledTypography>
                  )}
                </>
              )
            }
          </StyledBox>

          {promociones &&
            ((promociones.includes("STORE_ONLY_AVAILABLE") && !isB2B) ||
              ((promociones.includes("PRO_STORE_ONLY_AVAILABLE") || !loginStatus) && isB2B)) ? (
              <StyledBox className={"styled--showdetails-container"}>
                <Link to={to}>
                  <StyledButton
                    variant="outlined"
                    className={"productBoxVerDetalle"}
                    disableRipple={true}
                    fullWidth
                    onClick={null}>
                    {t("productBox.VerDetalle")}
                  </StyledButton>
                </Link>
              </StyledBox>
            ) : (
              <StyledBox className="styled--quantityproduct-container">
                <ProductQuantity
                  nominalQuantity={nominalQuantity}
                  quantity={quantity}
                  handleSetQuantity={handleSetQuantity}
                  isNotZero={true}
                />
                <StyledBox ml={1} width={"100%"}>
                  <AddProductCart
                    quantity={quantity}
                    partnumber={product.partNumber}
                    variant="outlined"
                    wording={t("productBox.addToCart")}
                    manufacturer={manufacturer}
                    attributes={attributes}
                  />
                </StyledBox>
            </StyledBox>
            )}


          {/* {!product.buyable ? (
            <StyledBox className={"verDetalle"}>
              <Link to={product?.seo?.href ? to : ""}>
                <StyledButton
                  variant="outlined"
                  className={"productBoxVerDetalle"}
                  disableRipple={true}
                  fullWidth
                  onClick={null}>
                  {t("productBox.showDetails")}
                </StyledButton>
              </Link>
            </StyledBox>
          ) : (
            <StyledBox className="styled--quantityproduct-container">
              <ProductQuantity
                nominalQuantity={nominalQuantity}
                quantity={quantity}
                handleSetQuantity={handleSetQuantity}
              />
              <StyledBox ml={1} width={"100%"}>
                <AddProductCart
                  quantity={quantity}
                  partnumber={product.partNumber}
                  variant="outlined"
                  wording={t("productBox.addToCart")}
                  manufacturer={manufacturer}
                  attributes={attributes}
                />
              </StyledBox>
            </StyledBox>
          )} */}
        </StyledBox>
      </StyledCardEmpty>
    </ContainerStyledEmarsysCard>
  );
};

export default EmarsysProduct;
