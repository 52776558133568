import { useEffect, useState, useMemo } from 'react';
//UI
import { StyledBox, StyledTypography, StyledList } from "../../../hdm/elements";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { StyledBoxHDM, StyledDivider } from "./categories-styles";
import CategoriesListItem from "./CategoriesListItem";
//Router
import { useNavigate } from "react-router";
import styled from "styled-components";
import { palette } from "../../../hdm/themes";
import { MenuItemHDM, sortCategories, StyledBoxContainer } from "./MenuDesktop";
import CardIcon from "../../custom-components/shared/CardIcon";
import { StyledListItem } from "@hcl-commerce-store-sdk/react-component";
import EspotHdm from '../../custom-components/shared/EspotHdm';
import { useSite } from '../../../_foundation/hooks/useSite';
import { CATALOG_PREFIX } from '../../../constants/routes';



export const StyledTypographyLink = styled((props: any) => <StyledTypography {...props} />)`
  ${({ theme }) => `
        color: ${palette.secondary.main};   
        padding-top: ${theme.spacing(1)}px;      
  `}
`;

const INIT_CATEGORY = {
    seo: {
        href: "/",
    },
    name: "",
};

const MenuMobile = ({ categories, hideCategories, hideDrawer, display, ...props }) => {
    const navigate = useNavigate();

    const { mySite } = useSite();
    const isB2B = mySite?.isB2B;

    // const [currentCategory, setCurrentCategory] = useState<any>(INIT_CATEGORY);
    const [currentSubCat, setCurrentSubCat] = useState<any>(INIT_CATEGORY);
    const [currentChildCat, setCurrentChildCat] = useState<any>(INIT_CATEGORY);
    const [showFeatured, setShowFeatured] = useState(true);

    //sorted
    const [sortedCategories, setSortedCategories] = useState<any>();

    const setCategorySelected = (category) => {
        if (!category.children) {
            if (!category.seo && category.links)
                navigate(`${CATALOG_PREFIX}${category.links.self.href}`, { replace: false });
            else 
                navigate(`${CATALOG_PREFIX}${category.seo.href}`, { replace: false });
            hideDrawer();
            hideCategories();
            return;
        }
        setShowFeatured(false);
        // setCurrentCategory(category);
        const subCatSort = {
            ...category,
            children: sortCategories(category.children),
        };
        setCurrentSubCat(subCatSort)

    }

    const setSubCatSelected = (category) => {
        if (!category.children) {
            if (!category.seo && category.links)
                navigate(`${CATALOG_PREFIX}${category.links.self.href}`, { replace: false });
            else 
                navigate(`${CATALOG_PREFIX}${category.seo.href}`, { replace: false });
            hideDrawer();
            hideCategories();
            return;
        }
        const childCatSort = {
            ...category,
            children: sortCategories(category.children),
        };
        setCurrentChildCat(childCatSort);
    }

    const clearMenuOptions = () => {
        if (currentChildCat.children) {
            setCurrentChildCat(INIT_CATEGORY);
            return;
        }
        if (currentSubCat.children)
            setCurrentSubCat(INIT_CATEGORY);

        setShowFeatured(true);
        if (showFeatured) {
            hideCategories()
            return;
        }
    }

    const handleNavigate = (category) => {
        if (category.seo.href) {
            navigate(`${CATALOG_PREFIX}${category.seo.href}`, { replace: true });
            hideDrawer();
            hideCategories();
            return;
        }
    }

    const closeDrawer = () => {
        hideCategories();
        hideDrawer();
    }

    useEffect(() => {
        const catSort = sortCategories(categories);
        setSortedCategories(catSort);
    }, [categories, sortedCategories]);


    /** 
    if (!categories.length){ 
        return (
        <StyledBoxProgressHDM className={'circular'}>
            <StyledCircularProgress className={'circular'}/>
        </StyledBoxProgressHDM>
         )
    }
    **/



    const DepartmentMenu = useMemo(() => {
        return (
            <StyledBoxContainer>
                {isB2B ?
                    <>
                        <EspotHdm espotName='ProMobileMenu_Departments_SuperiorSection' />
                    </>
                    :
                    <>
                        <EspotHdm espotName='MobileMenu_Departments_SuperiorSection' />
                        {/* Saved just in case */}
                        {/* {props.menuComponents && props.menuComponents.length > 0 && props.menuComponents[0].content.cards.map((card, index) => {
                    return <CardIcon
                        data={card}
                        key={index}
                        index={index}
                        closeMenu={closeDrawer}
                    />
                })} */}
                    </>
                }
            </StyledBoxContainer>
        )
    }, [props.menuComponents])


    return (
        <StyledBoxHDM className={'cat-cont'}
            style={{ padding: 0, display: display ? "" : "none" }}
        >
            <StyledBox
                id="menu-dept-return"
                onClick={clearMenuOptions}
                className={'cat-cont__return-sect'}
            >
                <ArrowBackIosIcon className={'cat-cont__return-sect-icon'} />
                <StyledTypography variant={'bodyBaseline'}>Regresar</StyledTypography>
            </StyledBox>
            {
                showFeatured && (
                    <StyledBox>
                        <StyledTypography
                            variant={"headingFeatured"}
                            style={{ paddingLeft: '10px' }}
                        >
                            {isB2B ? <EspotHdm espotName='ProMobileMenu_Departments_SuperiorTrigger' /> : "DESTACADOS"}
                        </StyledTypography>

                        <StyledBoxContainer style={{ marginTop: '5px' }}>
                            {DepartmentMenu}
                        </StyledBoxContainer>
                    </StyledBox>
                )
            }


            {
                currentSubCat.name !== ''
                    ? (
                        <MenuItemHDM style={{ borderBottom: 'none' }}>
                            <StyledTypography
                                variant={'headingFeatured'}
                                className={'cat-cont__title-line'}
                                onClick={() => handleNavigate(currentChildCat.name !== ''
                                    ? currentChildCat : currentSubCat)}
                            >
                                {currentChildCat.name !== ''
                                    ? currentChildCat.name.toUpperCase()
                                    : currentSubCat.name.toUpperCase()
                                }
                            </StyledTypography>
                        </MenuItemHDM>
                    )

                    : (
                        <MenuItemHDM style={{ pointerEvents: 'none', borderBottom: 'none' }}>
                            <StyledTypography
                                variant={'headingFeatured'}
                                className={'cat-cont__title'}
                            >
                                TODOS LOS DEPARTAMENTOS A-Z
                            </StyledTypography>
                        </MenuItemHDM>
                    )
            }

            <StyledDivider className={'divider'} />
            <StyledList
                component="nav"
                style={{ padding: 0 }}
            >
                {
                    !currentSubCat.children && categories.map(category => (
                        <>
                            <CategoriesListItem
                                idText={`departmentMenu_${category.id}`}
                                key={category.identifier}
                                category={category}
                                setCategory={() => setCategorySelected(category)}
                            />
                            <StyledDivider className={'divider'} />
                        </>
                    ))
                }
                {
                    (currentSubCat.children && !currentChildCat.children) && currentSubCat.children.map((subCat, index) => (
                        <>
                            <CategoriesListItem
                                idText={`categoryLink_${currentSubCat.id}_${subCat.id}`}
                                key={subCat.identifier}
                                category={subCat}
                                setCategory={() => setSubCatSelected(subCat)}
                            />
                            <StyledDivider className={'divider'} />
                            {index === (currentSubCat.children.length - 1) ? (
                                <StyledListItem style={{ "margin-top": "10px" }}>
                                    {!isB2B ?
                                        <>
                                            <EspotHdm espotName={`${currentSubCat.identifier}_MenuCategory_Inferior_Section`} />
                                        </>
                                        : null}
                                </StyledListItem>
                            ) : ""}
                        </>
                    ))
                }
                {
                    currentChildCat.children && currentChildCat.children.map((children, index) => (
                        <>
                            <CategoriesListItem
                                idText={`subcategoryLink_${currentSubCat.id}_${currentChildCat.id}_${children.id}`}
                                key={children.identifier}
                                category={children}
                                setCategory={() => handleNavigate(children)}
                            />
                            <StyledDivider className={'divider'} />

                            {index === (currentChildCat.children.length - 1) ? (
                                <StyledListItem style={{ "margin-top": "10px" }}>
                                    {!isB2B ?
                                        <>
                                            <EspotHdm espotName={`${currentChildCat.identifier}_MenuCategory_Inferior_Section`} />
                                        </>
                                        : null}
                                </StyledListItem>
                            ) : ""}
                        </>
                    ))
                }
            </StyledList>
            <StyledBox>
                <EspotHdm espotName="_ProMenuCategory_InferiorSection" />
            </StyledBox>
        </StyledBoxHDM>
    );
};

export default MenuMobile;
