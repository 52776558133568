import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { StyledBox, StyledGrid, StyledTypography } from "../../elements";
import { paintColorsPalette } from "../../functions/utils";
import { lineaGamaColorsRdc, hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";


const ContainerColorsPalette = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme }) => `

    .styled--palettecolor-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
    }
    
    .styled--rowcolor-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }

    .styled-individualcolor-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 57px;
        height: 56px;
    }

    .styled--individualcolor-border {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 1px solid ${theme.palette.gray["50"]};
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            border: 1px solid ${theme.palette.primary.main};
        }

    }

    .styled--gama-selected {
        border: 1px solid ${theme.palette.primary.main};
    }


`}
`;

const ContainerIndividualColor = styled(({ ...props }) => <StyledGrid {...props} />)`
${({ theme, color }) => `

    border-radius: 50%;
    width: 77%;
    height: 77%;
    background-color: ${color};

    &.styled--border-color {
        border: 1px solid #EEEEEE;
    }

`}
`;

export const ColorsPaletteDefault = (props) => {

    const { 
        // handleOpenBuyByColor, 
    gamaSelected, isDrawer } = props
    const [colorsPalette, setColorsPalette] = useState<any>(null)
    const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
    const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
    const dispatch = useDispatch<Dispatch<any>>();

    useEffect(() => {
        const colors = paintColorsPalette()
        setColorsPalette(colors.filter(color => color.name !== '#N/D'))
    }, [])

    const handleOpenBuyByColor = (gama) => {
        const selectedGamaColors = hdmLineaGamaColor.filter(
            (lineaGama) => lineaGama.linea === hdmDrawerColorData?.lineColorValue && lineaGama.gama === gama
            )[0];
    
        const query = {
          url: "",
          data: {
            ...hdmDrawerColorData,
            gamaColors: selectedGamaColors?.colores,
            isOpen: true,
            gama: gama
          },
          option: "HDM_DRAWER_COLOR_DATA",
          fetch: false,
        };

        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    };
    
    if(!colorsPalette) return null

    return (
        <ContainerColorsPalette>
        <StyledBox className="styled--palettecolor-container" id={isDrawer ? "color-gamma-palettes" : ''}>
          <StyledBox className="styled--rowcolor-container bottom-margin-1">

            {colorsPalette.slice(0,5).map(color => {
                let isSelected
                
                if(color.name === hdmDrawerColorData?.gama && isDrawer){
                    isSelected = 'styled--gama-selected'
                }else  if(color.name === gamaSelected && !hdmDrawerColorData?.open && !isDrawer){
                    isSelected = 'styled--gama-selected'
                }
                return (
                    <StyledBox className="styled-individualcolor-container" key={color.name}>
                        <div className={`styled--individualcolor-border ${isSelected}`} onClick={() => handleOpenBuyByColor(color.name)}>
                            <ContainerIndividualColor className={`${color.name === 'Pasteles' ? 'styled--border-color' : ''}`} color={color.color}>

                            </ContainerIndividualColor>
                        </div>
                    <StyledTypography variant="bodySubtext" className="top-padding-1">
                        {color.name}
                    </StyledTypography>
                    </StyledBox>
                )
            })}

          </StyledBox>
          <StyledBox className="styled--rowcolor-container">

            {colorsPalette.slice(5,10).map(color => {
                let isSelected
                
                if(color.name === hdmDrawerColorData?.gama && isDrawer){
                    isSelected = 'styled--gama-selected'
                }else  if(color.name === gamaSelected && !hdmDrawerColorData?.open && !isDrawer){
                    isSelected = 'styled--gama-selected'
                }
                return (
                    <StyledBox className="styled-individualcolor-container" key={color.name}>
                        <div className={`styled--individualcolor-border ${isSelected}`} onClick={() => handleOpenBuyByColor(color.name)}>
                            <ContainerIndividualColor className={`${color.name === 'Pasteles' ? 'styled--border-color' : ''}`} color={color.color}>

                            </ContainerIndividualColor>
                        </div>
                    <StyledTypography variant="bodySubtext" className="top-padding-1">
                        {color.name}
                    </StyledTypography>
                    </StyledBox>
                )
            })}

          </StyledBox>

        </StyledBox>
        </ContainerColorsPalette>
    )
}