import { useState, useEffect } from "react";
import PageViewEvent from "./PageViewEvent";

const EventId = () => {
  const [ruta, setRuta] = useState(window.location.pathname);

  useEffect(() => {
    console.log("pageView");
  }, [window.location.href]);

  return (
    <>
      <PageViewEvent />
    </>
  );
};

export default EventId;
