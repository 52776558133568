/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import loginIdentity from "../../../_foundation/apis/transaction/loginIdentity.service";

//Redux
import { FYF_LOGIN_SUCCESS_ACTION, fyfloginErrorAction } from "../../actions/fyflogin";

function* fyfloginAndFetchDetail(payload: any) {
  const response = yield call(loginIdentity.login, payload);
  const _user = {
    ...response.data,
    physicalStoreId: payload.physicalStoreId,
  };
  yield put(FYF_LOGIN_SUCCESS_ACTION(_user));
}

export function* fyflogin(action: any) {
  try {
    const payload = action.payload;
    yield* fyfloginAndFetchDetail(payload);
  } catch (error) {
    yield put({ type: fyfloginErrorAction, error });
  }
}
