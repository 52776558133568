import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ContainerStyledBox,
  ContainerStyledCardMedia,
  ContainerStyledTypography,
  ContainerStyledAvatar,
} from "../../containers-precio-promocion";
import ProductQuantity from "../../../../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import AddProductCart from "../../../../functions/AddProductCart";
import { hdmDrawerColorDataRdc, onlineStoreRdc } from "../../../../../redux/selectors/hdmData";

interface Props {
  brand: any;
  attributes: any;
  partNumber: any;
  shortDescription: any;
  nominalQuantity: any;
  ahorramasValues: any;
  handleClose: () => void;
}

const AhorraMasSeccionUno = (props: Props) => {
  const { ahorramasValues, handleClose, brand, attributes, partNumber, shortDescription, nominalQuantity } = props;
  const [quantity, setQuantity] = useState<string>("1");
  const storeConfData = useSelector(onlineStoreRdc);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);

  const [colorCodeSelected, setColorCodeSelected] = useState<any>(null);
  const handleSetQuantity = (value: string) => {
    setQuantity(value);
  };

  useEffect(() => {
    if (hdmDrawerColorData?.colorCode) {
      setColorCodeSelected(hdmDrawerColorData?.colorCode);
    } else {
      setColorCodeSelected(null);
    }
  }, [hdmDrawerColorData]);

  const imageURL = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-m.jpg`;

  return (
    <>
      <ContainerStyledBox className="dialog-seccionone">
        <ContainerStyledBox display="flex" className="dialog-seccionagrega">
          <ContainerStyledAvatar className="dialog-numberone">
            <ContainerStyledTypography varinat="bodySubText" weight="semibold">
              1
            </ContainerStyledTypography>
          </ContainerStyledAvatar>
          <ContainerStyledBox>
            <ContainerStyledTypography variant="bodyBaseline" component="span">
              &nbsp;Agrega&nbsp;
            </ContainerStyledTypography>
            <ContainerStyledTypography variant="bodyBaseline" weight="semibold" component="span">
              {` $${ahorramasValues.compra}`} en {ahorramasValues.compraGrupo}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>

        <ContainerStyledBox display="flex">
          <ContainerStyledBox>
            <ContainerStyledCardMedia image={imageURL} className="dialog-ahorramas-image" title="" component="img" />
          </ContainerStyledBox>
          <ContainerStyledBox className="dialog-marca-descripcion">
            <ContainerStyledBox className="product-title-text">
              <ContainerStyledTypography variant="bodyBaseline" weight="semibold" component="span">
                {brand}&nbsp;
              </ContainerStyledTypography>
              <ContainerStyledTypography variant="bodyBaseline" component="span">
                {shortDescription}
              </ContainerStyledTypography>
            </ContainerStyledBox>
            <ContainerStyledTypography variant="bodySubText" className="dialog-textsku" component="p">
              SKU {partNumber}
            </ContainerStyledTypography>
          </ContainerStyledBox>
        </ContainerStyledBox>

        <ContainerStyledBox display="flex" mt={2} height={50} alignItems="flex-end">
          <ProductQuantity
            nominalQuantity={nominalQuantity}
            quantity={quantity}
            handleSetQuantity={handleSetQuantity}
          />
          <ContainerStyledBox ml={1} width={"100%"}>
            <AddProductCart
              quantity={quantity}
              partnumber={partNumber}
              colorCodeSelected={colorCodeSelected}
              variant="outlined"
              wording="Agregar"
              manufacturer={brand}
              attributes={attributes}
              handleClose={handleClose}
            />
          </ContainerStyledBox>
        </ContainerStyledBox>
      </ContainerStyledBox>
    </>
  );
};

export default AhorraMasSeccionUno;
