import { Backdrop, CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { backdropLoaderSelector, backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";

const MAX_Z_INDEX = 2147483647;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: MAX_Z_INDEX,
    color: "#FFA500",
  },
  circularColor: {
    color: "white",
  },
}));

function BackDropLoader(props) {
  const { showLoader } = props;
  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);

  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={(loaderState.open && loaderState === undefined) || showLoader}>
        <CircularProgress className={classes.circularColor} />
      </Backdrop>
    </>
  );
}
export default BackDropLoader;
