import { memo, useEffect } from "react"
import { FooterNewsletter, getEspotFromProps, iconsComponent } from "./StyledFooterHDM"


export const StyledFooterNewsletter = memo((props: any) => {


    
    
    if(props?.data && props.data?.length === 0) return null
    const data = getEspotFromProps(props.espotName, props.data)
    const icons = iconsComponent(getEspotFromProps(props.espotName, props.data))
    return(
        <>
        <FooterNewsletter
            data={data}
            icons={icons}
          />
        </>
    )
})

