/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import { StyledCheckboxHDM } from "./Styled-checkbox-hmd";

export const StyledCheckbox = styled(({ ...props }) => <Checkbox {...props} />)`
  ${({ theme }) => `
    
    &.cybersource-checkbox{
      & svg{
        font-size: 42px;
      }
    }
    &.cybersource-checkbox-b2b {
      & input[type="checkbox"] {
        border: solid 1px ${theme.palette.gray[100]};
        color: ${theme.palette.gray[100]};
      }
      & svg {
        font-size: 18px;
        path { 
          width: 10px;
        }
      }
    }
  `}
`;

export const StyledCheckboxCompare = styled(({ ...props }) => <Checkbox {...props} />)`
  ${({ theme }) => `

    &.MuiIconButton-root {
      padding: 0px !important;
    }

    &.MuiIconButton-root {
      padding: 0px !important;
    }

    &.compare-checkbox {

    }

  `}
`;
