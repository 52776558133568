/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
//Custom libraries
import { useWishList } from "../../../_foundation/hooks/use-wishlist";
import { EMPTY_STRING } from "../../../constants/common";
//UI
import {
  StyledGrid,
  StyledTextField,
  StyledTypography,
  StyledIconLabel,
  StyledButton,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledLinkTextSecondary,
} from "../../../hdm/elements";
import Add from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ContainerCreateWishList } from "./create-wish-list-container";
import { useNavigate } from "react-router-dom";

const CreateWishList = (props) => {
  const { handleCancelarLista, userWishList, variant = "", partNumber, isMoveToList, handleSkuAdded } = props;
  const {
    wishListName,
    handleWishListName,
    validateWishListName,
    canCreateWishList,
    createWishList,
    isExpanded,
    toggleExpand,
    errorWishListName,
    canCreateWishListAndAddItem,
  } = useWishList();
  const { t } = useTranslation();
  const [nameList, setNameList] = useState("");
  const navigate = useNavigate();

  const handleChanege = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valor = event.target.value.trim();
    const patt = new RegExp(/^[A-Za-z-ñÑáéíóúÁÉÍÓÚ´\s]+$/g);
    const isValid = patt.test(event.target.value);

    if ((isValid && valor.length <= 50) || valor === "") {
      setNameList(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (variant !== "drawer") {
      const newList = await canCreateWishList();
      if (newList && newList !== true) {
        handleCancelarLista();
        navigate(`/account/WishListDisplayView?id=${newList}`);
      }
    } else {
      // I need to validate when the SKU is added to any list
      await canCreateWishListAndAddItem(partNumber, isMoveToList);
      handleSkuAdded(true)
      handleCancelarLista();
    }
  };

  const content = (
    <>
      {variant !== "drawer" && (
        <StyledTypography variant="bodyBaseline" className="bottom-margin-2">
          {t("WishList.labels.createNewList")}
        </StyledTypography>
      )}
      <form onSubmit={handleSubmit}>
        <StyledTextField
          margin="normal"
          className="bottom-margin-2"
          autoComplete="off"
          type="text"
          label="Nombre de la lista"
          value={wishListName}
          name="searchTerm"
          onChange={handleWishListName}
          inputProps={{ maxLength: 50 }}
          error={errorWishListName.error}
          helperText={errorWishListName.errorMsg}
        />

        <StyledButton
          id={"wishlist--list-saveBtn"}
          variant="outlined"
          color="primary"
          className="bottom-margin-2"
          disabled={wishListName.length === 0 ? true : false}
          onClick={handleSubmit}>
          {t("WishList.button.saveList")}
        </StyledButton>
      </form>

      <StyledLinkTextSecondary
        id={"wishlist--list-cancelBtn"}
        className="color-secondary text-align-center bottom-margin-2"
        onClick={handleCancelarLista}>
        {t("WishList.button.cancel")}
      </StyledLinkTextSecondary>
    </>
  );

  if (variant === "drawer") {
    return <>{content}</>;
  }
  return <ContainerCreateWishList className="styled--crear-lista">{content}</ContainerCreateWishList>;

  return (
    <StyledGrid container>
      <StyledAccordion testId={`create-wish-list`} style={{ flex: "1" }} expanded={isExpanded} onClick={toggleExpand}>
        <StyledAccordionSummary
          className="horizontal-padding-2 vertical-padding-2 cprof-cr8-acrdn"
          expandIcon={<ExpandMoreIcon />}>
          <StyledIconLabel
            icon={<Add color="primary" className="full-center" />}
            label={t("WishList.CreateWishListTitle")}
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails data-testid="create-wish-list-accordion-details" onClick={toggleExpand}>
          <StyledGrid item container spacing={2} alignItems="flex-start">
            <StyledGrid item xs={12} sm={6} md={5}>
              <StyledTextField
                fullWidth
                required
                id="create-wish-list-name"
                name="wishListName"
                label={
                  <StyledTypography variant="body1" style={{ fontWeight: "bold" }}>
                    {t("WishList.WishListName")}
                  </StyledTypography>
                }
                value={wishListName}
                inputProps={{ maxLength: 128 }}
                autoComplete="wishListName"
                onChange={handleWishListName}
                error={validateWishListName(wishListName)}
                helperText={validateWishListName(wishListName) ? t("WishList.InvalidWishListName") : EMPTY_STRING}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={3} md={3}>
              <StyledButton
                testId="create-wishlist"
                fullWidth
                className="top-margin-4"
                color="primary"
                disabled={canCreateWishList()}
                onClick={createWishList}>
                <StyledTypography variant="body1">{t("WishList.CreateList")}</StyledTypography>
              </StyledButton>
            </StyledGrid>
          </StyledGrid>
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledGrid>
  );
};

export default CreateWishList;
