import { Dimension } from "../dimensions";

export const SLCheckout = {
  header: {
    logo: new Dimension(60, 60),
    title: new Dimension(247, 36),
    backButton: new Dimension(136, 18),
  },
  steps: {
    header: {
      step: new Dimension(20, 20),
      title: new Dimension(163, 21.6),
    },
  },
};
