/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */

import { StyledGrid, StyledTypography, StyledBox } from "../../elements";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

interface KitProductWidgetProps {
  row: object;
  showSpecifications: boolean;
  setOpenDialogSpecifications: unknown;
  setDataDialogSpecifications: unknown;
  isDrawer?: boolean;
}

export const KitProductDisplay: React.FC<KitProductWidgetProps> = (props: any) => {
  const { row, showSpecifications, setOpenDialogSpecifications, setDataDialogSpecifications, isDrawer = false } = props;
  const { t } = useTranslation();
  const imgSize = isDrawer ? 71 : 90;
  const storeConfData = useSelector(onlineStoreRdc);

  const capitalizedFirstLetter = (productName: string) => {
    const firstLetter = productName?.charAt(0);
    const remainingLetters = productName?.slice(1).toLowerCase();
    return firstLetter + remainingLetters;
  };

  const thumbnailMini = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${row.partNumber}/${row.partNumber}-m.jpg`;
  let brand = "";
  if (row.attributes) {
    const extractBrand = row.attributes.filter((brand) => brand.identifier === "Marca");
    brand = extractBrand[0]?.values[0]?.value ? extractBrand[0]?.values[0]?.value : "";
  }

  const handleOpenDialogSpecifications = (row) => {
    setDataDialogSpecifications(row);
    setOpenDialogSpecifications(false);
    setOpenDialogSpecifications(true);
  };
  {
    /*<Link to={row.seo?.href ? row.seo?.href : ""} className={"right-margin-2"}>*/
  }
  {
    /*</Link>*/
  }
  return (
    <StyledGrid
      item
      xs
      className={"kits--styledGrid-flexContainer" + (!isDrawer ? " kits--styledGrid-borderRight" : "")}>
      {row.seo?.href && (
        <StyledGrid className={"right-margin-2"}>
          <StyledBox
            component="img"
            alt="Product"
            sx={{ minWidth: imgSize, minHeight: imgSize, width: imgSize, heigth: imgSize }}
            src={
              row.thumbnail
                ? thumbnailMini
                : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
            }
          />
        </StyledGrid>
      )}
      <StyledGrid>
        <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
          {row?.manufacturer ?
          `${row?.manufacturer} `
          :
          ""
          }
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" component="span" className="product-name">
          {capitalizedFirstLetter(row?.name)}
        </StyledTypography>
        <br />
        <StyledTypography variant="bodySubtext" component="span" className="color-gray300">
          {t("OrderItemTable.Labels.SKUsimple")}
          {row.partNumber}
        </StyledTypography>
        {showSpecifications && (
          <StyledGrid>
            <StyledLinkButton variant="bodyBaseline" onClick={() => handleOpenDialogSpecifications(row)}>
              {t("productDetail.ShowProductSpecificationsKit")}
            </StyledLinkButton>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};
