/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import styled from "styled-components";
//UI
import { Divider } from "@material-ui/core";
import { StyledProductRecommendationSlider, StyledTypography, StyledBox } from "../../elements";
import { EMarketingSpotWidgetProps } from "../../types";
import { palette } from '../../themes/color-palette'

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { tabletBreakpoint } from "../../functions/utils";
import { useTranslation } from "react-i18next";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../functions";

const StyledProductRecommendationLayout = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(0)}px 0px 0px 0;

    &.isTablet {
      padding-left: 10px;
      padding-right: 10px;
    }
    .title-border {
      border-bottom: 5px solid ${theme.palette.primary.main};
    }

    .vertical-padding-2 {
      padding-bottom: 0px !important;
    }
  `}
`;

/**
 * product recommendation widget.
 * For props definition, @see {@link EMarketingSpotWidgetProps}.
 * @param props The props for `ProductRecommendationWidget`, which contains an eSpot object.
 */
const ProductRecommendationWidget: React.FC<EMarketingSpotWidgetProps> = ({ eSpot, iseSpot, ...props }) => {
  const slides = eSpot.catEntry.slides?.filter((slide) => {
    const item = slide?.props?.children?.props?.renderingContext?.productInternal
    const allPrices: any = numberWithCommas(item);
    const price = parseFloat(allPrices?.offerPrice) !== 0 ? allPrices?.offerPrice :
      parseFloat(allPrices?.displayPrice) !== 0 ? allPrices?.displayPrice : "0";
    return item?.seo?.href?.includes("p/") && price && parseFloat(price) !== 0
  });
  const recommendedProductTitle = eSpot.catEntry.title;
  const espotId = eSpot.category.id;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const { t } = useTranslation();

  const currentStoreData = useSelector(currentStoreSelector);

  useEffect(()=>{
    if(eSpot.catEntry.processProductRecommendation){
      eSpot.catEntry.processProductRecommendation(eSpot.catEntry.catEntries,currentStoreData?.currentStore?.stLocId)
    }
  },[currentStoreData])


  return (
    <>
    {slides && slides.length > 0 ? (
    <StyledProductRecommendationLayout className={isTablet ? "isTablet" : ""}>
        <>
        {/* {recommendedProductTitle?.length !== 0 && (
        <StyledBox mb={2}>
            <StyledTypography variant="h4" component="span" className="title-border">
              {iseSpot 
              ? recommendedProductTitle
              : t("RecommendProducts.AlsoBought")
              }
            </StyledTypography>

          <Divider style={{ backgroundColor: palette.gray["100"], marginTop: '5px' }} />
        </StyledBox>
        )} */}
        <StyledProductRecommendationSlider slidesList={slides} espotId={espotId} />
        </>
      
    </StyledProductRecommendationLayout>
    ) : null}
    </>
  );
};

export { ProductRecommendationWidget };