import { useState } from "react";

import { StyledCardEmpty } from "../../elements/card";
import { ContainerStyledBox, ContainerStyledTypography } from "./containers-acerca-de";
import { Divider } from "@material-ui/core";

import { palette } from "../../themes/color-palette";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import EspecificacionesTecnicas from "./EspecificacionesTecnicas";
import { useTranslation } from "react-i18next";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";

interface Props {
  handleGoInformacionAdicional: () => void;
  handleGoEspecTecnicas: () => void;
  isTablet: boolean;
  isKit?: boolean;
  shortDescription: string;
  allEspecificacionesTec: any;
}
const AcercaDeEsteProducto = (props: Props) => {
  const {
    handleGoInformacionAdicional,
    handleGoEspecTecnicas,
    isTablet,
    isKit = false,
    shortDescription,
    allEspecificacionesTec,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const specsDrawerTitle = t("InformacionAdicional.especTecnicas");

  const handleOpenEspicificaciones = () => {
    setIsOpen(true);
  };

  const AboutThisProduct = ()=>{
    return (
      <>
        <ContainerStyledBox className="acerca-de-container" id="technical-specifications" data-specifications={JSON.stringify(allEspecificacionesTec)}>
          <StyledCardEmpty className="product-about-info-card padding-2">
            <ContainerStyledBox mb={2}>
              <ContainerStyledTypography variant="textSegment">Acerca de este producto</ContainerStyledTypography>
            </ContainerStyledBox>
  
            <ContainerStyledBox mb={2}>
              <ContainerStyledTypography variant="bodyBaseline">{shortDescription}</ContainerStyledTypography>
            </ContainerStyledBox>
  
            {!isKit && (
              <>
                <Divider style={{ background: palette.gray["50"] }} />
                <ContainerStyledBox display="flex" className="container-informacion-adicional">
                  <ContainerStyledTypography
                    variant="bodyBaseline"
                    className="text-informacion-adicional"
                    onClick={handleGoInformacionAdicional}>
                    Información adicional
                  </ContainerStyledTypography>
                  <Divider orientation="vertical" flexItem style={{ marginTop: 10, background: palette.gray["50"] }} />
                  <ContainerStyledTypography
                    variant="bodyBaseline"
                    className="text-espicificaciones"
                    onClick={isTablet ? handleOpenEspicificaciones : handleGoEspecTecnicas}>
                    Especificaciones
                  </ContainerStyledTypography>
                </ContainerStyledBox>
              </>
            )}
          </StyledCardEmpty>
        </ContainerStyledBox>
  
        <StyledSwipeableDrawerHDM
          isOpen={isOpen}
          setOpenDrawer={setIsOpen}
          variant={"especificaciones-tecnicas"}
          title={specsDrawerTitle}>
          <EspecificacionesTecnicas allEspecificacionesTec={allEspecificacionesTec} isTablet={isTablet} />
        </StyledSwipeableDrawerHDM>
      </>
    );
  }

  return withSkeletonLoader(<AboutThisProduct/>, (shortDescription !== undefined || shortDescription === ""), loaders.pdp.aboutCard)
};

export default AcercaDeEsteProducto;
