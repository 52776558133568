import styled from "styled-components";
import { Swiper } from "swiper/react";
import { Box, Grid } from "@material-ui/core";

export const StyledSwiper = styled(({ ...props }) => <Swiper {...props} />)`
  ${({ theme }) => `

    &.products-list-swiper {
        .swiper-wrapper{
            margin-bottom: 5px;
        }
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
        padding-top: ${theme.spacing(4)}px;
        background-color: #e4e4e4;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .swiper-wrapper{       

        & .swiper-slide {
            flex-shrink: 1;
        }
        
    }

`}
`;

export const StyledBulletPagination = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .buttton-color {
        &.MuiButton-root {
            color: ${theme.palette.secondary.main} !important;

        }
    }

`}
`;
