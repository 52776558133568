/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021, 2022
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import MatButton from "@material-ui/core/Button";

const CustomMatButton = React.forwardRef((props: any, ref: any) => {
  const { variant, className, color, testId, ...re } = props;
  const dataTestId = testId ? { "data-testid": `button-${testId}` } : {};
  return (
    <MatButton
      {...dataTestId}
      {...re}
      ref={ref}
      variant={variant || "contained"}
      color={color || "primary"}
      className={className}
      disableElevation
    />
  );
});

const StyledButton = styled(CustomMatButton)`
  ${({ theme }) => `

  &.form-confirm-bottom {
    margin: 0px;
  }

  &.form-cancel-bottom {
    padding-top: 0px;
    margin-top: ${theme.spacing(3)}px;
  }

  &.productBoxButton {
    width: 199px;
    height: 42px;
  }
  &.productBoxVerDetalle {
    width: 100%;
  }

  &.mascolor-button {
    max-width: 22px;
    min-width: 22px;
    max-height: 21px;
    min-height: 21px;
    border-radius: 12px;
    color: #000000;
    background-color: #FFFFFF;
    font-size: 30px;
  }

  &.styledButton--margin-none{
    margin: 0px;
  }

  &.styledButton--style-buttonAccept{
    margin-top: ${theme.spacing(2)}px;
  }
  `}
`;

export { StyledButton };
