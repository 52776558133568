// HoC for skeleton loader

import { Skeleton } from "@material-ui/lab";
import { ReactComponentElement, Suspense, lazy, useState } from "react";

const withSkeletonLoader = (
  component: JSX.Element,
  showCondition: boolean | undefined,
  variant?: JSX.Element,
  options?: {
    omitTimeout?: boolean
    timeout?: number
  }

): any => {

  let timeOut = 10000;
  let omitTimeout = false;

  if (options) {
    timeOut = options.timeout ?? 10000;
    omitTimeout = options.omitTimeout ?? false;
  }
  if (!variant) {
    variant = <Skeleton variant="rect" width={50} height={50} />;
  }

  const SkeletonLoader = variant;

  const LoaderComponent = (props) => {
    const { show } = props;
    const [timedCondition, setTimedCondition] = useState(false);

    if (!omitTimeout) {
      setTimeout(() => {
        setTimedCondition(true);
      }, timeOut);
    }

    return <>{show || timedCondition ? component : SkeletonLoader}</>;
  };

  return <LoaderComponent show={showCondition} />;
};

export default withSkeletonLoader;
