/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useMemo, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Axios, { Canceler } from "axios";
//Custom libraries
import { CART, CHECKOUT, IP_ORDER_DETAILS } from "../../constants/routes";
import { MINICART_CONFIGS } from "../../configs/order";
import { PRIVATE_ORDER_TYPE } from "../../constants/order";
//Redux
import { numItemsSelector, cartSelector, orderItemsSelector } from "../../redux/selectors/order";
import { forUserIdSelector, userIdSelector } from "../../redux/selectors/user";
//UI
// import { ClickAwayListener } from "@material-ui/core";
import { StyledMiniCartContent } from "../StyledUI";
import { useSite } from "../../_foundation/hooks/useSite";
import { CLOSE_CART_DRAWER_ACTION } from "../../redux/actions/drawer";
import cartService from "../../_foundation/apis/transaction/cart.service";
import * as orderActions from "../../redux/actions/order";
import getDisplayName from "react-display-name";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { CONSTANTS } from "../../constants/order-item-table";
import { logEvent } from "../../hdm/functions/utils";

interface MiniCartPopperContentProps {
  handleClose: (e?: any) => void;
  setShowCart: any;
  showCart: any;
}

/**
 * MiniCartPopperContent component
 * displays mini cart popper contents
 * @param props
 */
const MiniCartPopperContent: React.FC<MiniCartPopperContentProps> = (props: any) => {
  const { handleClose, setShowCart, showCart } = props;
  const numItems = useSelector(numItemsSelector);
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const forUserId = useSelector(forUserIdSelector);
  const uId = useSelector(userIdSelector);
  const userId = forUserId ?? uId;
  const dispatch = useDispatch<Dispatch<any>>();
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  const widgetName = getDisplayName("CartWidget");
  const contractId = useSelector(currentContractIdSelector);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo(() => [], []);
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  const payloadBase: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    widget: widgetName,
  };

  const handleCartOnClick = () => {
    if (!isB2B) {
      handleClose();
      dispatch(
        CLOSE_CART_DRAWER_ACTION(() => {
          /* */
        })
      );
      if(isMobileApp) {
        logEvent("goToCart")
      }else {
        navigate(CART);
      }
    } else {
      if (!cart || userId === cart.buyerId) {
        handleClose();
        dispatch(
          CLOSE_CART_DRAWER_ACTION(() => {
            /* */
          })
        );
        navigate(CART);
      } else {
        handleClose();
        dispatch(
          CLOSE_CART_DRAWER_ACTION(() => {
            /* */
          })
        );
        navigate(`${IP_ORDER_DETAILS}/${cart.orderId}`);
      }
    }
  };

  const handleCheckoutOnClick = () => {
    handleClose();
    dispatch(
      CLOSE_CART_DRAWER_ACTION(() => {
        /* */
      })
    );
    getUsableShippingInfoCart();
  };

  const getUsableShippingInfoCart = async () => {
    Axios.post(
      `/wcs/resources/store/${mySite.storeID}/cart/calculate?responseFormat=json&langId=-5&physicalStoreId=${mySite.storeCfg.userData.PhysicalStore}&calculationUsageId=-1&catalogId=${mySite.catalogID}&doConfigurationValidation=Y&updatePrices=1&orderId=${cart.orderId}&toCartPage=true&langId=-5&storeId=${mySite.storeID}&errorViewName=AjaxOrderItemDisplayView&fromCheckoutPage=true`
    )
      .then((res) => {
        const payload = {
          ...payloadBase,
          fetchCatentries: true,
          cancelToken: new CancelToken(function executor(c) {
            cancels.push(c);
          }),
        };
        dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));

        const itemsUpdate: { orderItemId: string; contractId: string }[] = [];
        for (let i = 0; i < orderItems.length; i++) {
          const obj = { orderItemId: orderItems[i].orderItemId, contractId: orderItems[i].contractId };
          itemsUpdate.push(obj);
        }
        const payloadItem = {
          ...payloadBase,
          orderId: cart.orderId,
          orderItemId: itemsUpdate,
          x_deliverType: CONSTANTS.deliveryShipType,
          x_page: "",
          x_pageLoad: "LOAD",
        };
        dispatch(orderActions.UPDATE_ITEM_ACTION(payloadItem));
        navigate(CHECKOUT);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initOrderTotalSummary = () => {
    let subtotal: number | null = null;
    let subtotalCurrency: string = "";
    if (cart) {
      try {
        subtotal =
          parseFloat(cart.totalProductPrice ? cart.totalProductPrice : 0) +
          parseFloat(cart.totalAdjustment ? cart.totalAdjustment : 0);
        subtotalCurrency = cart.totalProductPriceCurrency;
      } catch (e) {
        console.log("Could not parse cart totals");
      }
    }
    return {
      subtotal,
      subtotalCurrency,
    };
  };

  const { subtotal, subtotalCurrency } = useMemo(initOrderTotalSummary, [cart]);
  const miniCartItems = useMemo(() => orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).reverse(), [orderItems]);
  return (
    <StyledMiniCartContent
      title={
        !isB2B
          ? t("MiniCart.Title")
          : !cart
          ? t("MiniCart.MyOrder")
          : cart.orderDescription
          ? cart.orderDescription
          : cart.orderId
      }
      orderType={
        !cart
          ? t("MiniCart.PrivateOrder")
          : cart.orderTypeCode === PRIVATE_ORDER_TYPE
          ? t("MiniCart.PrivateOrder")
          : userId === cart.buyerId
          ? t("MiniCart.SharedOrderAdmin")
          : t("MiniCart.SharedOrderConributor")
      }
      orderItems={miniCartItems}
      subtotalLabel={t("MiniCart.Subtotal", { count: numItems })}
      subtotal={subtotal}
      subtotalCurrency={subtotalCurrency}
      emptyCartMsg={t("MiniCart.Empty")}
      cartLinkLabel={
        !isB2B
          ? t("MiniCart.Actions.Cart")
          : !cart
          ? t("MiniCart.Actions.Cart")
          : userId === cart.buyerId
          ? t("MiniCart.Actions.Cart")
          : t("MiniCart.Actions.ViewOrderDetails")
      }
      checkoutLinkLabel={t("MiniCart.Actions.CheckOut")}
      handleCartOnClick={handleCartOnClick}
      handleCheckoutOnClick={handleCheckoutOnClick}
      handleClose={handleClose}
      isOrderOwner={!cart ? true : userId === cart.buyerId}
      isB2B={isB2B}
      showCart={showCart}
      setShowCart={setShowCart}
    />
  );
};

export default MiniCartPopperContent;
