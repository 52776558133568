import axios from "axios";

const getExcluisive = (data: any) => {
  let brand_diy: string[] = [];
  let brand_pro: string[] = [];

  if (data?.EXCLUSIVE_BRAND_DIY && data?.EXCLUSIVE_BRAND_DIY?.length !== 0) {
    brand_diy = data.EXCLUSIVE_BRAND_DIY.split("|");
  }

  if (data?.EXCLUSIVE_BRAND_PRO && data?.EXCLUSIVE_BRAND_PRO?.length !== 0) {
    brand_pro = data.EXCLUSIVE_BRAND_PRO.split("|");
  }

  const brandResult: string[] = brand_diy.concat(brand_pro);
  return { exclusive: brandResult, onlineStore: data };
};

export const axiosGetInventory = async (query) => {
  // const url = "https://api.coingecko.com/api/v3/ping";
  const { url, option } = query;

  let data: any = null;
  // await axios
  //   .get<any>(url)
  //   .then((response) => {
  //     if (response) {
  //       data = response;
  //     }
  //   })
  //   .catch(function (e) {
  //     console.log("another error happened:" + e.message);
  //   });

  await axios
    .get(url)
    .then((response) => response.data)
    .then((res) => {
      if (res) {
        data = res;
      }
      // setServiceArea(res.zones);
    })
    .catch(function (e) {
      console.log("another error happened:" + e.message);
    });

  if (option === "ONLINE_STORE") {
    if (data?.resultList) {
      return getExcluisive(data.resultList[0].userData);
    }
  }

  return data;
};
