import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm/elements";
import { Trans, useTranslation } from "react-i18next";
import { palette } from "../../../hdm/themes";
import { Divider } from "@material-ui/core";
import { StyledCartRowPaint } from "../../custom-components/styled-cart-row-paint";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ProductInfoDetails = (props) => {
  const { t } = useTranslation();
  const storeConfData = useSelector(onlineStoreRdc);

  const order = props.order ? props.order : {};
  const isInstallation = props.isInstallation === true ? true : false;
  let totalAdjustment = "0";
  let hasFreeItem = false;
  let priceFreeItem = 0;
  const promosStoreConf = storeConfData?.CHECKOUT_VALIDATE_PROMOTIONS
    ? storeConfData?.CHECKOUT_VALIDATE_PROMOTIONS.split(",")
    : [];

  //get promo item, it validates if promotion stored in bd is eq to promo item and updates total adjustment
  if (order?.adjustment && order.adjustment.length > 0) {
    hasFreeItem = order?.adjustment[0]?.description?.includes("Artículo de Regalo") ? true : false;
    for (let i = 0; i < promosStoreConf.length; i++) {
      if (promosStoreConf[i] == order?.adjustment[0].description) {
        totalAdjustment = order.totalAdjustment.value;
        break;
      }
    }
    if(hasFreeItem){
      priceFreeItem = parseFloat(order?.adjustment[0].amount) * -1;
    }
  } else {
    //get promo item from attributes array, it validates if promotion stored in bd is eq to promo item and updates total adjustment
    if (order?.attributes && order.attributes.length > 0) {
      for (let i = 0; i < promosStoreConf.length; i++) {
        for (let x = 0; x < order.attributes.length; x++) {
          if (promosStoreConf[i] == order?.attributes[x].identifier) {
            totalAdjustment = order.totalAdjustment.value;
            break;
          }
        }
      }
    }
  }

  return (
    <StyledGrid className={`product ${isInstallation ? "deliveryInfoCard--StyledGrid-installationInfo" : ""}`}>
      <StyledTypography
        variant="bodySubText"
        weight="semibold"
        component={"span"}
        className={"product-sku"}
        style={{display: "none"}}>
        {order?.partNumber}
      </StyledTypography>
      <StyledBox className="deliveryInfoCard-styledBox-truncateText">
        {!isInstallation && order.manufacturer && (
          <StyledTypography
            variant="bodySubText"
            weight="semibold"
            component={"span"}
            className={"product-brand"}>
            {order.manufacturer + " "}
          </StyledTypography>
        )}
        <StyledTypography
          variant="bodySubText"
          component={"span"}
          weight={isInstallation ? "semibold" : ""}
          className={"product-name"}>
          {isInstallation && <CheckIcon className={"deliveryInfoCard-checkIcon-installations"} />}
          {order.name}
        </StyledTypography>
      </StyledBox>
      <StyledGrid xs={12}>
        <StyledTypography
          variant="bodySubText"
          component={"span"}
          className={"deliveryInfoCard-styledTypography-mediumGray"}>
          {t("DeliveryInfoCard.Price") + " "}
        </StyledTypography>
        <StyledTypography
          variant="bodySubText"
          weight={isInstallation ? "" : "semibold"}
          component={"span"}
          className={"product-price"}>
          {hasFreeItem
                ? formatter.format(priceFreeItem)
                : parseFloat(order.salesTax) === 0 
                  ? formatter.format(parseFloat(order.unitPrice)) 
                  : formatter.format(parseFloat(order.unitPrice) + (parseFloat(order.unitPrice) * (order.xitem_taxRate/100) || 0))
          }
        </StyledTypography>
        <StyledTypography
          variant="bodySubText"
          component={"span"}
          className={
            "left-padding-2 deliveryInfoCard-styledTypography-darkGray"
          }>
          {t("DeliveryInfoCard.Quantity") + " "}
        </StyledTypography>
        <StyledTypography
          variant="bodySubText"
          weight={isInstallation ? "" : "semibold"}
          component={"span"}
          className={"deliveryInfoCard-styledTypography-regularSize product-quantity"}>
          {parseInt(order.quantity)}
        </StyledTypography>

        {/* {!isInstallation && parseFloat(order?.totalAdjustment.value) !== 0 ? (
            <>
              <StyledTypography
                variant="bodySubText"
                component={"span"}
                className={
                  "left-padding-2 deliveryInfoCard-styledTypography-customSize deliveryInfoCard-styledTypography-darkGray"
                }>
                {t("DeliveryInfoCard.Discount") + " "}
              </StyledTypography>
              <StyledTypography
                variant="bodySubText"
                weight={isInstallation ? "" : "semibold"}
                component={"span"}
                className={"deliveryInfoCard-styledTypography-customSize"}>
                {formatter.format((parseFloat(order.totalAdjustment.value) * -1) * 0.16 + (parseFloat(order.totalAdjustment.value) * -1))}
              </StyledTypography>
            </>
          ) : null} */}
        {hasFreeItem ? (
          <>
            <StyledTypography
              variant="bodySubText"
              component={"span"}
              className={
                "left-padding-2 deliveryInfoCard-styledTypography-customSize deliveryInfoCard-styledTypography-darkGray"
              }>
              {t("DeliveryInfoCard.Subtotal") + " "}
            </StyledTypography>
            <StyledTypography
              variant="bodySubText"
              weight={isInstallation ? "" : "semibold"}
              component={"span"}
              className={"deliveryInfoCard-styledTypography-customSize"}>
              {t("OrderTotalSummary.Labels.Free")}
            </StyledTypography>
          </>
        ) : (
          <>
            <StyledTypography
              variant="bodySubText"
              component={"span"}
              className={
                "left-padding-2 deliveryInfoCard-styledTypography-darkGray"
              }>
              {t("DeliveryInfoCard.Subtotal") + " "}
            </StyledTypography>
            <StyledTypography
              variant="bodySubText"
              weight={isInstallation ? "" : "semibold"}
              component={"span"}>
              {parseFloat(order.salesTax) === 0 
                ? formatter.format(parseFloat(order.orderItemPrice) - (parseFloat(order.totalAdjustment.value) * -1)) 
                : formatter.format(parseFloat(order.orderItemPrice) + parseFloat(order.salesTax) - (parseFloat(order.totalAdjustment.value) * -1))
              }
            </StyledTypography>
          </>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export const DeliveryProductInfo = (props) => {
  const { order, hasInstallation, index } = props;
  const { t } = useTranslation();

  return (
    <>
      {index === 0 ? null : (
        <StyledBox className={"vertical-padding-2"}>
          <Divider style={{ backgroundColor: palette.gray["50"] }} />
        </StyledBox>
      )}
      <StyledGrid xs={12} className={"left-padding-2"}>
        <ProductInfoDetails order={order} />
        <StyledCartRowPaint rowData={order} variant="checkout" />
        {hasInstallation ? (
          <StyledGrid className={"deliveryInfoCard--StyledGrid-installationContainer"}>
            <ProductInfoDetails order={hasInstallation} isInstallation={true} />
          </StyledGrid>
        ) : null}
      </StyledGrid>

      {/* <StyledBox mb={2}>
            <Divider style={{backgroundColor: palette.gray["50"]}} />
        </StyledBox> */}
    </>
  );
};
