import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CATALOG_PREFIX } from "../../constants/routes";
import { BREADCRUM_SET_SUCCESS } from "../../redux/action-types/catalog";
import { FETCH_HDM_DATA_REQUESTED } from "../../redux/action-types/hdmData";
import { GetCategoriesSelector } from "../../redux/selectors/category";
import { seoSelector } from "../../redux/selectors/seo";
import { CATEGORY_ROUTE_SEPARATOR } from "../constants/common";

export const useCategoryBreadcrumbs = () => {
  const location: any = useLocation();
  const seoConfig = useSelector(seoSelector);
  const Categories = useSelector(GetCategoriesSelector);

  const dispatch = useDispatch();

  const identifier: string = useMemo(() => {
    const _i = location?.pathname;

    if (_i && _i.split(CATEGORY_ROUTE_SEPARATOR).length > 0) {
      return decodeURI(_i.split(CATEGORY_ROUTE_SEPARATOR)[1]);
    }
    return "";
  }, [location]);

  const breadcrumbs = useMemo<any>(() => {
    const { breadCrumbTrailEntryView }: any = location.state ?? {};
    if (!breadCrumbTrailEntryView && identifier !== "") {
      if (seoConfig && seoConfig[identifier]?.layout?.containerName === "category-landing-page") {
        const categoryIdentifier = seoConfig[identifier]?.tokenExternalValue?.split("-");
        categoryIdentifier?.reverse();
        let allCategoryIdentifier = categoryIdentifier && categoryIdentifier?.length !== 0 ? categoryIdentifier[0] : "";
        const breadcrumsItems: any = [];
        let content_title = "";
        let categoriesStrings = "";
        categoryIdentifier &&
          categoryIdentifier.map((catIdentifier, index) => {
            if (index > 0) {
              allCategoryIdentifier = `${catIdentifier}-${allCategoryIdentifier}`;
            }

            const category = Categories.find((cat) => cat?.identifier === allCategoryIdentifier);

            categoriesStrings = `${categoriesStrings}${category?.name}>`;

            content_title = category?.name;
            breadcrumsItems.push({
              label: category?.name,
              type_: "FACET_ENTRY_CATEGORY",
              value: category?.id,
              seo: {
                href: category?.seo?.href,
              },
            });
          });

        if (!content_title) return;

        categoriesStrings = categoriesStrings.substring(0, categoriesStrings.length - 1);

        const objTagPage = {
          content_title: `${content_title} | The Home Depot México`,
          content_url: window?.document?.location?.href,
          content_category: content_title,
          content_tag: content_title,
        };

        let query: any = {
          url: "",
          data: {
            category: ["category", categoriesStrings],
            tag: ["tag", "content_pageview", objTagPage],
          },
          option: "EMARSYS_CATEGORY_TAG_DATA",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

        query = { payload: breadcrumsItems };
        dispatch({ type: BREADCRUM_SET_SUCCESS, query });
      }
    }

    if (breadCrumbTrailEntryView) {
      const query = { payload: breadCrumbTrailEntryView };
      dispatch({ type: BREADCRUM_SET_SUCCESS, query });

      let categories = "";
      const arrayCategories = breadCrumbTrailEntryView.filter((e) => e.type_ === "FACET_ENTRY_CATEGORY");
      arrayCategories.map((tag) => {
        categories += tag.label + ">";
      });
      const length = arrayCategories.length;
      if (length > 0) {
        categories = categories.substring(0, categories.length - 1);
        const objTagPage = {
          content_title: window.document.title,
          content_url: window.document.location.href,
          content_category: arrayCategories[length - 1].label,
          content_tag: arrayCategories[length - 1].label,
        };
        const query = {
          url: "",
          data: {
            category: ["category", categories],
            tag: ["tag", "content_pageview", objTagPage],
          },
          option: "EMARSYS_CATEGORY_TAG_DATA",
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
      }
    }
  }, [location, seoConfig, Categories, identifier]);
};
