import { ClickAwayListener, InputAdornment } from "@material-ui/core";
import React, { FC } from "react";
import { StyledBox, StyledIconButton, StyledTooltip, StyledTypography } from "../../../hdm";
// import { useTranslation } from "react-i18next";
import { StyledTooltipContainer } from "./StyledTooltipContainer";
import HelpIcon from "../../../assets/homedepot/images/HelpIcon";
import Tooltip from "@material-ui/core/Tooltip";

interface TooltipHdm {
  content: string | number | JSX.Element;
  placement?: string;
}

const StyledTooltipHdm: FC<TooltipHdm> = ({ content, placement="bottom" }) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
    console.log("hoda")
  };

  const PromocionPrecio = () => {
    return (
      <>
        <StyledBox onMouseEnter={() => handleTooltipOpen()}>
          <StyledTooltip
            onClose={handleTooltipClose}
            open={open}
            onBlur={handleTooltipClose}
            title={content}
            placement={placement}>
            <InputAdornment position="end" onBlur={handleTooltipClose}>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener>
                    <StyledIconButton
                      className={"styledTooltipHdm-tooltip-button"}
                      onClick={handleTooltipOpen}
                      tabIndex="-1">
                      <HelpIcon className={"styledTooltipHdm-tooltip-icon"} />
                    </StyledIconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </InputAdornment>
          </StyledTooltip>
        </StyledBox>
      </>
    );
  };

  return <StyledTooltipContainer>{<PromocionPrecio />}</StyledTooltipContainer>;
};

export { StyledTooltipHdm };
