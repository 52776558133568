import React, { useEffect, useState } from "react";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSelector } from "react-redux";
import { getPageName, verifyVT } from "./blue-triangle-functions";

const BTUpdateEvent = () => {
  const storeConfData = useSelector(onlineStoreRdc);
  const isBTEnabled = storeConfData?.BT_ACTIVE_FLAG;
  const btEnvironment = storeConfData?.BT_ENVIRONMENT;
  const [eventTriggered, setEventTriggered] = useState(true);

  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntriesByType("navigation");
      if (eventTriggered) {
        if (entries.length > 0 && entries[0].duration > 0) {
          const navigationEntry = entries[0] as PerformanceNavigationTiming;
          const { domComplete, startTime } = navigationEntry;
          const pageLoadTime = domComplete - startTime;
          const halfPageLoadTime = pageLoadTime / 2;

          const pageNameValue = verifyVT();
          if (isBTEnabled && isBTEnabled != "false") {
            try {
              console.log("bt update pageName =>", pageNameValue);
              window["bttUT"].update({
                pageName: pageNameValue,
                txnName: btEnvironment,
              });
            } catch (error) {
              console.log("Error triggering bt", error);
            }
          }

          const timerId = setTimeout(() => {
            console.log("...Middle");
          }, halfPageLoadTime);

          return () => {
            clearTimeout(timerId);
          };
        }
        setEventTriggered(false);
      }
    });

    observer.observe({ type: "navigation", buffered: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return <></>;
};

export default BTUpdateEvent;
