import { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledBox, StyledTypography } from "../../elements";
import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledCheckCircleIcon,
} from "./containers-disponibilidad";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTranslation } from "react-i18next";

const ContainerStyleOnlineAvailability = styled((props) => <StyledBox {...props} />)`
  ${({ theme }) => `

  display: flex;
  align-items: end;

  .styled--check-icon {
    font-size: 20px;
    margin-bottom: -1px;

  }

  .check-enabled {
    color:${theme.palette.highlight.main};
  }

  .check-disabled {
    color: ${theme.palette.gray["200"]};
  }

`}
`;

interface Props {
  orderRibbons: any[];
  currentStoreId: any;
  marketInventory: any;
  isKit?: boolean;
  kitMarketAvailability?: number;
  fullFillmentType?: string;
}

const InventarioMercado = (props: Props) => {
  const { orderRibbons, currentStoreId, marketInventory, isKit, kitMarketAvailability, fullFillmentType } = props;
  const { t } = useTranslation();
  const ProStoreOnlyAvailable = orderRibbons?.includes("PRO_STORE_ONLY_AVAILABLE");

  // const currentMarket = productData && productData?.disponibilidadMarket?.filter(market => market?.physicalStoreName === currentStoreId);
  const currentMarket =
    marketInventory && marketInventory.filter((market) => market?.physicalStoreName === currentStoreId);
  const field2 =
    currentMarket?.length > 0 && currentMarket[0]?.x_customField2 ? parseInt(currentMarket[0]?.x_customField2) : 0;
  const availableQuantityInMarket =
    currentMarket?.length > 0 && currentMarket[0]?.x_AvailableQuantityInMarket
      ? parseInt(currentMarket[0]?.x_AvailableQuantityInMarket)
      : 0;

  let markInventory: number =
    marketInventory && availableQuantityInMarket ? availableQuantityInMarket : field2 && field2;

  if (isKit && kitMarketAvailability) {
    markInventory = kitMarketAvailability;
  }

  let cantidadWording = t("productDetail.Availability.labels.availableQuantity", { n: markInventory });
  let wording = t("productDetail.Availability.labels.toBuyOnline");
  let iconClass = "check-enabled";

  if (markInventory > 199) {
    cantidadWording = t("productDetail.Availability.labels.available199");
  }

  if (orderRibbons?.includes("STORE_ONLY_AVAILABLE") || ProStoreOnlyAvailable) {
    cantidadWording = t("productDetail.Availability.labels.forSell");
    wording = t("productDetail.Availability.labels.storeExclusive");
    iconClass = "check-disabled";
  }

  if (fullFillmentType === "Special Order" || markInventory === 999999999) {
    // V8 Disponible en linea, V9 Disponible para compra en linea
    cantidadWording = t("productDetail.Availability.labels.available");
  } else if (fullFillmentType === "Dropship" && markInventory > 199) {
    cantidadWording = t("productDetail.Availability.labels.available199");
  } else if (markInventory === 0) {
    iconClass = "check-disabled";
  }

  return (
    <>
      <ContainerStyleOnlineAvailability>
        <CheckCircleIcon className={`styled--check-icon ${iconClass}`} />
        <StyledTypography variant="bodyBaseline" weight="semibold">
          {cantidadWording}
        </StyledTypography>
        <StyledTypography variant="bodyBaseline" className="left-margin-1">
          {wording}
        </StyledTypography>
      </ContainerStyleOnlineAvailability>
    </>
  );
};

export default InventarioMercado;
