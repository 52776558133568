import { useState, useEffect, Dispatch, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ProductQuantity from "../../../components/custom-components/styled-cantidad-producto/ProductQuantity";
import AddProductCart from "../../functions/AddProductCart";
import { hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { StyledBox, StyledButton, StyledTypography } from "../../elements";
import { pdpQuantityRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { Slide, Collapse, Fade } from "@material-ui/core";
import { numberWithCommas } from "../../../hdm/functions";
import ShopperActionsPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsPDPHDM";
import styled from "styled-components";
import ShopperActionsKitPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsKitPDPHDM";
import { ShopperActionsDataInterface } from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsDataInterface";

const TransitionSlide = forwardRef(function Transition(props: any, ref) {
  props.timeout.enter = 200;
  props.timeout.exit = 200;
  return <Slide direction="right" ref={ref} {...props} />;
});

const ContainerQuantityHeader = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.container-quantity-header {
    position: relative;
  }

  .titulo-comparar-color {
    margin-bottom: 10px;
  }

  .quantity-header {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: flex-end;    
  }

  .header-izquierdo {
    width: calc(60% - 5px); 
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
  }

  .header-derecho {
    width: calc(40% - 5px); 
    height: 40px;
    margin-bottom: 8px;
  }

  .header-izquierdo-disabled {
    width: calc(42% - 5px); 
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
  }

  .header-derecho-disabled {
    width: calc(58% - 5px); 
    height: 40px;
    margin-bottom: 8px;
  }


`}
`;

interface Props {
  marketInventory: any;
  kitAvailabilityData: any;
  catentryData: any;
  showCarritoHeader: boolean;
  isTablet: boolean;
  nominalQuantity: string;
  currentSelection: any;
  currentStoreId: any;
  partNumber: string;
  inventoryInMarket?: number;
  productPrice: number;
  promociones: any;
  quantityMeasure: any;
  isKit?: boolean;
  shopperActionsData?: ShopperActionsDataInterface;
  addComboToCart?: any;
  isB2B: boolean;
  loginStatus: boolean;
  isPdp: boolean;
}

const QuantityHeader = (props: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const {
    marketInventory,
    kitAvailabilityData,
    catentryData,
    showCarritoHeader,
    isTablet,
    nominalQuantity,
    currentSelection,
    currentStoreId,
    partNumber,
    productPrice,
    promociones,
    quantityMeasure,
    isKit,
    shopperActionsData,
    addComboToCart,
    isB2B,
    loginStatus,
    isPdp,
  } = props;
  const quantityRdc = useSelector(pdpQuantityRdc);
  const dispatch = useDispatch<Dispatch<any>>();

  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const [colorCode, setColorCode] = useState<any>(null);

  const [quantity, setQuantity] = useState<string>("1");
  const [unitPrice, setUnitPrice] = useState<any>(null);
  const [totalPriceQuantity, setTotalPriceQuantity] = useState<any>(null);

  useEffect(() => {
    if (hdmDrawerColorData?.colorCode) {
      setColorCode(hdmDrawerColorData?.colorCode);
    } else {
      setColorCode(null);
    }
  }, [hdmDrawerColorData]);

  function ahorroWithComas(ahorro: number) {
    const result = ahorro
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return parseFloat(result);
    return result;
  }

  useEffect(() => {
    const price = numberWithCommas(catentryData);
    if (quantityMeasure !== "") {
      if (quantityMeasure === "c62") {
        setUnitPrice(price.offerPrice);
        return;
      }

      setUnitPrice(price.offerMedidaPrice);
    } else {
      setUnitPrice(price.offerPrice);
    }
  }, []);

  useEffect(() => {
    if (unitPrice) {
      const price = unitPrice.replaceAll(",", "");
      const total = parseFloat(nominalQuantity) * parseFloat(price);
      setTotalPriceQuantity(ahorroWithComas(total));
    }
  }, [unitPrice]);

  useEffect(() => {
    setQuantity(quantityRdc);

    const price = unitPrice ? unitPrice.replaceAll(",", "") : "0";
    if (quantityRdc && quantityRdc !== "") {
      const total = parseFloat(quantityRdc) * parseFloat(price);
      setTotalPriceQuantity(ahorroWithComas(total));
    }

    if (quantityRdc === "") {
      const total = parseFloat(nominalQuantity) * parseFloat(price);
      setTotalPriceQuantity(ahorroWithComas(total));
    }
  }, [quantityRdc]);

  const formatProductPrice = () => {
    const dollarUSLocale = Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" });
    const formattedValue = dollarUSLocale.format(catentryData.price[1].value);
    return formattedValue;
  };

  const formattedPrice = formatProductPrice();

  const handleSetQuantity = (value: string) => {
    const query = {
      url: "",
      data: `${value}`,
      option: "PDP_QUANTITY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

    const total = parseFloat(value) * parseFloat(unitPrice);

    setTotalPriceQuantity(ahorroWithComas(total));

    // setQuantity(value);
  };

  if (isTablet) return null;

  if (!totalPriceQuantity) return null;

  return (
    <ContainerQuantityHeader className="container-quantity-header">
      <Fade in={true} mountOnEnter unmountOnExit>
        <StyledBox style={{ backgroundColor: "#fff" }}>
          <StyledBox>
            {/* {!isKit ? ( */}
              <ShopperActionsPDPHDM
                catentryData={catentryData}
                partNumber={partNumber}
                productPrice={catentryData.price[1].value}
                currentSelection={currentSelection}
                marketInventory={marketInventory}
                kitAvailabilityData={kitAvailabilityData}
                currentStoreId={currentStoreId}
                // updateProductQuantity={updateProductQuantity}
                nominalQuantity={nominalQuantity}
                showCarritoHeader={true}
                showheader={showCarritoHeader}
                total={totalPriceQuantity}
                promociones={promociones}
                quantityMeasure={quantityMeasure}
                loginStatus={loginStatus}
                addComboToCart={addComboToCart}
                isB2B={isB2B}
                isNotZero={true}
                isPdp={isPdp}
              />
            {/* ) : (
              shopperActionsData && (
                <ShopperActionsKitPDPHDM
                  shopperActionsData={shopperActionsData}
                  nominalQuantity={nominalQuantity}
                  showCarritoHeader={showCarritoHeader}
                  addToCart={addToCart}
                />
              )
            )} */}
          </StyledBox>
        </StyledBox>
      </Fade>
    </ContainerQuantityHeader>
  );
  
};

export default QuantityHeader;
