import { StyledTypography } from "../../../../hdm/elements";
import styled from "styled-components";

const StyledLink = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      svg{
        text-decoration: none;
      }
    }
    &.align-center {
      text-align: center;
    }
    &.styledLink--style-buttonLike {
      margin-top: ${theme.spacing(2)}px;
      margin-bottom:  ${theme.spacing(2)}px;
      text-align: center;
      display: block;
    }
  `}
`;

const StyledLinkButton = (props) => {
  return (
      <StyledLink {...props} variant={props.variant ? props.variant : "bodyBaseline"}/>
  );
};

export { StyledLinkButton };
