import { StyledAccordion, StyledBox, StyledGrid } from "../../../../../hdm";
import { SLFooterElements } from "../dimensions/Footer";
import { StyledSkeleton } from "../StyledSkeleton";

const SLFooterNavigationCard = () => {
    return (
        <>
            <StyledAccordion
                defaultExpanded={true}
                square={false}>
                <StyledGrid container
                    spacing={2}
                    justifyContent="space-between"
                >
                    <StyledGrid item xs={8}>
                        <StyledSkeleton variant="rect" width={SLFooterElements.navigation.title.getWidth()} height={SLFooterElements.navigation.title.getHeight()} />
                    </StyledGrid>
                    <StyledGrid item xs={4}>
                        <StyledGrid container justifyContent="flex-end">
                            <StyledGrid>
                                <StyledSkeleton variant="rect" className={"right-margin-1"} width={SLFooterElements.navigation.chevron.getWidth()} height={SLFooterElements.navigation.title.getHeight()} />
                            </StyledGrid>
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>

                <StyledBox >
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem1.getWidth()} height={SLFooterElements.navigation.elem1.getHeight()} />
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem2.getWidth()} height={SLFooterElements.navigation.elem2.getHeight()} />
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem1.getWidth()} height={SLFooterElements.navigation.elem1.getHeight()} />
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem2.getWidth()} height={SLFooterElements.navigation.elem2.getHeight()} />
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem1.getWidth()} height={SLFooterElements.navigation.elem1.getHeight()} />
                    <StyledSkeleton variant="rect" width={SLFooterElements.navigation.elem2.getWidth()} height={SLFooterElements.navigation.elem2.getHeight()} />
                </StyledBox>
            </StyledAccordion>
        </>

    );
}
//footer newsletter
export const SLFooterNewsLetter = (props) => {
    return (
        <StyledGrid id={"home--footer-newsLetterContainer"} container className={"footer-sup"}>
            <StyledGrid
                container
                // xs={12}
                className={"footer-sup__grid-up-container"}>
            
            <StyledSkeleton variant="rect"  className="wide horizontal-margin-3 vertical-margin-3" height={20}/>


            </StyledGrid>
            <StyledGrid
                container
                // xs={12}
                className={"footer-sup__grid-down-container"}>
                <StyledSkeleton className={"wide"} variant="rect" height={20} />
            </StyledGrid>
        </StyledGrid>
    )
}
//footer cards
export const SLFooterNavigationCards = () => {
    return (

        <StyledGrid container spacing={2} >
            <StyledGrid item xs={3}>
                <SLFooterNavigationCard />
            </StyledGrid>
            <StyledGrid item xs={3}>
                <SLFooterNavigationCard />
            </StyledGrid>
            <StyledGrid item xs={3}>
                <SLFooterNavigationCard />
            </StyledGrid>
            <StyledGrid item xs={3}>
                <SLFooterNavigationCard />
            </StyledGrid>
        </StyledGrid>

    );
}