/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import { useMatch } from "react-router";
import { useSelector } from "react-redux";
//custom libraries
import { HOME } from "../../../constants/routes";
//custom redux
import { forUserIdSelector } from "../../../redux/selectors/user";
import { palette } from "../../../hdm/themes";
import { StyledGrid } from "../../../hdm";

const StyledWrapper = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${(props) => {
    const theme = props.theme;
    const match = useMatch({
      path: HOME,
      end: true,
      caseSensitive: true,
    });
    const forUserId = useSelector(forUserIdSelector);

    const headingClasses = "";

    /*for (let i = 1; i <= 6; i += 1) {
      headingClasses += `
        h${i}:not(.MuiTypography-root) {
          font-size: ${theme.typography[`h${i}`].fontSize};
          font-weight: ${theme.typography[`h${i}`].fontWeight};
          line-height: ${theme.typography[`h${i}`].lineHeight};
        }
      `;
    }*/

    let paddingClasses = "";

    for (let i = 1; i <= 20; i += 1) {
      paddingClasses += `
        .horizontal-padding-${i} {
          padding-left: ${theme.spacing(i)}px;
          padding-right: ${theme.spacing(i)}px;
        }
        .vertical-padding-${i} {
          padding-top: ${theme.spacing(i)}px;
          padding-bottom: ${theme.spacing(i)}px;
        }
        .top-padding-${i} {
          padding-top: ${theme.spacing(i)}px;
        }
        .bottom-padding-${i} {
          padding-bottom: ${theme.spacing(i)}px;
        }
        .left-padding-${i} {
          padding-left: ${theme.spacing(i)}px;
        }
        .right-padding-${i} {
          padding-right: ${theme.spacing(i)}px;
        }
        .horizontal-margin-${i} {
          margin-left: ${theme.spacing(i)}px;
          margin-right: ${theme.spacing(i)}px;
        }
        .vertical-margin-${i} {
          margin-top: ${theme.spacing(i)}px;
          margin-bottom: ${theme.spacing(i)}px;
        }
        .top-margin-${i} {
          margin-top: ${theme.spacing(i)}px;
        }
        .bottom-margin-${i} {
          margin-bottom: ${theme.spacing(i)}px;
        }
        .left-margin-${i} {
          margin-left: ${theme.spacing(i)}px;
        }
        .right-margin-${i} {
          margin-right: ${theme.spacing(i)}px;
        }
        .margin-${i} {
          margin: ${theme.spacing(i)}px;
        }
        .padding-${i} {
          padding: ${theme.spacing(i)}px;
        }
      `;
    }

    let fontSizeClasses = "";
    for (let i = 10; i <= 20; i += 1) {
      fontSizeClasses += `
        .hdm-font-${i} {
          font-size: ${i}px;
        }
      `;
    }

    const colorsClasses = `
      //Light Gray
      .color-gray50 {
        color: ${palette.gray["50"]}; 
      }

      // Gray
      .color-gray100 {
        color: ${palette.gray["100"]};
      }

      //Medium Gray
      .color-gray200 {
        color: ${palette.gray["200"]};
      }

      //Dark Gray
      .color-gray300 {
        color: ${palette.gray["300"]};
      }

      //PRO Shade
      .color-gray400 {
        color: ${palette.gray["400"]};
      }

      //Black
      .color-gray500 {
        color: ${palette.gray["500"]};
      }
      .color-highlight {
        color: ${palette.highlight.main}
      }
      .color-secondary {
        color: ${palette.secondary.main};
      }
      .color-primary {
        color: ${palette.primary.main};
      }
      .color-error {
        color: ${palette.error.main};
      }
      .divider-gray50 {
        background-color: ${palette.gray["50"]};
      }
    `;

    let borderClasses = "";

    for (let i = 1; i <= 2; i += 1) {
      borderClasses += `
        .border-bottom-solid-${i}-primary {
          border-bottom: solid ${palette.primary.main} ${i}px;
        }
        .border-bottom-solid-${i}-secondary {
          border-bottom: solid ${palette.secondary.main} ${i}px;
        }
      `;
    }

    let displayClasses = "";

    displayClasses += `
        .display-inline{
          display:inline-flex;
        }
        .display-block{
          display:block;
        }
        .display-flex{
          display:flex;
        }
        .display-inlineBlock{
          display:inline-block;
        }
      `;

    return `
      font-family: ${theme.typography.fontFamily};

      ${paddingClasses}
      ${headingClasses}
      ${colorsClasses}
      ${fontSizeClasses}
      ${borderClasses}
      ${displayClasses}

      .vertical-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .horizontal-center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .horizontal-center.vertical-center,
      .full-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .full-height {
        height: 100%;
      }

      .full-width {
        width: 100% !important;
      }

      .full-viewport-height {
        ${forUserId !== undefined ? `min-height: 1000px;` : `min-height: 100vh;`}
      }

      .full-viewport-width {
        width: 100vw;
      }

      .text-align-center {
        text-align: center;
      }

      .text-align-right {
        text-align: right;
      }

      .text-align-left {
        text-align: left;
      }

      .marketing-button {
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.button.fontSize}px;
        border-radius: ${theme.shape.borderRadius}px;
        padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
        letter-spacing: 0.02rem;
        box-shadow: none;
        color: ${theme.palette.text.secondary};
        line-height: 1.75;
        font-weight: 500;
        border: 2px solid ${theme.palette.primary.main};
        background: white;
        cursor: pointer;
        background: white;
        transition: all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn};

        &:hover {
          border-color: ${theme.palette.primary.dark};
          color: ${theme.palette.primary.dark};
        }

        &.primary {
          background: ${theme.palette.primary.main};
          border-width: 0;
          color: white;

          &:hover {
            background: ${theme.palette.primary.dark};
          }
        }
      }


      }

      img {
        max-width: 100%;
      }

      .product-image {
        max-width: 150px;
      }

      .upperCase{
        text-transform: uppercase;
      }

      ${
        match?.pathname === HOME
          ? `
          @supports ((perspective: 1px) and (not (-webkit-overflow-scrolling: touch))) {
            ${forUserId === undefined && `height: auto;`}
            overflow-x: hidden;
            //overflow-y: auto;
            // perspective: 3px;
            // perspective-origin: 50% 50%;

            .parallax__layer {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .parallax__layer--base {
              transform: translateZ(0);
            }

            .parallax__layer--back {
              transform: translateZ(-1px) translateY(-80%) translateX(-25%);

              img {
                max-width: 150%;
                width: 150%;
              }

              ${theme.breakpoints.down("xl")} {
                transform: translateZ(-1px) translateY(-150%) translateX(-25%);
              }

              ${theme.breakpoints.down("lg")} {
                transform: translateZ(-1px) translateY(-120%) translateX(-25%);
              }

              ${theme.breakpoints.down("md")} {
                transform: translateZ(-1px) translateY(-80%) translateX(-25%);
              }

              ${theme.breakpoints.down("sm")} {
                transform: translateZ(-1px) translateY(-80%) translateX(-30%);
                img {
                  max-width: 250%;
                  width: 250%;
                }
              }
            }
          }
        `
          : ``
      }

    `;
  }}
`;

export default StyledWrapper;
