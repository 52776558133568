import { Dispatch, Fragment, useEffect, useState } from "react";

import { StyledCardEmpty } from "../../elements/card";

import { Divider } from "@material-ui/core";

import { palette } from "../../themes/color-palette";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";

import {
  StyledBox,
  StyledFormControl,
  StyledFormControlLabel,
  StyledMenuItem,
  StyledNativeSelect,
  StyledRadio,
  StyledRadioGroup,
  StyledSelect,
  StyledTypography,
} from "../../elements";
import React from "react";
import { useNavigate } from "react-router";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { useTranslation } from "react-i18next";
import { StyledSelectHdm } from "../../../components/custom-components/styled-select/StyledSelectHdm";
import { useSite } from "../../../_foundation/hooks/useSite/useSite";
import Axios, { Canceler } from "axios";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import SuperSKU from "../../elements/product-card/Styled-product-supersku";
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import { BuyByColorDrawer } from "../pdp-comparar-color/BuyByColorDrawer";
import { MenuItemHDM } from "../../../components/header/categories-menu/MenuDesktop";
import { CLOSE_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import {
  SLPDPHelpForChoose,
  SLPDPSelectYourOptions,
} from "../../../components/custom-components/skeleton-loader/components/elements/PDP";

interface AttrValue {
  identifier: any;
  SKUs: Array<string>;
}
interface AttrSuperSKU {
  identifier: any;
  values: Map<string,AttrValue>;
}

interface Props {
  product: any;
  currentPartNumber: string;
  isTablet: boolean;
  currentMarket: any;
}
const SuperSkuContainer = (props: Props) => {
  const { product, currentPartNumber, isTablet, currentMarket } = props;

  const [superSKUAttributes, setSuperSKUAttributes] = useState<any>(null);
  const [itemsAvailability, setItemsAvailability] = useState<any>(null); //initial value null, for change value detection when availability request ends
  const [currentSKUAttributes, setCurrentSKUAttributes] = useState<any>(null);
  const [lastRadioButtonChanged, setLastRadioButtonChanged] = useState<any>(null);
  const [superSKUEspot, setSuperSKUEspot] = useState<any>(null);
  const [superSKUEspotComponent, setSuperSKUEspotComponent] = useState<any>(null);
  const [espotHasContent, setEspotHasContent] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const formRef: any = React.useRef(null);
  const buttonRef: any = React.useRef(null);
  const navigate = useNavigate();
  const currentStore = useSelector(currentStoreSelector);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const { t } = useTranslation();
  const superSkuDrawerTitle = t("SuperSku");
  const [colorCode, setColorCode] = useState<any>(null);
  const [showSkeletonLoader, setShowSkeletonLoader] = useState<any>(false); //flag for showing the Skeleton Loader
  const [excludedItems, setExcludedItems] = useState<any[]>([]);
  //Map of attribute values with exact match
  const [valueAvailability, setValueAvailability] = useState<{ [key: string]: boolean }>({}); //key -> AttrValueID: value -> If has existing exact match (if not it will be opaque) 
  //Relacion de valores de atributos superSKU y los productos
  const superSKUAttrValues : Map<string, AttrSuperSKU> = new Map();//ATTR_ID: AttrSuperSKU  

  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const DrawerEspotName = isB2B ? "PRO_PDP-SuperSKU_Help" : "PDP-SuperSKU_Help";

  const dispatch = useDispatch<Dispatch<any>>();

  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const currentStoreData = useSelector(currentStoreSelector);
  const storeUniqueId = currentStoreData?.currentStore?.uniqueID;

  const attributes = product?.attributes;
  const numberOfSKUs = product?.numberOfSKUs;
  const items = product?.items;
  const partNumber = product?.partNumber;

  useEffect(() => {
    if (hdmDrawerColorData?.changeBaseColor) {
      handleChangeBaseColor(hdmDrawerColorData?.changeBaseColor);
    }
  }, [hdmDrawerColorData]);

  useEffect(() => {
    if (items && currentMarket) {
      //Physical store identifier
      const marketPhysicalStoreIdentifier = currentMarket?.physicalStoreName ? currentMarket?.physicalStoreName : currentStoreData?.currentStore?.stLocId;
      //Physical store Id
      const marketPhysicalStoreId = currentMarket?.physicalStoreId ? currentMarket?.physicalStoreId : storeUniqueId;
      const excludedItems = items.filter((item) => {
        const storeBuyableExclude = item.attributes.find((attr) => attr.identifier === "STORE_BUYABLE_EXCLUDE");

        const marketBuyableExclude = item.attributes.find((attr) => attr.identifier === "MARKET_BUYABLE_EXCLUDE");

        const isStoreExcluded = storeBuyableExclude?.values.some((value) =>
          Array.isArray(value.value)
            ? value.value.includes(marketPhysicalStoreId) ||
              value.value.includes(marketPhysicalStoreIdentifier)
            : value.value === marketPhysicalStoreId || value.value === marketPhysicalStoreIdentifier
        );

        const isMarketExcluded = marketBuyableExclude?.values.some((value) =>
          Array.isArray(value.value)
            ? value.value.includes(marketPhysicalStoreId) ||
              value.value.includes(marketPhysicalStoreIdentifier)
            : value.value === marketPhysicalStoreId || value.value === marketPhysicalStoreIdentifier
        );

        return isStoreExcluded || isMarketExcluded;
      });

      const availableItems = items.filter((item) => !excludedItems.includes(item));
      setExcludedItems(excludedItems);
    }
  }, [items, currentMarket]);

  function extractCurrentSSKUValues(items) {
    const currentValues = {};
    const data = items?.find((item) => item.partNumber === currentPartNumber);

    superSKUAttributes?.forEach((attribute) => {
      const match = data?.attributes?.find((dataAttribute) => dataAttribute.identifier === attribute.identifier);
      if (match !== null) {
        currentValues[attribute.id] = {
          value: match?.values[0]?.identifier,
          identifier: attribute?.identifier,
          valueId: match?.values[0]?.id,
        };
      } else {
        currentValues[attribute.identifier] = null;
      }
    });

    //console.debug("--extractCurrentSSKUValues--")
    //console.debug(currentValues)
    setCurrentSKUAttributes(currentValues);
    handleCheckScroll();
  }

  const handleCheckScroll = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      setTimeout(() => {
        sessionStorage.removeItem("scrollPosition");
        window.scrollTo({ top: parseInt(scrollPosition), behavior: "instant" });
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);
    } else {
      setTimeout(() => {
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);
    }
  };

  function extractSSKUValues(data) {
    const prefix = partNumber.split("_")[0];
    const attrs: any = [];
    data?.forEach((element) => {
      //Filter attributes corresponding to this super sku
      if (element.identifier.endsWith(prefix)) {
        const skuData = {};
        skuData["title"] = element.name;
        skuData["id"] = element.id;
        skuData["identifier"] = element.identifier;
        skuData["usage"] = element?.usage;
        skuData["counter"] = element.values.length;
        const values: any = [];
        //console.debug("element", element)
        element.values.forEach((value) => {
          const valueData = {};
          valueData["value"] = value.value;
          valueData["id"] = value.id;
          valueData["identifier"] = value.identifier;
          valueData["isPublished"] = valueAvailability[value.id] || false;
          valueData["isAvailable"] = isValueAvailableInSSKUItems(items, value.id);
          valueData["isDisplayable"] = isValueDisplayableInSSKUItems(items, value.id);
          values.push(valueData);
        });
        skuData["values"] = values;
        attrs.push(skuData);
      }
      setSuperSKUAttributes(attrs);
    });

    if (attrs.length !== 0) {
      const query = {
        url: "",
        data: {
          ...hdmDrawerColorData,
          superSKUAttributes: attrs,
        },
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };

      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  }

  function isItemAvailable(id) {
    if (Object.keys(itemsAvailability).length > 0) {
      return Object.prototype.hasOwnProperty.call(itemsAvailability, id) && itemsAvailability[id];
    }
  }

  //check if value id is in any of the sSKU items
  function isValueAvailableInSSKUItems(items, id) {
    //check is attribute is in item
    //check inventory availability of items
    //if all items are unavailable, then the attribute is disabled
    const productsWithCurrentID = items?.filter((item) => {
      if (item?.attributes) {
        return item.attributes.some((attribute) => {
          return attribute.values.some((value) => {
            return value.id === id;
          });
        });
      }
    });

    /*  if(productsWithCurrentID.length > 0 ){
      availableInItemAttributes = true;
    }*/

    if (!productsWithCurrentID || productsWithCurrentID.length === 0) {
      return false;
    }

    const hasInventory = productsWithCurrentID.some((item) => isItemAvailable(item.id));

    if (!hasInventory) {
      return false;
    }

    //check for STORE_BUYABLE_EXCLUDE and item availability
    const isAnyItemValidAndAvailable = productsWithCurrentID.some((item) => {
      const isAvailable = isItemAvailable(item.id);
      return item.attributes.some((attribute) => {
        if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
          if (typeof attribute?.values[0]?.value === "string") {
            if (attribute?.values[0]?.value === currentStore.currentStore?.stLocId) {
              return false;
            } else {
              //if STORE_BUYABLE_EXCLUDE is not in item attributes
              return true && isAvailable;
            }
          } else {
            const isPhysicalStoreIdInValues = attribute.values.some((value) =>
              value.value.some((storeId) => storeId === currentStore.currentStore?.stLocId)
            );

            return !isPhysicalStoreIdInValues && isAvailable;
          }
        } else {
          return true && isAvailable;
        }
      });
    });

    return isAnyItemValidAndAvailable;
  }

  //check if value id is displayable
  function isValueDisplayableInSSKUItems(items, id) {
    //check is attribute is in item
    //check inventory availability of items

    const productsWithCurrentID = items?.filter((item) => {
      if (item?.attributes) {
        return item.attributes.some((attribute) => {
          return attribute.values.some((value) => {
            return value.id === id;
          });
        });
      }
    });

    //check for STORE_BUYABLE_EXCLUDE and item availability
    //all products must be valid
    const isAttributeVisibleInAnyItem = productsWithCurrentID.some((item) => {
      return item.attributes.some((attribute) => {
        if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
          if (typeof attribute?.values[0]?.value === "string") {
            if (attribute?.values[0]?.value === currentStore.currentStore?.stLocId) {
              return false;
            } else {
              //if STORE_BUYABLE_EXCLUDE is not in item attributes
              return true;
            }
          } else {
            const isPhysicalStoreIdInValues = attribute.values.some((value) =>
              value.value.some((storeId) => storeId === currentStore.currentStore?.stLocId)
            );

            return !isPhysicalStoreIdInValues;
          }
        } else {
          return true;
        }
      });
    });

    return isAttributeVisibleInAnyItem;
  }

  //This function will probably disappear
  /*function findItemByLastRadioChange(items) {
    const keys = Object.keys(lastRadioButtonChanged);

    const matches = items?.filter((item) => {
      return keys
        .map((key) => {
          return item.attributes.some((attr) => attr.id === key && attr.values[0]?.id === lastRadioButtonChanged[key]);
        })
        .some((value) => value);
    });

    //of all matches, select the first in which the current store is noOt excluded
    let match = undefined;
    if (matches !== undefined) {
      match = matches.find((match) =>
        match.attributes.some((attribute) => {
          if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
            return !attribute.values.some((value) => {
              return value.value.includes(currentStore.currentStore?.stLocId);
            });
          } else {
            return true;
          }
        })
      );
    }

    return match;
  }*/

  function findItemByRadioGroupSelection(items, selection) {
    console.log("findItemByRadioGroupSelection",selection,items)
    //The selection object structure is ATTR_ID: ATTRVAL_ID
    const selectedAttributeIds = Object.keys(selection);
  
    // Intentar encontrar una coincidencia exacta primero
    //Filtra items de todos los items que cumplan la condicion
    let matches = items?.filter((item) => {
      //Todos los atributos (attrId) deben existir en el attributes del producto actual
      return selectedAttributeIds.every((attrId) => {
        //Debe hacer match con algun atributo del producto
        return item.attributes.some(
          (attr) =>
            attr.id === attrId && //Es el atributo en cuestion
            //Y el valor del atributo debe ser igual al valor del atributo actual seleccionado
            attr.values.some((val) => val.id === selection[attrId]) //Y es el valor seleccionado
        );
      });
    });
  
    // Excluir productos donde la tienda actual esta excluida
    matches = matches?.filter((item) => !isProductExcluded(item));
  
    if (matches?.length > 0) {
      // Retornar la primera coincidencia exacta
      console.log("Encontro match exacto")
      return matches[0];
    }
    console.log("No encontro un match exacto")
  
    // No se encontro coincidencia exacta, buscar el producto con mayor numero de atributos coincidentes
    /*const itemsWithMatchCount = items?.map((item) => {
      //Conteo de atributos 
      const matchCount = selectedAttributeIds.reduce((count, attrId) => {
        //Valor del atributo en cuestion
        const attrValueId = selection[attrId];
        const hasAttributeValue = item.attributes.some(
          (attr) =>
            attr.id === attrId &&
            attr.values.some((val) => val.id === attrValueId)
        );
        return count + (hasAttributeValue ? 1 : 0);
      }, 0);
      return { item, matchCount };
    });
    console.log("itemsWithMatchCount",itemsWithMatchCount)
    // Encontrar el maximo numero de coincidencias
    const maxMatchCount = Math.max(
      ...itemsWithMatchCount.map((i) => i.matchCount)
    );
  
    // Obtener todos los productos con el maximo numero de coincidencias
    const bestMatches = itemsWithMatchCount
      .filter((i) => i.matchCount === maxMatchCount)
      .map((i) => i.item);
  
    // Excluir productos donde la tienda actual esta excluida
    const bestAvailableMatches = bestMatches.filter(
      (item) => !isProductExcluded(item)
    );
  
    if (bestAvailableMatches.length > 0) {
      return bestAvailableMatches[0]; // Retornar el producto mas cercano disponible
    }*/

    //Debe hacer pivote con el nuevo atributo elegido
    const secondMatch = findBestMatchForNewAttributeSelected(items);
    if(secondMatch){
      console.log("Encontro un match alternativo",secondMatch)
      return secondMatch;
    }
  
    // Si no se encuentra ninguna coincidencia, retornar el primer producto disponible
    const availableItems = items.filter((item) => !isProductExcluded(item));
    return availableItems[0] || null;
  }

  /**
   * Hace pivote con el nuevo atributo elegido para encontrar la segunda mejor coincidencia
   */
  function findBestMatchForNewAttributeSelected(items){
    if(superSKUAttributes && items){
      
      //Imprimir todos los productos hijos
      const productSSKUAttributeValues : Map<string, Map<string,any>> = new Map();
      items?.forEach((item) => {
        //Atributos SS del producto
        const productSSAttValues : Map<string,any> = new Map();
        //console.log("Buscando atributos SS del producto",item?.partNumber)
        superSKUAttributes.forEach((attributeSS) => {
            //Busca el valor del atributo para el item actual
            const attrMatch = item.attributes?.find((productAttr) => productAttr.id === attributeSS.id);
            if(attrMatch){
              productSSAttValues.set(attributeSS.id, //ID de atributo
                {
                  identifier: attributeSS?.identifier, //Identificador del atributo
                  value: {
                    id: attrMatch?.values[0]?.id,
                    identifier: attrMatch?.values[0]?.value, //Valor del atributo
                  }
                }
              );
              return; //Stop this iteration
            }
        });
        productSSKUAttributeValues.set(item?.partNumber, productSSAttValues)
      });
      console.log("productSSKUAttributeValues",productSSKUAttributeValues)
  
      //Recupera el valor de los atributos SS para el producto hijo
      //const superSKUAttributesMap : Map<string,string> = new Map();
      superSKUAttributes.forEach((attribute) => {
        //const currentAttributeValues
        const currentAttrId = attribute?.id;
        const attrValues: Map<string,AttrValue> = new Map();
        attribute.values.forEach((value) => {
          const currentSKUs = new Array<string>;
  
          const currentAttrValId = value?.id;
          //Recupera los productos que hacen match con ese valor de atributo
          productSSKUAttributeValues.forEach((attr: Map<string,any>, sku) =>{
            //Itera los valores de atributo
            if(attr?.get(attribute?.id)?.value?.id === currentAttrValId){
              currentSKUs.push(sku)
              return;
            }
          });
          //superSKUAttributesMap.set(attribute.id, attribute.identifier)
          const currentAttrValue: AttrValue = {
            identifier: value?.identifier,
            SKUs: currentSKUs
          };
          attrValues.set(currentAttrValId, currentAttrValue)
        })
        const currentAttr: AttrSuperSKU = {
          identifier: attribute?.identifier,
          values: attrValues
        };
        superSKUAttrValues.set(currentAttrId,currentAttr)
      });
      
      //Current new attribute selected
      const newAttrId = Object.keys(lastRadioButtonChanged)[0];
      const newAttrValueId = lastRadioButtonChanged[newAttrId];
      //Searchs all SKUs with that attribute
      const attrValueSelected = superSKUAttrValues?.get(newAttrId)?.values.get(newAttrValueId);
      const skusWithNewAttrValue: string[] = attrValueSelected?.SKUs || [];
      let itemsEncontrados = items?.filter(item => skusWithNewAttrValue?.find(sku => item?.partNumber === sku));
      // Excluir productos donde la tienda actual esta excluida
      itemsEncontrados = itemsEncontrados?.filter((item) => !isProductExcluded(item));
      return itemsEncontrados?.length > 0 ? itemsEncontrados[0] : null;
    }
    else {
      return null;
    }
  }

  function obtainItemsAvailability(items) {
    const itemsIds = items ? items.map((item) => item.id) : [];
    const itemAvailability = {};

    customInv
      .getAvailabilityByStores({
        productIds: itemsIds,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: [storeUniqueId],
      })
      .then((data) => {
        data.forEach((item) => {
          const isAvailable =
            item.inventoryStatus === "Available" &&
            (Number(item?.availableQuantity) > 0 ||
              Number(item?.x_AvailableQuantityInMarket) > 0 ||
              Number(item?.x_customField2) > 0);

          const isExcluded = excludedItems.some((excludedItem) => excludedItem.id === item.productId);

          itemAvailability[item.productId] = isAvailable && !isExcluded;
        });

        setItemsAvailability(itemAvailability);

        items.forEach((item) => {
          const isAvailable = itemAvailability[item.id];
        });
      })
      .catch((error) => {
        //If error, set items availability empty
        setItemsAvailability({});
        console.error("Algo salio mal:", error);
        handleCheckScroll();
      });
  }

  useEffect(() => {
    //get items inventories
    obtainItemsAvailability(items);
  }, [product]);

  useEffect(() => {
    //If still have the initial value null, do nothing
    if (itemsAvailability != null) {
      //Here a value has been setted
      if (Object.keys(itemsAvailability).length > 0) {
        extractSSKUValues(attributes);
      } else {
         //Item availability request completed, but no item available
        setShowSkeletonLoader(false); //Stop showing Skeleton Loader
        setCurrentSKUAttributes(undefined); //Flag for empty currentSKUAttributes, allow to hide the Skeleton Loader
      }
    }

    setTimeout(() => {
      handleCheckScroll();
    }, 5000);
  }, [itemsAvailability]);

  useEffect(() => {
    superSKUAttributes != null && extractCurrentSSKUValues(items);
  }, [superSKUAttributes]);

  //on component load, call for SuperSKU_Help Espot
  useEffect(() => {
    const asyncCall = async () => {
      const parameters: any = {
        storeId: storeID,
        name: DrawerEspotName,
        catalogId: catalogID,
        query: {
          DM_ReturnCatalogGroupId: true,
          DM_FilterResults: false,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      let eSpotRoot;
      try {
        const res = await eSpotService.findByName(parameters);
        eSpotRoot = res.data.MarketingSpotData[0];
        if (!eSpotRoot?.eSpotName) {
          // return eSpotRoot = null;
          //If no espot, change null to undefined
          //In order to detect a new value and then can hide the sekeleton loader
          return setSuperSKUEspot(undefined);
        }
        setSuperSKUEspot(res);
      } catch (e) {
        eSpotRoot = null;
      }
    };

    asyncCall();
  }, [superSKUAttributes]);

  useEffect(() => {
    //If still have the initial value null, do nothing
    if (superSKUEspot !== null) {
      //Here a value has been setted
      //If there is an espot drawer, enable the link to open it
      if (superSKUEspot != undefined) {
        setEspotHasContent(true);
        setSuperSKUEspotComponent(<EspotHdm espotName={DrawerEspotName} />);
      } else {
        //Asign false so the Skeleton Loader can be hidden
        setEspotHasContent(false);
      }
    }
  }, [superSKUEspot]);

  const handleSetScrollPosition = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const handleChangeBaseColor = async ({ name, value, id, color }) => {
    const lastSelection = {};
    lastSelection[id] = value;

    await setColorCode(color);
    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: name, valueId: value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);

    buttonRef.current.click();
  };

  //Al dar click en algun radio button
  //Recibe el evento del radio button y el ID del atributo
  const handleChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    const updatedValue = {};

    //value field is not used, in case it is neccesary.. further development is necessary
    //Actualiza el atributo seleccionado
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);
    //Realiza el envio del formulario (handleSubmit)
    buttonRef.current.click();
  };

  const handleSelectChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    await setLastRadioButtonChanged(lastSelection);

    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));

    buttonRef.current.click();
  };

  //Al realizar el envio del formulario
  const handleSubmit = (event) => {
    event.preventDefault();
    const radioGroupSelection = {};

    superSKUAttributes.forEach((attr) => {
      const element = formRef?.current?.elements[attr.identifier];
      if (element) {
        radioGroupSelection[attr.id] = element.value;
      }
    });

    const availableItems = items.filter((item) => itemsAvailability[item.id]);

    const match = findItemByRadioGroupSelection(availableItems, radioGroupSelection);

    if (match?.seo?.href) {
      const color = colorCode ? `#colorCode:${colorCode}` : "";
      handleSetScrollPosition();
      navigate(match.seo.href + color);
    }  else  {
    const firstAvailableItem = availableItems[0];
    if (firstAvailableItem?.seo?.href) {
      navigate(firstAvailableItem.seo.href);
    } /*else {
      findItemByLastRadioChange(items);
    }*/
  }
  };

  const PDPRightSideCard = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

    background-color:white;

    .super-sku-label-unavailable{
      opacity:60%;
    }

    .atribute-sku-hide {
      display: none;
    }


  `}
  `;
  const PDPRightSideCardRadioContainer = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

      display: flex;
      
      margin-right:10px;
      margin-bottom:10px;
      p{
        margin-right:5px;
      }

      .PDP-SSKUContainer-RadioComponent{
        margin-bottom:10px;
        display: flex;
        align-items:center;
      }

  `}
  `;

  const PDPRightSideCardContainer = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

    padding:10px;
    margin-bottom: 10px;

    

    .PDPRightSideCard-title{
      margin-bottom: 10px;
    }

    .PDPRightSideCard-divider{
      background-color:${theme.palette.gray["50"]};
      margin-bottom:10px;
    }


  `}
  `;

  //console.log("---supersku---")
  //console.log(product);
  // console.log('superSKUAttributes', superSKUAttributes);
  // console.log('currentSKUAttributes', currentSKUAttributes)
  let cantidadOpciones: number = 0;
  if (superSKUAttributes && superSKUAttributes.length > 0) {
    cantidadOpciones = superSKUAttributes.length;

    superSKUAttributes.forEach((attribute) => {
      if (attribute?.identifier.includes("BASE_") && attribute?.usage === "Defining") {
        cantidadOpciones = superSKUAttributes.length - 1;
      }
    });
  }

  let shouldRenderComponent = false;

  if (items && items.length > 0 && itemsAvailability != null) {
    const availableItems = items.filter((item) => {
      return item.partNumber !== currentPartNumber && isItemAvailable(item.id);
    });
    shouldRenderComponent = availableItems.length > 0;
  }

  /*function isValueAvailableBasedOnItemsAvailability(valueId) {
    const relevantItems = items?.filter((item) =>
      item.attributes.some((attribute) => attribute.values.some((value) => value.id === valueId))
    );

    if (!relevantItems || relevantItems.length === 0) {
      return false;
    }

    return relevantItems.some((item) => isItemAvailable(item.id));
  }*/

  /*
  Busca si el valor del atributo tiene un match exacto con el resto de los atributos seleccionados
  Si no, dicho valor de atributo se mostrara con opacidad del 60%
  */
  useEffect(() => {
    if (superSKUAttributes && itemsAvailability && currentSKUAttributes) {
      const newValueAvailability: { [key: string]: boolean } = {};
      superSKUAttributes.forEach((attribute) => {
        attribute.values.forEach((value) => {
          //Copy the current attributes selection
          const selectionCopy = { ...currentSKUAttributes };
          //Current value attribute variant
          selectionCopy[attribute.id] = {
            value: value.value,
            identifier: attribute.identifier,
            valueId: value.id,
          };
  
          const matchingProduct = findMatchingProduct(selectionCopy);
          //Si el valor del atributo SS esta disponible (si no hay match exacto quedara opaco)
          newValueAvailability[value.id] = matchingProduct !== null;
  
          if (!matchingProduct) {
            console.log(
              `No se encontro un producto disponible para la combinacion con ${attribute.title}: ${value.value}`
            );
          } else {
            const isExcluded = isProductExcluded(matchingProduct);
            if (isExcluded) {
              console.log(
                'Opcion que lleva a un producto despublicado:',
                attribute.title,
                value.value
              );
            } else {
              console.log(
                'Opcion que lleva a un producto disponible:',
                attribute.title,
                value.value
              );
            }
          }
        });
      });
  
      setValueAvailability(newValueAvailability);
    } else {
      console.log('Datos faltantes para ejecutar useEffect');
    }
  }, [superSKUAttributes, itemsAvailability, excludedItems, currentSKUAttributes]);

  //Busca un match exacto con la combinacion de atributos ingresada
  function findMatchingProduct(selection) {
    const newSelectedAttributes = Object.keys(selection);
  
    const matchingProducts = items.filter((item) => {
      //Para cada atributo de la nueva seleccion
      const matchesAllAttributes = newSelectedAttributes.every((attrId) => {
        const itemHasAttribute = item.attributes.some((itemAttr) => {
          const attributeMatches =
            itemAttr.id === attrId &&
            itemAttr.values.some(
              (val) => val.id === selection[attrId].valueId
            );
          return attributeMatches;
        });
        return itemHasAttribute;
      });
      return matchesAllAttributes;
    });
  
    console.log('Seleccion actual:', selection);
    console.log('Productos que coinciden:', matchingProducts);
  
    const availableProducts = matchingProducts.filter(
      (product) => itemsAvailability[product.id]
    );
  
    console.log('Productos disponibles:', availableProducts);
  
    return availableProducts.length > 0 ? availableProducts[0] : null;
  }
  
  
  function isProductExcluded(item) {
    return item.attributes.some((attribute) => {
      if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
        return attribute.values.some((value) => {
          return value.value.includes(currentStore.currentStore?.stLocId);
        });
      }
      return false;
    });
  }
  
  return (
    <>
      {attributes && items && currentStoreData?.currentStore?.uniqueID && (
        <>
          {/* This over-validations seems unnecesary, but must be this way for Skeleton Loader right working */}
          {showSkeletonLoader || superSKUAttributes == null || currentSKUAttributes == null ? (
              <SLPDPSelectYourOptions />
            ) : shouldRenderComponent ? (
              <PDPRightSideCard id="pdp-supersku-card">
                <PDPRightSideCardContainer className="super-sku supersku-card">
                  <StyledTypography variant="textSegment" className={"PDPRightSideCard-title"}>
                    Selecciona tus opciones
                  </StyledTypography>

                  <Divider className={"PDPRightSideCard-divider"} />
                  <form ref={formRef} onSubmit={handleSubmit}>
                    {/* Print all SS attributes */}
                    {superSKUAttributes.map((attribute) => {
                      let classAttrHide = "";
                      if (attribute?.identifier.includes("BASE_") && attribute?.usage === "Defining") {
                        classAttrHide = "atribute-sku-hide";
                      }

                      const availableValuesCount = attribute.values.filter(
                        (value) => value.isDisplayable && valueAvailability[value.id]
                      ).length;
                      if(superSKUAttributes.length < 3){
                        if (availableValuesCount === 0 || attribute.values.length === 1) {
                          return null;
                        }
                      }

                      return (
                        <StyledBox key={attribute?.valueId} className={classAttrHide}>
                          <PDPRightSideCardRadioContainer className="supersku-attribute">
                            <StyledTypography variant="bodyBaseline">{attribute.title}</StyledTypography>
                            <StyledTypography variant="bodyBaseline" fontWeight={600}>
                              {currentSKUAttributes[attribute.id].value}
                            </StyledTypography>
                          </PDPRightSideCardRadioContainer>

                          {availableValuesCount < 5 ? (
                            <>
                              <StyledRadioGroup
                                aria-labelledby={"super-sku-" + attribute.identifier}
                                defaultValue={currentSKUAttributes[attribute.id].valueId}
                                name={attribute.identifier}
                                onChange={(e) => handleChange(e, attribute.id)}>
                                <PDPRightSideCardRadioContainer>
                                  {attribute.values.map((value) => {
                                    const isAvailable = valueAvailability[value.id];
                                    if (superSKUAttributes.length > 2) {
                                      return (
                                        <div
                                          className={"PDP-SSKUContainer-RadioComponent"}
                                          style={{ marginBottom: "10px" }}
                                          key={attribute.id + "|" + value.id}>
                                          <StyledRadio
                                            color="primary"
                                            className={
                                              isAvailable
                                                ? "super-sku-label-available"
                                                : "super-sku-label-unavailable"
                                            }
                                            value={value.id}
                                            control={<StyledRadio />}
                                          />
                                          <StyledTypography
                                            variant="bodyBaseline"
                                            className={
                                              isAvailable
                                                ? "super-sku-label-available"
                                                : "super-sku-label-unavailable"
                                            }>
                                            {value.value}
                                          </StyledTypography>
                                        </div>
                                      );
                                    }else if (isAvailable) {
                                      return (
                                      <div
                                      className={"PDP-SSKUContainer-RadioComponent"}
                                      style={{ marginBottom: "10px" }}
                                      key={attribute.id + "|" + value.id}>
                                      <StyledRadio
                                        color="primary"
                                        className={"super-sku-label-available"}
                                        value={value.id}
                                        control={<StyledRadio />}
                                      />
                                      <StyledTypography
                                        variant="bodyBaseline"
                                        className={"super-sku-label-available"}>
                                        {value.value}
                                      </StyledTypography>
                                    </div>
                                    )
                                    }
                                  })}
                                </PDPRightSideCardRadioContainer>
                              </StyledRadioGroup>
                            </>
                          ) : (
                            <PDPRightSideCardRadioContainer style={{ marginBottom: "10px" }}>
                              <StyledSelectHdm
                                label={null}
                                name={attribute.identifier}
                                value={currentSKUAttributes[attribute.id].valueId}
                                handleChange={(e) => handleSelectChange(e, attribute.id)}
                                id={"super-sku-" + attribute.id}
                                className="supersku-select"
                                defaultValue={currentSKUAttributes[attribute.id].valueId}
                                items={attribute.values.map((value) =>
                                  value.isDisplayable && valueAvailability[value.id] ? (
                                    <MenuItemHDM
                                      className={
                                        valueAvailability[value.id]
                                          ? "super-sku-label-available"
                                          : "super-sku-label-unavailable"
                                      }
                                      value={value.id}
                                      key={attribute.id + "|" + value.id}>
                                      {value.value}
                                    </MenuItemHDM>
                                  ) : null
                                )}
                              />
                            </PDPRightSideCardRadioContainer>
                          )}
                        </StyledBox>
                      );
                    })}
                    <button ref={buttonRef} type="submit" style={{ display: "none" }}>
                      Submit
                    </button>
                  </form>
                  {/* While espotHasContent equals null, show Skeleton Loader */}
                  {/* Renderiza el espot si esta disponible */}
                  {espotHasContent !== null ? (
                    espotHasContent === true &&
                    superSKUEspotComponent && (
                      <>
                        <StyledLinkButton
                          variant="bodySubText"
                          onClick={() => setOpenDrawer(true)}
                          to=""
                          color="secondary">
                          {" Ayuda para elegir"}
                        </StyledLinkButton>

                        <StyledSwipeableDrawerHDM
                          isOpen={openDrawer}
                          setOpenDrawer={setOpenDrawer}
                          variant={"super-sku"}
                          title={superSkuDrawerTitle}>
                          {superSKUEspotComponent}
                        </StyledSwipeableDrawerHDM>
                      </>
                    )
                  ) : (
                    <SLPDPHelpForChoose />
                  )}
                </PDPRightSideCardContainer>
              </PDPRightSideCard>
            ) : null
          }
        </>
      )}
    </>
  );
};

export default SuperSkuContainer;
