import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Axios, { Canceler } from 'axios'
import getDisplayName from "react-display-name";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { hdmStoresDataRdc } from "../../../redux/selectors/hdmData";


import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { palette } from "../../themes/color-palette";
import Collapse from "@material-ui/core/Collapse";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import { estadoCiudadesRdc } from "../../../redux/selectors/hdmData";
import jsonStateCityList from "../../../components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";
import * as customInv from '../../../_foundation/utils/inventoryAvailability';

import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledButton,
  ContainerStyledRadioCardContainer,
  ContainerStyledUncheckedRadioIcon,
  ContainerStyledSuccessRoomIcon,
  ContainerStyledSuccessIconRadio,
} from "./containers-disponibilidad";

import { StyledSelect, StyledMenuItem, StyledFormControl, StyledInputLabel, StyledGrid, StyledBox } from "../../elements";

const StyledKeyboardArrowDown = styled(({ ...props }) => <KeyboardArrowDown {...props} />)`
  ${({ theme }) => `
  font-size: 24px;
`}
`;

interface Props {
  productId: any
  handleSetTiendas: (data: any) => void
}

const BuscarEstadoCiudad = (props: Props) => {
  const { productId, handleSetTiendas } = props;

  const widgetName = getDisplayName("ProductDetailsWidget");
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);

  const estadosCiudades = useSelector(estadoCiudadesRdc);
  const storeSelector = useSelector(currentStoreSelector);
  const hdmStoresData = useSelector(hdmStoresDataRdc)

  const [expanded, setExpanded] = useState(false);
  const [allciudades, setAllciudades] = useState<any>([]);
  const [selectedEstado, setSelectedEstado] = useState("0");
  const [selectedCiudad, setSelectedCiudad] = useState<any>({ stLocId: "0", city: "" });

  const payloadBase: any = {
    storeId: mySite.storeID,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    // const ciudades = jsonStateCityList.formScheduleObject.filter(
    //   (estado) => estado.stateId === storeSelector.currentStore.stateId
    // )[0];
    // const tiendas = ciudades.stores.filter((store) => store.stLocName === storeSelector.currentStore.stLocName);
    // handleSetTiendas(tiendas);
  }, []);

  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  const handleChangeEstado = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    const valor: string = event.target.value;
    setSelectedEstado(valor);
    setSelectedCiudad({ stLocId: "0", city: "" });
    if (valor !== "0") {
      setAllciudades(estadosCiudades.filter((estado) => estado.idEstado === valor)[0].ciudades);
    } else {
      setAllciudades([]);
    }
  };

  const handleChangeCiudad = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    const valor = allciudades.filter((ciudad) => ciudad.stLocId === event.target.value)[0];

    if (valor !== undefined)
      {
        setSelectedCiudad(valor);
      }               
  };

  const handleBuscarDisponibilidad = async () => {
    if (selectedEstado !== "0" && selectedCiudad.stLocId !== "0") {
      let tiendasCiudad = hdmStoresData.filter(stores => stores.city.trim() === selectedCiudad.ciudad.trim()); 

      // Get name of current state by filtering through the stateID
      const estadoActual = estadosCiudades.filter((estado) => estado.idEstado === selectedEstado)[0].estado;
      
      // Filter by state so as not to get from cities with the same name from different states
      tiendasCiudad = tiendasCiudad.filter(stores => stores.stLocName.trim() === estadoActual.trim());

      tiendasCiudad = JSON.parse(JSON.stringify(tiendasCiudad))
      const AllUniqueFetchIds: any = []

      tiendasCiudad.map(tienda => {
        AllUniqueFetchIds.push(tienda.uniqueID)
      })

      const parameters: any = {
        ...payloadBase,
        productIds: productId,
      };

      const disponibilidad = await customInv.getAvailabilityByStores({
        ...parameters,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: AllUniqueFetchIds//["12505", "12521"]
      });

      tiendasCiudad && tiendasCiudad.map(tienda => {

        disponibilidad && disponibilidad.map(disp => {

          if(disp.physicalStoreName === tienda.stLocId){
            tienda.availableQuantity = disp.availableQuantity
          }
        })
      })

      handleSetTiendas(tiendasCiudad);

    }
  }

  return (
    <>
      <ContainerStyledBox my={2} className="buscar-tienda-containerx">
        <ContainerStyledBox mb={2}>
          <Divider style={{ background: palette.primary.main }} />
        </ContainerStyledBox>

        <ContainerStyledBox
          style={{ display: "flex", justifyContent: "center" }}
          className="title-buscar-tiendax"
          onClick={handleChangeExpanded}>
          <ContainerStyledTypography align="center" variant="bodyBaseline" className="text-buscar-disponibilidad">
            Buscar disponibilidad por estado
          </ContainerStyledTypography>
          {expanded ? (
            <KeyboardArrowUp color="primary" style={{ marginLeft: '5px', fontSize: 20, cursor: 'pointer' }} onClick={handleChangeExpanded} />
          ) : (
            <KeyboardArrowDown color="primary" style={{ marginLeft: '5px', fontSize: 20, cursor: 'pointer' }} onClick={handleChangeExpanded} />
          )}
        </ContainerStyledBox>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ContainerStyledBox mx={2} className="collapse-container">
            <StyledFormControl fullWidth variant="outlined" className="">
              <StyledInputLabel id="">Estado</StyledInputLabel>
              <StyledGrid style={{ display: "flex" }}>
                <StyledSelect
                  fullWidth
                  labelId=""
                  id=""
                  value={selectedEstado}
                  onChange={handleChangeEstado}
                  label="Estado">
                  <StyledMenuItem value={"0"}>Selecciona un estado</StyledMenuItem>
                  {estadosCiudades &&
                    estadosCiudades.map((estado) => {
                      return (
                        <StyledMenuItem key={estado.idEstado} value={estado.idEstado}>
                          {estado.estado}
                        </StyledMenuItem>
                      );
                  })}
                </StyledSelect>
                <StyledKeyboardArrowDown color="primary" style={{ position: "absolute", right: 5, top: 5, cursor: 'pointer' }} />
              </StyledGrid>
            </StyledFormControl>

            <StyledFormControl fullWidth variant="outlined" className="">
              <StyledInputLabel id="">Ciudad</StyledInputLabel>
              <StyledGrid style={{ display: "flex" }}>
                <StyledSelect
                  fullWidth
                  labelId=""
                  id=""
                  value={selectedCiudad.stLocId}
                  onChange={handleChangeCiudad}
                  label="Ciudad">
                  <StyledMenuItem value={"0"}>Selecciona una ciudad</StyledMenuItem>
                  {allciudades &&
                    allciudades.map((ciudad) => {
                      return <StyledMenuItem value={ciudad.stLocId}>{ciudad.ciudad}</StyledMenuItem>;
                    })}
                </StyledSelect>
                <StyledKeyboardArrowDown color="primary" style={{ position: "absolute", right: 5, top: 5 }} />
              </StyledGrid>
            </StyledFormControl>
            
            <StyledBox mt={1}>
              <ContainerStyledButton onClick={handleBuscarDisponibilidad}>Buscar</ContainerStyledButton>
            </StyledBox>
          </ContainerStyledBox>
        </Collapse>
      </ContainerStyledBox>
    </>
  );
};

export default BuscarEstadoCiudad;
