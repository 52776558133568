import { Dispatch, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Divider } from "@material-ui/core";
import styled from "styled-components";
import { withStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledLinkTextSecondary,
  StyledSwipeableDrawer,
  StyledTooltip,
  StyledTypography,
} from "../../elements";
import Box from '@material-ui/core/Box'
import { ColorsPaletteDefault } from "./ColorsPaletteDefault";
import { lineaGamaColorsRdc, hdmDrawerColorDataRdc, hdmColorsRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";

import dbColorsHDM from "../../../pinturashdm.json";
import { palette } from "../../themes";
import { StyledChipCheckCircleIcon } from "../svg-icons";
import Sticky from "react-sticky-el";
import { AllowScroll, DisableScroll } from "../../functions/utils";
import { ColorSelected } from "./ColorSelected";
import { DrawerTitle } from "../drawer-title/DrawerTitle";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { StyledWrapper } from "../../../components/StyledUI";
import { useSite } from "../../../_foundation/hooks/useSite";
import React from "react";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    //   color: 'rgba(0, 0, 0, 0.87)',
    width: 85,
    //   fontSize: theme.typography.pxToRem(12),
    border: theme.palette.common.white,
    bottom: "35px",
    padding: "5px",
    left: "3px",
  },
  arrow: {
    "&:before": {
      border: `1px solid ${theme.palette.common.white}`,
    },
    color: theme.palette.common.white,
  },
}))(Tooltip);

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <Box {...props} ref={ref} />;
});

const ContainerChipColor = styled(ComponentWrapper)`
${({ theme }) => `

&.styled--chip-color {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 75px;
  width: 23%;
  margin: 1%;
  cursor: pointer;
}

`}
`;

export const StyledSwipeableDrawersBuyByolor = styled((props: any) => <StyledSwipeableDrawer {...props} />)`
  ${({ theme }) => `

  .MuiDrawer-paper{
    //position: relative;
    width: 90% !important;
    padding: 0!important;
    overflow-x: hidden;
    max-width: 360px!important;
    min-width: 288px!important;
  }
  .MuiDrawer-root{
    width: 90%;
  }

`}
`;

// const ContainerChipColor2 = styled((props: any) => <StyledBox {...props} />)`
//   ${({ theme }) => `


//   &.styled--chip-color {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 3px;
//     height: 75px;
//     width: 23%;
//     margin: 1%;
//     cursor: pointer;
// }

// `}
// `;

const ContainerBuyByColorDrawer = styled(({ ...props }) => <StyledBox component="div" {...props} />)`
  ${({ theme }) => `

height: 100%;
margin: 0px;

// .styled--chip-color {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 3px;
//   height: 75px;
//   width: 23%;
//   margin: 1%;
//   cursor: pointer;

.styled--wraper-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

.styled--overflow-content {
    flex: 1;
    overflow: auto;
  }


.styled--link-palette {
    margin-left: 5px;
}

.styled--gamacolor-container {
    padding-bottom: 10px;
    padding-left: 5px;
}

.styled--divider-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.styled--number-gamacolors {
    color: ${theme.palette.gray["300"]};
    margin-bottom: 5px;
    margin-left: 10px;
}

.styled--chipcolor-container {
    padding-left: 5px;
    padding-right: 5px;
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap; 
    align-content: space-around;
    align-items: flex-start; 
}



.styled--sticky-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 140px;
    padding: 10px;
}

.styled--sticky-top {
    position: absolute;
    top: 0;
    width: 100%;
}

.styled--wraper-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.styled--overflow-content {
    flex: 1;
    overflow: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.styled--overflow-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    
}

.styled--selectedcolor-marginbottom {
    margin-bottom: 140px;
}

.styled--unselectedcolor-marginbottom {
    margin-bottom: 70px;
}

`}
`;

const ContainerStyledTooltip = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    .styled--tooltip-namecolor {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

`}
`;

// const MyComponent = React.forwardRef(function MyComponent(props, ref) {
//     //  Spread the props to the underlying DOM element.
//     return <div {...props} ref={ref}>Bin</div>
// });


  const StyledColorsWraper = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${(props) => {
    
    const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);

    let colorPinturaClasses = "";
    if(hdmDrawerColorData && hdmDrawerColorData?.gamaColors){
      hdmDrawerColorData?.gamaColors.forEach((color) => {
        colorPinturaClasses += `.styled--chipbackground-color${color?.codigo} {
          background-color: rgba(${color?.R},${ color?.G}, ${color?.B}, 1); 
        }`
      });
    }
    return `${colorPinturaClasses}`
    }}
  `;

export const BuyByColorDrawer = (props) => {
  const {
    // isOpen,
    handleCloseBuyByColor,
    handleOpenBuyByColor,
    gamaSelected,
    handleChangeBaseColor,
    superSKUAttributes,
    // handleSetColorCodeSelected,
    // colorCodeSelected,
    gamaColors,
  } = props;
  const { t } = useTranslation();
  //   const [gamaColors, setGamaColors] = useState<any>(null);
  const [colorCodeSelected, setColorCodeSelected] = useState<any>(null);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;

  useEffect(() => {
    if (gamaSelected !== "") {
      const selectedGamaColors = hdmLineaGamaColor.filter(
        (lineaGama) => lineaGama.linea === "Premium Plus" && lineaGama.gama === gamaSelected
      )[0];
      //   setGamaColors(selectedGamaColors?.colores);
    }
  }, [gamaSelected]);

  useEffect(() => {
    if(!window.location.pathname.includes("/p/")){
      if (isOpen) {
        DisableScroll();
      } else {
        AllowScroll();
      }
    }

    return () => {
      //do nothing
    };
  }, [isOpen]);

  useEffect(() => {
    if (hdmDrawerColorData) {
      setIsOpen(hdmDrawerColorData.isOpen);
    }
  }, [hdmDrawerColorData]);

  const handleGoPalettes = () => {
    const anchor = document.getElementById("color-gamma-palettes") as HTMLElement | null;
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSetColorCodeSelected = (color) => {
    setColorCodeSelected(color);
  };

  const handleConfirmColorSelected = () => {
    // props.handleSetColorCodeSelected(colorCodeSelected);

    let changeBaseColor;
    if (hdmDrawerColorData?.base !== colorCodeSelected?.base) {
      const baseColor =
        hdmDrawerColorData?.superSKUAttributes &&
        hdmDrawerColorData?.superSKUAttributes.find(
          (attr) => attr?.identifier.includes("BASE_") && attr?.usage === "Defining"
        );
      if (!baseColor) return;
      const base = baseColor.values.find((val) => val.identifier === colorCodeSelected?.base);
      const color = colorCodeSelected.codigo !== "N/A" ? colorCodeSelected.codigo : null;
      changeBaseColor = { name: baseColor?.identifier, value: base?.id, id: baseColor?.id, color: color };
    }

    const query = {
      url: "",
      data: {
        ...hdmDrawerColorData,
        isOpen: false,
        gamaColors: null,
        colorCodeSelected: changeBaseColor ? null : colorCodeSelected,
        changeBaseColor: changeBaseColor,
        colorCode: colorCodeSelected?.codigo,
      },
      option: "HDM_DRAWER_COLOR_DATA",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const handleCloseDrawer = () => {
    const query = {
      url: "",
      data: {
        ...hdmDrawerColorData,
        isOpen: false,
        gamaColors: null,
      },
      option: "HDM_DRAWER_COLOR_DATA",
      fetch: false,
    };

    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    setColorCodeSelected(null);
  };


  return (
    <StyledSwipeableDrawersBuyByolor open={isOpen} onClose={handleCloseDrawer} anchor="right" disableEnforceFocus>
      <ContainerBuyByColorDrawer>

        <StyledBox className="styled--wraper-container">
          <DrawerTitle
            title={t("productDetail.BuyByColor.labels.drawertitle")}
            handleCloseDrawer={handleCloseDrawer}
            closeButtonAlign="right"
          />

          <StyledBox className="styled--gamacolor-container">
            <StyledTypography variant="bodyCaption" className="color-gray300" component="span">
              {t("productDetail.BuyByColor.labels.rangeColor")}
            </StyledTypography>
            <StyledLinkTextSecondary
              variant="bodySubtext"
              color="secondary"
              component="span"
              className="styled--link-palette"
              onClick={handleGoPalettes}>
              {hdmDrawerColorData?.gama}
            </StyledLinkTextSecondary>
          </StyledBox>

          {isB2B ?
          <EspotHdm espotName="PRO_PDP_Drawer_CompraPorColor" />
          :
          <EspotHdm espotName="PDP_Drawer_CompraPorColor" />
          }

          <StyledBox
            className={`styled--overflow-content ${
              colorCodeSelected?.codigo ? "styled--selectedcolor-marginbottom" : "styled--unselectedcolor-marginbottom"
            }`}>
            <ColorsPaletteDefault
              handleOpenBuyByColor={handleOpenBuyByColor}
              gamaSelected={gamaSelected}
              isDrawer={true}
            />

            <Divider className="styled--divider-row" />

            <StyledTypography variant="bodyCaption" className="styled--number-gamacolors">
              {hdmDrawerColorData?.gamaColors?.length} {t("productDetail.BuyByColor.labels.colors")}
            </StyledTypography>
            <StyledColorsWraper>
              <StyledBox className="styled--chipcolor-container">
                {hdmDrawerColorData?.gamaColors &&
                  hdmDrawerColorData.gamaColors.map((color) => {
                    let isSelected = false;
                    if (colorCodeSelected?.codigo && color.codigo === colorCodeSelected.codigo) {
                      isSelected = true;
                    }
                    return (
                      <HtmlTooltip
                        title={
                          <ContainerStyledTooltip>
                            <StyledGrid>
                              <StyledTypography align="center" className="styled--tooltip-namecolor ">
                                {color.nombre}
                              </StyledTypography>
                            </StyledGrid>
                            <StyledTypography variant="bodyCaption" component="span">
                              {color?.codigo === "N/A" ? "" : "Código"}
                            </StyledTypography>
                            <StyledTypography variant="bodyCaption" fontWeight={600} component="span">
                              {" "}
                              {color?.codigo === "N/A" ? "" : color?.codigo}
                            </StyledTypography>
                          </ContainerStyledTooltip>
                        }
                        arrow
                        TransitionComponent={Fade}
                        key={color.codigo}>
                        <ContainerChipColor
                          className={`styled--chip-color styled--chipbackground-color${color?.codigo}`}
                          onClick={() => handleSetColorCodeSelected(color)}>
                          {isSelected ? <StyledChipCheckCircleIcon color={palette.primary.main} width="35px" /> : null}
                        </ContainerChipColor>
                        {/* <StyledBox
                        className="styled--chip-color styled--chipbackground-color"
                        r={color?.R}
                        g={color?.G}
                        b={color?.B}
                        onClick={() => handleSetColorCodeSelected(color)}>
                        {isSelected ? <StyledChipCheckCircleIcon color={palette.primary.main} width="35px" /> : null}
                      </StyledBox> */}
                      </HtmlTooltip>
                    );
                  })}
              </StyledBox>

            </StyledColorsWraper>
          </StyledBox>

          <StyledBox className="styled--sticky-bottom">
            {colorCodeSelected?.codigo ? (
              <ColorSelected
                handleOpenBuyByColor={null}
                colorCodeSelected={colorCodeSelected}
                color={{ R: colorCodeSelected.R, G: colorCodeSelected.G, B: colorCodeSelected.B }}
              />
            ) : null}
            <StyledButton
              id="selectColorButton"
              variant="outlined"
              color="primary"
              onClick={colorCodeSelected?.codigo ? handleConfirmColorSelected : null}
              disabled={colorCodeSelected?.codigo ? false : true}>
              {t("productDetail.BuyByColor.Buttons.selectColor")}
            </StyledButton>
          </StyledBox>
        </StyledBox>
      </ContainerBuyByColorDrawer>
    </StyledSwipeableDrawersBuyByolor>
  );
};
