import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm/elements";
import BazaarVoice from "../shared/BazaarVoice";
import { useNavigate } from "react-router";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Icons
import ShareIcon from "@material-ui/icons/Share";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ButtonHDM from "../styled-cart-drawer/cart-item/ButtonHDM";
import { StyledWishListButton } from "../styled-wishList-button";
import { StyledShareButton } from "../styled-share-button";
import EspotHdm from "../shared/EspotHdm";
import { SEARCH } from "../../../constants/routes";
import { useSite } from "../../../_foundation/hooks/useSite";
import { CHROME, OFF_SET_HEIGHT_80 } from "../../../constants/common";
import withSkeletonLoader from "../skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../skeleton-loader/components";
//import { tabletBreakpoint } from "../../../hdm/functions/utils";

interface ISummaryCardProps {
  brand: string;
  productName: string | number;
  partNumber: string | number;
  model: string;
  customBrandExclude: any;
  detectedBrowser: any;
  showCarritoHeader: any;
  start: any;
  end: any;
  to: any;
}

const StyledSummaryCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme, start, end, to, showCarritoHeader }) => `
        @media (max-width: 1024px){
            margin: ${theme.spacing(2)}px ${theme.spacing(2)}px;
        }

        &.summary-card-mobile {
          max-width: 100%;
          min-height: 133.40px;        
          margin: 0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;
          background-color: #ffffff;
          box-shadow: #cccccc 0 3px 6px;
          padding: ${theme.spacing(2)}px;
        }

        &.summary-card-desktop {
          max-width: 100%;
          min-height: 133.40px;        
          margin: 0 0 ${theme.spacing(2)}px 0;
          background-color: #ffffff;
          box-shadow: #cccccc 0 3px 6px;
          padding: ${theme.spacing(2)}px;

          ${showCarritoHeader ?
          `margin-top: ${to}px;` : "margin-top: 0px;"
          }
        }

        &.summary-card-desktop-chrome {
          z-index: 99999 !mportant;
          max-width: 100%;
          min-height: 133.40px;        
          margin: 0 0 ${theme.spacing(2)}px 0;
          background-color: #ffffff;
          box-shadow: #cccccc 0 3px 6px;
          padding: ${theme.spacing(2)}px;

          animation: summary-card linear both;
          animation-timeline: scroll(root block);
          animation-range: ${start}px ${end}px;
        }

        @keyframes summary-card {
          z-index: 99999 !mportant;
          from {
            transform: translatey(0px);
            
          }
          to {
            transform: translatey(${to}px);
            margin-top: 10px;
          }

          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translateY(-50px);
          }
          100% {
            margin-top: 10px;
            transform: translatey(${to}px);
          }
        }

        .product-caption-info{
            color: ${theme.palette.gray["200"]}; 
        }

        span.MuiButton-label {
            justify-content: flex-start; 
        }
        
        .text-button {
            height: auto;
        }

        
        .espot-container{
            display: flex; 
            word-break: break-all;
        }

        .product-actions-container{
            display: flex;
        }

        .svg-container{
            width: fit-content;
            padding: ${theme.spacing(1)}px;
            box-shadow: 0 3px 6px ${theme.palette.gray["200"]};
            border-radius: 50%;
            color: ${theme.palette.primary.main};
            display: flex;
            margin-right: 15px;
            cursor: pointer;

        & svg{
            font-size: 25px;
        }
        }

        .bazaarvoice-component-container{
            display: flex;
            align-items: end;
            justify-content: flex-end;
        }

        .bv_main_container{
            display: flex !important;
            justify-content: flex-end;
        }

        .m-right {
          margin-right: ${theme.spacing(3)}px;
        }
    `}
`;

const ProductSummaryCardHDM: FC<ISummaryCardProps> = ({ brand, productName, partNumber, model, customBrandExclude, showCarritoHeader, detectedBrowser, start, end, to }) => {
  const navigate = useNavigate();
  function replaceSplChar(brand) {
    brand = brand.toLowerCase();
    brand = brand.replace(/[‘';.,_¿?=#%½|¡!$()[\]{}*“<>ª,º]/gi, "");
    brand = brand.replaceAll('@', '-');
    if(brand.includes("&")){
      brand = brand.replaceAll('&', 'and');
    }
    if(brand.includes("+")){
      brand = brand.replaceAll('+', 'and');
    }
    const com_acento = 'áéíóúãõñâêîôûäëïöüç';
    const sem_acento = 'aeiouaonaeiouaeiouc';
    brand = brand.split('')
      .map(char => {
        const charIdx = com_acento.indexOf(char)
        if (charIdx !== -1) {
          return sem_acento[charIdx]
        }
        return char
      })
      .join('');
      brand = brand.replaceAll(' ', '-');
      brand = brand.replace(/-{2,}/g, "-");
    return brand;
  }
  const handleOnClick = () => {
    const brandReplace = String(brand).toLowerCase().replace(/ /g, "_");
    sessionStorage.setItem(replaceSplChar(brand), brand);
    sessionStorage.setItem('marca_'+replaceSplChar(brand), encodeURIComponent(brandReplace));

    navigate(`${SEARCH}/${replaceSplChar(brand)}?marca=${encodeURIComponent(replaceSplChar(brand))}`, { replace: true });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const { mySite } = useSite();
  const isB2B:boolean = mySite?.isB2B;
  const espotPDPSummaryMiddle = isB2B ? "PRO_PDP-Summary_MiddleSection" : "PDP-Summary_MiddleSection"

  let brandIsExcluded = false
  customBrandExclude && customBrandExclude.map(brandExc => {
    if(brandExc.trim().toLowerCase() === brand.trim().toLowerCase()){
      brandIsExcluded = true
    }
  })


  const memoizedEspot = useMemo(()=>{
    return <EspotHdm espotName={espotPDPSummaryMiddle} />
  },[productName])

  

  const SummaryCard = ()=>useMemo(()=>{
    return (
      <>
        {!isMobile && (
          <>
            <StyledSummaryCardContainer className="summary-card-desktop" start={start} end={end}>
              <StyledGrid container spacing={1}>
                {/* Brand */}
                <StyledGrid className="product-brand" item xs={12} md={12}>
                  {!brandIsExcluded ? (
                    <ButtonHDM
                      variant="default"
                      text={<StyledTypography weight="regular" variant="textSegment">{brand}</StyledTypography>}
                      onClick={handleOnClick}
                    />
                  ) : (
                    <StyledTypography variant="textSegment">{brand}</StyledTypography>
                  )}
                </StyledGrid>
                {/* Product name */}
                <StyledGrid item xs={12} md={12}>
                  <StyledTypography className="product-name" variant="headingTitle" component="h1" fontWeight={300}>
                    {productName}
                  </StyledTypography>
                </StyledGrid>
  
                {/* Product caption */}
                {model && (
                  <StyledGrid item className="m-right">
                    <StyledTypography
                      variant="bodySubText"
                      className="product-caption-info product-model">{`Modelo ${model}`}</StyledTypography>
                  </StyledGrid>
                )}
                <StyledGrid item className="m-right">
                  <StyledTypography
                    variant="bodySubText"
                    className="product-caption-info product-sku">{`SKU ${partNumber}`}</StyledTypography>
                </StyledGrid>
                <StyledGrid item>
                  <StyledBox className="product-caption-info espot-container">
                    {memoizedEspot}
                  </StyledBox>
                </StyledGrid>
  
                {/* Product actions */}
                <StyledGrid container>
                  <StyledGrid item xs={isMobile ? 6 : "auto"} className="product-actions-container">
                    <StyledWishListButton cid="addToWishlistButton" partNumber={partNumber} isWishProduct={false} />
                    <StyledShareButton cid="shareButton" partNumber={partNumber}/>
                  </StyledGrid>
                  <StyledGrid item xs={isMobile ? 6 : "auto"} className="bazaarvoice-component-container">
                    <BazaarVoice productId={partNumber} />
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledSummaryCardContainer>
          </>
        )}
  
        {isMobile && (
          <>
            <StyledSummaryCardContainer className="summary-card-mobile">
              <StyledGrid container spacing={1}>
                {/* Brand */}
                <StyledGrid item xs={12} md={12}>
                  <ButtonHDM
                    variant="default"
                    text={<StyledTypography weight="regular" className="product-brand" variant="textSegment">{brand}</StyledTypography>}
                    onClick={handleOnClick}
                  />
                </StyledGrid>
                {/* Product name */}
                <StyledGrid item xs={12} md={12}>
                  <StyledTypography className="product-name" variant="headingTitle" component="h1" weight="light">
                    {productName}
                  </StyledTypography>
                </StyledGrid>
  
                {/* Product caption */}
                {model && (
                  <StyledGrid item className="m-right">
                    <StyledTypography
                      variant="bodySubText"
                      className="product-caption-info">{`Modelo ${model}`}</StyledTypography>
                  </StyledGrid>
                )}
                <StyledGrid item className="m-right">
                  <StyledTypography
                    variant="bodySubText"
                    className="product-caption-info product-sku">{`SKU ${partNumber}`}</StyledTypography>
                </StyledGrid>
                <StyledGrid item>
                  <StyledTypography variant="bodySubText" className="product-caption-info espot-container">
                    {memoizedEspot}
                  </StyledTypography>
                </StyledGrid>
  
                {/* Product actions */}
                <StyledGrid container>
                  <StyledGrid item xs={isMobile ? 6 : "auto"} className="product-actions-container">
                    <StyledWishListButton cid="addToWishlistButton" partNumber={partNumber} isWishProduct={false} />
                    <StyledShareButton cid="shareButton" partNumber={partNumber} />
                  </StyledGrid>
                  <StyledGrid item xs={isMobile ? 6 : "auto"} className="bazaarvoice-component-container">
                    <BazaarVoice productId={partNumber} />
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledSummaryCardContainer>
          </>
        )}
      </>
    );
  },[productName])

  const memoizedProductSummary = useMemo(()=>{
    return withSkeletonLoader(<SummaryCard/>, productName !== "", loaders.pdp.summaryCard)
  }, [productName])

  return memoizedProductSummary

  
};

export default ProductSummaryCardHDM;
