/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const BreadcrumbWrapper = (props: any) => <Breadcrumbs {...props} separator={<ArrowRightIcon fontSize="small" />} />;

export const StyledBreadcrumbs = styled(BreadcrumbWrapper)`
  ${({ theme }) => `
    margin: ${theme.spacing(2)}px 0;



    ${theme.breakpoints.down("md")} {  
          margin: 0 ${theme.spacing(2)}px;
          margin: ${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px;   
          flex-wrap: nowrap;
          overflow: auto;
          
        }
  
    & .MuiBreadcrumbs-ol{
        ${theme.breakpoints.down("md")} {     
          flex-wrap: nowrap;
          padding-bottom: ${theme.spacing(1)}px;
        }
    }

    & .MuiBreadcrumbs-separator{
      margin-left: ${theme.spacing(1)}px;
      margin-right: ${theme.spacing(1)}px;
    }
  `}
`;
