import styled from "styled-components";
import { StyledGrid, StyledBox, StyledStepper } from "../../../../hdm/elements";
import { palette } from "../../../../hdm/themes/color-palette";
import { tabletBreakpoint } from "../../../../hdm/functions/utils";

export const ContainerOrderHistory = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    .order-card-container {
        width: calc((100% - 20px) / 3);
        ${theme.breakpoints.down("sm")} {
            width: calc(100%);
        }
    }

    .styledgrid--misordenes-container {
        padding-left: 20px;
        ${theme.breakpoints.down("sm")} {
            padding-left: 0px;
        }
    }

    .order-id {
        cursor: pointer;
        color: ${palette.secondary.main};
    }

    .myorder-container {
        min-width: 90px;
        margin-right: 15px;
    }

    .order-status {
        &.status-M{
            color: ${palette.gray["500"]};
        }

        &.status-R {
            color: ${palette.gray["500"]};
        }

        &.status-S {
            color: ${palette.highlight["main"]};
        }
        
        &.status-SE {
            color: ${palette.highlight["main"]};
        }

        &.status-ER {
            color: ${palette.highlight["main"]};
        }

        &.status-T {
            color: ${palette.highlight["main"]};
        }

        &.status-A {
            color: ${palette.error["main"]};
        }

        &.status-D {
            color: ${palette.gray["300"]};
        }

        &.status-X {
            color: ${palette.gray["300"]};
        }
        &.status-PX {
            color: ${palette.gray["300"]};
        }
        &.status-PXC {
            color: ${palette.gray["300"]};
        }
        &.status-PXI {
            color: ${palette.gray["300"]};
        }
        &.status-XC {
            color: ${palette.gray["300"]};
        }
        &.status-XI {
            color: ${palette.gray["300"]};
        }
    }

    .reordenar {
        cursor: pointer;
        color: ${palette.secondary.main};
    }
    
    .container-filtro-status {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-right: 5px;
        border: 1px solid ${palette.gray["100"]};
        margin-top: 5px;
        background-color: ${palette.background.paper};
    }

    .filtros-aplicados {
        width: 150px;
    }

    .detalle-orden-lado-izquierdo {
        width: calc( ((100% / 3) * 2) - 10px );
        margin-right: 10px;
        ${theme.breakpoints.down("sm")} {
            width: 100%;
            margin-right: 0px;
        }
    }

    .detalle-orden-lado-derecho {
        width: calc(100% / 3);
        ${theme.breakpoints.down("sm")} {
            width: 100%;
        }
        .styledCartRowPaint-styledGrid-container{
            padding-left: 0px;
        }
    }

    .product-title {
        max-height: 56px;
        overflow: hidden;
    }

    .sticky--approveOrder-link {
        display: block;
        width: 100%;
    }

  `}
`;
