import { Dispatch, useEffect, useMemo } from "react";
import { withUseJsonESpots } from "../../hdm/widgets/json-espot/json-espots";
import StyledFooterHDM from "../custom-components/styled-footer-hdm/StyledFooterHDM";
import { useSite } from "../../_foundation/hooks/useSite";
import { forUserIdSelector, loginStatusSelector } from "../../redux/selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_WISHLIST_ACTION, WISHLIST_RESET_ACTION } from "../../redux/actions/wish-list";
import { IFRAME_RESIZER } from "../../_foundation/constants/csr";
import { useCSRForUser } from "../../_foundation/hooks/useCSRForUser";
import * as ROUTES from "../../constants/routes";
import { useLocation } from "react-router";



const FooterWithData = () => {

  const { mySite, storeDisplayName } = useSite();
  const loginStatus = useSelector(loginStatusSelector);
  const forUserId = useSelector(forUserIdSelector);
  const { receiveParentMessage } = useCSRForUser();
  const dispatch = useDispatch<Dispatch<any>>();
  const location = useLocation();

  const isB2B = mySite?.isB2B;

   useEffect(() => {
    if (forUserId) {
      window[IFRAME_RESIZER] = {
        onMessage: receiveParentMessage,
      };
    } else {
      window[IFRAME_RESIZER] = undefined;
    }
  }, [forUserId]);

  useEffect(() => {
    dispatch(WISHLIST_RESET_ACTION());
    dispatch(GET_USER_WISHLIST_ACTION({}));
  }, [loginStatus]);


    const StyledFooterHDMWithData = useMemo(
        () =>
          withUseJsonESpots(
            StyledFooterHDM,
            !isB2B
              ? [
                  "MainFooter_SuperiorSection",
                  "MainFooter_InferiorSection",
                  "MainFooter_Navigation1",
                  "MainFooter_Navigation2",
                  "MainFooter_Navigation3",
                  "MainFooter_Navigation4",
                ]
              : [
                  `${loginStatus ? "ProMainFooter_SuperiorSection" : "ProMainFooter_SuperiorSectionGuest"}`,
                  "ProMainFooter_SuperiorSection",
                  "ProMainFooter_InferiorSection",
                  "ProMainFooter_Navigation1",
                  "ProMainFooter_Navigation2",
                  "ProMainFooter_Navigation3",
                  "ProMainFooter_Navigation4",
                ]
          ),
        [mySite]
      );

    return (
      <>
        {location.pathname !== ROUTES.PRO_LEAD_FORM && location.pathname !== "/expired-link" && location.pathname !== ROUTES.PRO_AR_FEEDBACK ? 
          <StyledFooterHDMWithData />
        : null}
      </>
    )

}

export default FooterWithData