//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/hdmData";

const FETCH_HDM_DATA_REQUESTED = createAction<any, string>(ACTIONTYPES.FETCH_HDM_DATA_REQUESTED);
const FETCH_HDM_DATA_SUCCESS = createAction<any, string>(ACTIONTYPES.FETCH_HDM_DATA_SUCCESS);
const FETCH_HDM_DATA_ERROR = createAction<any, string>(ACTIONTYPES.FETCH_HDM_DATA_ERROR);
const SET_HDM_INSTALLATION_DATA = createAction<any, string>(ACTIONTYPES.SET_HDM_INSTALLATION_DATA);

export { FETCH_HDM_DATA_REQUESTED, FETCH_HDM_DATA_SUCCESS, FETCH_HDM_DATA_ERROR, SET_HDM_INSTALLATION_DATA };
