/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

const StyledDialog = styled((props) => <Dialog {...props} />)`
  ${({ theme, isHomologatedPopUp }) => `
  padding : ${theme.spacing(2)}px !important;
  
  .MuiPaper-root.MuiDialog-paper {
    max-width: ${isHomologatedPopUp ? 400 + "px" : ""};
}

  .styledDialog--typography-secondary{
    color: ${theme.palette.text.secondary};
    margin-bottom: ${theme.spacing(1)}px;
    display: block;
  }

  .styledDialog--content-subContent{
    display: block;
    color: ${theme.palette.text.secondary};
    margin-left: ${theme.spacing(3)}px;
    margin-top: ${theme.spacing(2)}px;
  }

  .styledDialog--link-terms{
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  &.dialog-choose-store-container {
    z-index: 2147483648 !important;

  }
  
  `}
`;

const StyledDialogVideo = styled((props) => <Dialog {...props} />)`
  ${({ theme }) => `

  &.dialog-video-container {

    .MuiPaper-root.MuiDialog-paper {
      width: 90%;
      height: 100%;
      max-width: 1024px;
      min-width: 288px;
      max-height: 90%;
      min-height: 100px;
      max-height: 600px;
    }

  }

  .container-titulo-close {
    display: flex;
    height: 40px;    
    align-items: center;
  }

  .titulo-close {    
    height: 40px;   
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

`}
`;

export { StyledDialog, StyledDialogVideo };
