export const OPEN_CART_DRAWER = "OPEN_CART_DRAWER";
export const CLOSE_CART_DRAWER = "CLOSE_CART_DRAWER";

export const OPEN_SIGN_IN_POPUP = "OPEN_SIGN_IN_POPUP";
export const CLOSE_SIGN_IN_POPUP = "CLOSE_SIGN_IN_POPUP";

export const OPEN_REGISTER_POPUP = "OPEN_REGISTER_POPUP";
export const CLOSE_REGISTER_POPUP = "CLOSE_REGISTER_POPUP";

export const OPEN_LOGIN_DRAWER = "OPEN_LOGIN_DRAWER";
export const CLOSE_LOGIN_DRAWER = "CLOSE_LOGIN_DRAWER";