// import { Hidden } from "@material-ui/core";
import React, { FC, Dispatch, useState, useEffect, useRef, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import { StyledBox, StyledButton, StyledGrid, StyledTypography } from "../../../hdm/elements";
import ButtonHDM from "../styled-cart-drawer/cart-item/ButtonHDM";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { installationPartNumber, pdpQuantityRdc } from "../../../redux/selectors/hdmData";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Sticky from "react-sticky-el";

//Icon
import InfoIcon from "@material-ui/icons/Info";
import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_CART_DRAWER_ACTION,
  OPEN_CART_DRAWER_ACTION,
  OPEN_SIGN_IN_POPUP_ACTION,
} from "../../../redux/actions/drawer";
import { hdmDrawerColorDataRdc, onlineStoreRdc } from "../../../redux/selectors/hdmData";

// Add to cart imports
import { useMemo } from "react";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { useSite } from "../../../_foundation/hooks/useSite";
import * as orderActions from "../../../redux/actions/order";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import InputQuantityHDM from "../shared/input-quantity-hdm/InputQuantityHDM";
import { Box, Grid } from "@material-ui/core";
import { tabletBreakpoint } from "../../../hdm/functions/utils";
import ProductQuantity from "../styled-cantidad-producto/ProductQuantity";
import { numberWithCommas } from "../../../hdm/functions";
import { getCookieValue, setCookieCAPI } from "../conversion-api/cookieHandler";
import { triggerAddToCartDL } from "../Datalayer-Utils/dataLayer-func";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
import withSkeletonLoader from "../skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../skeleton-loader/components";
import { OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { LoaderActions } from "../skeleton-loader/actions/sl-actions";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { triggerConversionsPinterestAddToCart } from "../conversion-api/pinterest/pinterest-conversion-utils";
import { cartSelector } from "../../../redux/selectors/order";

const StyledShopperActionsContainer = styled(({ ...props }) => <Grid {...props} />)`
  ${({ theme, fixed }) => `
        // min-height: 108px;
        min-height: 45px;
        width: 100%;
        background-color: #ffffff;
        box-shadow: #cccccc 0 3px 6px;
        padding: ${theme.spacing(2)}px;
        margin: 0 0 10px 0;
        z-index: 5;

        @media (min-width: 1025px) {
          ${
            fixed &&
            css`
              // // position: fixed;
              // // top: 0;
              // // z-index: 5;
              // // display: flex;
              // // right: 0%;
            `
          }
        }

        @media (max-width: 1024px) {
          width: calc(100% - 20px);
          margin: 0 ${theme.spacing(2)}px;
          position: fixed;
          bottom: 0;
          z-index: 10;
          box-sizing: border-box;
        }

        &.hideFooter{
          @media (max-width: 1024px) {
            display: none; 
          }
        }

        .prompt-alert{
            display: flex;
            background-color: ${theme.palette.alert.light};
            color: ${theme.palette.alert.main};
            border: solid 1px ${theme.palette.alert.main};
            margin-bottom: ${theme.spacing(1)}px;
            & svg{
                font-size: 10px;
                margin-top: 2px;
                margin-right: 3px;
                color: ${theme.palette.alert.main};
            }

            & b{
                font-size: 600;
            }
        }


        .price-quantity{
            & p{
                font-weight: 600;
            }
        }
        
        .minimun-quantity{
            display: flex;
            justify-content: flex-start;
            padding-left: 0;

            @media (max-width: 1024px) {
              justify-content: flex-end;
            }
            
            & p{
                font-weight: 400;
                color: ${theme.palette.gray["300"]};
            }
        }

        .addtocar-container{
            padding-right: 0;
        }

        .input-quantity-container{
            padding-left: 0;
        }

        & input{
            height: 42px;
            padding-left: 0;
        }

        .shopnow-button{
            margin-bottom: 0;
        }

    `}
`;

const ContainerStyledStickyShopper = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

  &.styled--styckyshopper-container {
    display: flex;
    align-items: end;
    justify-content: end;
    width: 100%;
    margin: ${theme.spacing(2)}px 0;
  }

  .styled--styckyshopper-price {
    margin-bottom: ${theme.spacing(2)}px;
  }

  .styled--shopperbutton-container {
    width: calc(50% - 10px); 
    margin-left: 10px;
    margin-right: 10px;
    & .productBoxVerDetalle {
      margin-bottom: ${theme.spacing(0)}px;
    }
  }

  .stikcy-shoper-button {
    display: flex;
    justify-content: bottom;
    align-items: bottom;
  }

  .prompt-alert{
    display: flex;
    background-color: ${theme.palette.alert.light};
    color: ${theme.palette.alert.main};
    border: solid 1px ${theme.palette.alert.main};
    margin-bottom: ${theme.spacing(1)}px;
    padding: ${theme.spacing(1)}px;

    & svg{
        font-size: 10px;
        margin-top: 4px;
        margin-right: 3px;
        color: ${theme.palette.alert.main};
    }

    & b{
        font-size: 600;
    }
  }

  .shopnow-button,
  .contenedor-cantidad button,
  .contenedor-cantidad .textfield-input-cantidad{
    margin-bottom: 0;
  }
`}
`;

interface Props {
  productPrice: number;
  // updateProductQuantity: (n: number) => void;
  currentSelection: any;
  marketInventory: any;
  kitAvailabilityData?: any;
  catentryData: any;
  partNumber: string;
  inventoryInMarket?: number;
  nominalQuantity: string;
  currentStoreId: any;
  promociones: any;
  quantityMeasure: any;
  showheader?: any;
  showCarritoHeader?: any;
  total?: any;
  loginStatus: boolean;
  addComboToCart: (quantity: string) => void;
  isB2B: boolean;
  isNotZero?: boolean;
  isMainShoperAction?: boolean;
  isPdp: boolean;
}

const ShopperActionsPDPHDM = (props: Props) => {
  const {
    productPrice,
    currentSelection,
    marketInventory,
    kitAvailabilityData,
    catentryData,
    // updateProductQuantity,
    partNumber,
    inventoryInMarket,
    nominalQuantity,
    currentStoreId,
    promociones,
    quantityMeasure,
    showheader,
    showCarritoHeader,
    total,
    loginStatus,
    addComboToCart,
    isB2B,
    isNotZero,
    isMainShoperAction,
    isPdp,
  } = props;

  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const [colorCode, setColorCode] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  // Add to cart data
  const [productQuantity, setproductQuantity] = useState("1");
  const [unitPrice, setUnitPrice] = useState<any>(null);
  const [totalPriceQuantity, setTotalPriceQuantity] = useState<any>("0");
  const [showMsg, setShowMsg] = useState<boolean>(false);
  // const [markInventory, setMarkInventory] = useState<any>(0);
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const contract = useSelector(currentContractIdSelector);
  const quantityRdc = useSelector(pdpQuantityRdc);
  // const installationData:any = useSelector<any>(state => state.hdmDataReducer);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const widgetName = getDisplayName("ProductDetailsWidget");
  const cart = useSelector(cartSelector);
  const installationPartNumberData = useSelector(installationPartNumber);
  const storeConfData = useSelector(onlineStoreRdc);
  const currentMarket =
    marketInventory && marketInventory?.filter((market) => market?.physicalStoreName === currentStoreId);
  const field2 =
    currentMarket?.length > 0 && currentMarket[0]?.x_customField2 ? parseInt(currentMarket[0]?.x_customField2) : 0;
  const availableQuantityInMarket =
    currentMarket?.length > 0 && currentMarket[0]?.x_AvailableQuantityInMarket
      ? parseInt(currentMarket[0]?.x_AvailableQuantityInMarket)
      : 0;

  let markInventory = 0;
  let noDisponible = false;
  if (isPdp && marketInventory) {
    markInventory = marketInventory && availableQuantityInMarket ? availableQuantityInMarket : field2 && field2;
  } else if (kitAvailabilityData?.kitMarketAvailability !== 0) {
    markInventory = kitAvailabilityData.kitMarketAvailability;
  }

  if (markInventory === 0) {
    noDisponible = true;
  }

  const currentStore = useSelector(currentStoreSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);
  /* update quantity to reducer */
  // const addQuantityReducer = (quantity) => {
  //   const query = {
  //     url: "",
  //     data: `${quantity}`,
  //     option: "PDP_QUANTITY",
  //     fetch: false,
  //   };
  //   dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  // };

  function ahorroWithComas(ahorro: number) {
    const result = ahorro
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return parseFloat(result);

    return result;
  }

  useEffect(() => {
    if (catentryData) {
      const price = numberWithCommas(catentryData);

      if (quantityMeasure !== "") {
        if (quantityMeasure === "c62") {
          setUnitPrice(price.offerPrice);
          return;
        }

        setUnitPrice(price.offerMedidaPrice);
      } else {
        setUnitPrice(price.offerPrice);
      }
    }
  }, [catentryData]);

  useEffect(() => {
    if (unitPrice !== null) {
      const unitPlainPrice = unitPrice.replaceAll(",", "");
      const total = parseFloat(nominalQuantity) * parseFloat(unitPlainPrice);
      setTotalPriceQuantity(ahorroWithComas(total));
    }
  }, [unitPrice, nominalQuantity]);

  useEffect(() => {
    // addQuantityReducer(productQuantity);
    // return () => {
    //   addQuantityReducer(null);
    // };
    if (quantityRdc === null) {
      setproductQuantity(nominalQuantity);
    } else {
      setproductQuantity(quantityRdc);

      if (quantityRdc && unitPrice !== null) {
        const unitPlainPrice = unitPrice.replaceAll(",", "");
        const total = parseFloat(quantityRdc) * parseFloat(unitPlainPrice);
        setTotalPriceQuantity(ahorroWithComas(total));
      }
    }
  }, [quantityRdc, showheader]);

  const handleSetQuantity = (value: string) => {
    const query = {
      url: "",
      data: `${value}`,
      option: "PDP_QUANTITY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });

    if (value === "" && unitPrice !== null) {
      const unitPlainPrice = unitPrice.replaceAll(",", "");
      const total = parseFloat(nominalQuantity) * parseFloat(unitPlainPrice);
      setTotalPriceQuantity(ahorroWithComas(total));
    }
    if (value && unitPrice !== null) {
      const unitPlainPrice = unitPrice.replaceAll(",", "");
      const total = parseFloat(value) * parseFloat(unitPlainPrice);
      setTotalPriceQuantity(ahorroWithComas(total));
    }
    // setQuantity(value);
  };

  window["handleSetQuantityByCalculator"] = (quantity: string) => {
    handleSetQuantity(quantity);
  };

  /* Format price */
  const formatProductPrice = () => {
    const dollarUSLocale = Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" });
    const formattedValue = dollarUSLocale.format(productPrice);
    return formattedValue;
  };
  const formattedPrice = formatProductPrice();

  /* Calculate if at the bottom of the page */
  const [isBottom, setIsBottom] = React.useState(false);

  useEffect(() => {
    if (hdmDrawerColorData?.colorCode) {
      setColorCode(hdmDrawerColorData?.colorCode);
    } else {
      setColorCode(null);
    }
  }, [hdmDrawerColorData]);

  React.useEffect(() => {
    if (parseInt(nominalQuantity) > 0) {
      if (parseInt(quantityRdc) > parseInt(nominalQuantity)) {
        handleSetQuantity(quantityRdc);
      } else {
        // setproductQuantity(nominalQuantity);
        handleSetQuantity(nominalQuantity);
      }
    }
  }, []);

  const handleClick = (markInventory) => {
    //Validation
    const isInStock = parseInt(productQuantity) <= markInventory;

    if (!isInStock) {
      //Show Msg
      setShowMsg(true);

      setTimeout(() => {
        setShowMsg(false);
      }, 5000);
      return;
    }

    if (isInStock) {
      dispatch(OPEN_CART_DRAWER_ACTION({source: "pdpProduct"}));
      dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_PRODUCT_TO_CART, idx: partNumber }));
      if (isPdp) {
        addToCart(partNumber, installationPartNumberData, productQuantity);
      } else {
        addComboToCart(productQuantity);
      }
      // scroll down to the last added items
      const cartList = document.getElementsByClassName("product-list-container")[0];
      if (cartList) {
        setTimeout(() => {
          cartList.scrollTop = cartList?.scrollHeight;
        }, 300);
      }

    }
  };

  const addToCart = (partNumber: string, installationPartNumberData123: string, productQuantity: string) => {
    // const installationPartNumberData = "746_SI_906207"
    const hasInstallation = true;
    const payloadBase: any = {
      storeId: storeId,
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };

    if (!productQuantity || productQuantity === "") {
      return;
    }

    // let colorCode: any = null
    // if(hdmDrawerColorData?.colorCodeSelected){
    //   colorCode = hdmDrawerColorData?.colorCodeSelected?.codigo !== 'N/A' ? hdmDrawerColorData?.colorCodeSelected?.codigo : hdmDrawerColorData?.colorCodeSelected?.valor
    // }
    const eventId = setCookieCAPI("mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);
    const pstore = getCookieValue(`WC_DefaultStore_${storeId}`);
    const param = {
      partnumber: hasInstallation ? [partNumber, installationPartNumberData] : partNumber,
      quantity: hasInstallation ? [productQuantity, productQuantity] : productQuantity,
      contractId: contract,
      colorCode: colorCode,
      mfbt: eventId,
      fbc: fbc,
      fbp: fbp,
      pstore: pstore,
      userAgent: userAgent,
      marketId: marketId,
      ...payloadBase,
    };

    dispatch(orderActions.ADD_ITEM_ACTION(param));
    const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
    triggerAddToCartDL(
      mySite.storeID,
      mySite?.isB2B && loginStatus && proCustomerLevel
        ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel
        : currentStore?.currentStore?.stLocId,
      contract,
      partNumber,
      productQuantity,
      itemAffiliation,
      currentStore?.currentStore?.marketId,
      mySite?.isB2B
    );

    triggerConversionsPinterestAddToCart(
      mySite.storeID,
      mySite?.isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
      contract,
      partNumber,
      productQuantity,
      currentStore?.currentStore?.marketId,
      mySite?.isB2B,
      cart.orderId
    );
    // if(installationData.installationPartNumber){
    //   dispatch(orderActions.ADD_ITEM_ACTION(installationParam));
    // }
  };

  const storeOnlyAvailable =
    promociones.includes("STORE_ONLY_AVAILABLE") || promociones.includes("PRO_STORE_ONLY_AVAILABLE");
  const promoIconPC = promociones.includes("PROMOICON_PC");

  if (!isMobile && showCarritoHeader && !isMainShoperAction) {
    return (
      <StyledBox width={"100%"}>
        <ContainerStyledStickyShopper contained className="styled--styckyshopper-container">
          <StyledBox className="styled--styckyshopper-price">
            <StyledTypography variant="bodyBaseline" fontWeight="600" component="span">
              {`$${totalPriceQuantity}`}
            </StyledTypography>
            {parseInt(catentryData.nominalQuantity) > 1 && parseInt(nominalQuantity) >= 1 ? (
              <>
                <StyledTypography variant="bodyBaseline" component="span" className="left-margin-2 color-gray300">
                  {`Cantidad mínima:`}
                </StyledTypography>
                <StyledTypography
                  variant="bodyBaseline"
                  component="span"
                  fontWeight="600"
                  className="left-margin-2 color-gray300">
                  {nominalQuantity}
                </StyledTypography>
              </>
            ) : null}
          </StyledBox>

          {!loginStatus && isB2B ? (
            <StyledBox className={"styled--shopperbutton-container"} width={"100%"}>
              <StyledButton
                variant="contained"
                className={"productBoxVerDetalle"}
                disableRipple={true}
                fullWidth
                onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                {t("productDetail.LoginToAddCart")}
              </StyledButton>
            </StyledBox>
          ) : (storeOnlyAvailable || noDisponible) && !promoIconPC ? (
            <StyledBox className={"styled--shopperbutton-container"} width={"100%"}>
              <StyledButton
                variant="contained"
                className={"productBoxVerDetalle"}
                disableRipple={true}
                fullWidth
                onClick={null}
                disabled>
                {storeOnlyAvailable ? t("productBox.VentaExclusivaTienda") : "Producto agotado"}
              </StyledButton>
            </StyledBox>
          ) : (
            <>
              <StyledBox className="styled--shopperbutton-container">
                <StyledBox>
                  {(!storeOnlyAvailable || !noDisponible) && showMsg && (
                    <StyledGrid item xs={12} md={12} className="prompt-alert ">
                      <InfoIcon />
                      <StyledTypography variant="bodySubText">
                        <Trans
                          t={t}
                          i18nKey="productDetail.someWithNotEnough"
                          values={{ promptNominalQuantity: markInventory }}>
                          Este producto solo tiene
                          <StyledTypography variant="bodySubText" fontWeight="600" component="span">
                            promptNominalQuantity
                          </StyledTypography>
                          unidades disponibles, intenta con una cantidad menor.
                        </Trans>
                      </StyledTypography>
                    </StyledGrid>
                  )}
                </StyledBox>

                <StyledBox className="stikcy-shoper-button" style={{ display: "flex" }}>
                  <StyledBox className="sticky-quantity-container right-margin-1">
                    <ProductQuantity
                      isPdp={isPdp}
                      nominalQuantity={nominalQuantity}
                      quantity={productQuantity}
                      handleSetQuantity={handleSetQuantity}
                      isNotZero={isNotZero}
                    />
                  </StyledBox>
                  <ButtonHDM
                    text={"Agregar al carrito"}
                    variant="contained"
                    onClick={productQuantity === "0" ? () => console.log() : () => handleClick(markInventory)}
                    disabled={productQuantity === "0" || noDisponible ? true : false}
                  />
                </StyledBox>
              </StyledBox>
            </>
          )}

          {/* </StyledBox> */}
        </ContainerStyledStickyShopper>

        <StyledGrid className="minimun-quantity"></StyledGrid>
      </StyledBox>
    );
  }

  // if (!isMobile && !showCarritoHeader) {
  //   console.log("entroakimero2")
  //   return (
  //     <>
  //       <StyledShopperActionsContainer
  //         container
  //         spacing={2}
  //         className={`${isBottom ? "hideFooter" : ""} shopper-actions-card`}
  //         id="gei-test-id">
  //         {/* Block 1 alert prompt */}

  //         {(!storeOnlyAvailable || !noDisponible) && showMsg && (
  //           <StyledGrid item xs={12} md={12} className="prompt-alert">
  //             <InfoIcon />
  //             <StyledTypography variant="bodySubText" component="span">
  //               {/* {`Este producto solo tiene ${parse(promptContent)} unidades disponibles, intenta con una cantidad menor.`} */}
  //               <Trans
  //                 t={t}
  //                 i18nKey="productDetail.someWithNotEnough"
  //                 values={{ promptNominalQuantity: markInventory }}>
  //                 Este producto solo tiene
  //                 <StyledTypography variant="bodySubText" fontWeight="600" component="span">
  //                   promptNominalQuantity
  //                 </StyledTypography>
  //                 unidades disponibles, intenta con una cantidad menor.
  //               </Trans>
  //             </StyledTypography>
  //           </StyledGrid>
  //         )}

  //         {/* Block 2 [product price and minim quantity]*/}
  //         {isMobile && (
  //           <StyledGrid item xs={6} md={6} className="price-quantity">
  //             <StyledTypography variant="bodyBaseline">{formattedPrice}</StyledTypography>
  //           </StyledGrid>
  //         )}

  //         {!loginStatus && isB2B ? (
  //           <StyledBox className={"verDetalle"} width={"100%"}>
  //             <StyledButton
  //               variant="contained"
  //               className={"productBoxVerDetalle"}
  //               disableRipple={true}
  //               fullWidth
  //               onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
  //               {t("productDetail.LoginToAddCart")}
  //             </StyledButton>
  //           </StyledBox>
  //         ) : (storeOnlyAvailable || noDisponible) && !promoIconPC ? (
  //           <StyledBox className={"verDetalle"} width={"100%"}>
  //             <StyledButton
  //               variant="contained"
  //               className={"productBoxVerDetalle"}
  //               disableRipple={true}
  //               fullWidth
  //               onClick={null}
  //               disabled>
  //               {storeOnlyAvailable ? t("productBox.VentaExclusivaTienda") : "Producto agotado"}
  //             </StyledButton>
  //           </StyledBox>
  //         ) : (
  //           <>
  //             <StyledGrid item xs={isMobile ? 6 : 12} className="minimun-quantity">
  //               {/* <StyledTypography variant="bodyBaseline">{`Cantidad mínima: ${nominalQuantity}`}</StyledTypography> */}
  //               {parseInt(nominalQuantity) > 1 ? (
  //                 <>
  //                   <StyledTypography variant="bodyBaseline" component="span" className="color-gray300">
  //                     Cantidad mínima:{" "}
  //                   </StyledTypography>
  //                   <StyledTypography
  //                     variant="bodyBaseline"
  //                     fontWeight={600}
  //                     component="span"
  //                     className="color-gray300">
  //                     {nominalQuantity}
  //                   </StyledTypography>
  //                 </>
  //               ) : null}
  //             </StyledGrid>

  //             {/* Block 3 [add input and add to car button]*/}
  //             {/* <StyledGrid item xs={5} md={5} className="input-quantity-container"> */}
  //             {/* <InputQuantityHDM quantity={productQuantity} min={nominalQuantity} handleOnChange={(v) => setproductQuantity(v)} /> */}
  //             <StyledBox width={"100%"} display="flex" justifyContent="space-between">
  //               <ProductQuantity
  //                 nominalQuantity={nominalQuantity}
  //                 quantity={productQuantity}
  //                 handleSetQuantity={handleSetQuantity}
  //                 productData={productData}
  //                 isNotZero={isNotZero}
  //               />
  //               {/* </StyledGrid> */}
  //               {/* <StyledGrid item xs={7} md={7} className="addtocar-container"> */}
  //               <ButtonHDM
  //                 text={"Agregar al carrito"}
  //                 variant="contained"
  //                 onClick={productQuantity === "0" ? () => console.log() : () => handleClick(markInventory)}
  //                 disabled={productQuantity === "0" ? true : false}
  //               />
  //             </StyledBox>
  //             {/* </StyledGrid> */}
  //           </>
  //         )}
  //       </StyledShopperActionsContainer>
  //       {/* </Sticky> */}
  //     </>
  //   );
  // }

  if (catentryData) {
    return (
      <>
        <StyledShopperActionsContainer container spacing={2} className={"shopper-actions-card"} id="gei-test-id">
          {/* Block 1 alert prompt */}

          {(!storeOnlyAvailable || !noDisponible) && showMsg && (
            <StyledGrid item xs={12} md={12} className="prompt-alert">
              <InfoIcon />
              <StyledTypography variant="bodySubText">
                {/* {`Este producto solo tiene ${parse(promptContent)} unidades disponibles, intenta con una cantidad menor.`} */}
                <Trans
                  t={t}
                  i18nKey="productDetail.someWithNotEnough"
                  values={{ promptNominalQuantity: markInventory }}>
                  Este producto solo tiene
                  <StyledTypography variant="bodySubText" fontWeight="600" component="span">
                    promptNominalQuantity
                  </StyledTypography>
                  unidades disponibles, intenta con una cantidad menor.
                </Trans>
              </StyledTypography>
            </StyledGrid>
          )}

          {/* Block 2 [product price and minim quantity]*/}
          {isMobile && (
            <StyledGrid item xs={6} md={6} className="price-quantity">
              <StyledTypography variant="bodyBaseline" fontWeight={600}>
                $ {totalPriceQuantity}
              </StyledTypography>
            </StyledGrid>
          )}

          <StyledGrid item xs={isMobile ? 6 : 12} className="minimun-quantity">
            {parseInt(catentryData.nominalQuantity) > 1 && parseInt(nominalQuantity) >= 1 ? (
              <>
                <StyledTypography variant="bodyBaseline" component="span" className="color-gray300">
                  Cantidad mínima:{" "}
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" fontWeight={600} component="span" className="color-gray300">
                  {nominalQuantity}
                </StyledTypography>
              </>
            ) : null}
          </StyledGrid>

          {!loginStatus && isB2B ? (
            <StyledBox className={"verDetalle"} width={"100%"}>
              <StyledButton
                variant="contained"
                className={"productBoxVerDetalle"}
                disableRipple={true}
                fullWidth
                onClick={() => dispatch(OPEN_SIGN_IN_POPUP_ACTION({}))}>
                {t("productDetail.LoginToAddCart")}
              </StyledButton>
            </StyledBox>
          ) : storeOnlyAvailable || noDisponible ? (
            <StyledBox className={"verDetalle"} width={"100%"}>
              <StyledButton
                variant="contained"
                className={"productBoxVerDetalle"}
                disableRipple={true}
                fullWidth
                onClick={null}
                disabled>
                {storeOnlyAvailable ? t("productBox.VentaExclusivaTienda") : "Producto agotado"}
              </StyledButton>
            </StyledBox>
          ) : (
            <>
              {/* Block 3 [add input and add to car button]*/}
              {/* <StyledGrid item xs={5} md={5} className="input-quantity-container"> */}
              {/* <InputQuantityHDM quantity={productQuantity} min={nominalQuantity} handleOnChange={(v) => setproductQuantity(v)} /> */}
              <StyledBox width={"100%"} display="flex" justifyContent="space-between">
                <ProductQuantity
                  isPdp={isPdp}
                  nominalQuantity={nominalQuantity}
                  quantity={productQuantity}
                  handleSetQuantity={handleSetQuantity}
                  isNotZero={isNotZero}
                />
                {/* </StyledGrid> */}
                {/* <StyledGrid item xs={7} md={7} className="addtocar-container"> */}
                <ButtonHDM
                  text={"Agregar al carrito"}
                  variant="contained"
                  onClick={productQuantity === "0" ? () => console.log() : () => handleClick(markInventory)}
                  disabled={productQuantity === "0" ? true : false}
                />
              </StyledBox>
              {/* </StyledGrid> */}
            </>
          )}
        </StyledShopperActionsContainer>
      </>
    );
  } else {
    return loaders.pdp.addToCart;
  }
};

export default ShopperActionsPDPHDM;
