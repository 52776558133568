/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { StyledButton } from "../button";
import { StyledTypography} from '../typography'

export const StyledTextLink = styled(({ ...props }) => (
  <StyledButton {...props} variant="text">
    {props.label}
  </StyledButton>
))`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    &.MuiButtonBase-root {
      font-weight: 400;
    }
  `}
`;


export const StyledLinkTextSecondary = styled(({variant, ...props }) => <StyledTypography {...props} />)`
${({ theme, font }) => `
    font-size: ${font ? font : "14px"};
    cursor: pointer;
`}
`;