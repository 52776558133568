import { useState, useEffect } from "react";
import { StyledBox, StyledTypography, StyledLink } from "../../elements";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTranslation } from "react-i18next";
import { ContainerDescargable } from "./containers-informacion-adicional";
interface Props {
  manuales: any;
}
const ManualesDescargables = (props: Props) => {
  const { manuales } = props;
  const { t } = useTranslation();

  function ordenarObjeto(a, b) {
    if (a.orden < b.orden) {
      return -1;
    }
    if (a.orden > b.orden) {
      return 1;
    }
    return 0;
  }

  const handleOpenFile = (urlFile) => {
    window.open(urlFile, "_blank");
  };

  manuales.sort(ordenarObjeto);

  return (
    <ContainerDescargable>
      <StyledBox className="descargable-seccion-container">
        <StyledTypography variant="textSegment" className="descargable-seccion">
          {t("InformacionAdicional.descargables")}
        </StyledTypography>
        {manuales.map((file) => {
          return (
            <StyledBox className="descargable-card" key={file.orden}>
              <StyledBox className="descargable-container">
                <DescriptionIcon className="desciption-icon" />
                <StyledTypography variant="bodyBaseline">{file.type}</StyledTypography>
              </StyledBox>
              <StyledBox className="download-container">
                <GetAppIcon className="download-icon" />
                  <StyledTypography variant="bodyBaseline" className="download" onClick={() => handleOpenFile(file.url)}>{t("InformacionAdicional.descargar")}</StyledTypography>
              </StyledBox>
            </StyledBox>
          );
        })}
      </StyledBox>
    </ContainerDescargable>
  );
};

export default ManualesDescargables;
