import styled from "styled-components";
import { StyledPaper } from "../../../hdm";

export const StyledSignInContainer = styled((props: any) => <StyledPaper {...props} />)`
  ${({ theme, isMobile, isPopUp, isFyF }) => `
    width: ${isFyF || isMobile ? "auto" : isPopUp ? "600px" : 36 + "%"};
    padding: ${theme.spacing(isPopUp ? 4 : 6)}px ${theme.spacing(isMobile ? 3 : isPopUp ? 4 : 10)}px ${theme.spacing(
    isPopUp ? 4 : 6
  )}px ${theme.spacing(isMobile ? 3 : isPopUp ? 4 : 10)}px;
    box-shadow: none;
    @media (max-width: 960px){
        margin-left: ${theme.spacing(0)}px;
        margin-right: ${theme.spacing(0)}px;
    }
    & .secondary-color-divider{
        width: -webkit-fill-available;
        border-bottom: solid 2px ${theme.palette.primary.main}; 
    }
    & .primary-color-divider{
        width: fit-content;
        border-bottom: solid 2px ${theme.palette.primary.main}; 
    }

    & .gray-color-divider{
        border-bottom: solid 1px ${theme.palette.gray["50"]}; 
        flex-grow: 1;
        margin-bottom: ${theme.spacing(1)}px;
    }

    & .show-password-icon{
        cursor: pointer;
        color: ${theme.palette.gray["300"]};
        font-size: large;
    }

    & .MuiSwitch-thumb {
        background-color: #fff;
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
    }

    & .MuiSwitch-root {
        transform: translateX(-7px);
    }

    & .no-account-title{
        text-align: initial;
    }

`}
`;
export const StyledSignInEspotContainer = styled((props: any) => <StyledPaper {...props} />)`
  ${({ theme, isMobile, isB2B }) => `
    width: ${isMobile ? "auto" : 64 + "%"}; 
    height: ${isMobile ? 500 + "px" : "auto"};
    background-color: ${theme.palette.gray["100"]};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: ${isB2B ? "30px 60px" : ""};
`}
`;
