import React, {FC} from 'react';
import {StyledTypography} from "../../../hdm/elements";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {StyledListItemHDM} from "./categories-styles";


interface ICategoriesListItem {
    setCategory: (any) => void,
    category: any,
    idText: string
}

const CategoriesListItem:FC<ICategoriesListItem> = ({setCategory, category, idText=""}) => {
    return (
        <StyledListItemHDM
            id={idText}
            className={'list-cat'}
            onClick={() => setCategory(category)}
        >
            <StyledTypography
                id={`departmentLink_${category.id}`}
                className={'list-cat__name'}
                variant={'bodyBaseline'}
            >
                {category.name}
            </StyledTypography>
            <ArrowForwardIosIcon
                className={'list-cat__icon'}
            />
        </StyledListItemHDM>
    );
};

export default CategoriesListItem;
