import React from "react";
import styled from "styled-components";
import {StyledBox, StyledListItem} from "../../../hdm";
import {palette} from "../../../hdm/themes";
import {Divider} from "@material-ui/core";
import {StyledLink} from "@hcl-commerce-store-sdk/react-component";


export const StyledBoxHDM = styled((props: any) => <StyledBox {...props} />)`
  ${({theme}) => `
    &.cat-cont{
      & .cat-cont__return-sect{
        display: flex;
        align-items: center;
        padding: ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;
        height: 44px;
        & .MuiTypography-root{
          color: ${palette.secondary.main};
        }
        & .cat-cont__return-sect-icon{
          color: ${palette.secondary.main};
          font-size: 1rem;
        }
      }
      .cat-cont__title, .cat-cont__title-line{
        // padding: ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;
      }
      .cat-cont__title-line{
        text-decoration: underline;
        white-space: initial;
        display: flex;
        align-items: center;
        min-height: 25px;
      }
    }
  `}
`;

export const StyledBoxProgressHDM = styled((props: any) => <StyledBox {...props} />)`
  ${({theme}) => `
      &.circular{
          position: absolute;
          top: 45%;
          left: 45%;
      }
  `}
`;

export const StyledDivider = styled((props: any) => <Divider {...props} />)`
  ${({theme}) => `
        &.divider{
          background-color: ${palette.gray["50"]}!important;
          height: 1px;
        }
  `}
`;

export const StyledLinkHDM = styled((props: any) => <StyledLink {...props} />)`
  ${({theme}) => `
        &.link{
          display: flex;
          color: ${palette.secondary.main};
          padding: ${theme.spacing(1)}px ${theme.spacing(1)}px;
          margin-left:5px!important;
        }
  `}
`;

// LIST STYLES
export const StyledListItemHDM = styled((props: any) => <StyledListItem {...props} />)`
  ${({theme}) => `
        &.list-cat{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 13px ${theme.spacing(1)}px 13px ${theme.spacing(2)}px;
          transition: all ease-in-out .2s;
          &:active{
           background: ${palette.gray["50"]}; 
          }
          .list-cat__name{
        
          }
          & .list-cat__icon{
            font-size: .9rem;
            color: ${palette.primary.main};
          }
        }
  `}
`;