//Standard libraries
import { StyledGrid, StyledTypography } from "../../../hdm";
import InfoIcon from "@material-ui/icons/Info";
import { StyledPrompContainer } from "./StyledPrompContainer";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircle from "@material-ui/icons/CheckCircle";

const StyledPromp = ({ ...props }) => {
  const message = props.message;
  const variant = props.variant;
  const className = props.className;
  const openOutOfRange = props.openOutOfRange;

  if (variant === "alert") {
    return (
      <StyledPrompContainer>
        <StyledGrid item className={"styledPromp--styledGrid-descriptionContainer styledPromp-styledGrid-alert"}>
          <InfoIcon className="styledPromp--icon-infoIcon" viewBox="0 0 24 24" />
          <StyledGrid className="styledPromp--styledGrid-messageContainer">
            <StyledTypography variant="bodySubText" className={"color-gray400 left-padding-1"}>
              {message}
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledPrompContainer>
    );
  }

  if (variant === "checkoutAlert") {
    return (
      <StyledPrompContainer>
        <StyledGrid
          item
          className={"styledPromp--styledGrid-descriptionContainer styledPromp-styledGrid-checkout-alert"}>
          <InfoIcon className="styledPromp--icon-infoIcon-cart" viewBox="0 0 24 24" />
          <StyledGrid className="styledPromp--styledGrid-messageContainer">
            <StyledTypography variant="bodySubText" className={"color-gray400 left-padding-1"}>
              {message}
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledPrompContainer>
    );
  }

  if (variant === "gray") {
    return (
      <StyledPrompContainer>
        <StyledGrid item className={"styledPromp--styledGrid-descriptionContainer styledPromp-styledGrid-gray top-margin-2"}>
          <InfoIcon className="styledPromp--icon-infoIcon color-gray300" viewBox="0 0 24 24" style={{marginRight: "3px"}} />
          <StyledGrid className="styledPromp--styledGrid-messageContainer">
            <StyledTypography variant="bodySubText" className={"color-gray300 left-padding-1"}>
              {message}
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledPrompContainer>
    );
  }

  if (variant === "error") {
    return (
      <StyledPrompContainer className={className}>
        <StyledGrid item className={ !openOutOfRange ? "styledPromp--styledGrid-descriptionContainer styledPromp--styledGrid-descriptionContainer-b styledPromp-styledGrid-error" 
          : "styledPromp--styledGrid-descriptionContainer styledPromp-styledGrid-error"}>
          <BlockIcon className="styledPromp--icon-blockIcon" viewBox="0 0 24 24" />
          <StyledGrid className="styledPromp--styledGrid-messageContainer">
            <StyledTypography variant="bodySubText">{message}</StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledPrompContainer>
    );
  }

  if (variant === "success") {
    return (
      <StyledPrompContainer>
        <StyledGrid item className={"styledPromp--styledGrid-descriptionContainer styledPromp-styledGrid-success"}>
          <CheckCircle className="styledPromp--icon-CheckCircle" viewBox="0 0 24 24" />
          <StyledGrid className="styledPromp--styledGrid-messageContainer">
            <StyledTypography variant="bodySubText" className={"styledPromp--typography-shippingGray"}>
              {message}
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledPrompContainer>
    );
  }

  return <></>;
};

export { StyledPromp };
