import styled from "styled-components";
import { StyledCardEmpty } from "../../../hdm";

const ContainerCreateWishList = styled(({ ...props }) => <StyledCardEmpty {...props} />)`
  ${({ theme }) => `

      width: calc((100% - 20px) / 3);
      padding: 10px;
      
      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }

  `}
`;

export { ContainerCreateWishList };