import { Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StyledAccordion, StyledBox, StyledContainer, StyledGrid } from "../../../../../hdm";
import { HDmHeadingTitle } from "../../../../../hdm/components/hdm-heading-main-title/HdmHeadingMainTitle";
import { SLCheckout } from "../dimensions/Checkout";
import { SLFooterElements } from "../dimensions/Footer";
import { SLShoppingCart } from "../dimensions/ShoppingCart";
import { StyledSkeleton } from "../StyledSkeleton";

const SLCheckoutHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const maxWidth600 = !useMediaQuery(theme.breakpoints.up(600));
  const maxWidth960 = !useMediaQuery(theme.breakpoints.up(960));
  const maxWidth1024 = useMediaQuery(theme.breakpoints.down(1024));

  return (
    <StyledAccordion style={{ marginBottom: "0px" }} className={maxWidth960 ? "full-width" : ""}>
      <StyledGrid container style={{ flexWrap: `${maxWidth1024 ? "wrap" : "nowrap"}` }}>
        <StyledGrid
          container
          item
          xs={10}
          direction="row"
          alignItems="center"
          className="bottom-margin-2"
          style={{ flexWrap: "nowrap" }}>
          <StyledGrid container className={"right-margin-2"} style={{ width: "auto" }}>
            <StyledSkeleton
              variant="rect"
              sharp
              width={maxWidth600 ? 40 : SLCheckout.header.logo.getWidth()}
              height={maxWidth600 ? 40 : SLCheckout.header.logo.getHeight()}
            />
          </StyledGrid>
          <StyledSkeleton
            variant="rect"
            width={SLCheckout.header.title.getWidth()}
            height={SLCheckout.header.title.getHeight()}
          />
        </StyledGrid>

        {maxWidth960 ? (
          <StyledGrid xs={12}>
            <Divider className="bottom-margin-1" />
          </StyledGrid>
        ) : (
          <></>
        )}

        {/* Back button */}
        <StyledGrid
          container
          item
          xs={2}
          alignItems="flex-start"
          style={{
            paddingTop: "2px",
            paddingLeft: `${maxWidth960 ? "7px" : "0px"}`,
            minWidth: `${SLCheckout.header.backButton.getWidth()}px`,
            justifyContent: `${maxWidth960 ? "start" : "end"}`,
          }}
          className={maxWidth960 ? "bottom-padding-3" : ""}>
          <StyledSkeleton
            variant="rect"
            width={SLCheckout.header.backButton.getWidth()}
            height={SLCheckout.header.backButton.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

const SLCheckoutStepper = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const maxWidth960 = !useMediaQuery(theme.breakpoints.up(960));
  return (
    <StyledGrid
      container
      item
      xs={12}
      direction="row"
      alignItems="center"
      className={`horizontal-padding-2 top-padding-${maxWidth960 ? "1" : "3"} bottom-padding-5`}>
      <StyledGrid xs={12}>
        <Divider style={{ backgroundColor: "#CCCCCC", height: "1.6px", marginTop: "10px", marginBottom: "13.4px" }} />
      </StyledGrid>
    </StyledGrid>
  );
};

const SLCheckoutStepOne = () => {
  const { t } = useTranslation();
  return (
    <StyledAccordion>
      <StyledContainer className="page">
        <StyledGrid container>
          <StyledGrid container item xs={12} direction="row" alignItems="center">
            <StyledSkeleton
              className={"right-margin-2"}
              variant="rect"
              width={SLCheckout.steps.header.step.getWidth()}
              height={SLCheckout.steps.header.step.getHeight()}
            />
            <StyledSkeleton
              variant="rect"
              width={SLCheckout.steps.header.title.getWidth()}
              height={SLCheckout.steps.header.title.getHeight()}
            />
          </StyledGrid>

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          <StyledGrid item xs={12}>
            <StyledSkeleton variant="rect" className="wide top-margin-1 bottom-margin-1" height={80} />
            <StyledGrid xs={12}>
              <Divider className="top-margin-3 bottom-margin-2" />
            </StyledGrid>
            <StyledSkeleton variant="rect" className="wide top-margin-1 bottom-margin-2" height={40} />
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledAccordion>
  );
};

const SLCheckoutStepTwo = () => {
  return (
    <StyledAccordion>
      <StyledGrid container>
        <StyledGrid container item xs={12} direction="row" alignItems="center" className="bottom-margin-2">
          <StyledSkeleton
            className={"right-margin-2"}
            variant="rect"
            width={SLCheckout.steps.header.step.getWidth()}
            height={SLCheckout.steps.header.step.getHeight()}
          />
          <StyledSkeleton
            variant="rect"
            width={SLCheckout.steps.header.title.getWidth()}
            height={SLCheckout.steps.header.title.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

const SLCheckoutStepThree = () => {
  return (
    <StyledAccordion>
      <StyledGrid container>
        <StyledGrid container item xs={12} direction="row" alignItems="center" className="bottom-margin-2">
          <StyledSkeleton
            className={"right-margin-2"}
            variant="rect"
            width={SLCheckout.steps.header.step.getWidth()}
            height={SLCheckout.steps.header.step.getHeight()}
          />
          <StyledSkeleton
            variant="rect"
            width={SLCheckout.steps.header.title.getWidth()}
            height={SLCheckout.steps.header.title.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

export const ReusableElement = () => {
  return (
    <StyledGrid
      container
      style={{ flexWrap: "nowrap" }}
      direction="row"
      justifyContent="space-between"
      className="bottom-margin-2">
      <StyledGrid container direction="column" xs={6}>
        <StyledGrid item className="bottom-margin-1">
          <StyledSkeleton variant="rect" width={113.89} height={18} />
        </StyledGrid>
        <StyledGrid item>
          <StyledSkeleton variant="rect" width={82.83} height={12} />
        </StyledGrid>
      </StyledGrid>
      <StyledGrid container item style={{ alignItems: "center", justifyContent: "right" }} xs={6}>
        <StyledSkeleton variant="rect" width={94.28} height={32.4} />
      </StyledGrid>
    </StyledGrid>
  );
};

const ReusableElementTwo = ({ widthTitle, widthDescription }) => {
  return (
    <>
      <StyledGrid container direction="column" xs={12}>
        <StyledGrid item className="bottom-margin-1">
          <StyledSkeleton variant="rect" width={widthTitle} height={18} />
        </StyledGrid>
        <StyledGrid item>
          <StyledSkeleton variant="rect" width={widthDescription} height={12} />
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

{
  /* Resumen del pedido */
}
export const SLCheckoutSummaryTop = () => {
  return (
    <StyledAccordion>
      <StyledContainer className="page">
        <StyledGrid container>
          {/* Titulo */}
          <StyledGrid container item xs={12} direction="row" justifyContent="center" className="bottom-margin-1">
            <StyledSkeleton
              variant="rect"
              width={SLCheckout.steps.header.title.getWidth()}
              height={SLCheckout.steps.header.title.getHeight()}
            />
          </StyledGrid>

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          {/* Subtotal */}
          <StyledGrid container style={{ flexWrap: "nowrap" }} direction="row" justifyContent="space-between">
            <StyledGrid container direction="column" xs={6}>
              <StyledSkeleton variant="rect" width={59.21} height={18} />
            </StyledGrid>
            <StyledGrid container item style={{ alignItems: "center", justifyContent: "right" }} xs={6}>
              <StyledSkeleton variant="rect" width={66.74} height={18} />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          {/* Total */}
          <ReusableElement />
        </StyledGrid>
      </StyledContainer>
    </StyledAccordion>
  );
};

{
  /* Resumen de tu orden */
}
const SLCheckoutSummaryBottom = () => {
  return (
    <StyledAccordion>
      <StyledContainer className="page">
        <StyledGrid container>
          {/* Titulo */}
          <StyledGrid container item xs={12} direction="row" justifyContent="center" className="bottom-margin-1">
            <StyledSkeleton
              variant="rect"
              width={SLCheckout.steps.header.title.getWidth()}
              height={SLCheckout.steps.header.title.getHeight()}
            />
          </StyledGrid>
          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          <ReusableElementTwo widthTitle={145.03} widthDescription={249.51} />

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          <ReusableElementTwo widthTitle={129.23} widthDescription={258.85} />

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          <ReusableElementTwo widthTitle={115} widthDescription={230.16} />

          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
          </StyledGrid>

          {/* Total */}
          <ReusableElement />
        </StyledGrid>
      </StyledContainer>
    </StyledAccordion>
  );
};

export const SLCheckoutPage = (props) => {
  const theme = useTheme();
  const maxWidth1024 = useMediaQuery(theme.breakpoints.down(1024));
  const maxWidth960 = !useMediaQuery(theme.breakpoints.up(960));

  return (
    <StyledContainer id={"checkout"}>
      <StyledGrid
        container
        spacing={2}
        style={{
          margin: "0 auto",
          width: "100%",
          padding: `${!maxWidth1024 ? "5px" : "0px"}`,
          flexWrap: `${maxWidth1024 ? "wrap" : "nowrap"}`,
        }}>
        {maxWidth1024 ? (
          <>
            {maxWidth960 ? (
              <>
                <SLCheckoutHeader />
                <StyledGrid item xs={12} style={{ padding: "10px" }}>
                  <SLCheckoutSummaryTop />
                  <SLCheckoutStepper />
                  <SLCheckoutStepOne />
                  <SLCheckoutStepTwo />
                  <SLCheckoutStepThree />
                  <SLCheckoutSummaryBottom />
                </StyledGrid>
              </>
            ) : (
              <StyledGrid item xs={12} style={{ padding: "10px" }}>
                <SLCheckoutHeader />
                <SLCheckoutStepper />
                <SLCheckoutStepOne />
                <SLCheckoutStepTwo />
                <SLCheckoutStepThree />
                <SLCheckoutSummaryTop />
                <SLCheckoutSummaryBottom />
              </StyledGrid>
            )}
          </>
        ) : (
          <>
            <StyledGrid item xs={7}>
              <SLCheckoutHeader />
              <SLCheckoutStepper />
              <SLCheckoutStepOne />
              <SLCheckoutStepTwo />
              <SLCheckoutStepThree />
            </StyledGrid>
            <StyledGrid item xs={5}>
              <SLCheckoutSummaryTop />
              <SLCheckoutSummaryBottom />
            </StyledGrid>
          </>
        )}
      </StyledGrid>
    </StyledContainer>
  );
};
