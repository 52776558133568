export interface productDataInterface {
    price: any,
    product: any,
    partNumber: any,
    name: any,
    quantityMeasure: any,
    weightMeasure: any,
    legal: any,
    allEspecificacionesTec: any,
    videos: any,
    promociones: any,
    urlRibbons: any,
    orderRibbons: any,
    modelo: any,
    manufacturer: any,
    productImage: any,
    nominalQuantity: any,
    bahiaData: any,
    disponibilidadEnTienda: any,
    nextAvailableInventoryDate: any,
    disponibilidadMarket: any,
    marketQuantity: any,
    tiendaNombre: any,
    locationName: any,
    tiendaId: any,
    productId: any,
    leadTime: any,
    thisProduct: any,
}

export enum productDataActions {
    SET_PRICE,
    SET_PRODUCT,
    SET_PARTNUMBER,
    SET_NAME,
    SET_QUANTITY_MEASURE,
    SET_WEIGHT_MEASURE,
    SET_LEGAL,
    SET_TECNICAL_SPECS,
    SET_VIDEOS,
    SET_PROMOTIONS,
    SET_URL_RIBBON,
    SET_ORDER_RIBBON,
    SET_MODELO,
    SET_MANUFACTURER,
    SET_PRODUCT_IMAGE,
    SET_NOMINAL_QUANTITY,
    SET_LOCATION_DATA,
    SET_AVAILABILITY_IN_PHYSICAL_STORE,
    SET_NEXT_AVAILABLE_INVENTORY_DATE,
    SET_MARKET_AVAILABILITY,
    SET_MARKET_QUANTITY,
    SET_STORE_NAME,
    SET_LOCATION_NAME,
    SET_STORE_ID,
    SET_PRODUCT_ID,
    SET_THIS_PRODUCT,
    LOAD_DATA,
}

export const productDataInitData: productDataInterface = {
    price: undefined,
    product: undefined,
    partNumber: undefined,
    name: undefined,
    quantityMeasure: undefined,
    weightMeasure: undefined,
    legal: undefined,
    allEspecificacionesTec: undefined,
    videos: undefined,
    promociones: undefined,
    urlRibbons: undefined,
    orderRibbons: undefined,
    modelo: undefined,
    manufacturer: undefined,
    productImage: undefined,
    nominalQuantity: undefined,
    bahiaData: undefined,
    disponibilidadEnTienda: undefined,
    nextAvailableInventoryDate: undefined,
    disponibilidadMarket: undefined,
    marketQuantity: undefined,
    tiendaNombre: undefined,
    locationName: undefined,
    tiendaId: undefined,
    productId: undefined,
    leadTime: undefined,
    thisProduct: undefined
}

export function productDataReducer(state: productDataInterface, action) {
    switch (action.type) {
        case productDataActions.LOAD_DATA:
            return { ...state, ...action.payload }
        default:
            throw new Error();
    }
}