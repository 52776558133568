/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import { CommerceEnvironment, KEY_CODES, SEARCHTERM } from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
// import * as ACTIONS from "../../../redux/action-types/catalog";
import * as ACTIONS from "../../../redux/actions/catalog";

//UI
import {
  StyledTextField,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
  StyledBox,
} from "../../../hdm/elements";
import { InputAdornment, ClickAwayListener, Divider } from "@material-ui/core";
import SearchIconSharp from "@material-ui/icons/Search";
import styled from "styled-components";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { useDebounce } from "use-debounce";

const StyledSearchIcon = styled(SearchIconSharp)`
  ${({ theme }) => `
      margin-right: -7px;
  `}
`;

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, openSearchBar, closeSearchBar }) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<any>>([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<any>>([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();

  const searchField = t("SearchBar.SearchField");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);

  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);

  const [searchBarPlaceholder, setSearchBarPlaceholder] = React.useState(searchField);

  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(() => new Map());

  const [debouncedValue] = useDebounce(input, 500);
  const [inputDisabled, setinputDisabled] = React.useState(true);

  //const accentedCharacter = "[áéíóúÁÉÍÓÚ]";

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowSearchBar(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    dispatch(ACTIONS.resetProductListAction());
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand"],

        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data.suggestionView[0].entry;
            const brandsResponse = res?.data.suggestionView[1].entry;
            generateCategoriesList(categoriesResponse);
            generateCategoriesURL(categoriesResponse);
            generateBrandsList(brandsResponse);
            setinputDisabled(false);
          }
        })
        .catch((e) => {
          console.warn("fail to get category and brand suggestions.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   retrieveSuggestions(input)
  //   return() => {
  //     cancels.forEach((cancel) => cancel('The input search has changed.'));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [input]);

  useEffect(() => {
    retrieveSuggestions(debouncedValue)
    // if (!isPaint) {
    // }
  }, [debouncedValue])

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < categoriesResponse.length; i++) {
      lists.push(categoriesResponse[i].fullPath);
    }
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < brandsResponse.length; i++) {
      lists.push(brandsResponse[i].name);
    }
    setBrands(lists);
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      const url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;

    setInput(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          ...payloadBase,
        };

        siteContentService.findKeywordSuggestionsByTermUsingGET(parameters).then((res) => {
          if (res.status === OK) {
            const keywordSuggestions = res?.data.suggestionView[0].entry;
            if (keywordSuggestions) {
              const list: string[] = [];
              generateSuggestionList(keywordSuggestions, searchTerm, list);
              generateCatgoriesAndBrandsSuggestions(searchTerm, list);
              setNameList(list);
            }
          }
        }).catch(function (e) {
          //If is a cancel
          if (Axios.isCancel(e)) {
            console.debug(`Request cancelled:${e.message}`)
          } else {
            //else print error
            console.error('An error ocurred while searching products '+e)
          }
        });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const generateCatgoriesAndBrandsSuggestions = (userInput: string, listTemp: string[]) => {
    //Note: include accented characters
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => {
      if (e) {
        return e.match(regex);
      }
    });
    const lists: object[] = [];
    if (matchedCategories) {
      for (const suggestion of matchedCategories) {
        if (lists.length === 4) {
          break;
        }
        const suggestionSkeleton = JSON.parse(JSON.stringify(CommerceEnvironment.suggestionSkeleton));

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = categoriesUrl.get(suggestion);
        nameListIndex++;
        lists.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setCategorySuggestions(lists);
    setShowCategories(true);
    const matchedBrands = brands?.filter((e) => e.match(regex));
    const lists2: object[] = [];
    if (matchedBrands) {
      for (const suggestion of matchedBrands) {
        if (lists2.length === 4) {
          break;
        }
        const suggestionSkeleton = JSON.parse(JSON.stringify(CommerceEnvironment.suggestionSkeleton));

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setBrandSuggestions(lists2);
    setShowBrands(true);
  };

  const generateSuggestionList = (keywordSuggestions: any[], userInput: string, listTemp: string[]) => {
    const lists: object[] = [];

    listTemp.push(userInput);
    const listTemp2: string[] = [];

    for (const suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        const suggestionSkeleton = JSON.parse(JSON.stringify(CommerceEnvironment.suggestionSkeleton));

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion.term;
        suggestionSkeleton.url = SEARCH + "/" + suggestion.term;
        listTemp.push(suggestion.term);
        lists.push(suggestionSkeleton);
        listTemp2.push(suggestion.term);
        nameListIndex++;
      }
    }
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    const len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      dispatch(ACTIONS.resetProductListAction());
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            sessionStorage.setItem("event_SearchTerm", searchTerm);
            url = res?.data.redirecturl;

            if (url === undefined) {
              const searchTermModified = searchTerm.replaceAll(" ", "+");
              url = SEARCH + "/" + searchTermModified;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          const searchTermModified = searchTerm.replaceAll(" ", "+");
          url = SEARCH + "/" + searchTermModified;

          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }

    setTimeout(() => {
      clearSuggestions();
      setShowSearchBar(false);
    }, 500);
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

  function boldedText(text, shouldBeBold) {
    const textArray = text.split(shouldBeBold);
    return (
      <b>
        {textArray.map((item, index) => (
          <>
            {item}
            {index !== textArray.length - 1 && <span className="blank">{shouldBeBold}</span>}
          </>
        ))}
      </b>
    );
  }

  return (
    <>
      <input id={`emarsys_input_search_tag`} style={{ display: "none" }} />
      <ClickAwayListener onClickAway={clickAway}>
        <StyledSearchBar
          id={"headerSearch"}
          className={`__searchBar ${isB2B ? "proheader-searchbar-backgroundcolor" : ""}`}>
          <form onSubmit={submitSearch} noValidate>
            <StyledTextField
              margin="normal"
              autoComplete="off"
              type="text"
              disabled={false}
              placeholder={searchBarPlaceholder}
              value={input}
              name="searchTerm"
              onFocus={() => setSearchBarPlaceholder("")}
              onBlur={() => setSearchBarPlaceholder(t("SearchBar.SearchField"))}
              onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
              onKeyDown={onKeyDown}
              InputProps={{
                style: { fontSize: 15 },
                endAdornment: (
                  <>
                    {showKeywords || showCategories || showBrands ? (
                      <InputAdornment position="end">
                        <StyledBox className={input ? "typingInput" : "searchIcon"} onClick={submitSearch}>
                          <StyledSearchIcon
                            fontSize="medium"
                            className={input ? "typingbarInput" : "searchbarIcon"}
                            titleAccess={t("SearchBar.Clear")}
                          />
                        </StyledBox>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="start">
                        <StyledBox className={input ? "typingInput" : "searchIcon"} onClick={submitSearch}>
                          <StyledSearchIcon
                            fontSize="medium"
                            className={input ? "typingbarInput" : "searchbarIcon"}
                            onClick={toggleSearchBar}
                          />
                        </StyledBox>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          </form>

          {(showKeywords || showCategories || showBrands) && (
            <ClickAwayListener onClickAway={clearSuggestionsAndInputField}>
              <ul className="searchBar-results">
                {showKeywords && (
                  <>
                    {keywordSuggestions?.map((e: any, i: number) => (
                      <Link key={`brand-${i}`} to={e.url} onClick={() => {clearSuggestionsAndUpdateInputField(e.name);sessionStorage.setItem("event_SearchTerm", e.name);}}>
                        <StyledMenuItem>
                          <StyledMenuTypography
                            variant="searchResults"
                            className={e.arrIndex === index ? "active" : ""}
                            key={e.arrIndex}
                            id={`megamenu_department_${e.id}`}
                            title={e.name}>
                            {boldedText(e.name, input)}
                          </StyledMenuTypography>
                        </StyledMenuItem>
                      </Link>
                    ))}
                  </>
                )}
                <Divider />
                {showCategories && (
                  <>
                    <StyledMenuItem className="__search-bar-titles">
                      <StyledMenuTypography variant="searchBarTitles">{categoryTitle}</StyledMenuTypography>
                    </StyledMenuItem>

                    {categorySuggestions?.map((e: any, i: number) => (
                      <Link
                        key={`category-${i}`}
                        to={e.url}
                        onClick={(evt) => {clearSuggestionsAndUpdateInputField(e.name);sessionStorage.setItem("event_SearchTerm", e.name);}}>
                        <StyledMenuItem>
                          <StyledMenuTypography
                            variant="searchResultsCategoriesAndBrands"
                            className={e.arrIndex === index ? "active" : ""}
                            key={e.arrIndex}
                            id={`megamenu_department_${e.id}`}
                            title={e.name}>
                            {e.name}
                          </StyledMenuTypography>
                        </StyledMenuItem>
                      </Link>
                    ))}
                  </>
                )}
                <Divider />
                {showBrands && (
                  <>
                    <StyledMenuItem className="__search-bar-titles">
                      <StyledMenuTypography variant="searchBarTitles">{brandTitle}</StyledMenuTypography>
                    </StyledMenuItem>
                    {brandSuggestions?.map((e: any, i: number) => (
                      <Link
                        key={`brand-${i}`}
                        to={e.url}
                        onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                        <StyledMenuItem>
                          <StyledMenuTypography
                            variant="searchResultsCategoriesAndBrands"
                            className={e.arrIndex === index ? "active" : ""}
                            key={e.arrIndex}
                            id={`megamenu_department_${e.id}`}
                            title={e.name}>
                            {e.name}
                          </StyledMenuTypography>
                        </StyledMenuItem>
                      </Link>
                    ))}
                  </>
                )}
              </ul>
            </ClickAwayListener>
          )}
        </StyledSearchBar>
      </ClickAwayListener>
    </>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
