import { useMediaQuery } from "@material-ui/core";
import { StyledContainer } from "@hcl-commerce-store-sdk/react-component";
import { StyledAccordion, StyledBox, StyledGrid } from "../../../../../hdm";
import { SLPDP } from "../dimensions/PDP";
import { StyledSkeleton } from "../StyledSkeleton";
import { tabletBreakpoint } from "../../../../../hdm/functions/utils";
import theme from "../../../../../hdm/themes/thd";
import { useTheme } from "@material-ui/core/styles";
import StyledWrapper from "../../../../StyledUI/StyledWrapper";
import styled from "styled-components";

export const SLPDPEspotUXComponentCard = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  return (
    <>
      <StyledGrid item xs={12} id="sofff">
        <StyledAccordion>
          <StyledGrid container className={isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
            <StyledGrid item xs={8} spacing={2}>
              <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={32} />
              <StyledSkeleton variant="rect" className={"wide"} height={32} />
            </StyledGrid>
            <StyledGrid container justifyContent="flex-end" item xs={4}>
              <StyledSkeleton variant="rect" className={"wide left-margin-1"} height={69} />
            </StyledGrid>
          </StyledGrid>
        </StyledAccordion>
      </StyledGrid>
    </>
  );
};

export const SLPDPEspotCrossSellComponentCard = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  return (
    <>
      <StyledGrid container xs={12}>
        <StyledGrid container className={"top-margin-1 bottom-margin-1"}>
          <StyledGrid item xs={12}>
            <StyledSkeleton noCard variant="rect" className={"bottom-margin-1"} width={200} height={30} />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledSkeleton noCard variant="rect" className={"wide bottom-margin-1"} height={5} />
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item xs={3}>
          <StyledAccordion>
            <StyledGrid container className={isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={115} />
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={28} />
              </StyledGrid>
            </StyledGrid>
          </StyledAccordion>
        </StyledGrid>
        <StyledGrid item xs={3}>
          <StyledAccordion>
            <StyledGrid container className={isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={115} />
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={28} />
              </StyledGrid>
            </StyledGrid>
          </StyledAccordion>
        </StyledGrid>
        <StyledGrid item xs={3}>
          <StyledAccordion>
            <StyledGrid container className={isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={115} />
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={28} />
              </StyledGrid>
            </StyledGrid>
          </StyledAccordion>
        </StyledGrid>
        <StyledGrid item xs={3}>
          <StyledAccordion>
            <StyledGrid container className={isTablet ? "bottom-margin-3" : "bottom-margin-1"}>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={115} />
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledSkeleton variant="rect" className={"wide bottom-margin-1"} height={28} />
              </StyledGrid>
            </StyledGrid>
          </StyledAccordion>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export const SLPDPProductImageCard = () => {
  return (
    <StyledGrid container>
      <StyledGrid item xs={10}>
        <StyledSkeleton className={"wide"} variant="rect" height={SLPDP.image.main.getHeight()} />
      </StyledGrid>
      <StyledGrid container justifyContent="flex-end" item xs={2}>
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
        <StyledSkeleton
          className={"margin-2"}
          variant="rect"
          width={SLPDP.image.carousel.getWidth()}
          height={SLPDP.image.carousel.getHeight()}
        />
      </StyledGrid>
    </StyledGrid>
  );
};
export const SLPDPProductSummaryCard = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }

  return (
    <>
      <StyledGrid className={isMobile ? "left-margin-2 right-margin-2" : ""}>
        <StyledAccordion>
          <StyledGrid container>
            <StyledGrid className={"bottom-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={isMobile ? "33%" : SLPDP.productSummary.brand.getWidth()}
                height={SLPDP.productSummary.brand.getHeight()}
              />
            </StyledGrid>
            <StyledGrid className={"bottom-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={isMobile ? "100%" : SLPDP.productSummary.name.getWidth()}
                height={SLPDP.productSummary.name.getHeight()}
              />
            </StyledGrid>
            <StyledGrid className={"bottom-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={isMobile ? "100%" : SLPDP.productSummary.productInfo.getWidth()}
                height={SLPDP.productSummary.productInfo.getHeight()}
              />
            </StyledGrid>
            <StyledGrid className={"bottom-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={isMobile ? "40%" : SLPDP.productSummary.rating.getWidth()}
                height={SLPDP.productSummary.rating.getHeight()}
              />
            </StyledGrid>
          </StyledGrid>
        </StyledAccordion>
      </StyledGrid>
    </>
  );
};

export const SLPDPProductImageCardIsTablet = () => {
  return (
    <StyledGrid container>
      <StyledGrid container item xs={12} justifyContent="center" alignItems="center">
        <StyledSkeleton
          className={"bottom-margin-1 wide"}
          variant="rect"
          height={SLPDP.image.mobile.main.getHeight()}
        />
      </StyledGrid>
      <StyledGrid container item xs={12} justifyContent="center" className={"bottom-margin-3 top-margin-3"}>
        <StyledSkeleton className={"margin-1"} variant="rect" width={10} height={10} />
        <StyledSkeleton className={"margin-1"} variant="rect" width={10} height={10} />
        <StyledSkeleton className={"margin-1"} variant="rect" width={10} height={10} />
        <StyledSkeleton className={"margin-1"} variant="rect" width={10} height={10} />
        <StyledSkeleton className={"margin-1"} variant="rect" width={10} height={10} />
      </StyledGrid>
    </StyledGrid>
  );
};

export const SLPDPProductPriceCard = () => {
  return (
    <StyledAccordion>
      <StyledGrid container>
        <StyledGrid item xs={12} className={"bottom-margin-5"} style={{width:"100%",maxWidth:`${SLPDP.priceCard.slot1.getWidth()}px`}}>
          <StyledSkeleton
            variant="rect"
            width={"inherit"}
            height={SLPDP.priceCard.slot1.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12} className={"bottom-margin-5"}>
          <StyledSkeleton
            variant="rect"
            width={SLPDP.priceCard.price.getWidth()}
            height={SLPDP.priceCard.price.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12} className={"bottom-margin-2 hola"} style={{width:"100%",maxWidth:`${SLPDP.priceCard.slot2.getWidth()}px`}}>
          <StyledSkeleton
            variant="rect"
            width={"inherit"}
            height={SLPDP.priceCard.slot2.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

export const SLPDPBreadCrumb = () => {
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }

  return (
    <StyledGrid
      className={
        isMobile
          ? "top-margin-2 bottom-margin-2 left-margin-2 right-margin-2"
          : "top-margin-2 bottom-margin-2 right-margin-2"
      }>
      <StyledGrid item xs={12}>
        <StyledSkeleton
          noCard
          variant="rect"
          width={isMobile ? SLPDP.breadCrumb.mobile : SLPDP.breadCrumb.desktop.getWidth()}
          height={SLPDP.breadCrumb.desktop.getHeight()}
          style={{ backgroundColor: "#000" }}
        />
      </StyledGrid>
    </StyledGrid>
  );
};
export const SLPDPAddToCart = () => {
  return (
    <StyledAccordion>
      <StyledGrid container spacing={2} className={"bottom-margin-2"}>
        <StyledGrid item xs={3}>
          <StyledSkeleton variant="rect" className="wide" height={SLPDP.addToCart.getHeight()} />
        </StyledGrid>
        <StyledGrid item xs={9}>
          <StyledSkeleton variant="rect" className="wide" height={SLPDP.addToCart.getHeight()} />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

export const SLPDPAvailability = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  return (
    <StyledAccordion>
      <StyledGrid container spacing={2} className={"bottom-margin-2"}>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={isTablet ? SLPDP.availability.mobile.title : SLPDP.availability.title.getWidth()}
            height={SLPDP.availability.title.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={
              isTablet ? SLPDP.availability.mobile.availabilityOnline : SLPDP.availability.availabilityOnline.getWidth()
            }
            height={SLPDP.availability.availabilityOnline.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={
              isTablet ? SLPDP.availability.mobile.availabilityStore : SLPDP.availability.availabilityStore.getWidth()
            }
            height={SLPDP.availability.availabilityStore.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={
              isTablet ? SLPDP.availability.mobile.searchOtherStore : SLPDP.availability.searchOtherStore.getWidth()
            }
            height={SLPDP.availability.searchOtherStore.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

export const SLPDPAboutThisProduct = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));

  return (
    <StyledAccordion>
      <StyledGrid container spacing={2} className={"bottom-margin-2"}>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={isTablet ? SLPDP.about.mobile.title : SLPDP.about.title.getWidth()}
            height={SLPDP.about.title.getHeight()}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton variant="rect" className="wide" height={SLPDP.about.content.getHeight()} />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledSkeleton
            variant="rect"
            width={isTablet ? SLPDP.about.mobile.options : SLPDP.about.mobile.options}
            height={SLPDP.about.options.getHeight()}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledAccordion>
  );
};

const StyledOption = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    .radio-btn{
        border-radius: 10.7px !important;
    }
  `}
`;

const SLPDPOption = () => {
    return (
        <StyledGrid direction="row" container alignItems="center" style={{ display:"flex", maxWidth:"84.4px", flexWrap: "nowrap" }}>
            {/* Radio button */}
            <StyledOption container item direction="row" alignItems="center" style={{ width: "min-content" }}>
                <StyledSkeleton className={"radio-btn"} variant="rect" width={21.4} height={21.4} />
            </StyledOption>
            {/* Option value */}
            <StyledGrid container item direction="row" alignItems="center" style={{ paddingLeft: "2.14px", maxWidth:"98px", minWidth: "50px", paddingRight:"5px"}}>
                <StyledSkeleton variant="rect" width={"inherit"} height={15} />
            </StyledGrid>
        </StyledGrid>
    );
}

const SLPDPOptionContainer = () => {
    return (
        <>
            <StyledGrid style={{ maxWidth:"130px",  width:"80%", marginBottom:"10px"}}>
                <StyledSkeleton variant="rect" width={"inherit"} height={18} />
            </StyledGrid>
            <StyledGrid container style={{ flexWrap:"nowrap", height: "auto", width:"100%", marginBottom: "20px"}}>
                <SLPDPOption/>
                <SLPDPOption/>
                <SLPDPOption/>
            </StyledGrid>
        </>
    );
}

export const SLPDPSelectYourOptions = () => {
  return (
      <>
        {/* Opciones disponibles */}
        <StyledGrid item xs={12} style={{paddingBottom:"0px"}}>
            <SLPDPOptionContainer/>
            <SLPDPOptionContainer/>
        </StyledGrid>
      </>
  );
}

export const SLPDPHelpForChoose = () => {
  return (
      <>
        {/* Ayuda para elegir */}
        <StyledGrid item xs={12} style={{maxWidth:"101px", paddingTop:"0px",paddingBottom:"0px"}}>
            <StyledSkeleton variant="rect" width={"inherit"} height={14}  />
        </StyledGrid>
      </>
  );
}

const StyledPdpContainer = styled(({ ...props }) => <StyledContainer {...props} />)`
${({ theme, start, end }) => `

.pdp-slot1 {
  position: relative;
  z-index: 10;
  ${theme.breakpoints.up(tabletBreakpoint())} {
      width: calc(60% - 5px);          
  }
  
  ${theme.breakpoints.down(tabletBreakpoint())} {
      width: 100%            
  }
}

.pdp-slot2 {

  ${theme.breakpoints.up(tabletBreakpoint())} {
      width: calc(40% - 5px);        
  }
  
  ${theme.breakpoints.down(tabletBreakpoint())} {
      width: 100%           
  }
}


`}
`;

export const SLPDPLayout = () => {
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const theme = useTheme();
  let isMobile = false;
  if (theme.breakpoints.down("sm")) {
    const breakpoint = theme.breakpoints.down("sm").replace("@media", "");
    isMobile = window.matchMedia(breakpoint).matches;
  }
  return (
    <>
      {isMobile && (
        <StyledContainer id="page">
          <SLPDPBreadCrumb />
          <StyledGrid container spacing={2}>
            <StyledGrid container item xs={12}>
              <StyledGrid item xs={12} className={"bottom-margin-3"}>
                <SLPDPProductSummaryCard />
              </StyledGrid>
              <StyledGrid item xs={12} className={isMobile ? "left-margin-2 right-margin-2" : ""}>
                <StyledAccordion>
                  <SLPDPProductImageCardIsTablet />
                </StyledAccordion>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPProductPriceCard />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAddToCart />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAvailability />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAboutThisProduct />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
      )}
      {!isMobile && (
        <StyledPdpContainer id="page">
          <SLPDPBreadCrumb />
          <StyledGrid container spacing={2}>
            <StyledGrid container item className="pdp-slot1">
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPProductSummaryCard />
              </StyledGrid>
              <StyledGrid item xs={12} id="sofff">
                <StyledAccordion>
                  <SLPDPProductImageCard />
                </StyledAccordion>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item className="pdp-slot2">
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPProductPriceCard />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAddToCart />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAvailability />
              </StyledGrid>
              <StyledGrid item xs={12} className={"bottom-margin-2"}>
                <SLPDPAboutThisProduct />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledPdpContainer>
      )}
    </>
  );
};

export const SLPDPSearchOtherStores = () => {

  return (
    <StyledWrapper>
      <StyledGrid className={"left-margin-2 right-margin-2"}>
          <StyledGrid container>
            <StyledGrid className={"top-margin-3"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={"100%"}
                height={SLPDP.searchOtherStore.storeName.getHeight()}
                />
            </StyledGrid>
            <StyledGrid className={"top-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={"100%"}
                height={SLPDP.searchOtherStore.addressStore.getHeight()}
              />
            </StyledGrid>
            <StyledGrid className={"top-margin-1"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={"70%"}
                height={SLPDP.searchOtherStore.addressStore.getHeight()}
                
                />
            </StyledGrid>
            <StyledGrid className={"top-margin-4"} item xs={12}>
              <StyledSkeleton
                variant="rect"
                width={"100%"}
                height={SLPDP.searchOtherStore.selectStore.getHeight()}
              />
            </StyledGrid>
          </StyledGrid>
      </StyledGrid>
    </StyledWrapper>
  );
};
